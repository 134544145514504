import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { AssignToRouteDetail } from './assign-to-route-detail';

@Component({
  selector: 'pnd-assign-to-route-detail',
  templateUrl: './assign-to-route-detail.component.html',
  styleUrls: ['./assign-to-route-detail.component.scss'],
  host: { class: 'pnd-AssignToRouteDetail' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignToRouteDetailComponent implements OnInit {
  constructor() {}

  @Input()
  detail: AssignToRouteDetail;

  ngOnInit() {}
}
