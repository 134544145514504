import { Injectable } from '@angular/core';
import { CustomerApiService } from '@xpo-ltl/sdk-customer';
import { GetCustomerLocationOperationsProfileResp } from '@xpo-ltl/sdk-customer/lib/api-customer';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StopDetailsService {
  constructor(private customerService: CustomerApiService) {}

  loadCustomerProfile(customerAccountNumber: number): Observable<GetCustomerLocationOperationsProfileResp> {
    return this.customerService
      .getCustomerLocationOperationsProfile({
        custAcctNbr: customerAccountNumber,
      })
      .pipe(
        catchError(() => {
          return of(undefined);
        }),
        take(1)
      );
  }
}
