import { Injectable, EventEmitter, Output } from '@angular/core';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { Observable, of } from 'rxjs';

/**
 * Empty data source since we don't use the Board to fetch data.
 */
@Injectable()
export class PlanningMapDataSource extends XpoBoardDataSource {
  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    return of(new XpoBoardData(state, [{}], 1, 1));
  }
}
