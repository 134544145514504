import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { Route, Stop } from '@xpo-ltl/sdk-cityoperations';
import { RouteStatusCd, ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import {
  AgGridEvent,
  MenuItemDef,
  RowGroupOpenedEvent,
  RowNode,
  RowSelectedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import {
  filter as _filter,
  find as _find,
  forEach as _forEach,
  get as _get,
  includes as _includes,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  map as _map,
  size as _size,
  some as _some,
  uniq as _uniq,
} from 'lodash';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { filter, finalize, map, take, takeUntil, debounceTime } from 'rxjs/operators';
import { GridRowTransposedComponent } from '../../../../core';
import { PndStoreState, RoutesStoreActions, RoutesStoreSelectors, TripsStoreActions } from '../../../store';
import { RouteBalancingActions } from '../../../store/route-balancing-store';
import {
  ActivityCdPipe,
  AssignToRouteComponent,
  GrandTotalService,
  MapToolbarService,
  pndFrameworkComponents,
  RouteService,
  StopMapComponent,
  StopWindowService,
} from '../../shared';
import { PluralMaps } from '../../shared/classes/plural-maps';
import { AssignToRouteDialogData } from '../../shared/components/assign-to-route/assign-to-route-dialog-data';
import { ExistingOrNewRoute } from '../../shared/components/assign-to-route/existing-or-new-route.enum';
import { InboundPlanningGridBaseComponent } from '../../shared/components/inbound-planning-grid-base/inbound-planning-grid-base.class';
import { StoreSourcesEnum } from '../../shared/enums/store-sources.enum';
import { areStopsEqual, AssignedStopIdentifier, EventItem } from '../../shared/interfaces/event-item.interface';
import { RouteColorService } from '../../shared/services/route-color.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { RouteStopsGridFields } from './enums/route-stops-grid-fields.enum';
import { RouteStopsGridItem } from './models';
import { RouteStopsBoardTemplate } from './route-stops-board-view-template.model';
import { RouteStopsComponentName } from './route-stops-component-name';
import { RouteStopsDataSource } from './route-stops-data-source.service';
import { RouteStopsDataViewDataStore } from './route-stops-data-view-data-store.service';

@Component({
  selector: 'pnd-route-stops',
  templateUrl: './route-stops.component.html',
  styleUrls: ['./route-stops.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteStopsComponent extends InboundPlanningGridBaseComponent implements OnInit, OnDestroy {
  views: XpoBoardView[];
  hasSelectedStops$: Observable<boolean>;
  routeIds$: Observable<string>;

  private isAssignDisabledSubject = new BehaviorSubject<boolean>(true);
  isAssignDisabled$ = this.isAssignDisabledSubject.asObservable();
  set isAssignDisabled(disabled) {
    this.isAssignDisabledSubject.next(disabled);
  }

  private isUnassignDisabledSubject = new BehaviorSubject<boolean>(true);
  isUnassignDisabled$ = this.isUnassignDisabledSubject.asObservable();
  set isUnassignDisabled(disabled) {
    this.isUnassignDisabledSubject.next(disabled);
  }

  private isClearSelectionDisabledSubject = new BehaviorSubject<boolean>(true);
  isClearSelectionDisabled$ = this.isClearSelectionDisabledSubject.asObservable();
  set isClearSelectionDisabled(disabled) {
    this.isClearSelectionDisabledSubject.next(disabled);
  }

  stopsSelected = 0;
  shipmentsSelected = 0;
  motorMovesSelected = 0;
  weightSelected = 0;
  specialServices: ShipmentSpecialServiceCd[];

  readonly PluralMaps = PluralMaps;

  private readonly showSpinnerSubject = new BehaviorSubject<boolean>(false);
  showSpinner$ = this.showSpinnerSubject.asObservable();

  private focusedItem: EventItem<AssignedStopIdentifier>;
  private focusedRow: RowNode;
  private collapsedRows: { routeInstId: number; seqNo: number }[] = [];
  private lastSelectedStops: EventItem<AssignedStopIdentifier>[];
  private setFocusedActionSubject = new Subject<RoutesStoreActions.SetFocusedStopForSelectedRouteAction>();

  constructor(
    public dataSource: RouteStopsDataSource,
    protected pndStore$: Store<PndStoreState.State>,
    private dialog: MatDialog,
    private stopWindowService: StopWindowService,
    private activityCd: ActivityCdPipe,
    private mapToolbarService: MapToolbarService,
    private routeService: RouteService,
    private routeColorService: RouteColorService,
    protected userPreferencesService: UserPreferencesService,
    private grandTotalService: GrandTotalService,
    @Inject(DOCUMENT) private document
  ) {
    super(pndStore$, dataSource, userPreferencesService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // create a debounced Subject to reduce spamming focus actions
    this.setFocusedActionSubject
      .asObservable()
      .pipe(debounceTime(50))
      .subscribe((action) => {
        this.pndStore$.dispatch(action);
      });

    this.routeIds$ = this.dataSource.routes$.pipe(
      map((arr) => arr.map((r) => r.routeId)),
      map((r) => (r && r.length ? r.join(', ') : null))
    );

    this.views = [
      (<RouteStopsBoardTemplate>this.viewTemplates[0]).createStopsView(),
      (<RouteStopsBoardTemplate>this.viewTemplates[0]).createStopsView(),
    ];
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.boardReadySubject.next(false);
  }

  onRowGroupOpened(event: RowGroupOpenedEvent) {
    const leaf = event.node.allLeafChildren[0];
    if (!event.node.expanded) {
      // We save one detail children to access its parent later, this need to be done because the master rows have a random Id.
      const newRow =
        _get(leaf, 'data.routeInstId', false) && _get(leaf, 'data.seqNo', false)
          ? { routeInstId: leaf.data.routeInstId, seqNo: leaf.data.seqNo }
          : undefined;
      if (newRow) {
        this.collapsedRows = [...this.collapsedRows, newRow];
      }
    } else {
      const indexToRemove = this.collapsedRows.findIndex(
        (row) =>
          _get(row, 'routeInstId', false) &&
          _get(leaf, 'data.routeInstId', false) &&
          row.routeInstId === leaf.data.routeInstId &&
          _get(row, 'seqNo', false) &&
          _get(leaf, 'data.seqNo', false) &&
          row.seqNo === leaf.data.seqNo
      );
      if (indexToRemove !== -1) {
        this.collapsedRows.splice(indexToRemove, 1);
      }
    }
  }

  onGridReady(gridEvent: AgGridEvent) {
    super.onGridReady(gridEvent);
    this.subscribeToChangeColor();

    // listen for focused stops shipments in the planning map
    this.pndStore$
      .select(RoutesStoreSelectors.focusedStopForSelectedRoute)
      .pipe(
        filter((item: EventItem<AssignedStopIdentifier>) => item && item.source !== StoreSourcesEnum.ROUTE_STOPS_GRID),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((item: EventItem<AssignedStopIdentifier>) => {
        this.focusedItem = item;
        if (item && item.id) {
          this.focusedRow = this.findNode(item.id.routeInstId, item.id.seqNo);
          if (this.focusedRow) {
            this.gridApiEvent.api.collapseAll();
            this.focusedRow.parent.expanded = true;
            this.gridApiEvent.api.onGroupExpandedOrCollapsed();
            this.gridApiEvent.api.redrawRows({ rowNodes: [this.focusedRow] });
            this.gridApiEvent.api.ensureNodeVisible(this.focusedRow);
          }
        } else {
          this.gridApiEvent.api.redrawRows({ rowNodes: [this.focusedRow] });
          this.focusedRow = null;
        }
      });

    this.pndStore$
      .select(RoutesStoreSelectors.selectedStopsForSelectedRoutes)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((selectedStops) => {
        // update the summary whenever selections change
        this.checkForPolygonSelections(selectedStops, StoreSourcesEnum.ROUTE_STOPS_GRID);
        this.updateSummary(selectedStops);
        this.lastSelectedStops = selectedStops;
        this.updateNodeSelection(_map(selectedStops, (selectedStop) => this.getSelectedRowFromStoreId(selectedStop)));
      });

    this.grandTotalService.routeStopsTotals$.pipe(takeUntil(this.unsubscriber.done)).subscribe((totals) => {
      if (this.grandTotalService.isRouteStopsGrandTotalsEmpty(totals)) {
        this.gridApiEvent.api.setPinnedBottomRowData(undefined);
      } else {
        this.gridApiEvent.api.setPinnedBottomRowData(totals);
      }
    });
  }

  collapseRows() {
    this.collapsedRows.forEach((row) => {
      const node = this.findNode(row.routeInstId, row.seqNo);
      if (node) {
        node.parent.expanded = false;
      }
    });
    // Throws an exception
    // this.gridApiEvent.onGroupExpandedOrCollapsed();
  }

  subscribeToChangeColor(): void {
    this.routeColorService.colorChanged$
      .pipe(
        filter((change) => !!change),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((change: { routeInstId: number; color: string }) => {
        this.gridApiEvent.api.redrawRows();
      });
  }

  assignRoute(): void {
    this.openAssignToRouteDialog(ExistingOrNewRoute.Existing);
  }

  createRoute(): void {
    this.openAssignToRouteDialog(ExistingOrNewRoute.New);
  }

  unassignRoute(): void {
    this.toggleActionButtons(false);

    const routeNameId = new Map<number, string>();
    const routeStops = new Map<number, Stop[]>();

    this.gridApiEvent.api.getSelectedNodes().forEach((row) => {
      routeNameId.set(_get(row, 'data.routeInstId'), _get(row, 'data.routeId'));
    });

    combineLatest([
      this.pndStore$.select(RoutesStoreSelectors.stopsForSelectedRoutes),
      this.pndStore$.select(RoutesStoreSelectors.selectedStopsForSelectedRoutes),
    ])
      .pipe(take(1))
      .subscribe(([stopsForSelectedRoutes, selectedStopsForSelectedRoutes]) => {
        selectedStopsForSelectedRoutes.forEach((identifier: EventItem<AssignedStopIdentifier>) => {
          if (!routeStops.has(identifier.id.routeInstId)) {
            routeStops.set(identifier.id.routeInstId, []);
          }
          const stopsToUnassign = routeStops.get(identifier.id.routeInstId);
          const allStops = _get(stopsForSelectedRoutes, identifier.id.routeInstId);
          let selectedStop;
          allStops.forEach((stop) => {
            _get(stop, 'activities', []).forEach((activity) => {
              if (_get(activity, 'routeShipment.routeSequenceNbr') === identifier.id.origSeqNo) {
                selectedStop = stop;
              }
            });
          });
          if (selectedStop) {
            routeStops.set(identifier.id.routeInstId, [...stopsToUnassign, selectedStop]);
          }
        });

        if (routeStops.size > 0) {
          this.pndStore$.dispatch(
            new RouteBalancingActions.SetCanOpenRouteBalancing({
              canOpenRouteBalancing: false,
            })
          );

          const requests: Observable<void>[] = [];
          routeStops.forEach((stops, routeInstId) => {
            requests.push(
              this.routeService.unassignStops(
                routeNameId.get(routeInstId),
                stops[0].tripNode.tripInstId,
                stops.map((stop) => stop.tripNode.tripNodeSequenceNbr)
              )
            );
          });

          this.showSpinnerSubject.next(true);

          forkJoin(requests)
            .pipe(
              take(1),
              finalize(() => this.showSpinnerSubject.next(false))
            )
            .subscribe(
              () => {
                this.pndStore$.dispatch(new TripsStoreActions.RefreshTrips());
                this.clearSelection();
              },
              () => {
                this.pndStore$.dispatch(
                  new RouteBalancingActions.SetCanOpenRouteBalancing({
                    canOpenRouteBalancing: true,
                  })
                );
                this.toggleActionButtons(true);
              }
            );
        }
      });
  }

  /**
   * Clear all selected stops
   */
  clearSelection(): void {
    this.mapToolbarService.toggleDrawModeOff();
    this.pndStore$.dispatch(
      new RoutesStoreActions.SetSelectedStopsForSelectedRoutesAction({
        selectedStopsForSelectedRoutes: [],
      })
    );
  }

  /**
   * Handles selection changed event which manages bulk selection/deselection events
   */

  onSelectionChangedFromBoard(event: SelectionChangedEvent): void {
    if (!this.areAllOrNoNodesSelected(event)) {
      return;
    }

    const selectedRows = event.api.getSelectedRows();

    if (!selectedRows.length) {
      this.clearSelectedStopsForSelectedRoutes();
    } else {
      this.addAllNodesToSelectedStopsForSelectedRoutes();
    }

    event.api.redrawRows();
  }

  /**
   * Clears the selection state for all selected stops for selected routes
   */
  private clearSelectedStopsForSelectedRoutes(): void {
    this.pndStore$.dispatch(
      new RoutesStoreActions.SetSelectedStopsForSelectedRoutesAction({
        selectedStopsForSelectedRoutes: [],
      })
    );

    this.updateSummary([]);
  }

  /**
   * Adds all nodes in grid to selected stops for selected routes
   */
  private addAllNodesToSelectedStopsForSelectedRoutes(): void {
    const selectedStopsForSelectedRoutes = [];
    this.gridApiEvent.api.forEachNode((node: RowNode) => {
      if (node.data) {
        const eventStop = node.data as AssignedStopIdentifier;
        const newStop: EventItem<AssignedStopIdentifier> = {
          id: {
            routeInstId: eventStop.routeInstId,
            seqNo: eventStop.seqNo,
            origSeqNo: eventStop.origSeqNo,
          },
          source: StoreSourcesEnum.ROUTE_STOPS_GRID,
        };
        selectedStopsForSelectedRoutes.push(newStop);
      }
    });

    this.pndStore$.dispatch(
      new RoutesStoreActions.SetSelectedStopsForSelectedRoutesAction({
        selectedStopsForSelectedRoutes,
      })
    );
    this.updateSummary(selectedStopsForSelectedRoutes);
  }

  /**
   * Update the store to reflect the selected stops in the Grid
   */
  onRowSelectedFromBoard(event: RowSelectedEvent) {
    if (this.areAllOrNoNodesSelected(event)) {
      return;
    }

    const isSelected = _get(event.node, 'selected', false);
    if (event.data) {
      const eventStop = event.data as AssignedStopIdentifier;
      // add/remove the stop from the list of selected stops
      let selectedStops: EventItem<AssignedStopIdentifier>[] = [...this.lastSelectedStops];
      const originalSelectedStopsCount = _size(selectedStops);
      if (isSelected) {
        if (!_find(selectedStops, (stop) => areStopsEqual(stop.id, eventStop))) {
          const newStop: EventItem<AssignedStopIdentifier> = {
            id: {
              routeInstId: eventStop.routeInstId,
              seqNo: eventStop.seqNo,
              origSeqNo: eventStop.origSeqNo,
            },
            source: StoreSourcesEnum.ROUTE_STOPS_GRID,
          };
          selectedStops.push(newStop);
        }
      } else {
        selectedStops = _filter(selectedStops, (stop) => !areStopsEqual(stop.id, eventStop));
      }

      if (originalSelectedStopsCount !== _size(selectedStops)) {
        this.pndStore$.dispatch(
          new RoutesStoreActions.SetSelectedStopsForSelectedRoutesAction({
            selectedStopsForSelectedRoutes: selectedStops,
          })
        );
        this.updateSummary(selectedStops);
      }
    }

    // TODO do we need this?
    if (event.api) {
      event.api.redrawRows();
    }
  }

  private areAllOrNoNodesSelected(event: RowSelectedEvent | SelectionChangedEvent): boolean {
    return (
      _get(this.dataSource.currentState, 'data.recordCount') === event.api.getSelectedRows().length ||
      !event.api.getSelectedRows().length
    );
  }

  private toggleActionButtons(enabled: boolean): void {
    this.isAssignDisabled = !enabled;
    this.isUnassignDisabled = !enabled;
    this.isClearSelectionDisabled = !enabled;
  }

  private clearSummary() {
    this.stopsSelected = 0;
    this.shipmentsSelected = 0;
    this.motorMovesSelected = 0;
    this.weightSelected = 0;
    this.specialServices = [];
  }

  // update the summary values of all selected stops
  private updateSummary(selectedStops: EventItem<AssignedStopIdentifier>[]) {
    this.clearSummary();

    if (!_isEmpty(selectedStops)) {
      this.pndStore$
        .select(RoutesStoreSelectors.selectedRoutes)
        .pipe(take(1))
        .subscribe((selectedRoutes: Route[]) => {
          this.isAssignDisabled = _size(_uniq(selectedStops.map((i) => i.id.routeInstId))) > 1;
          this.isUnassignDisabled = false;
          this.isClearSelectionDisabled = false;

          const servicesAccumulator = [];

          // Update new selected rows
          _forEach(selectedStops, (item: EventItem<AssignedStopIdentifier>) => {
            const selectedRoute = selectedRoutes.find((r) => r.routeInstId === item.id.routeInstId);
            if (
              _get(selectedRoute, 'statusCd') === RouteStatusCd.DISPATCHED ||
              _get(selectedRoute, 'statusCd') === RouteStatusCd.RETURNING ||
              _get(selectedRoute, 'statusCd') === RouteStatusCd.COMPLETE
            ) {
              this.isAssignDisabled = true;
              this.isUnassignDisabled = true;
            }

            const node = this.findNode(item.id.routeInstId, item.id.seqNo);
            if (node) {
              const routeItem: RouteStopsGridItem = node.data;
              this.stopsSelected += 1;
              this.shipmentsSelected += routeItem.totalBills || 0;
              this.motorMovesSelected += routeItem.totalMotorMoves || 0;
              this.weightSelected += routeItem.totalWeight || 0;
              servicesAccumulator.push(..._get(routeItem, 'specialServices', []));
            }
          });
          this.specialServices = _uniq(servicesAccumulator);
        });
    } else {
      this.toggleActionButtons(false);
    }
  }

  private openAssignToRouteDialog(routeType: ExistingOrNewRoute): void {
    const stopsToAssign: Stop[] = [];

    combineLatest([
      this.pndStore$.select(RoutesStoreSelectors.stopsForSelectedRoutes),
      this.pndStore$.select(RoutesStoreSelectors.selectedStopsForSelectedRoutes),
    ])
      .pipe(take(1))
      .subscribe(([stopsForSelectedRoutes, selectedStopsForSelectedRoutes]) => {
        selectedStopsForSelectedRoutes.forEach((identifier: EventItem<AssignedStopIdentifier>) => {
          const allStops = _get(stopsForSelectedRoutes, identifier.id.routeInstId);
          let selectedStop;
          allStops.forEach((stop) => {
            _get(stop, 'activities', []).forEach((activity) => {
              if (_get(activity, 'routeShipment.routeSequenceNbr') === identifier.id.origSeqNo) {
                selectedStop = stop;
              }
            });
          });
          if (selectedStop) {
            stopsToAssign.push(selectedStop);
          }
        });

        if (!_isEmpty(stopsToAssign)) {
          const dialogRef = this.dialog.open(AssignToRouteComponent, {
            data: <AssignToRouteDialogData>{
              initialMode: routeType,
              selectedStops: stopsToAssign,
            },
            disableClose: true,
            hasBackdrop: true,
          });
          dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((actionPerformed) => {
              if (actionPerformed) {
                this.mapToolbarService.toggleDrawModeOff();
                this.pndStore$.dispatch(new TripsStoreActions.RefreshTrips());
                this.clearSummary();
              }
            });
        }
      });
  }

  // Finds the RowNode that matches the requested stop in a route
  private findNode(routeInstId: number, seqNo: number): RowNode {
    let node: RowNode;
    if (this.gridApiEvent) {
      this.gridApiEvent.api.forEachLeafNode((leafNode) => {
        const leafStop = leafNode.data as AssignedStopIdentifier;
        if (areStopsEqual({ routeInstId, seqNo, origSeqNo: seqNo }, leafStop)) {
          node = leafNode;
        }
      });
    }
    return node;
  }

  private onHover(data: RouteStopsGridItem): void {
    const focusedStopForSelectedRoute: EventItem<AssignedStopIdentifier> = !!data
      ? {
          id: {
            routeInstId: data.routeInstId,
            seqNo: data.seqNo,
            origSeqNo: data.origSeqNo,
          },
          source: StoreSourcesEnum.ROUTE_STOPS_GRID,
        }
      : {
          id: undefined,
          source: StoreSourcesEnum.ROUTE_STOPS_GRID,
        };

    this.setFocusedActionSubject.next(
      new RoutesStoreActions.SetFocusedStopForSelectedRouteAction({
        focusedStopForSelectedRoute: focusedStopForSelectedRoute,
      })
    );
  }

  private onAddressClick(routeStop: RouteStopsGridItem): void {
    const { consigneeName, latitude, longitude } = routeStop;

    if (_isEmpty(consigneeName)) {
      return;
    }

    this.dialog.open(StopMapComponent, {
      data: {
        consigneeName,
        geoCoordinates: { latitude, longitude },
      },
      disableClose: true,
      hasBackdrop: false,
    });

    this.pndStore$.dispatch(new RoutesStoreActions.SetClickedRouteStopAction({ clickedRouteStop: routeStop }));
  }

  private getContextMenuItems(params): (string | MenuItemDef)[] {
    return [
      'copy',
      'copyWithHeaders',
      'paste',
      'separator',
      'export',
      'separator',
      {
        name: 'Transpose',
        action: () => {
          this.dialog.open(GridRowTransposedComponent, {
            data: { ...params, dialogTitle: `Route Stops - ${params.node.key || params.node.data.routeId}` },
            disableClose: true,
            hasBackdrop: false,
          });
        },
      },
    ];
  }

  protected getRowNodeId(node: RowNode) {
    return `${_get(node, 'data.routeInstId', undefined)}-${_get(node, 'data.seqNo', undefined)}`;
  }

  protected getSelectedRowFromStoreId(selectedRow) {
    return `${_get(selectedRow, 'id.routeInstId', undefined)}-${_get(selectedRow, 'id.seqNo', undefined)}`;
  }

  protected getSelectedStoreStateSelector() {
    return RoutesStoreSelectors.selectedStopsForSelectedRoutes;
  }

  protected getSelectedRowColumnFieldName() {
    return RouteStopsGridFields.ROW_SELECTED;
  }

  protected updateNodeSelection(selectedNodeIds: string[]) {
    if (!this.gridApiEvent) {
      return;
    }
    this.gridApiEvent.api.forEachLeafNode((leafNode) => {
      const isSelected = _includes(selectedNodeIds, this.getRowNodeId(leafNode));
      if (isSelected && !leafNode.isSelected()) {
        leafNode.setSelected(true);
      } else if (!isSelected && leafNode.isSelected()) {
        leafNode.setSelected(false);
      }
    });
    this.refreshSortForSelectedRows();
  }

  protected getBoardViewTemplates() {
    return [
      new RouteStopsBoardTemplate(
        this.onAddressClick.bind(this),
        this.stopWindowService,
        this.activityCd,
        this.routeColorService
      ),
    ];
  }

  protected getGridOptions() {
    const _this = this;
    return {
      frameworkComponents: pndFrameworkComponents,
      headerHeight: 40,
      rowHeight: 40,
      defaultColDef: { resizable: true },
      rowClassRules: {
        'ag-row-hover': (params) => {
          return (
            _get(params, 'data.seqNo', false) &&
            _get(this.focusedItem, 'id.seqNo', false) &&
            this.focusedItem.id.seqNo === +params.data.seqNo
          );
        },
      },
      groupDefaultExpanded: 1,
      onCellMouseOver: (event) => this.onHover(event.node.data),
      onCellMouseOut: () => this.onHover(undefined),
      onRowSelected: (event: RowSelectedEvent) => this.onRowSelectedFromBoard(event),
      onSelectionChanged: (event: SelectionChangedEvent) => this.onSelectionChangedFromBoard(event),
      onRowDataChanged: () => this.collapseRows(),
      onRowGroupOpened: (event) => this.onRowGroupOpened(event),
      getRowStyle: (params) => {
        const routeInstId = _get(params, 'node.allLeafChildren[0].data.routeInstId');
        const color =
          params.node.group && routeInstId ? _this.routeColorService.getColorForRoute(routeInstId) : 'transparent';
        const width = '4px';
        return {
          'border-left': `${width} solid ${color}`,
        };
      },
      getContextMenuItems: (params) => this.getContextMenuItems(params),
      pinnedBottomRowData: [],
    };
  }

  protected mapViewDataStore(preferences) {
    return new RouteStopsDataViewDataStore(this.userPreferencesService, preferences);
  }

  protected getComponentName() {
    return RouteStopsComponentName;
  }
}
