import { Component } from '@angular/core';

@Component({
  selector: 'pnd-zoom-in-button',
  templateUrl: './zoom-in-button.component.html',
  styleUrls: ['./zoom-in-button.component.scss'],
})
export class ZoomInButton {
  constructor() {}
}
