import { XpoGMapBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-gmaps';

export class PlanningMapBoardTemplate extends XpoGMapBoardViewTemplate {
  static templateId = 'PlanningMapBoardTemplate';

  constructor() {
    super({
      id: PlanningMapBoardTemplate.templateId,
      name: 'Planning Map',
      keyField: '',
      allowAdditional: false,
    });
  }
}
