import { AgmCoreModule } from '@agm/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatCardModule,
  MatCheckboxModule,
  MatInputModule,
  MatListModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatTooltipModule,
} from '@angular/material';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { XpoDashbarModule, XpoFiltersModule } from '@xpo-ltl/ngx-ltl-board';
import {
  XpoButtonModule,
  XpoDialogModule,
  XpoIconModule,
  XpoPopoverModule,
  XpoTabsModule,
} from '@xpo-ltl/ngx-ltl-core';
import {
  XpoAllowCharactersModule,
  XpoLtlDialogTitleModule,
  XpoLtlPipeModule,
  XpoLtlShipmentDetailsModule,
} from '@xpo/ngx-ltl';
import { DragScrollModule } from 'cdk-drag-scroll';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { ResizeObserverDirective } from '../../inbound-planning/shared/directives/resize-observer.directive';
import { AssignToRouteComponent } from './components/assign-to-route/assign-to-route.component';
import { AssignToRouteDetailComponent } from './components/assign-to-route/components/assign-to-route-detail/assign-to-route-detail.component';
import { CircleCellRendererComponent } from './components/circle-cell-renderer/circle-cell-renderer.component';
import { DeliveryWindowTimeRendererComponent } from './components/delivery-window-time-renderer/delivery-window-time-renderer.component';
import {
  ActionLinkCellRendererComponent,
  GuaranteedIconComponent,
  HazmatIconComponent,
  MetricBarComponent,
  RouteActionLinkCellRendererComponent,
  RouteSummaryComponent,
  RouteTimelineCellRendererComponent,
  RouteTimelineHeaderRendererComponent,
  SpecialServicesCellRendererComponent,
  SpecialServicesComponent,
  StatusChipComponent,
  TotalTextCellRendererComponent,
} from './components/index';
import { CenterSicButton } from './components/map-toolbar-widget/components/center-map/center-map-button.component';
import { GeoFilterPolygonSelectionComponent } from './components/map-toolbar-widget/components/geo-filter-polygon-selection/geo-filter-polygon-selection.component';
import { PolygonSelectionComponent } from './components/map-toolbar-widget/components/polygon-selection/polygon-selection.component';
import { RecenterComponent } from './components/map-toolbar-widget/components/recenter/recenter.component';
import { MapToolbarWidgetComponent } from './components/map-toolbar-widget/map-toolbar-widget.component';
import { MapWeatherComponent } from './components/map-weather/map-weather.component';
import { ZoomInButton } from './components/map-zoom-widget/components/zoom-in/zoom-in-button.component';
import { ZoomOutButton } from './components/map-zoom-widget/components/zoom-out/zoom-out-button.component';
import { MapZoomWidgetComponent } from './components/map-zoom-widget/map-zoom-widget.component';
import { MetricBarCardComponent } from './components/metric-bar/card';
import { RefreshNotificationWidgetComponent } from './components/refresh-notification-widget/refresh-notification-widget.component';
import { ReleaseRouteToggleCellRendererComponent } from './components/release-route-toggle-cell-renderer';
import { ReleaseRouteComponent } from './components/release-route/release-route.component';
import { EmployeeDetailsComponent } from './components/route-summary/components/employee-details/employee-details.component';
import { RouteDetailsComponent } from './components/route-summary/components/route-details/route-details.component';
import { ShipmentDetailsComponent } from './components/route-summary/components/shipment-details/shipment-details.component';
import { StopDetailsComponent } from './components/route-summary/components/stop-details/stop-details.component';
import { TripDetailsComponent } from './components/route-summary/components/trip-details/trip-details.component';
import { SaveChangesModalComponent } from './components/save-changes-modal/save-changes-modal.component';
import { SelectionSummaryComponent } from './components/selection-summary/selection-summary.component';
import { DriverCollectIconComponent } from './components/service-icons/driver-collect-icon.component';
import { LiftGateIconComponent } from './components/service-icons/lift-gate-icon.component';
import { SicMarkerCompassComponent } from './components/sic-marker-compass/sic-marker-compass.component';
import { StopMapComponent } from './components/stop-map/stop-map.component';
import { TimeFilterComponent } from './components/time-filter/time-filter.component';
import { UnmappedStopDetailComponent } from './components/unmapped-stops/components/unmapped-stop-detail/unmapped-stop-detail.component';
import { UnmappedStopsComponent } from './components/unmapped-stops/unmapped-stops.component';
import { UpdatedReleaseNotesComponent } from './components/updated-release-notes/updated-release-notes.component';
import { ActivityCdPipe, ActivityTypePipe, PickupTypeCdPipe, StopWindowCdPipe } from './pipes';
import { BillClassCdPipe } from './pipes/bill-class-cd.pipe';
import { DeliveryQualifierCdPipe } from './pipes/delivery-qualifier-cd.pipe';
import { EquipmentPipe } from './pipes/equipment.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { RemoveLeadingZerosPipe } from './pipes/remove-leading-zeros.pipe';
import { TripStatusCdPipe } from './pipes/trip-status-cd.pipe';

const pipes = [
  DeliveryQualifierCdPipe,
  StopWindowCdPipe,
  TripStatusCdPipe,
  EquipmentPipe,
  RemoveLeadingZerosPipe,
  ActivityTypePipe,
  ActivityCdPipe,
  FilterPipe,
  PickupTypeCdPipe,
  BillClassCdPipe,
];

@NgModule({
  imports: [
    CommonModule,
    MatChipsModule,
    DragDropModule,
    DragScrollModule,
    ReactiveFormsModule,
    XpoDashbarModule,
    XpoFiltersModule,
    XpoIconModule,
    XpoDialogModule,
    XpoPopoverModule,
    XpoLtlPipeModule,
    XpoLtlShipmentDetailsModule,
    XpoLtlDialogTitleModule,
    XpoAllowCharactersModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    XpoButtonModule,
    MatTableModule,
    MatMenuModule,
    MatListModule,
    MatRadioModule,
    XpoTabsModule,
    MatSelectModule,
    MatInputModule,
    MatCardModule,
    MatTooltipModule,
    FormsModule,
    MatAutocompleteModule,
    AgmCoreModule,
    Ng2CarouselamosModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    ActionLinkCellRendererComponent,
    DeliveryWindowTimeRendererComponent,
    RouteActionLinkCellRendererComponent,
    RouteTimelineCellRendererComponent,
    RouteTimelineHeaderRendererComponent,
    GuaranteedIconComponent,
    HazmatIconComponent,
    DriverCollectIconComponent,
    LiftGateIconComponent,
    MetricBarComponent,
    MetricBarCardComponent,
    SpecialServicesComponent,
    SpecialServicesCellRendererComponent,
    StatusChipComponent,
    TripDetailsComponent,
    RecenterComponent,
    CenterSicButton,
    PolygonSelectionComponent,
    GeoFilterPolygonSelectionComponent,
    ZoomInButton,
    ZoomOutButton,
    RouteDetailsComponent,
    RouteSummaryComponent,
    StopDetailsComponent,
    ShipmentDetailsComponent,
    EmployeeDetailsComponent,
    MapToolbarWidgetComponent,
    MapZoomWidgetComponent,
    RefreshNotificationWidgetComponent,
    StopMapComponent,
    SicMarkerCompassComponent,
    MapWeatherComponent,
    TimeFilterComponent,
    CircleCellRendererComponent,
    AssignToRouteComponent,
    SaveChangesModalComponent,
    AssignToRouteDetailComponent,
    UnmappedStopsComponent,
    UnmappedStopDetailComponent,
    ReleaseRouteComponent,
    ReleaseRouteToggleCellRendererComponent,
    ResizeObserverDirective,
    TotalTextCellRendererComponent,
    SelectionSummaryComponent,
    UpdatedReleaseNotesComponent,
    ...pipes,
  ],
  exports: [
    GuaranteedIconComponent,
    HazmatIconComponent,
    DriverCollectIconComponent,
    LiftGateIconComponent,
    MetricBarComponent,
    MetricBarCardComponent,
    SpecialServicesComponent,
    SpecialServicesCellRendererComponent,
    StatusChipComponent,
    MapToolbarWidgetComponent,
    MapZoomWidgetComponent,
    RefreshNotificationWidgetComponent,
    SicMarkerCompassComponent,
    MapWeatherComponent,
    TimeFilterComponent,
    CircleCellRendererComponent,
    ReleaseRouteToggleCellRendererComponent,
    UnmappedStopsComponent,
    SelectionSummaryComponent,
    UpdatedReleaseNotesComponent,
    ...pipes,
  ],
  entryComponents: [
    ActionLinkCellRendererComponent,
    DeliveryWindowTimeRendererComponent,
    GuaranteedIconComponent,
    HazmatIconComponent,
    DriverCollectIconComponent,
    LiftGateIconComponent,
    RouteActionLinkCellRendererComponent,
    RouteSummaryComponent,
    RouteTimelineCellRendererComponent,
    RouteTimelineHeaderRendererComponent,
    CircleCellRendererComponent,
    SpecialServicesComponent,
    SpecialServicesCellRendererComponent,
    StatusChipComponent,
    StopMapComponent,
    TimeFilterComponent,
    CircleCellRendererComponent,
    AssignToRouteComponent,
    SaveChangesModalComponent,
    ReleaseRouteComponent,
    ReleaseRouteToggleCellRendererComponent,
    TotalTextCellRendererComponent,
    UpdatedReleaseNotesComponent,
  ],
  providers: [...pipes, DatePipe, DecimalPipe],
})
export class InboundPlanningSharedModule {}
