import { Action } from '@ngrx/store';
import { StopCard } from '../../inbound-planning/components/route-balancing/classes/stop-card.model';

export enum ActionTypes {
  SetOpenRouteBalancingPanel = 'RouteBalancing.SetOpenRouteBalancingPanel',
  SetManualSequencingRoutes = 'RouteBalancing.SetManualSequencingRoutes',
  SetPinFirst = 'RouteBalancing.SetPinFirst',
  SetPinLast = 'RouteBalancing.SetPinLast',
  SetLanesDirty = 'RouteBalancing.SetLanesDirty',
  SetCanOpenRouteBalancing = 'RouteBalancing.SetCanOpenRouteBalancing',
}

export class SetOpenRouteBalancingPanel implements Action {
  readonly type = ActionTypes.SetOpenRouteBalancingPanel;
  constructor(public payload: { openRouteBalancingPanel: boolean }) {}
}

export class SetManualSequencingRoutes implements Action {
  readonly type = ActionTypes.SetManualSequencingRoutes;
  constructor(public payload: { routes: number[] }) {}
}

export class SetPinFirst implements Action {
  readonly type = ActionTypes.SetPinFirst;
  constructor(public payload: { pinFirst: StopCard }) {}
}

export class SetPinLast implements Action {
  readonly type = ActionTypes.SetPinLast;
  constructor(public payload: { pinLast: StopCard }) {}
}

export class SetLanesDirty implements Action {
  readonly type = ActionTypes.SetLanesDirty;
  constructor(public payload: { lanesDirty: boolean }) {}
}

export class SetCanOpenRouteBalancing implements Action {
  readonly type = ActionTypes.SetCanOpenRouteBalancing;
  constructor(public payload: { canOpenRouteBalancing: boolean }) {}
}

export type Actions =
  | SetOpenRouteBalancingPanel
  | SetManualSequencingRoutes
  | SetPinFirst
  | SetPinLast
  | SetLanesDirty
  | SetCanOpenRouteBalancing;
