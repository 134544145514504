import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { UnassignedPickup } from '@xpo-ltl/sdk-cityoperations';
import { TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import {
  filter as _filter,
  find as _find,
  first as _first,
  forEach as _forEach,
  get as _get,
  invoke as _invoke,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  set as _set,
  size as _size,
} from 'lodash';
import { Subscription, timer } from 'rxjs';
import { debounceTime, filter, pairwise, startWith, take, takeUntil } from 'rxjs/operators';
import {
  GeoLocationStoreActions,
  PndStoreState,
  UnassignedPickupsStoreActions,
  UnassignedPickupsStoreSelectors,
} from '../../../../../store';
import { UnassignedPickupsService } from '../../../../shared';
import { StopMapComponent } from '../../../../shared/components/stop-map/stop-map.component';
import { UnmappedStopDetail } from '../../../../shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stop-detail.model';
import { UnmappedStopsEditMode } from '../../../../shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stops-edit-mode.enum';
import { StoreSourcesEnum } from '../../../../shared/enums/store-sources.enum';
import { EventItem, UnassignedPickupIdentifier } from '../../../../shared/interfaces/event-item.interface';
import { MapMarkerInfo } from '../../../../shared/models/map-marker-info.model';
import { InteractiveMapMarker } from '../../../../shared/models/markers/map-marker';
import { MapMarkerIcon } from '../../../../shared/models/markers/map-marker-icon.model';
import { UnassignedPickupMapMarkerCluster } from '../../../../shared/models/markers/unassigned-pickup-map-marker-cluster.model';
import { UnassignedPickupMapMarker } from '../../../../shared/models/markers/unassigned-pickup-map-marker.model';
import { MapMarkersService } from '../../../../shared/services/map-markers.service';
import { MappingService } from '../../../../shared/services/mapping.service';
import { StopWindowService } from '../../../../shared/services/stop-window.service';
import {
  ContextMenuItem,
  MarkerContextMenuComponent,
} from '../../components/marker-context-menu/marker-context-menu.component';
import { AbstractClusteredMarkerLayer, OverlappedMarkers, ProcessedMarkers } from '../abstract-clustered-marker-layer';

enum ContextMenuItemId {
  Zoom,
  EditGeoLocation,
}

@Component({
  selector: 'app-unassigned-pickups-layer',
  templateUrl: './unassigned-pickups-layer.component.html',
  styleUrls: ['./unassigned-pickups-layer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnassignedPickupsLayerComponent
  extends AbstractClusteredMarkerLayer<UnassignedPickupMapMarker, UnassignedPickupMapMarkerCluster>
  implements OnInit {
  @ViewChild(MarkerContextMenuComponent, { static: false }) contextMenu: MarkerContextMenuComponent;

  private delaySubscription: Subscription; // used to delay display of info window on focus

  contextMenuItems: ContextMenuItem<ContextMenuItemId>[] = [
    { id: ContextMenuItemId.Zoom, label: 'Satellite View' },
    { id: ContextMenuItemId.EditGeoLocation, label: 'Edit Geo Location' },
  ];

  constructor(
    private pndStore$: Store<PndStoreState.State>,
    private unassignedPickupsService: UnassignedPickupsService,
    private markersService: MapMarkersService,
    private stopWindowService: StopWindowService,
    private changeRef: ChangeDetectorRef,
    private mappingService: MappingService,
    private dialog: MatDialog
  ) {
    super(new UnassignedPickupMapMarkerCluster(), 'PU');
    this.clusteringEnabled = true;
  }

  ngOnInit() {
    // Handle updates to list of unassigned pickups
    this.unassignedPickupsService.unassignedPickups$
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((unassignedPickups: UnassignedPickup[]) => {
        const pickupMarkers = unassignedPickups
          .filter(
            (unassignedPickup: UnassignedPickup) =>
              unassignedPickup.shipper.latitudeNbr !== 0 && unassignedPickup.shipper.longitudeNbr !== 0
          )
          .map(
            (unassignedPickup: UnassignedPickup) =>
              new UnassignedPickupMapMarker(unassignedPickup, this.markersService, this.stopWindowService)
          );

        const markers: ProcessedMarkers<
          UnassignedPickupMapMarker,
          UnassignedPickupMapMarkerCluster
        > = this.processMarkers(pickupMarkers, 38);

        this.markersSubject.next(markers.singles);
        this.markerClustersSubject.next(markers.overlapped);
      });

    // Handle selecting Unassigned Pickups
    this.pndStore$
      .pipe(
        select(UnassignedPickupsStoreSelectors.unassignedPickupsSelected),
        filter(() => this.showMarkersSubject.value && this.googleMap),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((items: EventItem<UnassignedPickupIdentifier>[]) => {
        // ensure selected pickup markers are flagged as selected
        const zoom = this.googleMap ? this.googleMap.getZoom() : undefined;

        _forEach(this.markers, (marker: UnassignedPickupMapMarker) => {
          const isSelected = !!_find(items, (pickup) => _isEqual(pickup.id.pickupInstId, marker.pickup.pickupInstId));

          marker.isSelected = isSelected;

          this.markersService.updateUnassignedStopMarker(marker, zoom);
        });

        _forEach(
          this.markerClusters,
          (markerClusters: OverlappedMarkers<UnassignedPickupMapMarkerCluster, UnassignedPickupMapMarker>) => {
            _forEach(markerClusters.markers, (marker: UnassignedPickupMapMarker) => {
              const isSelected = !!_find(items, (pickup) =>
                _isEqual(pickup.id.pickupInstId, marker.pickup.pickupInstId)
              );

              marker.isSelected = isSelected;

              this.markersService.updateUnassignedStopMarker(marker, zoom, marker.icon.anchor);
            });
          }
        );

        // if there are selected pickups, fit them into bounds and center map to them
        if (_size(items) > 0 && items[items.length - 1].source !== StoreSourcesEnum.PLANNING_MAP) {
          this.mappingService.updateMarkersBounds(
            items.map((i) => i.id.shipper),
            this.googleMap
          );
        }

        this.changeRef.detectChanges();
      });

    // Handle focusing an unassigned pickup
    this.pndStore$
      .pipe(
        select(UnassignedPickupsStoreSelectors.unassignedPickupsFocused),
        filter(() => this.showMarkers),
        debounceTime(500),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((item: EventItem<UnassignedPickupIdentifier>) => {
        // ensure the focused pickups are selected and others are not
        const focusedPickupId: number = _get(item, 'id.pickupInstId');
        let focusedMarker: UnassignedPickupMapMarker;
        let clusterOfFocusedMarker: UnassignedPickupMapMarkerCluster;
        const markers = this.markersSubject.value;
        const markerClusters = this.markerClustersSubject.value;
        const zoom = this.googleMap ? this.googleMap.getZoom() : undefined;

        _forEach(markers, (marker: UnassignedPickupMapMarker) => {
          const isFocused = _isEqual(focusedPickupId, marker.pickup.pickupInstId);
          marker.isInfoWindowOpened = isFocused && marker.isInfoWindowOpened;
          marker.isFocused = isFocused;

          this.markersService.updateUnassignedStopMarker(marker, zoom);

          focusedMarker = isFocused ? marker : focusedMarker;
        });

        _forEach(
          markerClusters,
          (overlappedMarkers: OverlappedMarkers<UnassignedPickupMapMarkerCluster, UnassignedPickupMapMarker>) => {
            _forEach(overlappedMarkers.markers, (marker: UnassignedPickupMapMarker) => {
              const isFocused = _isEqual(focusedPickupId, marker.pickup.pickupInstId);
              marker.isInfoWindowOpened = isFocused && marker.isInfoWindowOpened;
              marker.isFocused = isFocused;

              this.markersService.updateUnassignedStopMarker(marker, zoom, marker.icon.anchor);

              if (isFocused) {
                focusedMarker = marker;
                clusterOfFocusedMarker = overlappedMarkers.clusterer;
              }
            });
          }
        );

        this.changeRef.detectChanges();

        _invoke(this.delaySubscription, 'unsubscribe');
        this.delaySubscription = timer(400).subscribe(() => {
          // open the info window for focused marker
          _set(clusterOfFocusedMarker, 'isSelected', true);
          _set(focusedMarker, 'isInfoWindowOpened', true);

          if (_get(item, 'source') !== StoreSourcesEnum.PLANNING_MAP) {
            this.mappingService.updateMarkersBounds([item.id.shipper], this.googleMap);
          }

          this.markersSubject.next(this.markers);
          this.markerClustersSubject.next(this.markerClusters);
        });

        this.markersSubject.next(markers);
        this.markerClustersSubject.next(markerClusters);
      });

    this.zoomLevel$.pipe(startWith(-1), pairwise(), takeUntil(this.unsubscriber.done)).subscribe(([lastZoom, zoom]) => {
      // update the markers when zoom level changes
      if (
        (lastZoom <= this.markersService.ZOOM_LEVEL && zoom >= this.markersService.ZOOM_LEVEL) ||
        (lastZoom >= this.markersService.ZOOM_LEVEL && zoom <= this.markersService.ZOOM_LEVEL)
      ) {
        this.markersSubject.value.forEach((marker) => {
          this.markersService.updateUnassignedStopMarker(marker, zoom);
        });

        this.markerClustersSubject.value.forEach((overlappedMarkers) => {
          // TODO: 18/38 should be defined in the marker class
          this.calculateVirtualPositionForClusteredMarkers(overlappedMarkers.markers, zoom < 7 ? 18 : 38);

          overlappedMarkers.markers.forEach((marker) => {
            this.markersService.updateUnassignedStopMarker(marker, zoom, marker.icon.anchor);
          });
        });
      }
    });
  }

  onMouseOver(marker: UnassignedPickupMapMarker) {
    this.pndStore$.dispatch(
      new UnassignedPickupsStoreActions.SetFocusedUnassignedPickupAction({
        focusedPickup: {
          id: {
            pickupInstId: marker.pickup.pickupInstId,
            shipper: {
              latitudeNbr: marker.pickup.shipper.geoCoordinates.latitude,
              longitudeNbr: marker.pickup.shipper.geoCoordinates.longitude,
            },
          },
          source: StoreSourcesEnum.PLANNING_MAP,
        },
      })
    );
  }

  onMouseOut(marker: UnassignedPickupMapMarker) {
    this.pndStore$.dispatch(
      new UnassignedPickupsStoreActions.SetFocusedUnassignedPickupAction({
        focusedPickup: {
          id: undefined,
          source: StoreSourcesEnum.PLANNING_MAP,
        },
      })
    );
  }

  onClick(marker: UnassignedPickupMapMarker) {
    // add/remove the Pickup from the list of selected Pickups
    this.pndStore$
      .pipe(select(UnassignedPickupsStoreSelectors.unassignedPickupsSelected), take(1))
      .subscribe((items: EventItem<UnassignedPickupIdentifier>[]) => {
        // Preserve previous selection
        let selectedPickups: EventItem<UnassignedPickupIdentifier>[] = [...items];
        if (!marker.isSelected) {
          const selectedPickup: EventItem<UnassignedPickupIdentifier> = {
            id: {
              pickupInstId: marker.pickup.pickupInstId,
              shipper: {
                latitudeNbr: marker.pickup.shipper.geoCoordinates.latitude,
                longitudeNbr: marker.pickup.shipper.geoCoordinates.longitude,
              },
            },
            source: StoreSourcesEnum.PLANNING_MAP,
          };
          if (!_find(selectedPickups, (item) => item.id.pickupInstId === selectedPickup.id.pickupInstId)) {
            selectedPickups.push(selectedPickup);
          }
          marker.isSelected = true;
        } else {
          selectedPickups = _filter(
            items,
            (unassignedPickup) => !_isEqual(unassignedPickup.id.pickupInstId, marker.pickup.pickupInstId)
          );
          marker.isSelected = false;
        }

        this.pndStore$.dispatch(
          new UnassignedPickupsStoreActions.SetSelectedUnassignedPickupsAction({
            selectedPickups: selectedPickups,
          })
        );
      });
  }

  onRightClick(marker: InteractiveMapMarker, event: MouseEvent) {
    this.contextMenu.openMenu(event.clientX, event.clientY).subscribe((item: ContextMenuItem<ContextMenuItemId>) => {
      if (item) {
        switch (item.id) {
          case ContextMenuItemId.Zoom:
            this.showSatelliteView(marker as UnassignedPickupMapMarker);
            break;

          case ContextMenuItemId.EditGeoLocation:
            this.editGeoLocation(marker as UnassignedPickupMapMarker);
            break;
        }
      }
    });
  }

  private editGeoLocation(marker: UnassignedPickupMapMarker) {
    const markerInfo: MapMarkerInfo = marker.markerInfo;

    const stopDetail: UnmappedStopDetail = {
      acctInstId: markerInfo.acctInstId,
      stopName: markerInfo.consigneeName,
      stopTypeCd: TripNodeActivityCd.PICKUP_SHIPMENTS,
      address: `${markerInfo.consigneeAddress}, ${markerInfo.consigneeCity}, ${markerInfo.consigneeState} ${markerInfo.consigneeZipCode}`,
      location: new google.maps.LatLng(marker.latitude, marker.longitude),
      isFutureCustomer: _isEmpty(markerInfo.acctMadCd),
    };
    this.pndStore$.dispatch(new GeoLocationStoreActions.SetEditMode(UnmappedStopsEditMode.UnassignedPickup));
    this.pndStore$.dispatch(new GeoLocationStoreActions.SetStopToEdit(stopDetail));
  }

  showSatelliteView(marker: UnassignedPickupMapMarker) {
    const consigneeName = _get(marker, 'pickup.shipper.name1');

    this.dialog.open(StopMapComponent, {
      data: {
        consigneeName,
        geoCoordinates: { latitude: marker.latitude, longitude: marker.longitude },
      },
      disableClose: true,
      hasBackdrop: false,
    });
  }

  onClusterMarkerClick(marker: UnassignedPickupMapMarkerCluster) {
    const markerClusters = this.markerClustersSubject.value;

    for (const overlappedMarkers of markerClusters) {
      if (marker.clusterMarkerId === overlappedMarkers.clusterer.clusterMarkerId) {
        overlappedMarkers.clusterer.isSelected = !overlappedMarkers.clusterer.isSelected;
        break;
      }
    }

    this.markerClustersSubject.next(markerClusters);
  }

  onClusterMarkerRightClick(marker: UnassignedPickupMapMarkerCluster, event: MouseEvent) {
    const markerClusters = this.markerClustersSubject.value;

    this.contextMenu.openMenu(event.clientX, event.clientY).subscribe((item: ContextMenuItem<ContextMenuItemId>) => {
      if (item) {
        switch (item.id) {
          case ContextMenuItemId.Zoom:
            for (const overlappedMarkers of markerClusters) {
              if (marker.clusterMarkerId === overlappedMarkers.clusterer.clusterMarkerId) {
                this.showSatelliteView(_first(overlappedMarkers.markers));
              }
            }
            break;
        }
      }
    });
  }

  getClusterMarkerIcon(markerType: string, clusteredMarkers: number): MapMarkerIcon {
    return this.markersService.getClusterMarkerIcon(markerType, clusteredMarkers);
  }

  trackMarkerBy(index, item: UnassignedPickupMapMarker) {
    if (!item) {
      return null;
    }
    return `${item.pickup.pickupInstId}-${item.latitude}-${item.longitude}`;
  }

  trackClusterBy(index, item: OverlappedMarkers<UnassignedPickupMapMarkerCluster, UnassignedPickupMapMarker>) {
    if (!item) {
      return null;
    }
    return item.clusterer.clusterMarkerId;
  }
}
