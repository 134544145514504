import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog/typings/dialog-ref';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { XpoNotificationTemplate } from '@xpo-ltl/ngx-ltl-core/lib/snack-bar/notification-template';
import {
  isObject as _isObject,
  has as _has,
  defaultTo as _defaultTo,
  get as _get,
  isEmpty as _isEmpty,
  isString as _isString,
  toString as _toString,
} from 'lodash';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ErrorDialogComponent } from '../../app/inbound-planning/shared/components/error-dialog/error-dialog.component';
import { GenericErrorLazyTypedModel } from '../../app/inbound-planning/shared/models/generic-error-lazy-typed.model';
import { ConfigManagerProperties, NotificationMessageStatus } from '../enums';

const DEFAULT_SNACKBAR_DURATION = 5000;

@Injectable({ providedIn: 'root' })
export class NotificationMessageService {
  constructor(
    private snackbar: XpoSnackBar,
    private configManagerService: ConfigManagerService,
    private dialog: MatDialog
  ) {}

  /**
   * Opens a snack bar (toast) message.
   */
  openSnackBar(
    message: string,
    status: NotificationMessageStatus,
    duration: number = DEFAULT_SNACKBAR_DURATION
  ): MatSnackBarRef<XpoNotificationTemplate> {
    switch (status) {
      case NotificationMessageStatus.Success:
        duration = this.configManagerService.getSetting<number>(ConfigManagerProperties.successToastDuration);
        break;
      case NotificationMessageStatus.Error:
        duration = this.configManagerService.getSetting<number>(ConfigManagerProperties.errorToastDuration);
        break;
    }
    return this.snackbar.open({
      message: message,
      detailedMessage: ``,
      status: status,
      action: undefined,
      matConfig: {
        duration,
        verticalPosition: 'bottom',
        panelClass: `pnd-Snackbar-${status}`,
      },
    });
  }

  /**
   * Opens a dialog (modal) with an error message and with more details about the error
   */
  openErrorDialog(error: GenericErrorLazyTypedModel): MatDialogRef<ErrorDialogComponent> {
    return this.dialog.open(ErrorDialogComponent, {
      data: error,
      disableClose: false,
      hasBackdrop: true,
      panelClass: 'error-snackbar-panel',
    });
  }

  openNotificationMessage(
    status: NotificationMessageStatus,
    message?: GenericErrorLazyTypedModel | string
  ): Observable<NotificationMessageStatus> {
    const error: GenericErrorLazyTypedModel = (message
      ? _isString(message)
        ? new GenericErrorLazyTypedModel({ error: { message: message } })
        : message
      : new GenericErrorLazyTypedModel()) as GenericErrorLazyTypedModel;

    return new Observable((observer) => {
      if (status === NotificationMessageStatus.Error && error.code === '503') {
        const dialogRef = this.openErrorDialog(error);
        dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe(() => {
            observer.next(status);
            observer.complete();
          });
      } else {
        const snackRef = this.openSnackBar(this.parseErrorMessage(error), status);
        snackRef
          .afterDismissed()
          .pipe(take(1))
          .subscribe(() => {
            observer.next(status);
            observer.complete();
          });
      }
    });
  }

  // Parse error response from an API call.  Unfortunately, top level error response is not strongly typed yet.
  // An example error message:
  /*
  {
    "code":"400",
    "transactionTimestamp":1572446503417,
    "error":{
      "errorCode":"SCON021-596E",
      "message":"Validation Errors Found.",
      "trace":"at com.xpo.ltl.api.exception.AbstractExceptionBuilder.newValidationException(AbstractExceptionBuilder.java:304)",
      "moreInfo":[{
        "message":"Cannot reassign a stop to a trip that is not in New status",
        "location":"ReassignStopsValidator.validate"
      }]
    }}
  */
  parseErrorMessage(fault: GenericErrorLazyTypedModel | string | number): string {
    if (_get(fault, 'code', '').startsWith('4')) {
      let message: string = _get(fault, 'error.message', '');
      _get(fault, 'error.moreInfo', []).forEach((moreInfo) => {
        if (!message.endsWith('.')) {
          message += '.';
        }
        message += ` ${moreInfo.message}`;
      });
      return message;
    } else {
      if (_has(fault, 'error.message')) {
        fault = _get(fault, 'error.message');
      } else if (_isObject(fault)) {
        const auxFault = _isEmpty(fault) ? undefined : JSON.stringify(fault);

        return _toString(_defaultTo(auxFault, 'Unknown Error'));
      }

      return _toString(_defaultTo(fault, 'Unknown Error'));
    }
  }
}
