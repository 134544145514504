import { Action } from '@ngrx/store';

export enum ActionTypes {
  setSic = 'globalFilter.setSic',
  setSicLatLng = 'globalFilter.setSicLatLng',
  setPlanDate = 'globalFilter.setPlanDate',
  resetPreferences = 'globalFilter.resetPreferences',
}

export class SetSicAction implements Action {
  readonly type = ActionTypes.setSic;
  constructor(public payload: { sic: string }) {}
}

export class SetSicLatLngAction implements Action {
  readonly type = ActionTypes.setSicLatLng;
  constructor(public payload: { sicLatLng: { latitude: number; longitude: number } }) {}
}

export class SetPlanDateAction implements Action {
  readonly type = ActionTypes.setPlanDate;
  constructor(public payload: { planDate: Date }) {}
}

export class ResetPreferences implements Action {
  readonly type = ActionTypes.resetPreferences;
  constructor() {}
}

export type Actions = SetSicAction | SetSicLatLngAction | SetPlanDateAction | ResetPreferences;
