import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import {
  CityOperationsApiService,
  RegisterFilterRoutesResp,
  RegisterFilterRoutesRqst,
  Route,
  RouteFilter,
  UnregisterFilterRoutesRqst,
} from '@xpo-ltl/sdk-cityoperations';
import { RouteCategoryCd, RouteStatusCd, XrtAttributeFilter, ZoneIndicatorCd } from '@xpo-ltl/sdk-common';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { XrtChangedDocuments } from '@xpo/ngx-xrt';
import { XrtFireMessagingService, XrtFireMessagingTokenService } from '@xpo/ngx-xrt-firebase';
import {
  forEach as _forEach,
  get as _get,
  defaultTo as _defaultTo,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  size as _size,
} from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, takeUntil, debounceTime, tap, map } from 'rxjs/operators';
import { GlobalFilterStoreSelectors, PndStoreState, RoutesStoreActions } from '../../../store';
import { BoardStateSource } from '../../../store/board-state-source';
import { RoutesSearchCriteria } from '../../../store/routes-store/routes-search-criteria.interface';
import { PlanningRoutesCacheService } from '../../shared/services/planning-routes-cache.service';

interface FilterParameters {
  sicCd: string;
  planDate: string;
  specialServices: string[];
}

@Injectable()
export class RoutePlanningDataSource extends XpoBoardDataSource implements OnDestroy {
  private unsubscriber: Unsubscriber = new Unsubscriber();
  private currentFilterHash: string = '';
  private currentFilterParameters: FilterParameters = null;

  constructor(
    private cityOperationsService: CityOperationsApiService,
    private pndStore$: Store<PndStoreState.State>,
    private messagingTokenService: XrtFireMessagingTokenService,
    private xrtFireMessagingService: XrtFireMessagingService,
    private planningRoutesCacheService: PlanningRoutesCacheService
  ) {
    super();

    this.subscribeToStoreChanges();
    this.subscribeToAutoRefresh();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
    this.unregisterFilter(this.currentFilterHash);
  }

  // #region Auto-refresh

  /**
   * Listen for remote updates notifying us of document changes
   */
  private subscribeToAutoRefresh() {
    // listen for updates from Firebase
    this.xrtFireMessagingService
      .changedDocument$()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((changedDocuments: XrtChangedDocuments) => {
        this.updateRefreshedDocuments(changedDocuments);
      });

    // listen for background web-worker updates
    new BroadcastChannel('pnd-notification-broadcast-channel').onmessage = (event) => {
      const changedDocuments = _get(event, 'data.data') as XrtChangedDocuments;
      this.updateRefreshedDocuments(changedDocuments);
    };
  }

  private updateRefreshedDocuments(changedDocuments: XrtChangedDocuments) {
    // TODO - is this correct?  Do we want to clear out the documents if the filterHashes don't match?
    if (_get(changedDocuments, 'filterHash') === this.currentFilterHash) {
      this.pndStore$.dispatch(new RoutesStoreActions.SetChangedRoutes({ changedRoutes: changedDocuments.documents }));
    }
  }

  private registerFilter(filterParameters: FilterParameters): void {
    this.messagingTokenService.getToken$().subscribe((token) => {
      const request = new RegisterFilterRoutesRqst();
      request.connectionToken = token;
      request.filter = new RouteFilter();
      request.filter.sicCd = { ...new XrtAttributeFilter(), equals: filterParameters.sicCd };
      request.filter.planDate = { ...new XrtAttributeFilter(), equals: filterParameters.planDate };
      request.filter.specialServices = { ...new XrtAttributeFilter(), values: filterParameters.specialServices };

      this.cityOperationsService.registerFilterRoutes(request).subscribe((response: RegisterFilterRoutesResp) => {
        this.currentFilterHash = response.filterHash;
      });
    });
  }

  private unregisterFilter(filterHash: string): void {
    if (filterHash) {
      this.messagingTokenService.getToken$().subscribe((token) => {
        const request = new UnregisterFilterRoutesRqst();
        request.connectionToken = token;
        request.filterHash = filterHash;

        this.cityOperationsService.unregisterFilterRoutes(request).subscribe();
      });
    }
  }

  // #endregion

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    const computeTotals = (routes: Route[]) => {
      let totalBills = 0;
      let totalMM = 0;
      let totalWeight = 0;
      _forEach(_defaultTo(routes, []), (route) => {
        totalBills += _get(route, 'totalBillCount', 0);
        totalMM += _get(route, 'totalMmCount', 0);
        totalWeight += _get(route, 'totalWeightCount', 0);
      });
      return [{ totalBillCount: totalBills, totalMmCount: totalMM, totalWeightCount: totalWeight }];
    };

    if (state.changes.includes('viewId')) {
      // When switching views, we need to wait until the Store updates the list.
      // however, fetchData is called before refresh, so we end up getting the previous view's
      // list, which causes the view to display incorrect data initially until the refresh occurs.
      // To combat that, return an empty data set when changing views. fetchData will be called
      // again once the Store is updated
      return of(new XpoBoardData(state, [], 0, 10000));
    } else {
      // fetch the latest Routes from the Store to provide to the Board
      const routes: Route[] = this.planningRoutesCacheService.getAllPlanningRoutes();
      return of(new XpoBoardData(state, { rows: routes, totals: computeTotals(routes) }, _size(routes), 10000));
    }
  }

  /**
   * Update the Grid to reflect the current state of the Redux Store
   */
  private subscribeToStoreChanges() {
    // Update the search criteria when view is activated, sic, planData, filters, or sort is changed
    combineLatest([
      this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic),
      this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterPlanDate),
      this.state$.pipe(
        filter(
          (state) =>
            state.source === 'BOARD-ACTIVATING-VIEW' ||
            state.source === 'FILTER-CHANGE' ||
            state.source === 'ACTIVE-VIEW-CHANGE' ||
            state.source === 'ADD-NEW-VIEW'
        )
      ),
    ])
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(([sicCd, newPlanDate, boardState]) => {
        this.updateSearchCriteria(sicCd, newPlanDate, boardState);
      });

    // refresh the data when requested
    this.state$
      .pipe(
        filter((state) => state.source === 'DATA SOURCE REFRESH'),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(() => {
        this.pndStore$.dispatch(new RoutesStoreActions.RefreshPlanningRoutes());
      });
  }

  /**
   * Send action to update the filters used to fetch Route data.  This will update the Store state with
   * the new routes that match the filter criteriea
   */
  private updateSearchCriteria(sicCd: string, planDate: Date, state: XpoBoardState) {
    const buildStatusCodes = (statuses: string[]) => {
      const statusesValue = statuses || [];
      const statusesFilter = [];
      if (statusesValue.some((s) => s === 'UNRELEASED')) {
        statusesFilter.push(RouteStatusCd.UNRELEASED);
      }
      if (statusesValue.some((s) => s === 'RELEASED')) {
        statusesFilter.push(RouteStatusCd.RELEASED);
      }
      if (statusesValue.some((s) => s === 'LOADING')) {
        statusesFilter.push(RouteStatusCd.LOADING);
      }
      if (statusesValue.some((s) => s === 'CLOSED')) {
        statusesFilter.push(RouteStatusCd.CLOSED);
      }
      if (statusesValue.some((s) => s === 'DISPATCHED')) {
        statusesFilter.push(RouteStatusCd.DISPATCHED);
      }
      if (statusesValue.some((s) => s === 'RETURNING')) {
        statusesFilter.push(RouteStatusCd.RETURNING);
      }
      if (statusesValue.some((s) => s === 'COMPLETED')) {
        statusesFilter.push(RouteStatusCd.COMPLETE);
      }
      return statusesFilter;
    };

    const criteria: RoutesSearchCriteria = {
      sicCd: sicCd,
      planDate: moment(planDate)
        .add('years', 1) // UI Hack for PCT-3923: Don't limit planning routes by date
        .format('YYYY-MM-DD'),
      zoneInd: ZoneIndicatorCd.NO_ZONES,
      plannerInd: null,
      newReleaseInd: null,
      categoryCd: [RouteCategoryCd.PLANNING, RouteCategoryCd.DOCK],
      statusCd: buildStatusCodes(state.criteria['statusCd']),
      specialServices: state.criteria['special-services'],
    };

    const filterParameters: FilterParameters = {
      sicCd: criteria.sicCd,
      planDate: criteria.planDate,
      specialServices: criteria.specialServices,
    };

    if (!_isEqual(filterParameters, this.currentFilterParameters) && !_isEmpty(filterParameters.sicCd)) {
      this.unregisterFilter(this.currentFilterHash);
      this.registerFilter(filterParameters);
      this.currentFilterParameters = filterParameters;
    }

    this.pndStore$.dispatch(new RoutesStoreActions.SetSearchCriteria({ criteria }));
  }
}
