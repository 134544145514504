import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { DeliveryShipmentSearchRecord, UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { ShipmentId } from '@xpo-ltl/sdk-common';
import { XpoLtlShipmentDescriptor } from '@xpo/ngx-ltl';
import {
  differenceBy as _differenceBy,
  first as _first,
  forEach as _forEach,
  get as _get,
  invoke as _invoke,
  isEqual as _isEqual,
  map as _map,
  pick as _pick,
  set as _set,
  size as _size,
  some as _some,
  unionBy as _unionBy,
  uniqWith as _uniqWith,
  forOwn as _forOwn,
} from 'lodash';
import { forkJoin, Subscription, timer } from 'rxjs';
import { debounceTime, pairwise, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { PndDialogService } from '../../../../../../core/dialogs/pnd-dialog.service';
import { PndStoreState, RoutesStoreActions, RoutesStoreSelectors } from '../../../../../store';
import { RouteBalancingSelectors } from '../../../../../store/route-balancing-store';
import { AssignToRouteComponent, MapToolbarService, RouteService } from '../../../../shared';
import { AssignToRouteDialogData } from '../../../../shared/components/assign-to-route/assign-to-route-dialog-data';
import { ExistingOrNewRoute } from '../../../../shared/components/assign-to-route/existing-or-new-route.enum';
import { StopMapComponent } from '../../../../shared/components/stop-map/stop-map.component';
import { StoreSourcesEnum } from '../../../../shared/enums/store-sources.enum';
import {
  EventItem,
  PlanningRouteShipmentIdentifier,
  routeStopToId,
  UnassignedDeliveryIdentifier,
  consigneeToId,
} from '../../../../shared/interfaces/event-item.interface';
import { UnassignedDeliveryMapMarker, UnassignedDeliveryShipmentsMapMarker } from '../../../../shared/models';
import { InteractiveMapMarker } from '../../../../shared/models/markers/map-marker';
import { MapMarkersService } from '../../../../shared/services/map-markers.service';
import { MappingService } from '../../../../shared/services/mapping.service';
import { PlanningRoutesCacheService } from '../../../../shared/services/planning-routes-cache.service';
import { RouteColorService } from '../../../../shared/services/route-color.service';
import { SpecialServicesService } from '../../../../shared/services/special-services.service';
import { DELIVERY_COLOR } from '../../../../shared/services/stop-colors';
import { StopWindowService } from '../../../../shared/services/stop-window.service';
import {
  ContextMenuItem,
  MarkerContextMenuComponent,
} from '../../components/marker-context-menu/marker-context-menu.component';
import { AbstractMarkerLayer } from '../abstract-marker-layer';

enum ContextMenuItemId {
  Reassign,
  Unassign,
  ShowDetails,
  Zoom,
}

@Component({
  selector: 'app-planning-route-shipments-layer',
  templateUrl: './planning-route-shipments-layer.component.html',
  styleUrls: ['./planning-route-shipments-layer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanningRouteShipmentsLayerComponent extends AbstractMarkerLayer<UnassignedDeliveryShipmentsMapMarker>
  implements OnInit {
  @ViewChild(MarkerContextMenuComponent, { static: false }) contextMenu: MarkerContextMenuComponent;

  private delaySubscription: Subscription; // used to delay display of info window on focus

  private isRouteBalancerOpen: boolean = false;

  contextMenuItems: ContextMenuItem<ContextMenuItemId>[] = [
    { id: ContextMenuItemId.Unassign, label: 'Unassign', shouldHide: () => this.isRouteBalancerOpen },
    { id: ContextMenuItemId.Reassign, label: 'Reassign', shouldHide: () => this.isRouteBalancerOpen },
    { id: ContextMenuItemId.ShowDetails, label: 'Shipment Details' },
    { id: ContextMenuItemId.Zoom, label: 'Satellite View' },
  ];

  constructor(
    private pndStore$: Store<PndStoreState.State>,
    private markersService: MapMarkersService,
    private stopWindowService: StopWindowService,
    private mappingService: MappingService,
    private pndDialogService: PndDialogService,
    private dialog: MatDialog,
    private specialServicesService: SpecialServicesService,
    private mapToolbarService: MapToolbarService,
    private routeService: RouteService,
    private routeColorService: RouteColorService,
    private planningRoutesCacheService: PlanningRoutesCacheService,
    private changeRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    // Handle updates
    this.pndStore$
      .select(RoutesStoreSelectors.stopsForSelectedPlanningRoutesLastUpdate)
      .pipe(
        switchMap(() => this.pndStore$.select(RoutesStoreSelectors.selectedPlanningRoutes)),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((selectedRoutesIds: number[]) => {
        let markerCandidates: UnassignedStop[] = [];

        _forEach(selectedRoutesIds, (routeInstId: number) => {
          const stopsForSelectedRoutes = this.planningRoutesCacheService.getStopsForRoute(routeInstId);
          if (_size(stopsForSelectedRoutes) > 0) {
            markerCandidates = [...markerCandidates, ...stopsForSelectedRoutes];
          }
        });

        const newMarkers = markerCandidates
          .filter(
            (unassignedStop: UnassignedStop) =>
              _get(unassignedStop, 'consignee.geoCoordinates.latitude', 0) !== 0 &&
              _get(unassignedStop, 'consignee.geoCoordinates.longitude', 0) !== 0
          )
          .map((unassignedStop: UnassignedStop) => {
            const routeInstId = _get(unassignedStop, 'deliveryShipments[0].routeInstId');
            const color = routeInstId ? this.routeColorService.getColorForRoute(routeInstId) : DELIVERY_COLOR;
            return new UnassignedDeliveryShipmentsMapMarker(
              unassignedStop,
              this.markersService,
              this.stopWindowService,
              this.specialServicesService,
              color
            );
          });

        this.markersSubject.next(newMarkers);
      });

    // Handle selection
    this.pndStore$
      .select(RoutesStoreSelectors.selectedPlanningRoutesShipments)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((selectedShipments: PlanningRouteShipmentIdentifier[]) => {
        if (this.googleMap) {
          // ensure selected markers are flagged as selected
          const zoom = this.googleMap.getZoom();

          const selectedStopIds = _map(
            _uniqWith(selectedShipments, (a, b) => routeStopToId(a) === routeStopToId(b)),
            (stop) => routeStopToId(stop)
          );

          _forEach(this.markers, (marker: UnassignedDeliveryShipmentsMapMarker) => {
            const markerStopId = routeStopToId(_first(marker.rowData.deliveryShipments));
            const isSelected = _some(selectedStopIds, (selStopId) => _isEqual(selStopId, markerStopId));

            marker.isSelected = isSelected;
            this.markersService.updateUnassignedStopMarker(marker, zoom);
          });

          // if there are selected deliveries, fit them into bounds and center map to them
          this.mappingService.updateMarkersBounds(
            selectedShipments.map((s) => s.consignee),
            this.googleMap
          );

          this.changeRef.detectChanges();
        }
      });

    // Handle focusing
    this.pndStore$
      .select(RoutesStoreSelectors.planningRouteShipmentFocused)
      .pipe(debounceTime(250), takeUntil(this.unsubscriber.done))
      .subscribe((item: EventItem<UnassignedDeliveryIdentifier>) => {
        // ensure the focused deliveries are selected and others are not
        const focusedDeliveryId = consigneeToId(item.id);
        let focusedMarker: UnassignedDeliveryShipmentsMapMarker;
        const markers = this.markers;
        const zoom = this.googleMap ? this.googleMap.getZoom() : undefined;

        _forEach(markers, (marker: UnassignedDeliveryShipmentsMapMarker) => {
          const isFocused = _isEqual(focusedDeliveryId, consigneeToId(marker.rowData));
          marker.isInfoWindowOpened = isFocused && marker.isInfoWindowOpened;
          marker.isFocused = isFocused;

          this.markersService.updateUnassignedStopMarker(marker, zoom);

          focusedMarker = isFocused ? marker : focusedMarker;
        });

        _invoke(this.delaySubscription, 'unsubscribe');
        this.delaySubscription = timer(400).subscribe(() => {
          // open the info window for focused marker
          _set(focusedMarker, 'isInfoWindowOpened', true);

          if (item && item.source && item.source !== StoreSourcesEnum.PLANNING_MAP) {
            this.mappingService.updateMarkersBounds([_get(item, 'id.consignee')], this.googleMap);
          }

          this.markersSubject.next(this.markers);
        });

        this.markersSubject.next(markers);
      });

    this.zoomLevel$.pipe(startWith(-1), pairwise(), takeUntil(this.unsubscriber.done)).subscribe(([lastZoom, zoom]) => {
      // update the markers when zoom level changes
      if (
        (lastZoom <= this.markersService.ZOOM_LEVEL && zoom >= this.markersService.ZOOM_LEVEL) ||
        (lastZoom >= this.markersService.ZOOM_LEVEL && zoom <= this.markersService.ZOOM_LEVEL)
      ) {
        this.markersSubject.value.forEach((marker) => {
          this.markersService.updateUnassignedStopMarker(marker, zoom);
        });
        this.changeRef.detectChanges();
      }
    });

    this.pndStore$
      .select(RouteBalancingSelectors.openRouteBalancingPanel)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((isOpen) => {
        this.isRouteBalancerOpen = isOpen;
      });
  }

  onMouseOver(marker: UnassignedDeliveryShipmentsMapMarker) {
    const routeInstId = _get(marker.rowData, 'deliveryShipments[0].routeInstId');
    this.pndStore$.dispatch(
      new RoutesStoreActions.SetFocusedPlanningRouteShipmentAction({
        focusedPlanningRouteShipment: {
          id: {
            routeInstId: routeInstId,
            consignee: _pick(marker.rowData.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
          },
          source: StoreSourcesEnum.PLANNING_MAP,
        },
      })
    );
  }

  onMouseOut(marker: UnassignedDeliveryShipmentsMapMarker) {
    this.pndStore$.dispatch(
      new RoutesStoreActions.SetFocusedPlanningRouteShipmentAction({
        focusedPlanningRouteShipment: {
          id: undefined,
          source: StoreSourcesEnum.PLANNING_MAP,
        },
      })
    );
  }

  onClick(marker: UnassignedDeliveryShipmentsMapMarker) {
    // add/remove the delivery from the list of selected deliveries
    this.pndStore$
      .select(RoutesStoreSelectors.selectedPlanningRoutesShipments)
      .pipe(take(1))
      .subscribe((selectedShipments: PlanningRouteShipmentIdentifier[]) => {
        // get all shipments for this Stop
        const stopShipments = _map(marker.rowData.deliveryShipments, (delShip) => {
          const item: PlanningRouteShipmentIdentifier = {
            shipmentInstId: delShip.shipmentInstId,
            routeInstId: delShip.routeInstId,
            consignee: _pick(delShip.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
          };
          return item;
        }) as PlanningRouteShipmentIdentifier[];

        // Preserve previous selection
        if (!marker.isSelected) {
          // add all shipments at Stop to selectedShipments
          selectedShipments = _unionBy(selectedShipments, stopShipments, 'shipmentInstId');
          marker.isSelected = true;
        } else {
          // remove all shipments at Stop from selectedShipments
          selectedShipments = _differenceBy(selectedShipments, stopShipments, 'shipmentInstId');
          marker.isSelected = false;
        }

        this.pndStore$.dispatch(
          new RoutesStoreActions.SetSelectedPlanningRoutesShipmentsAction({
            selectedPlanningRoutesShipments: selectedShipments,
          })
        );
      });
  }

  onRightClick(marker: InteractiveMapMarker, event: MouseEvent) {
    this.contextMenu.openMenu(event.clientX, event.clientY).subscribe((item: ContextMenuItem<ContextMenuItemId>) => {
      if (item) {
        switch (item.id) {
          case ContextMenuItemId.ShowDetails:
            this.showDetails(marker as UnassignedDeliveryShipmentsMapMarker);
            break;

          case ContextMenuItemId.Zoom:
            this.showSatelliteView(marker as UnassignedDeliveryShipmentsMapMarker);
            break;

          case ContextMenuItemId.Reassign:
            this.openAssignToRouteDialog(marker as UnassignedDeliveryMapMarker);
            break;

          case ContextMenuItemId.Unassign:
            this.openUnassignRouteDialog(marker as UnassignedDeliveryMapMarker);
        }
      }
    });
  }

  showDetails(marker: UnassignedDeliveryShipmentsMapMarker) {
    const shipmentsAtStop = _map(_get(marker, 'rowData.deliveryShipments'), (record: DeliveryShipmentSearchRecord) => {
      return {
        proNbr: record.proNbr,
        shipmentInstId: record.shipmentInstId,
      } as XpoLtlShipmentDescriptor;
    });

    this.pndDialogService.showShipmentDetailsDialog(shipmentsAtStop).subscribe();
  }

  showSatelliteView(marker: UnassignedDeliveryShipmentsMapMarker) {
    const consigneeName = _get(marker, 'markerInfo.consigneeName');

    this.dialog.open(StopMapComponent, {
      data: {
        consigneeName,
        geoCoordinates: { latitude: marker.latitude, longitude: marker.longitude },
      },
      disableClose: true,
      hasBackdrop: false,
    });
  }

  private openAssignToRouteDialog(marker: UnassignedDeliveryMapMarker): void {
    const shipmentsAtStop = _get(marker, 'rowData.deliveryShipments', []) as DeliveryShipmentSearchRecord[];
    const acctInstId = _get(marker, 'rowData.consignee.acctInstId', -1);
    const selectedShipments = new Map<number, DeliveryShipmentSearchRecord[]>();
    selectedShipments.set(acctInstId, shipmentsAtStop);
    const selectedPlanningShipments = new Map<PlanningRouteShipmentIdentifier, DeliveryShipmentSearchRecord[]>();

    const key = _pick(shipmentsAtStop[0], ['shipmentInstId', 'routeInstId', 'consignee']);
    selectedPlanningShipments.set(key, shipmentsAtStop);

    const dialogRef = this.dialog.open(AssignToRouteComponent, {
      data: <AssignToRouteDialogData>{
        initialMode: ExistingOrNewRoute.Existing,
        selectedPlanningShipments: selectedPlanningShipments,
      },
      disableClose: true,
      hasBackdrop: true,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((actionPerformed) => {
        if (actionPerformed) {
          this.mapToolbarService.toggleDrawModeOff();
        }
      });
  }

  private openUnassignRouteDialog(marker: UnassignedDeliveryMapMarker): void {
    const { deliveryShipments } = marker.rowData;
    if (deliveryShipments && deliveryShipments.length > 0) {
      const requests = [];
      const routeName = `${deliveryShipments[0].routePrefix}-${deliveryShipments[0].routeSuffix}`;
      const routeInstId = deliveryShipments[0].routeInstId;
      const shipmentList: ShipmentId[] = deliveryShipments.map((shipment) => {
        return <ShipmentId>{
          shipmentInstId: `${shipment.shipmentInstId}`,
          proNumber: shipment.proNbr,
        };
      });
      requests.push(this.routeService.unassignShipments(routeName, routeInstId, shipmentList));
      forkJoin(requests)
        .pipe(take(1))
        .subscribe((results) => {
          this.pndStore$.dispatch(new RoutesStoreActions.RefreshPlanningRoutes());
        });
    }
  }

  trackMarkerBy(index, item: UnassignedDeliveryShipmentsMapMarker) {
    if (!item) {
      return null;
    }
    return `${item.rowData.consignee.acctInstId}-${item.latitude}-${item.longitude}`;
  }
}
