import { PlanningProfileInterface } from './planning-profile.interface';

export const StateKey = 'planningProfilesState';

export interface State {
  readonly planningProfiles: PlanningProfileInterface[];
}

export const initialState: State = {
  planningProfiles: [],
};
