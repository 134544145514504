import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatCheckboxModule,
} from '@angular/material';
import { XpoBoardModule } from '@xpo-ltl/ngx-ltl-board';
import { XpoGMapBoardModule } from '@xpo-ltl/ngx-ltl-board-gmaps';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoButtonModule, XpoCardModule, XpoIconModule } from '@xpo-ltl/ngx-ltl-core';
import { AgGridModule } from 'ag-grid-angular';
import { InboundPlanningSharedModule } from '../../shared';
import { pndFrameworkComponentsDeclarations } from '../../shared/components/frameworkComponents';
import { UnassignedDeliveriesHeaderComponent } from './unassigned-deliveries-header/unassigned-deliveries-header.component';
import { UnassignedDeliveriesShipmentsComponent } from './unassigned-deliveries-shipments/unassigned-deliveries-shipments.component';
import { UnassignedDeliveriesStopsComponent } from './unassigned-deliveries-stops/unassigned-deliveries-stops.component';
import { UnassignedDeliveriesComponent } from './unassigned-deliveries.component';

@NgModule({
  declarations: [
    UnassignedDeliveriesComponent,
    UnassignedDeliveriesHeaderComponent,
    UnassignedDeliveriesStopsComponent,
    UnassignedDeliveriesShipmentsComponent,
  ],
  exports: [UnassignedDeliveriesComponent],
  entryComponents: [UnassignedDeliveriesComponent],
  imports: [
    AgGridModule.withComponents(pndFrameworkComponentsDeclarations),
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    XpoButtonModule,
    XpoBoardModule,
    XpoAgGridBoardModule,
    XpoCardModule,
    XpoGMapBoardModule,
    XpoIconModule,
    InboundPlanningSharedModule,
  ],
})
export class UnassignedDeliveriesModule {}
