import { Injectable } from '@angular/core';
import {
  Activity,
  Route,
  RouteBalancingActivity,
  RouteBalancingStop,
  RouteIdentifier,
  Stop,
} from '@xpo-ltl/sdk-cityoperations';
import { LatLong, NodeTypeCd, StopWindow, TripNodeActivityCd, TripNodeTypeCd, TripStatusCd } from '@xpo-ltl/sdk-common';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { forOwn as _forOwn, get as _get, groupBy as _groupBy, has as _has } from 'lodash';
import * as moment from 'moment-timezone';
import { take } from 'rxjs/operators';
import { NumberToValueMap } from '../../../../../store/number-to-value-map';
import { ActivityCdPipe, SpecialServicesService, StopWindowService } from '../../../../shared';
import { MapUtils } from '../../../../shared/classes/map-utils';
import { ResequenceService } from '../../../../shared/services/resequence.service';
import { RouteColorService } from '../../../../shared/services/route-color.service';
import { TripPlanningGridItem } from '../../../trip-planning/models/trip-planning-grid-item.model';
import { RouteBoardLane } from '../board/route-board-lane.model';
import { StopCard } from '../stop-card.model';

@Injectable({
  providedIn: 'root',
})
export class RouteBalancingBuilderService {
  constructor(
    private timeService: XpoLtlTimeService,
    private stopWindowService: StopWindowService,
    private specialServicesService: SpecialServicesService,
    private resequenceService: ResequenceService,
    private routeColorService: RouteColorService,
    private loggingService: LoggingApiService,
    private activityCdPipe: ActivityCdPipe
  ) {}

  /**
   * Build a lane for the current Route
   */
  buildLane(
    currentRoute: Route,
    stops: Stop[],
    driverName: string,
    tripStatusCd: TripStatusCd,
    currentSic: string,
    planDate: Date
  ): RouteBoardLane {
    const origin: Stop = stops.find(
      (stop) => stop.tripNode.nodeTypeCd === 'ServiceCenter' && stop.tripNode.tripNodeTypeCd === 'Origin'
    );

    const destination: Stop = stops.find(
      (stop) => stop.tripNode.nodeTypeCd === 'ServiceCenter' && stop.tripNode.tripNodeTypeCd === 'Destination'
    );

    const lane = new RouteBoardLane(currentRoute.routeInstId, currentRoute.tripInstId);
    lane.routePrefix = _get(currentRoute, 'routePrefix');
    lane.routeSuffix = _get(currentRoute, 'routeSuffix');
    lane.routeStatusCd = _get(currentRoute, 'statusCd');
    lane.routeCategoryCd = _get(currentRoute, 'categoryCd');

    lane.estimatedEmptyDateTime = new Date(); // default value

    if (_has(origin, 'tripNode.estimatedDepartDateTimeLocal')) {
      lane.tripStartDate = moment(origin.tripNode.estimatedDepartDateTimeLocal).format('MM/DD/YYYY');
      this.timeService
        .timezoneForSicCd$(currentSic)
        .pipe(take(1))
        .subscribe((timeZone) => {
          lane.startTime$.next(
            moment(origin.tripNode.estimatedDepartDateTimeLocal)
              .tz(timeZone)
              .format('HH:mm')
          );
        });
    } else {
      lane.tripStartDate = moment(planDate).format('MM/DD/YYYY');
      const deliveryRouteDepartTime = _get(currentRoute, 'deliveryRouteDepartTime');
      lane.startTime$.next(deliveryRouteDepartTime === '00:00' ? undefined : deliveryRouteDepartTime);
    }

    const stopCards: StopCard[] = [];
    stops = stops.filter(
      (stop) => stop.tripNode.nodeTypeCd !== 'ServiceCenter' && stop.tripNode.tripNodeTypeCd !== 'Origin'
    );
    stops.forEach((stop: Stop) => {
      const groupedActivities = _groupBy(<Activity[]>_get(stop, 'activities', []), (activity: Activity) =>
        _get(activity, 'tripNodeActivity.activityCd', '')
      );

      let totalWeightCount = 0;
      let totalMmCount = 0;
      let totalShipmentsCount = 0;
      let tripNodeActivityCd: TripNodeActivityCd;
      _get(stop, 'activities', []).forEach((activity) => {
        if (_has(activity, 'routeShipment')) {
          totalWeightCount += _get(activity, 'tripNodeActivity.totalWeightCount', 0);
          totalMmCount += _get(activity, 'tripNodeActivity.totalMmCount', 0);
          totalShipmentsCount++;
          tripNodeActivityCd = _get(activity, 'tripNodeActivity.activityCd', TripNodeActivityCd.DELIVER_SHIPMENT);
        }
      });

      delete groupedActivities[TripNodeActivityCd.ARRIVE];
      delete groupedActivities[TripNodeActivityCd.DEPART_DISPATCH];

      const stopCard = new StopCard();
      stopCard.tripNodeInstId = _get(stop, 'tripNode.nodeInstId');
      stopCard.routeInstId = currentRoute.routeInstId;
      stopCard.origRouteInstId = currentRoute.routeInstId;
      stopCard.tripInstId = _get(stop, 'tripNode.tripInstId');
      stopCard.origTripInstId = _get(stop, 'tripNode.tripInstId');
      stopCard.seqNo = _get(stop, 'tripNode.stopSequenceNbr');
      stopCard.origSeqNo = _get(stop, 'tripNode.stopSequenceNbr');
      stopCard.tripNodeSequenceNbr = _get(stop, 'tripNode.tripNodeSequenceNbr');

      stopCard.tripNodeStatusCd = _get(stop, 'tripNode.statusCd');
      stopCard.activities = _get(stop, 'activities');
      stopCard.specialServicesSummary = _get(stop, 'specialServicesSummary');
      stopCard.nodeTypeCd = _get(stop, 'tripNode.nodeTypeCd');
      stopCard.tripNodeTypeCd = _get(stop, 'tripNode.tripNodeTypeCd');

      stopCard.stopType = Object.keys(groupedActivities)[1] ? 'MX' : Object.keys(groupedActivities)[0];

      stopCard.customer = _get(stop, 'customer');
      stopCard.consigneeName = _get(stop, 'customer.name1', '');
      stopCard.consigneeAddress = _get(stop, 'customer.addressLine1', '');
      stopCard.consigneeCity = _get(stop, 'customer.cityName', '');
      stopCard.consigneeStateCd = _get(stop, 'customer.stateCd', '');
      stopCard.consigneePostalCd = _get(stop, 'customer.postalCd', '');
      stopCard.consigneeGeoCoordinates = _get(stop, 'customer.geoCoordinates');

      stopCard.stopWindows = _get(stop, 'stopWindowSummary', []);
      stopCard.stopWindowTime = this.stopWindowService.getStopWindowTime(stop.stopWindowSummary, false);
      stopCard.stopWindowType = this.stopWindowService.getStopWindowType(stop.stopWindowSummary, true);
      stopCard.estimatedArriveDateTime = _get(stop, 'tripNode.estimatedArriveDateTime');
      stopCard.estimatedArriveDateTimeLocal = _get(stop, 'tripNode.estimatedArriveDateTimeLocal');
      stopCard.estimatedDepartDateTime = _get(stop, 'tripNode.estimatedDepartDateTime');
      stopCard.estimatedDepartDateTimeLocal = _get(stop, 'tripNode.estimatedDepartDateTimeLocal');
      stopCard.totalWeight = totalWeightCount;
      stopCard.totalMotorMoves = totalMmCount;
      stopCard.tripNodeActivityCd = tripNodeActivityCd;
      stopCard.specialServices = this.specialServicesService.getSpecialServicesForStop(stop);

      stopCard.idleTimeInMinutes = 0;

      this.checkForInvalidGeoCoordinates(stopCard);

      stopCards.push(stopCard);
    });

    const orderedStops = lane.sortStops(stopCards);
    lane.originalStops = [...orderedStops];
    lane.assignedStopsSubject$.next(orderedStops);
    lane.selectedRoutesDrivers = driverName;
    lane.tripStatusCd = tripStatusCd;
    lane.canResequenceRoute = this.resequenceService.canResequenceRoute(currentRoute);
    lane.color = this.routeColorService.setRouteColor(lane.routeInstId);
    lane.origin = origin;
    lane.destination = destination;

    return lane;
  }

  /**
   * Build all the lanes for the selected Routes
   */
  buildLanes(
    routeStops: NumberToValueMap<Stop[]>,
    selectedRoutes: Route[],
    trips: TripPlanningGridItem[],
    currentSic: string,
    planDate: Date
  ) {
    const lanes: RouteBoardLane[] = [];

    _forOwn(routeStops, (stops, routeInstId) => {
      const currentRoute = selectedRoutes.find((r) => r.routeInstId === +routeInstId);
      const currentTrip = trips.find((t) => t.routeInstId === +routeInstId);
      const lane = this.buildLane(
        currentRoute,
        stops,
        currentTrip.tripDsrName,
        currentTrip.statusCd,
        currentSic,
        planDate
      );
      lanes.push(lane);
    });

    return lanes;
  }

  /**
   * check for invalid geoCoordinates
   */
  checkForInvalidGeoCoordinates(stopCard: StopCard) {
    if (!MapUtils.isValidGeoCoordinates(stopCard.consigneeGeoCoordinates)) {
      stopCard.consigneeGeoCoordinates = undefined; // Covers case where it's 0,0
      stopCard.canResequenceStop = false;
      stopCard.coordinatesUnknown = true;
      this.loggingService.warn(
        `Stop ${this.activityCdPipe.transform(<TripNodeActivityCd>stopCard.stopType)} ${
          stopCard.consigneeName
        } on route ${stopCard.routeInstId} with seqNbr ${stopCard.tripNodeSequenceNbr} has no geo coordinates.`
      );
    }
  }

  /**
   * Returns a new route balancing stop
   */
  buildRouteBalancingStop(
    tripNodeInstId: number,
    tripNodeSequenceNumber: number,
    activities: RouteBalancingActivity[],
    fromRoute: RouteIdentifier,
    nodeTypeCd: NodeTypeCd,
    tripNodeTypeCd: TripNodeTypeCd,
    geoCoordinates: LatLong,
    customerName: string,
    stopWindows?: StopWindow[],
    estimatedArriveDateTime?: Date,
    estimatedDepartDateTime?: Date,
    estimatedArriveDateTimeLocal?: Date,
    estimatedDepartDateTimeLocal?: Date,
    planDistanceFromPreviousNode?: number,
    potentialMissedStopWindow?: boolean,
    ignoreStopWindowInd?: boolean
  ): RouteBalancingStop {
    const routeBalancingStop = new RouteBalancingStop();

    routeBalancingStop.tripNodeInstId = tripNodeInstId;
    routeBalancingStop.tripNodeSequenceNumber = tripNodeSequenceNumber;
    routeBalancingStop.activities = activities;
    routeBalancingStop.fromRoute = fromRoute;
    routeBalancingStop.nodeTypeCd = nodeTypeCd;
    routeBalancingStop.tripNodeTypeCd = tripNodeTypeCd;
    routeBalancingStop.geoCoordinate = geoCoordinates;
    routeBalancingStop.stopWindows = stopWindows;
    routeBalancingStop.estimatedArriveDateTime = estimatedArriveDateTime;
    routeBalancingStop.estimatedDepartDateTime = estimatedDepartDateTime;
    routeBalancingStop.estimatedArriveDateTimeLocal = estimatedArriveDateTimeLocal;
    routeBalancingStop.estimatedDepartDateTimeLocal = estimatedDepartDateTimeLocal;
    routeBalancingStop.planDistanceFromPreviousNode = planDistanceFromPreviousNode;
    routeBalancingStop.potentialMissedStopWindowsInd = potentialMissedStopWindow;
    routeBalancingStop.ignoreStopWindowInd = ignoreStopWindowInd ? ignoreStopWindowInd : false;
    routeBalancingStop.customerName = customerName;

    return routeBalancingStop;
  }
}
