import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { XpoBoardViewDataStoreBase, XpoBoardViewConfig } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardState, XpoAgGridBoardViewConfig, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { DeliveryShipmentSearchRecord } from '@xpo-ltl/sdk-cityoperations';
import { ShipmentId, ShipmentSpecialServiceCd, ShipmentSpecialServiceSummary } from '@xpo-ltl/sdk-common';
import { ProFormatterPipe, Unsubscriber, XpoLtlShipmentDescriptor, XpoLtlTimeService } from '@xpo/ngx-ltl';
import {
  AgGridEvent,
  GridOptions,
  MenuItemDef,
  RowEvent,
  RowNode,
  RowSelectedEvent,
  Column,
  ColumnApi,
  SelectionChangedEvent,
} from 'ag-grid-community';
import {
  bind as _bind,
  differenceBy as _differenceBy,
  filter as _filter,
  find as _find,
  forEach as _forEach,
  forOwn as _forOwn,
  get as _get,
  groupBy as _groupBy,
  has as _has,
  isEqual as _isEqual,
  map as _map,
  pick as _pick,
  set as _set,
  size as _size,
  some as _some,
  unionBy as _unionBy,
  uniq as _uniq,
  uniqBy as _uniqBy,
  without as _without,
} from 'lodash';
import moment from 'moment';
import { BehaviorSubject, forkJoin, ReplaySubject, of } from 'rxjs';
import { delay, filter, finalize, take, takeUntil, catchError } from 'rxjs/operators';
import { GridRowTransposedComponent, PndDialogService } from '../../../../core';
import { BoardStatesEnum } from '../../../../shared/enums/board-states.enum';
import { PndRouteUtils } from '../../../../shared/route-utils';
import { PndStoreState, RoutesStoreActions, RoutesStoreSelectors } from '../../../store';
import { BoardStateSource } from '../../../store/board-state-source';
import { RouteBalancingActions } from '../../../store/route-balancing-store';
import {
  ActivityCdPipe,
  AssignToRouteComponent,
  DeliveryQualifierCdPipe,
  GrandTotalService,
  MapToolbarService,
  pndFrameworkComponents,
  RouteService,
  SpecialServicesService,
  StopMapComponent,
  StopWindowService,
} from '../../shared';
import { RowHoverManager } from '../../shared/classes/row-hover-manager';
import { AssignToRouteDialogData } from '../../shared/components/assign-to-route/assign-to-route-dialog-data';
import { ExistingOrNewRoute } from '../../shared/components/assign-to-route/existing-or-new-route.enum';
import { InboundPlanningGridBaseComponent } from '../../shared/components/inbound-planning-grid-base/inbound-planning-grid-base.class';
import { SelectionSummaryData } from '../../shared/components/selection-summary/selection-summary-data.class';
import { StoreSourcesEnum } from '../../shared/enums/store-sources.enum';
import {
  AssignedStopIdentifier,
  ConsigneeIdentifier,
  EventItem,
  PlanningRouteShipmentIdentifier,
  RouteStopIdentifier,
  routeStopToId,
  consigneeToId,
} from '../../shared/interfaces/event-item.interface';
import { BillClassCdPipe } from '../../shared/pipes/bill-class-cd.pipe';
import { PlanningRoutesCacheService } from '../../shared/services/planning-routes-cache.service';
import { RouteColorService } from '../../shared/services/route-color.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { PlanningRouteShipmentsGridFields } from './enums/planning-route-shipments-grid-fields.enum';
import { PlanningRouteShipmentsGridItem } from './models/planning-route-shipments-grid-item.model';
import { PlanningRouteShipmentsBoardTemplate } from './planning-route-shipments-board-view-template.model';
import { PlanningRouteShipmentsComponentName } from './planning-route-shipments-component-name';
import { PlanningRouteShipmentsDataSource } from './planning-route-shipments-data-source.service';
import { PlanningRouteShipmentsDataViewDataStore } from './planning-route-shipments-data-view-data-store.service';

const DEFAULT_ROW_HEIGHT = 39; // Default row height

class ActionButtonsState {
  routeAssignmentDisabled = true;
  unassignDisabled = true;
  clearSelectionDisabled = true;
}

@Component({
  selector: 'pnd-planning-route-shipments',
  templateUrl: './planning-route-shipments.component.html',
  styleUrls: ['./planning-route-shipments.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PlanningRouteShipmentsDataSource],
})
export class PlanningRouteShipmentsComponent extends InboundPlanningGridBaseComponent implements OnInit, OnDestroy {
  allDisplayedColumns: Column[] = [];

  // summary data
  private readonly actionButtonsSubject = new BehaviorSubject<ActionButtonsState>(new ActionButtonsState());
  readonly actionButtons$ = this.actionButtonsSubject.asObservable();

  private readonly summarySubject = new BehaviorSubject<SelectionSummaryData>(new SelectionSummaryData());
  readonly summary$ = this.summarySubject.asObservable();

  private readonly showSpinnerSubject = new BehaviorSubject<boolean>(false);
  readonly showSpinner$ = this.showSpinnerSubject.asObservable();

  private focusedItem: EventItem<PlanningRouteShipmentIdentifier>;
  focusedRow: RowNode;

  private currentlyOpenedStopMapComponentDialog: MatDialogRef<StopMapComponent>;

  private rowHoverManager: RowHoverManager<PlanningRouteShipmentIdentifier>;
  private detailGridRowHoverManager: RowHoverManager<DeliveryShipmentSearchRecord>;

  constructor(
    public dataSource: PlanningRouteShipmentsDataSource,
    protected pndStore$: Store<PndStoreState.State>,
    private deliveryQualifierCdPipe: DeliveryQualifierCdPipe,
    private dialog: MatDialog,
    private stopWindowService: StopWindowService,
    private activityCd: ActivityCdPipe,
    private proFormatterPipe: ProFormatterPipe,
    private billClassCdPipe: BillClassCdPipe,
    private timeService: XpoLtlTimeService,
    private mapToolbarService: MapToolbarService,
    private routeService: RouteService,
    private pndDialogService: PndDialogService,
    private specialServicesService: SpecialServicesService,
    private routeColorService: RouteColorService,
    protected userPreferencesService: UserPreferencesService,
    private grandTotalService: GrandTotalService,
    private planningRoutesCacheService: PlanningRoutesCacheService
  ) {
    super(pndStore$, dataSource, userPreferencesService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.rowHoverManager = new RowHoverManager<PlanningRouteShipmentIdentifier>(
      this.gridOptions,
      _bind(this.setFocusedRow, this),
      _bind(this.clearFocusedRow, this)
    );
    this.detailGridRowHoverManager = new RowHoverManager(
      this.gridOptions.detailCellRendererParams.detailGridOptions,
      _bind(this.setFocusedRow, this),
      _bind(this.clearFocusedRow, this)
    );

    this.subscribeToStoreChanges();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.rowHoverManager.destroy();
    this.detailGridRowHoverManager.destroy();
  }

  /**
   * Callback for onRowSelected change events. Handles individual row selection events
   */
  private onRowSelected(event: RowSelectedEvent) {
    if (this.areAllOrNoNodesSelected(event)) {
      return;
    }

    if (event.node.master) {
      // ignore everything except masters for DetailGrids
      const gridItem = event.data as PlanningRouteShipmentsGridItem;

      if (!gridItem.selectedFromCode) {
        // user clicked on this row
        this.toggleStopShipmentSelectionState(event.node);
        event.api.redrawRows({ rowNodes: [event.node] });
      }
      gridItem.selectedFromCode = false;
    }
  }

  /**
   * Callback for selection change events. Handles Select All/Deselect All Events
   */
  private onSelectionChanged(event: SelectionChangedEvent): void {
    const selectedRows = event.api.getSelectedRows();
    const rowsSelectedByCode = selectedRows.filter((rowData) => rowData.selectedFromCode === true);

    if (!this.areAllOrNoNodesSelected(event)) {
      return;
    }

    if (_size(rowsSelectedByCode)) {
      rowsSelectedByCode.forEach((rowData) => {
        rowData.selectedFromCode = false;
      });
      return;
    }

    if (!selectedRows.length) {
      this.clearStopShipmentSelectionState();
    } else {
      this.addAllNodesToStopShipmentSelectionState();
    }
  }

  private areAllOrNoNodesSelected(event: RowSelectedEvent | SelectionChangedEvent): boolean {
    return (
      _get(this.dataSource.currentState, 'data.recordCount') === event.api.getSelectedRows().length ||
      !event.api.getSelectedRows().length
    );
  }

  /**
   * Clears the selection state for all stop nodes and child shipment notes
   */
  private clearStopShipmentSelectionState(): void {
    this.gridApiEvent.api.forEachNode((node: RowNode) => {
      if (node.master) {
        this.setSelectedShipmentsInStopNode(node, []);
        node.data.selectedFromCode = false;
      }
    });

    this.pndStore$.dispatch(
      new RoutesStoreActions.SetSelectedPlanningRoutesShipmentsAction({
        selectedPlanningRoutesShipments: [],
      })
    );
  }

  /**
   * Clears the selection state for all stop nodes and child shipment notes
   */
  private addAllNodesToStopShipmentSelectionState(): void {
    const allStopShipments = [];
    this.gridApiEvent.api.forEachNode((node: RowNode) => {
      if (node.master) {
        const gridItem = node.data as PlanningRouteShipmentsGridItem;
        const currentStopShipments = _map(
          gridItem.deliveryShipments,
          (delShip): PlanningRouteShipmentIdentifier => ({
            shipmentInstId: delShip.shipmentInstId,
            routeInstId: delShip.routeInstId,
            consignee: _pick(delShip.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
          })
        ) as PlanningRouteShipmentIdentifier[];
        allStopShipments.push(...currentStopShipments);
        this.setSelectedShipmentsInStopNode(node, currentStopShipments);
        node.data.selectedFromCode = false;
      }
    });

    this.pndStore$.dispatch(
      new RoutesStoreActions.SetSelectedPlanningRoutesShipmentsAction({
        selectedPlanningRoutesShipments: allStopShipments,
      })
    );
  }

  /**
   * Toggle the selection state for this Stop node.  IF selected, then
   * select all Shipments.  If not selected, then unselect all shipments
   */
  private toggleStopShipmentSelectionState(stopNode: RowNode) {
    // add/remove the shipments at this node to the Selected Shipments list
    this.pndStore$
      .select(RoutesStoreSelectors.selectedPlanningRoutesShipments)
      .pipe(take(1))
      .subscribe((selectedShipments: PlanningRouteShipmentIdentifier[]) => {
        // get sorted list of all currently selected shipmentInstIds
        const originalSelectedShipmentInstIds = _map(selectedShipments, (shipment) => shipment.shipmentInstId).sort();

        // gather all Shipments associated with this Stop node
        const gridItem = stopNode.data as PlanningRouteShipmentsGridItem;
        const stopShipments = _map(
          gridItem.deliveryShipments,
          (delShip): PlanningRouteShipmentIdentifier => ({
            shipmentInstId: delShip.shipmentInstId,
            routeInstId: delShip.routeInstId,
            consignee: _pick(delShip.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
          })
        ) as PlanningRouteShipmentIdentifier[];

        if (stopNode.isSelected()) {
          // add all shipments at Stop to selectedShipments
          selectedShipments = _unionBy(selectedShipments, stopShipments, 'shipmentInstId');

          // set selection state for all shipments at this stop
          this.setSelectedShipmentsInStopNode(stopNode, stopShipments);
        } else {
          // remove all shipments at Stop from selectedShipments
          selectedShipments = _differenceBy(selectedShipments, stopShipments, 'shipmentInstId');

          // clear selection state for all shipments at this stop
          this.setSelectedShipmentsInStopNode(stopNode, []);
        }

        const selectedShipmentInstIds = _map(selectedShipments, (shipment) => shipment.shipmentInstId).sort();
        if (!_isEqual(selectedShipmentInstIds, originalSelectedShipmentInstIds)) {
          this.pndStore$.dispatch(
            new RoutesStoreActions.SetSelectedPlanningRoutesShipmentsAction({
              selectedPlanningRoutesShipments: selectedShipments,
            })
          );
        }
      });
  }

  // #endregion

  // #region Handle Store state changes
  /**
   * React to changes in the Store, updating the Board state when needed
   */
  private subscribeToStoreChanges() {
    this.pndStore$
      .select(RoutesStoreSelectors.stopsForSelectedPlanningRoutesLastUpdate)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(() => {
        // The set of selected stopsForRoutes has changed, so refresh the content
        // of the Board
        this.stateChange$.next({
          source: BoardStateSource.ReduxStore,
          pageNumber: 1,
          changes: ['source', 'pageNumber'],
        });
      });

    this.pndStore$
      .select(RoutesStoreSelectors.selectedPlanningRoutesShipments)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((selectedShipments: PlanningRouteShipmentIdentifier[]) => {
        // The selected Shipments has changed.  Update the Board state to reflect
        // the new selections
        this.updateGridWithSelectedShipments(selectedShipments);
      });

    // listen for focused stops shipments in the planning map
    this.pndStore$
      .select(RoutesStoreSelectors.planningRouteShipmentFocused)
      .pipe(
        filter(() => !!this.gridApiEvent),
        filter(
          (item: EventItem<PlanningRouteShipmentIdentifier>) =>
            item && item.source !== StoreSourcesEnum.PLANNING_ROUTE_SHIPMENTS_GRID
        ),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((item: EventItem<PlanningRouteShipmentIdentifier>) => {
        this.focusedItem = item;
        if (item && item.id) {
          this.focusedRow = this.findStopRowNode(item.id);
          if (this.focusedRow) {
            this.gridApiEvent.api.collapseAll();
            this.focusedRow.parent.expanded = true;
            this.gridApiEvent.api.onGroupExpandedOrCollapsed();
            this.gridApiEvent.api.redrawRows({ rowNodes: [this.focusedRow] });
            this.gridApiEvent.api.ensureNodeVisible(this.focusedRow);
          }
        } else {
          this.gridApiEvent.api.redrawRows({ rowNodes: [this.focusedRow] });
          this.focusedRow = null;
        }
      });
  }

  // Update the selection state for all the shipments
  // NOTE: This should NEVER trigger a Store action!
  private updateGridWithSelectedShipments(selectedShipments: PlanningRouteShipmentIdentifier[]) {
    if (!this.gridApiEvent) {
      // grid is not ready, so skip this
      return;
    }

    // Group selectedShipments by their Stops
    const shipmentsByStops = _groupBy(selectedShipments, (shipment) => routeStopToId(shipment));

    // Loop through every Stop node
    this.gridApiEvent.api.forEachNode((rowNode: RowNode) => {
      if (!rowNode.group) {
        // Update the selection state for all Shipments at this Stop
        const stopData = rowNode.data as PlanningRouteShipmentsGridItem;
        const shipmentsForStop = _get(shipmentsByStops, routeStopToId(stopData));
        this.setSelectedShipmentsInStopNode(rowNode, shipmentsForStop, false);
      } else {
        // Route group node. If any Shipments belong to this route, then ensure the Group is expanded
        const hasSelectedShipment = !!_find(rowNode.allLeafChildren, (stopNode: RowNode) => {
          const stop = stopNode.data as PlanningRouteShipmentsGridItem;
          return !!_find(selectedShipments, (shipment) => shipment.routeInstId === stop.routeInstId);
        });
        if (hasSelectedShipment) {
          // expan the route group
          rowNode.setExpanded(true);
        }
      }
    });

    this.updateSummary(selectedShipments);
    this.refreshSortForSelectedRows();
  }

  // Manually set the selection state for Shipments in this Stop node.
  private setSelectedShipmentsInStopNode(
    stopRowNode: RowNode,
    shipmentsToSelect: PlanningRouteShipmentIdentifier[],
    suppressSelectionEvent: boolean = false
  ) {
    if (this.gridApiEvent) {
      const gridItemData = stopRowNode.data as PlanningRouteShipmentsGridItem;

      if (stopRowNode.expanded) {
        // Set the selecton state of Shipments in the expanded Detail grid

        // find the detailGridInfo matching this Stop

        const detailGridId = `detail_${routeStopToId(gridItemData)}`;
        const detailGridInfo = this.gridApiEvent.api.getDetailGridInfo(detailGridId);
        if (detailGridInfo) {
          // go through all of the rows in the detail grid and update selection state
          detailGridInfo.api.forEachNode((shipRow: RowNode) => {
            const shipment = shipRow.data as DeliveryShipmentSearchRecord;
            const isSelected = !!_find(
              shipmentsToSelect,
              (selShip) => selShip.shipmentInstId === shipment.shipmentInstId
            );

            shipRow.setSelected(isSelected, false, suppressSelectionEvent); // update the checked state for this Shipment Row. Eventually calls onDetailRowSelected()
          });
        }
      }
      // TODO - should set indeterminate state if only some rows selected.
      const shouldSelectStopNode = _size(shipmentsToSelect) > 0;
      const isStopNodeSelected = stopRowNode.isSelected();
      if (isStopNodeSelected !== shouldSelectStopNode) {
        gridItemData.selectedFromCode = true;
        stopRowNode.setSelected(shouldSelectStopNode, false, suppressSelectionEvent);
      }
    }
  }

  // #endregion

  // #region Stops Grid functions

  // add custom styling for a row.
  getRowStyle(params) {
    const routeInstId = _get(params, 'node.allLeafChildren[0].data.routeInstId');
    const color =
      params.node.group && routeInstId ? this.routeColorService.getColorForRoute(routeInstId) : 'transparent';
    const width = '4px';

    return {
      'border-left': `${width} solid ${color}`,
    };
  }

  // return the height of a row based on the visible contents
  getRowHeight(params) {
    // Default row height
    let rowHeight = DEFAULT_ROW_HEIGHT;
    if (params.node && params.node.detail) {
      let subRowHeight = DEFAULT_ROW_HEIGHT;
      const subHeaderHeight = DEFAULT_ROW_HEIGHT + 2;
      const subScrollBarHeight = 17;
      const subStopWindows = params.node.data.stopWindow.length;

      if (subStopWindows > 1) {
        subRowHeight = subRowHeight + 4 * subStopWindows;
      }

      const detailRowsHeight = params.node.data.deliveryShipments.length * subRowHeight;
      rowHeight = detailRowsHeight + subHeaderHeight + subScrollBarHeight;
    } else {
      const stopWindows = _get(params, 'data.stopWindow.length', 1);
      if (stopWindows > 1) {
        rowHeight = rowHeight + 4 * stopWindows;
      }
    }
    return rowHeight;
  }

  onGridReady(gridEvent: AgGridEvent) {
    super.onGridReady(gridEvent);

    this.grandTotalService.planningRouteShipmentsTotals$.pipe(takeUntil(this.unsubscriber.done)).subscribe((totals) => {
      if (this.grandTotalService.isPlanningRouteShipmentGrandTotalsEmpty(totals)) {
        this.gridApiEvent.api.setPinnedBottomRowData(undefined);
      } else {
        this.gridApiEvent.api.setPinnedBottomRowData(totals);
      }
    });
  }

  // #endregion

  // #region Detail Grid (Shipments for Stop) functions

  // return the grid options for the Detail grids
  private detailCellRendererParams() {
    return {
      detailGridOptions: {
        rowClass: 'pnd-PlanningRouteShipments__DetailsRow',
        rowSelection: 'multiple',
        suppressRowClickSelection: true, // force user to click checkbox for selection
        frameworkComponents: pndFrameworkComponents,
        headerHeight: DEFAULT_ROW_HEIGHT,
        defaultColDef: {
          resizable: true,
        },
        columnDefs: [
          {
            headerName: '',
            checkboxSelection: true,
            width: 40,
            cellStyle: (params) => {
              const routeInstId = _get(params, 'node.data.routeInstId');
              const isSelected = params.node.isSelected();
              const color =
                isSelected && routeInstId ? this.routeColorService.getColorForRoute(routeInstId) : 'transparent';
              const width = '4px';

              return {
                'border-left': isSelected ? `${width} solid ${color}` : `${width} solid transparent`,
              };
            },
          },
          {
            headerName: 'PRO',
            field: 'proNbr',
            width: 95,
            cellRenderer: 'actionLinkCellRenderer',
            cellRendererParams: { onClick: this.onShowShipmentDetails.bind(this) },
            valueFormatter: (params) => this.proFormatterPipe.transform(params.data.proNbr, 10),
          },
          {
            headerName: 'Bill Class',
            width: 70,
            valueFormatter: (params) => {
              return this.billClassCdPipe.transform(_get(params, 'data.billClassCd'));
            },
            tooltip: (params) => {
              return this.billClassCdPipe.transformToTooltip(_get(params, 'data.billClassCd'));
            },
          },
          // {
          //   headerName: 'LP',
          //   field: 'loosePcsCnt',
          //   width: 60,
          //   type: 'numericColumn',
          //   cellStyle: { textAlign: 'center' },
          // },
          {
            headerName: 'Weight (lbs.)',
            field: 'totalWeightLbs',
            width: 110,
            type: 'numericColumn',
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: 'MM',
            field: 'motorizedPiecesCount',
            width: 70,
            type: 'numericColumn',
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: 'Cube',
            field: 'totalCubePercentage',
            width: 70,
            type: 'numericColumn',
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: 'Svc Date',
            field: 'estimatedDeliveryDate',
            width: 100,
            valueFormatter: (params) => {
              const shipment: DeliveryShipmentSearchRecord = params.data as DeliveryShipmentSearchRecord;
              const estimatedDeliveryDate = _get(shipment, 'estimatedDeliveryDate');
              if (estimatedDeliveryDate) {
                return moment(estimatedDeliveryDate).format('MM-DD');
              } else {
                return '';
              }
            },
          },
          {
            headerName: 'Destination ETA',
            field: 'destSicEta',
            width: 120,
            valueFormatter: (params) => {
              const shipment: DeliveryShipmentSearchRecord = params.data as DeliveryShipmentSearchRecord;
              return this.timeService.formatDate(shipment.destSicEta, 'MM-DD HH:mm', shipment.destinationSicCd);
            },
          },
          { headerName: 'Destination SIC', field: 'destinationSicCd', width: 125 },
          { headerName: 'Current SIC', field: 'shipmentLocationSicCd', width: 125 },
          { headerName: 'Trailer', field: 'currentTrailer', width: 125 },
          { headerName: 'Trailer SIC', field: 'trailerCurrSicCd', width: 125 },
          {
            headerName: 'Exceptions',
            field: 'deliveryExceptions',
            width: 120,
            valueFormatter: (params) => this.deliveryQualifierCdPipe.transform(params.data.deliveryQualifierCd),
          },
          {
            headerName: 'Schedule ETA',
            field: 'scheduleETA',
            width: 120,
            valueFormatter: (params) => {
              const shipment: DeliveryShipmentSearchRecord = params.data as DeliveryShipmentSearchRecord;
              return this.timeService.formatDate(
                shipment.scheduleETA,
                'MM-DD HH:mm',
                shipment.scheduleDestinationSicCd
              );
            },
          },
          // Not yet Implemented
          {
            headerName: 'Schedule Destination',
            field: 'scheduleDestinationSicCd',
            width: 130,
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: 'Special Services',
            field: 'specialServiceSummary',
            width: 170,
            valueGetter: (params) => {
              const specialServicesSummary = _get(
                params,
                'data.specialServiceSummary'
              ) as ShipmentSpecialServiceSummary[];
              const specialServices = this.specialServicesService.getSpecialServicesForSummary(specialServicesSummary);
              return specialServices;
            },
            cellRenderer: 'specialServicesCellRenderer',
          },
          {
            headerName: 'Delivery Window Type',
            field: 'deliveryWindowType',
            width: 160,
            valueGetter: (params) => {
              const stopWindow = _get(params.data, 'stopWindow');
              return this.stopWindowService.getStopWindowType(stopWindow);
            },
          },
          {
            headerName: 'Delivery Window Time',
            field: 'deliveryWindowTime',
            width: 160,
            cellRenderer: 'deliverWindowTimeCellRenderer',
          },
          {
            headerName: 'Delivery Window Date',
            field: 'deliveryWindowDate',
            width: 160,
            valueGetter: (params) => {
              const stopWindow = _get(params.data, 'stopWindow');
              return this.stopWindowService.getStopWindowDate(stopWindow);
            },
          },
        ],

        getRowHeight: (params: RowEvent) => this.getDetailRowHeight(params),
        onGridReady: (params: RowEvent) => this.onDetailGridReady(params),
        onDetailGridReady: () => {},
        onSelectionChanged: () => {}, // override board selection changed event to prevent additional events firing
        onRowSelected: (row: RowSelectedEvent) => this.onDetailRowSelected(row),
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.deliveryShipments);
      },
    };
  }

  private getDetailRowHeight(params) {
    let totalRowHeight = DEFAULT_ROW_HEIGHT;
    const stopWindows = params.data.stopWindow.length;
    if (stopWindows > 1) {
      totalRowHeight = totalRowHeight + 4 * stopWindows;
    }
    return totalRowHeight;
  }

  // Return the RowNode for the specified Stop identifier
  // TODO - is there a better way to find the node without looping?
  private findStopRowNode(consignee: ConsigneeIdentifier): RowNode {
    const stopId = routeStopToId(consignee);
    let stopNode: RowNode;
    this.gridApiEvent.api.forEachNode((node) => {
      if (node.master && stopId === routeStopToId(node.data)) {
        stopNode = node;
        return;
      }
    });
    return stopNode;
  }

  /**
   * Handle when a Shipment detail grid is displayed for a Stop
   * This is called in two different ways:
   * 1) User clicks on the expand toggle for the Stop row
   * 2) Code manually set the Stop row to be expanded
   */
  onDetailGridReady(event: AgGridEvent): void {
    // update the initial checked state to match selected shipments
    this.pndStore$
      .select(RoutesStoreSelectors.selectedPlanningRoutesShipments)
      .pipe(take(1))
      .subscribe((selectedShipments: PlanningRouteShipmentIdentifier[]) => {
        this.updateGridWithSelectedShipments(selectedShipments);
      });
  }

  /**
   * Handle user selecting/deselecting a Shipment
   * This is called in two different ways:
   * 1) User clicks on the checkbox for this row
   * 2) Code manually set the selection state for this row
   *
   * If the User selected the checkbox then we need to update the
   * Store with the new selection state.  Otherwise, we ignore it
   */
  onDetailRowSelected(event: RowSelectedEvent) {
    const eventShipment = event.data as DeliveryShipmentSearchRecord;

    event.api.redrawRows({ rowNodes: [event.node] });

    // NOTE: This should only happen when the USER clicks a Shipment Node in the DetailGrid
    // add/remove the stop from the list of selected stops
    this.pndStore$
      .select(RoutesStoreSelectors.selectedPlanningRoutesShipments)
      .pipe(take(1))
      .subscribe((selectedShipments: PlanningRouteShipmentIdentifier[]) => {
        const originalSelectedShipments = _map(selectedShipments, (shipment) => shipment.shipmentInstId).sort();

        const isSelected = event.node.isSelected();
        if (isSelected) {
          // only add this shipment if it is NOT in the current selection list
          if (!_find(selectedShipments, (selShip) => selShip.shipmentInstId === eventShipment.shipmentInstId)) {
            // add this Shipment to the current selection
            const item: PlanningRouteShipmentIdentifier = {
              shipmentInstId: eventShipment.shipmentInstId,
              routeInstId: eventShipment.routeInstId,
              consignee: _pick(eventShipment.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
            };
            selectedShipments.push(item);

            // make sure the Stop is selected too
            const stopRowNode = this.findStopRowNode(eventShipment);
            if (!stopRowNode.isSelected()) {
              stopRowNode.data.selectedFromCode = true;
              stopRowNode.setSelected(true);
            }
          }
        } else {
          // ensure the node is no longer in the selected list
          selectedShipments = _filter(
            selectedShipments,
            (shipment) => !_isEqual(shipment.shipmentInstId, eventShipment.shipmentInstId)
          );

          const stopRowNode = this.findStopRowNode(eventShipment);
          if (stopRowNode) {
            const stopId = routeStopToId(eventShipment);
            const shouldSelectStopNode = _some(selectedShipments, (selShip) => routeStopToId(selShip) === stopId);
            if (shouldSelectStopNode !== stopRowNode.isSelected()) {
              stopRowNode.data.selectedFromCode = shouldSelectStopNode;
              stopRowNode.setSelected(shouldSelectStopNode);
            }
          }
        }

        // Only update the Store if the selected Stops have been modified
        const newSelectedShipments = _map(selectedShipments, (shipment) => shipment.shipmentInstId).sort();

        if (!_isEqual(originalSelectedShipments, newSelectedShipments)) {
          this.pndStore$.dispatch(
            new RoutesStoreActions.SetSelectedPlanningRoutesShipmentsAction({
              selectedPlanningRoutesShipments: selectedShipments,
            })
          );

          this.updateSummary(selectedShipments);
        }
      });
  }

  // #endregion

  // #region Summary

  // sets the enabled state of the toolbar action buttons
  private updateActionButtonState(selectedShipments: PlanningRouteShipmentIdentifier[]): void {
    const numSelectedRoutes = _size(_uniqBy(selectedShipments, 'routeInstId'));
    const state = {
      routeAssignmentDisabled: numSelectedRoutes !== 1,
      unassignDisabled: _size(selectedShipments) === 0,
      clearSelectionDisabled: _size(selectedShipments) === 0,
    };
    this.actionButtonsSubject.next(state);
  }

  // clear the Summary data
  private clearSummary() {
    this.summarySubject.next(undefined);
    this.updateActionButtonState([]);
  }

  // Update summary data based on the passed shipments
  private updateSummary(selectedShipments: PlanningRouteShipmentIdentifier[]) {
    this.clearSummary();

    if (_size(selectedShipments) > 0) {
      const stopIds: string[] = [];

      const shipments = _without(
        _map(selectedShipments, (shipmentId) => {
          const shipment = this.planningRoutesCacheService.getDeliveryShipmentSearchRecord(shipmentId);
          return shipment;
        }),
        undefined
      ) as DeliveryShipmentSearchRecord[];

      // gather all the stats for selected shipments
      let servicesAccumulator: ShipmentSpecialServiceCd[] = [];
      const newSummary = new SelectionSummaryData();

      newSummary.shipments = _size(shipments);
      _forEach(shipments, (shipment) => {
        stopIds.push(routeStopToId(shipment));
        newSummary.motorMoves += shipment.motorizedPiecesCount;
        newSummary.weight += shipment.totalWeightLbs;

        servicesAccumulator = servicesAccumulator.concat(
          this.specialServicesService.getSpecialServicesForSummary(shipment.specialServiceSummary)
        );
      });

      newSummary.stops = _size(_uniq(stopIds));

      newSummary.specialServices = _uniq(servicesAccumulator);

      this.summarySubject.next(newSummary);
    }

    this.updateActionButtonState(selectedShipments);
  }

  // #endregion

  // #region Hover Row Focus
  // TODO - what is the typing of this?!?!
  private setFocusedRow(data: any) {
    if (_has(data, 'routeInstId') && _has(data, 'consignee')) {
      const stopId = data as RouteStopIdentifier;
      this.pndStore$
        .select(RoutesStoreSelectors.focusedStopForSelectedRoute)
        .pipe(take(1))
        .subscribe((currentFocusedStop: EventItem<AssignedStopIdentifier>) => {
          if (_get(currentFocusedStop, 'id.routeInstId') !== stopId.routeInstId) {
            this.pndStore$.dispatch(
              new RoutesStoreActions.SetFocusedPlanningRouteShipmentAction({
                focusedPlanningRouteShipment: {
                  id: {
                    shipmentInstId: _get(stopId, 'shipmentInstId'),
                    routeInstId: _get(stopId, 'routeInstId'),
                    consignee: _get(stopId, 'consignee'),
                  },
                  source: StoreSourcesEnum.PLANNING_ROUTE_SHIPMENTS_GRID,
                },
              })
            );
          }
        });
    } else {
      // focus not on a Stop or Shipment
      this.clearFocusedRow();
    }
  }

  private clearFocusedRow() {
    this.pndStore$.dispatch(
      new RoutesStoreActions.SetFocusedPlanningRouteShipmentAction({
        focusedPlanningRouteShipment: {
          id: undefined,
          source: StoreSourcesEnum.PLANNING_ROUTE_SHIPMENTS_GRID,
        },
      })
    );
  }

  // #endregion

  // #region Stop and Shipment functions

  // Display the shipment details dialog for the passed shipment
  private onShowShipmentDetails(shipment: XpoLtlShipmentDescriptor): void {
    this.pndDialogService
      .showShipmentDetailsDialog({ proNbr: shipment.proNbr, shipmentInstId: shipment.shipmentInstId })
      .subscribe();
  }

  // return context menu definition for this grid
  private getContextMenuItems(params): (string | MenuItemDef)[] {
    return [
      'copy',
      'copyWithHeaders',
      'paste',
      'separator',
      'export',
      'separator',
      {
        name: 'Transpose',
        action: () => {
          this.dialog.open(GridRowTransposedComponent, {
            data: { ...params, dialogTitle: `Route Stops - ${params.node.key || params.node.data.routeId}` },
            disableClose: true,
            hasBackdrop: false,
          });
        },
      },
    ];
  }

  // Display the Stop map location dialog
  private onAddressClick(data: PlanningRouteShipmentsGridItem): void {
    const name1 = _get(data, 'consignee.name1');
    const latitude = _get(data, 'consignee.geoCoordinates.latitude');
    const longitude = _get(data, 'consignee.geoCoordinates.longitude');

    if (this.currentlyOpenedStopMapComponentDialog) {
      this.currentlyOpenedStopMapComponentDialog.close();
    }

    if (!latitude || !longitude) {
      // no location to display
      this.pndDialogService.showConfirmCancelDialog(
        'Missing Location Data',
        `No Geo Coordinates for ${name1}'s location`,
        ''
      );
    } else {
      this.currentlyOpenedStopMapComponentDialog = this.dialog.open(StopMapComponent, {
        data: {
          consigneeName: name1,
          geoCoordinates: { latitude, longitude },
        },
        disableClose: true,
      });
      this.currentlyOpenedStopMapComponentDialog.afterOpened().subscribe(() => {});
    }
  }

  // #endregion

  // #region Route Toolbar functions

  clearSelection(): void {
    this.clearSummary();
    this.mapToolbarService.toggleDrawModeOff();
    this.pndStore$.dispatch(
      new RoutesStoreActions.SetSelectedPlanningRoutesShipmentsAction({
        selectedPlanningRoutesShipments: [],
      })
    );
    this.gridApiEvent.api.deselectAll();
  }

  /**
   * Remove the selected Shipments from their routes
   */
  unassignRoute(): void {
    this.updateActionButtonState([]); // disable the action buttons when unassigning routes

    this.pndStore$
      .select(RoutesStoreSelectors.selectedPlanningRoutesShipments)
      .pipe(take(1))
      .subscribe((selectedShipments) => {
        let routesToShipments: {
          routeInstId: number;
          routeName: string;
          shipments: ShipmentId[];
        }[] = [];

        const shipmentsByRouteInstId = _groupBy(selectedShipments, 'routeInstId');

        // build entries for each Route and its Shipments to unassign
        _forEach(shipmentsByRouteInstId, (shipments: PlanningRouteShipmentIdentifier[], routeInstId: string) => {
          let routeName: string;

          // Map each Shipment to a ShipmentId
          const shipmentIds = _map(shipments, (shipment) => {
            const record: DeliveryShipmentSearchRecord = this.planningRoutesCacheService.getDeliveryShipmentSearchRecordInRoute(
              +routeInstId,
              shipment.shipmentInstId
            );

            if (!record) {
              // failed to find the shipment. THIS SHOULDNT HAPPEN! Means our data in the cache is out of sync
              return undefined;
            } else {
              if (!routeName) {
                // unclean code, but quickest way to get the routeName without other hacks
                routeName = PndRouteUtils.getRouteId(record);
              }

              return {
                shipmentInstId: record.shipmentInstId.toString(),
                proNumber: record.proNbr,
                pickupDate: undefined,
              } as ShipmentId;
            }
          });

          // save shipments for this route
          routesToShipments.push({
            routeInstId: +routeInstId,
            routeName,
            shipments: _without(shipmentIds, undefined),
          });
        });

        // remnove any routes that have no shipments to unassign
        routesToShipments = _filter(routesToShipments, (route) => _size(route.shipments) > 0);

        if (_size(routesToShipments) > 0) {
          this.pndStore$.dispatch(
            new RouteBalancingActions.SetCanOpenRouteBalancing({
              canOpenRouteBalancing: false,
            })
          );

          this.showSpinnerSubject.next(true);
          const requests = _map(routesToShipments, (routeShipments) => {
            return this.routeService.unassignShipments(
              routeShipments.routeName,
              routeShipments.routeInstId,
              routeShipments.shipments
            );
          });

          forkJoin(requests)
            .pipe(
              catchError(() => {
                this.pndStore$.dispatch(
                  new RouteBalancingActions.SetCanOpenRouteBalancing({
                    canOpenRouteBalancing: true,
                  })
                );
                return of({});
              }),
              finalize(() => {
                // clear selected shipments after unassign
                this.clearSelection();
                this.pndStore$.dispatch(new RoutesStoreActions.RefreshPlanningRoutes());
                this.showSpinnerSubject.next(false);
              })
            )
            .subscribe();
        }
      });
  }

  assignRoute() {
    this.openAssignToRouteDialog(ExistingOrNewRoute.Existing);
  }

  createRoute() {
    this.openAssignToRouteDialog(ExistingOrNewRoute.New);
  }

  // Display Assign To Routes dialog with the current selectedShipments
  private openAssignToRouteDialog(assignToRouteType: ExistingOrNewRoute) {
    this.pndStore$
      .select(RoutesStoreSelectors.selectedPlanningRoutesShipments)
      .pipe(take(1))
      .subscribe((selectedShipments) => {
        // build map of all DeliveryShipmentSearchRecords for selected shipmetns
        const idToIdentity: { [key: string]: PlanningRouteShipmentIdentifier } = {};
        const idToShipments: { [key: string]: DeliveryShipmentSearchRecord[] } = {};

        _forEach(selectedShipments, (shipmentId: PlanningRouteShipmentIdentifier) => {
          const sid = routeStopToId(shipmentId);
          _set(idToIdentity, sid, shipmentId);

          const deliveryShipment = this.planningRoutesCacheService.getDeliveryShipmentSearchRecord(shipmentId);
          if (deliveryShipment) {
            const deliveryShipments = _get(idToShipments, sid, []);
            _set(idToShipments, sid, deliveryShipments.concat(deliveryShipment));
          }
        });

        const selectedPlanningShipments = new Map<PlanningRouteShipmentIdentifier, DeliveryShipmentSearchRecord[]>();
        _forOwn(idToIdentity, (identity, sid) => {
          const shipments = _get(idToShipments, sid, []);
          selectedPlanningShipments.set(identity, shipments);
        });

        // open assign dialog
        this.dialog
          .open(AssignToRouteComponent, {
            data: <AssignToRouteDialogData>{
              initialMode: assignToRouteType,
              selectedPlanningShipments: selectedPlanningShipments,
            },
            disableClose: true,
            hasBackdrop: true,
          })
          .afterClosed()
          .pipe(take(1))
          .subscribe((actionPerformed) => {
            if (actionPerformed) {
              // clear selected shipments after assign
              this.clearSelection();

              this.pndStore$.dispatch(new RoutesStoreActions.RefreshPlanningRoutes());
            }
          });
      });
  }

  // #endregion

  /* BASE CLASS OVERWRITTEN FUNCTIONS */

  protected setRowsSelectedFromStore() {
    this.pndStore$
      .select(RoutesStoreSelectors.selectedPlanningRoutesShipments)
      .pipe(take(1))
      .subscribe((selectedShipments: PlanningRouteShipmentIdentifier[]) => {
        this.updateGridWithSelectedShipments(selectedShipments);
      });
  }

  /* ABSTRACT IMPLEMENTATIONS */

  protected getRowNodeId(node: RowNode): string {
    throw new Error('Method not implemented.');
  }

  protected getSelectedRowFromStoreId(selectedRow): string {
    throw new Error('Method not implemented.');
  }

  protected getSelectedStoreStateSelector() {
    return RoutesStoreSelectors.selectedPlanningRoutesShipments;
  }

  protected getSelectedRowColumnFieldName(): string {
    return PlanningRouteShipmentsGridFields.ROW_SELECTED;
  }

  protected getBoardViewTemplates(): XpoAgGridBoardViewTemplate[] {
    return [
      new PlanningRouteShipmentsBoardTemplate(
        this.onAddressClick.bind(this),
        this.stopWindowService,
        this.activityCd,
        this.specialServicesService,
        this.routeColorService
      ),
    ];
  }

  protected getGridOptions(): GridOptions {
    return {
      frameworkComponents: pndFrameworkComponents,
      defaultColDef: {
        resizable: true,
      },
      rowClassRules: {
        'ag-row-hover': (params) => {
          return consigneeToId(_get(this.focusedItem, 'id')) === consigneeToId(params.data);
        },
      },
      groupDefaultExpanded: 1,
      suppressRowClickSelection: true, // force user to click checkbox for selection
      pinnedBottomRowData: [],
      masterDetail: true,
      detailCellRendererParams: this.detailCellRendererParams(),
      getContextMenuItems: (params) => this.getContextMenuItems(params),
      getRowHeight: (params) => this.getRowHeight(params),
      getRowStyle: (params) => this.getRowStyle(params),
      onRowSelected: (params) => this.onRowSelected(params),
      onSelectionChanged: (params) => this.onSelectionChanged(params),
    };
  }

  protected mapViewDataStore(preferences): XpoBoardViewDataStoreBase<XpoBoardViewConfig> {
    return new PlanningRouteShipmentsDataViewDataStore(this.userPreferencesService, preferences);
  }

  protected getComponentName(): string {
    return PlanningRouteShipmentsComponentName;
  }
}
