import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { XpoAgGridBoardState } from '@xpo-ltl/ngx-ltl-board-grid';
import { CityOperationsApiService, GetPnDTripPath } from '@xpo-ltl/sdk-cityoperations';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { XrtChangedDocument } from '@xpo/ngx-xrt';
import { XrtFireMessagingService } from '@xpo/ngx-xrt-firebase';
import { AgGridEvent, MenuItemDef, RowSelectedEvent, RowNode, ColumnApi } from 'ag-grid-community';
import {
  bind as _bind,
  debounce as _debounce,
  isEqual as _isEqual,
  get as _get,
  includes as _includes,
  map as _map,
  size as _size,
  some as _some,
  uniq as _uniq,
  toString as _toString,
} from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { delay, distinctUntilChanged, filter, skip, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { GridRowTransposedComponent } from '../../../../core/dialogs/grid-row-transposed/grid-row-transposed.component';
import { PndDialogService } from '../../../../core/dialogs/pnd-dialog.service';
import { NotificationMessageStatus } from '../../../../core/enums/notification-message-status.enum';
import { NotificationMessageService } from '../../../../core/services/notification-message.service';
import { PndDateUtils } from '../../../../shared/date-utils';
import { BoardStatesEnum } from '../../../../shared/enums/board-states.enum';
import { PndRouteUtils } from '../../../../shared/route-utils';
import {
  GlobalFilterStoreSelectors,
  PndStoreState,
  RoutesStoreActions,
  RoutesStoreSelectors,
  TripsStoreActions,
  TripsStoreSelectors,
} from '../../../store';
import { RouteBalancingActions, RouteBalancingSelectors } from '../../../store/route-balancing-store';
import {
  EquipmentPipe,
  GrandTotalService,
  pndFrameworkComponents,
  RouteService,
  SpecialServicesService,
} from '../../shared';
import { RowHoverManager } from '../../shared/classes/row-hover-manager';
import { InboundPlanningGridBaseComponent } from '../../shared/components/inbound-planning-grid-base/inbound-planning-grid-base.class';
import { RouteSummaryComponent } from '../../shared/components/route-summary/route-summary.component';
import { StoreSourcesEnum } from '../../shared/enums/store-sources.enum';
import { EventItem, RouteIdentifier } from '../../shared/interfaces/event-item.interface';
import { RemoveLeadingZerosPipe } from '../../shared/pipes/remove-leading-zeros.pipe';
import { RouteColorService } from '../../shared/services/route-color.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { TripPlanningGridFields } from './enums/trip-planning-grid-fields.enum';
import { TripPlanningComponentName } from './models/trip-planning-component-name';
import { TripPlanningGridItem } from './models/trip-planning-grid-item.model';
import { TripPlanningRouteBoardTemplate } from './models/trip-planning-route-board-view-template.model';
import { TripPlanningDataSource } from './services/trip-planning-data-source.service';
import { TripPlanningViewDataStore } from './services/trip-planning-view-data-store.service';

const MIN_TIME_BETWEEN_REFRESHES = 3000; // min time between refreshing

@Component({
  selector: 'pnd-trip-planning',
  templateUrl: './trip-planning.component.html',
  styleUrls: ['./trip-planning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [TripPlanningDataSource, XrtFireMessagingService],
  host: { class: 'pnd-trip-planning' },
})
export class TripPlanningComponent extends InboundPlanningGridBaseComponent implements OnInit, OnDestroy {
  private isDeleteTripsBtnActiveSubject = new BehaviorSubject<boolean>(false);
  isDeleteTripsBtnActive$ = this.isDeleteTripsBtnActiveSubject.asObservable();

  changedTripsCount$ = new BehaviorSubject<Number>(0);
  lastUpdateTime$ = new BehaviorSubject<String>('');
  canOpenRouteBalancing$: Observable<boolean>;

  private rowHoverManager: RowHoverManager<TripPlanningGridItem>;
  private colApi: ColumnApi;

  private debouncedRefresh = _debounce(
    () => {
      this.clearSelections();
      this.dataSource.refresh();
    },
    MIN_TIME_BETWEEN_REFRESHES,
    { leading: true, trailing: false }
  );
  private debouncedUpdateSelection = _debounce(
    (selectedTrips: TripPlanningGridItem[]) => {
      this.updateNodeSelection(_map(selectedTrips, (trip) => `${trip.tripInstId}-${trip.routeInstId}`));
    },
    0,
    { leading: false, trailing: true }
  );

  constructor(
    public dataSource: TripPlanningDataSource,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog,
    protected pndStore$: Store<PndStoreState.State>,
    private cityOperationsService: CityOperationsApiService,
    private timeService: XpoLtlTimeService,
    private equipmentPipe: EquipmentPipe,
    private specialServicesService: SpecialServicesService,
    private routeService: RouteService,
    private routeColorService: RouteColorService,
    protected userPreferencesService: UserPreferencesService,
    private grandTotalService: GrandTotalService,
    private loggingService: LoggingApiService,
    private removeLeadingZerosPipe: RemoveLeadingZerosPipe,
    private pndDialogService: PndDialogService,
    private notificationMessageService: NotificationMessageService
  ) {
    super(pndStore$, dataSource, userPreferencesService);

    this.matIconRegistry.addSvgIcon(
      'routes',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/road-icon.svg')
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.rowHoverManager = new RowHoverManager(
      this.gridOptions,
      _bind(this.setFocusedRow, this),
      _bind(this.clearFocusedRow, this)
    );

    this.gridOptions.onRowSelected = (row: RowSelectedEvent) => {
      if (_get(row, 'node.selected')) {
        this.routeColorService.setRouteColor(row.node.data.routeInstId);
      } else {
        this.routeColorService.clearRouteColor(row.node.data.routeInstId);
      }

      row.api.refreshCells({
        force: true,
        columns: [TripPlanningGridFields.ROW_SELECTED],
        rowNodes: [row.node],
      });
    };

    this.pndStore$
      .select(TripsStoreSelectors.trips)
      .pipe(
        skip(1),
        withLatestFrom(this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic)),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(([_, sicCd]) => {
        if (sicCd) {
          // update the latestUpdateTime every time the Routes are updated
          // TODO - this really should be stored in the Store as the last time the data was fetched from the server!
          this.lastUpdateTime$.next(this.getCurrentDateTime(sicCd));
        }

        // ensure the selected trips are selected in the grid
        this.pndStore$
          .select(TripsStoreSelectors.selectedTrips)
          .pipe(take(1), delay(1))
          .subscribe((selectedTrips) => {
            this.updateNodeSelection(_map(selectedTrips, (trip) => `${trip.tripInstId}-${trip.routeInstId}`));
          });
      });

    // update the number of pending changed documents
    this.pndStore$
      .select(RoutesStoreSelectors.changedRoutes)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((changedDocuments: XrtChangedDocument[]) => {
        const docKeys = _uniq(
          _map(changedDocuments, (doc) => {
            const docKey = JSON.parse(JSON.stringify(doc)).DocumentKey;
            return docKey;
          })
        );

        this.changedTripsCount$.next(_size(docKeys));
      });

    this.pndStore$
      .select(TripsStoreSelectors.selectedTrips)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((selectedTrips: TripPlanningGridItem[]) => {
        this.updateDeleteBtnPermission(selectedTrips);
        this.debouncedUpdateSelection(selectedTrips);
      });

    this.canOpenRouteBalancing$ = this.pndStore$.select(RouteBalancingSelectors.canOpenRouteBalancing);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.boardReadySubject.next(false);
    this.rowHoverManager.destroy();
  }

  clearSelections() {
    this.pndStore$.dispatch(
      new TripsStoreActions.SetSelectedTrips({
        selectedTrips: [],
      })
    );
  }

  onRefreshIntent(): void {
    this.debouncedRefresh();
  }

  onShowRoute(tripGridItem: TripPlanningGridItem): void {
    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterSic)
      .pipe(
        take(1),
        filter((sicCd: string) => !!sicCd),
        switchMap((currentSic) => {
          const request = new GetPnDTripPath();
          request.sicCd = currentSic;
          request.tripInstId = tripGridItem.route.tripInstId;
          return this.cityOperationsService.getPnDTrip(request);
        })
      )
      .subscribe((response) => {
        this.dialog.open(RouteSummaryComponent, {
          data: {
            selectedRoute: tripGridItem.routeInstId,
            tripDetails: response,
          },
          disableClose: true,
          hasBackdrop: false,
        });
      });
  }

  private updateDeleteBtnPermission(selectedTrips: TripPlanningGridItem[]) {
    // Check 1: Route Status should be either - New, Unreleased or Released
    const tripsWithWrongStatus: TripPlanningGridItem[] = selectedTrips.filter((trip) => {
      const statusCd = _get(trip, 'route.statusCd');
      return statusCd !== 'New' && statusCd !== 'Unreleased' && statusCd !== 'Released';
    });
    // Check 2: Selected Trips should have no shipments associated
    const tripsWithShipments: TripPlanningGridItem[] = selectedTrips.filter((trip) => {
      return _get(trip, 'route.totalBillCount') > 0;
    });

    const status =
      selectedTrips.length > 0 && tripsWithWrongStatus.length === 0 && tripsWithShipments.length === 0 ? true : false;
    this.isDeleteTripsBtnActiveSubject.next(status);
  }

  private setFocusedRow(data: TripPlanningGridItem) {
    this.pndStore$
      .select(RoutesStoreSelectors.focusedRoute)
      .pipe(take(1))
      .subscribe((currentFocusedRoute: EventItem<RouteIdentifier>) => {
        if (_get(currentFocusedRoute, 'id.routeInstId') !== _get(data, 'routeInstId')) {
          this.pndStore$.dispatch(
            new RoutesStoreActions.SetFocusedRouteAction({
              focusedRoute: {
                id: { routeInstId: data.routeInstId },
                source: StoreSourcesEnum.TRIP_PLANNING_GRID,
              },
            })
          );
        }
      });
  }

  private clearFocusedRow() {
    this.pndStore$.dispatch(
      new RoutesStoreActions.SetFocusedRouteAction({
        focusedRoute: {
          id: undefined,
          source: StoreSourcesEnum.TRIP_PLANNING_GRID,
        },
      })
    );
  }

  deleteTrips() {
    const transactionTimestamp$ = this.pndStore$.select(TripsStoreSelectors.transactionTimestampUTC);
    const selectedTrips$ = this.pndStore$.select(TripsStoreSelectors.selectedTrips);
    const currentSic$ = this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic);

    combineLatest([transactionTimestamp$, selectedTrips$, currentSic$])
      .pipe(take(1))
      .subscribe(([transactionTimestampUTC, selectedTrips, currentSic]: [Date, TripPlanningGridItem[], string]) => {
        const pathParams = {
          sicCd: currentSic,
        };
        const queryParams = {
          routeInstIds: selectedTrips.map((trip) => {
            return trip.route.routeInstId;
          }),
        };
        const httpOptions = {
          headers: {
            'Transaction-Timestamp': _toString(PndDateUtils.conditionTimeToServer(transactionTimestampUTC).getTime()),
          },
        };
        this.cityOperationsService.deletePnDRoutes(pathParams, queryParams, {}, httpOptions).subscribe(
          () => {
            // Refresh trips grid to fetch updated trips after successful deletion
            this.onRefreshIntent();
            this.notificationMessageService
              .openNotificationMessage(
                NotificationMessageStatus.Success,
                `${queryParams.routeInstIds.length} ${
                  queryParams.routeInstIds.length > 1 ? 'routes were' : 'route was'
                } successfully deleted.`
              )
              .subscribe(() => {});
          },
          (error) => {
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, error)
              .subscribe(() => {});
          }
        );
      });
  }

  viewClick($event: MatButtonToggleChange) {
    this.colApi.setColumnsVisible([TripPlanningGridFields.TIMELINE], $event.value === TripPlanningGridFields.TIMELINE);
  }

  openRouteBalancing() {
    this.loggingService.info('User click to open route balancer');
    this.pndStore$.dispatch(new RouteBalancingActions.SetOpenRouteBalancingPanel({ openRouteBalancingPanel: true }));
  }

  onGridReady(gridEvent: AgGridEvent) {
    super.onGridReady(gridEvent);

    this.colApi = gridEvent.columnApi;
    this.subscribeToChangeColor();

    this.grandTotalService.tripsTotals$.pipe(takeUntil(this.unsubscriber.done)).subscribe((totals) => {
      if (this.grandTotalService.isTripsGrandTotalsEmpty(totals)) {
        this.gridApiEvent.api.setPinnedBottomRowData(undefined);
      } else {
        this.gridApiEvent.api.setPinnedBottomRowData(totals);
      }
    });
  }

  private subscribeToChangeColor(): void {
    this.routeColorService.colorChanged$
      .pipe(
        filter((change) => !!change),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((change: { routeInstId: number; color: string }) => {
        this.gridApiEvent.api.redrawRows();
      });
  }

  private getCurrentDateTime(sicCd: string): string {
    return moment.tz(new Date(), this.timeService.timezoneForSicCd(sicCd)).format('DD MMMM YYYY [at] HH:mm:ss z');
  }

  private getContextMenuItems(params): (string | MenuItemDef)[] {
    return [
      'copy',
      'copyWithHeaders',
      'paste',
      'separator',
      'export',
      'separator',
      {
        name: 'Transpose',
        action: () => {
          this.dialog.open(GridRowTransposedComponent, {
            data: { ...params, dialogTitle: `Trips - ${PndRouteUtils.getRouteId(params.node.data.route)}` },
            disableClose: true,
            hasBackdrop: false,
          });
        },
      },
    ];
  }

  protected getRowNodeId(node: RowNode) {
    const tripInstId = _get(node, 'data.tripInstId') as number;
    const routeInstId = _get(node, 'data.routeInstId');
    return `${tripInstId}-${routeInstId}`;
  }

  protected getSelectedRowFromStoreId(selectedRow) {
    return null;
  }

  protected getSelectedStoreStateSelector() {}

  protected getSelectedRowColumnFieldName() {
    return TripPlanningGridFields.ROW_SELECTED;
  }

  // TRIP PLANNING component does not currently implement store for selected rows
  // So it need to process states differently and does not need the methods
  // getSelectedStoreStateSelector & getSelectedRowFromStoreId
  protected manageBoardStates(state: XpoAgGridBoardState) {
    switch (state.source) {
      case BoardStatesEnum.SORT_CHANGE:
      case BoardStatesEnum.FILTER_CHANGE:
        this.setPrimarySortOnCondition(
          TripPlanningGridFields.ROW_SELECTED,
          'desc',
          this.groupSelectedSubject.getValue()
        );
        break;
      case BoardStatesEnum.BOARD_DATA_FETCHER:
        this.updateNodeSelection(
          _map(state.selection, (trip) => `${_get(trip, 'tripInstId')}-${_get(trip, 'routeInstId')}`)
        );
        break;
      case BoardStatesEnum.SAVE_VIEW:
      case BoardStatesEnum.SAVE_VIEW_AS: {
        this.dataSource.refresh();
        break;
      }
    }
  }

  protected getBoardViewTemplates() {
    return [
      new TripPlanningRouteBoardTemplate(
        this.onShowRoute.bind(this),
        this.debouncedRefresh,
        this.timeService,
        this.equipmentPipe,
        this.removeLeadingZerosPipe,
        this.specialServicesService,
        this.dialog,
        this.routeService,
        this.routeColorService
      ),
    ];
  }

  protected getGridOptions() {
    return {
      getContextMenuItems: (params) => this.getContextMenuItems(params),
      frameworkComponents: pndFrameworkComponents,
      headerHeight: 40,
      rowHeight: 40,
      defaultColDef: { resizable: true },
      pinnedBottomRowData: [],
    };
  }

  protected mapViewDataStore(preferences) {
    return new TripPlanningViewDataStore(this.userPreferencesService, preferences);
  }

  protected getComponentName() {
    return TripPlanningComponentName;
  }
}
