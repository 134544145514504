import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { RouteBalancingMetricsComparator } from './route-balancing-metrics.interface';

@Component({
  selector: 'pnd-route-balancing-metrics',
  templateUrl: './route-balancing-metrics.component.html',
  styleUrls: ['./route-balancing-metrics.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RouteBalancingMetricsComponent implements OnInit {
  @Input() metrics: RouteBalancingMetricsComparator[];
  @Input() valid: boolean = true;

  constructor() {}

  ngOnInit() {}
}
