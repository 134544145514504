import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { isArray as _isArray, get as _get, isEmpty as _isEmpty } from 'lodash';
import { GenericErrorLazyTypedModel } from '../../models/generic-error-lazy-typed.model';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorDialogComponent implements OnInit {
  defaultMessage: string;
  defaultMessageWithMoreInfo: string;
  genericError: GenericErrorLazyTypedModel;
  mainMessage: string;
  title: string;

  constructor(private dialogRef: MatDialogRef<ErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {
    this.defaultMessage = 'An error has happened. Contact support.';
    this.defaultMessageWithMoreInfo = 'The process did not complete successfully due the following errors.';
    this.genericError = {};
    this.title = 'An Error Occurred';
  }

  ngOnInit() {
    this.genericError = new GenericErrorLazyTypedModel(this.data);

    this.initMainMessage();
  }

  initMainMessage(): void {
    let auxDefault = this.defaultMessage + '';

    const hasMoreInfo =
      _get(this.genericError, 'error.moreInfo') &&
      _isArray(this.genericError.error.moreInfo) &&
      this.genericError.error.moreInfo.length > 0;

    if (hasMoreInfo) {
      auxDefault = this.defaultMessageWithMoreInfo;

      this.genericError.error.moreInfo = this.genericError.error.moreInfo.filter(
        (value, index) => !_isEmpty(_get(value, 'message'))
      );
    }

    this.mainMessage = _get(this.genericError, 'error.message', auxDefault);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
