import { MatDialog } from '@angular/material';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoAgGridFormatters } from '@xpo-ltl/ngx-ltl-grid';
import { Route } from '@xpo-ltl/sdk-cityoperations';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { get as _get } from 'lodash';
import { take } from 'rxjs/operators';
import { PndRouteUtils } from '../../../../shared/route-utils';
import { EquipmentPipe, SpecialServicesService } from '../../shared';
import { ReleaseRouteComponent } from '../../shared/components/release-route';
import { ReleaseRouteDialogData } from '../../shared/components/release-route/release-route-dialog-data';
import { ReleaseRouteDialogResults } from '../../shared/components/release-route/release-route-dialog-results';
import { ReleaseRouteFormFields } from '../../shared/components/release-route/release-route-form-fields';
import { RouteSpecialServiceFilter } from '../../shared/filters/route-special-services-filter';
import { RemoveLeadingZerosPipe } from '../../shared/pipes/remove-leading-zeros.pipe';
import { RouteColorService } from '../../shared/services/route-color.service';
import { RoutePlanningGridFields } from './enums/route-planning-grid-fields.enum';
import { RoutePlanningGridHeaders } from './enums/route-planning-grid-headers.enum';

export class RoutePlanningRouteBoardTemplate extends XpoAgGridBoardViewTemplate {
  static templateId = 'RoutePlanningRouteBoardTemplate';

  constructor(
    showRouteCallback: (route: any) => void,
    private refreshCallback: () => void,
    highlightRouteCallback: (route: any) => void,
    timeService: XpoLtlTimeService,
    private equipmentPipe: EquipmentPipe,
    specialServicesService: SpecialServicesService,
    private removeLeadingZerosPipe: RemoveLeadingZerosPipe,
    private dialog: MatDialog,
    private routeColorService: RouteColorService
  ) {
    super({
      id: RoutePlanningRouteBoardTemplate.templateId,
      name: 'Routes',
      keyField: 'routeInstId',
      availableColumns: [
        {
          headerName: RoutePlanningGridHeaders.ROW_SELECTED,
          field: RoutePlanningGridFields.ROW_SELECTED,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          width: 60,
          lockPosition: true,
          sortable: true,
          pinnedRowCellRenderer: 'totalTextCellRenderer',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel = nodeA.isSelected() ? 1 : 0;
            const nodeBSel = nodeB.isSelected() ? 1 : 0;
            return nodeASel - nodeBSel;
          },
          cellStyle: (params) => {
            const routeInstId = _get(params, 'node.data.routeInstId');
            const isSelected = params.node.isSelected();
            const color =
              isSelected && routeInstId ? this.routeColorService.getColorForRoute(routeInstId) : 'transparent';
            const width = '4px';

            return {
              'border-left': isSelected ? `${width} solid ${color}` : `${width} solid transparent`,
            };
          },
        },
        {
          headerName: RoutePlanningGridHeaders.TIMELINE,
          field: RoutePlanningGridFields.TIMELINE,
          hide: true,
          headerComponent: 'routeTimelineHeaderRenderer',
          cellRenderer: 'routeTimelineCellRenderer',
          width: 750,
          pinned: 'left',
          sortable: false,
        },
        {
          headerName: RoutePlanningGridHeaders.ROUTE_NAME,
          field: RoutePlanningGridFields.ROUTE_NAME,
          cellRenderer: 'routeActionLinkCellRenderer',
          cellRendererParams: { onClick: showRouteCallback, onHover: highlightRouteCallback },
          width: 150,
          sortable: true,
          sort: 'asc',
          valueGetter: (params) => {
            return PndRouteUtils.getRouteId(params.data);
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const routeA = PndRouteUtils.getRouteId(nodeA.data);
            const routeB = PndRouteUtils.getRouteId(nodeB.data);
            return routeA.localeCompare(routeB);
          },
        },
        {
          headerName: RoutePlanningGridHeaders.SIC,
          field: RoutePlanningGridFields.SIC,
          width: 100,
        },
        {
          headerName: RoutePlanningGridHeaders.SPECIAL_SERVICES,
          width: 170,
          valueGetter: (params) => _get(params, 'data.specialServices', []),
          cellRenderer: 'specialServicesCellRenderer',
          comparator: (valueA, valueB, nodeA, nodeB) =>
            specialServicesService.getSpecialServicesComparator(nodeA, nodeB),
        },
        {
          headerName: RoutePlanningGridHeaders.EARLIEST_SHIPMENT_ETA,
          field: RoutePlanningGridFields.EARLIEST_SHIPMENT_ETA,
          width: 160,
        },
        {
          headerName: RoutePlanningGridHeaders.LATEST_SHIPMENT_ETA,
          field: RoutePlanningGridFields.LATEST_SHIPMENT_ETA,
          width: 160,
        },
        {
          headerName: RoutePlanningGridHeaders.BILLS,
          field: RoutePlanningGridFields.BILLS,
          width: 56,
          type: 'numericColumn',
        },
        {
          headerName: RoutePlanningGridHeaders.MM,
          field: RoutePlanningGridFields.MM,
          width: 56,
          type: 'numericColumn',
        },
        {
          headerName: RoutePlanningGridHeaders.WEIGHT,
          field: RoutePlanningGridFields.WEIGHT,
          width: 110,
          type: 'numericColumn',
          valueFormatter: XpoAgGridFormatters.formatNumber,
        },
        {
          headerName: RoutePlanningGridHeaders.DOOR,
          field: RoutePlanningGridFields.DOOR,
          type: 'numericColumn',
          width: 74,
          cellStyle: (param) => (_get(param, 'data.routeInstId') ? { 'background-color': '#fff8bc' } : null),
          cellRenderer: 'actionLinkCellRenderer',
          valueFormatter: (param) => this.removeLeadingZerosPipe.transform(param.value),
          cellRendererParams: {
            onClick: (event$) => {
              this.showUpdateRouteDialog(event$, ReleaseRouteFormFields.DoorNumber);
            },
          },
          comparator: (curr, prev) => curr - prev,
        },
        {
          headerName: RoutePlanningGridHeaders.TRAILER,
          field: RoutePlanningGridFields.TRAILER,
          width: 120,
          cellStyle: (param) => (_get(param, 'data.routeInstId') ? { 'background-color': '#fff8bc' } : null),
          cellRenderer: 'actionLinkCellRenderer',
          cellRendererParams: {
            onClick: (event$) => {
              this.showUpdateRouteDialog(event$, ReleaseRouteFormFields.TrailerNumber);
            },
          },
          valueFormatter: (param) => {
            return equipmentPipe.transform(
              _get(param, 'data.equipmentIdPrefix', ''),
              _get(param, 'data.equipmentIdSuffixNbr', 0)
            );
          },
        },
        {
          headerName: RoutePlanningGridHeaders.DOCK_LOCATION,
          field: RoutePlanningGridFields.DOCK_LOCATION,
          width: 120,
          cellStyle: (param) => (_get(param, 'data.routeInstId') ? { 'background-color': '#fff8bc' } : null),
          cellRenderer: 'actionLinkCellRenderer',
          cellRendererParams: {
            onClick: (event$) => {
              this.showUpdateRouteDialog(event$, ReleaseRouteFormFields.DockLocation);
            },
          },
          valueFormatter: (param) => _get(param, 'data.equipmentDockLocation.dockName', ''),
        },
        {
          headerName: RoutePlanningGridHeaders.NEAREST_DOOR,
          field: RoutePlanningGridFields.NEAREST_DOOR,
          type: 'numericColumn',
          width: 120,
          cellStyle: (param) => (_get(param, 'data.routeInstId') ? { 'background-color': '#fff8bc' } : null),
          cellRenderer: 'actionLinkCellRenderer',
          cellRendererParams: {
            onClick: (event$) => {
              this.showUpdateRouteDialog(event$, ReleaseRouteFormFields.NearestDoor);
            },
          },
          valueFormatter: (param) =>
            this.removeLeadingZerosPipe.transform(_get(param, 'data.equipmentDockLocation.dockClosestDoorNbr', '')),
        },
      ],
      availableFilters: [
        // Currently Search will be disabled until BE can support it.
        // new XpoQuickSearchFilter('q', { disableAutofocus: true }),
        new RouteSpecialServiceFilter('special-services'),
      ],
      allowAdditional: true,
    });
  }

  private showUpdateRouteDialog(route: Route, focusedField: ReleaseRouteFormFields): void {
    const dialogRef = this.dialog.open(ReleaseRouteComponent, {
      data: <ReleaseRouteDialogData>{
        focusedField: focusedField,
        routeName: PndRouteUtils.getRouteId(route),
        routeInstId: route.routeInstId,
        routeStatusCd: route.statusCd,
        routeCategoryCd: route.categoryCd,
        xdockReleaseInd: false,
        doorNbr: route.plannedDoor,
        trailerNbr: this.equipmentPipe.transform(route.equipmentIdPrefix, route.equipmentIdSuffixNbr),
        dockLocation: _get(route, 'equipmentDockLocation.dockName'),
        nearestDoorNbr: _get(route, 'equipmentDockLocation.dockClosestDoorNbr'),
      },
      disableClose: true,
      hasBackdrop: true,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((results: ReleaseRouteDialogResults) => {
        if (results) {
          this.refreshCallback();
        }
      });
  }
  // /**
  //  * Creates a route planning view. If criteria is defined create the plan with it, otherwise it will create the route plan with an empty criteria.
  //  * @param id
  //  * @param name
  //  * @param criteria
  //  */
  // createRoutePlanningView(id: string, name: string, criteria: XpoFilterCriteria): XpoBoardView {
  //   return this.createView({
  //     closeable: true,
  //     criteria: criteria,
  //     id: id,
  //     name: name,
  //     visible: true,
  //   });
  // }
}
