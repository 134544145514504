import { Injectable } from '@angular/core';
import {
  LocationApiService,
  ListLocationFeatureValuesByFeaturePath,
  ListLocationFeatureValuesByFeatureQuery,
  FeatureSetting,
} from '@xpo-ltl-2.0/sdk-location';
import {
  each as _each,
  filter as _filter,
  find as _find,
  get as _get,
  isEmpty as _isEmpty,
  toUpper as _toUpper,
} from 'lodash';
import { Observable, of, forkJoin } from 'rxjs';
import { take, map, catchError, retryWhen, delay, mergeMap } from 'rxjs/operators';
import { enumToArray } from '../../functions';
import { UserRoleService } from '../user-role/user-role.service';
import { FeatureTypes } from './feature-types.enum';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  private features: FeatureSetting[] = [];
  constructor(private locationService: LocationApiService, private userRoleService: UserRoleService) {}

  loadFeatures$(): Observable<void> {
    this.features = [];

    const listLocationFeatureValuesByFeature = (featureCd) => {
      const pathParams: ListLocationFeatureValuesByFeaturePath = { featureCd };
      const queryParams = { ...new ListLocationFeatureValuesByFeatureQuery() };

      return this.locationService.listLocationFeatureValuesByFeature(pathParams, queryParams).pipe(
        retryWhen((errors) => errors.pipe(delay(250), take(10))),
        catchError(() => of(undefined)),
        map((response) => {
          this.features = _get(response, 'locationFeatures', []);
          return;
        })
      );
    };

    const source = of(enumToArray(FeatureTypes));
    return source.pipe(mergeMap((q) => forkJoin(...q.map(listLocationFeatureValuesByFeature))));
  }

  getFeatureValue(sicCd: string, featureType: FeatureTypes, defaultValue?: string): string {
    let feature = _find(
      this.features,
      (f) =>
        _toUpper(f.featureCd) === _toUpper(featureType) &&
        _isEmpty(f.sicCd) &&
        _isEmpty(f.employeeId) &&
        _isEmpty(f.deviceId)
    );
    if (feature) {
      return feature.settingValue;
    }

    feature = _find(
      this.features,
      (f) =>
        _toUpper(f.featureCd) === _toUpper(featureType) &&
        (_toUpper(f.employeeId) === _toUpper(this.userRoleService.getEmployeeId()) ||
          (_toUpper(f.sicCd) === _toUpper(sicCd) && _isEmpty(f.employeeId)))
    );
    return feature ? feature.settingValue : defaultValue;
  }
}
