import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatSidenavModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { XpoGMapBoardModule } from '@xpo-ltl/ngx-ltl-board-gmaps';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoLtlPipeModule } from '@xpo/ngx-ltl';
import { InboundPlanningSharedModule } from '../../shared';
import { MarkerContextMenuComponent } from './components/marker-context-menu/marker-context-menu.component';
import { AssignedStopMarkerInfoComponent } from './components/marker-info-window/assigned-stop-marker-info/assigned-stop-marker-info.component';
import { DriverMarkerInfoComponent } from './components/marker-info-window/driver-marker-info/driver-marker-info.component';
import { PlanningRouteShipmentMarkerInfoComponent } from './components/marker-info-window/planning-route-shipment-marker-info/planning-route-shipment-marker-info.component';
import { UnassignedDeliveryMarkerInfoComponent } from './components/marker-info-window/unassigned-delivery-marker-info/unassigned-delivery-marker-info.component';
import { UnassignedPickupMarkerInfoComponent } from './components/marker-info-window/unassigned-pickup-marker-info/unassigned-pickup-marker-info.component';
import { OffsetSnazzyInfoWindow } from './components/offset-snazzy-info-window/offset-snazzy-info-window.component';
import { ResequenceRouteStopsComponent } from './components/resequence-route-stops/resequence-route-stops.component';
import { AssignedStopsLayerComponent } from './layers/assigned-stops-layer/assigned-stops-layer.component';
import { DriverLocationLayerComponent } from './layers/driver-location-layer/driver-location-layer.component';
import { PlanningRouteShipmentsLayerComponent } from './layers/planning-route-shipments-layer/planning-route-shipments-layer.component';
import { RoutesLayerComponent } from './layers/routes-layer/routes-layer.component';
import { UnassignedDeliveriesLayerComponent } from './layers/unassigned-deliveries-layer/unassigned-deliveries-layer.component';
import { UnassignedPickupsLayerComponent } from './layers/unassigned-pickups-layer/unassigned-pickups-layer.component';
import { UnmappedStopsLayerComponent } from './layers/unmapped-stops-layer/unmapped-stops-layer.component';
import { UnmappedStopsMarkerInfowindowComponent } from './layers/unmapped-stops-layer/unmapped-stops-marker-infowindow/unmapped-stops-marker-infowindow.component';
import { PndMapMarkerDirective } from './map-marker.directive';
import { PlanningMapComponent } from './planning-map.component';

import { AngularSplitModule } from 'angular-split';
import { PlanningMapSplitterComponent } from '../planning-map-splitter/planning-map-splitter.component';
import { MapSplitPanelHostDirective } from './components/map-split-panels/map-split-panel-host.directive';
import { PickupsPanelComponent } from './components/map-split-panels/pickups-panel.component';
import { ShipmentsPanelComponent } from './components/map-split-panels/shipments-panel.component';

@NgModule({
  declarations: [
    AssignedStopMarkerInfoComponent,
    DriverMarkerInfoComponent,
    OffsetSnazzyInfoWindow,
    UnassignedDeliveryMarkerInfoComponent,
    UnassignedPickupMarkerInfoComponent,
    PlanningRouteShipmentMarkerInfoComponent,
    PlanningMapSplitterComponent,
    PlanningMapComponent,
    PndMapMarkerDirective,
    MapSplitPanelHostDirective,
    ResequenceRouteStopsComponent,
    DriverLocationLayerComponent,
    UnassignedPickupsLayerComponent,
    UnassignedDeliveriesLayerComponent,
    AssignedStopsLayerComponent,
    RoutesLayerComponent,
    UnmappedStopsLayerComponent,
    PlanningRouteShipmentsLayerComponent,
    UnmappedStopsMarkerInfowindowComponent,
    MarkerContextMenuComponent,
    ShipmentsPanelComponent,
    PickupsPanelComponent,
  ],
  exports: [PlanningMapSplitterComponent, PlanningMapComponent, ResequenceRouteStopsComponent],
  entryComponents: [PlanningMapSplitterComponent, PlanningMapComponent, ShipmentsPanelComponent, PickupsPanelComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    AgmCoreModule,
    AgmSnazzyInfoWindowModule,
    MatMenuModule,
    MatTableModule,
    XpoGMapBoardModule,
    XpoIconModule,
    InboundPlanningSharedModule,
    XpoLtlPipeModule,
    AngularSplitModule.forRoot(),
  ],
})
export class PlanningMapModule {}
