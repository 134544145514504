import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

export enum SaveChangesModalEnum {
  DO_NOT_SAVE = 'DO_NOT_SAVE',
  CANCEL = 'CANCEL',
}

@Component({
  selector: 'app-save-changes-modal',
  templateUrl: './save-changes-modal.component.html',
  styleUrls: ['./save-changes-modal.component.scss'],
})
export class SaveChangesModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<SaveChangesModalComponent>) {}

  ngOnInit() {}

  doNotSave() {
    this.dialogRef.close(SaveChangesModalEnum.DO_NOT_SAVE);
  }

  cancel() {
    this.dialogRef.close(SaveChangesModalEnum.CANCEL);
  }
}
