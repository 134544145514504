import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { get as _get } from 'lodash';

@Component({
  selector: 'pnd-circle-cell-renderer',
  templateUrl: './circle-cell-renderer.component.html',
  styleUrls: ['./circle-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-CircleCellRenderer' },
})
export class CircleCellRendererComponent implements ICellRendererAngularComp {
  visible = false;
  color = '';

  agInit(params: any): void {
    this.visible = _get(params, params.field);
    this.color = params.color || 'black';
  }

  refresh(params: any): boolean {
    return true;
  }
}
