import { tassign } from 'tassign';
import { ActionTypes, Actions } from './route-balancing.actions';
import { initialState, State } from './route-balancing.state';

export function RouteBalancingReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SetOpenRouteBalancingPanel:
      return tassign(state, {
        openRouteBalancingPanel: action.payload.openRouteBalancingPanel,
      });

    case ActionTypes.SetManualSequencingRoutes:
      return tassign(state, {
        routes: action.payload.routes,
      });

    case ActionTypes.SetPinFirst:
      return tassign(state, {
        pinFirst: action.payload.pinFirst,
      });

    case ActionTypes.SetPinLast:
      return tassign(state, {
        pinLast: action.payload.pinLast,
      });

    case ActionTypes.SetLanesDirty:
      return tassign(state, {
        lanesDirty: action.payload.lanesDirty,
      });

    case ActionTypes.SetCanOpenRouteBalancing:
      return tassign(state, {
        canOpenRouteBalancing: action.payload.canOpenRouteBalancing,
      });

    default:
      return state;
  }
}
