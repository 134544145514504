import { tassign } from 'tassign';
import { Actions, ActionTypes } from './unassigned-deliveries-store.actions';
import { initialState, State } from './unassigned-deliveries-store.state';

export function unassignedDeliveriesReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setSearchCriteria: {
      return tassign(state, {
        criteria: action.payload.criteria,
      });
    }

    case ActionTypes.setLastUpdate: {
      return tassign(state, {
        lastUpdate: action.payload.lastUpdate,
      });
    }

    case ActionTypes.setSelectedDeliveries: {
      return tassign(state, {
        selectedDeliveries: action.payload.selectedDeliveries,
      });
    }

    case ActionTypes.setFocusedDelivery: {
      return tassign(state, {
        focusedDelivery: action.payload.focusedDelivery,
      });
    }

    case ActionTypes.setClickedDelivery: {
      return tassign(state, {
        clickedDelivery: action.payload.clickedDelivery,
      });
    }

    case ActionTypes.setBoundingSearchArea: {
      return tassign(state, {
        boundingSearchArea: action.payload.boundingSearchArea,
      });
    }

    default: {
      return state;
    }
  }
}
