import { Unsubscriber } from '@xpo/ngx-ltl';
import { CellMouseOutEvent, CellMouseOverEvent, GridOptions } from 'ag-grid-community';
import { get as _get, invoke as _invoke } from 'lodash';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { pairwise, takeUntil } from 'rxjs/operators';

interface RowHoverState<DATA_TYPE> {
  mouseOver: boolean;
  row: number;
  col: string;
  data: DATA_TYPE;
}

export class RowHoverManager<DATA_TYPE> {
  private unsubscriber = new Unsubscriber();
  private hoverStateSubject = new BehaviorSubject<RowHoverState<DATA_TYPE>>(undefined);
  private hoverState$ = this.hoverStateSubject.asObservable();
  private clearFocusedRowTimer: Subscription;

  constructor(gridOptions: GridOptions, private setFocus: (DATA_TYPE) => void, private clearFocus: () => void) {
    gridOptions.onCellMouseOver = (event: CellMouseOverEvent) => {
      this.hoverStateSubject.next({
        mouseOver: true,
        row: _get(event, 'rowIndex'),
        col: _invoke(event.column, 'getUniqueId'),
        data: _get(event, 'data'),
      });
    };

    gridOptions.onCellMouseOut = (event: CellMouseOutEvent) => {
      this.hoverStateSubject.next({
        mouseOver: false,
        row: _get(event, 'rowIndex'),
        col: _invoke(event.column, 'getUniqueId'),
        data: _get(event, 'data'),
      });
    };

    this.hoverState$.pipe(pairwise(), takeUntil(this.unsubscriber.done)).subscribe(([previous, current]) => {
      this.onHover(previous, current);
    });
  }

  destroy() {
    this.unsubscriber.complete();
  }

  reprime() {
    // need to push another out state with invalid row Id so the next real
    // event will correctly trigger the focus
    this.hoverStateSubject.next({
      mouseOver: false,
      row: -1,
      col: '',
      data: undefined,
    });
  }

  private onHover(previous: RowHoverState<DATA_TYPE>, current: RowHoverState<DATA_TYPE>): void {
    _invoke(this.clearFocusedRowTimer, 'unsubscribe');
    this.clearFocusedRowTimer = undefined;

    if (previous && previous.mouseOver && !current.mouseOver) {
      // mouse moved out of a cell.
      this.clearFocusedRowTimer = timer(5).subscribe(() => {
        this.reprime();
        _invoke(this, 'clearFocus');
      });
    }

    if (!_get(previous, 'mouseOver', false) && current.mouseOver) {
      // mouse moved into a cell
      if (_get(previous, 'row') !== current.row) {
        // moused over a new row.  Set as focused
        _invoke(this, 'setFocus', current.data);
      }
    }
  }
}
