import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-lane-creator',
  templateUrl: './lane-creator.component.html',
  styleUrls: ['./lane-creator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LaneCreatorComponent implements OnInit {
  displayOptions: boolean = false;

  @Input() height: number = 0;

  @Output() newRoute = new EventEmitter<void>();
  @Output() existingRoute = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  onNewRoute() {
    this.newRoute.emit();
    this.displayOptions = false;
  }

  onExistingRoute() {
    this.existingRoute.emit();
    this.displayOptions = false;
  }
}
