import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, StateKey } from './unassigned-deliveries-store.state';

export const unassignedDeliveriesState = createFeatureSelector<State>(StateKey);

export const searchCriteria = createSelector(unassignedDeliveriesState, (state: State) => state.criteria);

export const lastUpdate = createSelector(unassignedDeliveriesState, (state: State) => state.lastUpdate);

export const unassignedDeliveriesSelected = createSelector(
  unassignedDeliveriesState,
  (state: State) => state.selectedDeliveries
);

export const unassignedDeliveryFocused = createSelector(
  unassignedDeliveriesState,
  (state: State) => state.focusedDelivery
);

export const unassignedDeliveryClicked = createSelector(
  unassignedDeliveriesState,
  (state: State) => state.clickedDelivery
);

export const unassignedDeliveriesBoundingSearchArea = createSelector(
  unassignedDeliveriesState,
  (state: State) => state.boundingSearchArea
);
