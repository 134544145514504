import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { ValidationRegexPatterns } from '@xpo-ltl/common-services';
import { Unsubscriber, XpoLtlTimeService } from '@xpo/ngx-ltl';
import { get as _get, isEmpty as _isEmpty, maxBy as _maxBy, forEach as _forEach } from 'lodash';
import * as moment from 'moment-timezone';
import { timer } from 'rxjs';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { GlobalFilterStoreSelectors, PndStoreState, RoutesStoreSelectors } from '../../../../../../store';
import { RouteBalancingActions, RouteBalancingSelectors } from '../../../../../../store/route-balancing-store';
import { StoreSourcesEnum } from '../../../../../shared/enums/store-sources.enum';
import { TimeFormatErrorEnum } from '../../../../../shared/enums/time-format-error.enum';
import { TimeUtil } from '../../../../../shared/services/time-format.util';
import { RouteBoardLane } from '../../../classes/board/route-board-lane.model';
import { StopCardMouseOver } from '../../../classes/interfaces/stop-card-mouseover.interface';
import { StopCard } from '../../../classes/stop-card.model';

@Component({
  selector: 'app-route-lane',
  templateUrl: './route-lane.component.html',
  styleUrls: ['./route-lane.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RouteLaneComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('currentCardContent', { static: true }) currentCardContent;

  @Input() lane: RouteBoardLane;
  @Input() height: number = 0;
  @Output() drop = new EventEmitter<CdkDragDrop<StopCard[]>>();
  @Output() dispatchStops = new EventEmitter<StopCard[]>();
  @Output() unassign = new EventEmitter<StopCard>();
  @Output() assign = new EventEmitter<StopCard>();
  @Output() remove = new EventEmitter<string>();
  @Output() startTimeChanged = new EventEmitter<void>();
  @Output() autosequence = new EventEmitter<void>();
  @Output() manualsequence = new EventEmitter<void>();
  @Output() stopCardMouseOver = new EventEmitter<StopCardMouseOver>();
  @Output() dragStarted = new EventEmitter<StopCard>();
  @Output() dragReleased = new EventEmitter<StopCard>();

  readonly ValidationRegexPatterns = ValidationRegexPatterns;

  private unsubscriber = new Unsubscriber();
  private lanePreviousStartTime: string;
  private planDate: Date;
  private isStartTimeFocused: boolean = false;

  startTime: FormControl;
  timeZone: string;

  errors: string[] = [];
  warnings: string[] = [];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private pndStore$: Store<PndStoreState.State>,
    private changeRef: ChangeDetectorRef,
    private timeService: XpoLtlTimeService
  ) {
    this.matIconRegistry.addSvgIcon(
      'autoSequence',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/draw-polygon.svg')
    );

    this.pndStore$
      .select(GlobalFilterStoreSelectors.selectGlobalFilterState)
      .pipe(take(1))
      .subscribe((global) => {
        this.planDate = global.planDate;

        this.timeService
          .timezoneForSicCd$(global.sic)
          .pipe(take(1))
          .subscribe((timeZone) => {
            this.timeZone = timeZone;
          });
      });
  }

  ngOnInit() {
    this.startTime = new FormControl(
      this.lane.startTime,
      Validators.compose([this.timeFormatValidatorFunction(), Validators.required])
    );

    if (!this.lane.canResequenceRoute) {
      if (this.startTime) {
        this.startTime.disable();
      }
    }

    this.lane.isStartTimeValid = this.startTime ? this.startTime.valid : false;

    this.startTime.valueChanges
      .pipe(takeUntil(this.unsubscriber.done))
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.lane.startTime$.next(value);
        this.lane.isStartTimeTouched = true;
        this.lane.autoSequencePerformed = false;
        this.lane.isStartTimeValid = this.startTime.valid;

        if (!!this.lane.startTime && this.lane.startTime !== this.lanePreviousStartTime && this.startTime.valid) {
          this.lanePreviousStartTime = this.lane.startTime;
          this.startTimeChanged.emit();
        }
      });

    this.lane.startTime$.pipe(distinctUntilChanged()).subscribe((value) => {
      this.startTime.setValue(value);
    });

    // Hover from the map
    this.pndStore$
      .select(RoutesStoreSelectors.focusedStopForSelectedRoute)
      .pipe(
        takeUntil(this.unsubscriber.done),
        filter((stop) => stop.source && stop.source !== StoreSourcesEnum.ROUTE_BALANCING_BOARD)
      )
      .subscribe((stop) => {
        this.onMouseOver({
          mouseOver: !!stop.id,
          stop:
            stop.id && stop.id.routeInstId === this.lane.routeInstId
              ? this.lane.assignedStops.find((s) => {
                  return (
                    s.routeInstId === stop.id.routeInstId &&
                    ((s.seqNo && s.seqNo === stop.id.seqNo) || (s.origSeqNo && s.origSeqNo === stop.id.origSeqNo))
                  );
                })
              : undefined,
          fromStore: true,
          scrollIntoView: !!stop.id,
        });
      });

    // Handle resequencing
    this.pndStore$
      .select(RoutesStoreSelectors.resequencedRouteData)
      .pipe(
        takeUntil(this.unsubscriber.done),
        filter(
          (resequencedRouteData) =>
            !!resequencedRouteData[this.lane.routeInstId] &&
            !_isEmpty(resequencedRouteData[this.lane.routeInstId].newResequencingStops)
        )
      )
      .subscribe((resequencingRouteData) => {
        this.lane.pinnedStopsSubject$.next(resequencingRouteData[this.lane.routeInstId].pinnedStops);

        this.lane.assignedStopsSubject$.next(
          this.lane.sortStops(
            resequencingRouteData[this.lane.routeInstId].newResequencingStops,
            resequencingRouteData[this.lane.routeInstId].pinnedStops
          )
        );

        if (resequencingRouteData[this.lane.routeInstId].source === StoreSourcesEnum.PLANNING_MAP) {
          this.lane.isStopsTouched = true;
        }

        this.pndStore$
          .select(RouteBalancingSelectors.manualSequencingRoutes)
          .pipe(take(1))
          .subscribe((routes: number[]) => {
            if (routes.includes(this.lane.routeInstId)) {
              const routeData = { ...resequencingRouteData[this.lane.routeInstId] };
              if (routeData.pinnedStops.last) {
                routeData.newResequencingStops = resequencingRouteData[
                  this.lane.routeInstId
                ].newResequencingStops.filter((stop) => stop.origSeqNo !== routeData.pinnedStops.last.origSeqNo);
              }
              const lastSequencedStop = _maxBy(routeData.newResequencingStops, (stop) => stop.seqNo);

              if (lastSequencedStop) {
                timer(50)
                  .pipe(take(1))
                  .subscribe((_) => {
                    this.onMouseOver({
                      mouseOver: routeData.source !== StoreSourcesEnum.ROUTE_BALANCING_BOARD,
                      stop: lastSequencedStop,
                      fromStore: true,
                      scrollIntoView: true,
                    });
                  });

                // handle when manualsequence ended
                const ended = !resequencingRouteData[this.lane.routeInstId].newResequencingStops.find(
                  (stp) => !stp.seqNo
                );

                if (ended) {
                  const newRoutes = [...routes];

                  newRoutes.splice(
                    newRoutes.findIndex((routeInstId) => this.lane.routeInstId === routeInstId),
                    1
                  );

                  this.pndStore$.dispatch(
                    new RouteBalancingActions.SetManualSequencingRoutes({
                      routes: newRoutes,
                    })
                  );

                  if (newRoutes.length === 0) {
                    this.manualsequence.emit();
                  }
                }
              }
            }
          });
      });

    // Pin from the map
    this.pndStore$
      .select(RouteBalancingSelectors.pinnedFirst)
      .pipe(
        filter((stop: StopCard) => !!stop && stop.routeInstId === this.lane.routeInstId),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((stop: StopCard) => {
        const stops = this.lane.assignedStops;
        const pinnedStop = stops.find((s) => s.origSeqNo === stop.origSeqNo);
        if (pinnedStop) {
          this.onPinFirst(pinnedStop);

          timer(50)
            .pipe(take(1))
            .subscribe((_) => {
              this.onMouseOver({
                mouseOver: true,
                stop: pinnedStop,
                fromStore: true,
                scrollIntoView: true,
              });
            });
        }
      });

    this.pndStore$
      .select(RouteBalancingSelectors.pinnedLast)
      .pipe(
        filter((stop: StopCard) => !!stop && stop.routeInstId === this.lane.routeInstId),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((stop: StopCard) => {
        const stops = this.lane.assignedStops;
        const pinnedStop = stops.find((s) => s.origSeqNo === stop.origSeqNo);
        if (pinnedStop) {
          this.onPinLast(pinnedStop);

          timer(50)
            .pipe(take(1))
            .subscribe((_) => {
              this.onMouseOver({
                mouseOver: true,
                stop: pinnedStop,
                fromStore: true,
                scrollIntoView: true,
              });
            });
        }
      });

    this.lane.assignedStopsSubject$.pipe(takeUntil(this.unsubscriber.done)).subscribe((stops) => {
      this.updateInfoMessages(stops);
    });
  }

  ngAfterViewChecked(): void {
    this.updateCardColor();
  }

  updateCardColor(): void {
    const correspondingRouteCard: HTMLElement = _get(
      this.currentCardContent,
      'nativeElement.parentElement.parentElement.firstChild'
    );
    if (correspondingRouteCard) {
      correspondingRouteCard['style'].backgroundColor = this.lane.color || 'transparent';
      correspondingRouteCard['style'].borderTopLeftRadius = '2px';
      correspondingRouteCard['style'].borderTopRightRadius = '2px';
      correspondingRouteCard['style'].marginTop = '-1px';
      correspondingRouteCard['style'].height = '6px';
    }

    if (this.lane.focusStartTime && !this.isStartTimeFocused) {
      const elem = <HTMLInputElement>this.currentCardContent.nativeElement.querySelector('input');
      if (elem && !elem.disabled) {
        this.isStartTimeFocused = true;
        elem.focus();
      }
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  // #region: Validations

  timeFormatValidatorFunction(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const validTimeRegExp = new RegExp(TimeUtil.TimeFormatValidatorRegExpWithAnchors);
      const val = control.value;
      // TODO - moment WARN: 'Deprecation warning: value provided is not in a recognized RFC2822 or ISO format
      const valAsString = moment(`${moment(this.planDate).format('YYYY-MM-DD')} ${control.value || '00:00'}`)
        .seconds(0)
        .milliseconds(0)
        .format('YYYY-MM-DD HH:mm');

      const nowAsString = moment()
        .seconds(0)
        .milliseconds(0)
        .tz(this.timeZone)
        .format('YYYY-MM-DD HH:mm');

      // validate the control value is in the form "HH:MM",
      // where HH is between [00, 23], and MM is between [00, 59]
      if (_isEmpty(val)) {
        return null; // handled by required validator!
      }
      if (!validTimeRegExp.test(val) || val === '00:00') {
        return { required: true };
      }
      return moment(valAsString).isBefore(nowAsString) ? { [TimeFormatErrorEnum.InvalidFormat]: true } : null;
    };
  }

  isPinnedFirst(): boolean {
    return this.lane.pinnedStops && !!this.lane.pinnedStops.first;
  }

  isPinnedLast(): boolean {
    return this.lane.pinnedStops && !!this.lane.pinnedStops.last;
  }

  canResequence(): boolean {
    return !!this.lane.canResequenceRoute && !!this.lane.isStartTimeValid;
  }

  canRemove(): boolean {
    return this.lane.isSameStops;
  }

  // #endregion

  // #region: Events

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  onblur(event: FocusEvent): void {
    const startTimeCtrl = this.startTime;

    if (!startTimeCtrl.valid && startTimeCtrl.value) {
      startTimeCtrl.setValue(TimeUtil.formatStringToTimeStringByFillingWithZeros(startTimeCtrl.value));
    }
    this.lane.isStartTimeValid = startTimeCtrl.valid;
  }

  onkeyup(event: KeyboardEvent): void {
    this.lane.isStartTimeValid = false;

    const startTimeCtrl = this.startTime;
    if (
      event.key !== 'Backspace' &&
      startTimeCtrl.value &&
      ((startTimeCtrl.value.length === 4 && !startTimeCtrl.value.includes(':')) ||
        (startTimeCtrl.value.length === 5 && startTimeCtrl.value.includes(':')))
    ) {
      startTimeCtrl.setValue(TimeUtil.formatStringToTimeStringByFillingWithZeros(startTimeCtrl.value));
    }
    this.lane.isStartTimeValid = startTimeCtrl.valid;
  }

  onDrop(event: CdkDragDrop<StopCard[]>) {
    this.drop.emit(event);
  }

  onRemove() {
    this.remove.emit(this.lane.laneId);
  }

  onUnassign(stop: StopCard) {
    this.unassign.emit(stop);
  }

  onAssign(stop: StopCard) {
    this.assign.emit(stop);
  }

  onToggleDLW(stop: StopCard) {
    stop.ignoreStopWindowInd = !stop.ignoreStopWindowInd;
    this.updateInfoMessages(this.lane.assignedStops);
  }

  // #endregion

  // #region: Actions

  onManualSequence() {
    this.pndStore$
      .select(RouteBalancingSelectors.manualSequencingRoutes)
      .pipe(take(1))
      .subscribe((routes: number[]) => {
        // Insert current routeInstId at the end of the array
        const index = routes.findIndex((routeInstId) => routeInstId === this.lane.routeInstId);

        if (index !== -1) {
          routes.splice(index, 1);
        }

        routes.push(this.lane.routeInstId);

        const assignedStops = this.lane.assignedStops;
        const pinnedStops = this.lane.pinnedStops;

        assignedStops.forEach((stop) => {
          if (!this.isPinnedFirst() && !this.isPinnedLast()) {
            stop.seqNo = undefined;
          } else {
            if (this.isPinnedFirst() && stop.seqNo !== pinnedStops.first.seqNo) {
              if (this.isPinnedLast()) {
                if (stop.seqNo !== pinnedStops.last.seqNo) {
                  stop.seqNo = undefined;
                }
              } else {
                stop.seqNo = undefined;
              }
            } else if (this.isPinnedLast() && stop.seqNo !== pinnedStops.last.seqNo) {
              if (this.isPinnedFirst()) {
                if (stop.seqNo !== pinnedStops.first.seqNo) {
                  stop.seqNo = undefined;
                }
              } else {
                stop.seqNo = undefined;
              }
            }
          }
        });

        this.pndStore$.dispatch(new RouteBalancingActions.SetManualSequencingRoutes({ routes: [...routes] }));
        this.dispatchStops.emit(assignedStops);
      });
  }

  onAutoSequence(): void {
    this.lane.autoSequencePerformed = true;
    this.autosequence.emit();
  }

  onPinFirst(stop: StopCard) {
    stop.seqNo = 1;

    let pinnedStops = this.lane.pinnedStops;
    if (pinnedStops) {
      pinnedStops.first = stop;

      if (pinnedStops.last && stop.origSeqNo === pinnedStops.last.origSeqNo) {
        pinnedStops.last = undefined;
      }
    } else {
      pinnedStops = {
        first: stop,
        last: undefined,
      };
    }

    this.lane.pinnedStopsSubject$.next(pinnedStops);

    this.lane.assignedStops.forEach((_stop) => {
      if (
        _stop.origSeqNo !== _get(pinnedStops, 'first.origSeqNo', -1) &&
        _stop.origSeqNo !== _get(pinnedStops, 'last.origSeqNo', -1)
      ) {
        _stop.seqNo = undefined;
      }
    });

    this.dispatchStops.emit(this.lane.assignedStops);
    this.lane.isStopsTouched = true;
  }

  onPinLast(stop: StopCard) {
    stop.seqNo = this.lane.assignedStops.length;

    let pinnedStops = this.lane.pinnedStops;
    if (pinnedStops) {
      pinnedStops.last = stop;

      if (pinnedStops.first && stop.origSeqNo === pinnedStops.first.origSeqNo) {
        pinnedStops.first = undefined;
      }
    } else {
      pinnedStops = {
        first: undefined,
        last: stop,
      };
    }

    this.lane.pinnedStopsSubject$.next(pinnedStops);

    this.lane.assignedStops.forEach((_stop) => {
      if (
        _stop.origSeqNo !== _get(pinnedStops, 'first.origSeqNo', -1) &&
        _stop.origSeqNo !== _get(pinnedStops, 'last.origSeqNo', -1)
      ) {
        _stop.seqNo = undefined;
      }
    });

    this.dispatchStops.emit(this.lane.assignedStops);
    this.lane.isStopsTouched = true;
  }

  onUnPinFirst(stop: StopCard) {
    let pinnedStops = this.lane.pinnedStops;

    if (pinnedStops) {
      pinnedStops.first = undefined;
    } else {
      pinnedStops = {
        first: undefined,
        last: undefined,
      };
    }

    this.lane.pinnedStopsSubject$.next(pinnedStops);

    this.dispatchStops.emit(this.lane.assignedStops);
    this.lane.isStopsTouched = true;
  }

  onUnPinLast(stop: StopCard) {
    let pinnedStops = this.lane.pinnedStops;

    if (pinnedStops) {
      pinnedStops.last = undefined;
    } else {
      pinnedStops = {
        first: undefined,
        last: undefined,
      };
    }

    this.lane.pinnedStopsSubject$.next(pinnedStops);

    this.dispatchStops.emit(this.lane.assignedStops);
    this.lane.isStopsTouched = true;
  }

  onMouseOver(event: StopCardMouseOver): void {
    event.currentCardContent = this.currentCardContent.nativeElement;
    event.color = this.lane.color;
    this.stopCardMouseOver.emit(event);
  }

  // #endregion

  cdkDragStarted(stop: StopCard) {
    this.dragStarted.emit(stop);
  }

  cdkDragReleased(stop: StopCard) {
    this.dragReleased.emit(stop);
  }

  updateInfoMessages(stops: StopCard[]): void {
    this.warnings = [];
    this.errors = [];

    _forEach(stops, (stop, index) => {
      if (stop.ignoreStopWindowInd) {
        this.warnings.push(`Stop ${index + 1} - DLW will be ignored by the auto-sequencer`);
      }

      if (stop.isExcessIdleTime()) {
        this.errors.push(`Stop ${index + 1} - Idle time. ETA is before DLW`);
      }

      if (stop.potentialMissedStopWindow) {
        this.errors.push(`Stop ${index + 1} - Missed stop. ETA is after DLW`);
      }

      if (stop.coordinatesUnknown) {
        this.errors.push(`Stop ${index + 1} - Coordinates unknown. Auto-sequence disabled`);
      }
    });
  }
}
