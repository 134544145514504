import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { get as _get, size as _size } from 'lodash';
import { MapMarkerInfo } from '../../../../../shared/models';

@Component({
  selector: 'app-planning-route-shipment-marker-info',
  templateUrl: './planning-route-shipment-marker-info.component.html',
  styleUrls: ['../marker-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PlanningRouteShipmentMarkerInfoComponent {
  @Input() markerInfo: MapMarkerInfo;

  get stopWindowTime(): string {
    let time = _get(this.markerInfo, 'stopWindowTime');

    if (_size(time) === 0) {
      time = 'N/A';
    }

    return time;
  }

  constructor() {}
}
