import { Component, Input, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PluralMaps } from '../../classes/plural-maps';
import { SelectionSummaryData } from './selection-summary-data.class';

@Component({
  selector: 'pnd-selection-summary',
  templateUrl: './selection-summary.component.html',
  styleUrls: ['./selection-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionSummaryComponent {
  private summarySubject = new BehaviorSubject<SelectionSummaryData>(undefined);
  readonly summary$ = this.summarySubject.asObservable();

  @Input() set summary(value: SelectionSummaryData) {
    this.summarySubject.next(value);
  }

  readonly PluralMaps = PluralMaps;
}
