import { TripDetail } from '@xpo-ltl/sdk-cityoperations';
import { TripPlanningGridItem } from '../../inbound-planning/components/trip-planning/models/trip-planning-grid-item.model';
import { TripsSearchCriteria } from './trips-search-criteria.interface';

export const StateKey = 'tripsState';

export interface State {
  readonly searchCriteria: TripsSearchCriteria;
  readonly trips: TripDetail[];
  readonly selectedTrips: TripPlanningGridItem[];
  readonly transactionTimestampUTC: Date;
}

export const initialState: State = {
  searchCriteria: {},
  trips: [],
  selectedTrips: [],
  transactionTimestampUTC: undefined,
};
