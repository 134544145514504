import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, StateKey } from './geo-area-store.state';

export const selectGeoAreaState = createFeatureSelector<State>(StateKey);

export const geoAreaGeoAreas = createSelector(
  selectGeoAreaState,
  (state: State) => state.geoAreas
);

export const geoAreaSicBoundaries = createSelector(
  selectGeoAreaState,
  (state: State) => state.sicBoundaries
);
