export enum RouteStopsGridFields {
  ROUTE_ID = 'routeId',
  ROW_SELECTED = 'rowSelected',
  SEQ = 'seqNo',
  STOP_TYPE = 'stopType',
  CONSIGNEE = 'consigneeName',
  CONSIGNEE_ADDRESS = 'consigneeAddress',
  CONSIGNEE_CITY = 'consigneeCity',
  CONSIGNEE_ZIP = 'postalCd',
  SPECIAL_SERVICES = 'specialServices',
  LTR = 'ltr',
  BILLS = 'totalBills',
  MM = 'totalMotorMoves',
  WEIGHT = 'totalWeight',
  CUBE = 'totalCube',
  DELIVERY_WINDOW_TYPE = 'deliveryWindowType',
  DELIVERY_WINDOW_TIME = 'deliveryWindowTime',
  DELIVERY_WINDOW = 'deliveryWindowDate',
  ETA = 'estimatedArrival',
  ETD = 'estimatedDeparture',
}
