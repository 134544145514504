import { GoogleMapsAPIWrapper } from '@agm/core';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatRadioChange } from '@angular/material';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { select, Store } from '@ngrx/store';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { isUndefined as _isUndefined, size as _size } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { GeoLocationStoreSelectors, PndStoreState } from '../../../../store';
import { RouteBalancingSelectors } from '../../../../store/route-balancing-store';
import { WeatherLayer } from '../../models/weather-layer.interface.js';
import { MapToolbarService } from '../../services/map-toolbar.service';
import { UnassignedDeliveriesService } from '../../services/unassigned-deliveries.service';
import { UnassignedPickupsService } from '../../services/unassigned-pickups.service';
import { UnmappedStopsEditMode } from '../unmapped-stops/components/unmapped-stop-detail/unmapped-stops-edit-mode.enum';
import { MapToolbarWidgetItems } from './map-toolbar-widget-items';
import { WeatherLayerOptions } from './weather-layer-options.js';

@Component({
  selector: 'map-toolbar-widget',
  templateUrl: './map-toolbar-widget.component.html',
  styleUrls: ['./map-toolbar-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MapToolbarWidgetComponent implements AfterViewInit, OnDestroy {
  @Input()
  sicMarkerRotationAngle: number = 0;
  @Input()
  displaySicMarker: boolean = false;

  @Output()
  fitServiceCenterBounds = new EventEmitter<void>();
  @Output()
  fitLastSelection = new EventEmitter<void>();
  @Output()
  unassignedDeliveriesLayerChange = new EventEmitter<boolean>();
  @Output()
  unassignedPickupsLayerChange = new EventEmitter<boolean>();
  @Output()
  driverLocationLayerChange = new EventEmitter<boolean>();
  @Output()
  trafficLayerChange = new EventEmitter<boolean>();
  @Output()
  weatherLayerChange = new EventEmitter<WeatherLayer>();
  @Output()
  geoAreasLayerChange = new EventEmitter<boolean>();
  @Output()
  completedStopsLayerChange = new EventEmitter<boolean>();
  @Output()
  drawModeChange = new EventEmitter<boolean>();
  @Output()
  compassClicked = new EventEmitter<void>();

  @Output()
  toggleUnmappedDeliveriesChange = new EventEmitter<boolean>();
  @Output()
  toggleUnmappedPickupsChange = new EventEmitter<boolean>();

  @Input()
  filterSelectorPlaceholder: string = 'Select';
  @Input() displayLayersOutsideSelector: boolean = false;
  isUnassignedDeliveriesChecked: boolean = true;
  isUnassignedPickupsChecked: boolean = false;

  filterSelectorOptions: { id: string; name: string; active: boolean; permission: boolean }[] = [
    { id: MapToolbarWidgetItems.UnassignedDeliveries, name: 'Unassigned Deliveries', active: true, permission: false },
    { id: MapToolbarWidgetItems.UnassignedPickups, name: 'Unassigned Pickups', active: false, permission: false },
    { id: MapToolbarWidgetItems.GeoAreas, name: 'Geo Area', active: false, permission: true },
    { id: MapToolbarWidgetItems.CompletedStops, name: 'Completed Stops', active: true, permission: true },
    { id: MapToolbarWidgetItems.DriversLocation, name: 'Drivers Location', active: false, permission: true },
    { id: MapToolbarWidgetItems.Traffic, name: 'Traffic', active: false, permission: true },
    { id: MapToolbarWidgetItems.Weather, name: 'Weather', active: false, permission: true },
  ];

  isRouteBalancingActive: boolean = false;
  isWeatherModeActive: boolean = false;

  mapWeatherLayers: Array<WeatherLayer> = WeatherLayerOptions;

  selectedWeatherLayer = this.mapWeatherLayers[0];
  selectedFilters: string[] = [MapToolbarWidgetItems.UnassignedDeliveries, MapToolbarWidgetItems.CompletedStops];

  private unmappedPickupsCountSubject = new BehaviorSubject<number>(0);
  readonly unmappedPickupsCount$ = this.unmappedPickupsCountSubject.asObservable();

  private unmappedDeliveriesCountSubject = new BehaviorSubject<number>(0);
  readonly unmappedDeliveriesCount$ = this.unmappedDeliveriesCountSubject.asObservable();

  private unassignedDeliveriesLayerChangeSubject = new BehaviorSubject<boolean>(true);
  readonly unassignedDeliveriesLayerChange$ = this.unassignedDeliveriesLayerChangeSubject.asObservable();

  private googleMap: google.maps.Map;
  private unsubscriber = new Unsubscriber();

  controlsDisabled$: Observable<boolean>;

  constructor(
    private pndStore$: Store<PndStoreState.State>,
    private googleMapsApi: GoogleMapsAPIWrapper,
    private unassignedPickupsService: UnassignedPickupsService,
    private unassignedDeliveriesService: UnassignedDeliveriesService
  ) {
    this.controlsDisabled$ = this.pndStore$
      .select(GeoLocationStoreSelectors.editMode)
      .pipe(map((mode: UnmappedStopsEditMode) => !_isUndefined(mode)));

    this.pndStore$
      .pipe(select(RouteBalancingSelectors.openRouteBalancingPanel), takeUntil(this.unsubscriber.done))
      .subscribe((isRouteBalancingActive: boolean) => {
        this.isRouteBalancingActive = isRouteBalancingActive;
      });
  }

  ngAfterViewInit(): void {
    this.googleMapsApi.getNativeMap().then((googleMap) => {
      if (googleMap) {
        this.googleMap = (googleMap as any) as google.maps.Map;
        this.googleMap.controls[google.maps.ControlPosition.TOP_LEFT].push(
          document.getElementsByClassName('pnd-map-toolbar')[0]
        );
      }
    });

    this.unassignedPickupsService.unmappedPickups$.pipe(takeUntil(this.unsubscriber.done)).subscribe(
      (unmappedPickups) => {
        this.unmappedPickupsCountSubject.next(_size(unmappedPickups));
      },
      () => {
        this.unmappedPickupsCountSubject.next(0);
      }
    );

    this.unassignedDeliveriesService.unmappedDeliveries$.pipe(takeUntil(this.unsubscriber.done)).subscribe(
      (unmappedDeliveries) => {
        this.unmappedDeliveriesCountSubject.next(_size(unmappedDeliveries));
      },
      () => {
        this.unmappedDeliveriesCountSubject.next(0);
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();

    if (this.googleMap) {
      this.googleMap.controls[google.maps.ControlPosition.TOP_LEFT].clear();
    }
  }

  onResize(ev: ResizeObserverEntry) {
    if (ev.contentRect.width < 970) {
      this.displayLayersOutsideSelector = false;
      this.filterSelectorOptions.forEach((item) => {
        if (
          item.id === MapToolbarWidgetItems.UnassignedPickups ||
          item.id === MapToolbarWidgetItems.UnassignedDeliveries
        ) {
          item.permission = true;
          const index = this.selectedFilters.indexOf(item.id);
          if (item.active) {
            if (index === -1) {
              this.selectedFilters.push(item.id);
            }
          } else {
            if (index !== -1) {
              this.selectedFilters.splice(index, 1);
            }
          }
        }
      });
    } else {
      this.displayLayersOutsideSelector = true;
      this.filterSelectorOptions.forEach((item) => {
        if (
          item.id === MapToolbarWidgetItems.UnassignedPickups ||
          item.id === MapToolbarWidgetItems.UnassignedDeliveries
        ) {
          item.permission = false;
          const index = this.selectedFilters.indexOf(item.id);
          if (index !== -1) {
            this.selectedFilters.splice(index, 1);
          }
        }
      });
    }
  }

  handleSelectionChange(selection: MatSelectChange) {
    const selectedIds = selection.value;
    this.filterSelectorOptions.forEach((optionObj) => {
      const id = optionObj.id;
      optionObj.active = selectedIds.indexOf(id) === -1 ? false : true;
      switch (optionObj.id) {
        case MapToolbarWidgetItems.UnassignedDeliveries:
          if (!this.displayLayersOutsideSelector) {
            this.unassignedDeliveriesLayerChange.emit(optionObj.active);
            this.unassignedDeliveriesLayerChangeSubject.next(optionObj.active);
            this.isUnassignedDeliveriesChecked = optionObj.active;
          } else {
            optionObj.active = this.isUnassignedDeliveriesChecked;
          }
          break;
        case MapToolbarWidgetItems.UnassignedPickups:
          if (!this.displayLayersOutsideSelector) {
            this.unassignedPickupsLayerChange.emit(optionObj.active);
            this.isUnassignedPickupsChecked = optionObj.active;
          } else {
            optionObj.active = this.isUnassignedPickupsChecked;
          }
          break;
        case MapToolbarWidgetItems.DriversLocation:
          this.driverLocationLayerChange.emit(optionObj.active);
          break;
        case MapToolbarWidgetItems.Traffic:
          this.trafficLayerChange.emit(optionObj.active);
          break;
        case MapToolbarWidgetItems.GeoAreas:
          this.geoAreasLayerChange.emit(optionObj.active);
          break;
        case MapToolbarWidgetItems.CompletedStops:
          this.completedStopsLayerChange.emit(optionObj.active);
          break;
        case MapToolbarWidgetItems.Weather:
          this.isWeatherModeActive = optionObj.active;
          if (this.isWeatherModeActive) {
            this.weatherLayerChange.emit(this.selectedWeatherLayer);
          } else {
            this.weatherLayerChange.emit(null);
          }
      }
    });
  }

  onFitServiceCenterBounds(): void {
    this.fitServiceCenterBounds.emit();
  }

  onFitLastSelection(): void {
    this.fitLastSelection.emit();
  }

  onPolygonDrawToggle(inDrawMode: boolean): void {
    this.drawModeChange.emit(inDrawMode);
  }

  onUnassignedDeliveriesLayerChange(event: MatCheckboxChange): void {
    this.filterSelectorOptions.forEach((item) => {
      if (item.id === MapToolbarWidgetItems.UnassignedDeliveries) {
        item.active = event.checked;
      }
    });

    this.unassignedDeliveriesLayerChange.emit(event.checked);
    this.unassignedDeliveriesLayerChangeSubject.next(event.checked);
  }

  onUnassignedPickupsLayerChange(event: MatCheckboxChange): void {
    this.filterSelectorOptions.forEach((item) => {
      if (item.id === MapToolbarWidgetItems.UnassignedPickups) {
        item.active = event.checked;
      }
    });
    this.unassignedPickupsLayerChange.emit(event.checked);
  }

  onDriverLocationLayerChange(event: MatCheckboxChange): void {
    this.driverLocationLayerChange.emit(event.checked);
  }

  onTrafficLayerChange(event: MatCheckboxChange): void {
    this.trafficLayerChange.emit(event.checked);
  }

  onWeatherLayerChange(event: MatRadioChange): void {
    this.selectedWeatherLayer = event.value;
    this.weatherLayerChange.emit(event.value as WeatherLayer);
  }

  onGeoAreasLayerChange(event: MatCheckboxChange): void {
    this.geoAreasLayerChange.emit(event.checked);
  }

  onCompletedStopsLayerChange(event: MatCheckboxChange): void {
    this.completedStopsLayerChange.emit(event.checked);
  }

  onUnmappedPickupsClicked(): void {
    this.toggleUnmappedPickupsChange.emit();
  }

  onUnmappedDeliveriesClicked(): void {
    this.toggleUnmappedDeliveriesChange.emit();
  }

  onCompassClicked(): void {
    this.compassClicked.emit();
  }
}
