import { Injectable } from '@angular/core';
import {
  ListLocationsResp,
  LocationApiService,
  ListLocationsRqst,
  ListLocationsQuery,
} from '@xpo-ltl-2.0/sdk-location';
import { XpoLtlBaseCacheService } from '@xpo/ngx-ltl';
import { toString as _toString } from 'lodash';
import { Observable } from 'rxjs';

export interface ListLocationsCacheParams {
  meetAndTurnInd: boolean;
}

@Injectable({ providedIn: 'root' })
export class ListLocationsCacheService extends XpoLtlBaseCacheService<ListLocationsCacheParams, ListLocationsResp> {
  constructor(private locationService: LocationApiService) {
    super();
  }

  protected getKeyFromParams(params: ListLocationsCacheParams): string {
    return _toString(params.meetAndTurnInd);
  }

  protected requestData(params: ListLocationsCacheParams): Observable<ListLocationsResp> {
    const request: ListLocationsRqst = { ...new ListLocationsRqst() };
    const query: ListLocationsQuery = {
      ...new ListLocationsQuery(),
      activeInd: true,
      meetAndTurnDesiredInd: params.meetAndTurnInd,
    };
    return this.locationService.listLocations(request, query);
  }
}
