export enum UnassignedPickupsGridFields {
  ROW_SELECTED = 'rowSelected',
  SOURCE = 'sourceCd',
  TYPE = 'typeCd',
  SHIPPER = 'shipper.name1',
  ADDRESS = 'shipper.addressLine1',
  CITY = 'shipper.cityName',
  SPECIAL_SERVICES = 'specialServices',
  ZIP_CODE = 'shipper.postalCd',
  PICKUP_DATE = 'pickupDate',
  READY_TIME = 'readyTime',
  CLOSE_TIME = 'closeTime',
  LP = 'loosePcsCnt',
  MM = 'totalMotorizedPiecesCount',
  WEIGHT = 'totalWeightLbs',
  CUBE = 'totalCubePercentage',
  ORIGIN_SIC = 'originalSicCd',
  CALL_NBR = 'callNbr',
  STATUS = 'statusCd',
  REMARKS = 'remarks',
}
