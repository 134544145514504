import { AgmCoreModule, LAZY_MAPS_API_CONFIG } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { LocationApiService, LocationApiService as LocationService } from '@xpo-ltl-2.0/sdk-location';
import { ConfigManagerModule, CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import { AccountsReceivableApiService } from '@xpo-ltl/sdk-accountsreceivable';
import { CityOperationsApiService } from '@xpo-ltl/sdk-cityoperations';
import { CustomerApiService } from '@xpo-ltl/sdk-customer';
import { ExceptionManagementApiService } from '@xpo-ltl/sdk-exceptionmanagement';
import { XpoAuthConfig, XpoAuthEventHandler, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { PndStoreModule } from '../app/store';
import { AppAuthEventHandler } from './app-auth-event-handler.class';
import { GoogleMapsConfig } from './services';
import { FeaturesService } from './services/features/features.service';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.getSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  imports: [
    ConfigManagerModule,
    AgmCoreModule.forRoot(),
    AgmSnazzyInfoWindowModule,
    PndStoreModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
  ],
  providers: [
    AccountsReceivableApiService,
    CityOperationsApiService,
    LocationApiService,
    LocationService,
    ExceptionManagementApiService,
    CustomerApiService,
    FeaturesService,
    { provide: CONFIG_MANAGER_SERVICE_CONFIGURATION, useValue: { configFileUrl: './assets/config.json' } },
    { provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapsConfig },
    {
      provide: XpoAuthEventHandler,
      useClass: AppAuthEventHandler,
    },
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string): void {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
}
