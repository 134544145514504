import { DriverLocation } from '@xpo-ltl/sdk-cityoperations';
import { concat as _concat, defaultTo as _defaultTo, head as _head, map as _map, remove as _remove } from 'lodash';
import { tassign } from 'tassign';
import { Actions, ActionTypes } from './driver-store.actions';
import { initialState, State } from './driver-store.state';

export function driversReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setDriversCurrentLocation: {
      return tassign(state, {
        driversCurrentLocation: action.payload.driversCurrentLocation,
      });
    }

    case ActionTypes.updateDriversCurrentLocation: {
      const updatedDriverLocations = action.payload.updatedDriverLocations;

      const driverLocations = _map(state.driversCurrentLocation, (existingLocation) => {
        const updatedLocation = _head(
          _remove(
            updatedDriverLocations,
            (dl: DriverLocation) => dl.currentLocation.employeeId === existingLocation.currentLocation.employeeId
          )
        );
        return _defaultTo(updatedLocation, existingLocation);
      });

      return tassign(state, {
        driversCurrentLocation: _concat(driverLocations, updatedDriverLocations),
      });
    }

    default: {
      return state;
    }
  }
}
