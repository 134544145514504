import { XpoBoardView, XpoCheckboxFilter } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardView, XpoAgGridBoardViewConfig, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoAgGridFormatters } from '@xpo-ltl/ngx-ltl-grid';
import { get as _get } from 'lodash';
import { RouteStopsGridItem } from '.';
import { ActivityCdPipe, StopWindowService } from '../../shared';
import { RouteColorService } from '../../shared/services/route-color.service';
import { RouteStopsGridFields } from './enums/route-stops-grid-fields.enum';
import { RouteStopsGridHeaders } from './enums/route-stops-grid-headers.enum';

export class RouteStopsBoardTemplate extends XpoAgGridBoardViewTemplate {
  static templateId = 'RouteStopsBoardTemplate';

  constructor(
    addressClickCallback: (stop: RouteStopsGridItem) => void,
    private stopWindowService: StopWindowService,
    private activityCd: ActivityCdPipe,
    private routeColorService: RouteColorService
  ) {
    super({
      id: RouteStopsBoardTemplate.templateId,
      name: 'Route Stops',
      keyField: 'uniqId',
      availableColumns: [
        {
          headerName: RouteStopsGridHeaders.ROUTE_ID,
          field: RouteStopsGridFields.ROUTE_ID,
          width: 120,
          rowGroup: true,
          hide: true,
        },
        {
          headerName: RouteStopsGridHeaders.ROW_SELECTED,
          field: RouteStopsGridFields.ROW_SELECTED,
          headerValueGetter: () => '',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          width: 50,
          lockPosition: true,
          pinnedRowCellRenderer: 'totalTextCellRenderer',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel = nodeA.isSelected() ? 1 : 0;
            const nodeBSel = nodeB.isSelected() ? 1 : 0;
            return nodeASel - nodeBSel;
          },
          cellStyle: (params) => {
            const routeInstId = _get(params, 'node.data.routeInstId');
            const isSelected = params.node.isSelected();
            const color =
              isSelected && routeInstId ? this.routeColorService.getColorForRoute(routeInstId) : 'transparent';
            const width = '4px';

            return {
              'border-left': isSelected ? `${width} solid ${color}` : `${width} solid transparent`,
            };
          },
        },
        { headerName: RouteStopsGridHeaders.SEQ, field: RouteStopsGridFields.SEQ, width: 60 },
        {
          headerName: RouteStopsGridHeaders.STOP_TYPE,
          field: RouteStopsGridFields.STOP_TYPE,
          width: 70,
          valueGetter: (params) => {
            return this.activityCd.transform(params.data.stopType);
          },
        },
        { headerName: RouteStopsGridHeaders.CONSIGNEE, field: RouteStopsGridFields.CONSIGNEE, width: 275 },
        {
          headerName: RouteStopsGridHeaders.CONSIGNEE_ADDRESS,
          field: RouteStopsGridFields.CONSIGNEE_ADDRESS,
          width: 250,
          cellRenderer: 'actionLinkCellRenderer',
          cellRendererParams: { onClick: addressClickCallback },
        },
        { headerName: RouteStopsGridHeaders.CONSIGNEE_CITY, field: RouteStopsGridFields.CONSIGNEE_CITY, width: 200 },
        {
          headerName: RouteStopsGridHeaders.ZIP_CODE,
          field: RouteStopsGridFields.CONSIGNEE_ZIP,
          width: 90,
        },
        {
          headerName: RouteStopsGridHeaders.SPECIAL_SERVICES,
          field: RouteStopsGridFields.SPECIAL_SERVICES,
          width: 170,
          cellRenderer: 'specialServicesCellRenderer',
        },

        { headerName: RouteStopsGridHeaders.LTR, field: RouteStopsGridFields.LTR, width: 65 },
        {
          headerName: RouteStopsGridHeaders.BILLS,
          field: RouteStopsGridFields.BILLS,
          width: 55,
          type: 'numericColumn',
          valueFormatter: XpoAgGridFormatters.formatNumber,
        },
        {
          headerName: RouteStopsGridHeaders.MM,
          field: RouteStopsGridFields.MM,
          width: 50,
          type: 'numericColumn',
          valueFormatter: XpoAgGridFormatters.formatNumber,
        },
        {
          headerName: RouteStopsGridHeaders.WEIGHT,
          field: RouteStopsGridFields.WEIGHT,
          width: 110,
          type: 'numericColumn',
          valueFormatter: XpoAgGridFormatters.formatNumber,
        },
        {
          headerName: RouteStopsGridHeaders.CUBE,
          field: RouteStopsGridFields.CUBE,
          width: 60,
          type: 'numericColumn',
          valueFormatter: XpoAgGridFormatters.formatNumber,
        },
        {
          headerName: RouteStopsGridHeaders.DELIVERY_WINDOW_TYPE,
          field: RouteStopsGridFields.DELIVERY_WINDOW_TYPE,
          width: 160,
          valueGetter: (params) => {
            const stopWindow = _get(params.data, 'stopWindowSummary');
            return this.stopWindowService.getStopWindowType(stopWindow);
          },
        },
        {
          headerName: RouteStopsGridHeaders.DELIVERY_WINDOW_TIME,
          field: RouteStopsGridFields.DELIVERY_WINDOW_TIME,
          width: 160,
          valueGetter: (params) => {
            const stopWindow = _get(params.data, 'stopWindowSummary');
            return this.stopWindowService.getStopWindowTime(stopWindow, false);
          },
        },
        {
          headerName: RouteStopsGridHeaders.DELIVERY_WINDOW,
          field: RouteStopsGridFields.DELIVERY_WINDOW,
          width: 160,
          valueGetter: (params) => {
            const stopWindow = _get(params.data, 'stopWindowSummary');
            return this.stopWindowService.getStopWindowDate(stopWindow);
          },
        },
        {
          headerName: RouteStopsGridHeaders.ETA,
          field: RouteStopsGridFields.ETA,
          width: 65,
        },
        {
          headerName: RouteStopsGridHeaders.ETD,
          field: RouteStopsGridFields.ETD,
          width: 65,
        },
        // { headerName: 'Stop Notes', field: 'stopNotes', width: 100 },  // TODO: at a later point we will figure out how to display notes at the stop level!
        // Status is commented out as per PCT-3619.  This will be re-enabled when we play PCT-3586.
        // { headerName: 'Status', field: 'status', width: 100, cellRenderer: 'statusChipCellRenderer' },
      ],
      allowAdditional: true,
    });
  }

  createView(config: XpoAgGridBoardViewConfig): XpoAgGridBoardView {
    // This is called everytime the view is changed.
    this.assignUniqueId(config);
    return new XpoAgGridBoardView(this, {
      ...config,
      templateId: this.id,
    });
  }

  createStopsView(): XpoBoardView {
    return this.createView({
      closeable: false,
      criteria: {},
      id: 'route-stops-default',
      name: '',
    });
  }
}
