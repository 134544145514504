import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material';
import { Store } from '@ngrx/store';
import {
  CityOperationsApiService,
  ListPnDSuggestedRouteNamesPath,
  ListPnDSuggestedRouteNamesQuery,
  SuggestedRouteName,
  ValidatePnDRouteRqst,
  ValidatePnDRoutePath,
} from '@xpo-ltl/sdk-cityoperations';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { get as _get, isEmpty as _isEmpty, orderBy as _orderBy, toUpper as _toUpper } from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, timer } from 'rxjs';
import { takeUntil, take, startWith, map, finalize } from 'rxjs/operators';
import { NotificationMessageService, NotificationMessageStatus } from '../../../../../../../core';
import { dynamicValidator } from '../../../../../../../core/validators/dynamic-validator';
import { PndStoreState, GlobalFilterStoreSelectors } from '../../../../../../store';
import { FormUtils } from '../../../../../shared/classes/form-utils.class';

export enum NewRouteLaneFields {
  PREFIX = 'prefix',
  SUFFIX = 'suffix',
}

@Component({
  selector: 'app-new-route-lane',
  templateUrl: './new-route-lane.component.html',
  styleUrls: ['./new-route-lane.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewRouteLaneComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('currentCardContent', { static: true }) currentCardContent;
  @ViewChild('prefixInput', { static: true }) prefixInput: ElementRef;

  formGroup: FormGroup;

  @Input() height: number = 0;
  @Input() loadedRoutesNames: {
    prefix: string;
    suffix: string;
    satelliteSic: string;
  }[] = [];

  @Output() apply = new EventEmitter<{
    prefix: string;
    suffix: string;
    satelliteSic: string;
  }>(undefined);
  @Output() cancel = new EventEmitter<void>();

  private readonly unsubscriber = new Unsubscriber();

  private prefixesFilteredSubject = new BehaviorSubject<string[]>([]);
  prefixesFiltered$ = this.prefixesFilteredSubject.asObservable();
  private existingGeoAreas: SuggestedRouteName[] = [];

  readonly characterNumberPattern = '^[0-9a-zA-Z]$';
  readonly characterPattern = '^[a-zA-Z]$';
  readonly NewRouteLaneFields = NewRouteLaneFields;

  private sic: string;
  private planDate: Date;

  constructor(
    private pndStore$: Store<PndStoreState.State>,
    private formBuilder: FormBuilder,
    private notificationMessageService: NotificationMessageService,
    private cityOperationsService: CityOperationsApiService
  ) {}

  ngOnInit() {
    this.pndStore$
      .select(GlobalFilterStoreSelectors.selectGlobalFilterState)
      .pipe(take(1))
      .subscribe((globalFilters) => {
        this.sic = globalFilters.sic;
        this.planDate = globalFilters.planDate;
      });

    this.formGroup = this.formBuilder.group({
      [NewRouteLaneFields.PREFIX]: [
        '',
        [
          dynamicValidator(() => this.hasValue(NewRouteLaneFields.PREFIX), this.validatePrefix.bind(this)),
          Validators.required,
        ],
      ],
      [NewRouteLaneFields.SUFFIX]: ['', Validators.required],
    });

    this.fetchExistingAreas();
  }

  ngAfterViewChecked(): void {
    const correspondingRouteCard: HTMLElement = _get(
      this.currentCardContent,
      'nativeElement.parentElement.parentElement.firstChild'
    );
    if (correspondingRouteCard) {
      correspondingRouteCard['style'].backgroundColor = 'transparent';
      correspondingRouteCard['style'].borderTopLeftRadius = '2px';
      correspondingRouteCard['style'].borderTopRightRadius = '2px';
      correspondingRouteCard['style'].height = '6px';
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  onCancel(): void {
    this.cancel.emit();
  }

  hasErrors(formField: NewRouteLaneFields): boolean {
    if (!this.formGroup) {
      return false;
    }
    return !_isEmpty(this.formGroup.get(formField).errors);
  }

  hasError(formField: NewRouteLaneFields, errorKey: string): boolean {
    return FormUtils.hasError(this.formGroup, formField, errorKey);
  }

  hasValue(formField: NewRouteLaneFields): boolean {
    if (!this.formGroup) {
      return false;
    }
    const control = this.formGroup.get(formField);
    return control && !_isEmpty(control.value);
  }

  onApply(): void {
    const enteredPrefix: string = _toUpper(this.formGroup.get(NewRouteLaneFields.PREFIX).value);
    const satelliteSic = enteredPrefix.indexOf('/') >= 0 ? enteredPrefix.substr(enteredPrefix.indexOf('/') + 1) : '';
    const prefix =
      enteredPrefix.indexOf('/') >= 0 ? enteredPrefix.substr(0, enteredPrefix.indexOf('/')) : enteredPrefix;
    const suffix = _toUpper(this.formGroup.get(NewRouteLaneFields.SUFFIX).value);

    const route = {
      prefix: prefix,
      suffix: suffix,
      satelliteSic: satelliteSic,
    };

    this.pndStore$
      .select(GlobalFilterStoreSelectors.selectGlobalFilterState)
      .pipe(take(1))
      .subscribe((globalFilters) => {
        const request: ValidatePnDRouteRqst = {
          ...new ValidatePnDRouteRqst(),
          planDate: globalFilters.planDate.toISOString().substring(0, 10),
          routePrefix: prefix,
          routeSuffix: suffix,
        };

        const params = { ...new ValidatePnDRoutePath(), sicCd: globalFilters.sic };

        this.cityOperationsService
          .validatePnDRoute(request, params)
          .pipe(take(1))
          .subscribe(
            (response) => {
              if (_get(response, 'validRouteInd')) {
                if (
                  this.loadedRoutesNames.find(
                    (r) =>
                      r.prefix === route.prefix && r.suffix === route.suffix && r.satelliteSic === route.satelliteSic
                  )
                ) {
                  this.notificationMessageService
                    .openNotificationMessage(
                      NotificationMessageStatus.Error,
                      `Route ${prefix}-${suffix} already loaded.`
                    )
                    .subscribe(() => {});
                } else {
                  this.apply.emit(route);
                }
              } else {
                this.notificationMessageService
                  .openNotificationMessage(NotificationMessageStatus.Error, `Route ${prefix}-${suffix} already exists.`)
                  .subscribe(() => {});
              }
            },
            (error) => {
              this.notificationMessageService
                .openNotificationMessage(NotificationMessageStatus.Error, error)
                .subscribe(() => {});
            }
          );
      });
  }

  selectionChange(item: MatOptionSelectionChange): void {
    this.populateSuggestedPrefix(item.source.value);
  }

  routePrefixBlur(): void {
    this.populateSuggestedPrefix(_toUpper(this.formGroup.get(NewRouteLaneFields.PREFIX).value));
  }

  private populateSuggestedPrefix(newRoutePrefix: string): void {
    for (const geoArea of this.existingGeoAreas) {
      let satelliteSic = '';

      if (_get(geoArea, 'geoArea.sicCd') !== this.sic) {
        satelliteSic = `/${geoArea.geoArea.sicCd}`;
      }

      if (`${_get(geoArea, 'geoArea.geoAreaName', '')}${satelliteSic}` === newRoutePrefix) {
        this.formGroup
          .get(NewRouteLaneFields.SUFFIX)
          .setValue(this.getNextSuggestedSuffix(newRoutePrefix, _get(geoArea, 'suggestedSuffix')));

        break;
      }
    }
  }

  /**
   * Returns the next suggested route suffix
   * @param prefix
   * @param actualSuggestion
   */
  private getNextSuggestedSuffix(prefix: string, actualSuggestion: string): string {
    if (this.loadedRoutesNames.length > 0) {
      const loadedNamesOrderedAndFiltered = _orderBy(
        this.loadedRoutesNames.filter((name) => !isNaN(Number(name.suffix))),
        ['prefix', 'suffix'],
        ['asc', 'desc']
      );

      for (const routeName of loadedNamesOrderedAndFiltered) {
        const satelliteSic: string = routeName.satelliteSic ? `/${routeName.satelliteSic}` : '';

        if (prefix === `${routeName.prefix}${satelliteSic}`) {
          return (Number(routeName.suffix) + 1).toString();
        }
      }
    }

    return actualSuggestion;
  }

  private validatePrefix(): ValidationErrors | null {
    const control = this.formGroup ? this.formGroup.get(NewRouteLaneFields.PREFIX) : null;

    if (!control) {
      return null;
    }

    const newRoutePrefix = _toUpper(control.value);
    let isValid = false;

    this.existingGeoAreas.forEach((geoArea) => {
      let satelliteSic = '';
      if (_get(geoArea, 'geoArea.sicCd') !== this.sic) {
        satelliteSic = `/${geoArea.geoArea.sicCd}`;
      }
      if (`${_get(geoArea, 'geoArea.geoAreaName', '')}${satelliteSic}` === newRoutePrefix) {
        isValid = true;
      }
    });

    return !isValid ? { invalid: true } : _isEmpty(control.value) ? { required: true } : null;
  }

  private fetchExistingAreas(): void {
    const path: ListPnDSuggestedRouteNamesPath = {
      sicCd: this.sic,
    };

    const query: ListPnDSuggestedRouteNamesQuery = {
      planDate: moment(this.planDate).format('YYYY-MM-DD'),
      satelliteInd: true,
    };

    this.cityOperationsService
      .listPnDSuggestedRouteNames(path, query)
      .pipe(
        take(1),
        finalize(() => this.prefixInput.nativeElement.focus())
      )
      .subscribe((response) => {
        if (response && response.suggestedRouteNames) {
          this.existingGeoAreas = _orderBy(response.suggestedRouteNames || [], (area) => area.geoArea.geoAreaName);

          this.prefixesFilteredSubject.next(
            this.existingGeoAreas.map((geoArea) => {
              let satelliteSic = '';

              if (_get(geoArea, 'geoArea.sicCd') !== this.sic) {
                satelliteSic = `/${geoArea.geoArea.sicCd}`;
              }

              return `${_get(geoArea, 'geoArea.geoAreaName', '')}${satelliteSic}`;
            })
          );

          this.prefixesFiltered$ = this.formGroup.get(NewRouteLaneFields.PREFIX).valueChanges.pipe(
            takeUntil(this.unsubscriber.done),
            startWith(''),
            map((name: string) => (name ? this.filterPrefixes(name) : this.prefixesFilteredSubject.value.slice()))
          );
        }
      });
  }

  private filterPrefixes(name: string): string[] {
    return this.prefixesFilteredSubject.value.filter((value) => _toUpper(value).indexOf(_toUpper(name)) === 0);
  }
}
