import { Injectable } from '@angular/core';
import {
  CityOperationsApiService,
  ListPnDUnassignedPickupsResp,
  ListPnDUnassignedPickupsRqst,
  UnassignedPickup,
} from '@xpo-ltl/sdk-cityoperations';
import { PickupLineItemSearchFilter, XrtSearchQueryHeader } from '@xpo-ltl/sdk-common';
import { get as _get, uniqBy as _uniqBy } from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { PndXrtService } from '../../../../core/services/pnd-xrt.service';
import { UnassignedPickupsSearchCriteria } from '../../../store/unassigned-pickups-store/unassigned-pickups-search-criteria.interface';

@Injectable({
  providedIn: 'root',
})
export class UnassignedPickupsService {
  constructor(private pndXrtService: PndXrtService, private cityOperationsService: CityOperationsApiService) {}

  private unassignedPickupsSubject = new BehaviorSubject<UnassignedPickup[]>([]);
  readonly unassignedPickups$ = this.unassignedPickupsSubject.asObservable();

  private unmappedPickupsSubject = new BehaviorSubject<UnassignedPickup[]>([]);
  readonly unmappedPickups$ = this.unmappedPickupsSubject.asObservable();
  get unmappedPickups(): UnassignedPickup[] {
    return this.unmappedPickupsSubject.value;
  }

  private fetchUnassignedPickups(
    criteria: UnassignedPickupsSearchCriteria,
    unmapped: boolean
  ): Observable<ListPnDUnassignedPickupsResp> {
    const header: XrtSearchQueryHeader = {
      pageNumber: 1,
      pageSize: 10000,
      sortExpressions: [],
    };

    const listPnDUnassignedPickupsRqst: ListPnDUnassignedPickupsRqst = {
      header: header,
      filter: {
        ...new PickupLineItemSearchFilter(),
        q: _get(criteria, `Q`),
        header_pickupTerminalSicCd: this.pndXrtService.toXrtFilterEquals(criteria.pickupTerminalSicCd),
        header_pickupDate: this.pndXrtService.toXrtFilterEqualsDateRange(
          _get(criteria, 'pickupDate.min'),
          _get(criteria, 'pickupDate.max')
        ),
        header_pickupTypeCd: this.pndXrtService.toXrtFilterValues(criteria.pickupTypeCd),
        header_readyTime: this.pndXrtService.toXrtFilterEqualsDate(criteria.readyTime),
        header_closeTime: this.pndXrtService.toXrtFilterEqualsDate(criteria.closeTime),
        header_cadCallNbr: this.pndXrtService.toXrtFilterValues(criteria.cadCallNbr),
        guaranteedInd: this.pndXrtService.toXrtFilterValues(criteria.guaranteedInd),
        hazardousMtInd: this.pndXrtService.toXrtFilterValues(criteria.hazardousMtInd),
        freezableInd: this.pndXrtService.toXrtFilterValues(criteria.freezableInd),
        proNbr: this.pndXrtService.toXrtFilterValues(criteria.proNbr),
        shipper_geoCoordinates: !unmapped
          ? this.pndXrtService.toXrtFilterPoints(criteria.shipperGeoCoordinates)
          : undefined,
      },
      unmappedInd: unmapped,
    };

    return this.cityOperationsService.listPnDUnassignedPickups(listPnDUnassignedPickupsRqst).pipe(
      tap((response) => {
        this.unassignedPickupsSubject.next(_get(response, 'unassignedPickups', []));
      }),
      catchError((error) => {
        this.unassignedPickupsSubject.next([]);
        // TODO: snackbar ???
        return of(undefined);
      }),
      take(1)
    );
  }

  searchUnassignedPickups(criteria: UnassignedPickupsSearchCriteria): Observable<ListPnDUnassignedPickupsResp> {
    return forkJoin([this.fetchUnassignedPickups(criteria, false), this.searchUnmappedPickups(criteria)]).pipe(
      // we only want to return the mapped pickups here
      map((results) => results[0])
    );
  }

  searchUnmappedPickups(criteria: UnassignedPickupsSearchCriteria): Observable<UnassignedPickup[]> {
    return this.fetchUnassignedPickups(criteria, true).pipe(
      map((response) => {
        // store all unmapped pickups
        const allUnmappedPickups: UnassignedPickup[] = _get(response, 'unassignedPickups', []);
        this.unmappedPickupsSubject.next(_uniqBy(allUnmappedPickups, (pickup) => _get(pickup, 'shipper.acctInstId')));
        return this.unmappedPickupsSubject.value;
      }),
      catchError(() => {
        this.unmappedPickupsSubject.next([]);
        return of([]);
      })
    );
  }

  updateUnassignedPickups(unassignedPickups: UnassignedPickup[]) {
    this.unmappedPickupsSubject.next(unassignedPickups);
  }
}
