import { LatLong } from '@xpo-ltl/sdk-common';
import { FeatureCollection, GeoJSON } from 'geojson';
import { get as _get } from 'lodash';
import { GeoJsonGeometryType } from '../enums/geo-json-geometry-type.enum';
import { GeoJsonType } from '../enums/geo-json-type.enum';

export class MapUtils {
  static convertToGeoPoint(json: GeoJSON): LatLong {
    if (json && json.type === GeoJsonType.FeatureCollection) {
      const fc = json as FeatureCollection;

      if (fc.features && fc.features[0] && fc.features[0].geometry.type === GeoJsonGeometryType.Point) {
        return { latitude: fc.features[0].geometry.coordinates[1], longitude: fc.features[0].geometry.coordinates[0] };
      }
    }

    return undefined;
  }

  static isValidGeoCoordinates(geoCoordinates: LatLong): boolean {
    return geoCoordinates && geoCoordinates.latitude !== 0 && geoCoordinates.longitude !== 0;
  }
}
