import { Component, ViewEncapsulation } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { get as _get } from 'lodash';
import { StopWindowService } from '../../services';

@Component({
  selector: 'delivery-window-time-renderer',
  templateUrl: './delivery-window-time-renderer.component.html',
  styleUrls: ['./delivery-window-time-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeliveryWindowTimeRendererComponent implements IHeaderAngularComp {
  constructor(private stopWindowService: StopWindowService) {}

  set params(value) {
    this.htmlSnippet = this.stopWindowService.getStopWindowTime(_get(value.data, 'stopWindow'));
  }

  htmlSnippet: any = '';

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
