import { Component, Input, ViewEncapsulation } from '@angular/core';
import { InterfaceAcct, UnassignedPickup } from '@xpo-ltl/sdk-cityoperations';
import { defaultTo as _defaultTo, get as _get, truncate as _truncate } from 'lodash';
import { MapMarkerInfo } from '../../../../../shared/models/map-marker-info.model';

@Component({
  selector: 'app-unassigned-pickup-marker-info',
  templateUrl: './unassigned-pickup-marker-info.component.html',
  styleUrls: ['../marker-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-UnassignedPickupInfoWindow' },
})
export class UnassignedPickupMarkerInfoComponent {
  @Input()
  markerInfo: MapMarkerInfo;

  @Input()
  pickup: UnassignedPickup;

  get shipper(): InterfaceAcct {
    return _get(this.pickup, 'shipper');
  }

  get readyCloseTimes(): string {
    if (!_get(this.pickup, 'readyTime') && !_get(this.pickup, 'closeTime')) {
      return 'N/A';
    } else {
      const readyTime = _truncate(_defaultTo(this.pickup.readyTime, 'N/A'), { length: 5, omission: '' });
      const closeTime = _truncate(_defaultTo(this.pickup.closeTime, 'N/A'), { length: 5, omission: '' });
      return `${readyTime} - ${closeTime}`;
    }
  }

  constructor() {}
}
