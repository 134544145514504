import { ChangeDetectionStrategy, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { filter as _filter } from 'lodash';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { RouteColorService } from '../../../../shared/services/route-color.service';

interface ContextMenuItemBase {
  label: string;
  shouldHide?: (userData: any) => boolean;
}

export interface ContextMenuItem<TYPE> extends ContextMenuItemBase {
  id: TYPE;
  nested?: boolean;
  colorId?: string;
}

export interface ContextMenuPosition {
  x: number;
  y: number;
}

export interface ContextMenuNestedColors extends ContextMenuItemBase {
  colorId: string;
}

/**
 * Implement a context menu that can be opened at a specific
 * screen position
 */
@Component({
  selector: 'pnd-marker-context-menu',
  templateUrl: './marker-context-menu.component.html',
  styleUrls: ['./marker-context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkerContextMenuComponent {
  @ViewChild(MatMenuTrigger, { static: false }) menuTrigger: MatMenuTrigger;

  @Input() menuItems: ContextMenuItemBase[] = [];

  private menuItemsSubject = new BehaviorSubject<ContextMenuItemBase[]>([]);
  menuItems$ = this.menuItemsSubject.asObservable();

  private menuObserver: any;

  private menuPositionSubject = new BehaviorSubject<ContextMenuPosition>({ x: 0, y: 0 });
  menuPosition$ = this.menuPositionSubject.asObservable();
  unassignedColors: { colorId: string }[];

  constructor(private routeColorService: RouteColorService) {}

  /**
   * Open the context menu at the specified absolute screen position
   */
  openMenu(x: number, y: number, userData?: any): Observable<ContextMenuItemBase> {
    this.setUnassignedColors();
    // filter out any menu options that should not be shown
    const availableItems = this.getAvailableItems(userData);
    this.menuItemsSubject.next(availableItems);

    // set the menu position.
    this.menuPositionSubject.next({ x, y });

    this.menuTrigger.openMenu();

    // TODO - HACK to set the position of the menu.  Should be able to do this
    // by setting the style on the element in HTML, but isn't working.
    const elem = document.querySelector('.pndContextMenu__panel') as HTMLElement;
    elem.style.left = `${x}px`;
    elem.style.top = `${y}px`;

    // return Observable that resolves with the selected menu item, or undefined
    // if menu closed with no item selected
    return new Observable<ContextMenuItemBase>((observer: Subscriber<ContextMenuItemBase>) => {
      this.menuObserver = observer;
    });
  }

  getAvailableItems(userData?: any) {
    return _filter(this.menuItems, (item) => {
      return !item.shouldHide || !item.shouldHide(userData);
    });
  }

  itemSelected(item: ContextMenuItemBase) {
    this.dispatchSelection(item);
  }

  setUnassignedColors() {
    this.unassignedColors = this.routeColorService.getUnassignedColors().map((color: string) => {
      return { colorId: color };
    });
  }

  colorSelected(item: ContextMenuItemBase, color: string) {
    const contextColor: ContextMenuNestedColors = {
      ...item,
      colorId: color,
    };
    this.dispatchSelection(contextColor);
  }

  menuClosed() {
    this.dispatchSelection(undefined);
  }

  dispatchSelection(selection: ContextMenuItemBase) {
    if (this.menuObserver) {
      this.menuObserver.next(selection);
      this.menuObserver.complete();
      this.menuObserver = undefined;
    }
  }
}
