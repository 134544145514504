import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[route-balancing-container]',
})
export class RoutebalancingContainerDirective {
  constructor(private elRef: ElementRef) {}

  @Input('route-balancing-container')
  instance: number;
  get instanceNumber(): number {
    return this.instance;
  }
  get element(): ElementRef {
    return this.elRef;
  }
}
