import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { some as _some } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PndStoreState, UnassignedDeliveriesStoreSelectors } from '../../../store';
import { StoreSourcesEnum } from '../../shared/enums/store-sources.enum';
import { UnassignedDeliveriesBoard } from './unassigned-deliveries-header/unassigned-deliveries-header.component';

@Component({
  selector: 'pnd-unassigned',
  templateUrl: './unassigned-deliveries.component.html',
  styleUrls: ['./unassigned-deliveries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UnassignedDeliveriesComponent implements OnInit, OnDestroy {
  protected unsubscriber = new Unsubscriber();

  // board currently being shown
  private selectedBoardSubject = new BehaviorSubject<UnassignedDeliveriesBoard>(UnassignedDeliveriesBoard.Stops);
  readonly selectedBoard$ = this.selectedBoardSubject.asObservable();

  private groupSelectedSubject = new BehaviorSubject<boolean>(false);
  readonly groupSelected$ = this.groupSelectedSubject.asObservable();

  readonly UnassignedDeliveriesBoard = UnassignedDeliveriesBoard;

  constructor(private pndStore$: Store<PndStoreState.State>) {}

  ngOnInit() {
    this.pndStore$
      .select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((selectedItems) => {
        if (_some(selectedItems, (item) => item.source === StoreSourcesEnum.POLYGON_SELECTION)) {
          // auto-group selected when selecting from lasso
          this.setGroupSelected(true);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  showBoard(boardToShow: UnassignedDeliveriesBoard): void {
    this.selectedBoardSubject.next(boardToShow);
  }

  setGroupSelected(selected: boolean) {
    this.groupSelectedSubject.next(selected);
  }
}
