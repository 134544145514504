import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { InterfaceAcct } from '@xpo-ltl/sdk-cityoperations';
import { XpoLtlTimeService, XpoLtlShipmentDescriptor } from '@xpo/ngx-ltl';
import { first as _first, get as _get, has as _has, isEmpty as _isEmpty } from 'lodash';
import { maxBy as _maxBy } from 'lodash';
import { take } from 'rxjs/operators';
import { PndDialogService } from '../../../../../../core/dialogs/pnd-dialog.service';
import { PndStoreState, GlobalFilterStoreSelectors, GeoLocationStoreActions } from '../../../../../store';
import { RouteBalancingSelectors } from '../../../../../store/route-balancing-store';
import { RoutesStoreSelectors, RoutesStoreActions } from '../../../../../store/routes-store';
import { UnmappedStopDetail } from '../../../../shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stop-detail.model';
import { UnmappedStopsEditMode } from '../../../../shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stops-edit-mode.enum';
import { StoreSourcesEnum } from '../../../../shared/enums/store-sources.enum';
import { MapMarkersService } from '../../../../shared/services/map-markers.service';
import { StopCard } from '../../classes/stop-card.model';
import { ResequencingRouteData } from '../../route-balancing.component';

@Component({
  selector: 'pnd-stop-card',
  templateUrl: './stop-card.component.html',
  styleUrls: ['./stop-card.component.scss'],
  host: { class: 'pnd-StopCard' },
  encapsulation: ViewEncapsulation.None,
})
export class StopCardComponent implements OnInit {
  @Input()
  stop: StopCard;

  @Input()
  assigned: boolean;

  @Input()
  isStartTimeValid: boolean;

  @Input()
  pinnedFirst: boolean = false;

  @Input()
  pinnedLast: boolean = false;

  @Output()
  mouseHover = new EventEmitter<{ mouseOver: boolean; stop: StopCard }>();

  @Output()
  pinFirst = new EventEmitter<StopCard>();

  @Output()
  pinLast = new EventEmitter<StopCard>();

  @Output()
  unPinFirst = new EventEmitter<StopCard>();

  @Output()
  unPinLast = new EventEmitter<StopCard>();

  @Output()
  unassign = new EventEmitter<StopCard>();

  @Output()
  assign = new EventEmitter<StopCard>();

  @Output()
  toggleDLW = new EventEmitter<StopCard>();

  private sicCd;

  constructor(
    public timeService: XpoLtlTimeService,
    private pndDialogService: PndDialogService,
    private pndStore$: Store<PndStoreState.State>,
    private mapMarkerService: MapMarkersService
  ) {}

  ngOnInit() {
    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterSic)
      .pipe(take(1))
      .subscribe((sicCd) => {
        this.sicCd = sicCd;
      });
  }

  onHover(mouseOver: boolean) {
    this.mouseHover.emit({
      mouseOver: mouseOver,
      stop: this.stop,
    });
  }

  onSequenceCard() {
    this.pndStore$
      .select(RouteBalancingSelectors.manualSequencingRoutes)
      .pipe(take(1))
      .subscribe((routes: number[]) => {
        if (routes.includes(this.stop.routeInstId)) {
          this.pndStore$
            .select(RoutesStoreSelectors.resequencedRouteData)
            .pipe(take(1))
            .subscribe((resequencedRouteData: { [routeInstId: number]: ResequencingRouteData }) => {
              const routeData = resequencedRouteData[this.stop.routeInstId];
              if (routeData) {
                if (routeData.pinnedStops && routeData.pinnedStops.last) {
                  routeData.newResequencingStops = routeData.newResequencingStops.filter(
                    (stop) => stop.origSeqNo !== routeData.pinnedStops.last.origSeqNo
                  );
                }
                const maxStop = _maxBy(routeData.newResequencingStops, (stop) => stop.seqNo);
                const currentStop = routeData.newResequencingStops.find(
                  (stop) => stop.origSeqNo === this.stop.origSeqNo
                );
                if (currentStop && !currentStop.seqNo) {
                  routeData.source = StoreSourcesEnum.PLANNING_MAP;
                  if (maxStop) {
                    currentStop.seqNo = maxStop.seqNo + 1;
                  } else {
                    currentStop.seqNo = 1;
                  }

                  if (routeData.pinnedStops && routeData.pinnedStops.last) {
                    routeData.newResequencingStops.push(routeData.pinnedStops.last);
                  }
                  routeData.source = StoreSourcesEnum.ROUTE_BALANCING_BOARD;

                  this.pndStore$.dispatch(
                    new RoutesStoreActions.SetResequencedRouteData({ resequenceData: { ...resequencedRouteData } })
                  );
                }
              }
            });
        }
      });
  }

  onPinFirst() {
    this.pinFirst.emit(this.stop);
  }

  onPinLast() {
    this.pinLast.emit(this.stop);
  }

  onUnPinFirst() {
    this.unPinFirst.emit(this.stop);
  }

  onUnPinLast() {
    this.unPinLast.emit(this.stop);
  }

  onUnassign() {
    this.unassign.emit(this.stop);
  }

  onAssign() {
    this.assign.emit(this.stop);
  }

  onToggleDLW() {
    this.toggleDLW.emit(this.stop);
  }

  formatTime(theTime: Date): string {
    return this.timeService.formatDate(theTime, 'HH:mm', this.sicCd);
  }

  showDetails() {
    if (_has(this.stop, 'activities')) {
      const shipmentDescriptors: XpoLtlShipmentDescriptor[] = [];
      this.stop.activities.forEach((activity) => {
        shipmentDescriptors.push({
          proNbr: _get(activity, 'routeShipment.proNbr'),
          shipmentInstId: _get(activity, 'routeShipment.shipmentInstId'),
        });
      });

      this.pndDialogService.showShipmentDetailsDialog(shipmentDescriptors).subscribe();
    }
  }

  editGeoLocation() {
    const customer: InterfaceAcct = this.stop.customer || { ...new InterfaceAcct() };

    const activityCds = this.mapMarkerService.activityCdsForActivities(this.stop.activities);
    const stopTypeCd = _first(activityCds); // TODO - this doesn't support MX stops

    const stopDetail: UnmappedStopDetail = {
      acctInstId: customer.acctInstId,
      stopName: customer.name1,
      stopTypeCd,
      address: `${customer.addressLine1}, ${customer.cityName}, ${customer.stateCd} ${customer.postalCd}`,
      location: new google.maps.LatLng(customer.geoCoordinates.latitude, customer.geoCoordinates.longitude),
      isFutureCustomer: _isEmpty(customer.acctMadCd),
    };
    this.pndStore$.dispatch(new GeoLocationStoreActions.SetEditMode(UnmappedStopsEditMode.AssignedStop));
    this.pndStore$.dispatch(new GeoLocationStoreActions.SetStopToEdit(stopDetail));
  }
}
