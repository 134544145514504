export enum BoardStatesEnum {
  SORT_CHANGE = 'SORT-CHANGE',
  FILTER_CHANGE = 'FILTER-CHANGE',
  BOARD_DATA_FETCHER = 'BOARD DATA FETCHER',
  ACTIVE_VIEW_CHANGE = 'ACTIVE-VIEW-CHANGE',
  ADD_NEW_VIEW = 'ADD-NEW-VIEW',
  SELECTION_CHANGE = 'SELECTION-CHANGE',
  SAVE_VIEW = 'SAVE-VIEW',
  SAVE_VIEW_AS = 'SAVE-VIEW-AS',
}
