import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';

// track summary of shipments selected
export class SelectionSummaryData {
  stops = 0;
  shipments = 0;
  motorMoves = 0;
  weight = 0;
  specialServices: ShipmentSpecialServiceCd[] = [];
}
