import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { XpoBoardData, XpoBoardState, XpoBoardDataSource } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardState } from '@xpo-ltl/ngx-ltl-board-grid';
import { Activity, Route, Stop } from '@xpo-ltl/sdk-cityoperations';
import { TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import { XpoLtlTimeService, Unsubscriber } from '@xpo/ngx-ltl';
import {
  countBy as _countBy,
  flatten as _flatten,
  forOwn as _forOwn,
  get as _get,
  orderBy as _orderBy,
  find as _find,
  trim as _trim,
} from 'lodash';
import { AsyncSubject, BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take, takeUntil, filter } from 'rxjs/operators';
import { PndRouteUtils } from '../../../../shared/route-utils';
import { PndStoreState, RoutesStoreSelectors } from '../../../store';
import { SpecialServicesService, GrandTotalService } from '../../shared';
import { RouteGridItem } from '../route-planning/models';
import { RouteStopsGridItem } from './models';

@Injectable({ providedIn: 'root' })
export class RouteStopsDataSource extends XpoBoardDataSource implements OnDestroy {
  private unsubscriber: Unsubscriber = new Unsubscriber();
  private routeSource$ = new BehaviorSubject<RouteGridItem[]>([]);
  private loadingDataSubject = new AsyncSubject<RouteStopsGridItem[]>();
  boardDataChange$ = this.state$.pipe(filter((s) => s.changes.includes('data')));

  get currentState(): XpoAgGridBoardState {
    return this.stateCache;
  }

  constructor(
    private pndStore$: Store<PndStoreState.State>,
    private timeService: XpoLtlTimeService,
    private specialServicesService: SpecialServicesService,
    private grandTotalService: GrandTotalService
  ) {
    super();
    // make the page-size really large because, there should never be that many routes in a SIC
    // and we want to use the client-side functionality of the grid
    this.pageSize = 10000;

    this.subscribeToStoreChanges();
  }

  private subscribeToStoreChanges() {
    const resequenced$ = this.pndStore$
      .select(RoutesStoreSelectors.resequencedRouteData)
      .pipe(takeUntil(this.unsubscriber.done));
    const selectedRoutes$ = this.pndStore$
      .select(RoutesStoreSelectors.stopsForSelectedRoutes)
      .pipe(takeUntil(this.unsubscriber.done));

    combineLatest([resequenced$, selectedRoutes$]).subscribe(([, stopsForSelectedRoutes]) => {
      this.loadingDataSubject = new AsyncSubject<RouteStopsGridItem[]>();

      const results: RouteStopsGridItem[][] = [];
      _forOwn(stopsForSelectedRoutes, (stops, routeInstId) => {
        this.pndStore$
          .select(RoutesStoreSelectors.selectedRoutes)
          .pipe(
            take(1),
            map((routes) => _find(routes, (route) => route.routeInstId === +routeInstId))
          )
          .subscribe((route) => {
            results.push(
              this.transformStopsToGrid(
                _orderBy(stops, (stop) => _get(stop, 'tripNode.stopSequenceNbr')),
                {
                  ...new Route(),
                  routeInstId: +routeInstId,
                  routePrefix: _get(route, 'routePrefix'),
                  routeSuffix: _get(route, 'routeSuffix'),
                }
              )
            );
          });
      });
      this.loadingDataSubject.next(_flatten(results));
      this.loadingDataSubject.complete();
      this.refresh();
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    return this.loadingDataSubject.pipe(
      map((routeStops: RouteStopsGridItem[]) => {
        this.grandTotalService.updateRouteStopsGrandTotals(routeStops);
        return new XpoBoardData(state, { rows: routeStops }, routeStops.length, 10000);
      })
    );
  }

  clearData(): void {
    this.setRoutes([]);
    this.refresh();
  }

  get routes(): RouteGridItem[] {
    return this.routeSource$.value;
  }

  get routes$(): Observable<RouteGridItem[]> {
    return this.routeSource$.asObservable();
  }

  private setRoutes(v: RouteGridItem[]): void {
    this.routeSource$.next(v);
  }

  private transformStopsToGrid(stops: Stop[], route: Route): RouteStopsGridItem[] {
    // TODO: Finish fixing Mapping
    return stops
      .filter((s) => s.tripNode.nodeTypeCd !== 'ServiceCenter')
      .map((stop) => {
        let totalBills = 0;
        let totalLoosePieces = 0;
        let totalCube = 0;
        let totalMotorMoves = 0;
        let totalWeight = 0;
        const totalFootprint = 0; // TODO: update when we have footprint
        const deliveryWindow = ''; // TODO: update when we have beginTime and endTime in activities
        let consigneeAddress = '';
        let positionOnTrailer = '';

        if (stop.activities && stop.activities.length) {
          stop.activities.forEach((act: Activity) => {
            if (act.tripNodeActivity) {
              totalBills = totalBills + act.tripNodeActivity.totalBillCount;
              totalLoosePieces = totalLoosePieces + act.tripNodeActivity.totalPiecesCount;
              totalCube = totalCube + act.tripNodeActivity.totalCubePercentage;
              totalMotorMoves = totalMotorMoves + act.tripNodeActivity.totalMmCount;
              totalWeight = totalWeight + act.tripNodeActivity.totalWeightCount;
            }
            if (act.routeShipment) {
              positionOnTrailer = act.routeShipment.positionOnTrailer || '';
            }
          });
        }

        if (stop.customer) {
          if (stop.customer.addressLine1) {
            consigneeAddress += stop.customer.addressLine1;
          }
          if (stop.customer.addressLine2) {
            consigneeAddress += ' ' + stop.customer.addressLine2;
          }
        }

        const activityTypes = _countBy(stop.activities || [], (a) => _get(a, 'tripNodeActivity.activityCd', ''));
        delete activityTypes[TripNodeActivityCd.ARRIVE];
        delete activityTypes[TripNodeActivityCd.DEPART_DISPATCH];

        const item = {
          id: stop.tripNode.nodeInstId,
          routeId: PndRouteUtils.getRouteId(route),
          routeInstId: route.routeInstId,
          seqNo: stop.tripNode.stopSequenceNbr,
          origSeqNo: stop.tripNode.stopSequenceNbr,
          stopType: activityTypes.length > 1 ? 'MX' : Object.keys(activityTypes)[0],
          consigneeName: _trim(`${_get(stop, 'customer.name1', '')} ${_get(stop, 'customer.name2', '')}`),
          consigneeAddress: consigneeAddress || '-',
          consigneeCity: _get(stop, 'customer.cityName', ''),
          postalCd: _get(stop, 'customer.postalCd', ''),
          ltr: positionOnTrailer,
          totalBills,
          totalLoosePieces,
          totalMotorMoves,
          totalWeight,
          totalFootprint,
          totalCube,
          deliveryWindow,
          estimatedArrival: this.timeService.to24Time(stop.tripNode.estimatedArriveDateTime, stop.tripNode.nodeSicCd),
          estimatedDeparture: this.timeService.to24Time(stop.tripNode.estimatedDepartDateTime, stop.tripNode.nodeSicCd),
          stopNotes: '', // TODO: update when available
          specialServices: this.specialServicesService.getSpecialServicesForStop(stop),
          longitude: _get(stop, 'customer.longitudeNbr', null),
          latitude: _get(stop, 'customer.latitudeNbr', null),
          status: stop.tripNode ? stop.tripNode.statusCd : '',
          stopWindowSummary: _get(stop, 'stopWindowSummary', []),
          uniqId: `${route.routeInstId}-${stop.tripNode.tripInstId}-${stop.tripNode.tripNodeSequenceNbr}`,
        };
        return item;
      });
  }
}
