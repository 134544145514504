import { Component, OnDestroy, OnInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { find as _find, orderBy as _orderBy } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { MapSplitPanelService } from '../../shared';
import { MapSplitPanelHostDirective } from '../planning-map/components/map-split-panels/map-split-panel-host.directive';
import { MapSplitPanelItem } from '../planning-map/components/map-split-panels/map-split-panel-item';
import { MapSplitPanelComponent } from '../planning-map/components/map-split-panels/map-split-panel.component';

export interface MapSplitPanelConfig {
  minSize: number;
  size: number;
  visible: boolean;
  direction: MapSplitPanelDirection;
  gutterSize: number;
  useTransition: boolean;
  disabled: boolean;
  expandedSize?: number;
}

export enum MapSplitPanelDirection {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

@Component({
  selector: 'pnd-planning-map-splitter',
  templateUrl: './planning-map-splitter.component.html',
  styleUrls: ['./planning-map-splitter.component.scss'],
})
export class PlanningMapSplitterComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();
  mapPanelItemsArray: MapSplitPanelItem[];
  currentLoadedComponent: string;
  panelConfig: MapSplitPanelConfig = {
    minSize: 37,
    size: 37,
    visible: false,
    direction: MapSplitPanelDirection.VERTICAL,
    gutterSize: 3,
    useTransition: true,
    disabled: true,
    expandedSize: 600,
  };

  constructor(
    private mapSplitPanelService: MapSplitPanelService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  @ViewChild(MapSplitPanelHostDirective, { static: true }) mapSplitPanelHost: MapSplitPanelHostDirective;

  ngOnInit(): void {
    this.mapSplitPanelService.componentsList$
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((itemsArr: MapSplitPanelItem[]) => {
        this.initMapSplitPanel(itemsArr);
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  initMapSplitPanel(itemsArr: MapSplitPanelItem[]): void {
    this.mapPanelItemsArray = _orderBy(itemsArr, (item) => item.data.orderIndex);
    if (!_find(this.mapPanelItemsArray, (item) => item.data.id === this.currentLoadedComponent)) {
      this.collapseMapSplitPanel();
    }
    this.toggleMapSplitPanelVisibility(itemsArr.length > 0);
  }

  clearComponentInSplitPanel(): void {
    this.currentLoadedComponent = '';
    const viewContainerRef = this.mapSplitPanelHost.viewContainerRef;
    viewContainerRef.clear();
  }

  loadComponentInSplitPanel(mapPanelItem: MapSplitPanelItem): void {
    if (mapPanelItem) {
      this.currentLoadedComponent = mapPanelItem.data.id;
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(mapPanelItem.component);
      const viewContainerRef = this.mapSplitPanelHost.viewContainerRef;
      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(componentFactory);
      (<MapSplitPanelComponent>componentRef.instance).data = mapPanelItem.data;
      this.toggleMapSplitPanelDraggable(true);
      this.changeMapSplitPanelSize(this.panelConfig.expandedSize);
    }
  }

  toggleMapSplitPanelDraggable(isDraggable: boolean) {
    this.panelConfig.disabled = !isDraggable;
  }

  changeMapSplitPanelSize(s: number): void {
    this.panelConfig.size = s;
  }

  toggleMapSplitPanelVisibility(isVisible: boolean): void {
    this.panelConfig.visible = isVisible;
    if (!isVisible) {
      this.collapseMapSplitPanel();
    }
  }

  collapseMapSplitPanel(): void {
    this.toggleMapSplitPanelDraggable(false);
    this.changeMapSplitPanelSize(this.panelConfig.minSize);
    this.clearComponentInSplitPanel();
  }
}
