import { Store } from '@ngrx/store';
import {
  ServiceCenter,
  LocationApiService,
  GetZoneAndSatelliteBySicPath,
  GetZoneAndSatelliteBySicQuery,
  ZoneSatelliteInfo,
  ListLocationsRqst,
  ListLocationsQuery,
  LocationSic,
} from '@xpo-ltl-2.0/sdk-location';
import { XpoMultiSelectAutocompleteFilter } from '@xpo-ltl/ngx-ltl-board';
import { XpoLtlServiceCentersService } from '@xpo/ngx-ltl';
import {
  filter as _filter,
  get as _get,
  isEmpty as _isEmpty,
  map as _map,
  orderBy as _orderBy,
  startsWith as _startsWith,
} from 'lodash';
import { map, switchMap } from 'rxjs/operators';
import { PndStoreState, GlobalFilterStoreSelectors } from '../../../store';
import { ListLocationsCacheService } from '../services/list-locations.service';

// NOTE: This is the current "Current SIC" filter until the API for PCT-4139 is enhanced due to performance issues!
export class SicFilter extends XpoMultiSelectAutocompleteFilter {
  constructor(field: string, name: string = 'SIC', serviceCentersService: XpoLtlServiceCentersService) {
    super(field, name, (partialName: string) =>
      serviceCentersService.serviceCenters$.pipe(
        map((sicList: ServiceCenter[]) => {
          const filteredSics = serviceCentersService.filterSicsBy(sicList, partialName);
          const centers = filteredSics.map((s) => ({ code: s.sicCd, title: s.sicCd }));
          return _orderBy(centers, (s) => s.code);
        })
      )
    );
  }
}

// NOTE: This is the "ideal" "Current SIC" filter but until the API for PCT-4139 is enhanced for performance issues we divert to the above filter.
export class CurrentSicFilter extends XpoMultiSelectAutocompleteFilter {
  constructor(field: string, name: string = 'SIC', private listLocationsCacheService: ListLocationsCacheService) {
    super(field, name, (partialName: string) =>
      listLocationsCacheService.request({ meetAndTurnInd: true }).pipe(
        map((response) =>
          _orderBy(
            _filter(<LocationSic[]>_get(response, 'locationSic', []), (location) =>
              _isEmpty(partialName) ? true : _startsWith(location.sicCd, partialName)
            ),
            (location) => location.sicCd
          )
        )
      )
    );
  }
}

export class DestinationSicFilter extends XpoMultiSelectAutocompleteFilter {
  constructor(
    field: string,
    name: string = 'Destination SIC',
    pndStore$: Store<PndStoreState.State>,
    private locationService: LocationApiService
  ) {
    super(field, name, (partialName: string) =>
      pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic).pipe(
        switchMap((sic: string) => {
          const path: GetZoneAndSatelliteBySicPath = { ...new GetZoneAndSatelliteBySicPath(), sicCd: sic };
          const query: GetZoneAndSatelliteBySicQuery = { ...new GetZoneAndSatelliteBySicQuery(), satelliteInd: true };

          return this.locationService.getZoneAndSatelliteBySic(path, query).pipe(
            map((response) => {
              const zones = _map(
                _filter(<ZoneSatelliteInfo[]>_get(response, 'zoneSatelliteInfo', []), (z) =>
                  _isEmpty(partialName) ? true : _startsWith(z.locationSic, partialName)
                ),
                (z) => {
                  return { code: z.locationSic, title: z.locationSic };
                }
              );

              zones.push({ code: sic, title: sic });
              return _orderBy(zones, (z) => z.code);
            })
          );
        })
      )
    );
  }
}
