export enum RoutePlanningGridFields {
  ROW_SELECTED = 'rowSelected',
  TIMELINE = 'timeline',
  ROUTE_NAME = 'routeName',
  SIC = 'terminalSicCd',
  EARLIEST_SHIPMENT_ETA = 'enrouteEarliestMessage',
  LATEST_SHIPMENT_ETA = 'enrouteLatestMessage',
  BILLS = 'totalBillCount',
  MM = 'totalMmCount',
  WEIGHT = 'totalWeightCount',
  DOOR = 'plannedDoor',
  TRAILER = 'fullTrailerId',
  DOCK_LOCATION = 'dockName',
  NEAREST_DOOR = 'dockClosestDoorNbr',
}
