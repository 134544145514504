import { Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { BehaviorSubject } from 'rxjs';
import { InteractiveMapMarker } from '../../../shared/models/markers/map-marker';

export abstract class AbstractMarkerLayer<MARKER_TYPE extends InteractiveMapMarker> implements OnDestroy {
  @Output() markerRightClick = new EventEmitter<MARKER_TYPE>();

  @Input() googleMap: any;

  @Input() set zoomLevel(value: number) {
    this.zoomLevelSubject.next(value);
  }

  @Input() set showMarkers(value: boolean) {
    this.showMarkersSubject.next(value);
  }

  protected unsubscriber: Unsubscriber = new Unsubscriber();

  protected markersSubject = new BehaviorSubject<MARKER_TYPE[]>([]);
  readonly markers$ = this.markersSubject.asObservable();
  get markers() {
    return this.markersSubject.value;
  }

  protected zoomLevelSubject = new BehaviorSubject<number>(-1);
  zoomLevel$ = this.zoomLevelSubject.asObservable();
  get zoomLevel() {
    return this.zoomLevelSubject.value;
  }

  protected showMarkersSubject = new BehaviorSubject<boolean>(true);
  showMarkers$ = this.showMarkersSubject.asObservable();
  get showMarkers() {
    return this.showMarkersSubject.value;
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  /**
   * Marker mouse over
   * @param marker Related marker
   */
  abstract onMouseOver(marker: MARKER_TYPE): void;

  /**
   * Marker mouse out
   * @param marker Related marker
   */
  abstract onMouseOut(marker: MARKER_TYPE): void;

  /**
   * Marker click
   * @param marker Related marker
   */
  abstract onClick(marker: MARKER_TYPE): void;

  /**
   * Marker right click
   * @param marker Related marker
   * @param event Mouse event
   */
  abstract onRightClick(marker: MARKER_TYPE, event?: MouseEvent): void;
}
