export enum PlanningRouteShipmentsGridHeaders {
  ROUTE_ID = 'Route ID.',
  ROW_SELECTED = 'rowSelected',
  BILLS = 'Bills',
  CONSIGNEE = 'Consignee',
  ADDRESS = 'Address',
  CITY = 'City',
  WEIGHT = 'Weight (lbs.)',
  MM = 'MM',
  CUBE = 'Cube',
  DESTINATION_SIC = 'Destination SIC',
  CURRENT_SIC = 'Current SIC',
  SPECIAL_SERVICES = 'Special Services',
  DELIVERY_WINDOW_TYPE = 'Delivery Window Type',
  DELIVERY_WINDOW_TIME = 'Delivery Window Time',
  DELIVERY_WINDOW_DATE = 'Delivery Window Date',
}
