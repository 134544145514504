export enum PlanningRouteShipmentsGridFields {
  ROW_SELECTED = 'rowSelected',
  ROUTE_ID = 'routeId',
  BILLS = '*',
  CONSIGNEE = 'consigneeName',
  ADDRESS = 'consigneeAddress',
  CITY = 'consigneeCity',
  WEIGHT = 'totalWeightLbs',
  MM = 'MM',
  CUBE = 'totalCubePercentage',
  CURRENT_SIC = 'currentSicCd',
  SPECIAL_SERVICES = 'specialServiceSummary',
  DELIVERY_WINDOW_TYPE = 'deliveryWindowType',
  DELIVERY_WINDOW_TIME = 'deliveryWindowTime',
  DELIVERY_WINDOW_DATE = 'deliveryWindowDate',
}
