import { Injectable } from '@angular/core';
import { StopWindow, StopWindowCd } from '@xpo-ltl/sdk-common';
import { each as _each, get as _get } from 'lodash';
import * as moment from 'moment-timezone';
import { StopWindowCdPipe } from '../pipes';

@Injectable({
  providedIn: 'root',
})
export class StopWindowService {
  constructor(private stopWindowCdPipe: StopWindowCdPipe) {}

  getStopWindowType(stopWindow: StopWindow[], abbr = false): string {
    const stopWindowType: StopWindowCd = _get(stopWindow, '[0].stopWindowType');
    return this.stopWindowCdPipe.transform(stopWindowType, abbr);
  }

  getStopWindowTime(stopWindows: StopWindow[], prettyFormat = true): string {
    const stopWindowType: StopWindowCd = _get(stopWindows, '[0].stopWindowType');
    const unformattedBeginTime = _get(stopWindows, '[0].beginTime');
    const unformattedEndTime = _get(stopWindows, '[0].endTime');
    const beginTime = unformattedBeginTime ? moment(unformattedBeginTime, 'HH:mm:ss').format('HH:mm') : undefined;
    const endTime = unformattedEndTime ? moment(unformattedEndTime, 'HH:mm:ss').format('HH:mm') : undefined;
    let output: string;

    if (beginTime || endTime) {
      switch (stopWindowType) {
        case StopWindowCd.APPOINTMENT:
        case StopWindowCd.CONFLICT:
        case StopWindowCd.MIXED_SOURCE:
        case StopWindowCd.GUARANTEED_BY_NOON:
          if (!beginTime) {
            output = `N/A - ${endTime}`;
          } else if (!endTime) {
            output = `${beginTime} - N/A`;
          } else {
            output = `${beginTime} - ${endTime}`;
          }
          break;
        case StopWindowCd.TIME_DATE_CRITICAL:
          if (beginTime && endTime) {
            output = `${beginTime} - ${endTime}`;
          } else {
            output = beginTime ? `After ${beginTime}` : `By ${endTime}`;
          }
          break;
        case StopWindowCd.CUSTOMER_OPERATIONAL_HOURS:
        case StopWindowCd.CUSTOMER_PROFILE_TIME:
          output = '';
          _each(stopWindows, (stopWindow) => {
            const stopWindowBeginTime = stopWindow.beginTime
              ? moment(stopWindow.beginTime, 'HH:mm:ss').format('HH:mm')
              : 'N/A';
            const stopWindowEndTime = stopWindow.endTime
              ? moment(stopWindow.endTime, 'HH:mm:ss').format('HH:mm')
              : 'N/A';
            if (prettyFormat) {
              output += `<div class="`;
              output += `${stopWindows.length > 1 ? 'customer-profile-cell-large' : 'customer-profile-cell'}`;
              output += `">`;
              output +=
                stopWindowBeginTime === 'N/A' && stopWindowEndTime === 'N/A'
                  ? ''
                  : `${stopWindowBeginTime} - ${stopWindowEndTime}</div>`;
            } else {
              if (output.length > 0) {
                output += ', ';
              }
              output +=
                stopWindowBeginTime === 'N/A' && stopWindowEndTime === 'N/A'
                  ? ''
                  : `${stopWindowBeginTime} - ${stopWindowEndTime}`;
            }
          });
          break;
      }
    }
    return output;
  }

  getStopWindowDate(stopWindow: StopWindow[]) {
    const stopWindowType: StopWindowCd = _get(stopWindow, '[0].stopWindowType', undefined);
    const unformattedBeginDate = _get(stopWindow, '[0].beginDate');
    const unformattedEndDate = _get(stopWindow, '[0].endDate');

    const beginDate = unformattedBeginDate ? moment(unformattedBeginDate).format('MM-DD') : undefined;
    const endDate = unformattedEndDate ? moment(unformattedEndDate).format('MM-DD') : undefined;

    let output = '';

    if (beginDate || endDate) {
      switch (stopWindowType) {
        case StopWindowCd.APPOINTMENT:
          output = beginDate || endDate;
          break;
        case StopWindowCd.TIME_DATE_CRITICAL:
          if (beginDate && endDate) {
            if (beginDate === endDate) {
              output = `On ${beginDate}`;
            } else {
              output = `${beginDate} - ${endDate}`;
            }
          } else {
            output = beginDate ? `After ${beginDate}` : `By ${endDate}`;
          }
          break;
      }
    }

    return output;
  }
}
