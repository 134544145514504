export enum ConfigManagerProperties {
  buildVersion = 'buildVersion',
  production = 'production',
  apiUrl = 'apiUrl',
  xrtUrl = 'xrtUrl',
  scopeOptions = 'scopeOptions',
  secretToken = 'secretToken',
  appName = 'appName',
  region = 'region',
  loggedInUserRoot = 'loggedInUserRoot',
  userContextPath = 'userContextPath',
  appMetadataEndpoint = 'appMetadataEndpoint',
  appMetadataAppName = 'appMetadataAppName',
  bolApiEndpoint = 'bolApiEndpoint',
  customerLocationApiEndpoint = 'customerLocationApiEndpoint',
  elasticSearchApiEndpoint = 'elasticSearchApiEndpoint',
  freightFlowApiEndpoint = 'freightFlowApiEndpoint',
  infrastructureApiEndpoint = 'infrastructureApiEndpoint',
  invoiceApiEndpoint = 'invoiceApiEndpoint',
  locationApiEndpoint = 'locationApiEndpoint',
  loggingApiEndpoint = 'loggingApiEndpoint',
  membershipApiEndpoint = 'membershipApiEndpoint',
  pricingApiEndpoint = 'pricingApiEndpoint',
  shipmentApiEndpoint = 'shipmentApiEndpoint',
  errorToastDuration = 'errorToastDuration',
  successToastDuration = 'successToastDuration',
  feedbackToAddress = 'feedbackToAddress',
  feedbackSubject = 'feedbackSubject',
  googleApiLicenseKey = 'googleApiLicenseKey',
  googleApiChannelName = 'googleApiChannelName',
  driversLocationRefreshInterval = 'driversLocationRefreshInterval',
  idleTimeoutInSeconds = 'idleTimeoutInSeconds',
  planDateCrossOverTime = 'planDateCrossOverTime',
  releaseNotesLink = 'releaseNotesLink',
  trainingResourcesLink = 'trainingResourcesLink',
  weatherMapSourceApiKey = 'weatherMapSourceApiKey',
}
