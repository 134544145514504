import { Store } from '@ngrx/store';
import { XpoAgGridBoardViewConfig } from '@xpo-ltl/ngx-ltl-board-grid';
import { forEach as _forEach, size as _size } from 'lodash';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { GlobalFilterStoreSelectors, PndStoreState } from '../../../../store';
import { InboundPlanningDataStoreBase } from '../../../shared/classes/inbound-planning-data-store-base.class';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import { UnassignedPickupsComponentName } from './unassigned-pickups-component-name';

export class UnassignedPickupsViewDataStore extends InboundPlanningDataStoreBase {
  private readonly viewId = '1';
  private planDate: Date;

  constructor(
    userPreferencesService: UserPreferencesService,
    preferences: XpoAgGridBoardViewConfig[],
    private pndStore$: Store<PndStoreState.State>
  ) {
    super(userPreferencesService, preferences, UnassignedPickupsComponentName);

    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterPlanDate)
      .pipe(take(1))
      .subscribe((planDate) => {
        this.planDate = planDate;
      });
  }

  getAll(): Observable<XpoAgGridBoardViewConfig[]> {
    if (_size(this.preferences) === 0) {
      return of([this.defaultConfig()]);
    } else {
      const viewArray: XpoAgGridBoardViewConfig[] = [];
      _forEach(this.preferences, (element: XpoAgGridBoardViewConfig) => {
        if (element && (element.name === 'Show All' || element.name === 'New') && element.criteria) {
          element.criteria.pickupDate = {
            min: this.planDate,
            max: this.planDate,
          };
        }

        viewArray.push({
          closeable: element.closeable,
          criteria: element.criteria,
          id: element.id,
          name: element.name,
          visible: element.visible,
          visibleColumns: element.visibleColumns,
          systemDefined: element.systemDefined || false,
          children: element.children || [],
          lastSaved: element.lastSaved,
          sortOrder: element.sortOrder || [],
          templateId: element.templateId || '',
        });
      });
      return of(viewArray);
    }
  }

  defaultConfig(): XpoAgGridBoardViewConfig {
    return {
      closeable: false,
      criteria: {
        pickupDate: {
          min: this.planDate,
          max: this.planDate,
        },
      },
      id: this.viewId,
      name: 'Show All',
      visible: true,
      systemDefined: true,
    };
  }
}
