import { Inject, Injectable } from '@angular/core';
import { ComponentConfiguration, GoldenLayoutService } from '@embedded-enterprises/ng6-golden-layout';
import GoldenLayout from 'golden-layout';
import {
  cloneDeep as _cloneDeep,
  defaultTo as _defaultTo,
  first as _first,
  forEach as _forEach,
  get as _get,
  has as _has,
  map as _map,
  remove as _remove,
  size as _size,
  some as _some,
} from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { LayoutComponentName } from '../../../app/layout-component-name.enum';
import { LayoutConfig } from '../layout-config.interface';
import { GoldenLayoutExtService } from './golden-layout-ext.service';
import {
  XpoLtlLayoutPreferencesStorage,
  XpoLtlLayoutPreferencesStorageData,
  XPO_LTL_LAYOUT_PREFERENCES_STORAGE,
} from './layout-preferences-storage.interface';

@Injectable({
  providedIn: 'root',
})
export class LayoutPreferenceService {
  private defaultLayoutName: string;
  private defaultLayouts: LayoutConfig[] = [];

  private availableLayoutsSubject = new BehaviorSubject<LayoutConfig[]>([]);
  readonly availableLayouts$ = this.availableLayoutsSubject.asObservable();

  private activeLayoutSubject = new BehaviorSubject<LayoutConfig>(undefined);
  readonly activeLayout$ = this.activeLayoutSubject.asObservable();

  set activeLayout(value: LayoutConfig) {
    this.activeLayoutSubject.next(value);
    this.installLayout(_cloneDeep(value));
  }

  get activeLayout(): LayoutConfig {
    return this.activeLayoutSubject.value;
  }

  private stashedLayout: {
    layout: GoldenLayout.ContentItem;
    reusedComponents: string[];
  };

  constructor(
    @Inject(GoldenLayoutService) private goldenLayoutService: GoldenLayoutExtService,
    @Inject(XPO_LTL_LAYOUT_PREFERENCES_STORAGE) private storage: XpoLtlLayoutPreferencesStorage
  ) {}

  /**
   * Initialize with the passed Components and default Layouts. Loads user configurations.
   */
  initialize(components: ComponentConfiguration[], defaultLayouts: LayoutConfig[]) {
    // set the components
    this.goldenLayoutService.config.components = components;

    this.defaultLayouts = _defaultTo(defaultLayouts, []);
    if (this.defaultLayouts.length === 0) {
      // There are no default layouts. Create a default
      const defaultLayout = {
        name: 'default',
        default: true,
        content: [],
      };

      const defaultComponent = _first(this.goldenLayoutService.config.components);
      if (defaultComponent) {
        // use first component as the default component to display
        defaultLayout.content.push({
          type: 'component',
          componentName: defaultComponent.componentName,
          title: defaultComponent.componentName,
        });
      }

      this.defaultLayouts.push(defaultLayout);
    }

    // use the first defaultLayout as the default
    this.defaultLayoutName = _get(_first(this.defaultLayouts), 'name');
  }

  /**
   * load saved layouts from storage
   */
  loadLayoutsFromStorage() {
    // wait until the golden layout has been initialized before loading new layouts
    this.goldenLayoutService.initialized$
      .pipe(
        take(1),
        switchMap(() => this.storage.getData())
      )
      .subscribe((userPrefs: XpoLtlLayoutPreferencesStorageData) => {
        // Hack: Fix Golden Layout maximised bug after restore
        const userLayouts: LayoutConfig[] = [
          ...userPrefs.userLayouts.map((layout) => {
            layout['maximisedItemId'] = null;
            return layout;
          }),
        ];
        const layouts = [...this.defaultLayouts, ...userLayouts];
        this.availableLayoutsSubject.next(layouts);

        const lastLayout = this.getLayout(userPrefs.activeLayout);
        const defaultLayout = this.getLayout(this.defaultLayoutName);
        this.activeLayout = _defaultTo(lastLayout, defaultLayout);
      });
  }

  /**
   * Return true if the named layout exists
   * @param layoutName name of the layout to look for (case-insensitive)
   */
  hasLayout(layoutName: string): boolean {
    return this.getLayout(layoutName) !== undefined;
  }

  /**
   * Returns the named Layout
   * @param layoutName name of layout (case-insensitive)
   */
  getLayout(layoutName: string): LayoutConfig {
    if (layoutName && this.availableLayoutsSubject.value.length > 0) {
      const layoutToFind = layoutName.toLowerCase();
      return this.availableLayoutsSubject.value.find((item) => item.name.toLowerCase() === layoutToFind);
    }
    return undefined;
  }

  /**
   * Save the current layout as a new layout with the passed name, replacing existing layout if it
   * it already exists
   */
  saveLayoutAs(layoutName: string) {
    const newLayout: LayoutConfig = this.goldenLayoutService.goldenLayout.toConfig();
    // Hack: Fix Golden Layout maximised bug after restore
    newLayout['maximisedItemId'] = null;

    const existingDefault = this.availableLayoutsSubject.value.find(
      (item) => item.default && item.name.toLowerCase() === layoutName.toLowerCase()
    );
    if (existingDefault || _size(layoutName) === 0) {
      layoutName = 'User ' + layoutName;
    }

    newLayout.name = layoutName;
    newLayout.default = false;

    // remove the named layout if it is already in the list
    const userLayouts = this.availableLayoutsSubject.value.filter(
      (item) => !item.default && item.name.toLowerCase() !== layoutName.toLowerCase()
    );
    userLayouts.push(newLayout);

    // rebuild list of available layouts to include the new layout
    const defaultLayouts = this.availableLayoutsSubject.value.filter((item) => item.default);
    this.availableLayoutsSubject.next([...defaultLayouts, ...userLayouts]);

    // set the layout we just saved as active
    this.activeLayout = this.getLayout(layoutName);

    return this.savePrefs();
  }

  /**
   * Delete the named layout.  If it is the current layout, then make the default
   * layout active.
   *
   * @param layoutName name of layout to delete
   */
  deleteLayout(layoutName: string): Observable<void> {
    if (_size(layoutName) === 0) {
      // must supply a name
      return of();
    }

    // remove the named layout from the list
    const userLayouts = this.availableLayoutsSubject.value.filter((item) => !item.default);
    const removed = _remove(userLayouts, (item) => item.name.toLowerCase() === layoutName.toLowerCase());
    if (_size(removed) === 0) {
      return of();
    }

    if (this.activeLayout.name === layoutName) {
      // deleting active layout, so make default layout active
      this.activeLayout = this.getLayout(this.defaultLayoutName);
    }

    // rebuild list of available layouts to include the new layout
    const defaultLayouts = this.availableLayoutsSubject.value.filter((item) => item.default);
    this.availableLayoutsSubject.next([...defaultLayouts, ...userLayouts]);

    return this.savePrefs();
  }

  /**
   * Clear all user layouts and resets to the default layout
   * WARNING: This is DESTRUCTIVE! ALL SAVED USER LAYOUTS WILL BE LOST!
   */
  deleteAllLayouts(): Observable<void> {
    this.availableLayoutsSubject.next(this.defaultLayouts);
    this.activeLayout = undefined;

    const userPrefs = {
      activeLayout: this.defaultLayoutName,
      userLayouts: [],
    };

    return this.storage.setData(userPrefs);
  }

  /**
   * Stash the current layout, hiding it, and adding components from the new layout
   * If a component from the new layout already exists, reuse it. else, create a new one
   */
  stashAndLoadLayout(layout: LayoutConfig) {
    const goldenLayout = this.goldenLayoutService.goldenLayout;
    if (goldenLayout) {
      console.log(`stashing current layout: `, JSON.stringify(goldenLayout.toConfig()));

      // Gather list of all components currently in GL that we may reuse
      const reusableComponents = _map(goldenLayout.root.getItemsByType('component'), (item) => {
        return _get(item, 'componentName');
      });

      // clone the incoming layout and modify it to mark reusable components
      const newLayoutConfig = _cloneDeep(layout) as GoldenLayout.ItemConfigType;
      const reusedComponents = this.markForReuse(newLayoutConfig, reusableComponents);

      // Save the current layout to restore it later
      // NOTE: even though we remove the item from GL, it still exists, so we
      // need to hide it and set height to 0 to ensure it isn't visible
      this.stashedLayout = {
        layout: goldenLayout.root.contentItems[0],
        reusedComponents,
      };
      this.stashedLayout.layout.element.hide();
      this.stashedLayout.layout.config.height = 0;

      // remove existing layout, but don't destroy it
      goldenLayout.root.removeChild(this.stashedLayout.layout as any, true);

      // install the new layout (this creates the ContentItems for the layout from the newLayout config)
      goldenLayout.root.addChild(newLayoutConfig.content[0]);

      // replace all placeholders with components from existing layout
      _forEach(this.stashedLayout.reusedComponents, (existingComponentName: string, index) => {
        this.replaceReusableComponent(
          goldenLayout.root.contentItems[0],
          this.stashedLayout.layout,
          existingComponentName
        );
      });

      goldenLayout.updateSize();

      console.log(`set new layout: `, JSON.stringify(goldenLayout.toConfig()));
      console.log(`stashedLayout: `, this.stashedLayout);
    }
  }

  /**
   * restore the previously stashed layout, reusing existing components from current layout
   */
  restoreStashedLayout() {
    const goldenLayout = this.goldenLayoutService.goldenLayout;
    if (goldenLayout) {
      console.log(`current layout: `, JSON.stringify(goldenLayout.toConfig()));

      const currentLayout = goldenLayout.root.contentItems[0];

      // replace all placeholders with components from existing layout
      _forEach(this.stashedLayout.reusedComponents, (existingComponentName: string, index) => {
        this.replaceReusableComponent(this.stashedLayout.layout, currentLayout, existingComponentName);
      });

      // remove the current layout, destroying it
      currentLayout.remove();

      // show and add the stashed layout
      this.stashedLayout.layout.element.show();
      this.stashedLayout.layout.config.height = 100;

      goldenLayout.root.addChild(this.stashedLayout.layout);
      goldenLayout.updateSize();

      // clear stashed data
      this.stashedLayout = undefined;

      console.log('restoreStashedLayout: ', JSON.stringify(goldenLayout.toConfig()));
    }
  }

  /**
   * Find and return the Component in the passed hiearchy
   */
  private getComponentByName(
    item: GoldenLayout.ContentItem | GoldenLayout.ItemConfigType,
    name: string
  ): GoldenLayout.ContentItem | GoldenLayout.ItemConfigType {
    if (_get(item, 'componentName') === name) {
      return item;
    } else {
      // look in content items for the component
      const content = _has(item, 'contentItems')
        ? _get(item, 'contentItems')
        : (_get(item, 'content') as GoldenLayout.ContentItem[] | GoldenLayout.ItemConfigType[]);

      for (let ii = 0; ii < _size(content); ii++) {
        const component = this.getComponentByName(content[ii], name);
        if (component) {
          return component;
        }
      }
      return undefined;
    }
  }

  /**
   * Replace all existing components in the hiearchy with placeholders
   * Returns list of compnentNames that have been marked for reuse
   */
  private markForReuse(
    item: GoldenLayout.ItemConfigType,
    existingComponents: string[],
    reusedComponentNames: string[] = []
  ): string[] {
    if (item.type === 'component') {
      const componentConfig = item as GoldenLayout.ComponentConfig;
      const itemName = _get(item, 'componentName');
      if (_some(existingComponents, (existing: string) => existing === componentConfig.componentName)) {
        // modify the item to be a marker to be replaced with reused component
        componentConfig.id = `${itemName}-placeholder`;
        componentConfig.type = 'stack';
        componentConfig.componentName = undefined;
        reusedComponentNames.push(itemName);
      }
    }

    // scan child components for existing components to reuse
    _forEach(item.content, (subItem) => {
      this.markForReuse(subItem, existingComponents, reusedComponentNames);
    });

    return reusedComponentNames;
  }

  /**
   * Replace the named reusable component in the container with the componet in reusableLayout
   */
  private replaceReusableComponent(
    targetContainer: GoldenLayout.ContentItem,
    sourceContainer: GoldenLayout.ContentItem,
    componentToReuse: string
  ) {
    // find the placehold for the component
    const componentContainerId = `${componentToReuse}-placeholder`;
    const targetPlaceholder = _first(targetContainer.getItemsById(componentContainerId));
    if (!targetPlaceholder) {
      return;
    }
    const targetParent = targetPlaceholder.parent;

    // find the component in the source
    const sourceComponent = this.getComponentByName(sourceContainer, componentToReuse) as GoldenLayout.ContentItem;
    if (!sourceComponent) {
      return;
    }
    const sourceParent = sourceComponent.parent;
    const sourcePlaceholder: GoldenLayout.ItemConfig = {
      id: componentContainerId,
      type: 'stack',
    };
    sourceParent.replaceChild(sourceComponent as any, sourcePlaceholder);

    // replace the target with the component we want to reuse
    targetParent.replaceChild(targetPlaceholder, sourceComponent);
    if (targetParent.type === 'stack') {
      // activate the component in the stack
      targetParent.setActiveContentItem(sourceComponent);
    }
  }

  /**
   * Load the passed layout into GoldenLayout
   */
  private installLayout(layout: LayoutConfig): void {
    const goldenLayout = this.goldenLayoutService.goldenLayout;
    if (!goldenLayout) {
      // GL not initialized yet
      return;
    }
    if (layout) {
      // scan through the layout and fix any activeItemIndex that is out of range.
      const fixActiveItemIndex = (data) => {
        if (_has(data, 'activeItemIndex')) {
          if (data.activeItemIndex >= _size(data.content)) {
            data.activeItemIndex = 0;
          }
        }
        _forEach(data.content, (c) => {
          fixActiveItemIndex(c);
        });
      };

      // Fix 'ag-header' bug when layout is maximized or exiting Route Balancer and
      // reset ag header container translateX props to 0px
      const resetAgHeaderContainerProps = (item: GoldenLayout.ContentItem): void => {
        if (!!_size(item.childElementContainer)) {
          Array.from(item.childElementContainer[0].getElementsByClassName('ag-header-container')).forEach(
            (elem: HTMLElement) => {
              if (elem.style.transform !== 'translateX(0px)') {
                elem.style.transform = 'translateX(0px)';
              }
            }
          );
        }
      };

      // Recursively adds event listeners to all child content items to act on various events that do
      // not propogate to parent content item elements
      const addContentItemListeners = (contentItems: GoldenLayout.ContentItem[]) => {
        _forEach(contentItems, (contentItem: GoldenLayout.ContentItem) => {
          contentItem.on('maximised', () => {
            resetAgHeaderContainerProps(contentItem);
          });

          contentItem.on('minimised', () => {
            resetAgHeaderContainerProps(contentItem);
          });

          if (_size(contentItem.contentItems)) {
            addContentItemListeners(contentItem.contentItems);
          }
        });
      };

      fixActiveItemIndex(layout);

      // remove all items from current layout
      goldenLayout.root.contentItems.forEach((item: GoldenLayout.ContentItem) => {
        item.remove();
      });

      // add all items from new layout
      layout.content.forEach((item) => {
        goldenLayout.root.addChild(item);
      });

      // add listeners to content items
      addContentItemListeners(goldenLayout.root.contentItems);

      // listen for route balancer destruction to fix ag-header bug
      goldenLayout.on('stackCreated', (stack: GoldenLayout.ContentItem) => {
        stack.on('itemDestroyed', (item: GoldenLayout.ContentItem) => {
          if (_get(item, 'origin.config.componentName') === LayoutComponentName.ROUTE_BALANCING) {
            const getRootContentItem = (currentStackItem: GoldenLayout.ContentItem) =>
              !!_get(currentStackItem, 'parent.isRoot')
                ? currentStackItem.parent
                : getRootContentItem(currentStackItem.parent);
            const rootStack: GoldenLayout.ContentItem = getRootContentItem(stack);
            resetAgHeaderContainerProps(rootStack);
          }
        });
      });

      goldenLayout.updateSize();

      // update this layout as the last selected
      this.savePrefs().subscribe();
    } else {
      // clear out all content leaving no open panels
      goldenLayout.root.contentItems.forEach((item: GoldenLayout.ContentItem) => {
        item.remove();
      });

      goldenLayout.updateSize();
    }
  }

  /**
   * Save the currently activeLayout and all userLayouts
   */
  private savePrefs(): Observable<void> {
    const userPrefs = {
      activeLayout: _get(this.activeLayout, 'name', ''),
      userLayouts: this.availableLayoutsSubject.value.filter((item) => !item.default),
    };

    return this.storage.setData(userPrefs);
  }
}
