import { Store } from '@ngrx/store';
import { XpoSelectFilter, XpoSelectFilterParams } from '@xpo-ltl/ngx-ltl-board';
import { isEmpty as _isEmpty, map as _map, orderBy as _orderBy } from 'lodash';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { PndStoreState, PlanningProfilesStoreSelectors, GlobalFilterStoreSelectors } from '../../../store';

export class PlanningProfilesFilter extends XpoSelectFilter {
  constructor(field: string, name: string, private pndStore$: Store<PndStoreState.State>) {
    super(<XpoSelectFilterParams>{
      field: field,
      label: 'Profiles',
      options: combineLatest([pndStore$.select(PlanningProfilesStoreSelectors.planningProfiles)]).pipe(
        map(([planningProfiles]) => {
          return _orderBy(
            _map(planningProfiles, (planningProfile) => {
              return {
                title: planningProfile.profileName,
                code: planningProfile.profileId,
              };
            }),
            (planningProfile) => planningProfile.title
          );
        })
      ),
      isSingleSelect: true,
    });
  }
}
