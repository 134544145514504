import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { DeliveryShipmentSearchRecord } from '@xpo-ltl/sdk-cityoperations';
import { TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import { Unsubscriber, XpoLtlShipmentDescriptor } from '@xpo/ngx-ltl';
import { clone as _clone, get as _get } from 'lodash';
import { Observable } from 'rxjs';
import { map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { PndDialogService } from '../../../../../../../core/dialogs/pnd-dialog.service';
import { PndStoreState } from '../../../../../../store';
import { GeoLocationStoreActions, GeoLocationStoreSelectors } from '../../../../../../store/geo-location-store';
import { ActivityCdPipe } from '../../../../pipes';
import { MapMarkersService } from '../../../../services/map-markers.service';
import { DELIVERY_COLOR, PICKUP_COLOR } from '../../../../services/stop-colors';
import { AssignToRouteDialogData } from '../../../assign-to-route/assign-to-route-dialog-data';
import { AssignToRouteComponent } from '../../../assign-to-route/assign-to-route.component';
import { ExistingOrNewRoute } from '../../../assign-to-route/existing-or-new-route.enum';
import { UnmappedStopStatusCode } from '../../classes/unmapped-stop-status-code.enum';
import { UnmappedStopDetail } from './unmapped-stop-detail.model';

@Component({
  selector: 'pnd-unmapped-stop-detail',
  templateUrl: './unmapped-stop-detail.component.html',
  styleUrls: ['./unmapped-stop-detail.component.scss'],
  host: { class: 'pnd-UnmappedStopDetail' },
})
export class UnmappedStopDetailComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();

  @Input() stopDetail: UnmappedStopDetail;
  @Output() reassignedToRoute: EventEmitter<UnmappedStopDetail> = new EventEmitter(); // emitted when stop is assigned to a route

  readonly active$: Observable<boolean> = this.pndStore$.select(GeoLocationStoreSelectors.stopToEdit).pipe(
    map((stop) => {
      return !!stop && stop.acctInstId === this.stopDetail.acctInstId;
    }),
    takeUntil(this.unsubscriber.done)
  );

  readonly disabled$: Observable<boolean> = this.pndStore$.select(GeoLocationStoreSelectors.stopToEdit).pipe(
    map((stop) => {
      return !!stop && stop.acctInstId !== this.stopDetail.acctInstId;
    }),
    takeUntil(this.unsubscriber.done)
  );

  readonly status$ = this.pndStore$.select(GeoLocationStoreSelectors.status).pipe(takeUntil(this.unsubscriber.done));

  readonly hasErrors$: Observable<boolean> = this.status$.pipe(
    withLatestFrom(this.active$),
    map(([status, active]) => {
      return (
        active &&
        status &&
        (status.code === UnmappedStopStatusCode.SEARCHING_ERROR || status.code === UnmappedStopStatusCode.SAVING_ERROR)
      );
    })
  );

  readonly isSearching$: Observable<boolean> = this.status$.pipe(
    withLatestFrom(this.active$),
    map(([status, active]) => {
      return active && status && status.code === UnmappedStopStatusCode.SEARCHING;
    })
  );

  readonly isSaving$: Observable<boolean> = this.status$.pipe(
    withLatestFrom(this.active$),
    map(([status, active]) => {
      return active && status && status.code === UnmappedStopStatusCode.SAVING;
    })
  );

  readonly TripNodeActivityCd = TripNodeActivityCd;

  stopSvg: SafeHtml;
  originalAddress: string;

  constructor(
    private sanitizer: DomSanitizer,
    private activityCdPipe: ActivityCdPipe,
    private pndDialogService: PndDialogService,
    private dialog: MatDialog,
    private pndStore$: Store<PndStoreState.State>,
    private mapMarkersService: MapMarkersService
  ) {}

  ngOnInit() {
    if (this.stopDetail) {
      this.originalAddress = _clone(this.stopDetail.address);
      this.stopSvg = this.buildMarkerHtml(this.stopDetail.stopTypeCd);
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  /**
   * Begin editing this stop location
   */
  beginEdit(forceGeocode: boolean = false) {
    // Need to trigger looking up the address again since the user may have edited it
    // get the address the user typed in
    this.pndStore$.dispatch(
      new GeoLocationStoreActions.SetStopToEdit({
        ...this.stopDetail,
        // if forceGeocode, then clear existing location to force geocoding the address
        location: forceGeocode ? undefined : this.stopDetail.location,
      })
    );
  }

  /**
   * Show the shipment details dialog for this stop
   */
  showShipmentDetails(): void {
    const shipmentsDescriptor: XpoLtlShipmentDescriptor[] = [];
    _get(this.stopDetail, 'shipmentDetails', []).forEach((shipmentDetail: DeliveryShipmentSearchRecord) => {
      shipmentsDescriptor.push({ proNbr: shipmentDetail.proNbr, shipmentInstId: shipmentDetail.shipmentInstId });
    });
    this.pndDialogService.showShipmentDetailsDialog(shipmentsDescriptor).subscribe();
  }

  /**
   * show Assign to Route dialog for this stop
   */
  assignToRoute(): void {
    const selectedShipments = new Map<number, DeliveryShipmentSearchRecord[]>();
    selectedShipments.set(0, this.stopDetail.shipmentDetails);

    const dialogRef = this.dialog.open(AssignToRouteComponent, {
      data: <AssignToRouteDialogData>{
        initialMode: ExistingOrNewRoute.Existing,
        selectedShipments,
      },
      disableClose: true,
      hasBackdrop: true,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((actionPerformed) => {
        if (actionPerformed) {
          this.reassignedToRoute.emit(this.stopDetail);
        }
      });
  }

  /// Create the SVG representing this stop
  private buildMarkerHtml(stopTypeCd: TripNodeActivityCd): SafeHtml {
    // TODO - do we want the color to match any route the stop is a part of?
    const color = stopTypeCd === TripNodeActivityCd.PICKUP_SHIPMENTS ? PICKUP_COLOR : DELIVERY_COLOR;

    const stopType = this.activityCdPipe.transform(stopTypeCd);
    const icon = this.mapMarkersService.getMarkerIconUnassignedSvg(stopType, 10, false, color);

    // NOTE: Need to fix the url for filter or it won't render correctly
    const svg = icon.svg.replace('url(%23shadowUn3)', `url('#shadowUn3')`);

    return this.sanitizer.bypassSecurityTrustHtml(svg);
  }
}
