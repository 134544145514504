import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MapToolbarService {
  private toggleDrawModeOffSubject = new BehaviorSubject<void>(null);
  readonly toggleDrawModeOff$ = this.toggleDrawModeOffSubject.asObservable();

  private setDrawModeStateSubject = new BehaviorSubject<boolean>(true);
  readonly setDrawModeState$ = this.setDrawModeStateSubject.asObservable();

  private clearGeoAreaFilterSubject = new BehaviorSubject<void>(null);
  readonly clearGeoAreaFilter$ = this.clearGeoAreaFilterSubject.asObservable();

  toggleDrawModeOff(): void {
    this.toggleDrawModeOffSubject.next();
  }

  setDrawModeState(enabled: boolean): void {
    this.setDrawModeStateSubject.next(enabled);
  }

  handleSicClicked() {
    this.clearGeoAreaFilterSubject.next();
  }

  drawPolygonLayer(map, arrayofLatLng, layerId) {
    map.data.add({ geometry: new google.maps.Data.Polygon([arrayofLatLng]), id: layerId });
    map.data.setStyle({
      strokeColor: '#304FFE',
      strokeWeight: 3,
      fillColor: '#304FFE',
      fillOpacity: 0.1,
      clickable: false, // allows us to draw another geo filter area which is intersecting with an exisiting geo filter area
    });
  }

  clearPolygonLayer(map, layerId) {
    if (map) {
      const feature = map.data.getFeatureById(layerId);
      if (feature) {
        map.data.remove(feature);
      }
    }
  }
}
