export enum StoreSourcesEnum {
  PLANNING_MAP = 'planning-map',
  TRIP_PLANNING_GRID = 'trip-planning-grid',
  UNASSIGNED_DELIVERY_GRID = 'unassigned-delivery-grid',
  UNASSIGNED_PICKUP_GRID = 'unassigned-pickup-grid',
  ASSIGNED_STOP_GRID = 'assigned-stop-grid',
  ROUTE_STOPS_GRID = 'route-stops-grid',
  ROUTE_BALANCING_BOARD = 'route-balancing-board',
  ASSIGN_TO_ROUTE_DIALOG = 'assign-to-route-dialog',
  PLANNING_ROUTE_SHIPMENTS_GRID = 'planning-route-shipments-grid',
  POLYGON_SELECTION = 'polygon-selection',
  REDUX_STORE = 'redux-store', // modified by an effect in the Store
}
