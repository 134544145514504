import { ActionLinkCellRendererComponent } from './action-link-cell-renderer/action-link-cell-renderer';
import { CircleCellRendererComponent } from './circle-cell-renderer/circle-cell-renderer.component';
import { DeliveryWindowTimeRendererComponent } from './delivery-window-time-renderer/delivery-window-time-renderer.component';
import { ReleaseRouteToggleCellRendererComponent } from './release-route-toggle-cell-renderer';
import { RouteActionLinkCellRendererComponent } from './route-action-link-cell-renderer/route-action-link-cell-renderer';
import { RouteTimelineCellRendererComponent } from './route-timeline-cell-renderer/route-timeline-cell-renderer';
import { RouteTimelineHeaderRendererComponent } from './route-timeline-header-renderer/route-timeline-header-renderer.component';
import { DriverCollectIconComponent } from './service-icons/driver-collect-icon.component';
import { GuaranteedIconComponent } from './service-icons/guaranteed-icon.component';
import { HazmatIconComponent } from './service-icons/hazmat-icon.component';
import { LiftGateIconComponent } from './service-icons/lift-gate-icon.component';
import { SpecialServicesCellRendererComponent } from './special-services-cell-renderer/special-services-cell-renderer.component';
import { StatusChipComponent } from './status-chip/status-chip.component';
import { TotalTextCellRendererComponent } from './total-text-cell-renderer/total-text-cell-renderer.component';

/**
 * Map all of the Angular CellRendererFramework components so they can be referenced by name
 * in column definitions.
 *
 * NOTE: WE MUST DO THIS in order for grid transposing to work!
 */
export const pndFrameworkComponents = {
  actionLinkCellRenderer: ActionLinkCellRendererComponent,
  routeActionLinkCellRenderer: RouteActionLinkCellRendererComponent,
  routeTimelineCellRenderer: RouteTimelineCellRendererComponent,
  routeTimelineHeaderRenderer: RouteTimelineHeaderRendererComponent,
  guaranteedIconCellRenderer: GuaranteedIconComponent,
  hazmatIconCellRenderer: HazmatIconComponent,
  specialServicesCellRenderer: SpecialServicesCellRendererComponent,
  statusChipCellRenderer: StatusChipComponent,
  deliverWindowTimeCellRenderer: DeliveryWindowTimeRendererComponent,
  driverCollectIconCellRenderer: DriverCollectIconComponent,
  liftGateIconCellRenderer: LiftGateIconComponent,
  circleCellRenderer: CircleCellRendererComponent,
  releaseRouteToggleCellRenderer: ReleaseRouteToggleCellRendererComponent,
  totalTextCellRenderer: TotalTextCellRendererComponent,
};

/**
 * List of Angular components that can be used as CellRenderers and CellEditors.
 * used by AgGridModule.withComponents()
 */
export const pndFrameworkComponentsDeclarations = Object.values(pndFrameworkComponents);

// NOTE: For AOT builds to work, you must also add these components to
// the entryComponents in the module!
