import { Component } from '@angular/core';

@Component({
  selector: 'pnd-center-sic-button',
  templateUrl: './center-map-button.component.html',
  styleUrls: ['./center-map-button.component.scss'],
})
export class CenterSicButton {
  constructor() {}
}
