import { LatLong } from '@xpo-ltl/sdk-common';
import { compact as _compact, cloneDeep as _cloneDeep } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ClusterableMarker } from '../../../shared/models/markers/clusterable-marker';
import { InteractiveMapMarker } from '../../../shared/models/markers/map-marker';
import { MapMarkerClusterer } from '../../../shared/models/markers/map-marker-clusterer.model';
import { MapMarkerIcon } from '../../../shared/models/markers/map-marker-icon.model';
import { AbstractMarkerLayer } from './abstract-marker-layer';

export interface OverlappedMarkers<
  CLUSTERER_MARKER_TYPE extends MapMarkerClusterer,
  MARKER_TYPE extends InteractiveMapMarker & ClusterableMarker
> {
  clusterer: CLUSTERER_MARKER_TYPE;
  markers: MARKER_TYPE[];
}

export interface ProcessedMarkers<
  MARKER_TYPE extends InteractiveMapMarker & ClusterableMarker,
  CLUSTERER_MARKER_TYPE extends MapMarkerClusterer
> {
  singles: MARKER_TYPE[];
  overlapped: OverlappedMarkers<CLUSTERER_MARKER_TYPE, MARKER_TYPE>[];
}

export abstract class AbstractClusteredMarkerLayer<
  MARKER_TYPE extends InteractiveMapMarker & ClusterableMarker,
  CLUSTERER_MARKER_TYPE extends MapMarkerClusterer
> extends AbstractMarkerLayer<MARKER_TYPE> {
  readonly markerOverlapThreshold: number = 40;
  readonly clustererMarkerTemplate: CLUSTERER_MARKER_TYPE;
  readonly clustererMarkerPrefix: string;
  clusteringEnabled: boolean = false;

  protected markerClustersSubject = new BehaviorSubject<OverlappedMarkers<CLUSTERER_MARKER_TYPE, MARKER_TYPE>[]>([]);
  readonly markerClusters$ = this.markerClustersSubject.asObservable();
  get markerClusters() {
    return this.markerClustersSubject.value;
  }

  protected constructor(clustererMarkerTemplate: CLUSTERER_MARKER_TYPE, clustererMarkerPrefix: string) {
    super();

    this.clustererMarkerTemplate = clustererMarkerTemplate;
    this.clustererMarkerPrefix = clustererMarkerPrefix;
  }

  /**
   * Function to be used when the used clicks the cluster marker, typically to show overlapped markers
   * @param marker Marker clicked
   */
  abstract onClusterMarkerClick(marker: CLUSTERER_MARKER_TYPE): void;

  /**
   * Returns the icon of the cluster marker
   * @param markerType Text to be show in the clusterer marker
   * @param clusteredMarkers Number of overlapped markers
   */
  abstract getClusterMarkerIcon(markerType: string, clusteredMarkers: number): MapMarkerIcon;

  /**
   * Compares the distance in meters between markers against a threshold
   * @param markerA First marker to compare
   * @param markerB Second marker to compare
   * @param threshold Optional threshold
   */
  private isMarkerOverlapped(
    markerA: MARKER_TYPE,
    markerB: MARKER_TYPE,
    threshold: number = this.markerOverlapThreshold
  ): boolean {
    return (
      google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(markerA.latitude, markerA.longitude),
        new google.maps.LatLng(markerB.latitude, markerB.longitude)
      ) < threshold
    );
  }

  /**
   * Calculates the virtual position (anchor/offset) of each marker and info window
   * @param markers Overlapped markers
   * @param markerSize Marker square size
   */
  calculateVirtualPositionForClusteredMarkers(markers: MARKER_TYPE[], markerSize: number): void {
    const clustererMarkerSize = 40; // TODO: This should come from the clusterer marker class
    const radiusSpacingMultiplier = 0.8;
    const radius = markerSize * radiusSpacingMultiplier;

    for (let i = 0; i < markers.length; i++) {
      const angle = (i / (markers.length / 2)) * Math.PI;

      markers[i].icon.anchor = new google.maps.Point(
        radius * Math.cos(angle) + markerSize / 2,
        radius * Math.sin(angle) + markerSize / 2
      );

      markers[i].infoWindowTopOffset = markers[i].icon.anchor.y * -1 + markers[i].infoWindowSpaceBetweenMarker * -1;
      markers[i].infoWindowLeftOffset = markers[i].icon.anchor.x * -1 + clustererMarkerSize / 2;
    }
  }

  /**
   * Returns an object of processed markers to be shown on the map
   * @param markers Raw markers
   * @param markerSize Marker square size
   */
  processMarkers(markers: MARKER_TYPE[], markerSize: number): ProcessedMarkers<MARKER_TYPE, CLUSTERER_MARKER_TYPE> {
    const processedMarkers: ProcessedMarkers<MARKER_TYPE, CLUSTERER_MARKER_TYPE> = {
      singles: [],
      overlapped: [],
    };
    for (let i = markers.length - 1; i >= 0; i--) {
      const overlappedMarkers: MARKER_TYPE[] = [];

      for (let j = markers.length - 1; j >= 0; j--) {
        if (markers[i] && markers[j] && i !== j) {
          if (this.isMarkerOverlapped(markers[i], markers[j])) {
            overlappedMarkers.push(markers.slice(j, j + 1)[0]);
            markers[j] = null;
          }
        }
      }

      if (markers[i] && overlappedMarkers.length > 0) {
        const clustererMarker = _cloneDeep(this.clustererMarkerTemplate);
        const virtualClusterPosition: LatLong = { latitude: 0, longitude: 0 };

        overlappedMarkers.push(markers.slice(i, i + 1)[0]);
        markers[i] = null;

        for (const overlappedMarker of overlappedMarkers) {
          virtualClusterPosition.latitude += overlappedMarker.latitude;
          virtualClusterPosition.longitude += overlappedMarker.longitude;
        }

        clustererMarker.latitude = virtualClusterPosition.latitude / overlappedMarkers.length;
        clustererMarker.longitude = virtualClusterPosition.longitude / overlappedMarkers.length;
        clustererMarker.clusterMarkerId = `${clustererMarker.latitude}#${clustererMarker.longitude}`;
        clustererMarker.icon = this.getClusterMarkerIcon(this.clustererMarkerPrefix, overlappedMarkers.length);
        clustererMarker.isSelected = false;
        clustererMarker.isVisible = true;

        this.calculateVirtualPositionForClusteredMarkers(overlappedMarkers, markerSize);

        processedMarkers.overlapped.push({
          clusterer: clustererMarker,
          markers: overlappedMarkers,
        });
      }
    }

    processedMarkers.singles = _compact(markers);

    return processedMarkers;
  }
}
