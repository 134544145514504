import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { XpoLtlDmsUtilsRoutingModule, BrowserGuard, XpoLtlEnvironmentAndRoleGuard } from '@xpo/ngx-ltl';
import { XpoBrowserGuardModule } from '@xpo/ngx-ltl';
import { ApplicationMinimumVersionGuard } from '../core/guards';
import { VersionMismatchGuard } from '../core/guards/version/version-mismatch/version-mismatch.guard';
import { environment } from '../environments/environment';
import { InboundPlanningComponent } from './inbound-planning/inbound-planning.component';

// Skip SSO when running in Cypress test environment
const skipCanActivate = environment.cypress;

const RouterDefinitions: Routes = [
  {
    path: 'version-mismatch',
    canActivate: skipCanActivate ? [BrowserGuard] : [BrowserGuard, XpoAuthenticationGuard, VersionMismatchGuard],
    loadChildren: () => import('../app/version-mismatch/version-mismatch.module').then((m) => m.VersionMismatchModule),
  },
  {
    path: '',
    pathMatch: 'full',
    canActivate: skipCanActivate
      ? [BrowserGuard]
      : [BrowserGuard, XpoAuthenticationGuard, ApplicationMinimumVersionGuard, XpoLtlEnvironmentAndRoleGuard],
    component: InboundPlanningComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    XpoBrowserGuardModule,
    RouterModule.forRoot(RouterDefinitions),
    XpoLtlDmsUtilsRoutingModule, // adds the /docview/:docCode/:timestamp route to display documents in a new tab
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
