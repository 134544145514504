export const StateKey = 'globalFilterState';

export interface State {
  readonly sic: string;
  readonly sicLatLng: { latitude: number; longitude: number };
  readonly planDate: Date;
}

export const initialState: State = {
  sic: '',
  sicLatLng: undefined,
  planDate: new Date(),
};
