import * as moment from 'moment-timezone';

export class PndDateUtils {
  static SERVER_TIME_ZONE = 'America/Los_Angeles';

  /**
   * Date from input type date is UTC based. The UTC date is the date we want
   * in PST/PSD ( SERVER_TIME_ZONE ) in the payload. For passing time please
   * set the desired time in the UTCHours, UTCMinutes, UTCSeconds.
   * @param date
   * @returns {Date}
   */
  static conditionTimeToServer(date: Date): Date {
    if (!date) {
      return date;
    }

    const offset = moment(date)
      .tz(PndDateUtils.SERVER_TIME_ZONE)
      .utcOffset();
    return new Date(date.getTime() - offset * 60000);
  }
}
