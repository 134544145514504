import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, StateKey } from './trips-store.state';

export const tripsState = createFeatureSelector<State>(StateKey);

export const searchCriteria = createSelector(tripsState, (state: State) => state.searchCriteria);

export const trips = createSelector(tripsState, (state: State) => state.trips);

export const selectedTrips = createSelector(tripsState, (state: State) => state.selectedTrips);

export const transactionTimestampUTC = createSelector(tripsState, (state: State) => state.transactionTimestampUTC);
