import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { select, Store } from '@ngrx/store';
import {
  Activity,
  CityOperationsApiService,
  InterfaceAcct,
  Route,
  Stop,
  Trip,
  TripNode,
  UnassignStopsRqst,
} from '@xpo-ltl/sdk-cityoperations';
import { RouteStatusCd, TripNodeStatusCd } from '@xpo-ltl/sdk-common';
import { XpoLtlShipmentDescriptor } from '@xpo/ngx-ltl';
import {
  filter as _filter,
  find as _find,
  first as _first,
  forEach as _forEach,
  forOwn as _forOwn,
  get as _get,
  groupBy as _groupBy,
  has as _has,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  map as _map,
  maxBy as _maxBy,
  set as _set,
  size as _size,
  sortBy as _sortBy,
  toNumber as _toNumber,
  some as _some,
  difference as _difference,
} from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, pairwise, startWith, take, takeUntil, withLatestFrom, skip, filter } from 'rxjs/operators';
import { PndDialogService } from '../../../../../../core/dialogs/pnd-dialog.service';
import { NotificationMessageStatus } from '../../../../../../core/enums/notification-message-status.enum';
import { NotificationMessageService } from '../../../../../../core/services/notification-message.service';
import { PndStoreState, RoutesStoreActions, RoutesStoreSelectors, TripsStoreActions } from '../../../../../store';
import { GeoLocationStoreActions } from '../../../../../store/geo-location-store';
import { NumberToValueMap } from '../../../../../store/number-to-value-map';
import { RouteBalancingActions, RouteBalancingSelectors } from '../../../../../store/route-balancing-store';
import { ActivityTypePipe, MapMarkerInfo } from '../../../../shared';
import { AssignToRouteDialogData } from '../../../../shared/components/assign-to-route/assign-to-route-dialog-data';
import { AssignToRouteComponent } from '../../../../shared/components/assign-to-route/assign-to-route.component';
import { ExistingOrNewRoute } from '../../../../shared/components/assign-to-route/existing-or-new-route.enum';
import { StopMapComponent } from '../../../../shared/components/stop-map/stop-map.component';
import { UnmappedStopDetail } from '../../../../shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stop-detail.model';
import { UnmappedStopsEditMode } from '../../../../shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stops-edit-mode.enum';
import { StoreSourcesEnum } from '../../../../shared/enums/store-sources.enum';
import { AssignedStopIdentifier, EventItem } from '../../../../shared/interfaces/event-item.interface';
import { AssignedStopMapMarkerCluster } from '../../../../shared/models/markers/assigned-stop-map-marker-cluster.model';
import { AssignedStopMapMarker } from '../../../../shared/models/markers/assigned-stop-map-marker.model';
import { InteractiveMapMarker } from '../../../../shared/models/markers/map-marker';
import { MapMarkerIcon } from '../../../../shared/models/markers/map-marker-icon.model';
import { MapMarkersService } from '../../../../shared/services/map-markers.service';
import { RouteColorService } from '../../../../shared/services/route-color.service';
import { SpecialServicesService } from '../../../../shared/services/special-services.service';
import { StopWindowService } from '../../../../shared/services/stop-window.service';
import { ResequencingRouteData } from '../../../route-balancing';
import { StopCard } from '../../../route-balancing/classes/stop-card.model';
import {
  ContextMenuItem,
  MarkerContextMenuComponent,
} from '../../components/marker-context-menu/marker-context-menu.component';
import { AbstractClusteredMarkerLayer, OverlappedMarkers, ProcessedMarkers } from '../abstract-clustered-marker-layer';

export enum ContextMenuItemId {
  Colors,
  PinAsFirst,
  PinAsLast,
  UnassignStop,
  ReassignStop,
  ShowDetails,
  Zoom,
  EditGeoLocation,
}

export interface StopsForRoutesState {
  routes: Route[];
  stopsForRoutes: NumberToValueMap<Stop[]>;
}

export interface ResequencedRouteInfo {
  routes: { [routeInstId: number]: ResequencingRouteData };
  showCompleteStops: boolean;
}

// maps a Route to a set of Stop markers
interface MarkersForRoute {
  routeInstId: number;
  markersSubject: BehaviorSubject<AssignedStopMapMarker[]>;
  markers$: Observable<AssignedStopMapMarker[]>;
}

@Component({
  selector: 'app-assigned-stops-layer',
  templateUrl: './assigned-stops-layer.component.html',
  styleUrls: ['./assigned-stops-layer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignedStopsLayerComponent
  extends AbstractClusteredMarkerLayer<AssignedStopMapMarker, AssignedStopMapMarkerCluster>
  implements OnInit, OnDestroy {
  @ViewChild(MarkerContextMenuComponent, { static: false }) contextMenu: MarkerContextMenuComponent;

  // TODO - see if we can't use the base markers instead of having to keep our own list
  private markersForRoutesSubject = new BehaviorSubject<MarkersForRoute[]>([]);
  markersForRoutes$ = this.markersForRoutesSubject.asObservable();
  get markersForRoutes() {
    return this.markersForRoutesSubject.value;
  }

  private isRouteBalancerOpen: boolean = false;
  private lastBalancedRoutes: number[] = [];
  showCompletedStops: boolean;
  sequencingRoutes: number[];
  resequencedRouteData: ResequencedRouteInfo;
  processedMarkers: ProcessedMarkers<AssignedStopMapMarker, AssignedStopMapMarkerCluster> = {
    overlapped: [],
    singles: [],
  };

  stopsForRoutesState: StopsForRoutesState = {
    routes: [],
    stopsForRoutes: {},
  };

  contextMenuItems: ContextMenuItem<ContextMenuItemId>[] = [
    {
      id: ContextMenuItemId.PinAsFirst,
      label: 'Pin as First',
      shouldHide: (marker) => this.isClusteredMarker(marker) || !this.isRouteBalancerOpen || this.isRouteFixed(marker),
    },
    {
      id: ContextMenuItemId.PinAsLast,
      label: 'Pin as Last',
      shouldHide: (marker) => this.isClusteredMarker(marker) || !this.isRouteBalancerOpen || this.isRouteFixed(marker),
    },
    {
      id: ContextMenuItemId.UnassignStop,
      label: 'Unassign Stop',
      shouldHide: (marker) => {
        return this.isRouteBalancerOpen || this.isClusteredMarker(marker) || this.isRouteFixed(marker);
      },
    },
    {
      id: ContextMenuItemId.ReassignStop,
      label: 'Reassign To',
      shouldHide: (marker) => this.isRouteBalancerOpen || this.isClusteredMarker(marker) || this.isRouteFixed(marker),
    },
    {
      id: ContextMenuItemId.ShowDetails,
      label: 'Shipment Details',
      shouldHide: (marker) => this.isClusteredMarker(marker),
    },
    { id: ContextMenuItemId.Zoom, label: 'Satellite View' },
    { id: ContextMenuItemId.EditGeoLocation, label: 'Edit Geo Location', shouldHide: this.isClusteredMarker },
    {
      id: ContextMenuItemId.Colors,
      label: 'Change Color',
      nested: true,
      shouldHide: (marker) => this.isClusteredMarker(marker) || this.isRouteFixed(marker),
    },
  ];

  constructor(
    private pndStore$: Store<PndStoreState.State>,
    private mapMarkerService: MapMarkersService,
    private routeColorService: RouteColorService,
    private stopWindowService: StopWindowService,
    private changeRef: ChangeDetectorRef,
    private pndDialogService: PndDialogService,
    private cityOperationsService: CityOperationsApiService,
    private dialog: MatDialog,
    private notificationMessageService: NotificationMessageService,
    private specialServicesService: SpecialServicesService,
    private activityTypePipe: ActivityTypePipe
  ) {
    super(new AssignedStopMapMarkerCluster(), 'DL');
    this.clusteringEnabled = true;
  }

  // returns true if the Marker's route is no longer modifiable
  private isRouteFixed(marker: AssignedStopMapMarker) {
    return !(
      marker.markerInfo.routeStatus === RouteStatusCd.UNRELEASED ||
      marker.markerInfo.routeStatus === RouteStatusCd.RELEASED ||
      marker.markerInfo.routeStatus === RouteStatusCd.NEW ||
      marker.markerInfo.routeStatus === RouteStatusCd.LOADING ||
      marker.markerInfo.routeStatus === RouteStatusCd.CLOSED
    );
  }

  private isClusteredMarker(
    marker: AssignedStopMapMarker | OverlappedMarkers<AssignedStopMapMarkerCluster, AssignedStopMapMarker>[]
  ): boolean {
    return !(marker instanceof AssignedStopMapMarker);
  }

  ngOnInit() {
    // react to selected stop changes
    this.updateSelectedStops(false);
    this.subscribeToChangeColor();
    this.subscribeToManualSequencing();

    // listen for focused stops changes
    this.pndStore$
      .select(RoutesStoreSelectors.focusedStopForSelectedRoute)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((focusedStop: EventItem<AssignedStopIdentifier>) => {
        this.updateMarkersFocusedState(focusedStop);
      });

    this.pndStore$
      .select(RoutesStoreSelectors.selectedStopsForSelectedRoutes)
      .pipe(debounceTime(500), takeUntil(this.unsubscriber.done))
      .subscribe((selectedStops: EventItem<AssignedStopIdentifier>[]) => {
        this.updateMarkersSelectionState(selectedStops);
      });

    this.zoomLevel$
      .pipe(startWith(-1), pairwise(), debounceTime(500), takeUntil(this.unsubscriber.done))
      .subscribe(([lastZoom, zoom]) => {
        // update the markers when zoom level changes
        if (
          (lastZoom <= this.mapMarkerService.ZOOM_LEVEL && zoom >= this.mapMarkerService.ZOOM_LEVEL) ||
          (lastZoom >= this.mapMarkerService.ZOOM_LEVEL && zoom <= this.mapMarkerService.ZOOM_LEVEL)
        ) {
          _forEach(this.markersForRoutes, (markersForRoute) => {
            _forEach(markersForRoute.markersSubject.value, (marker) => {
              this.updateStopMarker(marker, zoom);
            });
          });

          this.markerClustersSubject.value.forEach((overlappedMarkers) => {
            // TODO: 18/38 should be defined in the marker class
            this.calculateVirtualPositionForClusteredMarkers(overlappedMarkers.markers, zoom < 7 ? 18 : 38);

            overlappedMarkers.markers.forEach((marker) => {
              this.updateStopMarker(marker, zoom, marker.icon.anchor);
            });
          });

          this.changeRef.detectChanges();
        }
      });

    // update visibility of completed legs
    this.pndStore$
      .select(RoutesStoreSelectors.showCompletedStops)
      .pipe(debounceTime(500), takeUntil(this.unsubscriber.done))
      .subscribe((showCompletedStops) => {
        this.showCompletedStops = showCompletedStops;
        _forEach(this.markersForRoutes, (markersForRoute) => {
          _forEach(markersForRoute.markersSubject.value, (marker) => {
            marker.isVisible = showCompletedStops || this.isStopActive(marker.stop);
            this.updateStopMarker(marker, this.zoomLevel);
          });
        });
        _forEach(
          this.markerClusters,
          (overlappedMarkers: OverlappedMarkers<AssignedStopMapMarkerCluster, AssignedStopMapMarker>) => {
            _forEach(overlappedMarkers.markers, (marker: AssignedStopMapMarker) => {
              marker.isVisible = showCompletedStops || this.isStopActive(marker.stop);
              this.updateStopMarker(marker, this.zoomLevel);
            });

            // Hide cluster if all the markers are not visible
            if (overlappedMarkers.markers.filter((marker) => marker.isVisible).length === 0) {
              overlappedMarkers.clusterer.isVisible = false;
            } else {
              overlappedMarkers.clusterer.isVisible = true;

              let processMarker = this.processMarkers(overlappedMarkers.markers, 38);

              this.markerClustersSubject.next(processMarker.overlapped);
            }
          }
        );

        this.changeRef.detectChanges();
      });

    this.subscribeToResequenceRouteData();

    this.pndStore$
      .select(RouteBalancingSelectors.openRouteBalancingPanel)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((isOpen) => {
        this.isRouteBalancerOpen = isOpen;
      });
  }

  subscribeToResequenceRouteData(): void {
    this.pndStore$
      .select(RoutesStoreSelectors.resequencedRouteData)
      .pipe(
        skip(1),
        withLatestFrom(this.pndStore$.select(RoutesStoreSelectors.showCompletedStops)),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(([resequencedRouteData, showCompletedStops]) => {
        this.resequencedRouteData = {
          routes: resequencedRouteData,
          showCompleteStops: showCompletedStops,
        };
        if (this.isRouteBalancerOpen) {
          this.resequenceRouteData();
        }
      });
  }

  resequenceRouteData(): void {
    const stopsForSelectedRoutes: NumberToValueMap<Stop[]> = {};
    const selectedRoutes: Route[] = [];

    Object.keys(this.resequencedRouteData.routes).forEach((routeInstId) => {
      const routeData: ResequencingRouteData = this.resequencedRouteData.routes[routeInstId];

      const route = {
        ...new Route(),
        routeInstId: +routeInstId,
        routeName: routeData.routeName,
        routePrefix: routeData.routePrefix,
        routeSuffix: routeData.routeSuffix,
        statusCd: routeData.routeStatusCd,
      };

      selectedRoutes.push(route);

      const resequencedStops = routeData.newResequencingStops.map((stopCard) => {
        const stop: Stop = {
          ...new Stop(),
          tripNode: {
            ...new TripNode(),
            tripNodeSequenceNbr: stopCard.tripNodeSequenceNbr,
            stopSequenceNbr: stopCard.seqNo,
            statusCd: stopCard.tripNodeStatusCd,
          },
          customer: stopCard.customer,
          activities: stopCard.activities,
          specialServicesSummary: stopCard.specialServicesSummary,
        };
        _set(stop, 'tripNode.originalStopSequenceNbr', stopCard.origSeqNo);
        return stop;
      });

      _set(stopsForSelectedRoutes, +routeInstId, [...resequencedStops]);
    });

    this.updateRoutes(selectedRoutes, stopsForSelectedRoutes, this.resequencedRouteData.showCompleteStops);

    this.pndStore$
      .select(RoutesStoreSelectors.selectedStopsForSelectedRoutes)
      .pipe(take(1))
      .subscribe((selectedStopsForSelectedRoutes: EventItem<AssignedStopIdentifier>[]) => {
        this.updateMarkersSelectionState(selectedStopsForSelectedRoutes);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  trackMarkerBy(index, item: AssignedStopMapMarker) {
    if (!item) {
      return null;
    }
    return `${item.routeInstId}-${item.origSeqNo}`;
  }

  trackClusterBy(index, item: OverlappedMarkers<AssignedStopMapMarkerCluster, AssignedStopMapMarker>) {
    if (!item) {
      return null;
    }
    return item.clusterer.clusterMarkerId;
  }

  /**
   * Returns True if the Stop is not completed
   */
  isStopActive(stop: Stop): boolean {
    return stop && _get(stop, 'tripNode.statusCd') !== TripNodeStatusCd.COMPLETED;
  }

  /**
   * Resize unassigned stop markers
   * @param mapMarker
   * @param zoomLevel
   * @param anchor
   */
  updateStopMarker(mapMarker: AssignedStopMapMarker, zoomLevel: number, anchor?: google.maps.Point) {
    mapMarker.updateIcon(zoomLevel, this.mapMarkerService, anchor);
  }

  /**
   * Open info window when marker is hovered.
   * @param marker
   */
  onMouseOver(marker: AssignedStopMapMarker): void {
    this.pndStore$.dispatch(
      new RoutesStoreActions.SetFocusedStopForSelectedRouteAction({
        focusedStopForSelectedRoute: {
          id: {
            routeInstId: marker.routeInstId,
            seqNo: marker.seqNo,
            origSeqNo: marker.origSeqNo,
          },
          source: StoreSourcesEnum.PLANNING_MAP,
        },
      })
    );
  }

  /**
   * Hide marker's tooltip when mouse out/blur
   * @param marker
   */
  onMouseOut(marker: AssignedStopMapMarker): void {
    this.pndStore$.dispatch(
      new RoutesStoreActions.SetFocusedStopForSelectedRouteAction({
        focusedStopForSelectedRoute: {
          id: undefined,
          source: StoreSourcesEnum.PLANNING_MAP,
        },
      })
    );
  }

  /**
   * Marker click
   * @param marker Related marker
   */
  onClick(marker: AssignedStopMapMarker): void {
    // tslint:disable-next-line
    const event = window.event as MouseEvent; // NO WAY to get to the MouseEvent otherwise! Darn you AgmMarker!!!

    // If shift pressed, should add/remove stop from the store
    if (event.shiftKey) {
      // add/remove the stop from the list of selected stops
      this.updateSelectedStopsList(marker);
    } else {
      this.resequenceStopList(marker);
    }
  }

  resequenceStopList(marker: AssignedStopMapMarker): void {
    if (this.sequencingRoutes.includes(marker.routeInstId)) {
      this.pndStore$
        .select(RoutesStoreSelectors.resequencedRouteData)
        .pipe(take(1))
        .subscribe((resequencedRouteData: { [routeInstId: number]: ResequencingRouteData }) => {
          const routeData = resequencedRouteData[marker.routeInstId];
          if (routeData) {
            if (routeData.pinnedStops && routeData.pinnedStops.last) {
              routeData.newResequencingStops = routeData.newResequencingStops.filter(
                (stop) => stop.origSeqNo !== routeData.pinnedStops.last.origSeqNo
              );
            }
            const maxStop = _maxBy(routeData.newResequencingStops, (stop) => stop.seqNo);
            const currentStop = routeData.newResequencingStops.find((stop) => stop.origSeqNo === marker.origSeqNo);
            if (currentStop && !currentStop.seqNo) {
              routeData.source = StoreSourcesEnum.PLANNING_MAP;
              if (maxStop) {
                currentStop.seqNo = maxStop.seqNo + 1;
              } else {
                currentStop.seqNo = 1;
              }
              if (routeData.pinnedStops && routeData.pinnedStops.last) {
                routeData.newResequencingStops.push(routeData.pinnedStops.last);
              }

              this.pndStore$.dispatch(
                new RoutesStoreActions.SetResequencedRouteData({ resequenceData: { ...resequencedRouteData } })
              );
            }
          }
        });
    }
  }

  updateSelectedStopsList(marker: AssignedStopMapMarker): void {
    this.pndStore$
      .pipe(select(RoutesStoreSelectors.selectedStopsForSelectedRoutes), take(1))
      .subscribe((items: EventItem<AssignedStopIdentifier>[]) => {
        // Preserve previous selection
        let selectedStopsForSelectedRoutes: EventItem<AssignedStopIdentifier>[] = [...items];
        if (!marker.isSelected) {
          const selectedStop: EventItem<AssignedStopIdentifier> = {
            id: {
              routeInstId: marker.routeInstId,
              seqNo: marker.seqNo,
              origSeqNo: marker.origSeqNo,
            },
            source: StoreSourcesEnum.PLANNING_MAP,
          };
          if (!_find(selectedStopsForSelectedRoutes, (item) => this.isMarkerForStop(marker, item))) {
            selectedStopsForSelectedRoutes.push(selectedStop);
          }
          marker.isSelected = true;
        } else {
          selectedStopsForSelectedRoutes = _filter(items, (stop) => !this.isMarkerForStop(marker, stop));
          marker.isSelected = false;
        }

        this.pndStore$.dispatch(
          new RoutesStoreActions.SetSelectedStopsForSelectedRoutesAction({
            selectedStopsForSelectedRoutes: selectedStopsForSelectedRoutes,
          })
        );
      });
  }

  onRightClick(marker: InteractiveMapMarker, event: MouseEvent) {
    this.contextMenu
      .openMenu(event.clientX, event.clientY, marker)
      .subscribe((item: ContextMenuItem<ContextMenuItemId>) => {
        this.handleContextMenuSelection(item, marker);
      });
  }

  handleContextMenuSelection(item: ContextMenuItem<ContextMenuItemId>, marker: InteractiveMapMarker): void {
    if (item) {
      switch (item.id) {
        case ContextMenuItemId.Colors:
          const selectedMarker = marker as AssignedStopMapMarker;
          this.routeColorService.changeRouteColor(selectedMarker.routeInstId, item.colorId);
          if (this.isRouteBalancerOpen) {
            this.resequenceRouteData();
          } else {
            this.updateSelectedStops(true);
          }
          break;
        case ContextMenuItemId.PinAsFirst:
          this.pinAsFirst(marker as AssignedStopMapMarker);
          break;

        case ContextMenuItemId.PinAsLast:
          this.pinAsLast(marker as AssignedStopMapMarker);
          break;

        case ContextMenuItemId.ShowDetails:
          this.showDetails(marker as AssignedStopMapMarker);
          break;

        case ContextMenuItemId.Zoom:
          this.showSatelliteView(marker as AssignedStopMapMarker);
          break;

        case ContextMenuItemId.ReassignStop:
          this.reassignStop(marker as AssignedStopMapMarker);
          break;

        case ContextMenuItemId.UnassignStop:
          this.unassignStop(marker as AssignedStopMapMarker);
          break;

        case ContextMenuItemId.EditGeoLocation:
          this.editGeoLocation(marker as AssignedStopMapMarker);
          break;
      }
    }
  }

  private editGeoLocation(marker: AssignedStopMapMarker) {
    const stopDetail = this.getStopDetailForGeoLocation(marker);
    this.pndStore$.dispatch(new GeoLocationStoreActions.SetEditMode(UnmappedStopsEditMode.AssignedStop));
    this.pndStore$.dispatch(new GeoLocationStoreActions.SetStopToEdit(stopDetail));
  }

  getStopDetailForGeoLocation(marker: AssignedStopMapMarker) {
    const markerInfo: MapMarkerInfo = marker.markerInfo;
    const customer: InterfaceAcct = marker.stop.customer;

    const activityCds = this.mapMarkerService.activityCdsForActivities(marker.stop.activities);
    const stopTypeCd = _first(activityCds); // TODO - this doesn't support MX stops
    const stopDetail: UnmappedStopDetail = {
      acctInstId: customer.acctInstId,
      stopName: customer.name1,
      stopTypeCd,
      address: `${customer.addressLine1}, ${customer.cityName}, ${customer.stateCd} ${customer.postalCd}`,
      location: new google.maps.LatLng(customer.geoCoordinates.latitude, customer.geoCoordinates.longitude),
      isFutureCustomer: _isEmpty(customer.acctMadCd),
    };

    return stopDetail;
  }

  showDetails(marker: AssignedStopMapMarker) {
    const activities = _filter(marker.stop.activities, (activity: Activity) => _has(activity, 'routeShipment'));
    const shipmentsAtStop = _map(activities, (activity: Activity) => {
      return {
        proNbr: activity.routeShipment.proNbr,
        shipmentInstId: activity.routeShipment.shipmentInstId,
      } as XpoLtlShipmentDescriptor;
    });
    this.pndDialogService.showShipmentDetailsDialog(shipmentsAtStop).subscribe();
  }

  showSatelliteView(marker: AssignedStopMapMarker) {
    if (marker) {
      const consigneeName = marker.stop.customer.name1;

      this.dialog.open(StopMapComponent, {
        data: {
          consigneeName,
          geoCoordinates: { latitude: marker.latitude, longitude: marker.longitude },
        },
        disableClose: true,
        hasBackdrop: false,
      });
    }
  }

  reassignStop(marker: AssignedStopMapMarker) {
    this.pndStore$
      .select(RoutesStoreSelectors.stopsForSelectedRoutes)
      .pipe(take(1))
      .subscribe((selectedStopsForSelectedRoutes) => {
        const selectedStops = _get(selectedStopsForSelectedRoutes, marker.routeInstId) || [];
        const selectedStop = selectedStops.find(
          (stop) => stop.tripNode.tripNodeSequenceNbr === marker.tripNodeSequenceNbr
        );

        this.dialog.open(AssignToRouteComponent, {
          data: <AssignToRouteDialogData>{
            initialMode: ExistingOrNewRoute.Existing,
            selectedStops: [selectedStop],
          },
          disableClose: true,
          hasBackdrop: true,
        });
      });
  }

  unassignStop(marker: AssignedStopMapMarker) {
    this.pndStore$.dispatch(
      new RouteBalancingActions.SetCanOpenRouteBalancing({
        canOpenRouteBalancing: false,
      })
    );

    const request = new UnassignStopsRqst();
    request.trip = { ...new Trip(), tripInstId: marker.tripInstId };
    request.tripNodes = [{ ...new TripNode(), tripNodeSequenceNbr: marker.tripNodeSequenceNbr }];
    this.cityOperationsService.unassignStops(request).subscribe(
      () => {
        this.updateUnassignedStop(marker);
      },
      (error) => {
        this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Error, error)
          .subscribe(() => {});

        this.pndStore$.dispatch(
          new RouteBalancingActions.SetCanOpenRouteBalancing({
            canOpenRouteBalancing: true,
          })
        );
      }
    );
  }

  updateUnassignedStop(marker: AssignedStopMapMarker) {
    this.pndStore$.dispatch(
      new RoutesStoreActions.UpdateStopsForSelectedRouteAction({
        route: { ...new Route(), routeInstId: marker.routeInstId },
      })
    );

    this.notificationMessageService
      .openNotificationMessage(NotificationMessageStatus.Success, `Route ${marker.routeName} updated.`)
      .subscribe(() => {});

    this.updateTripsGrid();
    this.updatePlanningRoutesGrid();
    this.updateUnassignedDeliveriesGrid();
  }

  pinAsLast(marker: AssignedStopMapMarker) {
    const pinnedStop = this.createPinnedStop(marker);

    this.pndStore$.dispatch(
      new RouteBalancingActions.SetPinLast({
        pinLast: pinnedStop,
      })
    );
  }

  pinAsFirst(marker: AssignedStopMapMarker) {
    const pinnedStop = this.createPinnedStop(marker);

    this.pndStore$.dispatch(
      new RouteBalancingActions.SetPinFirst({
        pinFirst: pinnedStop,
      })
    );
  }

  createPinnedStop(marker: AssignedStopMapMarker) {
    const pinnedStop = new StopCard();
    pinnedStop.routeInstId = marker.routeInstId;
    pinnedStop.origSeqNo = marker.origSeqNo;
    return pinnedStop;
  }

  subscribeToManualSequencing(): void {
    this.pndStore$.select(RouteBalancingSelectors.manualSequencingRoutes).subscribe((sequencingRoutes: number[]) => {
      this.sequencingRoutes = sequencingRoutes;
    });
  }

  getValidStops(stops: Stop[]): Stop[] {
    return _sortBy(
      _filter(stops, (stop: Stop) => {
        const latLng = {
          lat: _get(stop, 'customer.latitudeNbr', 0),
          lng: _get(stop, 'customer.longitudeNbr', 0),
        };
        return !_isEqual(latLng, { lat: 0, lng: 0 });
      }),
      [(stop) => stop.tripNode.stopSequenceNbr]
    );
  }

  createRawMarkers(
    routes: Route[],
    stopsForRoutes: NumberToValueMap<Stop[]>,
    showCompletedStops: boolean
  ): AssignedStopMapMarker[] {
    let rawMarkers: AssignedStopMapMarker[] = [];
    _forOwn(stopsForRoutes, (stops, routeInstId) => {
      const route = _find(routes, (r) => r.routeInstId === +routeInstId);

      if (route) {
        const routeColor = this.routeColorService.getColorForRoute(route.routeInstId);

        // filter out stops that are invalid (no customer or no lat/lng), then
        // sort the stops by their sequenceNbr.
        const validStops = this.getValidStops(stops);

        rawMarkers = rawMarkers.concat(
          _map(validStops, (stop) => {
            return new AssignedStopMapMarker(
              route,
              stop,
              routeColor,
              this.mapMarkerService,
              this.stopWindowService,
              this.specialServicesService,
              showCompletedStops || this.isStopActive(stop),
              this.activityTypePipe
            );
          })
        );
      }
    });
    return rawMarkers;
  }

  updateMarkersForRouteStops(notZoom?: boolean): void {
    let bounds: google.maps.LatLngBounds;
    const markersForRouteStops: MarkersForRoute[] = [];
    const markersGroupedByRoute = _groupBy(this.processedMarkers.singles, 'routeInstId');

    for (const [routeInstId, markers] of Object.entries(markersGroupedByRoute)) {
      const markersSubject = new BehaviorSubject<AssignedStopMapMarker[]>(markers);

      const markersForRoute = {
        routeInstId: _toNumber(routeInstId),
        markersSubject,
        markers$: markersSubject.asObservable(),
      };

      markersForRouteStops.push(markersForRoute);

      _forEach(markers, (marker) => {
        if (!bounds) {
          bounds = new google.maps.LatLngBounds();
        }

        bounds.extend({ lat: marker.latitude, lng: marker.longitude });
      });
    }

    // BE SURE to unsubscribe any observables from the previous Markers lists
    _forEach(this.markersForRoutesSubject.value, (markers: MarkersForRoute) => markers.markersSubject.unsubscribe());
    this.markersForRoutesSubject.next(markersForRouteStops);

    // Map panning only when Route Balancing is not open or a new route was added to it
    if (this.googleMap) {
      this.checkOpenRouteBalancing(bounds, notZoom);
    }
  }

  private stopToMarkerComparator(stop, marker: AssignedStopMapMarker): boolean {
    return (
      _get(stop, 'tripNode.tripNodeSequenceNbr') === _get(marker, 'stop.tripNode.tripNodeSequenceNbr') &&
      _get(stop, 'customer.acctInstId') === _get(marker, 'stop.customer.acctInstId') &&
      _get(stop, 'customer.name1') === _get(marker, 'stop.customer.name1')
    );
  }

  private isEqual(markerA: AssignedStopMapMarker, markerB: AssignedStopMapMarker): boolean {
    return (
      markerA.routeInstId === markerB.routeInstId &&
      markerA.stop.customer.acctInstId === markerB.stop.customer.acctInstId &&
      markerA.stop.customer.name1 === markerB.stop.customer.name1
    );
  }

  private cleanProcessedSingleMarkers(
    stopsForRoutes: NumberToValueMap<Stop[]>,
    newProcessedMarkers: ProcessedMarkers<AssignedStopMapMarker, AssignedStopMapMarkerCluster>
  ) {
    if (this.processedMarkers.singles.length > 0) {
      this.processedMarkers.singles = this.processedMarkers.singles.filter((marker) => {
        if (
          stopsForRoutes[marker.routeInstId] &&
          _find(stopsForRoutes[marker.routeInstId], (stop) => this.stopToMarkerComparator(stop, marker)) &&
          !_find(newProcessedMarkers.singles, (changedMarker) => this.isEqual(changedMarker, marker))
        ) {
          return marker;
        }
      });
    }
  }

  private cleanProcessedOverlappedMarkers(stopsForRoutes: NumberToValueMap<Stop[]>) {
    if (this.processedMarkers.overlapped.length > 0) {
      this.processedMarkers.overlapped = this.processedMarkers.overlapped.filter((overlapped) => {
        const routeInstId = overlapped.markers[0].routeInstId;
        if (stopsForRoutes[routeInstId]) {
          const originalLenght = overlapped.markers.length;
          overlapped.markers = overlapped.markers.filter((marker) =>
            _find(stopsForRoutes[routeInstId], (stop) => this.stopToMarkerComparator(stop, marker))
          );
          if (overlapped.markers.length === 1) {
            this.processedMarkers.singles.push(overlapped.markers[0]);
          } else if (overlapped.markers.length > 1) {
            if (overlapped.markers.length === originalLenght) {
              return overlapped;
            }
            overlapped.clusterer.icon = this.getClusterMarkerIcon('DL', overlapped.markers.length);
            return overlapped;
          }
        }
      });
    }
  }

  private cleanProcessedMarkers(
    stopsForRoutes: NumberToValueMap<Stop[]>,
    newProcessedMarkers: ProcessedMarkers<AssignedStopMapMarker, AssignedStopMapMarkerCluster>
  ) {
    this.cleanProcessedOverlappedMarkers(stopsForRoutes);
    this.cleanProcessedSingleMarkers(stopsForRoutes, newProcessedMarkers);
  }

  findChangesInStops(
    stopsForRoutes: NumberToValueMap<Stop[]>,
    routes: Route[]
  ): { stops: NumberToValueMap<Stop[]>; routes: Route[] } {
    const changes = {
      stops: {},
      routes: [],
    };
    _forOwn(stopsForRoutes, (stops: Stop[], routeInstId: string) => {
      stops.forEach((stop: Stop) => {
        if (this.stopsForRoutesState.stopsForRoutes[routeInstId]) {
          const savedStop = this.stopsForRoutesState.stopsForRoutes[routeInstId].find(
            (oldStop) => oldStop.tripNode.tripNodeSequenceNbr === stop.tripNode.tripNodeSequenceNbr
          );
          if (
            !savedStop ||
            _get(savedStop, 'tripNode.stopSequenceNbr') !== _get(stop, 'tripNode.stopSequenceNbr') ||
            _get(savedStop, 'tripNode.originalStopSequenceNbr') !== _get(stop, 'tripNode.originalStopSequenceNbr')
          ) {
            if (changes.stops[routeInstId]) {
              changes.stops[routeInstId].push(stop);
            } else {
              changes.stops[routeInstId] = [stop];
            }
          }
        }
      });
    });
    changes.routes = routes.filter((route) => changes.stops[route.routeInstId]);
    return changes;
  }

  checkForNewRoutes(route: Route): boolean {
    const color = this.routeColorService.getColorForRoute(route.routeInstId);
    const exist = _find(this.stopsForRoutesState.routes, (oldRoute) => oldRoute.routeInstId === route.routeInstId);
    const colorChanged = exist
      ? _filter(
          this.processedMarkers.singles,
          (marker) => marker.routeInstId === route.routeInstId && marker.color !== color
        ).length > 0
      : false;
    return !exist || colorChanged;
  }

  private handleStopForRoutesState(
    routes: Route[],
    stopsForRoutes: NumberToValueMap<Stop[]>,
    showCompletedStops: boolean
  ): ProcessedMarkers<AssignedStopMapMarker, AssignedStopMapMarkerCluster> {
    const changes = this.findChangesInStops(stopsForRoutes, routes);
    let newAddRoutes = [];
    newAddRoutes = routes.filter((route: Route) => {
      if (this.checkForNewRoutes(route)) {
        changes.stops[route.routeInstId] = stopsForRoutes[route.routeInstId];
        return route;
      }
    });
    this.stopsForRoutesState.routes = routes;
    this.stopsForRoutesState.stopsForRoutes = stopsForRoutes;
    return this.processMarkers(
      this.createRawMarkers([...newAddRoutes, ...changes.routes], changes.stops, showCompletedStops),
      38
    );
  }

  /**
   * Updates all markers to reflect the list of routes and stops
   * @param routes
   * @param stopsForRoutes
   */
  updateRoutes(
    routes: Route[],
    stopsForRoutes: NumberToValueMap<Stop[]>,
    showCompletedStops: boolean,
    notZoom?: boolean
  ) {
    const addedProcessedMarkers = this.handleStopForRoutesState(routes, stopsForRoutes, showCompletedStops);
    this.cleanProcessedMarkers(stopsForRoutes, addedProcessedMarkers);
    this.processedMarkers = {
      singles: [...this.processedMarkers.singles, ...addedProcessedMarkers.singles],
      overlapped: [...this.processedMarkers.overlapped, ...addedProcessedMarkers.overlapped],
    };

    if (this.isRouteBalancerOpen) {
      this.pndStore$
        .select(RouteBalancingSelectors.manualSequencingRoutes)
        .pipe(take(1))
        .subscribe((sequencingRoutes: number[]) => {
          let originalClusters: OverlappedMarkers<AssignedStopMapMarkerCluster, AssignedStopMapMarker>[] = [
            ...this.markerClustersSubject.value,
          ];

          if (originalClusters.length === 0) {
            originalClusters = this.processedMarkers.overlapped;
          } else {
            _forEach(this.processedMarkers.overlapped, (cluster) => {
              const originalClusterIndex = originalClusters.findIndex((origCl) => {
                return origCl.clusterer.clusterMarkerId === cluster.clusterer.clusterMarkerId;
              });
              const originalCluster = originalClusters[originalClusterIndex];

              if (originalCluster) {
                if (_some(cluster.markers, (marker) => sequencingRoutes.includes(marker.routeInstId))) {
                  originalCluster.clusterer.isSelected = true;

                  cluster.markers.forEach((currentMarker) => {
                    const originalMarkerIndex: number = originalCluster.markers.findIndex(
                      (m) => m.routeInstId === currentMarker.routeInstId && m.origSeqNo === currentMarker.origSeqNo
                    );
                    const originalMarker = originalCluster.markers[originalMarkerIndex];

                    if (this.markerHasChanged(originalMarker, currentMarker)) {
                      originalCluster.markers[originalMarkerIndex] = currentMarker;
                    }
                  });

                  this.calculateVirtualPositionForClusteredMarkers(originalCluster.markers, 38);
                } else {
                  originalClusters[originalClusterIndex] = cluster;
                }
              }
            });
          }

          this.markerClustersSubject.next(originalClusters);
        });
    } else {
      this.markerClustersSubject.next(this.processedMarkers.overlapped);
    }

    this.updateMarkersForRouteStops(notZoom);
  }

  markerHasChanged(original: AssignedStopMapMarker, current: AssignedStopMapMarker): boolean {
    return original.seqNo !== current.seqNo;
  }

  checkOpenRouteBalancing(bounds: google.maps.LatLngBounds, notZoom: boolean) {
    this.pndStore$
      .select(RouteBalancingSelectors.openRouteBalancingPanel)
      .pipe(take(1))
      .subscribe((open) => {
        const shouldCenter = !notZoom;

        if (open) {
          this.setLastBalancedRoutes();
        }

        if (shouldCenter && _size(this.sequencingRoutes) === 0) {
          this.centerZoomMap(bounds);
        }
      });
  }

  setLastBalancedRoutes() {
    this.pndStore$
      .select(RoutesStoreSelectors.resequencedRouteData)
      .pipe(take(1))
      .subscribe((resequecingRouteData) => {
        const routeInstIds = Object.keys(resequecingRouteData).map((key) => +key);
        this.lastBalancedRoutes = routeInstIds;
      });
  }

  /**
   * Tries to center the map given lat lng bounds
   * @param bounds
   */
  centerZoomMap(bounds: google.maps.LatLngBounds) {
    if (bounds) {
      if (
        _size(this.markersForRoutesSubject.value) === 1 &&
        _size(this.markersForRoutesSubject.value[0].markersSubject.value) === 1
      ) {
        // set zoom manually if there is only one stop
        this.googleMap.setCenter(bounds.getCenter());
        this.googleMap.setZoom(17);
      } else {
        // if there are any stops, fit map to show them all and center
        this.googleMap.fitBounds(bounds, 0);
        this.googleMap.setCenter(bounds.getCenter());
      }
    }
  }

  /**
   * Returns true if the marker is for the passed stop
   */
  private isMarkerForStop(marker: AssignedStopMapMarker, stop: EventItem<AssignedStopIdentifier>): boolean {
    return !!(
      stop &&
      stop.id &&
      marker &&
      marker.routeInstId === stop.id.routeInstId &&
      ((marker.seqNo && marker.seqNo === stop.id.seqNo) || (marker.origSeqNo && marker.origSeqNo === stop.id.origSeqNo))
    );
  }

  /**
   * Sets the focused state for all markers so that only the
   * passed focusedStop is set to focused.
   */
  private updateMarkersFocusedState(item: EventItem<AssignedStopIdentifier>) {
    const markerClusters = this.markerClustersSubject.value;
    let clusterOfFocusedMarker: AssignedStopMapMarkerCluster;

    // set focused state for all markers
    _forEach(this.markersForRoutes, (markersForRoute) => {
      _forEach(markersForRoute.markersSubject.value, (marker: AssignedStopMapMarker) => {
        marker.isFocused = this.isMarkerForStop(marker, item);
        marker.panOnOpen = marker.isFocused && item.source === StoreSourcesEnum.ROUTE_BALANCING_BOARD;
        marker.isInfoWindowOpened = marker.isFocused;

        this.updateStopMarker(marker, this.zoomLevel);
      });
    });
    _forEach(
      markerClusters,
      (overlappedMarkers: OverlappedMarkers<AssignedStopMapMarkerCluster, AssignedStopMapMarker>) => {
        _forEach(overlappedMarkers.markers, (marker: AssignedStopMapMarker) => {
          marker.isFocused = this.isMarkerForStop(marker, item);
          marker.panOnOpen = marker.isFocused && item.source === StoreSourcesEnum.ROUTE_BALANCING_BOARD;
          marker.isInfoWindowOpened = marker.isFocused;

          this.updateStopMarker(marker, this.zoomLevel, marker.icon.anchor);

          if (marker.isFocused) {
            clusterOfFocusedMarker = overlappedMarkers.clusterer;
          }
        });
      }
    );

    _set(clusterOfFocusedMarker, 'isSelected', true);
    this.changeRef.detectChanges();
  }

  /**
   * Sets the isSelected state of all markers to reflect the list of
   * stops provided.  Markers in the list will be set to selected, while
   * those not in the list will be un-selected
   */
  private updateMarkersSelectionState(selectedStops: EventItem<AssignedStopIdentifier>[]) {
    // return true if the marker's stop is in the list of stops
    const isSelected = (marker: AssignedStopMapMarker, stops: EventItem<AssignedStopIdentifier>[]) => {
      let found = false;
      if (marker) {
        found = !!_find(stops, (stop) => this.isMarkerForStop(marker, stop));
      }
      return found;
    };

    _forEach(this.markersForRoutes, (markersForRoute) => {
      _forEach(markersForRoute.markersSubject.value, (marker) => {
        marker.isSelected = isSelected(marker, selectedStops);
        this.updateStopMarker(marker, this.zoomLevel);
      });
    });

    _forEach(
      this.markerClusters,
      (markerClusters: OverlappedMarkers<AssignedStopMapMarkerCluster, AssignedStopMapMarker>) => {
        _forEach(markerClusters.markers, (marker: AssignedStopMapMarker) => {
          marker.isSelected = isSelected(marker, selectedStops);
          this.updateStopMarker(marker, this.zoomLevel, marker.icon.anchor);
        });
      }
    );

    this.changeRef.detectChanges();
  }

  onClusterMarkerClick(marker: AssignedStopMapMarkerCluster) {
    const markerClusters = this.markerClustersSubject.value;

    for (const overlappedMarkers of markerClusters) {
      if (marker.clusterMarkerId === overlappedMarkers.clusterer.clusterMarkerId) {
        overlappedMarkers.clusterer.isSelected = !overlappedMarkers.clusterer.isSelected;
        break;
      }
    }

    this.markerClustersSubject.next(markerClusters);
  }

  onClusterMarkerRightClick(marker: AssignedStopMapMarkerCluster, event: MouseEvent) {
    const markerClusters = this.markerClustersSubject.value;

    this.contextMenu
      .openMenu(event.clientX, event.clientY, markerClusters)
      .subscribe((item: ContextMenuItem<ContextMenuItemId>) => {
        this.handleClusterMarkerContextMenu(item, marker, markerClusters);
      });
  }

  handleClusterMarkerContextMenu(
    item: ContextMenuItem<ContextMenuItemId>,
    marker: AssignedStopMapMarkerCluster,
    markerClusters: OverlappedMarkers<AssignedStopMapMarkerCluster, AssignedStopMapMarker>[]
  ) {
    if (item) {
      switch (item.id) {
        case ContextMenuItemId.Zoom:
          // find the cluster we are lookiong for
          const cluster = _find(markerClusters, (overlappedMarkers) => {
            return marker.clusterMarkerId === overlappedMarkers.clusterer.clusterMarkerId;
          });

          if (cluster) {
            // zoom to the first marker in cluster
            this.showSatelliteView(_first(cluster.markers));
          }
          break;
      }
    }
  }

  getClusterMarkerIcon(markerType: string, clusteredMarkers: number): MapMarkerIcon {
    return this.mapMarkerService.getClusterMarkerIcon(markerType, clusteredMarkers);
  }

  private updateTripsGrid() {
    this.pndStore$.dispatch(new TripsStoreActions.RefreshTrips());
  }

  private updateSelectedStops(notZoom: boolean): void {
    this.pndStore$
      .select(RoutesStoreSelectors.stopsForSelectedRoutes)
      .pipe(
        debounceTime(500),
        withLatestFrom(this.pndStore$.select(RoutesStoreSelectors.selectedRoutes)),
        withLatestFrom(this.pndStore$.select(RoutesStoreSelectors.showCompletedStops)),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(([[stopsForSelectedRoutes, selectedRoutes], showCompletedStops]) => {
        // This happens AFTER the stopsForSelectedRoutes resolve the set of stops.
        this.updateRoutes(selectedRoutes, stopsForSelectedRoutes, showCompletedStops, notZoom);
      });
  }

  subscribeToChangeColor(): void {
    this.routeColorService.colorChanged$
      .pipe(
        filter((change) => !!change),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((change: { routeInstId: number; color: string }) => {
        if (this.isRouteBalancerOpen) {
          this.resequenceRouteData();
        } else {
          this.updateSelectedStops(true);
        }
      });
  }

  private updatePlanningRoutesGrid() {
    this.pndStore$.dispatch(new RoutesStoreActions.RefreshPlanningRoutes());
  }

  private updateUnassignedDeliveriesGrid() {
    this.pndStore$.dispatch(
      new RoutesStoreActions.UpdateUnassignedDeliveriesGridAction({
        updateUnassignedDeliveriesGrid: {
          source: StoreSourcesEnum.PLANNING_MAP,
          date: new Date(),
        },
      })
    );
  }
}
