import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { InterfaceAcct } from '@xpo-ltl/sdk-cityoperations';
import { get as _get } from 'lodash';
import { MapMarkerInfo } from '../../../../../shared';
import { AssignedStopMapMarker } from '../../../../../shared/models';

@Component({
  selector: 'app-marker-info',
  templateUrl: './assigned-stop-marker-info.component.html',
  styleUrls: ['../marker-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AssignedStopMarkerInfoComponent implements OnInit, DoCheck {
  @Input()
  marker: AssignedStopMapMarker;

  get markerInfo(): MapMarkerInfo {
    return _get(this.marker, 'markerInfo');
  }

  get customer(): InterfaceAcct {
    return _get(this.marker, 'stop.customer');
  }

  feetAvailable: string;
  routeColor: string;
  routeName: string;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {}

  ngDoCheck() {
    if (this.routeColor !== this.marker.color || this.routeName !== this.marker.routeName) {
      this.routeColor = this.marker.color;
      this.routeName = this.marker.routeName;

      this.changeDetector.detectChanges();
    }
  }
}
