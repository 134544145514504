import { ChangeDetectionStrategy, Component, ViewEncapsulation, Input } from '@angular/core';
import { Metric } from '@xpo-ltl/sdk-cityoperations';
import { MetricVarianceCd } from '@xpo-ltl/sdk-common';

@Component({
  selector: 'pnd-metric-bar-card',
  templateUrl: 'metric-bar-card.component.html',
  styleUrls: ['metric-bar-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-MetricBarCard' },
})
export class MetricBarCardComponent {
  @Input()
  metric: Metric;
  constructor() {}

  getVarianceIcon(variance: MetricVarianceCd): string {
    switch (variance) {
      case MetricVarianceCd.ABOVE: {
        return 'arrow_upward';
      }
      case MetricVarianceCd.BELOW: {
        return 'arrow_downward';
      }
      default: {
        return 'remove';
      }
    }
  }

  getVarianceClassColor(variance: MetricVarianceCd): string {
    switch (variance) {
      case MetricVarianceCd.ABOVE: {
        return 'variance-above';
      }
      case MetricVarianceCd.BELOW: {
        return 'variance-below';
      }
      default: {
        return 'variance-neutral';
      }
    }
  }
}
