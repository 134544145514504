import { Action } from '@ngrx/store';
import { DeliveryShipmentSearchRecord, UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { GlobalFilterMapCoordinate } from '../../inbound-planning/shared';
import { EventItem, UnassignedDeliveryIdentifier } from '../../inbound-planning/shared/interfaces/event-item.interface';
import { UnassignedDeliveriesSearchCriteria } from './unassigned-deliveries-search-criteria.interface';

export enum ActionTypes {
  setSearchCriteria = 'unassignedDeliveries.setSearchCriteria',
  setLastUpdate = 'unassignedDeliveries.setLastUpdate',
  setSelectedDeliveries = 'unassignedDeliveries.setSelectedDeliveries',
  setFocusedDelivery = 'unassignedDeliveries.setFocusedDelivery',
  setClickedDelivery = 'unassignedDeliveries.setClickedDelivery',
  setBoundingSearchArea = 'unassignedDeliveries.setBoundingSearchArea',
  refresh = 'unassignedDeliveries.refresh',
}

export class SetSearchCriteria implements Action {
  readonly type = ActionTypes.setSearchCriteria;
  constructor(public payload: { criteria: UnassignedDeliveriesSearchCriteria }) {}
}

export class SetLastUpdate implements Action {
  readonly type = ActionTypes.setLastUpdate;
  constructor(public payload: { lastUpdate: Date }) {}
}

export class SetSelectedDeliveries implements Action {
  readonly type = ActionTypes.setSelectedDeliveries;
  constructor(public payload: { selectedDeliveries: EventItem<UnassignedDeliveryIdentifier>[] }) {}
}

export class SetFocusedDelivery implements Action {
  readonly type = ActionTypes.setFocusedDelivery;
  constructor(public payload: { focusedDelivery: EventItem<UnassignedDeliveryIdentifier> }) {}
}

export class SetClickedDelivery implements Action {
  readonly type = ActionTypes.setClickedDelivery;
  constructor(public payload: { clickedDelivery: DeliveryShipmentSearchRecord }) {}
}

export class SetBoundingSearchArea implements Action {
  readonly type = ActionTypes.setBoundingSearchArea;
  constructor(public payload: { boundingSearchArea: GlobalFilterMapCoordinate[] }) {}
}

export class Refresh implements Action {
  readonly type = ActionTypes.refresh;
  constructor() {}
}

export type Actions =
  | SetSearchCriteria
  | SetLastUpdate
  | SetSelectedDeliveries
  | SetFocusedDelivery
  | SetClickedDelivery
  | SetBoundingSearchArea
  | Refresh;
