import { tassign } from 'tassign';
import { Actions, ActionTypes } from './trips-store.actions';
import { initialState, State } from './trips-store.state';

export function tripsReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.setTransactionTimestampUTC: {
      return tassign(state, {
        transactionTimestampUTC: action.payload.transactionTimestampUTC,
      });
    }
    case ActionTypes.setSearchCriteria: {
      return tassign(state, {
        searchCriteria: action.payload.criteria,
      });
    }
    case ActionTypes.setTrips: {
      return tassign(state, {
        trips: action.payload.trips,
      });
    }
    case ActionTypes.setSelectedTrips: {
      return tassign(state, {
        selectedTrips: action.payload.selectedTrips,
      });
    }
    default: {
      return state;
    }
  }
}
