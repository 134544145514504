import { Component } from '@angular/core';

@Component({
  selector: 'pnd-zoom-out-button',
  templateUrl: './zoom-out-button.component.html',
  styleUrls: ['./zoom-out-button.component.scss'],
})
export class ZoomOutButton {
  constructor() {}
}
