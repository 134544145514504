import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material';
import { Store } from '@ngrx/store';
import {
  CityOperationsApiService,
  ListPnDSuggestedRouteNamesPath,
  ListPnDSuggestedRouteNamesQuery,
  ExistingRouteSummary,
} from '@xpo-ltl/sdk-cityoperations';
import { RouteCategoryCd } from '@xpo-ltl/sdk-common';
import { Unsubscriber } from '@xpo/ngx-ltl';
import {
  filter as _filter,
  find as _find,
  forEach as _forEach,
  map as _map,
  get as _get,
  orderBy as _orderBy,
  toUpper as _toUpper,
} from 'lodash';
import { Observable } from 'rxjs';
import { finalize, map, startWith, take, takeUntil } from 'rxjs/operators';
import { NotificationMessageStatus } from '../../../../../../../core/enums';
import { NotificationMessageService } from '../../../../../../../core/services';
import { PndRouteUtils } from '../../../../../../../shared/route-utils';
import { GlobalFilterStoreSelectors, PndStoreState, RoutesStoreSelectors } from '../../../../../../store';

export enum ExistingRouteLaneFields {
  EXISTING_ROUTE = 'existingRoute',
}

@Component({
  selector: 'app-existing-route-lane',
  templateUrl: './existing-route-lane.component.html',
  styleUrls: ['./existing-route-lane.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExistingRouteLaneComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('currentCardContent', { static: true }) currentCardContent;
  @ViewChild('existingRouteInput', { static: true }) existingRouteInput: ElementRef;

  @Input() height: number = 0;
  @Input() loadedRoutesNames: {
    prefix: string;
    suffix: string;
    satelliteSic: string;
  }[] = [];

  @Output() apply = new EventEmitter<ExistingRouteSummary>(undefined);
  @Output() cancel = new EventEmitter<void>();
  @Output() showSpinner = new EventEmitter<boolean>(true);

  formGroup: FormGroup;
  existingRoutesFiltered$: Observable<string[]>;

  disabled: boolean = false;
  private existingRoutes: ExistingRouteSummary[] = [];
  private existingRoutesAvailableToLoad: ExistingRouteSummary[] = [];
  private readonly unsubscriber = new Unsubscriber();

  readonly characterNumberHyphenPattern = '^[0-9a-zA-Z-]$';

  constructor(
    private pndStore$: Store<PndStoreState.State>,
    private formBuilder: FormBuilder,
    private cityOpsService: CityOperationsApiService,
    private notificationMessageService: NotificationMessageService
  ) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      [ExistingRouteLaneFields.EXISTING_ROUTE]: ['', Validators.required],
    });

    this.showSpinner.next(true);
    this.subscribeToStoreChange();
  }

  ngAfterViewChecked(): void {
    const correspondingRouteCard: HTMLElement = _get(
      this.currentCardContent,
      'nativeElement.parentElement.parentElement.firstChild'
    );
    if (correspondingRouteCard) {
      correspondingRouteCard['style'].backgroundColor = 'transparent';
      correspondingRouteCard['style'].borderTopLeftRadius = '2px';
      correspondingRouteCard['style'].borderTopRightRadius = '2px';
      correspondingRouteCard['style'].height = '6px';
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private subscribeToStoreChange(): void {
    this.pndStore$
      .select(RoutesStoreSelectors.resequencedRouteData)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(() => {
        this.fetchExistingRoutes();
      });
  }

  private filterExistingRoutes(value: string): string[] {
    const valueLower = value.toLowerCase();

    return this.existingRoutesAvailableToLoad
      .filter((v) =>
        PndRouteUtils.getRouteId(v)
          .toLowerCase()
          .includes(valueLower)
      )
      .map((route) => PndRouteUtils.getRouteId(route));
  }

  private fetchExistingRoutes(): void {
    this.pndStore$
      .select(GlobalFilterStoreSelectors.selectGlobalFilterState)
      .pipe(take(1))
      .subscribe(
        (state) => {
          const path: ListPnDSuggestedRouteNamesPath = {
            sicCd: state.sic,
          };
          const query: ListPnDSuggestedRouteNamesQuery = {
            planDate: state.planDate.toISOString().substring(0, 10),
            satelliteInd: true,
          };
          this.cityOpsService
            .listPnDSuggestedRouteNames(path, query)
            .pipe(
              take(1),
              finalize(() => {
                this.showSpinner.next(false);
                this.existingRouteInput.nativeElement.focus();
              })
            )
            .subscribe((response) => {
              const routes: ExistingRouteSummary[] = [];

              _forEach(response.existingRouteSummaries, (route: ExistingRouteSummary) => {
                if (route.routePrefix !== 'A' && route.categoryCd === RouteCategoryCd.DELIVERY) {
                  routes.push(route);
                }
              });

              this.existingRoutes = _orderBy(routes, (route) => PndRouteUtils.getRouteId(route));

              this.existingRoutesAvailableToLoad = _filter(
                this.existingRoutes,
                (route) =>
                  !this.loadedRoutesNames.find((r) => r.prefix === route.routePrefix && r.suffix === route.routeSuffix)
              );

              this.existingRoutesFiltered$ = this.formGroup
                .get(ExistingRouteLaneFields.EXISTING_ROUTE)
                .valueChanges.pipe(
                  takeUntil(this.unsubscriber.done),
                  startWith(''),
                  map((v) =>
                    v
                      ? this.filterExistingRoutes(v)
                      : _map(this.existingRoutesAvailableToLoad, (route) => PndRouteUtils.getRouteId(route)).slice()
                  )
                );
            });
        },
        (error) => {
          console.error(error);
          this.showSpinner.next(false);
        }
      );
  }

  selectionChange(item: MatOptionSelectionChange): void {
    this.addItemToLane(item.source.value);
  }

  onApply(): void {
    this.addItemToLane(this.formGroup.get(ExistingRouteLaneFields.EXISTING_ROUTE).value);
  }

  onCancel(): void {
    this.cancel.emit();
  }

  private addItemToLane(routeName: string): void {
    const routeInExistingRoutes = _find(
      this.existingRoutes,
      (r) => PndRouteUtils.getRouteId(r) === _toUpper(routeName)
    );

    if (routeInExistingRoutes) {
      const routeAvailableToLoad = _find(
        this.existingRoutesAvailableToLoad,
        (r) => PndRouteUtils.getRouteId(r) === _toUpper(routeName)
      );

      if (routeAvailableToLoad) {
        this.apply.emit(routeAvailableToLoad);
      } else {
        this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Error, 'Route already loaded')
          .subscribe(() => {});
      }
    } else {
      this.notificationMessageService
        .openNotificationMessage(NotificationMessageStatus.Error, 'The route does not exist')
        .subscribe(() => {});
    }
  }
}
