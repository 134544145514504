import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { CityOperationsApiService, ListPnDTripsPath, ListPnDTripsQuery, TripDetail } from '@xpo-ltl/sdk-cityoperations';
import { filter as _filter, some as _some, toString as _toString } from 'lodash';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, concatMapTo, pluck, take, withLatestFrom, switchMap } from 'rxjs/operators';
import { TripPlanningGridItem } from '../../inbound-planning/components/trip-planning/models/trip-planning-grid-item.model';
import * as PndStoreState from '../pnd-store.state';
import { RoutesStoreActions } from '../routes-store';
import { TripsSearchCriteria } from './trips-search-criteria.interface';
import {
  ActionTypes,
  RefreshTrips,
  SetSelectedTrips,
  SetTrips,
  SetTripsSearchCriteria,
  SetTransactionTimestampUTC,
} from './trips-store.actions';
import { searchCriteria, selectedTrips } from './trips-store.selectors';

@Injectable()
export class TripsStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<PndStoreState.State>,
    private cityOpsService: CityOperationsApiService
  ) {}

  @Effect()
  setTrips$: Observable<Action> = this.actions$.pipe(
    ofType<SetTrips>(ActionTypes.setTrips),
    concatMapTo([new SetTransactionTimestampUTC({ transactionTimestampUTC: moment.utc().toDate() })])
  );

  @Effect()
  setSearchCriteria$: Observable<Action> = this.actions$.pipe(
    ofType<SetTripsSearchCriteria>(ActionTypes.setSearchCriteria),
    concatMapTo([new RefreshTrips()])
  );

  @Effect()
  refreshTrips$: Observable<Action> = this.actions$.pipe(
    ofType<RefreshTrips>(ActionTypes.refreshTrips),
    concatMap(() => this.store$.select(searchCriteria).pipe(take(1))),
    switchMap((criteria) => this.fetchTrips(criteria)), // cancel out fetchTrips call if another refresh action is fired rapidly
    withLatestFrom(this.store$.select(selectedTrips)),
    concatMap(([trips, currentSelectedTrips]) => {
      const selTrips = _filter(currentSelectedTrips, (curSelTrip) => {
        return _some(trips, (trip) => curSelTrip.tripInstId === trip.trip.tripInstId);
      });

      return [new SetTrips({ trips }), new SetSelectedTrips({ selectedTrips: selTrips })];
    }),
    catchError(() => {
      // error loading trips, so clear existing ones
      return [new SetTrips({ trips: [] }), new SetSelectedTrips({ selectedTrips: [] })];
    })
  );

  // Set the selected Routes to be the Routes in the selected Trips
  @Effect()
  setSelectedTrips$: Observable<Action> = this.actions$.pipe(
    ofType<SetSelectedTrips>(ActionTypes.setSelectedTrips),
    concatMap((action) => {
      return [
        new RoutesStoreActions.SetSelectedRoutesAction({
          selectedRoutes: (action.payload.selectedTrips as TripPlanningGridItem[]).map((trip) => trip.route),
        }),
      ];
    })
  );

  /// Utility methods
  private fetchTrips(criteria: TripsSearchCriteria): Observable<TripDetail[]> {
    const pathParams: ListPnDTripsPath = {
      sicCd: criteria.sicCd,
    };

    const queryParams: ListPnDTripsQuery = {
      includeZoneCodeInd: false,
      tripStatusCd: criteria.tripStatusCd,
      tripDate: criteria.tripDate,
    };
    return this.cityOpsService.listPnDTrips(pathParams, queryParams).pipe(
      take(1),
      pluck('tripDetails'),
      catchError((err) => of([]))
    );
  }
}
