export enum TripPlanningGridFields {
  ROW_SELECTED = 'rowSelected',
  TIMELINE = 'timeline',
  DRIVER = 'tripDsrName',
  ROUTE_PREFIX = 'routeName',
  SUFFIX = 'routeName',
  ROUTE_STATUS = 'statusCd',
  BILLS = 'route.totalBillCount',
  WEIGHT = 'route.totalWeightCount',
  MM = 'route.totalMmCount',
  TRIP_STOPS = 'tripStopCount',
  EST_START_TIME = 'route.deliveryRouteDepartTime',
  DOOR = 'route.plannedDoor',
  TRAILER = 'route.fullTrailerId',
  DOCK_LOCATION = 'route.equipmentDockLocation.dockName',
  NEAREST_DOOR = 'route.equipmentDockLocation.dockClosestDoorNbr',
  EARLIEST_SHIPMENT_ETA = 'route.enrouteEarliestMessage',
  LATEST_SHIPMENT_ETA = 'route.enrouteLatestMessage',
}
