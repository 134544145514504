import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentConfiguration, GoldenLayoutService } from '@embedded-enterprises/ng6-golden-layout';
import { Store } from '@ngrx/store';
import { ServiceCenter } from '@xpo-ltl-2.0/sdk-location';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { LoginService } from '@xpo-ltl/login';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import {
  XpoAppSwitcherApplication,
  XpoFeedback,
  XpoMessagingPopoverMessage,
  XpoShellRoute,
  XpoAccountPopoverConfig,
} from '@xpo-ltl/ngx-ltl-core';
import {
  CityOperationsApiService,
  UnregisterFilterRoutesRqst,
  UnregisterFilterServiceCenterMetricsRqst,
  UnregisterFilterTripsRqst,
} from '@xpo-ltl/sdk-cityoperations';
import { User } from '@xpo-ltl/sdk-common';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import {
  Unsubscriber,
  XpoLtlAppSwitcherService,
  XpoLtlFeedbackService,
  XpoLtlReleaseNotesService,
  XpoLtlServiceCentersService,
  XpoLtlSicSwitcherComponent,
  XpoLtlTimeService,
} from '@xpo/ngx-ltl';
import { XrtFireMessagingService, XrtFireMessagingTokenService } from '@xpo/ngx-xrt-firebase';
import GoldenLayout from 'golden-layout';
import {
  defaultTo as _defaultTo,
  find as _find,
  forEach as _forEach,
  get as _get,
  invoke as _invoke,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  toLower as _toLower,
} from 'lodash';
import * as moment from 'moment-timezone';
import { Observable, timer } from 'rxjs';
import { delay, retryWhen, take, takeUntil, skip, tap, delayWhen } from 'rxjs/operators';
import routeBalancingLayout from '../assets/layouts/route-balancing.json';
import TestSics from '../assets/test-sics.json';
import { ConfigManagerProperties } from '../core/enums/config-manager-properties.enum';
import { FeaturesService } from '../core/services/features/features.service';
import { UserRoleService } from '../core/services/user-role/user-role.service';
import { GoldenLayoutExtService } from '../shared/layout-preference/services/golden-layout-ext.service';
import { LayoutPreferenceService } from '../shared/layout-preference/services/layout-preference.service';
import { UpdatedReleaseNotesComponent } from './inbound-planning/shared/components/updated-release-notes/updated-release-notes.component';
import { ReleaseNotesUpdateService } from './inbound-planning/shared/services/release-notes-update.service';
import { LayoutComponentName } from './layout-component-name.enum';
import { GlobalFilterStoreActions, GlobalFilterStoreSelectors, PndStoreState } from './store';
import { RouteBalancingSelectors } from './store/route-balancing-store';

enum AccountUrls {
  myAccount = 'https://portal.office.com/account/#personalinfo',
  help = '',
  privacyPolicy = '',
  switchApiUrl = 'https://switch.xpoapi.com/users/',
  pictureUrl = '/picture?size=medium',
}

type GoldenLayoutComponent = GoldenLayout.ContentItem & { componentName: string };

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-Root' },
  providers: [XrtFireMessagingService],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public feedbackService: XpoLtlFeedbackService,
    private appSwitcherService: XpoLtlAppSwitcherService,
    private configManagerService: ConfigManagerService,
    private dialog: MatDialog,
    private loginService: LoginService,
    private releaseNotesService: XpoLtlReleaseNotesService,
    private goldenLayoutService: GoldenLayoutService,
    private serviceCentersService: XpoLtlServiceCentersService,
    private pndStore$: Store<PndStoreState.State>,
    private userRoleService: UserRoleService,
    private cityOperationsService: CityOperationsApiService,
    private layoutPreferenceService: LayoutPreferenceService,
    private messagingTokenService: XrtFireMessagingTokenService,
    private timeService: XpoLtlTimeService,
    private releaseNotesUpdateService: ReleaseNotesUpdateService,
    private loggingService: LoggingApiService,
    featureService: FeaturesService,
    private authService: XpoLtlAuthenticationService
  ) {
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);

    this.region = `${_toLower(configManagerService.getSetting<string>(ConfigManagerProperties.region))}`;

    this.authService.initAuthSetup$(this.region).subscribe(() => {
      this.messagingTokenService.getToken$().subscribe((token) => {
        const unregisterFilterRoutesRqst = new UnregisterFilterRoutesRqst();
        unregisterFilterRoutesRqst.connectionToken = token;
        unregisterFilterRoutesRqst.filterHash = null;
        const unregisterFilterTripsRqst = new UnregisterFilterTripsRqst();
        unregisterFilterTripsRqst.connectionToken = token;
        unregisterFilterTripsRqst.filterHash = null;
        const unregisterFilterServiceCenterMetricsRqst = new UnregisterFilterServiceCenterMetricsRqst();
        unregisterFilterServiceCenterMetricsRqst.connectionToken = token;
        unregisterFilterServiceCenterMetricsRqst.filterHash = null;
        this.cityOperationsService.unregisterFilterRoutes(unregisterFilterRoutesRqst).subscribe();
        this.cityOperationsService.unregisterFilterTrips(unregisterFilterTripsRqst).subscribe();
        this.cityOperationsService
          .unregisterFilterServiceCenterMetrics(unregisterFilterServiceCenterMetricsRqst)
          .subscribe();
      });
    });

    this.messageData = [
      {
        type: 'alert', // could be alert, warning or success
        app: 'Edge - Pickup and Delivery',
        received: new Date(),
        title: 'Welcome to P&D',
        body: '...',
        link: '/',
      },
    ];

    const loggedInUserFunc = () => {
      this.loginService
        .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
        .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(5))))
        .subscribe(
          (user: User) => {
            if (user) {
              _invoke(window['dtrum'], 'identifyUser', !_isEmpty(user.emailAddress) ? user.emailAddress : user.userId);

              featureService.loadFeatures$().subscribe();

              this.checkForUpdatedReleaseNotes();

              this.configureFeedback(user);

              this.userRoleService.setUser(user);

              this.populateAccountPopover(user);

              this.populateAppSwitcher();

              const permittedSicPrefixes = ['U', 'X', 'N', 'L'];
              let userSicCd = _defaultTo(user.sicCode, '');

              if (permittedSicPrefixes.indexOf(userSicCd.toUpperCase()[0]) < 0) {
                if (this.configManagerService.getSetting(ConfigManagerProperties.buildVersion) === 'local-version') {
                  // default to UPO for local bulds.
                  userSicCd = 'UPO';
                } else if (userSicCd.toUpperCase().startsWith('I')) {
                  // pick a random SIC for testing
                  userSicCd = TestSics[Math.floor(Math.random() * Math.floor(TestSics.length))].NODE_SIC_CD;
                }
              }

              this.handleSicChanged(userSicCd);
            }
          },
          (error) => {
            this.userRoleService.setUser(undefined);

            console.log('ERROR', error);
          }
        );
    };

    loggedInUserFunc();
    this.loginService.userLoggedIn$.subscribe(() => {
      loggedInUserFunc();
    });

    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterPlanDate)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((newPlanDate: Date) => {
        this.planDate = newPlanDate;
      });

    this.pndStore$
      .select(RouteBalancingSelectors.openRouteBalancingPanel)
      .pipe(takeUntil(this.unsubscriber.done), skip(1))
      .subscribe((isRouteBalancingActive: boolean) => {
        this.loggingService.info(`Received intent to route balancer ${isRouteBalancingActive}`);

        this.isRouteBalancingActive = isRouteBalancingActive;

        if (isRouteBalancingActive) {
          // stash current layout and load Route Balancing layout
          this.layoutPreferenceService.stashAndLoadLayout(routeBalancingLayout);
        } else {
          // restoring stashed layout
          this.layoutPreferenceService.restoreStashedLayout();
        }

        this.updateLayoutSize()
          .pipe(
            take(1),
            retryWhen((errors) =>
              errors.pipe(
                tap((error) => console.log(error)),
                delayWhen(() => timer(200))
              )
            )
          )
          .subscribe();
      });

    this.goldenLayoutExtService = this.goldenLayoutService as GoldenLayoutExtService;
    this.goldenLayoutExtService.initialized$.pipe(take(1)).subscribe(() => {
      this.goldenLayout = this.goldenLayoutExtService.goldenLayout;
      this.goldenLayoutRegisteredComponents = this.goldenLayoutService.getRegisteredComponents();
    });

    this.goldenLayoutExtService.componentCreated$.subscribe((component: GoldenLayoutComponent) => {
      const createdComponent = this.goldenLayoutControls.find((c) => c.componentName === component.componentName);
      if (createdComponent) {
        createdComponent.opened = true;
      }
    });

    this.goldenLayoutExtService.componentDestroyed$.subscribe((component: GoldenLayoutComponent) => {
      const destroyedComponent = this.goldenLayoutControls.find((c) => c.componentName === component.componentName);
      if (destroyedComponent) {
        destroyedComponent.opened = false;
      }
    });

    this.goldenLayoutExtService.activeContentItemChanged$.subscribe((component: GoldenLayoutComponent) => {
      const selectedComponentName = _get(component, 'componentName');
      const selectedComponentStackName = _get(component, 'parent.config.title');

      // two-way binding
      const componentBindings = [
        { componentA: LayoutComponentName.TRIP_PLANNING, componentB: LayoutComponentName.ROUTE_STOPS },
        { componentA: LayoutComponentName.ROUTE_PLANNING, componentB: LayoutComponentName.PLANNING_ROUTE_SHIPMENTS },
      ];

      _forEach(componentBindings, ({ componentA, componentB }) => {
        const isComponentAActive = this.isGoldenLayoutComponentActive(componentA);
        const isComponentBActive = this.isGoldenLayoutComponentActive(componentB);

        if (
          selectedComponentName === componentA &&
          !this.isGoldenLayoutComponentInStack(componentB, selectedComponentStackName) &&
          !isComponentBActive
        ) {
          this.activatePanel(componentB);
        }
        if (
          selectedComponentName === componentB &&
          !this.isGoldenLayoutComponentInStack(componentA, selectedComponentStackName) &&
          !isComponentAActive
        ) {
          this.activatePanel(componentA);
        }
      });
    });
  }
  @ViewChild('sicSwitcher', { static: true }) sicSwitcher: XpoLtlSicSwitcherComponent;

  apps$: Observable<XpoAppSwitcherApplication[]>;
  build: string;
  currentSic = '';
  messageData: XpoMessagingPopoverMessage[];
  planDate: Date = undefined;
  routes: XpoShellRoute[] = [
    {
      label: 'Planning',
      path: '/inbound-planning',
    },
  ];

  isRouteBalancingActive: boolean = false;
  goldenLayout: GoldenLayout;
  goldenLayoutRegisteredComponents: ComponentConfiguration[];
  goldenLayoutControls = [
    { componentName: LayoutComponentName.PLANNING_MAP, title: 'Map', icon: 'map', opened: false },
    {
      componentName: LayoutComponentName.UNASSIGNED_STOPS,
      title: 'Unassigned Deliveries',
      icon: 'not_listed_location',
      opened: false,
    },
    {
      componentName: LayoutComponentName.UNASSIGNED_PICKUPS,
      title: 'Unassigned Pickups',
      icon: 'add_shopping_cart',
      opened: false,
    },
    { componentName: LayoutComponentName.ROUTE_PLANNING, title: 'Planning Routes', icon: 'access_time', opened: false },
    { componentName: LayoutComponentName.TRIP_PLANNING, title: 'Trips', icon: 'timeline', opened: false },
    { componentName: LayoutComponentName.ROUTE_STOPS, title: 'Route Stops', icon: 'local_shipping', opened: false },
    {
      componentName: LayoutComponentName.PLANNING_ROUTE_SHIPMENTS,
      title: 'Planning Route Shipments',
      icon: 'departure_board',
      opened: false,
    },
  ];
  private goldenLayoutExtService: GoldenLayoutExtService;

  private unsubscriber = new Unsubscriber();
  trainingResourcesLink: string;

  readonly AccountUrls = AccountUrls;

  region: string = '';

  accountPopoverConfig: XpoAccountPopoverConfig;

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  ngOnInit() {
    this.trainingResourcesLink = this.configManagerService.getSetting('trainingResourcesLink');
  }

  ngAfterViewInit() {
    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterSic)
      .pipe(skip(1), takeUntil(this.unsubscriber.done))
      .subscribe((sicCd: string) => {
        if (sicCd) {
          this.serviceCentersService
            .getSicByCd$(sicCd)
            .pipe(take(1))
            .subscribe((sic: ServiceCenter) => {
              if (sic) {
                this.currentSic = sic.sicCd;
              } else {
                this.setDefaultSic();
              }
            });
        } else {
          this.setDefaultSic();
        }
      });
  }

  private setDefaultSic() {
    this.currentSic = '';
    this.sicSwitcher.focus();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  /**
   * Update golden layout height with retry
   */
  updateLayoutSize(): Observable<number> {
    return timer(1).pipe(
      tap(() => {
        if (this.goldenLayoutExtService.goldenLayout) {
          this.goldenLayoutExtService.goldenLayout.updateSize();
        } else {
          throw new Error('GoldenLayout is undefined');
        }
      })
    );
  }

  handleFeedbackClick(): void {
    this.feedbackService.feedbackConfig$.pipe(take(1)).subscribe((config) => {
      this.dialog.open(XpoFeedback, { data: config });
    });
  }

  handleReleaseNotesClick(): void {
    this.releaseNotesService.showReleaseNotes().subscribe(() => {});
  }

  handlePlanDateChanged(newPlanDate: Date): void {
    if (newPlanDate !== this.planDate) {
      this.pndStore$.dispatch(new GlobalFilterStoreActions.SetPlanDateAction({ planDate: newPlanDate }));
    }
  }

  handleSicChanged(newSic: string): void {
    if (newSic && newSic !== this.currentSic) {
      this.serviceCentersService.serviceCenters$.pipe(take(1)).subscribe((serviceCenters) => {
        const serviceCenter = serviceCenters.find((s) => s.sicCd === newSic);
        const sicCoordinates: { latitude: number; longitude: number } = {
          latitude: _get(serviceCenter, 'reference.physSiteLatd', 0),
          longitude: _get(serviceCenter, 'reference.physSiteLngt', 0),
        };
        this.pndStore$.dispatch(new GlobalFilterStoreActions.SetSicAction({ sic: newSic }));
        this.pndStore$.dispatch(new GlobalFilterStoreActions.SetSicLatLngAction({ sicLatLng: sicCoordinates }));
        this.setDefaultPlanDate(newSic);
      });
    }
  }

  handleResetPreferences() {
    // TODO - need to close the Account popover somehow
    this.pndStore$.dispatch(new GlobalFilterStoreActions.ResetPreferences());
  }

  private setDefaultPlanDate(sic: string): void {
    if (sic && sic !== '') {
      this.timeService.timezoneForSicCd$(sic).subscribe((result) => {
        const currentTime = moment()
          .tz(result)
          .format('HH:mm:ss');
        let planDate = moment().tz(result); // The default date should be the current date at the sic.
        if (
          currentTime >= this.configManagerService.getSetting<string>(ConfigManagerProperties.planDateCrossOverTime)
        ) {
          const tomorrow = new Date(planDate);
          tomorrow.setDate(tomorrow.getDate() + 1); // If it's past the crossover time set the plan date to tomorrow.
          planDate = tomorrow;
        }
        if (this.planDate && this.planDate.toISOString().substring(0, 10) !== planDate.toISOString().substring(0, 10)) {
          this.pndStore$.dispatch(new GlobalFilterStoreActions.SetPlanDateAction({ planDate: planDate })); // Only update it if it's changed
        }
      });
    }
  }

  private getGoldenLayoutOpenedComponents() {
    return this.goldenLayout.root.getItemsByType('component');
  }

  isGoldenLayoutComponentOpen(componentName: string) {
    const openedComponents = this.getGoldenLayoutOpenedComponents();
    return !!openedComponents.find((c: GoldenLayoutComponent) => c.componentName === componentName);
  }

  isGoldenLayoutComponentInStack(componentName: string, stack: string): boolean {
    const component = this.getGoldenLayoutOpenedComponents().find(
      (c: GoldenLayoutComponent) => c.componentName === componentName
    );
    const stackName = _get(component, 'parent.config.title');
    return stackName === stack;
  }

  isGoldenLayoutComponentActive(componentName: string): boolean {
    const component = this.getGoldenLayoutOpenedComponents().find(
      (c: GoldenLayoutComponent) => c.componentName === componentName
    );
    return _get(component, 'container.tab.isActive', false);
  }

  activatePanel(componentName: string) {
    try {
      const stacks = this.goldenLayout.root.getItemsByType('stack');
      stacks.forEach((stack) => {
        stack.contentItems.forEach((item) => {
          if ((item as GoldenLayoutComponent).componentName === componentName) {
            stack.setActiveContentItem(item as GoldenLayoutComponent);
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  addPanel(componentName: string, title: string) {
    const component = this.goldenLayoutRegisteredComponents.find((c) => c.componentName === componentName);
    const isComponentOpen = this.isGoldenLayoutComponentOpen(componentName);
    if (component && !isComponentOpen) {
      // Add stack to the root element when there is no content items.
      if (this.goldenLayout.root.contentItems && this.goldenLayout.root.contentItems.length === 0) {
        // This is a hack to solve a bug in the library 'ng6-golden-layout'
        this.goldenLayout.root.addChild(this.goldenLayout.createContentItem({ type: 'stack', title: title }) as any);
      }

      if (componentName === LayoutComponentName.ROUTE_BALANCING) {
        try {
          const stacks = this.goldenLayout.root.getItemsByType('stack');
          if (stacks && stacks.length > 1) {
            stacks[1].addChild({
              type: 'component',
              componentName: component.componentName,
              title: title,
              isClosable: false,
            } as GoldenLayout.ComponentConfig);
          } else {
            this.goldenLayoutService.createNewComponent(component, title);
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        this.goldenLayoutService.createNewComponent(component, title);
      }
    }
  }

  removePanel(componentName: string) {
    if (_get(this.goldenLayout, 'root')) {
      const components = this.goldenLayout.root.getItemsByType('component');
      const component = _find(components, (c) => {
        return _isEqual(_get(c, 'componentName'), componentName);
      });

      if (component) {
        component.remove();
      }
    }
  }

  private populateAppSwitcher(): void {
    this.apps$ = this.appSwitcherService.getAppList();
  }

  private configureFeedback(user: User): void {
    const jiraConfig = _get(window, 'ATL_JQ_PAGE_PROPS.fieldValues');
    if (jiraConfig) {
      // THESE VARIABLES ARE CONFIGURED IN THE ISSUE COLLECTOR FOR P&D PROJECT
      jiraConfig.environment = `${this.configManagerService.getSetting(
        ConfigManagerProperties.region
      )} - ${this.configManagerService.getSetting(ConfigManagerProperties.buildVersion)}`;
      jiraConfig.customfield_15875 = `${user.displayName} (${user.employeeId})`;
      jiraConfig.customfield_13435 = user.emailAddress;
    }
  }

  private checkForUpdatedReleaseNotes(): void {
    this.releaseNotesUpdateService
      .doNewReleaseNotesExist()
      .pipe(take(1))
      .subscribe((updatesExist) => {
        if (updatesExist) {
          this.releaseNotesUpdateService.showReleaseNotes$.subscribe((showReleaseNotes) => {
            if (showReleaseNotes) {
              this.showReleaseNotes();
            }
          });
        }
      });
  }

  private showReleaseNotes(): void {
    this.dialog.open(UpdatedReleaseNotesComponent, {
      disableClose: true,
      hasBackdrop: false,
    });
  }

  private populateAccountPopover(user: User): void {
    const fullName = `${user.givenName} ${user.lastName}`;

    this.accountPopoverConfig = {
      imageUri: AppComponent.getProfilePictureUrl(user.emailAddress),
      name: fullName,
      onSignOutCallback: (): void => {
        this.signOut();
      },
      links: [
        { title: 'My Account', url: AccountUrls.myAccount },
        { title: 'Help', url: AccountUrls.help },
        { title: 'Privacy Policy', url: AccountUrls.privacyPolicy },
      ],
    };
  }

  private signOut(): void {
    // Removing the local storage keys
    this.loginService.clear();
  }
}
