import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { XpoBoardApi, XpoBoardReadyEvent } from '@xpo-ltl/ngx-ltl-board';
import { UnassignedPickup } from '@xpo-ltl/sdk-cityoperations';
import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { AgGridEvent, MenuItemDef, RowNode, RowSelectedEvent } from 'ag-grid-community';
import {
  bind as _bind,
  filter as _filter,
  find as _find,
  forEach as _forEach,
  get as _get,
  has as _has,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  some as _some,
} from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { GridRowTransposedComponent } from '../../../../core';
import {
  GlobalFilterStoreSelectors,
  PndStoreState,
  UnassignedPickupsStoreActions,
  UnassignedPickupsStoreSelectors,
} from '../../../store';
import {
  GlobalFilterMapCoordinate,
  MapToolbarService,
  pndFrameworkComponents,
  SpecialServicesService,
  StopMapComponent,
  MapSplitPanelService,
} from '../../shared';
import { PluralMaps } from '../../shared/classes/plural-maps';
import { RowHoverManager } from '../../shared/classes/row-hover-manager';
import { InboundPlanningGridBaseComponent } from '../../shared/components/inbound-planning-grid-base/inbound-planning-grid-base.class';
import { StoreSourcesEnum } from '../../shared/enums/store-sources.enum';
import { EventItem, UnassignedPickupIdentifier } from '../../shared/interfaces/event-item.interface';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { PickupsPanelComponent } from '../planning-map/components/map-split-panels/pickups-panel.component';
import { UnassignedDeliveriesGridFields } from '../unassigned-deliveries/enums/unassigned-deliveries-grid-fields.enum';
import { UnassignedPickupsBoardViewTemplate } from './models/unassigned-pickups-board-view-template.model';
import { UnassignedPickupsGridItem } from './models/unassigned-pickups-grid-item.model';
import { UnassignedPickupsComponentName } from './services/unassigned-pickups-component-name';
import { UnassignedPickupsDataSource } from './services/unassigned-pickups-data-source.service';
import { UnassignedPickupsViewDataStore } from './services/unassigned-pickups-view-data-store.service';

@Component({
  selector: 'unassigned-pickups',
  templateUrl: './unassigned-pickups.component.html',
  styleUrls: ['./unassigned-pickups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [UnassignedPickupsDataSource],
})
export class UnassignedPickupsComponent extends InboundPlanningGridBaseComponent implements OnInit, OnDestroy {
  private focusedItem: EventItem<UnassignedPickupIdentifier>;
  private focusedRow: RowNode;
  private selectedRow: BehaviorSubject<RowSelectedEvent> = new BehaviorSubject(null);
  private lastSelectedItems: EventItem<UnassignedPickupIdentifier>[] = [];

  isDisabled = true;
  stopsSelected = 0;
  loosePiecesSelected = 0;
  motorMovesSelected = 0;
  weightSelected = 0;
  isGeoAreaFilterActive: boolean = false;

  specialServices: ShipmentSpecialServiceCd[];

  readonly PluralMaps = PluralMaps;

  private rowHoverManager: RowHoverManager<UnassignedPickup>;
  private setFocusedActionSubject = new Subject<UnassignedPickupsStoreActions.SetFocusedUnassignedPickupAction>();

  private boardApi: XpoBoardApi;

  constructor(
    private dialog: MatDialog,
    public dataSource: UnassignedPickupsDataSource,
    private route: ActivatedRoute,
    private mapSplitPanelService: MapSplitPanelService,
    private timeService: XpoLtlTimeService,
    protected pndStore$: Store<PndStoreState.State>,
    private specialServicesService: SpecialServicesService,
    private mapToolbarService: MapToolbarService,
    protected userPreferencesService: UserPreferencesService
  ) {
    super(pndStore$, dataSource, userPreferencesService);
  }

  private findNode(id: number): RowNode {
    let gridNode: RowNode;
    this.gridApiEvent.api.forEachNode((node) => {
      const pickupId = _get(node, 'data.pickupInstId') as number;
      if (pickupId === id) {
        gridNode = node;
      }
    });
    return gridNode;
  }

  clearGeoFilter(): void {
    this.mapToolbarService.handleSicClicked();
  }

  ngOnInit() {
    super.ngOnInit();

    // create a debounced Subject to reduce spamming focus actions
    this.setFocusedActionSubject
      .asObservable()
      .pipe(debounceTime(250))
      .subscribe((action) => {
        this.pndStore$.dispatch(action);
      });

    // handle setting focused pickup
    this.rowHoverManager = new RowHoverManager(
      this.gridOptions,
      _bind(this.setFocusedRow, this),
      _bind(this.clearFocusedRow, this)
    );

    this.gridOptions.frameworkComponents = pndFrameworkComponents;
    this.gridOptions.getContextMenuItems = (params) => this.getContextMenuItems(params);

    // show as focused when row is the focused row
    this.gridOptions.rowClassRules = {
      'ag-row-hover': (params) => {
        return (
          _get(params, 'data.pickupInstId', false) &&
          _get(this.focusedItem, 'id.pickupInstId', false) &&
          this.focusedItem.id.pickupInstId === +params.data.pickupInstId
        );
      },
    };

    this.gridOptions.onRowSelected = ($event: RowSelectedEvent) => {
      this.selectedRow.next($event);
    };

    this.pndStore$
      .select(UnassignedPickupsStoreSelectors.unassignedPickupsBoundingSearchArea)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((geoAreaCoordinatesArr: GlobalFilterMapCoordinate[]) => {
        this.isGeoAreaFilterActive = geoAreaCoordinatesArr && geoAreaCoordinatesArr.length ? true : false;
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.boardReadySubject.next(false);
    this.rowHoverManager.destroy();
  }

  onGridReady(gridEvent: AgGridEvent) {
    super.onGridReady(gridEvent);

    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterPlanDate)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((planDate) => {
        // TODO: Having to look at internal board state in order to determine which tab is current
        const stateCache = this.dataSource['stateCache'] || {};
        if (stateCache['viewName'] === 'Show All') {
          const config = {
            max: planDate,
            min: planDate,
          };

          this.boardApi.applyFilterCriterion('pickupDate', config);
        }
      });

    // show the focused Pickup
    this.pndStore$
      .select(UnassignedPickupsStoreSelectors.unassignedPickupsFocused)
      .pipe(
        filter(
          (item: EventItem<UnassignedPickupIdentifier>) =>
            item && item.source !== StoreSourcesEnum.UNASSIGNED_PICKUP_GRID
        ),
        debounceTime(500),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((item: EventItem<UnassignedPickupIdentifier>) => {
        this.focusedItem = item;
        if (item && item.id) {
          this.focusedRow = this.findNode(_get(this.focusedItem, 'id.pickupInstId', -1));
          this.gridApiEvent.api.redrawRows({ rowNodes: [this.focusedRow] });
          this.gridApiEvent.api.ensureNodeVisible(this.focusedRow);
        } else {
          this.gridApiEvent.api.redrawRows({ rowNodes: [this.focusedRow] });
          this.focusedRow = null;
        }
      });

    this.pndStore$
      .select(UnassignedPickupsStoreSelectors.unassignedPickupsSelected)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((items: EventItem<UnassignedPickupIdentifier>[]) => {
        this.checkForPolygonSelections(items, StoreSourcesEnum.UNASSIGNED_PICKUP_GRID);

        this.isDisabled = _isEmpty(items);
        this.clearSelected();
        if (items.length > 0) {
          this.toggleAssignPickupsInMapSplitPanel(true);
          const servicesAccumulator = [];
          // Update new selected rows
          _forEach(items, (item: EventItem<UnassignedPickupIdentifier>) => {
            // if (item.source !== StoreSourcesEnum.UNASSIGNED_PICKUP_GRID) {
            const node = this.findNode(item.id.pickupInstId);
            if (node) {
              if (!node.isSelected()) {
                node.setSelected(true);
              }

              const unassignedPickup = node.data as UnassignedPickup;
              this.stopsSelected += 1;
              this.loosePiecesSelected += _get(unassignedPickup, 'loosePcsCnt', 0);
              this.motorMovesSelected += _get(unassignedPickup, 'totalMotorizedPiecesCount', 0);
              this.weightSelected += _get(unassignedPickup, 'totalWeightLbs', 0);

              _get(unassignedPickup, 'specialServiceSummary', []).forEach(
                (specialService: ShipmentSpecialServiceCd) => {
                  if (servicesAccumulator.findIndex((s) => s === specialService) < 0) {
                    servicesAccumulator.push(specialService);
                  }
                }
              );
            }
            // }

            this.specialServices = servicesAccumulator;
          });
        } else {
          this.toggleAssignPickupsInMapSplitPanel(false);
          this.updateNodeSelection([]);
        }
        this.lastSelectedItems = items;
      });

    this.pndStore$
      .select(UnassignedPickupsStoreSelectors.searchCriteria)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(() => {
        this.clearSelected();
      });

    this.selectedRow
      .pipe(
        filter((row) => row !== null),
        map((row) => [row.node, row.data]),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(([node, unassignedPickup]: [RowNode, UnassignedPickup]) => {
        // Listen for specific row selection on the grid

        // add/remove the Pickup from the list of selected Pickups
        this.pndStore$
          .pipe(select(UnassignedPickupsStoreSelectors.unassignedPickupsSelected), take(1))
          .subscribe((items: EventItem<UnassignedPickupIdentifier>[]) => {
            // Preserve previous selection
            let selectedPickups: EventItem<UnassignedPickupIdentifier>[] = [...items];

            if (node.isSelected()) {
              const selectedPickup: EventItem<UnassignedPickupIdentifier> = {
                id: {
                  pickupInstId: unassignedPickup.pickupInstId,
                  shipper: {
                    latitudeNbr: unassignedPickup.shipper.geoCoordinates.latitude,
                    longitudeNbr: unassignedPickup.shipper.geoCoordinates.longitude,
                  },
                },
                source: StoreSourcesEnum.UNASSIGNED_PICKUP_GRID,
              };
              if (!_find(selectedPickups, (item) => item.id.pickupInstId === selectedPickup.id.pickupInstId)) {
                selectedPickups.push(selectedPickup);
              }
            } else {
              selectedPickups = _filter(
                items,
                (pickup) => !_isEqual(pickup.id.pickupInstId, unassignedPickup.pickupInstId)
              );
            }

            this.pndStore$.dispatch(
              new UnassignedPickupsStoreActions.SetSelectedUnassignedPickupsAction({
                selectedPickups: selectedPickups,
              })
            );
          });
      });

    this.subscribeToRowSelectedToRefreshSort();
  }

  // Refreshes Sort upon selection when rowSelected sorting is selected.
  // Handled separately to set debounce for when multiple rows are selected in
  // sequence from store to avoid too many event dispatches
  subscribeToRowSelectedToRefreshSort() {
    this.selectedRow.pipe(takeUntil(this.unsubscriber.done), debounceTime(200)).subscribe(() => {
      this.refreshSortForSelectedRows();
    });
  }

  onBoardReady($event: XpoBoardReadyEvent) {
    this.boardApi = $event.boardApi;
  }

  clearSelection(): void {
    this.mapToolbarService.toggleDrawModeOff();
    this.pndStore$.dispatch(
      new UnassignedPickupsStoreActions.SetSelectedUnassignedPickupsAction({
        selectedPickups: [],
      })
    );
  }

  private clearSelected() {
    this.stopsSelected = 0;
    this.loosePiecesSelected = 0;
    this.motorMovesSelected = 0;
    this.weightSelected = 0;
    this.specialServices = [];
  }

  private toggleAssignPickupsInMapSplitPanel(visible: boolean) {
    // NOTE: example reference implementation for map slide overlay for assign pickups / assign shipments work TBD
    // const assignPickupsItem = new MapSplitPanelItem(PickupsPanelComponent, {
    //   title: 'Assign Pickups',
    //   id: 'assignPickups',
    //   orderIndex: 1,
    // });
    // if (visible) {
    //   this.mapSplitPanelService.addPanelItem(assignPickupsItem);
    // } else {
    //   this.mapSplitPanelService.removePanelItem(assignPickupsItem);
    // }
  }

  private setFocusedRow(pickup: UnassignedPickup) {
    const focusedPickup = {
      id: undefined,
      source: StoreSourcesEnum.UNASSIGNED_PICKUP_GRID,
    };

    if (_has(pickup, 'shipper')) {
      focusedPickup.id = {
        pickupInstId: pickup.pickupInstId,
        shipper: {
          latitudeNbr: pickup.shipper.latitudeNbr,
          longitudeNbr: pickup.shipper.longitudeNbr,
        },
      };
    }

    this.setFocusedActionSubject.next(
      new UnassignedPickupsStoreActions.SetFocusedUnassignedPickupAction({
        focusedPickup,
      })
    );
  }

  private clearFocusedRow() {
    this.setFocusedActionSubject.next(
      new UnassignedPickupsStoreActions.SetFocusedUnassignedPickupAction({
        focusedPickup: {
          id: undefined,
          source: StoreSourcesEnum.UNASSIGNED_PICKUP_GRID,
        },
      })
    );
  }

  private onAddressClick(pickup: UnassignedPickupsGridItem): void {
    const latitude = pickup.shipper.latitudeNbr;
    const longitude = pickup.shipper.longitudeNbr;

    this.dialog.open(StopMapComponent, {
      data: {
        consigneeName: _get(pickup, 'shipper.name1', ''),
        geoCoordinates: { latitude, longitude },
      },
      disableClose: true,
      hasBackdrop: false,
    });
  }

  private getContextMenuItems(params): (string | MenuItemDef)[] {
    return [
      'copy',
      'copyWithHeaders',
      'paste',
      'separator',
      'export',
      'separator',
      {
        name: 'Transpose',
        action: () => {
          this.dialog.open(GridRowTransposedComponent, {
            data: { ...params, dialogTitle: `Unassigned Pickups - ${params.node.data.sourceCd}` },
            disableClose: true,
            hasBackdrop: false,
          });
        },
      },
    ];
  }

  protected getRowNodeId(node: RowNode) {
    return _get(node, 'data.pickupInstId');
  }

  protected getSelectedRowFromStoreId(selectedRow) {
    return _get(selectedRow, 'id.pickupInstId');
  }

  protected getSelectedStoreStateSelector() {
    return UnassignedPickupsStoreSelectors.unassignedPickupsSelected;
  }

  protected getSelectedRowColumnFieldName() {
    return UnassignedDeliveriesGridFields.ROW_SELECTED;
  }

  protected getBoardViewTemplates() {
    return [
      new UnassignedPickupsBoardViewTemplate(
        this.timeService,
        this.onAddressClick.bind(this),
        this.specialServicesService
      ),
    ];
  }

  protected getGridOptions() {
    return (<UnassignedPickupsBoardViewTemplate>this.viewTemplates[0]).getGridOptions();
  }

  protected mapViewDataStore(preferences) {
    return new UnassignedPickupsViewDataStore(this.userPreferencesService, preferences, this.pndStore$);
  }

  protected getComponentName() {
    return UnassignedPickupsComponentName;
  }
}
