import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { CityOperationsApiService, ListPnDStopsPath, Activity, TripStop, Stop } from '@xpo-ltl/sdk-cityoperations';
import { TripNodeStatusCd, TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { countBy as _countBy, get as _get, padStart as _padStart } from 'lodash';
import { Moment } from 'moment';
import * as moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import {
  DataSet,
  Timeline,
  TimelineOptions,
  TimelineTimeAxisOption,
  TimelineTooltipOption,
  DataItemCollectionType,
} from 'vis';
import { GlobalFilterStoreSelectors, PndStoreState } from '../../../../store';

@Component({
  selector: 'pnd-route-timeline-cell-renderer',
  template: `
    <div (click)="timelineClick($event)" id="timeline" #visjsTimeline></div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class RouteTimelineCellRendererComponent implements ICellRendererAngularComp {
  @ViewChild('visjsTimeline', { static: true }) routeTimelineContainer: ElementRef;

  timeline: Timeline;
  params: any;

  private showTimelineSubject = new BehaviorSubject<boolean>(false);
  showTimeline$ = this.showTimelineSubject.asObservable();

  private planDate: Date;

  constructor(
    private cityOperationsService: CityOperationsApiService,
    private pndStore$: Store<PndStoreState.State>,
    private timeService: XpoLtlTimeService
  ) {}

  agInit(params: any): void {
    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterPlanDate)
      .pipe(take(1))
      .subscribe((planDate) => {
        this.planDate = planDate;
        this.params = params;

        const request = new ListPnDStopsPath();
        request.routeInstId = _get(params, 'data.route.routeInstId');

        const startTime = moment(this.planDate)
          .hour(5)
          .minute(0)
          .second(0);
        const endTime = moment(this.planDate)
          .hour(21)
          .minute(0)
          .second(0);

        this.timeline = new Timeline(
          this.routeTimelineContainer.nativeElement,
          new DataSet(),
          this.buildTimelineOptions(startTime, endTime)
        );

        this.cityOperationsService.listPnDStops(request).subscribe(
          (response) => {
            let stops = _get(response, 'stops', []);
            // Remove empty stops
            stops = stops.filter((stop) => stop && stop.tripNode && stop.activities);
            this.timeline.setItems(this.buildDataSet(stops));
            this.showTimelineSubject.next(true);
          },
          (error) => {
            console.error(error);
          }
        );

        this.pndStore$
          .select(GlobalFilterStoreSelectors.globalFilterSic)
          .pipe(
            take(1),
            filter((sicCd: string) => !!sicCd)
          )
          .subscribe(
            (currentSicCd) => {
              this.timeService.timezoneForSicCd$(currentSicCd).subscribe((timezoneforSicCd) => {
                this.timeline.setCurrentTime(moment.tz(new Date(), timezoneforSicCd).format('YYYY-MM-DDTHH:mm:ss'));
              });
            },
            (error) => {
              console.error(error);
            }
          );
      });
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  timelineClick(clickEvent: any): void {
    clickEvent.stopImmediatePropagation();
  }

  private buildDataSet(stops: TripStop[] | Stop[]): DataItemCollectionType {
    let lowerStartTime: moment.Moment;
    let upperEndTime: moment.Moment;
    const dataSet = new DataSet();

    const stopColorFunc = (activities: Activity[], status: string): string => {
      const inProgress =
        activities.findIndex((a) => a.tripNodeActivity.activityCd === TripNodeActivityCd.ARRIVE) >= 0 &&
        activities.findIndex((a) => a.tripNodeActivity.activityCd === TripNodeActivityCd.DEPART_DISPATCH) === -1;
      if (status === TripNodeStatusCd.COMPLETED) {
        return 'stop-complete';
      } else if (inProgress) {
        return 'stop-in-progress';
      } else {
        return 'stop-incomplete';
      }
    };

    const stopTypeFunc = (activities: Activity[]): string => {
      const activityTypes = _countBy(activities, (a) => a.tripNodeActivity.activityCd);

      delete activityTypes[TripNodeActivityCd.ARRIVE];
      delete activityTypes[TripNodeActivityCd.DEPART_DISPATCH];

      return Object.keys(activityTypes).length > 1 ? 'MX' : Object.keys(activityTypes)[0];
    };

    stops.forEach((stop) => {
      try {
        const stopStatus = _get(stop, 'tripNode.statusCd', '');
        const customerName = _get(stop, 'customer.name1');

        let arrivalDateTime = _get(stop, 'tripNode.estimatedArriveDateTimeLocal');
        let departureDateTime = _get(stop, 'tripNode.estimatedDepartDateTimeLocal');

        if (stopStatus === TripNodeStatusCd.COMPLETED) {
          const arrivalActivity = _get(stop, 'activities', []).find(
            (activity: Activity) => activity.tripNodeActivity.activityCd === TripNodeActivityCd.ARRIVE
          );
          const departureActivity = _get(stop, 'activities', []).find(
            (activity: Activity) => activity.tripNodeActivity.activityCd === TripNodeActivityCd.DEPART_DISPATCH
          );

          if (arrivalActivity) {
            arrivalDateTime = _get(arrivalActivity, 'tripNodeActivity.actualActivityDateTimeLocal', '');
          }

          if (departureActivity) {
            departureDateTime = _get(departureActivity, 'tripNodeActivity.actualActivityDateTimeLocal', '');
          }
        }

        if (arrivalDateTime && departureDateTime && customerName) {
          // NOTE: We can ignore timezone here, as long as we are consistant throughout the timeline
          const startTime = this.timeService.to24Time(arrivalDateTime);
          const endTime = this.timeService.to24Time(departureDateTime);

          dataSet.add({
            id: `trip-${this.params.data.route.tripInstId}-route-${this.params.data.routeInstId}-stop-${_get(
              stop,
              'tripNode.tripNodeSequenceNbr'
            )}`,
            start: this.convertToVisDateTime(arrivalDateTime),
            end: this.convertToVisDateTime(departureDateTime),
            title: `<div class="item-hint">${stopTypeFunc(
              _get(stop, 'activities', [])
            )} - ${customerName} from ${startTime} to ${endTime}</div>`,
            className: stopColorFunc(_get(stop, 'activities', []), stopStatus),
          });

          const lowerStartTimeCandidate = moment(this.convertToVisDateTime(arrivalDateTime));
          const upperEndTimeCandidate = moment(this.convertToVisDateTime(departureDateTime));

          if (!lowerStartTime || (!!lowerStartTime && lowerStartTimeCandidate.isBefore(lowerStartTime))) {
            lowerStartTime = lowerStartTimeCandidate;
          }

          if (!upperEndTime || (!!upperEndTime && upperEndTimeCandidate.isAfter(upperEndTime))) {
            upperEndTime = upperEndTimeCandidate;
          }
        }
      } catch (error) {
        console.log(error);
      }
    });

    if (stops.length > 1) {
      dataSet.add({
        id: `timeline-range`,
        start: lowerStartTime._i,
        end: upperEndTime._i,
        className: 'time-between',
      });
    }

    const routeStartEnd = {
      id: `trip-${this.params.data.route.tripInstId}-route-${this.params.data.routeInstId}`,
      start: `${moment().format('YYYY-MM-DD')} ${this.params.data.route.deliveryRouteDepartTime}`,
      end: `${moment(this.params.data.route.estimatedClearTime).format('YYYY-MM-DD HH:mm')}`,
      type: 'background',
      className: 'route',
    };

    dataSet.add(routeStartEnd);

    return dataSet;
  }

  private convertToVisDateTime(dateTime: Date): string {
    return `${moment(this.planDate).format('YYYY-MM-DD')} ${this.timeService.formatDate(dateTime, 'HH:mm:00')}`;
  }

  private buildTimelineOptions(startDate: moment.Moment, endDate: moment.Moment): TimelineOptions {
    const timeAxis: TimelineTimeAxisOption = {
      scale: 'hour',
      step: 1,
    };

    const tooltip: TimelineTooltipOption = {
      followMouse: true,
      overflowMethod: 'cap',
    };

    const options = {
      autoResize: true,
      end: endDate.format('YYYY-MM-DD HH:mm:ss'),
      format: {
        minorLabels: {
          hour: '',
        },
      },
      margin: {
        item: 20,
      },
      maxHeight: '39px',
      moveable: false,
      selectable: false,
      showMajorLabels: false,
      showMinorLabels: false,
      stack: false,
      start: startDate.format('YYYY-MM-DD HH:mm:ss'),
      timeAxis: timeAxis,
      tooltip: tooltip,
      zoomable: false,
    };

    return options;
  }
}
