import { DriverStoreState } from './driver-store';
import { GeoAreaStoreState } from './geo-area-store';
import { GeoLocationStoreState } from './geo-location-store';
import { GlobalFilterStoreState } from './global-filters-store';
import { PlanningProfilesStoreState } from './planning-profiles-store';
import { RouteBalancingState } from './route-balancing-store';
import { RoutesStoreState } from './routes-store';
import { TripsStoreState } from './trips-store';
import { UnassignedDeliveriesStoreState } from './unassigned-deliveries-store';
import { UnassignedPickupsStoreState } from './unassigned-pickups-store';

export interface State {
  readonly [GlobalFilterStoreState.StateKey]: GlobalFilterStoreState.State;
  readonly [RoutesStoreState.StateKey]: RoutesStoreState.State;
  readonly [UnassignedDeliveriesStoreState.StateKey]: UnassignedDeliveriesStoreState.State;
  readonly [UnassignedPickupsStoreState.StateKey]: UnassignedPickupsStoreState.State;
  readonly [GeoAreaStoreState.StateKey]: GeoAreaStoreState.State;
  readonly [DriverStoreState.StateKey]: DriverStoreState.State;
  readonly [RouteBalancingState.StateKey]: RouteBalancingState.State;
  readonly [TripsStoreState.StateKey]: TripsStoreState.State;
  readonly [GeoLocationStoreState.StateKey]: GeoLocationStoreState.State;
  readonly [PlanningProfilesStoreState.StateKey]: PlanningProfilesStoreState.State;
}

export const initialState: State = {
  [GlobalFilterStoreState.StateKey]: { ...GlobalFilterStoreState.initialState },
  [RoutesStoreState.StateKey]: { ...RoutesStoreState.initialState },
  [UnassignedDeliveriesStoreState.StateKey]: { ...UnassignedDeliveriesStoreState.initialState },
  [UnassignedPickupsStoreState.StateKey]: { ...UnassignedPickupsStoreState.initialState },
  [GeoAreaStoreState.StateKey]: { ...GeoAreaStoreState.initialState },
  [DriverStoreState.StateKey]: { ...DriverStoreState.initialState },
  [RouteBalancingState.StateKey]: { ...RouteBalancingState.initialState },
  [TripsStoreState.StateKey]: { ...TripsStoreState.initialState },
  [GeoLocationStoreState.StateKey]: { ...GeoLocationStoreState.initialState },
  [PlanningProfilesStoreState.StateKey]: { ...PlanningProfilesStoreState.initialState },
};
