import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../core';
import { ReleaseNotesUpdateService } from '../inbound-planning/shared/services/release-notes-update.service';

@Component({
  selector: 'xpo-footer',
  templateUrl: './xpo-footer.component.html',
  styleUrls: ['./xpo-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class XpoFooterComponent implements OnInit {
  trainingResourcesLink: string;
  buildVersion: string;
  releaseNotesLink: string;
  copyrightYear: number;
  constructor(
    private configManagerService: ConfigManagerService,
    public releaseNotesUpdateService: ReleaseNotesUpdateService
  ) {
    this.copyrightYear = new Date().getFullYear();
  }

  ngOnInit() {
    this.trainingResourcesLink = this.configManagerService.getSetting(ConfigManagerProperties.trainingResourcesLink);
    this.buildVersion = this.configManagerService.getSetting(ConfigManagerProperties.buildVersion);
    this.releaseNotesLink = this.configManagerService.getSetting(ConfigManagerProperties.releaseNotesLink);
  }

  handleReleaseNotesClick(): void {
    this.releaseNotesUpdateService.dismissNewReleaseNotes().subscribe();

    window.open(this.releaseNotesLink);
  }
}
