import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';
import { PndDialogService } from '../../../core/dialogs/pnd-dialog.service';
import { LayoutPreferenceService } from '../../../shared/layout-preference/services/layout-preference.service';
import { UserPreferencesService } from '../../inbound-planning/shared/services/user-preferences.service';
import {
  LoadPlanningProfilesAction,
  SetPlanningProfilesStoreAction,
} from '../planning-profiles-store/planning-profiles-store.actions';
import { SetBoundingSearchArea } from '../unassigned-deliveries-store/unassigned-deliveries-store.actions';
import { ActionTypes, ResetPreferences, SetSicAction } from './global-filters-store.actions';

@Injectable()
export class GlobalFiltersStoreEffects {
  constructor(
    private actions$: Actions,
    private pndDialogService: PndDialogService,
    private userPreferencesService: UserPreferencesService,
    private layoutPreferenceService: LayoutPreferenceService
  ) {}

  // Clear bounds and selections when SIC changes
  @Effect()
  setSic$: Observable<Action> = this.actions$.pipe(
    ofType<SetSicAction>(ActionTypes.setSic),
    concatMap(() => {
      return [
        new SetBoundingSearchArea({ boundingSearchArea: [] }),
        new SetPlanningProfilesStoreAction({ planningProfiles: [] }),
        new LoadPlanningProfilesAction(),
      ];
    })
  );

  @Effect({ dispatch: false })
  resetPreferences$: Observable<Action> = this.actions$.pipe(
    ofType<ResetPreferences>(ActionTypes.resetPreferences),
    switchMap(() => {
      this.pndDialogService
        .showConfirmCancelDialog(
          'Clear Customizations',
          `Would you like to reset all layouts and tabs to their default state?`,
          'NO',
          'YES'
        )
        .subscribe((result) => {
          if (result) {
            this.layoutPreferenceService.activeLayout = undefined;
            this.userPreferencesService.resetUserPreferences().subscribe(() => {
              this.layoutPreferenceService.loadLayoutsFromStorage();
            });
          }
        });
      return EMPTY;
    })
  );
}
