import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, StateKey } from './unassigned-pickups-store.state';

export const unassignedPickupsState = createFeatureSelector<State>(StateKey);

export const searchCriteria = createSelector(unassignedPickupsState, (state: State) => state.criteria);

export const unassignedPickupsSelected = createSelector(
  unassignedPickupsState,
  (state: State) => state.selectedPickups
);

export const unassignedPickupsFocused = createSelector(unassignedPickupsState, (state: State) => state.focusedPickup);

export const unassignedPickupsBoundingSearchArea = createSelector(
  unassignedPickupsState,
  (state: State) => state.boundingSearchArea
);
