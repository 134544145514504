import { ValidatorFn, AbstractControl } from '@angular/forms';
import { isEmpty as _isEmpty, padStart as _padStart, padEnd as _padEnd, size as _size } from 'lodash';
import * as moment from 'moment-timezone';
import { TimeFormatErrorEnum } from '../enums/time-format-error.enum';
import { TimeValidationEnum } from '../enums/time-validation.enum';
import { TimeInputModel } from '../models/time-input.model';

export class TimeUtil {
  static TimeFormatValidatorRegExp = '(([0-1][0-9]|[2][0-3]):([0-5][0-9]))';
  static TimeFormatValidatorRegExpWithAnchors = `^${TimeUtil.TimeFormatValidatorRegExp}$`;
  static TimeProgressiveFormatValidatorRegExp = `([0-2]|[0-1][0-9]|[2][0-3]|[0-1][0-9][:]|[2][0-3][:]|[0-1][0-9][:][0-5]|[2][0-3][:][0-5]|[0-1][0-9][:][0-5][0-9]|[2][0-3][:][0-5][0-9])`;
  static TimeProgressiveFormatValidatorRegExpWithAnchors = `^${TimeUtil.TimeProgressiveFormatValidatorRegExp}$`;
  static FullDateTimeMomentFormat = `YYYY-MM-DD HH:mm`;
  static FullDateMomentFormat = `YYYY-MM-DD`;

  static inputTimeFormControlEnlightening(timeInput: TimeInputModel): TimeInputModel {
    const regExpProgressiveMatch = new RegExp(TimeUtil.TimeProgressiveFormatValidatorRegExpWithAnchors);

    const separatorRegExp = new RegExp(/[,]|[;]|[:]|[.]/);

    const separatorWithColonRepeatedRegExp = new RegExp(/[,]|[;]|[:]{2}|[.]/);

    const startingDigitForTwoDigitsMinuteWithHourWithoutColon = new RegExp(/^([0-1][0-9][0-5]|[2][0-3][0-5])$/);

    const nonStartingDigitForTwoDigitsMinuteWithHourWithoutColon = new RegExp(/^([0-1][0-9][6-9]|[2][0-3][6-9])$/);

    const nonStartingDigitForTwoDigitsMinuteWithHourWithColon = new RegExp(/^([0-1][0-9][:][6-9]|[2][0-3][:][6-9])$/);

    const nonStartingDigitForTwoDigitsHour = /^([3-9])$/;

    if (!regExpProgressiveMatch.test(timeInput.nextValue)) {
      if (!separatorRegExp.test(timeInput.previousValue) && separatorRegExp.test(timeInput.nextValue)) {
        return TimeUtil.fixSeparatorInputPositionAndReplaceByColon(
          new TimeInputModel({
            previousValue: timeInput.previousValue,
            nextValue: timeInput.nextValue,
          })
        );
      } else if (
        separatorRegExp.test(timeInput.previousValue) &&
        separatorWithColonRepeatedRegExp.test(timeInput.nextValue)
      ) {
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        return timeInput;
      } else if (startingDigitForTwoDigitsMinuteWithHourWithoutColon.test(timeInput.nextValue)) {
        timeInput.previousValue = `${timeInput.nextValue.substr(0, 2)}:${timeInput.nextValue.substr(2, 1)}`;
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        return timeInput;
      } else if (nonStartingDigitForTwoDigitsMinuteWithHourWithoutColon.test(timeInput.nextValue)) {
        timeInput.previousValue = `${timeInput.nextValue.substr(0, 2)}:0${timeInput.nextValue.substr(2, 1)}`;
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        return timeInput;
      } else if (nonStartingDigitForTwoDigitsMinuteWithHourWithColon.test(timeInput.nextValue)) {
        timeInput.previousValue = `${timeInput.nextValue.substr(0, 2)}:0${timeInput.nextValue.substr(3, 1)}`;
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        return timeInput;
      } else if (nonStartingDigitForTwoDigitsHour.test(timeInput.nextValue)) {
        timeInput.previousValue = `0${timeInput.nextValue.substr(0, 1)}:`;
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        return timeInput;
      } else if (timeInput.previousValue === '' && timeInput.nextValue === '') {
        return timeInput;
      } else {
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        return timeInput;
      }
    } else if (!separatorRegExp.test(timeInput.previousValue) && separatorRegExp.test(timeInput.nextValue)) {
      return TimeUtil.fixSeparatorInputPositionAndReplaceByColon(
        new TimeInputModel({
          previousValue: timeInput.previousValue,
          nextValue: timeInput.nextValue,
        })
      );
    } else {
      timeInput.previousValue = timeInput.nextValue;
      return timeInput;
    }
  }

  static fixSeparatorInputPositionAndReplaceByColon(timeInput: TimeInputModel): TimeInputModel {
    // deleting next value reference
    const auxNextValue = timeInput.nextValue + '';
    delete timeInput.nextValue;
    timeInput.nextValue = auxNextValue;

    const separatorIndex: number = TimeUtil.indexOfSeparator(timeInput.nextValue);
    switch (separatorIndex) {
      case 0:
        timeInput.previousValue = `00:${timeInput.nextValue.substr(3, 2)}`;
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        break;

      case 1:
        timeInput.previousValue = `0${timeInput.nextValue.substr(0, 1)}:${timeInput.nextValue.substr(3, 2)}`;
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        break;

      case 2:
        timeInput.previousValue = `${timeInput.nextValue.substr(0, 2)}:${timeInput.nextValue.substr(3, 2)}`;
        timeInput.nextValue = TimeValidationEnum.ShouldReplace;
        break;

      default:
        timeInput.previousValue = timeInput.nextValue;
        timeInput.nextValue = '';
        break;
    }
    return timeInput;
  }

  static indexOfSeparator(value: string): number {
    return value.indexOf(':') > -1
      ? value.indexOf(':')
      : value.indexOf(',') > -1
      ? value.indexOf(',')
      : value.indexOf(';') > -1
      ? value.indexOf(';')
      : value.indexOf('.') > -1
      ? value.indexOf('.')
      : -1;
  }

  static formatStringToTimeStringByFillingWithZeros(invalidTime: string): string {
    invalidTime = invalidTime.replace(/:/g, '');

    invalidTime = _size(invalidTime) === 3 ? (invalidTime = `0${invalidTime}`) : invalidTime;
    const hours = invalidTime.substr(0, 2);
    const minutes = invalidTime.substr(2, 2);

    return `${_padStart(`${hours}`, 2, '0')}:${_padEnd(`${minutes}`, 2, '0')}`;
  }

  static timeFormatValidatorFunction(): ValidatorFn {
    return TimeUtil.timeFormatValidation;
  }

  static timeFormatValidation(control: AbstractControl): { [key: string]: any } | null {
    const validTimeRegExp = new RegExp(TimeUtil.TimeFormatValidatorRegExpWithAnchors);
    const val = control.value;
    // validate the control value is in the form "HH:MM",
    // where HH is between [00, 23], and MM is between [00, 59]
    return !_isEmpty(val)
      ? !validTimeRegExp.test(val) || val === '00:00'
        ? { [TimeFormatErrorEnum.InvalidFormat]: true }
        : null
      : null;
  }

  static timeFormatValidationWithLocalDate(
    value: string,
    planDate: Date,
    timeZone: string,
    now?: Date
  ): { [key: string]: any } | null {
    if (_isEmpty(value)) {
      return null; // handled by required validator!
    }

    if (value === '00:00') {
      return { required: true };
    }

    const validTimeRegExp = new RegExp(TimeUtil.TimeFormatValidatorRegExpWithAnchors);
    // validate the control value is in the form "HH:MM",
    // where HH is between [00, 23], and MM is between [00, 59]
    if (!validTimeRegExp.test(value)) {
      return { [TimeFormatErrorEnum.InvalidFormat]: true };
    }

    const valOnTimezone = moment(`${moment(planDate).format(TimeUtil.FullDateMomentFormat)} ${value}`)
      .seconds(0)
      .milliseconds(0)
      .format(TimeUtil.FullDateTimeMomentFormat);

    const _now = now ? moment(`${moment(now).format(TimeUtil.FullDateTimeMomentFormat)}`) : moment();

    const nowAsString = _now
      .seconds(0)
      .milliseconds(0)
      .tz(timeZone)
      .format(TimeUtil.FullDateTimeMomentFormat);

    const isBefore = moment(valOnTimezone).isBefore(nowAsString);

    return isBefore ? { [TimeFormatErrorEnum.InvalidFormat]: true } : null;
  }
}
