import { LatLngLiteral } from '@agm/core';
import { Injectable } from '@angular/core';
import { LatLong } from '@xpo-ltl/sdk-common';
import { forEach as _forEach, get as _get, size as _size } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { InteractiveMapMarker } from '../models/markers/map-marker';
import { MapMarkersService } from './map-markers.service';

@Injectable({ providedIn: 'root' })
export class MappingService {
  constructor(private markersService: MapMarkersService) {}

  private geoPoints: Array<LatLong> = [];
  private geoPointsSubject = new BehaviorSubject<LatLong[]>(undefined);
  readonly geoPoints$ = this.geoPointsSubject.asObservable();

  private geoAreaObjectsArray = new BehaviorSubject(undefined);
  readonly geoAreasMaster$ = this.geoAreaObjectsArray.asObservable();

  addGeoAreaPolygons(geoAreaResponse: any): void {
    const data = geoAreaResponse.geoArea;
    const names = [];
    const geoAreaPolygons: Array<Array<LatLngLiteral>> = [[]];

    const geoObjectsArray: any = [];

    data.forEach((geo) => {
      const bounds = new google.maps.LatLngBounds();
      const geoAreaPolygon: Array<LatLngLiteral> = [];

      geo.polygon.forEach((item) => {
        const coord = { lat: item.latitude, lng: item.longitude };
        geoAreaPolygon.push(coord); // Add each coordinate
        bounds.extend(coord);
      });

      geoAreaPolygons.push(geoAreaPolygon); // Add each polygon

      const marker = new InteractiveMapMarker();
      marker.icon = this.markersService.getGeoAreaIcon(geo.geoAreaName);
      marker.latitude = bounds.getCenter().lat();
      marker.longitude = bounds.getCenter().lng();
      names.push(marker);

      geoObjectsArray.push({
        marker: marker,
        area: geoAreaPolygon,
        strokeColor: this.markersService.getStringToHslColor(geo.geoAreaName, 100, 25),
        fillColor: this.markersService.getStringToHslColor(geo.geoAreaName, 100, 80),
        fillOpacity: 0.4,
        strokeWeight: 1,
      });
    });

    this.geoAreaObjectsArray.next(geoObjectsArray);
  }

  addGeoPoint(point: LatLong): void {
    this.geoPoints.push(point);
    this.geoPointsSubject.next(this.geoPoints);
  }

  removeGeoPoint(point: LatLong): void {
    // TODO: Need impl
  }

  updateMarkersBounds(items: { latitudeNbr: number; longitudeNbr: number }[], map: google.maps.Map) {
    const mapBounds = map.getBounds();
    let boundsExtended = false;

    _forEach(items, (item) => {
      const lat = _get(item, 'latitudeNbr');
      const lng = _get(item, 'longitudeNbr');
      if (lat && lng) {
        const coordinates = { lat, lng };
        if (!mapBounds.contains(coordinates)) {
          // when hovering, items will come only with that individual hovered item, so we just pan to it
          if (_size(items) === 1) {
            map.panTo(coordinates);

            // when selecting, we need to account for multiple items, hence extending bounds
          } else {
            mapBounds.extend(coordinates);
            boundsExtended = true;
          }
        }
      }
    });

    if (boundsExtended) {
      map.fitBounds(mapBounds);
      map.setCenter(mapBounds.getCenter());
    }
  }
}
