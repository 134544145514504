import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Activity, Stop } from '@xpo-ltl/sdk-cityoperations';
import { ShipmentSpecialServiceCd, TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { countBy as _countBy, forEach as _forEach, get as _get, map as _map, uniq as _uniq } from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
import { ActivityTypePipe } from '../../../../pipes';
import { SpecialServicesService } from '../../../../services';

export interface ActivitySummaryLine {
  type: string;
  ready: string;
  close: string;
  arrival: string;
  departure: string;
  dwell: string;
  bills: number;
  lp: number;
  mm: number;
  weight: string;
  cube: string;
  specialServicesSummary: ShipmentSpecialServiceCd[];
}

@Component({
  selector: 'pnd-route-details',
  templateUrl: './route-details.component.html',
  styleUrls: ['./route-details.component.scss'],
  host: { class: 'pnd-RouteDetails' },
})
export class RouteDetailsComponent implements OnInit {
  columns = [
    { id: 'type', label: 'Type', renderer: (row: ActivitySummaryLine) => this.activityTypePipe.transform(row.type) },
    // { id: 'ready', label: 'Ready', renderer: (row: ActivitySummaryLine) => row.ready },
    // { id: 'close', label: 'Close', renderer: (row: ActivitySummaryLine) => row.close },
    { id: 'arrival', label: 'Arrival', renderer: (row: ActivitySummaryLine) => row.arrival },
    { id: 'departure', label: 'Departure', renderer: (row: ActivitySummaryLine) => row.departure },
    { id: 'dwell', label: 'Dwell Time', renderer: (row: ActivitySummaryLine) => row.dwell },
    { id: 'bills', label: 'Bills', renderer: (row: ActivitySummaryLine) => row.bills },
    { id: 'mm', label: 'MM', renderer: (row: ActivitySummaryLine) => row.mm },
    { id: 'weight', label: 'Weight (lbs.)', renderer: (row: ActivitySummaryLine) => row.weight },
    { id: 'cube', label: 'Cube', renderer: (row: ActivitySummaryLine) => row.cube },
    { id: 'specialServicesSummary', label: 'Special Services' },
  ];
  columnsToDisplay = _map(this.columns, (c) => c.id);

  private dataSourceSubject = new BehaviorSubject<MatTableDataSource<ActivitySummaryLine>>(undefined);
  dataSource$ = this.dataSourceSubject.asObservable();

  seqNbr = '';

  @Input()
  stop: Stop;

  @Output()
  viewStopDetails = new EventEmitter<Stop>();

  constructor(
    private timeService: XpoLtlTimeService,
    private decimalPipe: DecimalPipe,
    private activityTypePipe: ActivityTypePipe,
    private specialServicesService: SpecialServicesService
  ) {}

  ngOnInit() {
    let bills = 0;
    let lp = 0;
    let mm = 0;
    let weight = 0;
    let cube = 0;

    const activityTypes = _countBy(_get(this.stop, 'activities', []), (a) =>
      _get(a, 'tripNodeActivity.activityCd', '')
    );
    delete activityTypes[TripNodeActivityCd.ARRIVE];
    delete activityTypes[TripNodeActivityCd.DEPART_DISPATCH];

    const arrivalActivity = <Activity>(
      _get(this.stop, 'activities', []).find((a) => a.tripNodeActivity.activityCd === TripNodeActivityCd.ARRIVE)
    );

    const departureActivity = <Activity>(
      _get(this.stop, 'activities', []).find(
        (a) => a.tripNodeActivity.activityCd === TripNodeActivityCd.DEPART_DISPATCH
      )
    );

    const servicesAccumulator: ShipmentSpecialServiceCd[] = _get(this.stop, 'specialServicesSummary')
      ? this.specialServicesService.getSpecialServicesForSummary(this.stop.specialServicesSummary)
      : [];

    _forEach(this.stop.activities, (activity: Activity) => {
      bills += _get(activity, 'tripNodeActivity.totalBillCount', 0);
      lp += _get(activity, 'tripNodeActivity.totalPiecesCount', 0);
      mm += _get(activity, 'tripNodeActivity.totalMmCount', 0);
      weight += _get(activity, 'tripNodeActivity.totalWeightCount', 0);
      cube += _get(activity, 'tripNodeActivity.totalCubePercentage', 0);

      if (this.seqNbr === '') {
        this.seqNbr = _get(activity, 'routeShipment.routeSequenceNbr', '');
      }
    });

    const activitySummary = {
      type: Object.keys(activityTypes).length > 1 ? 'MX' : Object.keys(activityTypes)[0],
      ready: '',
      close: '',
      arrival: arrivalActivity
        ? this.timeService.to24Time(arrivalActivity.tripNodeActivity.actualActivityDateTimeLocal)
        : '',
      departure: departureActivity
        ? this.timeService.to24Time(departureActivity.tripNodeActivity.actualActivityDateTimeLocal)
        : '',
      dwell:
        arrivalActivity && departureActivity
          ? moment(departureActivity.tripNodeActivity.actualActivityDateTimeLocal).diff(
              arrivalActivity.tripNodeActivity.actualActivityDateTimeLocal,
              'minutes'
            )
          : '',
      bills: bills,
      lp: lp,
      mm: mm,
      weight: this.decimalPipe.transform(weight),
      cube: this.decimalPipe.transform(cube, '1.1-2'),
      specialServicesSummary: servicesAccumulator,
    };
    this.dataSourceSubject.next(new MatTableDataSource([activitySummary]));
  }

  viewStopDetailsClicked(): void {
    this.viewStopDetails.emit(this.stop);
  }
}
