import { Injectable } from '@angular/core';
import { cloneDeep as _cloneDeep } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { ROUTE_COLORS } from './route-colors';

@Injectable({
  providedIn: 'root',
})
export class RouteColorService {
  private assignedColors: Map<number, string>;
  private readonly colorChangedSubject = new BehaviorSubject<{ routeInstId: number; color: string }>(undefined);
  readonly colorChanged$ = this.colorChangedSubject.asObservable();

  constructor() {
    this.clear();
  }

  /**
   * Clear all assigned colors
   */
  clear() {
    this.assignedColors = new Map<number, string>();
  }

  /**
   * Returns color assigned to this route. Assigns a new color if one has not already been assigned
   * to this route.
   */
  getColorForRoute = (routeInstId: number): string => this.assignedColors.get(routeInstId);

  private getLessRepeatedColor(): string {
    let lessRepeatedColor;
    let notUsedColorFound = false;
    for (let i = 0; i < ROUTE_COLORS.length && !notUsedColorFound; i++) {
      let count = 0;
      this.assignedColors.forEach((assignedColor: string) => {
        if (ROUTE_COLORS[i] === assignedColor) {
          count++;
        }
      });

      if (count === 0) {
        notUsedColorFound = true;
      }
      if (i === 0 || count === 0 || count < lessRepeatedColor.count) {
        lessRepeatedColor = {
          code: ROUTE_COLORS[i],
          count: count,
        };
      }
    }

    return lessRepeatedColor && lessRepeatedColor.code;
  }

  getUnassignedColors(): string[] {
    let allColors = _cloneDeep(ROUTE_COLORS);
    this.assignedColors.forEach((assignedColor: string) => {
      allColors = allColors.filter((color) => color !== assignedColor);
    });
    return allColors;
  }

  /**
   * Removes the association between a route and a specific color
   */
  clearRouteColor(routeInstId: number): void {
    const routeColor = this.assignedColors.get(routeInstId);
    if (routeColor) {
      this.assignedColors.delete(routeInstId);
    }
    this.colorChangedSubject.next(undefined);
  }

  /**
   * Adds an association between a route and a specific color
   */
  setRouteColor(routeInstId: number): string {
    if (!this.assignedColors.has(routeInstId)) {
      const nextColor = this.getLessRepeatedColor();
      this.assignedColors.set(routeInstId, nextColor);
      return nextColor;
    } else {
      return this.getColorForRoute(routeInstId);
    }
  }

  changeRouteColor(routeInstId: number, newColor: string) {
    this.assignedColors.set(routeInstId, newColor);
    this.colorChangedSubject.next({ routeInstId, color: newColor });
  }
}
