import { Injectable } from '@angular/core';
import {
  CityOperationsApiService,
  EvaluatePnDRouteBalancingResp,
  EvaluatePnDRouteBalancingRqst,
  Metric,
  RouteBalancingTrip,
  RouteBalancingRoute,
} from '@xpo-ltl/sdk-cityoperations';
import { get as _get } from 'lodash';
import { Observable } from 'rxjs';
import {
  BalancingMetricCategory,
  RouteBalancingMetrics,
  RouteBalancingMetricsComparator,
} from '../../route-balancing-metrics/route-balancing-metrics.interface';

@Injectable({
  providedIn: 'root',
})
export class RouteBalancingMessagingService {
  private cachedMetrics: Metric[] = [];
  private currentTrips: RouteBalancingTrip[] = [];
  constructor(private cityOperationsService: CityOperationsApiService) {}

  /**
   * Calculate metrics
   * @param request
   */
  getMetrics(request: EvaluatePnDRouteBalancingRqst): Observable<RouteBalancingMetrics> {
    return new Observable((observer) => {
      this.cityOperationsService.evaluatePnDRouteBalancing(request).subscribe(
        (response: EvaluatePnDRouteBalancingResp) => {
          const metrics: RouteBalancingMetricsComparator[] = [];
          const tripsSelectedAreTheSame: boolean = this.tripsEquality(this.currentTrips, response.trips);

          if (!tripsSelectedAreTheSame) {
            this.cachedMetrics = response.metrics;
          }

          _get(response, 'metrics', []).forEach((metric: Metric) => {
            let before: Metric;
            const after: Metric = metric;
            const category: BalancingMetricCategory = {
              description: metric.description,
              goal: metric.goal,
            };

            if (tripsSelectedAreTheSame && response.trips.length > 0) {
              before = this.cachedMetrics.filter(
                (currentMetric) => metric.description === currentMetric.description
              )[0];
            } else {
              before = metric;
            }

            metrics.push({ category, before, after });
          });

          this.currentTrips = response.trips;

          observer.next({ metrics: metrics, trips: response.trips });
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  resetMetrics(): void {
    this.cachedMetrics = [];
    this.currentTrips = [];
  }

  /**
   * Returns true if the given trips are the same
   * @param previous
   * @param current
   */
  private tripsEquality(previous: RouteBalancingTrip[], current: RouteBalancingTrip[]): boolean {
    let equal = previous.length === current.length;

    if (equal) {
      for (let i = 0; i < current.length; i++) {
        const currentTrip = current[i];
        const previousTrip = previous.find((trip) => this.routeEquality(trip.routes[0], currentTrip.routes[0]));

        if (!previousTrip) {
          equal = false;
          break;
        }
      }
    }

    return equal;
  }

  /**
   * Returns true if the given routes are the same
   * @param previousRoute
   * @param currentRoute
   */
  private routeEquality(previousRoute: RouteBalancingRoute, currentRoute: RouteBalancingRoute): boolean {
    let equal = false;

    if (previousRoute && currentRoute) {
      equal =
        `${previousRoute.routePrefix}-${previousRoute.routeSuffix}` ===
        `${currentRoute.routePrefix}-${currentRoute.routeSuffix}`;
    }

    return equal;
  }
}
