import { Injectable, Type } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { PndBroadcastMessage } from '../models/broadcast-message.model';

@Injectable({
  providedIn: 'root',
})
export class PndBroadcastingService {
  private messageCache: Map<string, string> = new Map<string, string>();

  /**
   * Returns an observable of the broadcast chanel message event match your entered model.
   *
   * @param {Type<T>} model Model of the event you are trying to listen to
   */
  listen<T extends PndBroadcastMessage>(model: Type<T>): Observable<T> {
    // Create broadcast channel with the name of the chanel being the model name
    const channel = new BroadcastChannel(model.name);

    // if the message is the same as the last one, received ignore it
    return fromEvent(channel, 'message').pipe(
      map((message: MessageEvent) => <T>message.data),
      filter((data: T) => this.messageCache.get(model.name) !== JSON.stringify(data)),
      tap((data: T) => this.messageCache.set(model.name, JSON.stringify(data)))
    );
  }

  broadcastMessage<T extends PndBroadcastMessage>(message: T): void {
    const channel = new BroadcastChannel(message.messageId());

    // make sure we don't echo back
    this.messageCache.set(message.messageId(), JSON.stringify(message));

    channel.postMessage(message);
    channel.close();
  }
}
