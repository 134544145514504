import { Injectable } from '@angular/core';
import { Route, Stop } from '@xpo-ltl/sdk-cityoperations';
import { TripNodeStatusCd } from '@xpo-ltl/sdk-common';
import {
  filter as _filter,
  find as _find,
  first as _first,
  flatten as _flatten,
  forEach as _forEach,
  forOwn as _forOwn,
  get as _get,
  isEqual as _isEqual,
  last as _last,
  map as _map,
  nth as _nth,
  size as _size,
  slice as _slice,
  some as _some,
  sortBy as _sortBy,
} from 'lodash';
import { BehaviorSubject, Observable, of, timer, zip, fromEvent } from 'rxjs';
import { catchError, delayWhen, retryWhen, take } from 'rxjs/operators';
import { NumberToValueMap } from '../../../../../store/number-to-value-map';
import { RouteColorService } from '../../../../shared/services/route-color.service';

export enum LegRenderType {
  solid = 'solid',
  dashed = 'dashed',
}

/**
 * Used to render a Leg of a Route.
 * A Leg is the path between a start and end location on the map
 *
 */
interface RouteLeg {
  routeInstId: number;
  completed: boolean;
  polyline: google.maps.Polyline;
  eventListeners: google.maps.MapsEventListener[];
  start: google.maps.LatLngLiteral;
  end: google.maps.LatLngLiteral;
  distance: google.maps.Distance;
  startIndex: number;
  endIndex: number;

  leg?: google.maps.DirectionsLeg;
}

/**
 * Represents a Route containing Stops that can be rendered on the Map
 */
export interface RouteStopsRenderInfo {
  route: Route;
  stops: Stop[];
  color: string;

  legs: RouteLeg[]; // each Leg is the path between two Stops
  midpoint: google.maps.LatLngLiteral; // apporximate location along path between first and last stop in Route
}

export interface RightClickEvent {
  latLng: google.maps.LatLng;
  ya: MouseEvent;
}

export interface RightClickSubject {
  routeInstId: number;
  location: google.maps.LatLngLiteral;
  event: RightClickEvent;
}

export interface StopLocation {
  location: google.maps.LatLngLiteral;
  stopIndex: number;
}

type StopLocations = StopLocation[][];

const DEFAULT_STROKE_WIDTH: number = 4;
const MAX_WAYPOINTS_PER_CHUNK = 25; // max number of waypoints to send in one route call to Google

@Injectable({
  providedIn: 'root',
})
export class RouteRenderingService {
  private gmDirectionsService: google.maps.DirectionsService;
  private directionsServiceCache: { id: string; directionResult: google.maps.DirectionsResult }[] = [];
  private DIRECTIONS_SERVICE_CACHE_MAX_SIZE = 100;
  routeStopsRenderInfos: RouteStopsRenderInfo[] = [];

  private hoverOverRouteSubject = new BehaviorSubject<{
    routeInstId: number;
    location: google.maps.LatLngLiteral;
  }>(undefined);
  hoverOverRoute$ = this.hoverOverRouteSubject.asObservable();

  private clickRouteSubject = new BehaviorSubject<{
    routeInstId: number;
    location: google.maps.LatLngLiteral;
  }>(undefined);
  clickRoute$ = this.clickRouteSubject.asObservable();
  private rightClickRouteSubject = new BehaviorSubject<RightClickSubject>(undefined);
  rightClickRoute$ = this.rightClickRouteSubject.asObservable();

  constructor(private routeColorService: RouteColorService) {}

  /**
   * Returns a new Polyline Options with specified properties
   */
  private polylineOptions(
    color: string,
    type: LegRenderType,
    opacity: number = 1.0,
    strokeWidth: number = DEFAULT_STROKE_WIDTH
  ): google.maps.PolylineOptions {
    const options: google.maps.PolylineOptions = {
      strokeColor: color,
      strokeWeight: strokeWidth,
      visible: true,
    };

    if (type === LegRenderType.solid) {
      options.strokeOpacity = opacity;
      options.icons = [
        { icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW, scale: 2.0 }, offset: '100%', repeat: '100px' },
      ];
    } else {
      options.strokeOpacity = 0;
      options.icons = [
        {
          icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW, strokeOpacity: opacity, scale: 2.0 },
          offset: '100%',
          repeat: '100px',
        },
        { icon: { path: 'M 0,-1 0,1', strokeOpacity: opacity, scale: 1.5 }, offset: '0', repeat: '10px' },
      ];
    }
    return options;
  }

  /**
   * Returns true if the start and end Stops in the Route are marked as COMPLETED.
   */
  private isLegCompleted(start: Stop, end: Stop): boolean {
    const completed =
      _get(start, `tripNode.statusCd`) === TripNodeStatusCd.COMPLETED ||
      _get(end, `tripNode.statusCd`) === TripNodeStatusCd.COMPLETED;

    return completed;
  }

  /**
   * Called when the user moves the mouse over a leg in the route
   */
  private onMouseOverRoute(routeInstId: number, location: google.maps.LatLngLiteral) {
    this.hoverOverRouteSubject.next({ routeInstId, location });
  }

  /**
   * Called when the user moves the mouse off of a leg in the route
   */
  private onMouseOutRoute() {
    this.hoverOverRouteSubject.next(undefined);
  }

  /**
   * Called when the user click a leg in the route
   */
  private onClickRoute(routeInstId: number, location: google.maps.LatLngLiteral) {
    this.clickRouteSubject.next({ routeInstId, location });
  }

  private onRightClickRoute(routeInstId: number, location: google.maps.LatLngLiteral, event) {
    this.rightClickRouteSubject.next({ routeInstId, location, event });
  }

  /**
   * Set the blur state of the Route polylines. A blurred route is 50% transparent.
   */
  blurRoute(routeInfo: RouteStopsRenderInfo, isBlurred: boolean) {
    const opacity = isBlurred ? 0.5 : 1.0;

    const activeOptions = this.polylineOptions(routeInfo.color, LegRenderType.solid, opacity);
    const completedOptions = this.polylineOptions(routeInfo.color, LegRenderType.dashed, opacity);

    _forEach(routeInfo.legs, (leg) => {
      const options = leg.completed ? completedOptions : activeOptions;
      leg.polyline.setOptions(options);
    });
  }

  /**
   * Stop rendering the Polyline segments in the Route and clear data
   * @param routeInfo Route to clear
   * @param hard if true, deletes the Polylines as well.
   */
  private clearRoutePolylines(routeInfo: RouteStopsRenderInfo) {
    _forEach(routeInfo.legs, (leg) => {
      _forEach(leg.eventListeners, (listener) => listener.remove());
      leg.eventListeners = undefined;
      leg.polyline.setMap(null);
      leg.polyline = undefined;
    });
    routeInfo.legs = [];
  }

  /**
   * Hids all routes that don't match one of the passed routeInstIds
   */
  private hideRoutesExcept(routeInstIds: number[]) {
    _forEach(this.routeStopsRenderInfos, (routeInfo) => {
      if (!_some(routeInstIds, (routeInstId: number) => routeInstId === routeInfo.route.routeInstId)) {
        this.updateVisibility(routeInfo, false, false, null);
      }
    });
  }

  /**
   * shows/hides the route lines, and optionally the completed legs
   */
  updateVisibility(
    renderInfo: RouteStopsRenderInfo,
    visible: boolean,
    showCompletedLegs: boolean,
    googleMap: google.maps.Map
  ) {
    _forEach(renderInfo.legs, (leg) => {
      const isVisible = visible && (!leg.completed || (leg.completed && showCompletedLegs));
      leg.polyline.setMap(isVisible ? googleMap : null);
    });
  }

  /**
   * Update visibility for each RouteRenderInfo
   */
  updateAllVisibility(visible: boolean, showCompletedLegs: boolean, googleMap: google.maps.Map) {
    _forEach(this.routeStopsRenderInfos, (renderInfo) => {
      this.updateVisibility(renderInfo, visible, showCompletedLegs, googleMap);
    });
  }

  /**
   * Returns the Lat/Lng of the Stop as a LatLngLiteral
   */
  private getLatLng(stop: Stop): google.maps.LatLngLiteral {
    return {
      lat: stop.customer.latitudeNbr,
      lng: stop.customer.longitudeNbr,
    };
  }

  /**
   * Split stops into chunks of max size.  If more then 1 chunk, then
   * first stop in chunk equal last stop in previous chunk
   * If chunkSize < 2, then returns a single chunk with all stops
   */
  private chunkStops(stops: Stop[], chunkSize: number): StopLocations {
    // convert stops into StopLocations
    const stopsLocations: StopLocation[] = _map(stops, (stop, stopIndex) => {
      return {
        location: this.getLatLng(stop),
        stopIndex,
      };
    });

    if (chunkSize < 2) {
      // can't chunk into arrays less then 2
      return [stopsLocations];
    }

    const stopChunks = [];
    let chunk = [];
    for (let ii = 0; ii < stopsLocations.length; ii += 1) {
      if (_size(chunk) === chunkSize) {
        stopChunks.push(chunk);
        chunk = [_last(chunk)];
      }
      chunk.push(stopsLocations[ii]);
    }
    stopChunks.push(chunk);

    return stopChunks;
  }

  /**
   * Get the Legs from Google Directions Service connecting the passed array of StopLocations
   */
  private getLegs(
    routeInstId: number,
    stops: StopLocation[],
    routeStops: Stop[],
    chunkId: number
  ): Observable<{ chunkId: number; legs: RouteLeg[] }> {
    if (_size(stops) === 0) {
      // no stops, so can't create any legs
      return of({ chunkId, legs: [] });
    } else {
      return new Observable((observer) => {
        // Request directions for each chunk in the route
        const directonsRequest: google.maps.DirectionsRequest = {
          origin: _first(stops).location,
          destination: _last(stops).location,
          waypoints: _map(_slice(stops, 1, _size(stops) - 1), (wps) => {
            return {
              location: wps.location,
              stopover: true,
            } as google.maps.DirectionsWaypoint;
          }),
          optimizeWaypoints: false,
          travelMode: google.maps.TravelMode.DRIVING,
        };

        if (!this.gmDirectionsService) {
          this.gmDirectionsService = new google.maps.DirectionsService();
        }

        // Remove oldest cached direction result
        if (this.directionsServiceCache.length >= this.DIRECTIONS_SERVICE_CACHE_MAX_SIZE) {
          this.directionsServiceCache.shift();
        }

        const cachedDirectionResult = this.directionsServiceCache.find(
          (cached) => cached.id === JSON.stringify(directonsRequest)
        );

        if (cachedDirectionResult) {
          const computedLegs = this.computeLegs(routeInstId, stops, routeStops, cachedDirectionResult.directionResult);
          this.directionsServiceCache.push({
            id: JSON.stringify(directonsRequest),
            directionResult: cachedDirectionResult.directionResult,
          });
          observer.next({ chunkId, legs: computedLegs });
        } else {
          this.gmDirectionsService.route(directonsRequest, (directionResult, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              const computedLegs = this.computeLegs(routeInstId, stops, routeStops, directionResult);
              this.directionsServiceCache.push({
                id: JSON.stringify(directonsRequest),
                directionResult: directionResult,
              });
              observer.next({ chunkId, legs: computedLegs });
            } else {
              // Failed to find Route render interface.  Most likely because the route was missing geocoordinates and
              // was never rendered.  We display THAT error in planning-map, so just reject this.
              observer.error(status);
            }
          });
        }
      });
    }
  }

  private computeLegs(
    routeInstId: number,
    stops: StopLocation[],
    routeStops: Stop[],
    directionResult: google.maps.DirectionsResult
  ): RouteLeg[] {
    const legs: RouteLeg[] = [];
    const routeColor = this.routeColorService.getColorForRoute(routeInstId);
    const activeOptions = this.polylineOptions(routeColor, LegRenderType.solid);
    const completedOptions = this.polylineOptions(routeColor, LegRenderType.dashed);

    // collect all parts of the path into a single path for a polyline
    // each Leg is a path from one Stop to another.
    // for exampl: if we have Stops 0,1,2,3 we will end up with legs (0,1), (1,2), (2,3)
    // there will ALWAYS be one less Leg then number of Stops.
    _forEach(directionResult.routes[0].legs, (leg, legIndex: number) => {
      const startStopIndex = stops[legIndex].stopIndex;
      let endStopIndex = startStopIndex;
      if (legIndex + 1 < _size(stops)) {
        endStopIndex = stops[legIndex + 1].stopIndex;
      }

      const completed = this.isLegCompleted(routeStops[startStopIndex], routeStops[endStopIndex]);

      // gather all the points that make up the segments for this Leg
      let pathPoints: google.maps.LatLng[] = [];
      _forEach(leg.steps, (step) => {
        pathPoints = pathPoints.concat(step.path);
      });

      const polyline = new google.maps.Polyline(completed ? completedOptions : activeOptions);
      polyline.setPath(pathPoints);
      polyline.setMap(null);

      const eventListeners = [
        polyline.addListener('mouseover', (event) => {
          this.onMouseOverRoute(routeInstId, { lat: event.latLng.lat(), lng: event.latLng.lng() });
        }),

        polyline.addListener('click', (event) => {
          this.onClickRoute(routeInstId, { lat: event.latLng.lat(), lng: event.latLng.lng() });
        }),

        polyline.addListener('rightclick', (event) => {
          fromEvent(document, 'contextmenu')
            .pipe(take(1))
            .subscribe((e) => {
              let listenerEvt: MouseEvent;
              Object.values(event).forEach((key) => {
                if (key && key instanceof MouseEvent) {
                  listenerEvt = key;
                }
              });
              const rclickevent: RightClickEvent = {
                latLng: event.latLng,
                ya: listenerEvt ? listenerEvt : new MouseEvent('rightclick', event),
              };
              this.onRightClickRoute(routeInstId, { lat: event.latLng.lat(), lng: event.latLng.lng() }, rclickevent);
              e.preventDefault();
              e.stopPropagation();
            });
        }),

        polyline.addListener('mouseout', (event) => {
          this.onMouseOutRoute();
        }),
      ];

      const routeLeg: RouteLeg = {
        routeInstId,
        startIndex: legIndex,
        endIndex: legIndex + 1,
        start: { lat: leg.start_location.lat(), lng: leg.start_location.lng() },
        end: { lat: leg.end_location.lat(), lng: leg.end_location.lng() },
        distance: leg.distance,
        completed,
        polyline,
        eventListeners,
        leg,
      };
      legs.push(routeLeg);
    });

    return legs;
  }

  /**
   * Update the RouteLegs for the passed RouteStopsRenderInfo, clearing any existing data.
   *
   * For large numbers of Stops (>25), we need to break the route request to Google into
   * chunks and manually combine them. This is done by setting the origin of the next chunk
   * to the destination of the previous.
   */
  private generateRoute(renderInfo: RouteStopsRenderInfo): Observable<RouteStopsRenderInfo> {
    return new Observable<RouteStopsRenderInfo>((observer) => {
      // split the stops into chunks acceptable to Google
      const stopChunks = this.chunkStops(renderInfo.stops, MAX_WAYPOINTS_PER_CHUNK);

      // begin requesting the RouteLegs
      const chunkObservers = _map(
        stopChunks,
        (chunk, index): Observable<{ chunkId: number; legs: RouteLeg[] }> =>
          this.getLegs(renderInfo.route.routeInstId, chunk, renderInfo.stops, index).pipe(
            retryWhen((errors) =>
              errors.pipe(
                // tap((err) => {
                //   console.log(`Route ${renderInfo.route.routeInstId}: - getLegs failed with: ${err}, Retrying.`);
                // }),
                delayWhen(() => timer(500 + Math.random() * 500)), // delay between .5 and 1 second before retrying
                take(3) // if we can't resolve in 3 retries no sense continuing on
              )
            ),

            catchError((err) => {
              console.error(`Route ${renderInfo.route.routeInstId}: GIVING UP on generating leg with: ${err}`);
              return of(undefined);
            })
          )
      );
      zip(...chunkObservers).subscribe((legChunks) => {
        const legs = _flatten(
          _map(
            _sortBy(
              _filter(legChunks, (lc) => !!lc),
              (o) => o.chunkId
            ),
            (chunk) => chunk.legs
          )
        );

        // clear out any old Polylines after getting the new legs to prevent flikering
        this.clearRoutePolylines(renderInfo);

        // done generating legs for this route
        renderInfo.legs = legs;
        renderInfo.midpoint = this.calculateMidpoint(legs);
        observer.next(renderInfo);
        // console.log(`Route ${renderInfo.route.routeInstId}: Completed generating Legs for Route`);
      });
    });
  }

  /**
   * Generate data necessary to render each of the Selected Routes
   */
  updateRoutes(routes: Route[], stopsForRoutes: NumberToValueMap<Stop[]>): Observable<RouteStopsRenderInfo[]> {
    return new Observable((observer) => {
      // TODO - instead of clearing all the routes, look for routes that
      // keep all pre-generated routes and only delete and regenerate
      // ones where the auditInfo for the route does not match.  This indicates
      // that the route was changes and needs to be regenerated.

      // TODO - keep a map of all generated routes and just reuse the
      // generated data if we can

      // Create render info for each Route
      const stopsRenderInfos: RouteStopsRenderInfo[] = [];
      _forOwn(stopsForRoutes, (stops, routeInstId) => {
        // get the route for this set of stops
        const route = _find(routes, (r) => r.routeInstId === +routeInstId);
        if (route) {
          // filter out stops that are invalid (no customer or no lat/lng), then
          // sort the stops by their sequenceNbr.

          const isRenderableStop = (stop) => {
            const latLng = {
              lat: _get(stop, 'customer.latitudeNbr', 0),
              lng: _get(stop, 'customer.longitudeNbr', 0),
            };
            return (
              !_isEqual(latLng, { lat: 0, lng: 0 }) && stop.tripNode.stopSequenceNbr // stop must be in sequence to generate legs
            );
          };

          const renderableStops: Stop[] = _sortBy(_filter(stops, isRenderableStop), [
            (stop) => stop.tripNode.stopSequenceNbr,
          ]);

          // create initial Route rendering info with valid stops
          const routeColor = this.routeColorService.getColorForRoute(route.routeInstId);
          const routeInfo: RouteStopsRenderInfo = {
            route,
            stops: renderableStops,
            color: routeColor,
            legs: undefined,
            midpoint: { lat: 0, lng: 0 },
          };
          stopsRenderInfos.push(routeInfo);
        }
      });

      // For each Route, generate the Legs that make up the route
      const routeObservers: Observable<RouteStopsRenderInfo>[] = _map(stopsRenderInfos, (renderInfo) =>
        this.generateRoute(renderInfo).pipe(
          catchError((err) => {
            console.error(err);
            return of(undefined);
          })
        )
      );
      if (routeObservers.length === 0) {
        // remove all existing Routes
        const _routeStopsRenderInfos = { ...this.routeStopsRenderInfos };

        _forEach(_routeStopsRenderInfos, (routeInfo) => {
          this.clearRoutePolylines(routeInfo);
        });

        // save the Route that can be rendered
        this.routeStopsRenderInfos = [];
        observer.next(this.routeStopsRenderInfos);
      }
      zip(...routeObservers)
        .pipe(
          catchError((err) => {
            console.error(err);
            return of(err);
          })
        )
        .subscribe((renderInfos: RouteStopsRenderInfo[]) => {
          // remove all existing Routes
          const _routeStopsRenderInfos = { ...this.routeStopsRenderInfos };

          _forEach(_routeStopsRenderInfos, (routeInfo) => {
            this.clearRoutePolylines(routeInfo);
          });

          // save the Route that can be rendered
          this.routeStopsRenderInfos = _filter(renderInfos, (ri) => !!ri);
          observer.next(this.routeStopsRenderInfos);
        });
    });
  }

  /**
   * Return the lat/lng of the middle point in the route Legs.
   * The Midpoint is the middle step in the route
   */
  private calculateMidpoint(legs: RouteLeg[]): google.maps.LatLngLiteral {
    if (_size(legs) === 0) {
      return { lat: 0, lng: 0 };
    }
    const steps = [...legs];

    // sort by distances in step
    steps.sort((a, b) => b.distance.value - a.distance.value);

    // pick second to longest leg as our midpoint
    const index = Math.ceil((_size(steps) - 1) / 2);
    const midStep = _nth(steps, index);
    const midpoint = midStep.start;

    return midpoint;
  }
}
