import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PluralMaps } from '../../classes/plural-maps';

@Component({
  selector: 'refresh-notification-widget',
  templateUrl: './refresh-notification-widget.component.html',
  styleUrls: ['./refresh-notification-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefreshNotificationWidgetComponent {
  @Input() numberOfItemsChanged: number;
  @Input() lastUpdateTime: string;

  @Output() refreshIntent = new EventEmitter<void>();

  readonly PluralMaps = PluralMaps;

  onRefreshIntent(): void {
    this.refreshIntent.emit();
  }
}
