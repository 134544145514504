import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { GeoArea } from '@xpo-ltl-2.0/sdk-location';
import {
  Activity,
  AssignShipmentsToRoutePath,
  AssignShipmentsToRouteRqst,
  CityOperationsApiService,
  CreateNewRouteResp,
  CreateNewRouteRqst,
  DeliveryShipmentSearchRecord,
  ExistingRouteSummary,
  InterfaceAcct,
  ListPnDSuggestedRouteNamesPath,
  ListPnDSuggestedRouteNamesQuery,
  ReassignStopsResp,
  ReassignStopsRqst,
  Route,
  SuggestedRouteName,
  Trip,
  TripNode,
} from '@xpo-ltl/sdk-cityoperations';
import { AuditInfo, RouteCategoryCd, ShipmentId } from '@xpo-ltl/sdk-common';
import { Unsubscriber } from '@xpo/ngx-ltl';
import {
  flatten as _flatten,
  get as _get,
  isEmpty as _isEmpty,
  map as _map,
  orderBy as _orderBy,
  reduce as _reduce,
  result as _result,
  size as _size,
  uniqBy as _uniqBy,
} from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { delay, finalize, map, startWith, take, takeUntil } from 'rxjs/operators';
import { NotificationMessageStatus } from '../../../../../core/enums/notification-message-status.enum';
import { NotificationMessageService } from '../../../../../core/services/notification-message.service';
import { UserRoleService } from '../../../../../core/services/user-role/user-role.service';
import { dynamicValidator } from '../../../../../core/validators/dynamic-validator';
import {
  GlobalFilterStoreSelectors,
  PndStoreState,
  RoutesStoreActions,
  TripsStoreActions,
  UnassignedDeliveriesStoreActions,
  UnassignedDeliveriesStoreSelectors,
} from '../../../../store';
import { RouteBalancingActions } from '../../../../store/route-balancing-store';
import { StoreSourcesEnum } from '../../enums/store-sources.enum';
import { RouteService } from '../../services';
import { AssignToRouteDialogData } from './assign-to-route-dialog-data';
import { AssignToRouteDialogResults } from './assign-to-route-dialog-results';
import { AssignToRouteFormFields } from './assign-to-route-form-fields';
import { AssignToRouteDetail } from './components/assign-to-route-detail/assign-to-route-detail';
import { ExistingOrNewRoute } from './existing-or-new-route.enum';

@Component({
  selector: 'pnd-assign-to-route',
  templateUrl: './assign-to-route.component.html',
  styleUrls: ['./assign-to-route.component.scss'],
  host: { class: 'pnd-AssignToRoute' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignToRouteComponent implements OnInit, OnDestroy {
  @ViewChild('existingRouteInput', { static: true }) existingRouteInput: ElementRef;
  @ViewChild('newRouteAreaInput', { static: true }) newRouteAreaInput: ElementRef;

  formGroup: FormGroup;

  private showSpinnerSubject = new BehaviorSubject<boolean>(false);
  readonly showSpinner$ = this.showSpinnerSubject.asObservable();
  private detailsSubject = new BehaviorSubject<AssignToRouteDetail[]>(undefined);
  readonly details$ = this.detailsSubject.asObservable();
  private existingRoutes: ExistingRouteSummary[] = [];
  private existingRouteNames: string[] = [];
  existingRoutesFiltered$: Observable<string[]>;
  private newRouteAreasSubject = new BehaviorSubject<GeoArea[]>([]);
  newRouteAreasFiltered$ = this.newRouteAreasSubject.asObservable();
  private suggestedRouteNames: SuggestedRouteName[];
  private preferredAreas: number[] = [];
  private totalShipments = 0;
  private readonly planningRoutes = ['LG', 'OSD', 'TRAP', 'CME'];
  private sicCd: string;
  private planDate: Date;
  private readonly unsubscriber = new Unsubscriber();

  readonly ExistingOrNewRoute = ExistingOrNewRoute;
  readonly RouteCategoryCd = RouteCategoryCd;
  readonly AssignToRouteFormFields = AssignToRouteFormFields;

  readonly characterNumberHyphenPattern = '^[0-9a-zA-Z-]$';
  readonly characterNumberPattern = '^[0-9a-zA-Z]$';
  readonly characterPattern = '^[a-zA-Z]$';

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AssignToRouteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AssignToRouteDialogData,
    private cityOpsService: CityOperationsApiService,
    private pndStore$: Store<PndStoreState.State>,
    private userRoleService: UserRoleService,
    private notificationMessageService: NotificationMessageService,
    private routeService: RouteService
  ) {}

  ngOnInit() {
    combineLatest([
      this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic),
      this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterPlanDate),
    ])
      .pipe(take(1))
      .subscribe(([sicCd, planDate]) => {
        this.sicCd = sicCd;
        this.planDate = planDate;
      });

    this.formGroup = this.formBuilder.group({
      [AssignToRouteFormFields.ExistingOrNew]: [],
      [AssignToRouteFormFields.ExistingRoute]: [
        '',
        [
          dynamicValidator(() => this.isExistingRouteOptionSelected(), Validators.required),
          dynamicValidator(() => this.isExistingRouteOptionSelected(), this.validateExistingRouteInput.bind(this)),
        ],
      ],
      [AssignToRouteFormFields.RouteCategoryCd]: [RouteCategoryCd.DELIVERY],
      [AssignToRouteFormFields.NewRouteArea]: [
        '',
        [
          dynamicValidator(() => this.isNewRouteOptionSelected(), Validators.required),
          dynamicValidator(() => this.isNewRouteOptionSelected(), this.validateNewRouteAreaInput.bind(this)),
        ],
      ],
      [AssignToRouteFormFields.NewRouteSuffix]: [
        '',
        [dynamicValidator(() => this.isNewRouteOptionSelected(), Validators.required)],
      ],
    });

    this.formGroup
      .get(AssignToRouteFormFields.ExistingOrNew)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done))
      .subscribe((value) => {
        this.toggleExistingOrNewRoute(value);
      });

    this.formGroup
      .get(AssignToRouteFormFields.RouteCategoryCd)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done))
      .subscribe((value) => {
        if (value === RouteCategoryCd.PLANNING) {
          this.newRouteAreasSubject.next(this.planningRoutes.map((r) => ({ ...new GeoArea(), geoAreaName: r })));

          setTimeout(() => {
            this.newRouteAreaInput.nativeElement.focus();
          });
        } else {
          let shipmentsArr = [];
          if (this.data.selectedShipments || this.data.selectedPlanningShipments) {
            shipmentsArr = _flatten(
              Array.from(
                this.data.selectedShipments && this.data.selectedShipments.values()
                  ? this.data.selectedShipments.values()
                  : this.data.selectedPlanningShipments.values()
              )
            );
          } else if (this.data.selectedStops) {
            shipmentsArr = _map(this.data.selectedStops, (stop) => stop.tripNode);
          }

          this.newRouteAreasSubject.next(
            this.sortSuggestedRouteNamesByArea(
              this.suggestedRouteNames,
              _uniqBy(shipmentsArr, (shipment) => shipment.areaInstId)
            )
          );

          setTimeout(() => {
            this.newRouteAreaInput.nativeElement.focus();
          });
        }
        if (this.formGroup.get(AssignToRouteFormFields.NewRouteArea).enabled) {
          this.formGroup.get(AssignToRouteFormFields.NewRouteArea).setValue('');
        }
      });

    this.formGroup
      .get(AssignToRouteFormFields.NewRouteArea)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done))
      .subscribe((value) => {
        const area = (value || '').toUpperCase();
        const categoryCd: RouteCategoryCd = this.formGroup.get(AssignToRouteFormFields.RouteCategoryCd).value;
        const suggestedRoute = this.suggestedRouteNames.find(
          (s) => s.categoryCd === categoryCd && s.geoArea.geoAreaName === area
        );

        const setSuffix = (suffix) => {
          this.formGroup.get(AssignToRouteFormFields.NewRouteSuffix).setValue(suffix);
        };

        setSuffix(suggestedRoute ? suggestedRoute.suggestedSuffix : '');
      });

    this.existingRoutesFiltered$ = this.formGroup.get(AssignToRouteFormFields.ExistingRoute).valueChanges.pipe(
      takeUntil(this.unsubscriber.done),
      startWith(''),
      map((v) => (v ? this.filterExistingRoutes(v) : this.existingRouteNames.slice()))
    );

    this.newRouteAreasFiltered$ = this.formGroup.get(AssignToRouteFormFields.NewRouteArea).valueChanges.pipe(
      takeUntil(this.unsubscriber.done),
      startWith(''),
      map((name: string) =>
        name
          ? this.orderByPreferredArea(this.filterNewRouteAreas(name))
          : this.orderByPreferredArea(this.newRouteAreasSubject.value.slice())
      )
    );

    this.fetchRouteNames();

    this.detailsSubject.next(this.getDetails());
  }

  getDetails(): Array<AssignToRouteDetail> {
    const details: Array<AssignToRouteDetail> = [];
    if (this.data.selectedShipments) {
      this.data.selectedShipments.forEach((shipments) => {
        const consignee = <InterfaceAcct>_result(shipments, '[0].consignee', {});
        details.push({
          stopName: `${consignee.name1}`,
          fullAddress: `${consignee.addressLine1}, ${consignee.cityName} ${consignee.stateCd} ${consignee.postalCd}`,
          shipmentCount: shipments.length,
        });
        shipments.forEach((shipment) => {
          if (!this.preferredAreas.includes(shipment.areaInstId)) {
            this.preferredAreas.push(shipment.areaInstId);
          }
        });
        this.totalShipments += shipments.length;
      });
    } else if (_size(this.data.selectedStops) > 0) {
      this.data.selectedStops.forEach((selectedStop) => {
        const totalShipments = _reduce(
          selectedStop.activities,
          (count, activity) => {
            return count + _get(activity, 'tripNodeActivity.totalBillCount', 0);
          },
          0
        );

        const consignee = <InterfaceAcct>_get(selectedStop, 'customer', {});
        details.push({
          stopName: consignee.name1,
          fullAddress: `${consignee.addressLine1}, ${consignee.cityName} ${consignee.stateCd} ${consignee.postalCd}`,
          shipmentCount: totalShipments,
        });
      });
      this.preferredAreas.push(_get(this.data.selectedStops[0], 'tripNode.areaInstId'));
    } else if (this.data.selectedPlanningShipments) {
      this.data.selectedPlanningShipments.forEach((shipments) => {
        const consignee = <InterfaceAcct>_result(shipments, '[0].consignee', {});
        details.push({
          stopName: `${consignee.name1}`,
          fullAddress: `${consignee.addressLine1}, ${consignee.cityName} ${consignee.stateCd} ${consignee.postalCd}`,
          shipmentCount: shipments.length,
        });
        shipments.forEach((shipment) => {
          if (!this.preferredAreas.includes(shipment.areaInstId)) {
            this.preferredAreas.push(shipment.areaInstId);
          }
        });
        this.totalShipments += shipments.length;
      });
    }
    return details;
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  assignClicked(): void {
    this.pndStore$.dispatch(
      new RouteBalancingActions.SetCanOpenRouteBalancing({
        canOpenRouteBalancing: false,
      })
    );

    if (this.data.selectedShipments) {
      this.assignShipments();
    } else if (_size(this.data.selectedStops) > 0) {
      this.assignStop();
    } else if (this.data.selectedPlanningShipments) {
      this.assignPlanningShipments();
    }
  }

  cancelClicked(): void {
    this.dialogRef.close(false);
  }

  private getFormInputValues(): AssignToRouteDialogResults {
    const existingOrNewRoute = <ExistingOrNewRoute>this.formGroup.get(AssignToRouteFormFields.ExistingOrNew).value;
    const route: string = this.isExistingRouteOptionSelected()
      ? this.formGroup.get(AssignToRouteFormFields.ExistingRoute).value
      : `${this.formGroup.get(AssignToRouteFormFields.NewRouteArea).value}-${
          this.formGroup.get(AssignToRouteFormFields.NewRouteSuffix).value
        }`.toUpperCase();
    const results: AssignToRouteDialogResults = {
      existingOrNewRoute: existingOrNewRoute,
      categoryCd: this.isExistingRouteOptionSelected()
        ? RouteCategoryCd.DELIVERY
        : <RouteCategoryCd>this.formGroup.get(AssignToRouteFormFields.RouteCategoryCd).value,
      routePrefix: route.split('-')[0],
      routeSuffix: route.split('-')[1],
      routeDate: undefined,
      terminalSicCd: undefined,
      selectedShipments: this.data.selectedShipments || this.data.selectedPlanningShipments,
    };
    return results;
  }

  private assignShipments(): void {
    const results = this.getFormInputValues();
    results.terminalSicCd = this.sicCd;
    results.routeDate = moment(this.planDate).format('YYYY-MM-DD');

    const shipmentIds: ShipmentId[] = [];
    results.selectedShipments.forEach((shipment: DeliveryShipmentSearchRecord[]) => {
      const currentShipmentIds: ShipmentId[] = shipment.map((s) => {
        return {
          shipmentInstId: s.shipmentInstId.toString(),
          proNumber: s.proNbr,
          pickupDate: null,
        };
      });
      shipmentIds.push(...currentShipmentIds);
    });

    if (this.isExistingRouteOptionSelected()) {
      this.showSpinnerSubject.next(true);

      this.existingRoutes.forEach((existingRoute) => {
        let satelliteSic = '';
        if (existingRoute.sicCd !== this.sicCd) {
          satelliteSic = `/${existingRoute.sicCd}`;
        }
        if (
          `${existingRoute.routePrefix}-${existingRoute.routeSuffix}${satelliteSic}` ===
          this.formGroup.get(AssignToRouteFormFields.ExistingRoute).value
        ) {
          results.routeInstId = existingRoute.routeInstId;
        }
      });

      const request = new AssignShipmentsToRouteRqst();
      request.shipmentIds = shipmentIds;
      request.overrideTdcInd = true;

      const params = new AssignShipmentsToRoutePath();
      params.sicCd = results.terminalSicCd;
      params.routeInstId = results.routeInstId;

      this.cityOpsService
        .assignShipmentsToRoute(request, params)
        .pipe(finalize(() => this.showSpinnerSubject.next(false)))
        .subscribe(
          () => {
            this.pndStore$
              .select(UnassignedDeliveriesStoreSelectors.searchCriteria)
              .pipe(take(1))
              .subscribe((criteria) => {
                this.pndStore$.dispatch(new UnassignedDeliveriesStoreActions.SetSearchCriteria({ criteria }));
              });

            this.updateTripsGrid();
            this.updatePlanningRoutesGrid();
            this.updateUnassignedDeliveriesGrid();

            this.dialogRef.close(true);

            this.notificationMessageService
              .openNotificationMessage(
                NotificationMessageStatus.Success,
                `${this.totalShipments} ${this.totalShipments > 1 ? 'shipments have' : 'shipment has'} ` +
                  `been assigned to existing route ${results.routePrefix}-${results.routeSuffix}`
              )
              .subscribe(() => {});
          },
          (error) => {
            this.showSpinnerSubject.next(false);

            console.error('error', JSON.stringify(error));
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, error)
              .subscribe(() => {});

            this.pndStore$.dispatch(
              new RouteBalancingActions.SetCanOpenRouteBalancing({
                canOpenRouteBalancing: true,
              })
            );
          }
        );
    } else if (this.isNewRouteOptionSelected()) {
      this.showSpinnerSubject.next(true);

      this.createNewRoute(results.routePrefix, results.routeSuffix, results.categoryCd, shipmentIds)
        .pipe(finalize(() => this.showSpinnerSubject.next(false)))
        .subscribe(
          () => {
            this.pndStore$
              .select(UnassignedDeliveriesStoreSelectors.searchCriteria)
              .pipe(take(1))
              .subscribe((criteria) => {
                this.pndStore$.dispatch(new UnassignedDeliveriesStoreActions.SetSearchCriteria({ criteria }));
              });

            this.updateTripsGrid();
            this.updatePlanningRoutesGrid();
            this.updateUnassignedDeliveriesGrid();

            this.dialogRef.close(true);

            this.notificationMessageService
              .openNotificationMessage(
                NotificationMessageStatus.Success,
                `${this.totalShipments} ${this.totalShipments > 1 ? 'shipments have' : 'shipment has'} ` +
                  `been assigned to new route ${results.routePrefix}-${results.routeSuffix}`
              )
              .subscribe(() => {});
          },
          (error) => {
            this.showSpinnerSubject.next(false);

            console.error('error', JSON.stringify(error));
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, error)
              .subscribe(() => {});

            this.pndStore$.dispatch(
              new RouteBalancingActions.SetCanOpenRouteBalancing({
                canOpenRouteBalancing: true,
              })
            );
          }
        );
    } else {
      this.notificationMessageService
        .openNotificationMessage(NotificationMessageStatus.Error, `Error: Unknown selected option`)
        .subscribe(() => {});

      this.pndStore$.dispatch(
        new RouteBalancingActions.SetCanOpenRouteBalancing({
          canOpenRouteBalancing: true,
        })
      );
    }
  }

  private assignStop(): void {
    const results = this.getFormInputValues();
    results.terminalSicCd = this.sicCd;
    results.routeDate = moment(this.planDate).format('YYYY-MM-DD');

    const reassignStopFunc = (createNewRouteResp?: CreateNewRouteResp): Observable<ReassignStopsResp> => {
      let newRouteInstId: number;
      let newTripInstId: number;
      if (createNewRouteResp) {
        newRouteInstId = _get(createNewRouteResp, 'route.routeInstId');
        newTripInstId = _get(createNewRouteResp, 'trip.tripInstId');
      } else {
        this.existingRoutes.forEach((existingRoute) => {
          let satelliteSic = '';
          if (existingRoute.sicCd !== this.sicCd) {
            satelliteSic = `/${existingRoute.sicCd}`;
          }
          if (
            `${existingRoute.routePrefix}-${existingRoute.routeSuffix}${satelliteSic}` ===
            this.formGroup.get(AssignToRouteFormFields.ExistingRoute).value
          ) {
            newRouteInstId = existingRoute.routeInstId;
            newTripInstId = existingRoute.tripInstId;
          }
        });
      }

      let oldRouteInstId = 0;
      let oldTripInstId = 0;
      this.data.selectedStops.forEach((selectedStop) => {
        if (!_isEmpty(_get(selectedStop, 'tripNode'))) {
          oldTripInstId = selectedStop.tripNode.tripInstId;
        }
        _get(selectedStop, 'activities', []).forEach((activity: Activity) => {
          if (_get(activity, 'routeShipment')) {
            oldRouteInstId = activity.routeShipment.routeInstId;
          }
        });
      });

      const request = new ReassignStopsRqst(); // In these requests he only wants route instance id and trip instance id.
      request.newRoute = { ...new Route(), routeInstId: newRouteInstId };
      request.newTrip = { ...new Trip(), tripInstId: newTripInstId };
      request.oldRoute = { ...new Route(), routeInstId: oldRouteInstId };
      request.oldTrip = { ...new Trip(), tripInstId: oldTripInstId };
      request.tripNode = this.data.selectedStops.map((selectedStop) => {
        return {
          ...new TripNode(),
          tripNodeSequenceNbr: _get(selectedStop, 'tripNode.tripNodeSequenceNbr'),
        };
      });
      request.auditInfo = this.createAuditInfo();
      return this.cityOpsService.reassignStops(request).pipe(finalize(() => this.showSpinnerSubject.next(false)));
    };

    if (this.isExistingRouteOptionSelected()) {
      this.showSpinnerSubject.next(true);

      reassignStopFunc().subscribe(
        () => {
          this.updateTripsGrid();
          this.updatePlanningRoutesGrid();
          this.updateUnassignedDeliveriesGrid();

          this.dialogRef.close(true);

          this.notificationMessageService
            .openNotificationMessage(
              NotificationMessageStatus.Success,
              `Stop ${this.data.selectedStops[0].customer.name1} has been assigned to existing route ` +
                `${results.routePrefix}-${results.routeSuffix}`
            )
            .subscribe(() => {});
        },
        (error) => {
          this.showSpinnerSubject.next(false);

          console.error('error', JSON.stringify(error));
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Error, error)
            .subscribe(() => {});

          this.pndStore$.dispatch(
            new RouteBalancingActions.SetCanOpenRouteBalancing({
              canOpenRouteBalancing: true,
            })
          );
        }
      );
    } else if (this.isNewRouteOptionSelected()) {
      this.showSpinnerSubject.next(true);

      this.createNewRoute(results.routePrefix, results.routeSuffix, results.categoryCd).subscribe(
        (response) => {
          reassignStopFunc(response).subscribe(
            () => {
              this.updateTripsGrid();
              this.updatePlanningRoutesGrid();
              this.updateUnassignedDeliveriesGrid();

              this.dialogRef.close(true);

              this.notificationMessageService
                .openNotificationMessage(
                  NotificationMessageStatus.Success,
                  `Stop ${this.data.selectedStops[0].customer.name1} has been assigned to new route ` +
                    `${results.routePrefix}-${results.routeSuffix}`
                )
                .subscribe(() => {});
            },
            (error) => {
              this.showSpinnerSubject.next(false);

              console.error('error', JSON.stringify(error));
              this.notificationMessageService
                .openNotificationMessage(NotificationMessageStatus.Error, error)
                .subscribe(() => {});

              this.pndStore$.dispatch(
                new RouteBalancingActions.SetCanOpenRouteBalancing({
                  canOpenRouteBalancing: true,
                })
              );
            }
          );
        },
        (error) => {
          this.showSpinnerSubject.next(false);

          console.error('error', JSON.stringify(error));
          this.notificationMessageService
            .openNotificationMessage(NotificationMessageStatus.Error, error)
            .subscribe(() => {});

          this.pndStore$.dispatch(
            new RouteBalancingActions.SetCanOpenRouteBalancing({
              canOpenRouteBalancing: true,
            })
          );
        }
      );
    } else {
      this.notificationMessageService
        .openNotificationMessage(NotificationMessageStatus.Error, `Error: Unknown selected option`)
        .subscribe(() => {});

      this.pndStore$.dispatch(
        new RouteBalancingActions.SetCanOpenRouteBalancing({
          canOpenRouteBalancing: true,
        })
      );
    }
  }

  private assignPlanningShipments(): void {
    const results = this.getFormInputValues();
    results.terminalSicCd = this.sicCd;
    results.routeDate = moment(this.planDate).format('YYYY-MM-DD');

    const prevRouteInstId = this.data.selectedPlanningShipments.values().next().value[0].routeInstId;

    const shipmentIds: ShipmentId[] = [];
    results.selectedShipments.forEach((shipment: DeliveryShipmentSearchRecord[]) => {
      const currentShipmentIds: ShipmentId[] = shipment.map((s) => {
        return {
          shipmentInstId: s.shipmentInstId.toString(),
          proNumber: s.proNbr,
          pickupDate: null,
        };
      });
      shipmentIds.push(...currentShipmentIds);
    });

    const reassignShipmentsFunc = (
      oldRouteInstId: number,
      newRouteName: string,
      newRouteInstId: number,
      shipmentList: ShipmentId[]
    ) => {
      this.routeService
        .reassignShipments(oldRouteInstId, newRouteName, newRouteInstId, shipmentList)
        .pipe(take(1))
        .subscribe(
          () => {
            this.updateTripsGrid();
            this.updatePlanningRoutesGrid();
            this.updateUnassignedDeliveriesGrid();

            this.dialogRef.close(true);
          },
          (error) => {
            this.showSpinnerSubject.next(false);

            console.error('error', JSON.stringify(error));
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, error)
              .subscribe(() => {});

            this.pndStore$.dispatch(
              new RouteBalancingActions.SetCanOpenRouteBalancing({
                canOpenRouteBalancing: true,
              })
            );
          }
        );
    };

    if (this.isExistingRouteOptionSelected()) {
      this.showSpinnerSubject.next(true);

      const routeName = this.formGroup.get(AssignToRouteFormFields.ExistingRoute).value;

      this.existingRoutes.forEach((existingRoute) => {
        if (`${existingRoute.routePrefix}-${existingRoute.routeSuffix}` === routeName) {
          results.routeInstId = existingRoute.routeInstId;
        }
      });

      reassignShipmentsFunc(prevRouteInstId, routeName, results.routeInstId, shipmentIds);
    } else if (this.isNewRouteOptionSelected()) {
      this.showSpinnerSubject.next(true);

      this.createNewRoute(results.routePrefix, results.routeSuffix, results.categoryCd)
        .pipe(take(1))
        .subscribe(
          (response) => {
            reassignShipmentsFunc(
              prevRouteInstId,
              `${results.routePrefix}-${results.routeSuffix}`,
              _get(response, 'route.routeInstId'),
              shipmentIds
            );
          },
          (error) => {
            this.showSpinnerSubject.next(false);

            console.error('error', JSON.stringify(error));
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, error)
              .subscribe(() => {});

            this.pndStore$.dispatch(
              new RouteBalancingActions.SetCanOpenRouteBalancing({
                canOpenRouteBalancing: true,
              })
            );
          }
        );
    } else {
      this.notificationMessageService
        .openNotificationMessage(NotificationMessageStatus.Error, `Error: Unknown selected option`)
        .subscribe(() => {});

      this.pndStore$.dispatch(
        new RouteBalancingActions.SetCanOpenRouteBalancing({
          canOpenRouteBalancing: true,
        })
      );
    }
  }

  private createNewRoute(
    routePrefix: string,
    routeSuffix: string,
    categoryCd: RouteCategoryCd,
    shipmentIds?: ShipmentId[]
  ): Observable<CreateNewRouteResp> {
    const suggestedRoute = this.suggestedRouteNames.find((s) => _get(s, 'geoArea.geoAreaName') === routePrefix);

    const request = {
      ...new CreateNewRouteRqst(),
      shipmentList: shipmentIds,
      overrideTdcInd: true,
      areaInstId: _get(suggestedRoute, 'geoArea.areaInstId'),

      route: {
        ...new Route(),
        routePrefix: routePrefix,
        routeSuffix: routeSuffix,
        routeDate: moment(this.planDate).format('YYYY-MM-DD'),
        categoryCd: categoryCd,
        terminalSicCd: this.sicCd,
      },
    };

    // NOTE: required to induce a delay between the creating of a new route and any
    //       actions on it so that the BE has time to sync the data.
    return this.cityOpsService.createNewRoute(request).pipe(delay(250));
  }

  /**
   * Used to create audit info for proxy calls. Uses the current date time and the employee id to create the audit info object.
   * TODO we want the backend to populate the updateById. Passing back the original modified time is planned.
   */
  private createAuditInfo(): AuditInfo {
    const auditInfo = new AuditInfo();
    auditInfo.updatedTimestamp = new Date();
    auditInfo.updateById = _result(this.userRoleService, 'user.employeeId');
    return auditInfo;
  }

  private filterExistingRoutes(value: string): string[] {
    const valueLower = value.toLowerCase();
    return this.existingRouteNames.filter((v) => v.toLowerCase().indexOf(valueLower) === 0);
  }

  private filterNewRouteAreas(name: string): GeoArea[] {
    const nameLower = name.toLowerCase();
    return this.newRouteAreasSubject.value.filter((g: GeoArea) => g.geoAreaName.toLowerCase().indexOf(nameLower) === 0);
  }

  /**
   * Given a list of raw suggested route names and selected shipments, returns a sorted list with related geo-areas at the beginning.
   * @param suggestedRouteNames
   * @param shipments
   */
  private sortSuggestedRouteNamesByArea(
    suggestedRouteNames: SuggestedRouteName[],
    shipments: DeliveryShipmentSearchRecord[]
  ): GeoArea[] {
    const geoAreas: GeoArea[] = suggestedRouteNames
      .filter((r) => this.planningRoutes.indexOf(_get(r, 'geoArea.geoAreaName')) === -1)
      .map((suggestedRoute) => suggestedRoute.geoArea);

    for (const shipment of shipments) {
      const index = geoAreas.findIndex((geoArea) => Number(geoArea.areaInstId) === shipment.areaInstId);

      if (index >= 0) {
        geoAreas.unshift(geoAreas.splice(index, 1)[0]);
      }
    }

    return geoAreas;
  }

  private toggleExistingOrNewRoute(value: string): void {
    if (value === ExistingOrNewRoute.Existing) {
      this.formGroup.get(AssignToRouteFormFields.ExistingRoute).enable();
      this.existingRouteInput.nativeElement.focus();
      this.formGroup.get(AssignToRouteFormFields.NewRouteArea).disable();
      this.formGroup.get(AssignToRouteFormFields.RouteCategoryCd).disable();
      this.formGroup.get(AssignToRouteFormFields.NewRouteSuffix).disable();
    } else {
      this.formGroup.get(AssignToRouteFormFields.ExistingRoute).disable();
      this.formGroup.get(AssignToRouteFormFields.NewRouteArea).enable();
      this.newRouteAreaInput.nativeElement.focus();
      this.formGroup.get(AssignToRouteFormFields.RouteCategoryCd).enable();
      this.formGroup.get(AssignToRouteFormFields.NewRouteSuffix).enable();
    }
  }

  isExistingRouteOptionSelected(): boolean {
    return (
      this.formGroup && this.formGroup.get(AssignToRouteFormFields.ExistingOrNew).value === ExistingOrNewRoute.Existing
    );
  }

  private isNewRouteOptionSelected(): boolean {
    return this.formGroup && this.formGroup.get(AssignToRouteFormFields.ExistingOrNew).value === ExistingOrNewRoute.New;
  }

  private validateExistingRouteInput(): ValidationErrors | null {
    const item = this.existingRouteNames.find(
      (existingRoute) => existingRoute === this.formGroup.get(AssignToRouteFormFields.ExistingRoute).value.toUpperCase()
    );
    return item ? null : [{ invalid: true }];
  }

  private validateNewRouteAreaInput(): ValidationErrors | null {
    const item = this.newRouteAreasSubject.value.find(
      (newRouteArea) =>
        newRouteArea.geoAreaName === this.formGroup.get(AssignToRouteFormFields.NewRouteArea).value.toUpperCase()
    );
    return item ? null : [{ invalid: true }];
  }

  private fetchRouteNames(): void {
    const path: ListPnDSuggestedRouteNamesPath = {
      sicCd: this.sicCd,
    };

    const query: ListPnDSuggestedRouteNamesQuery = {
      planDate: moment(this.planDate).format('YYYY-MM-DD'),
      satelliteInd: true,
    };

    this.cityOpsService.listPnDSuggestedRouteNames(path, query).subscribe(
      (response) => {
        let oldRouteInstId = 0;
        _get(this.data, 'selectedStops', []).forEach((selectedStop) => {
          _get(selectedStop, 'activities', []).forEach((activity: Activity) => {
            if (_get(activity, 'routeShipment')) {
              oldRouteInstId = activity.routeShipment.routeInstId;
            }
          });
        });

        this.suggestedRouteNames = _get(response, 'suggestedRouteNames', []);
        this.existingRoutes = _get(response, 'existingRouteSummaries', []).filter(
          (route: ExistingRouteSummary) => oldRouteInstId !== route.routeInstId
        );
        const routeNames = [];
        this.existingRoutes.forEach((route) => {
          let satelliteSic = '';
          if (route.sicCd !== this.sicCd) {
            satelliteSic = `/${route.sicCd}`;
          }
          routeNames.push(`${route.routePrefix}-${route.routeSuffix}${satelliteSic}`);
        });
        this.existingRouteNames = _orderBy(routeNames);

        this.formGroup.get(AssignToRouteFormFields.ExistingOrNew).setValue(this.data.initialMode);
      },
      () => {
        this.suggestedRouteNames = [];
        this.existingRouteNames = [];
      }
    );
  }

  private orderByPreferredArea(geoAreas: GeoArea[]): GeoArea[] {
    const orderedGeoAreas: GeoArea[] = [];

    const preferredGeoAreas = geoAreas.filter((g) => this.preferredAreas.includes(+g.areaInstId)) || [];
    _orderBy(preferredGeoAreas, (g) => g.geoAreaName).forEach((g) => orderedGeoAreas.push(g));

    orderedGeoAreas.push({ ...new GeoArea(), geoAreaName: '' });

    const otherGeoAreas = geoAreas.filter((g) => !this.preferredAreas.includes(+g.areaInstId));
    _orderBy(otherGeoAreas, (g) => g.geoAreaName).forEach((g) => orderedGeoAreas.push(g));

    return orderedGeoAreas;
  }

  private updateTripsGrid() {
    this.pndStore$.dispatch(new TripsStoreActions.RefreshTrips());
  }

  private updatePlanningRoutesGrid() {
    this.pndStore$.dispatch(new RoutesStoreActions.RefreshPlanningRoutes());
  }

  private updateUnassignedDeliveriesGrid() {
    this.pndStore$.dispatch(
      new RoutesStoreActions.UpdateUnassignedDeliveriesGridAction({
        updateUnassignedDeliveriesGrid: {
          source: StoreSourcesEnum.ASSIGN_TO_ROUTE_DIALOG,
          date: new Date(),
        },
      })
    );
  }
}
