import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UnmappedInfoWindowAction, GeoLocationActionType } from '../unmapped-stops-layer.component';

@Component({
  selector: 'app-unmapped-stops-marker-infowindow',
  templateUrl: './unmapped-stops-marker-infowindow.component.html',
  styleUrls: ['./unmapped-stops-marker-infowindow.component.scss'],
})
export class UnmappedStopsMarkerInfowindowComponent implements OnInit {
  @Input() message: string;
  @Input() actions: UnmappedInfoWindowAction[];
  @Output() actionSelected: EventEmitter<number> = new EventEmitter();

  readonly GeoLocationActionType = GeoLocationActionType;

  constructor() {}

  ngOnInit() {}

  selectAction(type: number) {
    this.actionSelected.emit(type);
  }
}
