export enum UnassignedPickupsGridHeaders {
  ROW_SELECTED = 'checkbox',
  SOURCE = 'Source',
  TYPE = 'Type',
  SHIPPER = 'Shipper',
  ADDRESS = 'Address',
  CITY = 'City',
  SPECIAL_SERVICES = 'Special Services',
  ZIP_CODE = 'Zip',
  PICKUP_DATE = 'Pickup Date',
  READY_TIME = 'Ready',
  CLOSE_TIME = 'Close',
  LP = 'LP',
  MM = 'MM',
  WEIGHT = 'Weight (lbs.)',
  CUBE = 'Cube',
  ORIGIN_SIC = 'Orig SIC',
  CALL_NBR = 'Call Nbr',
  STATUS = 'Status',
  REMARKS = 'Remarks',
}
