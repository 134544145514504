import { Component, OnInit, Input } from '@angular/core';
import { RouteBalancingMetricsComparator } from '../route-balancing-metrics.interface';

@Component({
  selector: 'pnd-category-column',
  templateUrl: './category-column.component.html',
  styleUrls: ['./category-column.component.scss'],
})
export class CategoryColumnComponent implements OnInit {
  @Input() item: RouteBalancingMetricsComparator;
  @Input() valid: boolean = true;

  constructor() {}

  ngOnInit() {}
}
