import { DeliveryShipmentSearchRecord } from '@xpo-ltl/sdk-cityoperations';
import { GlobalFilterMapCoordinate } from '../../inbound-planning/shared';
import { EventItem, UnassignedDeliveryIdentifier } from '../../inbound-planning/shared/interfaces/event-item.interface';
import { UnassignedDeliveriesSearchCriteria } from './unassigned-deliveries-search-criteria.interface';

export const StateKey = 'unassignedDeliveriesState';

export interface State {
  readonly criteria: UnassignedDeliveriesSearchCriteria;
  readonly lastUpdate: Date;

  readonly selectedDeliveries: EventItem<UnassignedDeliveryIdentifier>[];
  readonly focusedDelivery: EventItem<UnassignedDeliveryIdentifier>;
  readonly clickedDelivery: DeliveryShipmentSearchRecord;
  readonly boundingSearchArea: GlobalFilterMapCoordinate[];
}

export const initialState: State = {
  criteria: undefined,
  lastUpdate: new Date(0),
  selectedDeliveries: [],
  focusedDelivery: {
    id: undefined,
    source: undefined,
  },
  clickedDelivery: undefined,
  boundingSearchArea: new Array<GlobalFilterMapCoordinate>(),
};
