import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { XpoBoardState, XpoBoardViewDataStoreBase, XpoBoardViewConfig } from '@xpo-ltl/ngx-ltl-board';
import {
  XpoAgGridBoardViewConfig,
  XpoAgGridBoardState,
  XpoAgGridBoardViewTemplate,
  XpoAgGridBoardView,
} from '@xpo-ltl/ngx-ltl-board-grid';
import { CityOperationsApiService, GetPnDTripPath, Route } from '@xpo-ltl/sdk-cityoperations';
import { Unsubscriber, XpoLtlTimeService } from '@xpo/ngx-ltl';
import { XrtChangedDocument } from '@xpo/ngx-xrt';
import { XrtFireMessagingService } from '@xpo/ngx-xrt-firebase';
import { AgGridEvent, GridOptions, MenuItemDef, RowNode, RowSelectedEvent, Column, ColumnApi } from 'ag-grid-community';
import {
  bind as _bind,
  get as _get,
  includes as _includes,
  xor as _xor,
  map as _map,
  noop as _noop,
  size as _size,
  some as _some,
  sortBy as _sortBy,
  uniq as _uniq,
} from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, forkJoin, of, ReplaySubject } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { GridRowTransposedComponent } from '../../../../core/dialogs/grid-row-transposed/grid-row-transposed.component';
import { PndRouteUtils } from '../../../../shared/route-utils';
import { GlobalFilterStoreSelectors, PndStoreState, RoutesStoreActions, RoutesStoreSelectors } from '../../../store';
import { BoardStateSource } from '../../../store/board-state-source';
import { EquipmentPipe, GrandTotalService, pndFrameworkComponents, SpecialServicesService } from '../../shared';
import { RowHoverManager } from '../../shared/classes/row-hover-manager';
import { InboundPlanningGridBaseComponent } from '../../shared/components/inbound-planning-grid-base/inbound-planning-grid-base.class';
import { RouteSummaryComponent } from '../../shared/components/route-summary/route-summary.component';
import { StoreSourcesEnum } from '../../shared/enums/store-sources.enum';
import { EventItem, RouteIdentifier } from '../../shared/interfaces/event-item.interface';
import { RemoveLeadingZerosPipe } from '../../shared/pipes/remove-leading-zeros.pipe';
import { PlanningRoutesCacheService } from '../../shared/services/planning-routes-cache.service';
import { RouteColorService } from '../../shared/services/route-color.service';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { RoutePlanningGridFields } from './enums/route-planning-grid-fields.enum';
import { RoutePlanningComponentName } from './route-planning-component-name';
import { RoutePlanningDataSource } from './route-planning-data-source.service';
import { RoutePlanningRouteBoardTemplate } from './route-planning-route-board-view-template.model';
import { RoutePlanningViewDataStore } from './route-planning-view-data-store.service';

@Component({
  selector: 'pnd-route-planning',
  templateUrl: './route-planning.component.html',
  styleUrls: ['./route-planning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [RoutePlanningDataSource, XrtFireMessagingService],
})
export class RoutePlanningComponent extends InboundPlanningGridBaseComponent implements OnInit, OnDestroy {
  allDisplayedColumns: Column[] = [];
  protected colApi: ColumnApi;

  // the number of changed routes notified from auto-refresh
  changedRoutesCount$ = this.pndStore$.select(RoutesStoreSelectors.changedRoutes).pipe(
    takeUntil(this.unsubscriber.done),
    map((changedDocuments: XrtChangedDocument[]) => {
      const docKeys = _uniq(
        _map(changedDocuments, (doc) => {
          const docKey = JSON.parse(JSON.stringify(doc)).DocumentKey;
          return docKey;
        })
      );
      return _size(docKeys);
    })
  );

  // last time the Planning Routes were updated
  lastUpdateTime$ = this.pndStore$.select(RoutesStoreSelectors.planningRoutesLastUpdate).pipe(
    withLatestFrom(this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic)),
    switchMap(([date, sicCd]) => {
      return forkJoin([of(date), this.timeService.timezoneForSicCd$(sicCd)]);
    }),
    map(([date, timezone]) => {
      return moment.tz(date, timezone).format('DD MMMM YYYY [at] HH:mm:ss z');
    })
  );
  private rowHoverManager: RowHoverManager<RouteIdentifier>;

  constructor(
    dataSource: RoutePlanningDataSource,
    protected pndStore$: Store<PndStoreState.State>,
    protected userPreferencesService: UserPreferencesService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog,
    private cityOperationsService: CityOperationsApiService,
    private timeService: XpoLtlTimeService,
    private equipmentPipe: EquipmentPipe,
    private specialServicesService: SpecialServicesService,
    private routeColorService: RouteColorService,
    private removeLeadingZerosPipe: RemoveLeadingZerosPipe,
    private grandTotalService: GrandTotalService,
    private planningRoutesCacheService: PlanningRoutesCacheService
  ) {
    super(pndStore$, dataSource, userPreferencesService);
    this.matIconRegistry.addSvgIcon(
      'routes',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/road-icon.svg')
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.rowHoverManager = new RowHoverManager(
      this.gridOptions,
      _bind(this.setFocusedRow, this),
      _bind(this.clearFocusedRow, this)
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.rowHoverManager.destroy();
  }

  /**
   * React to changes in the Store, updating the Board state when needed
   */
  private subscribeToStoreChanges() {
    // Update the board state from the store. should NOT trigger a Store update!
    this.pndStore$
      .select(RoutesStoreSelectors.planningRoutesLastUpdate)
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscriber.done))
      .subscribe(() => {
        const planningRoutes = this.planningRoutesCacheService.getAllPlanningRoutes();
        this.onPlanningRoutesChangedFromStore(planningRoutes);
      });

    this.pndStore$
      .select(RoutesStoreSelectors.selectedPlanningRoutes)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((selectedRoutes) => {
        // TODO: Check POLYGON SELECTION
        this.updateNodeSelection(selectedRoutes.map(String));
      });
  }

  /**
   * User selected some items in the Board.  Update the Store to reflect the new selection state
   */
  private onSelectionChangedFromBoard(row: RowSelectedEvent) {
    this.pndStore$
      .select(RoutesStoreSelectors.selectedPlanningRoutes)
      .pipe(take(1))
      .subscribe((currentSelectedRoutes: number[]) => {
        const rowNodeId = Number(this.getRowNodeId(row.node));
        if (row.node.isSelected()) {
          if (!_some(currentSelectedRoutes, (selectedRoute) => selectedRoute === rowNodeId)) {
            currentSelectedRoutes.push(rowNodeId);
            this.pndStore$.dispatch(
              new RoutesStoreActions.SetSelectedPlanningRoutesAction({ selectedPlanningRoutes: currentSelectedRoutes })
            );
          }
        } else {
          if (
            _size(currentSelectedRoutes) > 0 &&
            !_some(currentSelectedRoutes, (selectedRoute) => selectedRoute === rowNodeId)
          ) {
            currentSelectedRoutes = currentSelectedRoutes.filter((selectedRoute) => selectedRoute !== rowNodeId);
            this.pndStore$.dispatch(
              new RoutesStoreActions.SetSelectedPlanningRoutesAction({ selectedPlanningRoutes: currentSelectedRoutes })
            );
          }
        }
        this.refreshSortForSelectedRows();
      });
  }

  /**
   * Update the Grid state. Should NOT trigger a Store update
   */
  onPlanningRoutesChangedFromStore(planningRoutes: Route[]) {
    this.stateChange$.next(<XpoAgGridBoardState>{
      source: BoardStateSource.ReduxStore,
      pageNumber: 1,
      changes: ['pageNumber'],
    });
  }

  /**
   * Refresh the data in the board
   */
  onRefreshIntent(): void {
    this.dataSource.refresh();
  }

  /**
   * Dispaly the Route Summary dialog for the passed Route
   */
  onShowRoute(route: Route): void {
    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterSic)
      .pipe(
        take(1),
        filter((sicCd: string) => !!sicCd),
        switchMap((currentSic) => {
          const request = new GetPnDTripPath();
          request.sicCd = currentSic;
          request.tripInstId = route.tripInstId;
          return this.cityOperationsService.getPnDTrip(request);
        })
      )
      .subscribe((response) => {
        this.dialog.open(RouteSummaryComponent, {
          data: {
            selectedRoute: route.routeInstId,
            tripDetails: response,
          },
          disableClose: true,
          hasBackdrop: false,
        });
      });
  }

  private setFocusedRow(data: RouteIdentifier) {
    this.pndStore$
      .select(RoutesStoreSelectors.focusedPlanningRoute)
      .pipe(take(1))
      .subscribe((currentFocusedRoute: EventItem<RouteIdentifier>) => {
        if (_get(currentFocusedRoute, 'id.routeInstId') !== _get(data, 'routeInstId')) {
          this.pndStore$.dispatch(
            new RoutesStoreActions.SetFocusedPlanningRouteAction({
              focusedPlanningRoute: {
                id: { routeInstId: data.routeInstId },
                source: StoreSourcesEnum.ROUTE_STOPS_GRID,
              },
            })
          );
        }
      });
  }

  private clearFocusedRow() {
    this.pndStore$.dispatch(
      new RoutesStoreActions.SetFocusedPlanningRouteAction({
        focusedPlanningRoute: {
          id: undefined,
          source: StoreSourcesEnum.ROUTE_STOPS_GRID,
        },
      })
    );
  }

  onGridReady(gridEvent: AgGridEvent) {
    super.onGridReady(gridEvent);

    this.grandTotalService.routePlanningDeliveriesTotals$
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((totals) => {
        if (this.grandTotalService.isRoutePlanningGrandTotalsEmpty(totals)) {
          this.gridApiEvent.api.setPinnedBottomRowData(undefined);
        } else {
          this.gridApiEvent.api.setPinnedBottomRowData(totals);
        }
      });

    this.subscribeToStoreChanges();
  }

  private getContextMenuItems(params): (string | MenuItemDef)[] {
    return [
      'copy',
      'copyWithHeaders',
      'paste',
      'separator',
      'export',
      'separator',
      {
        name: 'Transpose',
        action: () => {
          this.dialog.open(GridRowTransposedComponent, {
            data: { ...params, dialogTitle: `Planning Routes - ${PndRouteUtils.getRouteId(params.node.data)}` },
            disableClose: true,
            hasBackdrop: false,
          });
        },
      },
    ];
  }

  /* ABSTRACT IMPLEMENTATIONS */

  protected getRowNodeId(node: RowNode): string {
    return _get(node, 'data.routeInstId') as string;
  }

  protected getSelectedRowFromStoreId(selectedRow): string {
    return selectedRow;
  }

  protected getSelectedStoreStateSelector() {
    return RoutesStoreSelectors.selectedPlanningRoutes;
  }

  protected getSelectedRowColumnFieldName(): string {
    return RoutePlanningGridFields.ROW_SELECTED;
  }

  protected getBoardViewTemplates(): XpoAgGridBoardViewTemplate[] {
    return [
      new RoutePlanningRouteBoardTemplate(
        this.onShowRoute.bind(this),
        this.onRefreshIntent.bind(this),
        _noop, // TODO this used to be onHover, but doesn't make sense
        this.timeService,
        this.equipmentPipe,
        this.specialServicesService,
        this.removeLeadingZerosPipe,
        this.dialog,
        this.routeColorService
      ),
    ];
  }

  protected getGridOptions(): GridOptions {
    return {
      frameworkComponents: pndFrameworkComponents,
      headerHeight: 40,
      rowHeight: 40,
      defaultColDef: { resizable: true },
      pinnedBottomRowData: [],
      getContextMenuItems: (params) => this.getContextMenuItems(params),
      onRowSelected: (row: RowSelectedEvent) => {
        // TODO - this should probably be done based on Store route selection
        if (_get(row, 'node.selected')) {
          // assign a color to this route when it is selected
          this.routeColorService.setRouteColor(row.node.data.routeInstId);
        } else {
          // unassign the color for this route when it is deselected
          this.routeColorService.clearRouteColor(row.node.data.routeInstId);
        }
        row.api.redrawRows();
        this.onSelectionChangedFromBoard(row);
      },
    };
  }

  protected mapViewDataStore(preferences): XpoBoardViewDataStoreBase<XpoBoardViewConfig> {
    return new RoutePlanningViewDataStore(this.userPreferencesService, preferences);
  }

  protected getComponentName(): string {
    return RoutePlanningComponentName;
  }
}
