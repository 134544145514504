/**
 * Common Maps for I18nPluralPipe
 */
interface KeyValue {
  [k: string]: string;
}

export namespace PluralMaps {
  export const Customers: KeyValue = {
    '=1': 'Customer',
    other: 'Customers',
  };

  export const items: KeyValue = {
    '=1': 'item',
    other: 'items',
  };

  export const Stops: KeyValue = {
    '=1': 'Stop',
    other: 'Stops',
  };
}
