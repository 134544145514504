import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Stop, Activity } from '@xpo-ltl/sdk-cityoperations';
import { TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import { GetCustomerLocationOperationsProfileResp as CustomerProfileResp, Contact } from '@xpo-ltl/sdk-customer';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { map as _map, groupBy as _groupBy, get as _get, orderBy as _orderBy } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, switchMap, catchError, map, share } from 'rxjs/operators';
import { StopDetailsService } from '../../../../services/stop-details.service';
import { ShipmentSummaryLine } from '../shipment-details/shipment-details.component';

interface CustomerSummaryLine {
  dock: string;
  forklift: string;
  loadUnloadBy: string;
  pupOnly: string;
  liftgateRequired: string;
  locationType: string;
  openTimes: string;
  closeTimes: string;
}

export interface StopDetailContact extends Contact {
  contactFormattedPhoneNbr: string;
}

export interface CustomerContacts {
  pickupContact: StopDetailContact;
  deliveryContact: StopDetailContact;
  operationalContact: StopDetailContact;
  noaContact: StopDetailContact;
}

@Component({
  selector: 'pnd-stop-details',
  templateUrl: './stop-details.component.html',
  styleUrls: ['./stop-details.component.scss'],
  host: { class: 'pnd-StopDetails' },
})
export class StopDetailsComponent implements OnInit {
  @Input()
  set stop(stop: Stop) {
    this.stopSubject.next(stop);
  }
  get stop(): Stop {
    return this.stopSubject.value;
  }

  @Output()
  viewShipment = new EventEmitter<ShipmentSummaryLine>(undefined);

  private stopSubject: BehaviorSubject<Stop> = new BehaviorSubject<Stop>(undefined);
  stop$: Observable<Stop> = this.stopSubject.asObservable();

  customerContacts$: Observable<CustomerContacts> = this.stop$.pipe(
    filter((stop: Stop) => !!_get(stop, 'customer.acctInstId')),
    switchMap((stop: Stop) => this.stopDetailsService.loadCustomerProfile(_get(stop, 'customer.acctInstId'))),
    catchError(() => of(undefined)),
    map((resp: CustomerProfileResp) => this.mapCustomerContacts(resp)),
    share()
  );

  private customerSummaryDataSourceSubject = new BehaviorSubject<MatTableDataSource<CustomerSummaryLine>>(undefined);
  customerSummaryDataSource$ = this.customerSummaryDataSourceSubject.asObservable();

  activityMap = new Map<string, Activity[]>();

  customerSummaryColumns = [
    { id: 'dock', label: 'Dock', renderer: (row: CustomerSummaryLine) => row.dock },
    { id: 'forklift', label: 'Forklift', renderer: (row: CustomerSummaryLine) => row.forklift },
    { id: 'loadUnloadBy', label: 'Load/Unload By', renderer: (row: CustomerSummaryLine) => row.loadUnloadBy },
    { id: 'pupOnly', label: 'Pup Only', renderer: (row: CustomerSummaryLine) => row.pupOnly },
    {
      id: 'liftgateRequired',
      label: 'Liftgate Required',
      renderer: (row: CustomerSummaryLine) => row.liftgateRequired,
    },
    { id: 'locationType', label: 'Location Type', renderer: (row: CustomerSummaryLine) => row.locationType },
    { id: 'openTimes', label: 'Open Times', renderer: (row: CustomerSummaryLine) => row.openTimes },
    { id: 'closeTimes', label: 'Close Times', renderer: (row: CustomerSummaryLine) => row.closeTimes },
  ];
  customerSummaryColumnsToDisplay = _map(this.customerSummaryColumns, (c) => c.id);

  constructor(private stopDetailsService: StopDetailsService) {}

  ngOnInit() {
    this.initialization();
  }

  cellClicked() {}

  getActivityTypes(): string[] {
    return Array.from(this.activityMap.keys());
  }

  getActivityList(code: string): Activity[] {
    return this.activityMap.get(code);
  }

  viewShipmentClicked($event): void {
    this.viewShipment.emit($event);
  }

  private initialization(): void {
    this.populateActivities();
    this.populateCustomerSummary();
  }

  private mapCustomerContacts(customerProfile: CustomerProfileResp): CustomerContacts {
    const customerProfileContacts: Contact[] = _get(customerProfile, 'custContacts');

    if (!customerProfileContacts) {
      return undefined;
    }

    const parseAndFormatPhoneNumber = (contact: Contact) => {
      const phoneAsString =
        `${contact.contactAreaCd || ''}${contact.contactPhone || ''}` +
        `${contact.contactPhoneExt ? 'x' + contact.contactPhoneExt : ''}`;
      const phoneParsed = parsePhoneNumberFromString(phoneAsString, 'US');
      return phoneParsed ? phoneParsed.formatNational() : '';
    };

    const formatCustomerContact = (contactTypeCd: string): StopDetailContact => {
      const contact = customerProfileContacts.filter((customerContact) => customerContact.typeCd === contactTypeCd)[0];
      return contact
        ? <StopDetailContact>{
            ...contact,
            contactFormattedPhoneNbr: parseAndFormatPhoneNumber(contact),
          }
        : undefined;
    };

    return <CustomerContacts>{
      pickupContact: formatCustomerContact('1'),
      deliveryContact: formatCustomerContact('2'),
      operationalContact: formatCustomerContact('O'),
      noaContact: formatCustomerContact('N'),
    };
  }

  private populateActivities(): void {
    const groupedActivities = _groupBy(_get(this.stop, 'activities', []), (a: Activity) =>
      _get(a, 'tripNodeActivity.activityCd', '')
    );
    delete groupedActivities[TripNodeActivityCd.ARRIVE];
    delete groupedActivities[TripNodeActivityCd.DEPART_DISPATCH];

    _orderBy(Object.keys(groupedActivities), (key) => key).forEach((key) => {
      this.activityMap.set(key, groupedActivities[key]);
    });
  }

  private populateCustomerSummary(): void {
    const summaryLine: CustomerSummaryLine = {
      dock: '',
      forklift: '',
      loadUnloadBy: '',
      pupOnly: '',
      liftgateRequired: '',
      locationType: '',
      openTimes: '',
      closeTimes: '',
    };
    this.customerSummaryDataSourceSubject.next(new MatTableDataSource([summaryLine]));
  }
}
