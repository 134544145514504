import { Action } from '@ngrx/store';
import { Route, Stop } from '@xpo-ltl/sdk-cityoperations';
import { XrtChangedDocument } from '@xpo/ngx-xrt';
import { ResequencingRouteData } from '../../inbound-planning/components/route-balancing';
import { RouteStopsGridItem } from '../../inbound-planning/components/route-stops';
import { StoreSourcesEnum } from '../../inbound-planning/shared/enums/store-sources.enum';
import {
  AssignedStopIdentifier,
  EventItem,
  PlanningRouteShipmentIdentifier,
  RouteIdentifier,
} from '../../inbound-planning/shared/interfaces/event-item.interface';
import { AssignedStopMapMarker } from '../../inbound-planning/shared/models/markers/assigned-stop-map-marker.model';
import { NumberToValueMap } from '../number-to-value-map';
import { RoutesSearchCriteria } from './routes-search-criteria.interface';

export enum ActionTypes {
  // Misc
  setShowCompletedStops = 'routes.setShowCompletedStops',
  updateUnassignedDeliveriesGrid = 'routes.updateUnassignedDeliveriesGrid',
  setRouteMarkers = 'routes.setRouteMarkers',

  // Trip Routes
  setSelectedRoutes = 'routes.trip.setSelectedRoutes',
  setClickedRouteStop = 'routes.trip.setClickedRouteStop',

  setFocusedStopForSelectedRoute = 'routes.trip.setFocusedStopForSelectedRoute',
  setFocusedRoute = 'routes.trip.setFocusedRoute',

  setStopsForSelectedRoutes = 'routes.trip.setStopsForSelectedRoutes',
  setSelectedStopsForSelectedRoutes = 'routes.trip.setSelectedStopsForSelectedRoutes',
  updateStopsForSelectedRoute = 'routes.trip.updateStopsForSelectedRoute',

  // TODO - move to Route Balancing
  setResequencedRouteData = 'routes.setResequencedRouteData',

  // Planning Routes
  setSearchCriteria = 'routes.planning.setSearchCriteria',
  setChangedRoutes = 'routes.planning.setChangedRoutes',
  setPlanningRoutesLastUpdate = 'routes.planning.setPlanningRoutesLastUpdate',

  setFocusedPlanningRoute = 'routes.planning.setFocusedPlanningRoute',
  setFocusedPlanningRouteShipment = 'routes.planning.setFocusedPlanningRouteShipment',

  setSelectedPlanningRoutes = 'routes.planning.setSelectedPlanningRoutes',
  setStopsForSelectedPlanningRoutesLastUpdate = 'routes.planning.setStopsForSelectedPlanningRoutesLastUpdate',

  setSelectedPlanningRoutesShipments = 'routes.planning.setSelectedPlanningRoutesShipments',

  refreshPlanningRoutes = 'routes.planning.refreshPlanningRoutes',
}

// #region Misc
export class SetShowCompletedStops implements Action {
  readonly type = ActionTypes.setShowCompletedStops;
  constructor(public payload: { showCompletedStops: boolean }) {}
}

export class SetRouteMarkersAction implements Action {
  readonly type = ActionTypes.setRouteMarkers;
  constructor(public payload: { routeMarkers: AssignedStopMapMarker[] }) {}
}

export class UpdateUnassignedDeliveriesGridAction implements Action {
  readonly type = ActionTypes.updateUnassignedDeliveriesGrid;
  constructor(public payload: { updateUnassignedDeliveriesGrid: { source: StoreSourcesEnum; date: Date } }) {}
}

// #endregion

// #region Trip Routes
export class SetSelectedRoutesAction implements Action {
  readonly type = ActionTypes.setSelectedRoutes;
  constructor(public payload: { selectedRoutes: Route[] }) {}
}

export class SetClickedRouteStopAction implements Action {
  readonly type = ActionTypes.setClickedRouteStop;
  constructor(public payload: { clickedRouteStop: RouteStopsGridItem }) {}
}

export class SetFocusedRouteAction implements Action {
  readonly type = ActionTypes.setFocusedRoute;
  constructor(public payload: { focusedRoute: EventItem<RouteIdentifier> }) {}
}

export class SetFocusedStopForSelectedRouteAction implements Action {
  readonly type = ActionTypes.setFocusedStopForSelectedRoute;
  constructor(public payload: { focusedStopForSelectedRoute: EventItem<AssignedStopIdentifier> }) {}
}

export class SetStopsForSelectedRoutesAction implements Action {
  readonly type = ActionTypes.setStopsForSelectedRoutes;
  constructor(public payload: { stopsForSelectedRoutes: NumberToValueMap<Stop[]> }) {}
}

export class SetSelectedStopsForSelectedRoutesAction implements Action {
  readonly type = ActionTypes.setSelectedStopsForSelectedRoutes;
  constructor(public payload: { selectedStopsForSelectedRoutes: EventItem<AssignedStopIdentifier>[] }) {}
}

export class UpdateStopsForSelectedRouteAction implements Action {
  readonly type = ActionTypes.updateStopsForSelectedRoute;
  constructor(public payload: { route: Route }) {}
}
// #endregion

// #region TODO - move to Route Balancing

export class SetResequencedRouteData implements Action {
  readonly type = ActionTypes.setResequencedRouteData;
  constructor(public payload: { resequenceData: { [routeInstId: string]: ResequencingRouteData } }) {}
}

// #endregion

// #region Planning Routes
export class SetSearchCriteria implements Action {
  readonly type = ActionTypes.setSearchCriteria;
  constructor(public payload: { criteria: RoutesSearchCriteria }) {}
}

export class RefreshPlanningRoutes implements Action {
  readonly type = ActionTypes.refreshPlanningRoutes;
  constructor() {}
}

export class SetChangedRoutes implements Action {
  readonly type = ActionTypes.setChangedRoutes;
  constructor(public payload: { changedRoutes: XrtChangedDocument[] }) {}
}

export class SetPlanningRoutesLastUpdate implements Action {
  readonly type = ActionTypes.setPlanningRoutesLastUpdate;
  constructor(public payload: { planningRoutesLastUpdate: Date }) {}
}

export class SetFocusedPlanningRouteAction implements Action {
  readonly type = ActionTypes.setFocusedPlanningRoute;
  constructor(public payload: { focusedPlanningRoute: EventItem<RouteIdentifier> }) {}
}

export class SetFocusedPlanningRouteShipmentAction implements Action {
  readonly type = ActionTypes.setFocusedPlanningRouteShipment;
  constructor(public payload: { focusedPlanningRouteShipment: EventItem<PlanningRouteShipmentIdentifier> }) {}
}

export class SetSelectedPlanningRoutesAction implements Action {
  readonly type = ActionTypes.setSelectedPlanningRoutes;
  constructor(public payload: { selectedPlanningRoutes: number[] }) {}
}

export class SetStopsForSelectedPlanningRoutesLastUpdate implements Action {
  readonly type = ActionTypes.setStopsForSelectedPlanningRoutesLastUpdate;
  constructor(public payload: { stopsForSelectedPlanningRoutesLastUpdate: Date }) {}
}

export class SetSelectedPlanningRoutesShipmentsAction implements Action {
  readonly type = ActionTypes.setSelectedPlanningRoutesShipments;
  constructor(public payload: { selectedPlanningRoutesShipments: PlanningRouteShipmentIdentifier[] }) {}
}

// #endregion

export type Actions =
  | SetShowCompletedStops
  | UpdateUnassignedDeliveriesGridAction
  | SetRouteMarkersAction
  | SetSelectedRoutesAction
  | SetClickedRouteStopAction
  | SetFocusedRouteAction
  | SetFocusedStopForSelectedRouteAction
  | SetStopsForSelectedRoutesAction
  | UpdateStopsForSelectedRouteAction
  | SetSelectedStopsForSelectedRoutesAction
  | SetResequencedRouteData
  | SetSearchCriteria
  | RefreshPlanningRoutes
  | SetChangedRoutes
  | SetPlanningRoutesLastUpdate
  | SetFocusedPlanningRouteAction
  | SetFocusedPlanningRouteShipmentAction
  | SetSelectedPlanningRoutesAction
  | SetStopsForSelectedPlanningRoutesLastUpdate
  | SetSelectedPlanningRoutesShipmentsAction;
