import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { XpoFilterComponentBase, XpoFilterCriteria } from '@xpo-ltl/ngx-ltl-board';
import { takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { PndStoreState } from '../../../../store';
import { GlobalFilterStoreSelectors } from '../../../../store/global-filters-store';
import { TimeValidationEnum } from '../../enums/time-validation.enum';
import { TimeUtil } from '../../services/time-format.util';
import { TimeFilter } from './time-filter';

@Component({
  selector: 'pnd-time-filter',
  templateUrl: 'time-filter.component.html',
  styleUrls: ['time-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': `this.searchFormControl.valid
      ? 'pnd-TimeInputFilter xpo-FilterChip pnd-TimeInputFilter--without-errors'
      : 'pnd-TimeInputFilter xpo-FilterChip pnd-TimeInputFilter--with-errors'`,
    '[attr.id]': 'id',
  },
})
export class TimeFilterComponent extends XpoFilterComponentBase<TimeFilter> {
  searchFormControl: FormControl = new FormControl();
  previousValidCriteria: string = '';
  previousValue: string = '';
  globalPlanDateSelected: Date = undefined;

  constructor(private pndStore$: Store<PndStoreState.State>, private cdr: ChangeDetectorRef) {
    super();
    this.inline = true;
  }

  storeCriteriaIfValid(value: string): void {
    const regExpFullMatch = new RegExp(TimeUtil.TimeFormatValidatorRegExpWithAnchors);
    if ((regExpFullMatch.test(value) && this.previousValidCriteria !== value) || value === '') {
      this.previousValidCriteria = value;
      this.storeCriteria(value);
    }
  }

  /**
   * Clears inputs and sets focus back to input
   */
  clearInput(): void {}

  protected initialize(): void {
    this.initValidators();

    this.initPlanDateLabel();

    this.onValueChangeEnlighteningInput();

    this.onValueChangeStoreCriteria();
  }

  protected initPlanDateLabel(): void {
    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterPlanDate)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((planDate: Date) => {
        this.globalPlanDateSelected = new Date(planDate);
        this.cdr.detectChanges();
      });
  }

  protected onValueChangeEnlighteningInput() {
    this.searchFormControl.valueChanges
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((nextValue: string) => this.formControlValueChangesSubscription(nextValue));
  }

  formControlValueChangesSubscription(nextValue: string): void {
    if (nextValue) {
      const timeInput = {
        previousValue: this.previousValue,
        nextValue: nextValue,
      };

      const timeFixed = TimeUtil.inputTimeFormControlEnlightening(timeInput);

      this.previousValue = timeFixed.previousValue;

      if (timeFixed.nextValue === TimeValidationEnum.ShouldReplace) {
        this.searchFormControl.setValue(timeFixed.previousValue, { emitEvent: false });
      } else if (timeInput.nextValue.length === 4) {
        this.previousValidCriteria = '';
      }
    } else {
      this.previousValue = '';
    }
  }

  protected onValueChangeStoreCriteria() {
    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(this.configuration.inputDebounceTime),
        distinctUntilChanged(),
        takeUntil(this.componentDestroyed)
      )
      .subscribe((v) => {
        this.storeCriteriaIfValid(v);
      });
  }

  /**
   * Initializes validators
   */
  protected initValidators(): void {
    const validators = this.configuration.validators;

    if (validators && validators.length) {
      this.searchFormControl.setValidators(validators);
      this.searchFormControl.updateValueAndValidity({ emitEvent: false });
    }
  }

  protected onCriteriaModified(fieldValue: object, criteria: XpoFilterCriteria): void {
    this.searchFormControl.setValue(fieldValue ? fieldValue.toString() : '', { emitEvent: true });
  }
}
