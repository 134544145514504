import { LatLngLiteral } from '@agm/core';
import { AgmSnazzyInfoWindow } from '@agm/snazzy-info-window';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Route, Stop, TripNode } from '@xpo-ltl/sdk-cityoperations';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { forEach as _forEach, get as _get, includes as _includes, set as _set } from 'lodash';
import { BehaviorSubject, timer, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, take, takeUntil, withLatestFrom, skip, filter } from 'rxjs/operators';
import {
  GlobalFilterStoreSelectors,
  PndStoreState,
  RoutesStoreSelectors,
  TripsStoreActions,
} from '../../../../../store';
import { NumberToValueMap } from '../../../../../store/number-to-value-map';
import { RouteBalancingSelectors } from '../../../../../store/route-balancing-store';
import { RouteColorService } from '../../../../shared/services/route-color.service';
import { ResequencingRouteData } from '../../../route-balancing';
import {
  ContextMenuItem,
  MarkerContextMenuComponent,
} from '../../components/marker-context-menu/marker-context-menu.component';
import { RouteRenderingService, RouteStopsRenderInfo, RightClickSubject } from './route-rendering.service';

export enum ContextMenuItemId {
  colors,
}

@Component({
  selector: 'app-routes-layer',
  templateUrl: './routes-layer.component.html',
  styleUrls: ['./routes-layer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutesLayerComponent implements OnInit, OnDestroy {
  @ViewChild('routeInfoWindow', { static: false }) routeInfoWindow: AgmSnazzyInfoWindow;

  @Output()
  invokeSequencing = new EventEmitter<string>(undefined);

  @Input() googleMap: any;

  private unsubscriber: Unsubscriber = new Unsubscriber();
  private lastClickedRoute: number;

  private focusedRouteSubject = new BehaviorSubject<RouteStopsRenderInfo>(undefined);
  get focusedRoute() {
    return this.focusedRouteSubject.value;
  }
  focusedRoute$ = this.focusedRouteSubject.asObservable();
  isOpenRouteBalancing: boolean = false;
  private isInfoWindowOpenSubject = new BehaviorSubject<boolean>(false);
  isInfoWindowOpen$ = this.isInfoWindowOpenSubject.asObservable();
  contextMenuItems: ContextMenuItem<ContextMenuItemId>[] = [
    {
      id: ContextMenuItemId.colors,
      label: 'Change Color',
      nested: true,
    },
  ];
  @ViewChild(MarkerContextMenuComponent, { static: false }) contextMenu: MarkerContextMenuComponent;

  constructor(
    private pndStore$: Store<PndStoreState.State>,
    private routeColorService: RouteColorService,
    private routeRenderingService: RouteRenderingService
  ) {}

  ngOnInit() {
    this.handleSwitchingSics();
    this.setFocusedRoute();
    this.watchForSelectedRouteChange();
    this.watchForRouteBalancingStateChange();
    this.updateVisibilityOfCompletedLegs();
    this.updateRoutesSelections();
    this.subscribeToChangeColor();
    this.subscribeToOpenRouteBalancing();
  }

  watchForRouteBalancingStateChange() {
    this.routeRenderingService.hoverOverRoute$.pipe(takeUntil(this.unsubscriber.done)).subscribe((value) => {
      const routeInstId = _get(value, 'routeInstId');
      const location = _get(value, 'location');
      // Prevent hover on the same route when user has clicked it. Reset hover if the user hovers a different route.
      if (!this.lastClickedRoute || (this.lastClickedRoute !== routeInstId && !!routeInstId)) {
        this.focusRoute(routeInstId, location);
        this.lastClickedRoute = undefined;
      }
    });
    this.routeRenderingService.clickRoute$.pipe(takeUntil(this.unsubscriber.done)).subscribe((value) => {
      const routeInstId = _get(value, 'routeInstId');
      const location = _get(value, 'location');
      // Toggle
      if (this.lastClickedRoute === routeInstId) {
        this.focusRoute(undefined);
        this.lastClickedRoute = undefined;
      } else {
        this.focusRoute(routeInstId, location);
        this.lastClickedRoute = routeInstId;
      }
    });
    this.routeRenderingService.rightClickRoute$
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((value: RightClickSubject) => {
        if (value) {
          this.openContextMenu(value);
        }
      });
    this.focusedRoute$.pipe(takeUntil(this.unsubscriber.done)).subscribe((routeInfo: RouteStopsRenderInfo) => {
      timer(1)
        .pipe(take(1))
        .subscribe((_) => {
          this.isInfoWindowOpenSubject.next(true);
        });
    });
  }

  updateVisibilityOfCompletedLegs() {
    // update visibility of completed legs
    this.pndStore$
      .select(RoutesStoreSelectors.showCompletedStops)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((showCompletedStops) => {
        this.routeRenderingService.updateAllVisibility(true, showCompletedStops, this.googleMap);
      });
  }

  watchForSelectedRouteChange() {
    // Update the list of Routes to render whenever the selected routes changes
    this.pndStore$
      .select(RoutesStoreSelectors.stopsForSelectedRoutes)
      .pipe(
        withLatestFrom(this.pndStore$.select(RoutesStoreSelectors.selectedRoutes)),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(([stopsForSelectedRoutes, selectedRoutes]) => {
        // This happens AFTER the stopsForSelectedRoutes resolve the set of stops.
        this.routeRenderingService
          .updateRoutes(selectedRoutes, stopsForSelectedRoutes)
          .pipe(take(1))
          .pipe(withLatestFrom(this.pndStore$.select(RoutesStoreSelectors.showCompletedStops)))
          .subscribe(([routeRenderInfos, showCompletedStops]) => {
            _forEach(routeRenderInfos, (renderInfo) => {
              this.routeRenderingService.updateVisibility(renderInfo, true, showCompletedStops, this.googleMap);
            });
            this.pndStore$
              .select(RoutesStoreSelectors.selectedRoutes)
              .subscribe((verifiedstopsForSelectedRoutes: Route[]) => {
                if (verifiedstopsForSelectedRoutes.length === 0) {
                  this.routeRenderingService.updateAllVisibility(false, showCompletedStops, this.googleMap);
                }
              });
          });
      });
  }

  setFocusedRoute() {
    this.pndStore$
      .select(RoutesStoreSelectors.focusedRoute)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((focusedRoute) => {
        const focusedRouteInstId = _get(focusedRoute, 'id.routeInstId');
        this.focusRoute(focusedRouteInstId);
      });
  }

  handleSwitchingSics() {
    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterSic)
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscriber.done))
      .subscribe(() => {
        this.routeColorService.clear();
      });
  }

  private subscribeToChangeColor(): void {
    this.routeColorService.colorChanged$
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((changed: { routeInstId: number; color: string }) => {
        if (changed) {
          this.updateRoutesSelections();
        }
      });
  }

  subscribeToOpenRouteBalancing() {
    this.pndStore$
      .select(RouteBalancingSelectors.openRouteBalancingPanel)
      .pipe(takeUntil(this.unsubscriber.done), skip(1))
      .subscribe((isRouteBalancingActive: boolean) => {
        this.isOpenRouteBalancing = isRouteBalancingActive;
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private updateTripsGrid() {
    this.pndStore$.dispatch(new TripsStoreActions.RefreshTrips());
  }

  private updateRoutesSelections(): void {
    // Update the list of Routes to render whenever the selected routes changes
    const stopsForSelectedRoutes$ = this.pndStore$.select(RoutesStoreSelectors.stopsForSelectedRoutes);
    combineLatest([stopsForSelectedRoutes$, this.routeColorService.colorChanged$])
      .pipe(
        withLatestFrom(this.pndStore$.select(RoutesStoreSelectors.selectedRoutes)),
        withLatestFrom(this.pndStore$.select(RouteBalancingSelectors.openRouteBalancingPanel)),
        filter(
          ([[[stopsForSelectedRoutes, colorChange], selectedRoutes], isOpenRouteBalancing]) => !isOpenRouteBalancing
        ),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(([[[stopsForSelectedRoutes, colorChange], selectedRoutes], isOpenRouteBalancing]) => {
        // This happens AFTER the stopsForSelectedRoutes resolve the set of stops.
        this.routeRenderingService
          .updateRoutes(selectedRoutes, stopsForSelectedRoutes)
          .pipe(take(1))
          .pipe(withLatestFrom(this.pndStore$.select(RoutesStoreSelectors.showCompletedStops)))
          .subscribe(([routeRenderInfos, showCompletedStops]) => {
            _forEach(routeRenderInfos, (renderInfo) => {
              this.routeRenderingService.updateVisibility(renderInfo, true, showCompletedStops, this.googleMap);
            });
            this.pndStore$
              .select(RoutesStoreSelectors.selectedRoutes)
              .subscribe((verifiedstopsForSelectedRoutes: Route[]) => {
                if (verifiedstopsForSelectedRoutes.length === 0) {
                  this.routeRenderingService.updateAllVisibility(false, showCompletedStops, this.googleMap);
                }
              });
          });
      });
    //
    // Every time the route balancing state changes, we need to update the route rendering
    // to reflect new sequence
    const resequencedRouteData$ = this.pndStore$.select(RoutesStoreSelectors.resequencedRouteData);
    combineLatest([resequencedRouteData$, this.routeColorService.colorChanged$])
      .pipe(
        withLatestFrom(this.pndStore$.select(RouteBalancingSelectors.openRouteBalancingPanel)),
        filter(([[resequencedRouteData, colorChange], isOpenRouteBalancing]) => isOpenRouteBalancing),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(([[resequencedRouteData, colorChange], isOpenRouteBalancing]) => {
        const stpsForSelectedRoutes: NumberToValueMap<Stop[]> = {};
        const selectedRoutes: Route[] = [];

        Object.keys(resequencedRouteData).forEach((routeInstId) => {
          const routeData: ResequencingRouteData = resequencedRouteData[routeInstId];

          const route = {
            ...new Route(),
            routeInstId: +routeInstId,
            routeName: routeData.routeName,
            routePrefix: routeData.routePrefix,
            routeSuffix: routeData.routeSuffix,
            statusCd: routeData.routeStatusCd,
          };

          selectedRoutes.push(route);

          const resequencedStops = routeData.newResequencingStops.map((stopCard) => {
            const stop: Stop = {
              ...new Stop(),
              tripNode: {
                ...new TripNode(),
                tripNodeSequenceNbr: stopCard.tripNodeSequenceNbr,
                stopSequenceNbr: stopCard.seqNo,
                statusCd: stopCard.tripNodeStatusCd,
              },
              customer: stopCard.customer,
              activities: stopCard.activities,
              specialServicesSummary: stopCard.specialServicesSummary,
            };
            _set(stop, 'tripNode.originalStopSequenceNbr', stopCard.origSeqNo);
            return stop;
          });

          _set(stpsForSelectedRoutes, +routeInstId, [...resequencedStops]);
        });

        this.routeRenderingService
          .updateRoutes(selectedRoutes, stpsForSelectedRoutes)
          .pipe(take(1))
          .subscribe((routeRenderInfos) => {
            _forEach(routeRenderInfos, (renderInfo: RouteStopsRenderInfo) => {
              this.routeRenderingService.updateVisibility(renderInfo, true, true, this.googleMap);
            });
          });
      });
  }

  openContextMenu(value: RightClickSubject): void {
    const routeInstId = _get(value, 'routeInstId');
    this.contextMenu
      .openMenu(value.event.ya.clientX, value.event.ya.clientY)
      .subscribe((item: ContextMenuItem<ContextMenuItemId>) => {
        if (item) {
          switch (item.id) {
            case ContextMenuItemId.colors:
              this.routeColorService.changeRouteColor(routeInstId, item.colorId);
              break;
          }
        }
      });
  }

  /**
   * returns the name of the Route
   */
  routeName(routeInfo: RouteStopsRenderInfo): string {
    if (routeInfo && routeInfo.route) {
      return routeInfo.route.routeName
        ? routeInfo.route.routeName
        : `${routeInfo.route.routePrefix}-${routeInfo.route.routeSuffix}`;
    } else {
      return 'N/A';
    }
  }

  /**
   * Set the focused state of a route.
   */
  focusRoute(routeInstId: number | undefined, location?: LatLngLiteral | undefined) {
    let focusedRoute: RouteStopsRenderInfo;
    _forEach(this.routeRenderingService.routeStopsRenderInfos, (renderInfo) => {
      if (renderInfo.route.routeInstId === routeInstId) {
        // unblur this route since it is focused
        this.routeRenderingService.blurRoute(renderInfo, false);
        // save reference to the focused route
        focusedRoute = renderInfo;
      } else {
        // blur route if there is a focusedRoute, or unblur if no focused route
        this.routeRenderingService.blurRoute(renderInfo, !!routeInstId);
      }
    });

    const infoWindowRouteData = focusedRoute ? { ...focusedRoute } : undefined;

    if (infoWindowRouteData) {
      if (location) {
        // use location from pointer position
        infoWindowRouteData.midpoint = location;
      }

      this.isInfoWindowOpenSubject.next(false);
      this.pndStore$
        .select(RouteBalancingSelectors.openRouteBalancingPanel)
        .pipe(take(1))
        .subscribe((panelOpen: boolean) => {
          if (!panelOpen) {
            this.focusedRouteSubject.next(infoWindowRouteData);
          }
        });
    } else {
      this.isInfoWindowOpenSubject.next(false);
    }
  }

  /**
   * Dirty ugly hack to set the correct background color when showing an InfoWindow for a Route
   */
  updateInfoWindow() {
    const routeInfo = this.focusedRouteSubject.value;
    if (routeInfo) {
      // force setting the background color for the infoWindow and pointer.
      // ideally, this would be done with the [backgroudColor] property of
      // AgmSnazzyInfoWindow...but it doesn't support being changed after view
      // initialization.  So, we have to do it the ugly way
      const infoWindow = _get(this.routeInfoWindow, '_nativeSnazzyInfoWindow');
      infoWindow._opts.backgroundColor = routeInfo.color;
    }
  }
}
