import { UnmappedStopStatusCode } from './unmapped-stop-status-code.enum';

export class UnmappedStopStatus {
  code: UnmappedStopStatusCode;
  message?: string;

  constructor(code: UnmappedStopStatusCode, message?: string) {
    this.code = code;
    this.message = message;
  }
}
