import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DriverLocation } from '@xpo-ltl/sdk-cityoperations';
import { State, StateKey } from './driver-store.state';

export const driversState = createFeatureSelector<State>(StateKey);

export const driversCurrentLocation = createSelector(
  driversState,
  (state: State) => state.driversCurrentLocation
);
