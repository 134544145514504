import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Activity } from '@xpo-ltl/sdk-cityoperations';
import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import { XpoLtlConditioningService } from '@xpo/ngx-ltl';
import { forEach as _forEach, get as _get, map as _map } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { SpecialServicesService } from '../../../../services';

export interface ShipmentSummaryLine {
  proNbr: string;
  consignee: string;
  lp: number;
  mm: number;
  weight: string;
  cube: number;
  specialServices: ShipmentSpecialServiceCd[];
  deliveryWindow: string;
  exceptions: string;
  trailer: string;
  currentSic: string;
  eta: string;
  etaDestination: string;
  shipmentInstId: number;
}

@Component({
  selector: 'pnd-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss'],
  host: { class: 'pnd-ShipmentDetails' },
})
export class ShipmentDetailsComponent implements OnInit {
  @Input()
  activityCode: string;

  @Input()
  activities: Activity[];

  @Input()
  consignee: string;

  @Output()
  viewShipment = new EventEmitter<ShipmentSummaryLine>(undefined);

  private shipmentDetailsDataSourceSubject = new BehaviorSubject<MatTableDataSource<ShipmentSummaryLine>>(undefined);
  shipmentDetailsDataSource$ = this.shipmentDetailsDataSourceSubject.asObservable();

  shipmentDetailsColumns = [
    { id: 'proNbr', label: 'PRO', renderer: (row: ShipmentSummaryLine) => row.proNbr },
    { id: 'consignee', label: 'Consignee', renderer: (row: ShipmentSummaryLine) => row.consignee },
    { id: 'lp', label: 'LP', renderer: (row: ShipmentSummaryLine) => row.lp },
    { id: 'mm', label: 'MM', renderer: (row: ShipmentSummaryLine) => row.mm },
    { id: 'weight', label: 'Weight (lbs.)', renderer: (row: ShipmentSummaryLine) => row.weight },
    { id: 'cube', label: 'Cube', renderer: (row: ShipmentSummaryLine) => row.cube },
    { id: 'specialServices', label: 'Special Services', renderer: (row: ShipmentSummaryLine) => row.specialServices },
    { id: 'deliveryWindow', label: 'Delivery Window', renderer: (row: ShipmentSummaryLine) => row.deliveryWindow },
    { id: 'exceptions', label: 'Exceptions', renderer: (row: ShipmentSummaryLine) => row.exceptions },
    { id: 'trailer', label: 'Trailer', renderer: (row: ShipmentSummaryLine) => row.trailer },
    { id: 'currentSic', label: 'Current SIC', renderer: (row: ShipmentSummaryLine) => row.currentSic },
    { id: 'eta', label: 'ETA', renderer: (row: ShipmentSummaryLine) => row.eta },
    { id: 'etaDestination', label: 'ETA Destination', renderer: (row: ShipmentSummaryLine) => row.etaDestination },
  ];
  shipmentDetailsColumnsToDisplay = _map(this.shipmentDetailsColumns, (c) => c.id);

  constructor(
    private conditioningService: XpoLtlConditioningService,
    private decimalPipe: DecimalPipe,
    private specialServicesService: SpecialServicesService
  ) {}

  ngOnInit() {
    const summaryLines: ShipmentSummaryLine[] = [];

    _forEach(this.activities, (activity) => {
      const shipmentSpecialServices = _get(activity, 'shipmentSpecialServices')
        ? (activity as any).shipmentSpecialServices
        : [];

      const specialServices =
        shipmentSpecialServices.length > 0
          ? this.specialServicesService.getSpecialServicesForSummary(shipmentSpecialServices)
          : [];

      summaryLines.push({
        proNbr: this.conditioningService.conditionProNumber(activity.tripNodeActivity.proNbr, 10),
        consignee: this.consignee,
        lp: activity.tripNodeActivity.totalPiecesCount,
        mm: activity.tripNodeActivity.totalMmCount,
        weight: this.decimalPipe.transform(activity.tripNodeActivity.totalWeightCount),
        cube: activity.tripNodeActivity.totalCubePercentage,
        specialServices: specialServices,
        deliveryWindow: '',
        exceptions: '',
        trailer: '',
        currentSic: '',
        eta: '',
        etaDestination: '',
        shipmentInstId: _get(activity, 'tripNodeActivity.shipmentInstId'),
      });
    });
    this.shipmentDetailsDataSourceSubject.next(new MatTableDataSource(summaryLines));
  }

  cellClicked(row: ShipmentSummaryLine, columnId: string) {
    if (columnId === 'proNbr') {
      this.viewShipment.emit(row);
    }
  }
}
