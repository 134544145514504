import { DeliveryShipmentSearchRecord, Stop } from '@xpo-ltl/sdk-cityoperations';
import { PlanningRouteShipmentIdentifier } from '../../interfaces/event-item.interface';
import { ExistingOrNewRoute } from './existing-or-new-route.enum';

export class AssignToRouteDialogData {
  initialMode: ExistingOrNewRoute;
  // Three use cases:
  //  1. assigning "unassigned" deliveries to a new or existing route
  //  2. assigning a stop on a route to a new or existing route
  //  3. assigning planning route deliveries to a new or existing route
  selectedShipments?: Map<number, DeliveryShipmentSearchRecord[]>;
  selectedStops?: Stop[];
  selectedPlanningShipments?: Map<PlanningRouteShipmentIdentifier, DeliveryShipmentSearchRecord[]>;
}
