import { Action } from '@ngrx/store';
import { PlanningProfileInterface } from './planning-profile.interface';

export enum ActionTypes {
  loadPlanningProfiles = 'planningProfiles.loadPlanningProfiles',
  setPlanningProfiles = 'planningProfiles.setPlanningProfiles',
}

export class LoadPlanningProfilesAction implements Action {
  readonly type = ActionTypes.loadPlanningProfiles;
  constructor() {}
}

export class SetPlanningProfilesStoreAction implements Action {
  readonly type = ActionTypes.setPlanningProfiles;
  constructor(public payload: { planningProfiles: PlanningProfileInterface[] }) {}
}

export type Actions = LoadPlanningProfilesAction | SetPlanningProfilesStoreAction;
