import { Activity, InterfaceAcct } from '@xpo-ltl/sdk-cityoperations';
import {
  TripNodeActivityCd,
  TripNodeStatusCd,
  ShipmentSpecialServiceSummary,
  NodeTypeCd,
  TripNodeTypeCd,
  LatLong,
  StopWindow,
} from '@xpo-ltl/sdk-common';
import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common/lib/enum/shipment-special-service-cd';

export class StopCard {
  tripNodeInstId: number = 0;
  routeInstId: number = 0;
  tripInstId: number = 0;
  origRouteInstId: number = 0;
  origTripInstId: number = 0;
  seqNo: number = 0;
  origSeqNo: number = 0;
  tripNodeSequenceNbr: number = 0;
  tripNodeActivityCd: TripNodeActivityCd;
  canResequenceStop: boolean = true;
  potentialMissedStopWindow: boolean = false;
  ignoreStopWindowInd: boolean = false;

  stopType: string = '';
  consigneeName: string = '';
  consigneeAddress: string = '';
  consigneeCity: string = '';
  consigneeStateCd: string = '';
  consigneePostalCd: string = '';
  consigneeGeoCoordinates: LatLong;
  coordinatesUnknown: boolean;
  coordinatesInvalid: boolean;

  stopWindows: StopWindow[];
  stopWindowTime: string = '';
  stopWindowType: string = '';
  totalWeight: number = 0;
  totalMotorMoves: number = 0;

  specialServices: ShipmentSpecialServiceCd[];

  customer: InterfaceAcct;
  tripNodeStatusCd: TripNodeStatusCd;
  activities: Activity[];
  specialServicesSummary: ShipmentSpecialServiceSummary[];
  nodeTypeCd: NodeTypeCd;
  tripNodeTypeCd: TripNodeTypeCd;

  estimatedArriveDateTime: Date;
  estimatedDepartDateTime: Date;
  estimatedArriveDateTimeLocal: Date;
  estimatedDepartDateTimeLocal: Date;
  planDistanceFromPreviousNode: number;

  idleTimeInMinutes: number = 0;

  constructor() {}

  isExcessIdleTime(): boolean {
    return this.idleTimeInMinutes > 1;
  }
}
