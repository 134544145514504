import { LatLngLiteral } from '@agm/core/services/google-maps-types';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { matDialogAnimations, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { get as _get, isNumber as _isNumber } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface StopMapData {
  consigneeName: string;
  geoCoordinates: LatLngLiteral;
}

@Component({
  selector: 'pnd-stop-map',
  templateUrl: './stop-map.component.html',
  styleUrls: ['./stop-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [matDialogAnimations.dialogContainer],
  host: {
    class: 'pnd-StopMap',
  },
})
export class StopMapComponent implements OnInit {
  private locationSubject = new BehaviorSubject<{ lat: number; lng: number }>(undefined);
  location$ = this.locationSubject.asObservable();

  private consigneeNameSubject = new BehaviorSubject<string>(undefined);
  consigneeName$ = this.consigneeNameSubject.asObservable().pipe(filter((val) => val !== undefined));

  readonly defaultZoom: number = 19;
  private zoomSubject = new BehaviorSubject<number>(null);
  zoom$ = this.zoomSubject.asObservable().pipe(filter((val) => val !== null));
  map: google.maps.Map;
  shouldShowMarkers: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<StopMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StopMapData,
    private loggingService: LoggingApiService
  ) {}

  ngOnInit() {
    this.shouldShowMarkers = false;
    // on animation done
    this.dialogRef.afterOpened().subscribe(() => {
      this.consigneeNameSubject.next(_get(this.data, 'consigneeName', ''));
      this.initMap();
    });
  }

  /**
   * Initializes/broadcast map data
   */
  initMap(): void {
    const isLatNumber = _isNumber(_get(this.data, 'geoCoordinates.latitude'));
    const isLngNumber = _isNumber(_get(this.data, 'geoCoordinates.longitude'));
    if (!isLatNumber || !isLngNumber) {
      this.loggingService.info(`Missing geo coordinates for ${this.data}`);
    } else {
      this.zoomSubject.next(this.defaultZoom);
      this.locationSubject.next({
        lat: _get(this.data, 'geoCoordinates.latitude'),
        lng: _get(this.data, 'geoCoordinates.longitude'),
      });
    }
  }

  /**
   * Callback when map initialization on gmap api is done
   * @param map
   */
  // tslint:disable-next-line: no-shadowed-variable
  mapReady(map: google.maps.Map): void {
    this.map = map;
    this.shouldShowMarkers = true;
    this.map.setMapTypeId(google.maps.MapTypeId.HYBRID);
  }
}
