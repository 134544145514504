import { Component, ViewEncapsulation } from '@angular/core';
import { RouteStatusCdHelper, RouteStatusCd } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { get as _get, isEmpty as _isEmpty } from 'lodash';
import { take } from 'rxjs/operators';

@Component({
  selector: 'pnd-release-route-toggle-cell-renderer',
  templateUrl: './release-route-toggle-cell-renderer.html',
  styleUrls: ['./release-route-toggle-cell-renderer.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-ReleaseRouteToggleCellRenderer' },
})
export class ReleaseRouteToggleCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  isVisible = false;
  isChecked = false;
  isDisabled = true;

  agInit(params: any): void {
    this.params = params;

    const statusCd = RouteStatusCdHelper.toEnum(_get(params, 'data.route.statusCd'));
    this.isVisible =
      statusCd === RouteStatusCd.UNRELEASED ||
      statusCd === RouteStatusCd.RELEASED ||
      statusCd === RouteStatusCd.LOADING;
    this.isChecked = _get(params, 'data.route.xdockReleaseInd', false);
    const startTime = _get(params, 'data.route.deliveryRouteDepartTime', '00:00');
    this.isDisabled = startTime === '00:00';
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  onClick(clickEvent) {
    clickEvent.stopImmediatePropagation();

    if (this.isVisible && this.params.onClick) {
      this.params.onClick(this.params);
    }
  }
}
