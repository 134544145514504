import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pnd-stops-not-found',
  templateUrl: './stops-not-found.component.html',
  styleUrls: ['./stops-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StopsNotFoundComponent {
  @Input()
  get routeSelected(): boolean {
    return this.routeSelectedValue;
  }
  set routeSelected(value: boolean) {
    this.routeSelectedValue = coerceBooleanProperty(value);
  }
  private routeSelectedValue = false;
}
