import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { get as _get, some as _some } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, concatMapTo, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { PndStoreState } from '..';
import { UnassignedPickupsService } from '../../inbound-planning/shared/services/unassigned-pickups.service';
import {
  ActionTypes,
  Refresh,
  SetFocusedUnassignedPickupAction,
  SetSearchCriteriaAction,
  SetSelectedUnassignedPickupsAction,
} from './unassigned-pickups-store.actions';

@Injectable()
export class UnassignedPickupsStoreEffects {
  constructor(
    private actions$: Actions,
    private unassignedPickupsService: UnassignedPickupsService,
    private store$: Store<PndStoreState.State>
  ) {}

  @Effect()
  setSearchCriteria$: Observable<Action> = this.actions$.pipe(
    ofType<SetSearchCriteriaAction>(ActionTypes.setSearchCriteria),
    concatMapTo([new Refresh()])
  );

  // refresh the pickups based on current searchCriteria
  @Effect()
  refresh$: Observable<Action> = this.actions$.pipe(
    ofType<SetSearchCriteriaAction>(ActionTypes.setSearchCriteria),
    map((action) => action.payload.criteria),
    switchMap((criteria) => this.unassignedPickupsService.searchUnassignedPickups(criteria)),
    withLatestFrom(this.store$),
    switchMap(([result, store]) => {
      let filteredSelectedPickups = [];
      if (!!_get(result, 'unassignedPickups', undefined)) {
        const pickupsInstId = result.unassignedPickups.map((pickup) => _get(pickup, 'pickupInstId'));
        filteredSelectedPickups = store.unassignedPickupsState.selectedPickups.filter((pickup) =>
          _some(pickupsInstId, (pickupAcctInstId) => pickupAcctInstId === _get(pickup, 'id.pickupInstId'))
        );
      }
      return [
        new SetFocusedUnassignedPickupAction({ focusedPickup: undefined }),
        new SetSelectedUnassignedPickupsAction({ selectedPickups: filteredSelectedPickups }),
      ];
    }),
    catchError(() => of(undefined))
  );
}
