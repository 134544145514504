import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ViewChild,
  AfterViewInit,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { FormGroup, FormBuilder, ValidationErrors, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ErrorStateMatcher } from '@angular/material/core';
import { ValidationRegexPatterns, FormatValidationService } from '@xpo-ltl/common-services';
import { RouteStatusCd, RouteCategoryCd } from '@xpo-ltl/sdk-common';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { padStart as _padStart, isEmpty as _isEmpty } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { dynamicValidator } from '../../../../../core/validators/dynamic-validator';
import { FormUtils } from '../../classes/form-utils.class';
import { TimeValidationEnum } from '../../enums/time-validation.enum';
import { OnAnyInputErrorStateMatcher } from '../../matchers/on-any-input-error-state.matcher';
import { EquipmentPipe } from '../../pipes';
import { RemoveLeadingZerosPipe } from '../../pipes/remove-leading-zeros.pipe';
import { RouteService } from '../../services/route.service';
import { TimeUtil } from '../../services/time-format.util';
import { ReleaseRouteDialogData } from './release-route-dialog-data';
import { ReleaseRouteDialogResults } from './release-route-dialog-results';
import { ReleaseRouteFormFields } from './release-route-form-fields';

@Component({
  selector: 'pnd-release-route',
  templateUrl: './release-route.component.html',
  styleUrls: ['./release-route.component.scss'],
  host: { class: 'pnd-ReleaseRoute' },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReleaseRouteComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('routeStartTime', { static: true })
  routeStartTime;
  @ViewChild('doorNumber', { static: true })
  doorNumber;
  @ViewChild('trailerNumber', { static: true })
  trailerNumber;
  @ViewChild('dockLocation', { static: true })
  dockLocation: ElementRef;
  @ViewChild('nearestDoor', { static: true })
  nearestDoor;

  formGroup: FormGroup;
  dialogTitle: string;
  errorStateMatcher: ErrorStateMatcher = new OnAnyInputErrorStateMatcher();
  previousValue: string = '';

  readonly ReleaseRouteFormFields = ReleaseRouteFormFields;
  readonly ValidationRegexPatterns = ValidationRegexPatterns;
  readonly RouteStatusCd = RouteStatusCd;
  readonly RouteCategoryCd = RouteCategoryCd;

  private unsubscriber = new Unsubscriber();

  private showSpinnerSubject = new BehaviorSubject<boolean>(false);
  readonly showSpinner$ = this.showSpinnerSubject.asObservable();

  constructor(
    public dialogRef: MatDialogRef<ReleaseRouteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReleaseRouteDialogData,
    private formBuilder: FormBuilder,
    private validationService: FormatValidationService,
    private equipmentPipe: EquipmentPipe,
    private routeService: RouteService,
    private removeLeadingZerosPipe: RemoveLeadingZerosPipe
  ) {}

  ngOnInit() {
    this.dialogTitle = this.data.routeCategoryCd !== RouteCategoryCd.DELIVERY ? 'Planning Route' : 'Release Route';

    const validatorFn =
      this.data.routeCategoryCd === RouteCategoryCd.DELIVERY
        ? [TimeUtil.timeFormatValidatorFunction(), Validators.maxLength(5), Validators.required]
        : undefined;

    this.formGroup = this.formBuilder.group({
      [ReleaseRouteFormFields.RouteStartTime]: [this.data.startTime, validatorFn],
      [ReleaseRouteFormFields.DoorNumber]: [
        this.removeLeadingZerosPipe.transform(this.data.doorNbr),
        [
          dynamicValidator(
            () => this.hasValue(ReleaseRouteFormFields.DoorNumber),
            this.validateDoorNumber.bind(this, ReleaseRouteFormFields.DoorNumber)
          ),
        ],
      ],
      [ReleaseRouteFormFields.TrailerNumber]: [
        this.data.trailerNbr,
        [
          dynamicValidator(
            () => this.hasValue(ReleaseRouteFormFields.TrailerNumber),
            this.validateTrailerNumber.bind(this)
          ),
        ],
      ],
      [ReleaseRouteFormFields.DockLocation]: [this.data.dockLocation],
      [ReleaseRouteFormFields.NearestDoor]: [
        this.removeLeadingZerosPipe.transform(this.data.nearestDoorNbr),
        [
          dynamicValidator(
            () => this.hasValue(ReleaseRouteFormFields.NearestDoor),
            this.validateDoorNumber.bind(this, ReleaseRouteFormFields.NearestDoor)
          ),
        ],
      ],
    });

    this.formGroup.get(ReleaseRouteFormFields.DoorNumber).valueChanges.subscribe((val) => {
      this.formGroup
        .get(ReleaseRouteFormFields.DoorNumber)
        .setValue(this.removeLeadingZerosPipe.transform(val), { emitEvent: false });
    });
    this.removeLeadingZerosPipe.transform(this.data.nearestDoorNbr),
      this.formGroup.get(ReleaseRouteFormFields.NearestDoor).valueChanges.subscribe((val) => {
        this.formGroup
          .get(ReleaseRouteFormFields.NearestDoor)
          .setValue(this.removeLeadingZerosPipe.transform(val), { emitEvent: false });
      });

    this.onRouteValueChangeEnlighteningTimeInput();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      let element: ElementRef;

      switch (this.data.focusedField) {
        case ReleaseRouteFormFields.DoorNumber:
          element = this.doorNumber;
          break;
        case ReleaseRouteFormFields.TrailerNumber:
          element = this.trailerNumber;
          break;
        case ReleaseRouteFormFields.DockLocation:
          element = this.dockLocation;
          break;
        case ReleaseRouteFormFields.NearestDoor:
          element = this.nearestDoor;
          break;
        default:
          element = this.routeStartTime;
      }

      element.nativeElement.focus();
      element.nativeElement.select();

      this.formGroup.markAllAsTouched();
      this.formGroup.updateValueAndValidity();
    }, 250);
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  onRouteValueChangeEnlighteningTimeInput(): void {
    this.formGroup
      .get(ReleaseRouteFormFields.RouteStartTime)
      .valueChanges.pipe(takeUntil(this.unsubscriber.done))
      .subscribe((nextValue: string) => this.formControlValueChangesSubscription(nextValue));
  }

  formControlValueChangesSubscription(nextValue: string): void {
    if (nextValue) {
      const timeInput = {
        previousValue: this.previousValue,
        nextValue: nextValue,
      };

      const timeFixed = TimeUtil.inputTimeFormControlEnlightening(timeInput);

      this.previousValue = timeFixed.previousValue;

      if (timeFixed.nextValue === TimeValidationEnum.ShouldReplace) {
        this.formGroup
          .get(ReleaseRouteFormFields.RouteStartTime)
          .setValue(timeFixed.previousValue, { emitEvent: false });
      }
    } else {
      this.previousValue = '';
    }
  }

  saveAndReleaseClicked(): void {
    const dialogData = this.getFormInputValues();
    dialogData.isReleased = true;

    this.saveRoute(dialogData);
  }

  saveAndCloseClicked(): void {
    const dialogData = this.getFormInputValues();
    // NOTE: Retain current route status if released
    dialogData.isReleased = this.data.xdockReleaseInd;

    this.saveRoute(dialogData);
  }

  cancelClicked(): void {
    this.dialogRef.close();
  }

  handleTimeBlur(): void {
    const control = this.formGroup.get(ReleaseRouteFormFields.RouteStartTime);
    const val = control.value;

    if (val && control.invalid) {
      control.setValue(TimeUtil.formatStringToTimeStringByFillingWithZeros(val), { emitEvent: true });
    }
  }

  handleDoorBlur(formField: ReleaseRouteFormFields): void {
    const control = this.formGroup.get(formField);
    let val = control.value;

    if (val) {
      val = _padStart(val, 4, '0');
      control.setValue(val, { emitEvent: true });
    }
  }

  handleTrailerBlur(): void {
    const control = this.formGroup.get(ReleaseRouteFormFields.TrailerNumber);
    const val: string = control.value;

    if (val && val.includes('-')) {
      const prefix = val.substr(0, val.indexOf('-'));
      const suffix = val.substr(val.indexOf('-') + 1);
      const equipFormatted = this.equipmentPipe.transform(prefix, +suffix);
      control.setValue(!_isEmpty(equipFormatted) ? equipFormatted : val);
    }
  }

  hasErrors(formField: ReleaseRouteFormFields): boolean {
    if (!this.formGroup) {
      return false;
    }

    return !_isEmpty(this.formGroup.get(formField).errors);
  }

  hasError(formField: ReleaseRouteFormFields, errorKey: string): boolean {
    return FormUtils.hasError(this.formGroup, formField, errorKey);
  }

  hasValue(formField: ReleaseRouteFormFields): boolean {
    if (!this.formGroup) {
      return false;
    }

    const control = this.formGroup.get(formField);

    return !_isEmpty(control.value);
  }

  private getFormInputValues(): ReleaseRouteDialogResults {
    const ctrlValFunc = (formField: ReleaseRouteFormFields) => {
      const control = this.formGroup.get(formField);
      return control.value;
    };

    return {
      focusedField: this.data.focusedField,
      routeName: this.data.routeName,
      routeInstId: this.data.routeInstId,
      routeStatusCd: this.data.routeStatusCd,
      routeCategoryCd: this.data.routeCategoryCd,
      xdockReleaseInd: this.data.xdockReleaseInd,
      startTime: ctrlValFunc(ReleaseRouteFormFields.RouteStartTime),
      doorNbr: ctrlValFunc(ReleaseRouteFormFields.DoorNumber),
      trailerNbr: ctrlValFunc(ReleaseRouteFormFields.TrailerNumber),
      dockLocation: ctrlValFunc(ReleaseRouteFormFields.DockLocation),
      nearestDoorNbr: ctrlValFunc(ReleaseRouteFormFields.NearestDoor),
      isReleased: false,
    };
  }

  private validateDoorNumber(formField: ReleaseRouteFormFields): ValidationErrors | null {
    if (!this.formGroup || !this.formGroup.get(formField)) {
      return null;
    }

    const control = this.formGroup.get(formField);
    if (!_isEmpty(control.value)) {
      const validationErrors = +control.value <= 0 ? { invalid: true } : null;
      return validationErrors;
    }
    return null;
  }

  private validateTrailerNumber(): ValidationErrors | null {
    if (!this.formGroup) {
      return null;
    }

    const control = this.formGroup.get(ReleaseRouteFormFields.TrailerNumber);
    if (!_isEmpty(control.value)) {
      if (!this.validationService.isValidTrailerNumber(control.value)) {
        return { invalid: true };
      } else {
        const eqpSfx = +control.value.substr(control.value.indexOf('-') + 1);
        return eqpSfx > 0 ? null : { invalid: true };
      }
    }
    return null;
  }

  private validateStartTime(): ValidationErrors | null {
    if (!this.formGroup) {
      return null;
    }

    const validTimeRegExp = new RegExp(TimeUtil.TimeFormatValidatorRegExpWithAnchors);

    const control = this.formGroup.get(ReleaseRouteFormFields.RouteStartTime);
    const val = control.value;

    return !_isEmpty(val) ? (!validTimeRegExp.test(val) || val === '00:00' ? { invalid: true } : null) : null;
  }

  private saveRoute(dialogData: ReleaseRouteDialogResults): void {
    this.showSpinnerSubject.next(true);

    this.routeService
      .releaseRoute(
        dialogData.routeName,
        dialogData.routeInstId,
        dialogData.doorNbr,
        dialogData.startTime,
        dialogData.dockLocation,
        dialogData.nearestDoorNbr,
        dialogData.trailerNbr,
        dialogData.isReleased
      )
      .subscribe(
        (results) => {
          if (results) {
            this.showSpinnerSubject.next(false);

            this.dialogRef.close(results);
          }
        },
        (error) => {
          this.showSpinnerSubject.next(false);
        }
      );
  }
}
