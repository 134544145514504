import { ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { XpoFilterParams, XpoInlineFilter } from '@xpo-ltl/ngx-ltl-board';
import { TimeFilterComponent } from './time-filter.component';

export interface TimeFilterParams extends XpoFilterParams {
  validators?: ValidatorFn[];
  title?: string;
  inputDebounceTime?: number;
  matcher?: ErrorStateMatcher;
}

export class TimeFilter extends XpoInlineFilter {
  static readonly DefaultInputDebounceTime = 500;

  validators: ValidatorFn[];
  title: string;
  inputDebounceTime: number;
  matcher: ErrorStateMatcher;

  constructor(params: TimeFilterParams | any) {
    super(params.field, params.label, TimeFilterComponent);

    this.title = params.title || '';
    this.validators = params.validators || null;
    this.inputDebounceTime = params.inputDebounceTime || TimeFilter.DefaultInputDebounceTime;
    this.matcher = params.matcher || null;
  }

  getDisplayValue(val: any): any {
    return val;
  }
}
