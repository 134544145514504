import { Store } from '@ngrx/store';
import { XpoBoardViewDataStoreBase } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewConfig } from '@xpo-ltl/ngx-ltl-board-grid';
import { forEach as _forEach } from 'lodash';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { GlobalFilterStoreSelectors, PndStoreState } from '../../../store';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { UnassignedDeliveriesComponentName } from './unassigned-deliveries-component-name';

// default View tabs
export enum UnassignedDeliveriesViewId {
  ShowAll = 'showAll',
  ArrivalByNoon = 'arrivalByNoon',
  CurrentSic = 'currentSic',
}

/**
 * Shared View Data source for Stops and Shipments views
 */
export class UnassignedDeliveriesViewDataStore extends XpoBoardViewDataStoreBase {
  constructor(
    private userPreferencesService: UserPreferencesService,
    protected preferences: XpoAgGridBoardViewConfig[],
    private pndStore$: Store<PndStoreState.State>
  ) {
    super();
  }

  /**
   * Return list of all available View configs
   */
  getAll(): Observable<XpoAgGridBoardViewConfig[]> {
    return this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic).pipe(
      take(1),
      map((sicCd) => {
        const viewArray: XpoAgGridBoardViewConfig[] = [
          this.showAllConfig(),
          this.secondaryDefaultConfig(sicCd),
          this.defaultConfig(),
        ];
        _forEach(this.preferences, (element: XpoAgGridBoardViewConfig) => {
          // system defined elements are the default ones - "arrival by noon" and "current sic",
          // since they will be pushed anyways, filter them out in the parsedPreferences
          if (!element.systemDefined) {
            viewArray.push({
              closeable: element.closeable,
              criteria: element.criteria,
              id: element.id,
              name: element.name,
              visible: element.visible,
              visibleColumns: element.visibleColumns,
              systemDefined: element.systemDefined || false,
              children: element.children || [],
              lastSaved: element.lastSaved,
              sortOrder: element.sortOrder || [],
              templateId: element.templateId || '',
            });
          }
        });
        return viewArray;
      })
    );
  }

  /**
   * update the preferences based on what was stored in user preferences
   */
  updateDataStore(viewConfigs: XpoAgGridBoardViewConfig[]): Observable<XpoAgGridBoardViewConfig[]> {
    return this.userPreferencesService.updatePreferencesFor<XpoAgGridBoardViewConfig[]>(
      UnassignedDeliveriesComponentName,
      viewConfigs
    );
  }

  private showAllConfig(): XpoAgGridBoardViewConfig {
    return {
      closeable: false,
      criteria: {},
      id: UnassignedDeliveriesViewId.ShowAll,
      name: 'Show All',
      visible: true,
      systemDefined: true,
    };
  }

  private defaultConfig(): XpoAgGridBoardViewConfig {
    return {
      closeable: false,
      criteria: {
        scheduleETA: '12:00',
      },
      id: UnassignedDeliveriesViewId.ArrivalByNoon,
      name: 'Arrival By Noon',
      visible: true,
      systemDefined: true,
    };
  }

  private secondaryDefaultConfig(sicCd: string): XpoAgGridBoardViewConfig {
    const config = {
      closeable: false,
      criteria: {
        currentSicCd: [sicCd],
      },
      id: UnassignedDeliveriesViewId.CurrentSic,
      name: 'Arrived',
      visible: true,
      systemDefined: true,
    };

    return config;
  }
}
