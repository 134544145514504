import { GeocoderLocationType } from '@agm/core';
import { Injectable } from '@angular/core';
import { LatLong } from '@xpo-ltl/sdk-common';
import {
  CustomerApiService,
  UpdateGeoCoordinatesForCustQuery,
  UpdateGeoCoordinatesForCustRqst,
} from '@xpo-ltl/sdk-customer';
import { find as _find, first as _first, get as _get, startCase as _startCase, toString as _toString } from 'lodash';
import { forEach as _forEach } from 'lodash';
import { BehaviorSubject, Observable, of, throwError, forkJoin } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { NotificationMessageStatus } from '../../../../core/enums/notification-message-status.enum';
import { NotificationMessageService } from '../../../../core/services/notification-message.service';
import { UnassignedDeliveriesService } from './unassigned-deliveries.service';
import { UnassignedPickupsService } from './unassigned-pickups.service';

export enum GeocodeCustomLocationTypes {
  NON_GEOCODED = 'NON_GEOCODED',
}

export type GeocodeResultLocationType = google.maps.GeocoderLocationType | GeocodeCustomLocationTypes;

export interface GeocodeResult {
  location: google.maps.LatLng;
  locationType: GeocodeResultLocationType;
  address: string;
}

export interface AddressUpdated {
  customerId: number;
  geoCoordinate: LatLong;
}

export type GeocodeRequest = google.maps.GeocoderRequest;

@Injectable({
  providedIn: 'root',
})
export class GeoLocationService {
  private addressUpdatedSubject = new BehaviorSubject<AddressUpdated>(undefined);
  readonly addressUpdated$ = this.addressUpdatedSubject.asObservable();

  private _geocoder: google.maps.Geocoder;
  get geocoder() {
    if (!this._geocoder) {
      this._geocoder = new google.maps.Geocoder();
    }
    return this._geocoder;
  }

  constructor(
    private customerApiService: CustomerApiService,
    private notificationMessageService: NotificationMessageService,
    private unassignedDeliveriesService: UnassignedDeliveriesService,
    private unassignedPickupsService: UnassignedPickupsService
  ) {}

  firstOfLocationType(results: GeocodeResult[], locType: GeocoderLocationType): GeocodeResult {
    return _find(results, (result) => result && result.locationType === locType);
  }

  /**
   * Attempt to geocode the passed address data, returning the location if successful
   */
  geocodeAddress(request: GeocodeRequest): Observable<GeocodeResult> {
    return new Observable((observer) => {
      this.geocoder.geocode(request, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === google.maps.GeocoderStatus.OK) {
          // geocoding found an address
          const res = _first(results);

          const geoResult = {
            location: res.geometry.location,
            locationType: res.geometry.location_type,
            address: _get(res, 'formatted_address', ''),
          };

          observer.next(geoResult);
          observer.complete();
        } else {
          // failed to find address
          observer.error(_startCase(status));
          observer.complete();
        }
      });
    });
  }

  geocodeAddresses(requests: GeocodeRequest[]): Observable<GeocodeResult[]> {
    const observers: Observable<GeocodeResult>[] = [];
    _forEach(requests, (request) => {
      observers.push(
        this.geocodeAddress(request).pipe(
          take(1),
          catchError((error) => of(null))
        )
      );
    });
    return forkJoin(observers);
  }

  /**
   * Update the specified customer's GeoLocation
   * Returns observable that provides the acctInstId when update successful
   */
  updateCustomerGeoLocation(customerId: number, cisCustomerInd: boolean, geoCoordinate: LatLong): Observable<number> {
    const request = {
      ...new UpdateGeoCoordinatesForCustRqst(),
      customerId: _toString(customerId),
      cisCustomerInd,
      geoCoordinate,
    };
    const query = new UpdateGeoCoordinatesForCustQuery();

    return this.customerApiService.updateGeoCoordinatesForCust(request, query).pipe(
      take(1),
      switchMap((response) => {
        if (response && response.successInd) {
          // remove the customer from the list of unmapped deliveries
          this.unassignedDeliveriesService.updateUnmappedDeliveries(
            this.unassignedDeliveriesService.unmappedDeliveries.filter(
              (stop) => stop.consignee.acctInstId !== customerId
            )
          );

          // remove the customer from the list of unmapped pickups
          this.unassignedPickupsService.updateUnassignedPickups(
            this.unassignedPickupsService.unmappedPickups.filter((stop) => stop.shipper.acctInstId !== customerId)
          );

          // Broadcast updated values
          this.addressUpdatedSubject.next({
            customerId,
            geoCoordinate,
          });

          return of(customerId);
        } else {
          // no response or there was an error.  Either way, this is bad
          return throwError('updateGeoCoordinatesForCust failed');
        }
      }),
      catchError((error) => {
        // display error message to user
        this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Error, error)
          .subscribe(() => {});

        return throwError(error);
      })
    );
  }
}
