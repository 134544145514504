import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  CityOperationsApiService,
  DockLocation,
  ReassignPnDShipmentsPath,
  ReassignPnDShipmentsRqst,
  Route,
  Trip,
  TripNode,
  UnassignPnDShipmentsFromRoutePath,
  UnassignPnDShipmentsFromRouteRqst,
  UnassignStopsRqst,
  UpdatePnDRoutePath,
  UpdatePnDRouteResp,
  UpdatePnDRouteRqst,
} from '@xpo-ltl/sdk-cityoperations';
import { ShipmentId } from '@xpo-ltl/sdk-common';
import { findIndex as _findIndex, get as _get, isEmpty as _isEmpty } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { NotificationMessageStatus } from '../../../../core/enums/notification-message-status.enum';
import { NotificationMessageService } from '../../../../core/services/notification-message.service';
import { GlobalFilterStoreSelectors, PndStoreState } from '../../../store';
import { GenericErrorLazyTypedModel, PartialMoreInfo } from '../models/generic-error-lazy-typed.model';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  constructor(
    private cityOperationsService: CityOperationsApiService,
    private pndStore$: Store<PndStoreState.State>,
    private notificationMessageService: NotificationMessageService
  ) {}

  releaseRoute(
    routeName: string,
    routeInstId: number,
    doorNbr: string,
    startTime: string,
    dockLocation: string,
    nearestDoor: string,
    trailer: string,
    release: boolean
  ): Observable<UpdatePnDRouteResp> {
    return new Observable((observer) => {
      this.pndStore$
        .select(GlobalFilterStoreSelectors.globalFilterSic)
        .pipe(take(1))
        .subscribe((globalFilterSic) => {
          const isTrailerAvailable = trailer && trailer.indexOf('-') > 0;
          const eqpPfx = isTrailerAvailable ? trailer.substr(0, trailer.indexOf('-')) : undefined;
          const eqpSfx = isTrailerAvailable ? +trailer.substr(trailer.indexOf('-') + 1) : undefined;

          const request = {
            ...new UpdatePnDRouteRqst(),
            route: {
              ...new Route(),
              routeInstId: routeInstId,
              terminalSicCd: globalFilterSic,

              plannedDoor: doorNbr,
              equipmentIdPrefix: eqpPfx,
              equipmentIdSuffixNbr: eqpSfx,
              deliveryRouteDepartTime: startTime ? `${startTime}:00` : undefined, // startTime is in HH:mm we need to include seconds as :00
              xdockReleaseInd: release,
              equipmentDockLocation: {
                ...new DockLocation(),
                dockName: (dockLocation || '').toUpperCase(),
                dockClosestDoorNbr: nearestDoor || '',
              },
            },
          };
          const params = { ...new UpdatePnDRoutePath(), routeInstId: routeInstId };

          this.cityOperationsService
            .updatePnDRoute(request, params)
            .pipe(take(1))
            .subscribe(
              (results) => {
                observer.next(results);
                observer.complete();

                if (!_isEmpty(results.messages)) {
                  this.notificationMessageService
                    .openNotificationMessage(NotificationMessageStatus.Info, `${results.messages.join('. ')}`)
                    .subscribe(() => {});
                } else {
                  this.notificationMessageService
                    .openNotificationMessage(NotificationMessageStatus.Success, `Route ${routeName} updated.`)
                    .subscribe(() => {});
                }
              },
              (error) => {
                this.checkTripEquipmentNotFound(error);

                observer.error(error);
                observer.complete();

                this.notificationMessageService
                  .openNotificationMessage(NotificationMessageStatus.Error, error)
                  .subscribe(() => {});
              }
            );
        });
    });
  }

  /**
   * Checks error for Equipment Not Found in moreInfo array and modifies message to empty string
   * @param error Error object from updatePnDRoute response
   */
  private checkTripEquipmentNotFound(error: GenericErrorLazyTypedModel): void {
    if (_get(error, 'code') === '404') {
      const equipmentNotFoundIndex = _findIndex(
        _get(error, 'error.moreInfo', []),
        (moreInfo: PartialMoreInfo) => moreInfo.message === 'Equipment Not Found'
      );

      if (equipmentNotFoundIndex > -1) {
        error.error.moreInfo[equipmentNotFoundIndex].message = '';
      }
    }
  }

  reassignShipments(
    prevRouteInstId: number,
    newRouteName: string,
    newRouteInstId: number,
    shipmentList: ShipmentId[]
  ): Observable<void> {
    return new Observable((observer) => {
      const request: ReassignPnDShipmentsRqst = {
        previousRouteInstId: prevRouteInstId,
        shipmentIds: shipmentList,
        overrideTdcInd: false,
        auditInfo: undefined,
      };
      const pathParams = new ReassignPnDShipmentsPath();
      pathParams.routeInstId = newRouteInstId;

      this.cityOperationsService
        .reassignPnDShipments(request, pathParams)
        .pipe(take(1))
        .subscribe(
          () => {
            observer.next();
            observer.complete();

            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Success, `Shipments reassigned to ${newRouteName}.`)
              .subscribe(() => {});
          },
          (error) => {
            observer.error(error);
            observer.complete();

            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, error)
              .subscribe(() => {});
          }
        );
    });
  }

  unassignShipments(routeName: string, routeInstId: number, shipmentList: ShipmentId[]): Observable<void> {
    const request: UnassignPnDShipmentsFromRouteRqst = {
      shipmentIds: shipmentList,
    };
    const pathParams: UnassignPnDShipmentsFromRoutePath = {
      routeInstId: routeInstId,
    };

    return this.cityOperationsService.unassignPnDShipmentsFromRoute(request, pathParams).pipe(
      tap(() => {
        this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Success, `Shipments removed from route ${routeName}.`)
          .subscribe(() => {});
      }),
      catchError((error) => {
        this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Error, error)
          .subscribe(() => {});

        return throwError(error);
      })
    );
  }

  unassignStops(routeName: string, tripInstId: number, tripNodeSequenceNbrs: number[]): Observable<void> {
    return new Observable((observer) => {
      const request = new UnassignStopsRqst();
      request.trip = { ...new Trip(), tripInstId: tripInstId };
      request.tripNodes = tripNodeSequenceNbrs.map((tripNodeSequenceNbr) => ({
        ...new TripNode(),
        tripNodeSequenceNbr: tripNodeSequenceNbr,
      }));
      this.cityOperationsService
        .unassignStops(request)
        .pipe(take(1))
        .subscribe(
          () => {
            observer.next();
            observer.complete();

            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Success, `Route ${routeName} updated.`)
              .subscribe(() => {});
          },
          (error) => {
            observer.error();
            observer.complete();

            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, error)
              .subscribe(() => {});
          }
        );
    });
  }
}
