import { PndBroadcastMessage } from '../../../shared';
import { RouteStopsGridItem } from './route-stops-grid-item.model';

export class RouteStopsBroadcastData implements PndBroadcastMessage {
  constructor(public stops: RouteStopsGridItem[]) {}

  messageId(): string {
    return 'RouteStopsBroadcastData';
  }
}
