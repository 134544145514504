import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-total-text-cell-renderer',
  template: '<span>Total</span>',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalTextCellRendererComponent implements ICellRendererAngularComp {
  agInit(): void {}

  refresh(): boolean {
    return false;
  }
}
