import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { DeliveryShipmentSearchRecord, UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import {
  chain as _chain,
  filter as _filter,
  find as _find,
  forEach as _forEach,
  get as _get,
  map as _map,
  size as _size,
  uniq as _uniq,
} from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, take, withLatestFrom } from 'rxjs/operators';
import { FeatureTypes } from '../../../../../core/services/features/feature-types.enum';
import {
  GlobalFilterStoreSelectors,
  PndStoreState,
  UnassignedDeliveriesStoreActions,
  UnassignedDeliveriesStoreSelectors,
} from '../../../../store';
import {
  AssignToRouteComponent,
  GlobalFilterMapCoordinate,
  MapToolbarService,
  SpecialServicesService,
  UnassignedDeliveriesService,
} from '../../../shared';
import { ExistingOrNewRoute } from '../../../shared/components/assign-to-route/existing-or-new-route.enum';
import {
  consigneeToId,
  EventItem,
  UnassignedDeliveryIdentifier,
} from '../../../shared/interfaces/event-item.interface';
import { FeaturesService } from './../../../../../core/services/features/features.service';

export class SelectionSummary {
  stopsSelected: number = 0;
  shipmentsSelected: number = 0;
  motorMovesSelected: number = 0;
  weightSelected: number = 0;
  specialServices: ShipmentSpecialServiceCd[] = [];
}

@Injectable({
  providedIn: 'root',
})
export class UnassignedDeliveriesHeaderService {
  readonly summary$: Observable<SelectionSummary>;

  readonly isGeoFilterActive$: Observable<boolean>;
  readonly areDeliveriesSelected$: Observable<boolean>;
  readonly showOptimize$: Observable<boolean>;

  constructor(
    private pndStore$: Store<PndStoreState.State>,
    private unassignedDeliveriesService: UnassignedDeliveriesService,
    private mapToolbarService: MapToolbarService,
    private specialServicesService: SpecialServicesService,
    private featuresService: FeaturesService,
    private dialog: MatDialog
  ) {
    this.areDeliveriesSelected$ = this.pndStore$
      .select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected)
      .pipe(
        distinctUntilChanged(),
        map((items: EventItem<UnassignedDeliveryIdentifier>[]) => {
          return _size(items) > 0;
        })
      );

    this.summary$ = this.pndStore$.select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected).pipe(
      distinctUntilChanged(),
      withLatestFrom(this.unassignedDeliveriesService.unassignedDeliveries$),
      map(([selectedItems, unassignedStops]: [EventItem<UnassignedDeliveryIdentifier>[], UnassignedStop[]]) => {
        return this.calculateSummary(
          _map(selectedItems, (item) => item.id),
          unassignedStops
        );
      })
    );

    this.isGeoFilterActive$ = this.pndStore$
      .select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesBoundingSearchArea)
      .pipe(
        distinctUntilChanged(),
        map((geoAreaCoordinatesArr: GlobalFilterMapCoordinate[]) => {
          return _size(geoAreaCoordinatesArr) > 0;
        })
      );

    this.showOptimize$ = this.pndStore$.select(GlobalFilterStoreSelectors.selectGlobalFilterState).pipe(
      distinctUntilChanged(),
      map((item) => {
        const featureValue = this.featuresService.getFeatureValue(item.sic, FeatureTypes.Optimize);
        return featureValue === 'Y';
      })
    );
  }

  private calculateSummary(
    selectedIds: UnassignedDeliveryIdentifier[],
    unassignedStops: UnassignedStop[]
  ): SelectionSummary {
    if (_size(selectedIds) === 0 || _size(unassignedStops) === 0) {
      return undefined;
    }

    const summary = new SelectionSummary();
    let servicesAccumulator: ShipmentSpecialServiceCd[] = [];

    // count all stops (selections without a shipmentInstId)
    summary.stopsSelected = _chain(selectedIds)
      .map((item) => consigneeToId(item))
      .uniq()
      .size()
      .value();

    const selectedDeliveries = this.deliveriesFromSelected(selectedIds, unassignedStops);

    summary.shipmentsSelected = _size(selectedDeliveries);

    // go through all of the selected deliveries and gather summary data
    _forEach(selectedDeliveries, (deliveryShipment) => {
      summary.motorMovesSelected += deliveryShipment.motorizedPiecesCount;
      summary.weightSelected += deliveryShipment.totalWeightLbs;
      servicesAccumulator = servicesAccumulator.concat(
        this.specialServicesService.getSpecialServicesForSummary(deliveryShipment.specialServiceSummary)
      );
    });

    summary.specialServices = _uniq(servicesAccumulator).sort();

    return summary;
  }

  private deliveriesFromSelected(
    selectedIds: UnassignedDeliveryIdentifier[],
    unassignedStops: UnassignedStop[]
  ): DeliveryShipmentSearchRecord[] {
    const selectedDeliveries: DeliveryShipmentSearchRecord[] = [];

    // gather all shipments in selected stops
    const selectedConsignees = _filter(selectedIds, (item: UnassignedDeliveryIdentifier) => {
      return !item.shipmentInstId;
    });

    _forEach(selectedConsignees, (id: UnassignedDeliveryIdentifier) => {
      // find stop for this selected shipment
      const stop = _find(unassignedStops, (us: UnassignedStop) => {
        return consigneeToId(us) === consigneeToId(id);
      });

      _forEach(_get(stop, 'deliveryShipments', []), (ds: DeliveryShipmentSearchRecord) => {
        selectedDeliveries.push(ds);
      });
    });

    // gather all selected shipments from Items
    const selectedShipments = _filter(selectedIds, (item: UnassignedDeliveryIdentifier) => {
      return !!item.shipmentInstId;
    });
    _forEach(selectedShipments, (id: UnassignedDeliveryIdentifier) => {
      // find stop for this selected shipment
      const stop = _find(unassignedStops, (us: UnassignedStop) => {
        return consigneeToId(us) === consigneeToId(id);
      });

      const shipment = _find(
        _get(stop, 'deliveryShipments', []),
        (ds: DeliveryShipmentSearchRecord) => ds.shipmentInstId === id.shipmentInstId
      );

      if (shipment) {
        selectedDeliveries.push(shipment);
      }
    });

    return selectedDeliveries;
  }

  clearGeoFilter(): void {
    this.mapToolbarService.handleSicClicked();
  }

  clearSelection(): void {
    this.mapToolbarService.toggleDrawModeOff();

    this.pndStore$.dispatch(
      new UnassignedDeliveriesStoreActions.SetSelectedDeliveries({
        selectedDeliveries: [],
      })
    );
  }

  assignRoute(): void {
    this.openAssignToRouteDialog(ExistingOrNewRoute.Existing);
  }

  createRoute(): void {
    this.openAssignToRouteDialog(ExistingOrNewRoute.New);
  }

  private openAssignToRouteDialog(routeType: ExistingOrNewRoute): void {
    // TODO: note we need the full delivery shipment search record structure which includes consignee.
    // BUT we only want the ones selected and currently we get all
    this.pndStore$
      .select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected)
      .pipe(
        take(1),
        withLatestFrom(this.unassignedDeliveriesService.unassignedDeliveries$),
        map(([selectedItems, unassignedStops]) => {
          const selectedIds = _map(selectedItems, (item) => item.id);

          const selectedShipments = new Map<number, DeliveryShipmentSearchRecord[]>();

          // gather all selected DeliveryShipmentSearchRecord
          const deliveries = this.deliveriesFromSelected(selectedIds, unassignedStops);
          _forEach(deliveries, (shipment) => {
            const acctInstId = shipment.consignee.acctInstId;
            const arr = selectedShipments.get(acctInstId) || [];
            arr.push(shipment);
            selectedShipments.set(acctInstId, arr);
          });

          return this.dialog
            .open(AssignToRouteComponent, {
              data: {
                initialMode: routeType,
                selectedShipments,
              },
              disableClose: true,
              hasBackdrop: true,
            })
            .afterClosed();
        })
      )
      .subscribe((actionPerformed) => {
        if (actionPerformed) {
          this.mapToolbarService.toggleDrawModeOff();
        }
      });
  }
}
