import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import {
  filter as _filter,
  get as _get,
  includes as _includes,
  invoke as _invoke,
  isFunction as _isFunction,
  map as _map,
} from 'lodash';
import { pndFrameworkComponents } from '../../../app/inbound-planning/shared';
import { GridRowTransposedData } from './grid-row-transposed-data';

interface TransposedParams {
  xposedField: string; // field to use for this row's label
  xposedColDef: ColDef; // column definition used for rendering row
}

// list of renderers that we do not support.  Default cell renderer is used instead
const unsupportedRenderers: string[] = ['agGroupCellRenderer'];

@Component({
  selector: 'pnd-grid-row-transposed',
  templateUrl: './grid-row-transposed.component.html',
  styleUrls: ['./grid-row-transposed.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridRowTransposedComponent implements OnInit {
  gridTitle: string;

  gridOptions = {
    rowHeight: 40,
    frameworkComponents: pndFrameworkComponents,
    columnDefs: [
      { headerName: 'Field', field: 'xposedField', sortable: true, width: 175 },
      {
        headerName: 'Value',
        field: 'value',
        sortable: true,
        width: 492,
        cellRendererSelector: this.selectRenderer,
        valueGetter: this.valueGetter,
        valueFormatter: this.valueFormatter,
      },
    ],
    rowData: [] as TransposedParams[],
  };

  private originalColumns: ColDef[]; // column definitions from original table

  constructor(@Inject(MAT_DIALOG_DATA) public data: GridRowTransposedData) {
    this.originalColumns = _map(
      _filter(this.data.columnApi.getAllColumns(), (column) => !column.getColDef().headerCheckboxSelection),
      (column) => {
        return column.getColDef();
      }
    );

    this.gridTitle = this.data.dialogTitle;
  }

  ngOnInit() {
    const row = this.data.node.data;

    this.gridOptions.rowData = _map(this.originalColumns, (colDef: ColDef) => {
      return {
        ...row,
        xposedField: colDef.headerName,
        xposedColDef: colDef,
      };
    });
  }

  valueGetter(params: ICellRendererParams) {
    const colDef = _get(params, 'data.xposedColDef') as ColDef;
    let value = _get(params.data, colDef.field);

    if (_isFunction(colDef.valueGetter)) {
      value = _invoke(colDef, 'valueGetter', params);
    }

    return value;
  }

  valueFormatter(params: ValueFormatterParams) {
    const colDef = _get(params, 'data.xposedColDef') as ColDef;
    let formattedValue = params.value;

    if (colDef.valueFormatter) {
      formattedValue = colDef.valueFormatter(params);
    }

    return formattedValue;
  }

  selectRenderer(params: ICellRendererParams) {
    const colDef = _get(params, 'data.xposedColDef') as ColDef;
    let renderer = null;

    if (colDef.cellRenderer && !_includes(unsupportedRenderers, colDef.cellRenderer)) {
      renderer = {
        component: colDef.cellRenderer,
        params: colDef.cellRendererParams,
      };
    }

    return renderer;
  }
}
