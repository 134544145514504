import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { XpoBoardData, XpoBoardState, XpoBoardDataSource } from '@xpo-ltl/ngx-ltl-board';
import {
  CityOperationsApiService,
  RegisterFilterTripsResp,
  RegisterFilterTripsRqst,
  RouteDetail,
  TripDetail,
  TripDetailFilter,
  UnregisterFilterTripsRqst,
} from '@xpo-ltl/sdk-cityoperations';
import { TripStatusCd, XrtAttributeFilter } from '@xpo-ltl/sdk-common';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { XrtChangedDocument, XrtChangedDocuments } from '@xpo/ngx-xrt';
import { XrtFireMessagingService, XrtFireMessagingTokenService } from '@xpo/ngx-xrt-firebase';
import {
  debounce as _debounce,
  forEach as _forEach,
  get as _get,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
} from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { filter, map, take, takeUntil, skip } from 'rxjs/operators';
import { GlobalFilterStoreSelectors, PndStoreState, TripsStoreActions, TripsStoreSelectors } from '../../../../store';
import { TripsSearchCriteria } from '../../../../store/trips-store/trips-search-criteria.interface';
import { GrandTotalService, TripsGridItemConverterService } from '../../../shared';
import { TripPlanningGridItem } from '../models/trip-planning-grid-item.model';
import { BoardStatesEnum } from './../../../../../shared/enums/board-states.enum';

interface FilterParameters {
  sicCd: string;
  planDate: string;
  tripStatusCd: string[];
}

const MIN_TIME_BETWEEN_SEARCHES = 3000; // min time between searches when criteria is the same

@Injectable()
export class TripPlanningDataSource extends XpoBoardDataSource implements OnDestroy {
  private unsubscriber: Unsubscriber = new Unsubscriber();
  private sicCd: string;
  private planDate: Date = new Date();

  private currentFilterHash: string = '';
  private currentFilterParameters: FilterParameters = null;

  private fetchingData: boolean = false;
  private lastSearchCriteriaUpdate: number = 0;

  private elementsUpdatedSubject = new BehaviorSubject<XrtChangedDocument[]>([]);
  debouncingSelection = _debounce((state: XpoBoardState) => this.selectorDispatcher(state), 100);
  get elementsUpdated$(): Observable<XrtChangedDocument[]> {
    return this.elementsUpdatedSubject.asObservable();
  }

  constructor(
    private cityOperationsService: CityOperationsApiService,
    private pndStore$: Store<PndStoreState.State>,
    private messagingTokenService: XrtFireMessagingTokenService,
    private xrtFireMessagingService: XrtFireMessagingService,
    private grandTotalService: GrandTotalService,
    private tripsGridItemConverterService: TripsGridItemConverterService
  ) {
    super();

    this.subscribeToStoreChanges();
    this.subscribeToStateChanges();
    this.subscribeToGlobalFilterChange();
    this.subscribeToAutoRefresh();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
    this.unregisterFilter(this.currentFilterHash);
  }

  /**
   * Listen for remote updates notifying us of document changes
   */
  private subscribeToAutoRefresh() {
    // list for updated documents from auto-refresh
    this.xrtFireMessagingService
      .changedDocument$()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((changedDocument: XrtChangedDocuments) => {
        this.updateRefreshedDocuments({
          filterHash: changedDocument.filterHash,
          documents: JSON.parse(changedDocument.documents.toString()),
        });
      });

    new BroadcastChannel('pnd-notification-broadcast-channel').onmessage = (event) => {
      this.updateRefreshedDocuments({
        filterHash: event.data.data.filterHash,
        documents: JSON.parse(event.data.data.changedDocumentsJson),
      });
    };
  }

  private updateRefreshedDocuments(changedDocuments: XrtChangedDocuments) {
    if (changedDocuments.filterHash === this.currentFilterHash) {
      console.log('Trip changes received', changedDocuments);
      // this.pndStore$.dispatch(new TripsStoreActions.SetChangedTrips({ changedRoutes: changedDocuments.documents }));
      this.elementsUpdatedSubject.next(changedDocuments.documents);
    }
  }

  private registerFilter(filterParameters: FilterParameters): void {
    this.messagingTokenService.getToken$().subscribe((token) => {
      const request = new RegisterFilterTripsRqst();
      request.connectionToken = token;
      request.filter = new TripDetailFilter();
      request.filter.sicCd = { ...new XrtAttributeFilter(), equals: filterParameters.sicCd };
      request.filter.tripDate = { ...new XrtAttributeFilter(), equals: filterParameters.planDate };
      request.filter.tripStatusCd = { ...new XrtAttributeFilter(), values: filterParameters.tripStatusCd };

      this.cityOperationsService.registerFilterTrips(request).subscribe((response: RegisterFilterTripsResp) => {
        this.currentFilterHash = response.filterHash;
      });
    });
  }

  private unregisterFilter(filterHash: string): void {
    if (filterHash) {
      this.messagingTokenService.getToken$().subscribe((token) => {
        const request = new UnregisterFilterTripsRqst();
        request.connectionToken = token;
        request.filterHash = filterHash;

        this.cityOperationsService.unregisterFilterTrips(request).subscribe();
      });
    }
  }

  computeTotals(rows: TripDetail[]) {
    let totalBills = 0;
    let totalWeight = 0;
    let totalMM = 0;
    let totalStops = 0;

    (rows || []).forEach((row: TripDetail) => {
      _get(row, 'route', []).forEach((route: RouteDetail) => {
        totalBills += _get(route, 'route.totalBillCount', 0);
        totalWeight += _get(route, 'route.totalWeightCount', 0);
        totalMM += _get(route, 'route.totalMmCount', 0);
      });
      totalStops += _get(row, 'trip.stopCount', 0);
    });

    return [
      {
        route: { totalBillCount: totalBills, totalWeightCount: totalWeight, totalMmCount: totalMM },
        tripStopCount: totalStops,
      },
    ];
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    if (state.changes.includes('viewId')) {
      // When switching views, we need to wait until the Store updates the list.
      // however, fetchData is called before refresh, so we end up getting the previous view's
      // list, which causes the view to display incorrect data initially until the refresh occurs.
      // To combat that, return an empty data set when changing views. fetchData will be called
      // again once the Store is updated
      return of(new XpoBoardData(state, [], 0, 10000));
    } else {
      // fetch the latest Trips from the Store to provide to the Board
      return this.pndStore$.select(TripsStoreSelectors.trips).pipe(
        take(1),
        map((trips) => {
          const gridItems: TripPlanningGridItem[] = this.tripsGridItemConverterService.getTripsGridItems(trips);
          return new XpoBoardData(
            state,
            { rows: gridItems, totals: this.computeTotals(trips) },
            gridItems ? gridItems.length : 0,
            10000
          );
        })
      );
    }
  }

  refresh() {
    if (!this.fetchingData) {
      // not currently fetching data, so trigger update by updating
      // the search criteria
      this.state$.pipe(take(1)).subscribe((state) => {
        this.fetchingData = true;
        this.updateSearchFilters(state);
      });
    } else {
      // we have completed fetching data from service, so update the grid
      super.refresh();
      this.fetchingData = false;
    }
  }

  private subscribeToGlobalFilterChange(): void {
    const sicStore = this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic);
    const planDateStore = this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterPlanDate);

    combineLatest([sicStore, planDateStore])
      .pipe(
        filter(([sicCd, planDate]) => !!sicCd),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(([sicCd, planDate]) => {
        this.sicCd = sicCd;
        this.planDate = planDate;
        this.refresh();
      });
  }

  /**
   * Update the Grid to reflect the current state of the Redux Store
   */
  private subscribeToStoreChanges() {
    // we need t refresh the board every time the Routes changes in the Store
    this.pndStore$
      .select(TripsStoreSelectors.trips)
      .pipe(skip(1), takeUntil(this.unsubscriber.done))
      .subscribe((trips) => {
        this.grandTotalService.updateTripsGrandTotals(trips);
        this.fetchingData = true;

        // update the the grid.
        this.refresh();
      });
  }

  private subscribeToStateChanges(): void {
    this.state$
      .pipe(
        filter(
          (currentState) =>
            currentState.changes.includes('selection') && currentState.source === BoardStatesEnum.SELECTION_CHANGE
        ),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((state) => {
        // need to also select the Routes that the selected Trips use

        this.pndStore$
          .select(TripsStoreSelectors.selectedTrips)
          .pipe(
            take(1),
            filter((selectedTrips: TripPlanningGridItem[]) => {
              return (
                selectedTrips.length !== state.selection.length ||
                !_isEqual(
                  selectedTrips.map((trp) => trp.keyField).sort(),
                  state.selection.map((trp) => trp.keyField).sort()
                )
              );
            })
          )
          .subscribe((selectedTrips: TripPlanningGridItem[]) => {
            this.debouncingSelection(state);
          });
      });

    this.state$
      .pipe(
        filter((currentState) => {
          return (
            currentState.source === BoardStatesEnum.ACTIVE_VIEW_CHANGE ||
            currentState.source === BoardStatesEnum.ADD_NEW_VIEW
          );
        }),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(() => {
        this.refresh();
      });

    this.state$
      .pipe(
        filter((state: XpoBoardState) => state.source === BoardStatesEnum.FILTER_CHANGE),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((state: XpoBoardState) => {
        this.updateSearchFilters(state);
      });
  }

  private selectorDispatcher(state: XpoBoardState): void {
    // selection state on the board has changed.  Update the Store
    // with selection state
    this.pndStore$.dispatch(
      new TripsStoreActions.SetSelectedTrips({
        selectedTrips: state.selection as TripPlanningGridItem[],
      })
    );
  }

  /**
   * Update the Search criteria through the Store in order to
   * update the list of Trips
   */
  private updateSearchFilters(state: XpoBoardState): void {
    const buildStatusCodes = (statuses: string[]) => {
      const statusesValue = statuses || [];
      const statusesFilter = [];

      if (statusesValue.some((s) => s === 'new')) {
        statusesFilter.push(TripStatusCd.NEW_TRIP);
      }
      if (statusesValue.some((s) => s === 'completed')) {
        statusesFilter.push(TripStatusCd.COMPLETED);
      }
      if (statusesValue.some((s) => s === 'dispatched')) {
        statusesFilter.push(TripStatusCd.DISPATCHED);
      }
      if (statusesValue.some((s) => s === 'returning')) {
        statusesFilter.push(TripStatusCd.RETURNING);
      }
      return statusesFilter;
    };

    const criteria: TripsSearchCriteria = {
      sicCd: this.sicCd,
      tripStatusCd: buildStatusCodes(state.criteria.tripStatusCd),
      tripDate: moment(this.planDate).format('YYYY-MM-DD'),
    };

    const filterParameters: FilterParameters = {
      sicCd: criteria.sicCd,
      planDate: criteria.tripDate,
      tripStatusCd: criteria.tripStatusCd,
    };

    if (!_isEqual(filterParameters, this.currentFilterParameters) && !_isEmpty(filterParameters.sicCd)) {
      this.unregisterFilter(this.currentFilterHash);
      this.registerFilter(filterParameters);
      this.currentFilterParameters = filterParameters;
    }

    // is the new criteria different from the old, or has a minimum time passed since our last search?
    this.pndStore$
      .select(TripsStoreSelectors.searchCriteria)
      .pipe(take(1))
      .subscribe((previousCriteria) => {
        const now = Date.now();
        const diff = now - this.lastSearchCriteriaUpdate;
        if (!_isEqual(previousCriteria, criteria) || diff > MIN_TIME_BETWEEN_SEARCHES) {
          this.lastSearchCriteriaUpdate = now;
          this.pndStore$.dispatch(new TripsStoreActions.SetTripsSearchCriteria({ criteria }));
        }
      });
  }
}
