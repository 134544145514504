import { Action } from '@ngrx/store';
import { DriverLocation } from '@xpo-ltl/sdk-cityoperations';

export enum ActionTypes {
  setDriversCurrentLocation = 'drivers.setDriversCurrentLocation',
  fetchDriversCurrentLocation = 'drivers.fetchDriversCurrentLocation',
  updateDriversCurrentLocation = 'drivers.updateDriversCurrentLocation',
}

export class SetDriversCurrentLocationAction implements Action {
  readonly type = ActionTypes.setDriversCurrentLocation;
  constructor(public payload: { driversCurrentLocation: DriverLocation[] }) {}
}

export class FetchDriversCurrentLocationAction implements Action {
  readonly type = ActionTypes.fetchDriversCurrentLocation;
  constructor(public payload: { sicCd: string }) {}
}

export class UpdateDriversCurrentLocationAction implements Action {
  readonly type = ActionTypes.updateDriversCurrentLocation;
  constructor(public payload: { updatedDriverLocations: DriverLocation[] }) {}
}

export type Actions =
  | SetDriversCurrentLocationAction
  | FetchDriversCurrentLocationAction
  | UpdateDriversCurrentLocationAction;
