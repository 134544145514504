import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { GetEmployeeDetailsByEmpIdResp } from '@xpo-ltl/sdk-humanresource';
import { XpoLtlEmployeeDetailsCacheService, XpoLtlServiceCentersService } from '@xpo/ngx-ltl';
import { get as _get, result as _result } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

interface EmployeeSummary {
  employeeId: string;
  jobTitle: string;
  department: string;
  phone: string;
  email: string;
  address: string;
  cityStateZip: string;
  sicCode: string;
  supervisor: string;
}

@Component({
  selector: 'pnd-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss'],
  host: { class: 'pnd-EmployeeDetails' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeDetailsComponent implements OnInit {
  @Input()
  employeeId: string;

  private employeeSummarySubject = new BehaviorSubject<EmployeeSummary>(undefined);
  employeeSummary$ = this.employeeSummarySubject.asObservable();

  constructor(
    private employeeCache: XpoLtlEmployeeDetailsCacheService,
    private serviceCentersService: XpoLtlServiceCentersService
  ) {}

  ngOnInit() {
    if (this.employeeId) {
      this.employeeCache
        .request({ employeeId: this.employeeId })
        .pipe(take(1))
        .subscribe((employeeDetails: GetEmployeeDetailsByEmpIdResp) => {
          if (employeeDetails) {
            const sicCd = _get(employeeDetails, 'employee.basicInfo.deptSic', '');
            this.serviceCentersService
              .getSicByCd$(sicCd)
              .pipe(take(1))
              .subscribe((serviceCenter) => {
                this.employeeSummarySubject.next({
                  employeeId: `${this.employeeId} (${_get(employeeDetails, 'employee.basicInfo.sfEmployeeId', '')})`,
                  jobTitle: _result(employeeDetails, 'employee.employments[0].jobPositions[0].jobDescription', ''),
                  department: _result(employeeDetails, 'employee.employments[0].jobPositions[0].deptSic', ''),
                  phone: 'TODO',
                  email: 'TODO',
                  address: _get(serviceCenter, 'locAddress.addr1', ''),
                  cityStateZip: `${_get(serviceCenter, 'locAddress.cityName', '')}, ${_get(
                    serviceCenter,
                    'locAddress.countrySubdivisionCd',
                    ''
                  )} ${_get(serviceCenter, 'locAddress.postalCd', '')}`,
                  sicCode: _get(employeeDetails, 'employee.basicInfo.deptSic', ''),
                  supervisor: _get(employeeDetails, 'employee.supervisorEmailAddress', ''),
                });
              });
          }
        });
    }
  }

  phoneClicked(): void {}

  emailClicked(): void {}
}
