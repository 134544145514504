import { XpoAgGridBoardViewConfig } from '@xpo-ltl/ngx-ltl-board-grid';
import { forEach as _forEach, size as _size } from 'lodash';
import { Observable, of } from 'rxjs';
import { InboundPlanningDataStoreBase } from '../../shared/classes/inbound-planning-data-store-base.class';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { PlanningRouteShipmentsComponentName } from './planning-route-shipments-component-name';

export class PlanningRouteShipmentsDataViewDataStore extends InboundPlanningDataStoreBase {
  constructor(userPreferencesService: UserPreferencesService, preferences: XpoAgGridBoardViewConfig[]) {
    super(userPreferencesService, preferences, PlanningRouteShipmentsComponentName);
  }

  getAll(): Observable<XpoAgGridBoardViewConfig[]> {
    if (_size(this.preferences) === 0) {
      return of([this.showAllConfig()]);
    } else {
      const viewArray: XpoAgGridBoardViewConfig[] = [];
      viewArray.push(this.showAllConfig());
      _forEach(this.preferences, (element: XpoAgGridBoardViewConfig) => {
        if (!element.systemDefined) {
          viewArray.push({
            closeable: element.closeable,
            criteria: element.criteria,
            id: element.id,
            name: element.name,
            visible: element.visible,
            visibleColumns: element.visibleColumns,
            systemDefined: element.systemDefined || false,
            children: element.children || [],
            lastSaved: element.lastSaved,
            sortOrder: element.sortOrder || [],
            templateId: element.templateId || '',
          });
        }
      });
      return of(viewArray);
    }
  }

  showAllConfig(): XpoAgGridBoardViewConfig {
    return {
      closeable: false,
      criteria: {},
      id: 'showAll',
      name: 'Show All',
      visible: true,
      systemDefined: true,
    };
  }
}
