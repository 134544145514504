import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  CityOperationsApiService,
  ListPnDRoutesPath,
  ListPnDRoutesQuery,
  ListPnDStopsPath,
  ListPnDStopsResp,
  ListPnDUnassignedStopsResp,
  Route,
  Stop,
  UnassignedStop,
} from '@xpo-ltl/sdk-cityoperations';
import { ZoneIndicatorCd } from '@xpo-ltl/sdk-common';
import {
  filter as _filter,
  find as _find,
  forEach as _forEach,
  forOwn as _forOwn,
  get as _get,
  has as _has,
  isEmpty as _isEmpty,
  remove as _remove,
  set as _set,
  size as _size,
  some as _some,
} from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  concatMapTo,
  map,
  pluck,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import {
  areStopsEqual,
  AssignedStopIdentifier,
  EventItem,
  PlanningRouteShipmentIdentifier,
} from '../../inbound-planning/shared/interfaces/event-item.interface';
import { PlanningRoutesCacheService } from '../../inbound-planning/shared/services/planning-routes-cache.service';
import { GlobalFilterStoreSelectors } from '../global-filters-store';
import { NumberToValueMap } from '../number-to-value-map';
import * as PndStoreState from '../pnd-store.state';
import { RouteBalancingActions } from '../route-balancing-store';
import { RoutesSearchCriteria } from './routes-search-criteria.interface';
import {
  ActionTypes,
  RefreshPlanningRoutes,
  SetPlanningRoutesLastUpdate,
  SetSearchCriteria,
  SetSelectedPlanningRoutesAction,
  SetSelectedPlanningRoutesShipmentsAction,
  SetSelectedRoutesAction,
  SetSelectedStopsForSelectedRoutesAction,
  SetStopsForSelectedPlanningRoutesLastUpdate,
  SetStopsForSelectedRoutesAction,
  UpdateStopsForSelectedRouteAction,
} from './routes-store.actions';
import * as RoutesStoreSelectors from './routes-store.selectors';

@Injectable()
export class RoutesStoreEffects {
  constructor(
    private actions$: Actions,
    private cityOpsService: CityOperationsApiService,
    private store$: Store<PndStoreState.State>,
    private planningRoutesCacheService: PlanningRoutesCacheService
  ) {}

  // #region Trip Routes
  @Effect()
  setSelectedRoutes$: Observable<Action> = this.actions$.pipe(
    ofType<SetSelectedRoutesAction>(ActionTypes.setSelectedRoutes),
    map((action) => action.payload.selectedRoutes),
    switchMap((selectedRoutes: Route[]) => {
      _remove(selectedRoutes, function(route) {
        // remove it from the list if there's no route id.
        return !_get(route, 'routeInstId');
      });
      if (!selectedRoutes || selectedRoutes.length === 0) {
        return of(new SetStopsForSelectedRoutesAction({ stopsForSelectedRoutes: {} }));
      }

      const fetchStopsForRoute = (routeInstId: number) => {
        const pathParams: ListPnDStopsPath = {
          routeInstId: `${routeInstId}`,
        };
        return this.cityOpsService.listPnDStops(pathParams).pipe(
          // cancel the request if we get another setSelectedRoutes action
          takeUntil(this.actions$.pipe(ofType<SetSelectedRoutesAction>(ActionTypes.setSelectedRoutes))),
          map((response) => {
            return { routeInstId, stops: response.stops };
          })
        );
      };

      return forkJoin(selectedRoutes.map((route) => fetchStopsForRoute(route.routeInstId))).pipe(
        catchError(() => {
          return of(undefined);
        }),
        map((results: { routeInstId: number; stops: Stop[] }[]) => {
          const stopsForRoutes: NumberToValueMap<Stop[]> = {};
          _forEach(results, (value) => {
            _set(stopsForRoutes, value.routeInstId, value.stops);
          });
          return stopsForRoutes;
        }),
        map((stopsForRoutes) => {
          return new SetStopsForSelectedRoutesAction({ stopsForSelectedRoutes: stopsForRoutes });
        })
      );
    }),
    catchError(() => {
      return of(new SetStopsForSelectedRoutesAction({ stopsForSelectedRoutes: {} }));
    })
  );

  @Effect()
  updateStopsForSelectedRoute$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateStopsForSelectedRouteAction>(ActionTypes.updateStopsForSelectedRoute),
    map((action) => action.payload.route),
    withLatestFrom(this.store$.select(RoutesStoreSelectors.stopsForSelectedRoutes)),
    switchMap(([route, stopsForSelectedRoutes]) => {
      const pathParams: ListPnDStopsPath = {
        routeInstId: `${route.routeInstId}`,
      };
      return this.cityOpsService.listPnDStops(pathParams).pipe(
        catchError((error) => {
          this.store$.dispatch(
            new RouteBalancingActions.SetCanOpenRouteBalancing({
              canOpenRouteBalancing: true,
            })
          );
          return of(new ListPnDStopsResp());
        }),
        map((response) => {
          const results: NumberToValueMap<Stop[]> = {};
          _forOwn(stopsForSelectedRoutes, (stops, routeInstId) => {
            if (+routeInstId === route.routeInstId) {
              _set(results, routeInstId, response.stops);
            } else {
              _set(results, routeInstId, stops);
            }
          });

          return new SetStopsForSelectedRoutesAction({
            stopsForSelectedRoutes: results,
          });
        })
      );
    })
  );

  @Effect()
  setStopsForSelectedRoutes$: Observable<Action> = this.actions$.pipe(
    ofType<SetStopsForSelectedRoutesAction>(ActionTypes.setStopsForSelectedRoutes),
    withLatestFrom(this.store$.select(RoutesStoreSelectors.selectedStopsForSelectedRoutes)),
    switchMap(([stopsForRoutes, stopsSelectedForSelectedRoutes]) => {
      // remove selectedStops that are no longer part of a selectedRoute
      const newSelectedStops = _filter(stopsSelectedForSelectedRoutes, (selectedStop) => {
        const routeStops = _get(stopsForRoutes.payload.stopsForSelectedRoutes, selectedStop.id.routeInstId);
        return !!_find(routeStops, (stop: Stop) =>
          areStopsEqual(selectedStop.id, {
            routeInstId: selectedStop.id.routeInstId,
            seqNo: stop.tripNode.stopSequenceNbr,
            origSeqNo: stop.tripNode.stopSequenceNbr,
          })
        );
      }) as EventItem<AssignedStopIdentifier>[];
      return [
        new SetSelectedStopsForSelectedRoutesAction({ selectedStopsForSelectedRoutes: newSelectedStops }),
        new RouteBalancingActions.SetCanOpenRouteBalancing({ canOpenRouteBalancing: true }),
      ];
    })
  );
  // #endregion

  // #region Planning Routes
  @Effect()
  setSearchCriteria$: Observable<Action> = this.actions$.pipe(
    ofType<SetSearchCriteria>(ActionTypes.setSearchCriteria),
    concatMapTo([new RefreshPlanningRoutes()])
  );

  @Effect()
  refreshPlanningRoutes$: Observable<Action> = this.actions$.pipe(
    ofType<RefreshPlanningRoutes>(ActionTypes.refreshPlanningRoutes),
    withLatestFrom(this.store$.select(RoutesStoreSelectors.searchCriteria)),
    concatMap(([_, criteria]) => this.fetchPlanningRoutes(criteria)),
    withLatestFrom(this.store$.select(RoutesStoreSelectors.selectedPlanningRoutes)),
    concatMap(([routes, currentSelectedRoutes]) => {
      const selPlanningRoutes = _filter(currentSelectedRoutes, (curSelRouteInstId) => {
        return _some(routes, (route) => curSelRouteInstId === route.routeInstId);
      });
      this.planningRoutesCacheService.setPlanningRoutes(routes);
      return [
        new SetPlanningRoutesLastUpdate({ planningRoutesLastUpdate: new Date() }),
        new SetSelectedPlanningRoutesAction({ selectedPlanningRoutes: selPlanningRoutes }),
      ];
    }),
    catchError(() => {
      // error loading routes, so clear existing ones
      this.planningRoutesCacheService.setPlanningRoutes([]);
      return [
        new SetPlanningRoutesLastUpdate({ planningRoutesLastUpdate: new Date() }),
        new SetSelectedPlanningRoutesAction({ selectedPlanningRoutes: [] }),
      ];
    })
  );

  @Effect()
  setSelectedPlanningRoutes$: Observable<Action> = this.actions$.pipe(
    ofType<SetSelectedPlanningRoutesAction>(ActionTypes.setSelectedPlanningRoutes),
    map((action) => action.payload.selectedPlanningRoutes),
    withLatestFrom(this.store$.select(RoutesStoreSelectors.selectedPlanningRoutesShipments)),
    switchMap(([selPlanningRoutes, selectedShipments]: [number[], PlanningRouteShipmentIdentifier[]]) => {
      if (_size(selPlanningRoutes) === 0) {
        // No selected planning routes, so short-circuit to clear stops
        this.planningRoutesCacheService.setStopsForSelectedPlanningRoutes({});
        return [
          new SetStopsForSelectedPlanningRoutesLastUpdate({ stopsForSelectedPlanningRoutesLastUpdate: new Date() }),
          new SetSelectedPlanningRoutesShipmentsAction({ selectedPlanningRoutesShipments: [] }),
        ];
      } else {
        // get all of the Stops for all of the selected Planning Routes
        return forkJoin(selPlanningRoutes.map((routeInstId) => this.fetchUnassignedStopsForRoute(routeInstId))).pipe(
          takeUntil(this.actions$.pipe(ofType<SetSelectedPlanningRoutesAction>(ActionTypes.setSelectedPlanningRoutes))),
          map((results: { routeInstId: number; stops: UnassignedStop[] }[]) => {
            const stopsForRoutes = {};
            _forEach(results, (value) => {
              _set(stopsForRoutes, value.routeInstId, value.stops);
            });
            return stopsForRoutes;
          }),
          switchMap((stopsForSelectedPlanningRoutes) => {
            this.planningRoutesCacheService.setStopsForSelectedPlanningRoutes(stopsForSelectedPlanningRoutes);

            // determine if any of the selected Shipments is no longer in the selected Routes list
            const originalShipmentCount = _size(selectedShipments);
            const newSelectedShipments = _filter(
              selectedShipments,
              (selectedShipment: PlanningRouteShipmentIdentifier) => {
                return _has(stopsForSelectedPlanningRoutes, selectedShipment.routeInstId);
              }
            ) as PlanningRouteShipmentIdentifier[];

            const lastUpdateAction = new SetStopsForSelectedPlanningRoutesLastUpdate({
              stopsForSelectedPlanningRoutesLastUpdate: new Date(),
            });

            if (originalShipmentCount !== _size(newSelectedShipments)) {
              // remove selected shipments that are no longer part of the selected Routes
              return [
                lastUpdateAction,
                new SetSelectedPlanningRoutesShipmentsAction({ selectedPlanningRoutesShipments: newSelectedShipments }),
              ];
            } else {
              return [lastUpdateAction];
            }
          })
        );
      }
    }),
    catchError(() => {
      this.planningRoutesCacheService.setStopsForSelectedPlanningRoutes({});
      return [
        new SetStopsForSelectedPlanningRoutesLastUpdate({ stopsForSelectedPlanningRoutesLastUpdate: new Date() }),
        new SetSelectedPlanningRoutesShipmentsAction({ selectedPlanningRoutesShipments: [] }),
      ];
    })
  );

  // #endregion

  // Utility methods

  // return mapping of stops for this routeInstId
  private fetchUnassignedStopsForRoute(
    routeInstId: number
  ): Observable<{ routeInstId: number; stops: UnassignedStop[] }> {
    return this.store$.select(GlobalFilterStoreSelectors.globalFilterSic).pipe(
      take(1),
      switchMap((globalFilterSic) => {
        // TODO - why would we be getting data form unassigned deliveries for planning routes?
        return this.planningRoutesCacheService.searchPlanningRouteShipments({
          routeInstId: `${routeInstId}`,
          hostDestSicCd: globalFilterSic,
        });
      }),
      map((value: ListPnDUnassignedStopsResp) => {
        return {
          routeInstId,
          stops: value.unassignedStops,
        };
      })
    );
  }

  private fetchPlanningRoutes(criteria: RoutesSearchCriteria): Observable<Route[]> {
    if (_isEmpty(criteria.sicCd)) {
      return of([]);
    }

    const pathParams: ListPnDRoutesPath = {
      sicCd: criteria.sicCd,
    };
    const queryParams: ListPnDRoutesQuery = {
      planDate: criteria.planDate,
      zoneInd: ZoneIndicatorCd.NO_ZONES,
      plannerInd: criteria.plannerInd,
      newReleaseInd: criteria.newReleaseInd,
      categoryCd: criteria.categoryCd,
      statusCd: criteria.statusCd,
      specialServices: criteria.specialServices,
    };
    return this.cityOpsService.listPnDRoutes(pathParams, queryParams).pipe(
      takeUntil(this.actions$.pipe(ofType<RefreshPlanningRoutes>(ActionTypes.refreshPlanningRoutes))),
      pluck('routes'),
      catchError((err) => of([]))
    );
  }
}
