import LatLngBounds = google.maps.LatLngBounds;
import { ControlPosition, MapTypeControlOptions } from '@agm/core';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSidenav } from '@angular/material';
import { MatMenuTrigger } from '@angular/material/menu';
import { select, Store } from '@ngrx/store';
import {
  GeoArea,
  GetGeoAreasForLocationPath,
  GetGeoAreasForLocationQuery,
  GetGeoAreasForLocationResp,
  LocationApiService as LocationService,
  ServiceCenter,
} from '@xpo-ltl-2.0/sdk-location';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoBoardView, XpoBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board';
import { LatLong } from '@xpo-ltl/sdk-common';
import { Unsubscriber, XpoLtlServiceCentersService } from '@xpo/ngx-ltl';
import { forEach as _forEach, size as _size } from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ConfigManagerProperties } from '../../../../core';
import {
  GeoLocationStoreActions,
  GeoLocationStoreSelectors,
  GlobalFilterStoreSelectors,
  PndStoreState,
  RoutesStoreActions,
  RoutesStoreSelectors,
  UnassignedPickupsStoreActions,
  UnassignedPickupsStoreSelectors,
} from '../../../store';
import { GeoAreaStoreActions } from '../../../store/geo-area-store';
import {
  UnassignedDeliveriesStoreActions,
  UnassignedDeliveriesStoreSelectors,
} from '../../../store/unassigned-deliveries-store';
import {
  AssignedStopMapMarker,
  GlobalFilterMapCoordinate,
  UnassignedDeliveriesService,
  UnassignedPickupsService,
  WeatherLayer,
} from '../../shared';
import { UnmappedStopsEditMode } from '../../shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stops-edit-mode.enum';
import { MapMarker } from '../../shared/models/markers/map-marker';
import { GeocodeResult, GeoLocationService } from '../../shared/services/geo-location.service';
import { MapMarkersService } from '../../shared/services/map-markers.service';

import { MapToolbarService } from '../../shared/services/map-toolbar.service';
import { MappingService } from '../../shared/services/mapping.service';
import { RouteStopsGridItem } from '../route-stops/models/route-stops-grid-item.model';

import { PlanningMapBoardTemplate } from './planning-map-board-view-template.model';
import { PlanningMapDataSource } from './planning-map-data-source.service';

@Component({
  selector: 'pnd-planning-map',
  templateUrl: './planning-map.component.html',
  styleUrls: ['./planning-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-PlanningMap' },
  providers: [PlanningMapDataSource],
})
export class PlanningMapComponent implements OnInit, AfterViewInit, OnDestroy {
  serviceCenterMarker: MapMarker;
  displaySicMarkerCompass: boolean = false;
  sicMarkerCompassRotationAngle: number = 0;
  readonly viewTemplates: XpoBoardViewTemplate[];
  readonly views: XpoBoardView[];

  readonly UnmappedStopsMode = UnmappedStopsEditMode;

  readonly mapTypeControlOptions: MapTypeControlOptions = { position: ControlPosition.LEFT_BOTTOM };

  private unsubscriber = new Unsubscriber();
  private sicBounds: LatLngBounds;
  private geoAreas: GetGeoAreasForLocationResp;
  selectedContextMenu: string;
  selectedStop: AssignedStopMapMarker;

  map: google.maps.Map;

  private mapInitializedSubject = new Subject();
  readonly mapInitialized$ = this.mapInitializedSubject.asObservable();

  private trafficLayer: google.maps.TrafficLayer;

  private showUnassignedDeliveriesSubject = new BehaviorSubject<boolean>(true);
  readonly showUnassignedDeliveries$ = this.showUnassignedDeliveriesSubject.asObservable();

  private showUnassignedPickupsSubject = new BehaviorSubject<boolean>(false);
  readonly showUnassignedPickups$ = this.showUnassignedPickupsSubject.asObservable();

  private showDriverLocationsSubject = new BehaviorSubject<boolean>(false);
  readonly showDriverLocations$ = this.showDriverLocationsSubject.asObservable();

  private showGeoAreaSubject = new BehaviorSubject<boolean>(false);
  readonly showGeoAreas$ = this.showGeoAreaSubject.asObservable();

  defaultFilters: string[];

  private lastStopSelected: LatLong;

  private isGeoAreasLayerSelected = false;

  private serviceCenter: ServiceCenter;

  isGeoAreaFilterActive: boolean = false;

  reselectedMarker: AssignedStopMapMarker[] = [];
  routeStopMapMarkers: AssignedStopMapMarker[] = [];

  lastZoomLevel: number;

  unassignedPickupsZIndex = 1;
  unassignedDeliveriesZIndex = 1;
  planningRouteShipmentsZIndex = 1;
  assignedRoutesZIndex = 1;

  weatherMapSource: string = '';
  weatherMapSourceApiKey: string = '';

  readonly showGeoLocationEditor$: Observable<boolean>;
  private previousLayerVisibilityState: {
    unassignedDeliveries: boolean;
    unassignedPickups: boolean;
    driverLocations: boolean;
    geoAreas: boolean;
    traffic: boolean;
  };

  constructor(
    public dataSource: PlanningMapDataSource,
    public mappingService: MappingService,
    private locationService: LocationService,
    private serviceCentersService: XpoLtlServiceCentersService,
    private pndStore$: Store<PndStoreState.State>,
    private markersService: MapMarkersService,
    private mapToolbarService: MapToolbarService,
    private geoLocationService: GeoLocationService,
    public unassignedDeliveriesService: UnassignedDeliveriesService,
    public unassignedPickupsService: UnassignedPickupsService,
    private configManagerService: ConfigManagerService
  ) {
    this.serviceCenterMarker = new MapMarker();
    this.serviceCenterMarker.latitude = 0;
    this.serviceCenterMarker.longitude = 0;

    const template = new PlanningMapBoardTemplate();

    const view = template.createView({
      closeable: false,
      criteria: {},
      id: 'map-view',
      name: 'Planning Map',
      templateId: PlanningMapBoardTemplate.templateId,
    });

    this.viewTemplates = [template];
    this.views = [view];

    this.weatherMapSourceApiKey = this.configManagerService.getSetting(ConfigManagerProperties.weatherMapSourceApiKey);

    // enable/disable the unmapped stop editor whenever there is a stop to edit
    this.showGeoLocationEditor$ = this.pndStore$.select(GeoLocationStoreSelectors.stopToEdit).pipe(
      distinctUntilChanged(),
      map((stop) => {
        if (stop) {
          if (!this.previousLayerVisibilityState) {
            // save status of other layers so we can restore them when the editor closes
            this.previousLayerVisibilityState = {
              unassignedDeliveries: this.showUnassignedDeliveriesSubject.value,
              unassignedPickups: this.showUnassignedPickupsSubject.value,
              driverLocations: this.showDriverLocationsSubject.value,
              geoAreas: this.showGeoAreaSubject.value,
              traffic: !!this.trafficLayer,
            };

            // disable all other layers
            this.showUnassignedDeliveriesSubject.next(false);
            this.showUnassignedPickupsSubject.next(false);
            this.showDriverLocationsSubject.next(false);
            this.showGeoAreaSubject.next(false);
            this.handleDrawTraffic(false);
          }
          return true;
        } else {
          // restore previous status of layers
          if (this.previousLayerVisibilityState) {
            this.showUnassignedDeliveriesSubject.next(this.previousLayerVisibilityState.unassignedDeliveries);
            this.showUnassignedPickupsSubject.next(this.previousLayerVisibilityState.unassignedPickups);
            this.showDriverLocationsSubject.next(this.previousLayerVisibilityState.driverLocations);
            this.showGeoAreaSubject.next(this.previousLayerVisibilityState.geoAreas);
            this.handleDrawTraffic(this.previousLayerVisibilityState.traffic);

            this.previousLayerVisibilityState = undefined;
          }
          return false;
        }
      }),
      takeUntil(this.unsubscriber.done)
    );
    this.mapInitialized$.pipe(take(1)).subscribe(() => {
      this.pndStore$
        .select(GlobalFilterStoreSelectors.globalFilterSic)
        .pipe(
          distinctUntilChanged(),
          filter((sicCd: string) => !!sicCd),
          switchMap((sic) => {
            if (!this.serviceCenter) {
              // default position until a SIC is set
              const center = new google.maps.LatLng(41.4790207, -101.9241914);
              this.map.setCenter(center);
              this.map.setZoom(4);
              this.lastZoomLevel = 4;
            }
            return this.serviceCentersService.getSicByCd$(sic);
          }),
          filter((serviceCenter: ServiceCenter) => !!serviceCenter),
          takeUntil(this.unsubscriber.done)
        )
        .subscribe((serviceCenter: ServiceCenter) => {
          this.serviceCenter = serviceCenter;

          this.fitSicBoundaries(serviceCenter);
          this.findGeoAreas(serviceCenter.sicCd);
        });
      this.pndStore$
        .select(GlobalFilterStoreSelectors.globalFilterSicLatLng)
        .pipe(
          filter((latLng) => !!latLng),
          takeUntil(this.unsubscriber.done)
        )
        .subscribe((latLng) => {
          this.serviceCenterMarker.icon = this.markersService.getServiceCenterIconPath();
          this.serviceCenterMarker.latitude = latLng.latitude;
          this.serviceCenterMarker.longitude = latLng.longitude;
        });

      this.pndStore$
        .select(RoutesStoreSelectors.routeStopClicked)
        .pipe(takeUntil(this.unsubscriber.done))
        .subscribe((routeStop: RouteStopsGridItem) => {
          if (routeStop) {
            // TODO - center this stop
          } else if (this.sicBounds) {
            this.map.fitBounds(this.sicBounds, 0);
          }
        });
    });
    this.pndStore$
      .pipe(
        select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesBoundingSearchArea),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((geoAreaCoordinatesArr: GlobalFilterMapCoordinate[]) => {
        this.isGeoAreaFilterActive = geoAreaCoordinatesArr && geoAreaCoordinatesArr.length ? true : false;
      });
  }

  @ViewChild('sidenav', { static: true })
  sidenav: MatSidenav;

  @ViewChild(MatMenuTrigger, { static: false }) contextMenu: MatMenuTrigger;

  ngOnInit(): void {
    this.mapToolbarService.clearGeoAreaFilter$.pipe(takeUntil(this.unsubscriber.done)).subscribe(() => {
      this.mapToolbarService.clearPolygonLayer(this.map, 'geoFilterLayer');
      this.handleSicClicked();
    });

    // update state of sidenav when the unmapped stops edit mode changes
    this.pndStore$
      .select(GeoLocationStoreSelectors.editMode)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((mode) => {
        if (mode) {
          if (!this.sidenav.opened) {
            this.lastZoomLevel = this.map.getZoom();
            this.sidenav.open();
          }
        } else {
          this.sidenav.close();
        }
      });
  }

  ngAfterViewInit(): void {
    this.sidenav.openedChange.pipe(takeUntil(this.unsubscriber.done)).subscribe((opened) => {
      this.mapToolbarService.setDrawModeState(!opened);
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  showUnmappedStops(mode: UnmappedStopsEditMode) {
    this.pndStore$.dispatch(new GeoLocationStoreActions.SetEditMode(mode));
  }

  clearGeoFilter() {
    this.mapToolbarService.clearPolygonLayer(this.map, 'geoFilterLayer');
    this.mapToolbarService.handleSicClicked();
  }

  handleWeatherMode(layer: WeatherLayer): void {
    if (layer) {
      this.addWeatherLayer(layer);
    } else {
      this.removeWeatherLayer();
    }
  }

  addWeatherLayer(selectedWeatherLayer: WeatherLayer) {
    this.weatherMapSource =
      'https://tile.openweathermap.org/map/' + selectedWeatherLayer['layer_option']['layer_value'] + '/';
    const weatherOption = selectedWeatherLayer['layer_option'];
    this.plotWeatherLayers(this.map);
  }

  removeWeatherLayer() {
    this.weatherMapSource = '';
    this.map.overlayMapTypes.clear();
  }

  plotWeatherLayers(event) {
    const weatherMapProvider = this.weatherMapSource;
    const weatherApiKey = this.weatherMapSourceApiKey;
    event.overlayMapTypes.clear();
    event.overlayMapTypes.insertAt(0, new agmMapType({ width: 256, height: 256, f: 'px', b: 'px' }));
    function agmMapType(tileSize) {
      this.tileSize = tileSize;
    }
    agmMapType.prototype.getTile = function(coord, zoom, ownerDocument) {
      const div = ownerDocument.createElement('div');
      div.style.width = this.tileSize.width + 'px';
      div.style.height = this.tileSize.height + 'px';
      div.style.fontSize = '10';
      div.style['background-image'] =
        'url(' + weatherMapProvider + zoom + '/' + coord.x + '/' + coord.y + '.png?appid=' + weatherApiKey + ')';
      return div;
    };
  }

  // tslint:disable-next-line: no-shadowed-variable
  mapReady(map: google.maps.Map): void {
    this.map = map;
    this.mapInitializedSubject.next(true);
  }

  boundsChange(bounds: LatLngBounds) {
    if (bounds.contains({ lat: this.serviceCenterMarker.latitude, lng: this.serviceCenterMarker.longitude })) {
      this.displaySicMarkerCompass = false;
    } else {
      this.displaySicMarkerCompass = true;
      // get angle from serviceCenter to map right-top
      const alpha = Math.atan2(
        this.serviceCenterMarker.latitude - bounds.getNorthEast().lat(),
        this.serviceCenterMarker.longitude - bounds.getNorthEast().lng()
      );
      let angle = alpha * (180 / Math.PI);
      if (angle <= 0) {
        angle += 360;
      }
      // 270 is the angle when the icon angle is 0
      angle -= 270;
      this.sicMarkerCompassRotationAngle = angle * -1;
    }
  }

  handleDrawModeChange(inDrawMode: boolean): void {
    // TODO: we will need to disable certain map functionality when in draw mode
    // This will come as later stories
    if (inDrawMode) {
      this.map.setOptions({
        draggableCursor: 'url(./assets/pencil.png) -16 16, auto',
        draggingCursor: 'url(./assets/pencil.png) -16 16, auto',
      });
    } else {
      this.map.setOptions({ draggableCursor: 'grab', draggingCursor: 'grabbing' });
    }
  }

  fitServiceCenterBounds(): void {
    this.map.fitBounds(this.sicBounds, 0);
  }

  zoomChange(zoom: number) {
    // DO NOT STORE ZOOM LEVEL CHANGES WHEN SIDENAV IS OPEN!!!
    if (!this.sidenav.opened) {
      this.lastZoomLevel = zoom;
    }
  }

  zoomIn(): void {
    this.map.setZoom(this.map.getZoom() + 1);
  }

  zoomOut(): void {
    this.map.setZoom(this.map.getZoom() - 1);
  }

  fitLastSelection(): void {
    if (this.lastStopSelected) {
      const lastStopBounds = new google.maps.LatLngBounds();

      lastStopBounds.extend({
        lat: this.lastStopSelected.latitude,
        lng: this.lastStopSelected.longitude,
      });

      this.map.fitBounds(lastStopBounds, 0);
    } else {
      this.map.fitBounds(this.sicBounds, 0);
    }
  }

  handleDrawUnassignedDeliveries(shouldDraw: boolean) {
    this.showUnassignedDeliveriesSubject.next(shouldDraw);
  }

  handleDrawUnassignedPickups(shouldDraw: boolean) {
    this.showUnassignedPickupsSubject.next(shouldDraw);
  }

  handleDrawDriversLocation(shouldDraw: boolean): void {
    this.showDriverLocationsSubject.next(shouldDraw);
  }

  handleDrawTraffic(checked: boolean): void {
    if (checked) {
      this.trafficLayer = new google.maps.TrafficLayer();
      this.trafficLayer.setMap(this.map);
    } else if (this.trafficLayer) {
      this.trafficLayer.setMap(null);
      this.trafficLayer = undefined;
    }
  }

  handleDrawGeoAreas(checked: boolean): void {
    this.isGeoAreasLayerSelected = checked;
    this.showGeoAreaSubject.next(checked);

    if (checked) {
      this.mappingService.addGeoAreaPolygons(this.geoAreas);
    }
  }

  handleDrawCompletedStops(checked: boolean): void {
    this.pndStore$.dispatch(new RoutesStoreActions.SetShowCompletedStops({ showCompletedStops: checked }));
  }

  handleSicClicked(): void {
    if (this.serviceCenter) {
      this.fitSicBoundaries(this.serviceCenter);

      combineLatest([
        this.pndStore$.select(UnassignedPickupsStoreSelectors.unassignedPickupsBoundingSearchArea),
        this.pndStore$.select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesBoundingSearchArea),
      ])
        .pipe(take(1))
        .subscribe(([unassignedPickupsFilters, unassignedDeliveriesFilters]) => {
          if (_size(unassignedPickupsFilters) > 0 || _size(unassignedDeliveriesFilters) > 0) {
            this.mapToolbarService.clearPolygonLayer(this.map, 'geoFilterLayer');

            this.pndStore$.dispatch(
              new UnassignedDeliveriesStoreActions.SetBoundingSearchArea({
                boundingSearchArea: new Array<GlobalFilterMapCoordinate>(),
              })
            );

            this.pndStore$.dispatch(
              new UnassignedPickupsStoreActions.SetBoundingSearchAreaAction({
                boundingSearchArea: new Array<GlobalFilterMapCoordinate>(),
              })
            );
          }
        });
    }
  }

  private findGeoAreas(sic: string): void {
    this.pndStore$
      .select(GlobalFilterStoreSelectors.globalFilterPlanDate)
      .pipe(take(1))
      .subscribe((planDate) => {
        const path = { ...new GetGeoAreasForLocationPath(), sicCd: sic.toUpperCase(), polygonTypeCd: 'A' };
        const query = {
          ...new GetGeoAreasForLocationQuery(),
          planDate: moment(planDate).format('YYYY-MM-DD'),
          satelliteInd: true,
        };

        this.locationService
          .getGeoAreasForLocation(path, query)
          .pipe(take(1))
          .subscribe((serviceCenterDetailsResponse) => {
            this.pndStore$.dispatch(
              new GeoAreaStoreActions.SetGeoAreaGeoAreasAction({ geoAreas: serviceCenterDetailsResponse })
            );

            if (serviceCenterDetailsResponse) {
              this.geoAreas = serviceCenterDetailsResponse;
              this.handleDrawGeoAreas(this.isGeoAreasLayerSelected);
            }
          });
      });
  }

  /**
   * Sets the zoom and centers the map so that all of the service center's boundries are shown.
   * @param sic
   */
  private fitSicBoundaries(serviceCenter: ServiceCenter): void {
    let boundaries: GetGeoAreasForLocationResp;

    const path = { ...new GetGeoAreasForLocationPath(), sicCd: serviceCenter.sicCd.toUpperCase(), polygonTypeCd: 'B' };
    const query = { ...new GetGeoAreasForLocationQuery() };

    this.locationService
      .getGeoAreasForLocation(path, query)
      .pipe(take(1))
      .subscribe(
        (serviceCenterDetailsResponse) => {
          this.pndStore$.dispatch(
            new GeoAreaStoreActions.SetGeoAreaSicBoundariesAction({ sicBoundaries: serviceCenterDetailsResponse })
          );

          if (serviceCenterDetailsResponse) {
            boundaries = serviceCenterDetailsResponse;
            this.sicBounds = new google.maps.LatLngBounds();

            _forEach(boundaries.geoArea, (geo: GeoArea) => {
              _forEach(geo.polygon, (item: LatLong) => {
                this.sicBounds.extend({ lat: item.latitude, lng: item.longitude });
              });
            });

            this.map.fitBounds(this.sicBounds, 0);
          }
        },
        () => {
          // initial lookup failed, so try to find based on address
          const request = {
            address: `${serviceCenter.locAddress.addr1}, ${serviceCenter.locAddress.cityName} ${serviceCenter.locAddress.countrySubdivisionCd} ${serviceCenter.locAddress.postalCd}`,
          };

          this.geoLocationService.geocodeAddress(request).subscribe((result: GeocodeResult) => {
            this.sicBounds = new google.maps.LatLngBounds();

            this.sicBounds.extend(result.location);
            this.map.fitBounds(this.sicBounds);
            this.map.setZoom(7);
          });
        }
      );
  }
}
