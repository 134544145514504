import { UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { MapMarkersService } from '../../services/map-markers.service';
import { SpecialServicesService } from '../../services/special-services.service';
import { StopWindowService } from '../../services/stop-window.service';
import { MapMarkerInfo } from '../map-marker-info.model';
import { MapMarkerWithInfoWindow } from './map-marker-with-info-window';

export class UnassignedDeliveryShipmentsMapMarker extends MapMarkerWithInfoWindow<MapMarkerInfo> {
  readonly rowData: UnassignedStop;

  get mappable(): boolean {
    return !!this.latitude && !!this.longitude;
  }

  constructor(
    unassignedStop: UnassignedStop,
    mapMarkerService: MapMarkersService,
    private stopWindowService: StopWindowService,
    private specialServicesService: SpecialServicesService,
    color: string
  ) {
    super();

    this.rowData = unassignedStop;
    this.icon = mapMarkerService.getMarkerIconUnassigned('DL', 10, false, color);

    if (unassignedStop.consignee && unassignedStop.consignee.geoCoordinates) {
      this.latitude = +unassignedStop.consignee.geoCoordinates.latitude;
      this.longitude = +unassignedStop.consignee.geoCoordinates.longitude;
    }

    let routeName: string;
    if (this.rowData.deliveryShipments && this.rowData.deliveryShipments.length > 0) {
      routeName = `${this.rowData.deliveryShipments[0].routePrefix}-${this.rowData.deliveryShipments[0].routeSuffix}`;
    }

    this.markerInfo = new MapMarkerInfo(
      this.stopWindowService,
      {
        ...unassignedStop,
        color,
      },
      undefined,
      undefined,
      undefined,
      routeName
    );

    this.markerInfo.specialServices = this.specialServicesService.getSpecialServicesForSummary(
      unassignedStop.specialServiceSummary
    );
  }
}
