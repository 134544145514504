import { Injectable } from '@angular/core';
import {
  CityOperationsApiService,
  DeliveryShipmentSearchFilter,
  DeliveryShipmentSearchRecord,
  ListPnDUnassignedStopsResp,
  ListPnDUnassignedStopsRqst,
  Route,
  UnassignedStop,
} from '@xpo-ltl/sdk-cityoperations';
import { XrtSearchQueryHeader } from '@xpo-ltl/sdk-common';
import {
  defaultTo as _defaultTo,
  find as _find,
  forEach as _forEach,
  forOwn as _forOwn,
  get as _get,
  reduce as _reduce,
} from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PndXrtService } from '../../../../core/services/pnd-xrt.service';
import { NumberToValueMap } from '../../../store/number-to-value-map';
import { PlanningRouteDeliveriesSearchCriteria } from '../../../store/unassigned-deliveries-store/planning-route-deliveries-search-criteria.interface';
import { consigneeToId, PlanningRouteShipmentIdentifier } from '../interfaces/event-item.interface';

/**
 * Provides cache of large Data types instead of storing them in the Store
 */
@Injectable({
  providedIn: 'root',
})
export class PlanningRoutesCacheService {
  // list of all routes available
  private planningRoutes = new Map<number, Route>();

  // map of all Stops for a set of routeInstIds
  private stopsForSelectedPlanningRoutes = new Map<number, UnassignedStop[]>();

  constructor(private pndXrtService: PndXrtService, private cityOperationsService: CityOperationsApiService) {}

  /**
   * Clear existing routes and store the new set of routes
   * @param routes new set of Routes
   */
  setPlanningRoutes(routes: Route[]) {
    this.planningRoutes.clear();
    _forEach(routes, (route) => {
      this.planningRoutes.set(route.routeInstId, route);
    });
  }

  /**
   * Return all Routes as an Array
   *
   */
  getAllPlanningRoutes(): Route[] {
    const routes = Array.from(this.planningRoutes.values());
    return routes;
  }

  /**
   * Return the planningRoute with the requested routeInstId, or undefined
   * if not found
   */
  getPlanningRoute(routeInstId: number): Route {
    return this.planningRoutes.get(routeInstId);
  }

  /**
   * Set the mapping of RouteInstIds to the Stops for that route
   */
  setStopsForSelectedPlanningRoutes(stopsMap: NumberToValueMap<UnassignedStop[]>) {
    this.stopsForSelectedPlanningRoutes.clear();
    _forOwn(stopsMap, (stops, routeInstId) => {
      this.stopsForSelectedPlanningRoutes.set(+routeInstId, stops);
    });
  }

  /**
   * Return map of all Routes and their Stops
   */
  getStopsForSelectedPlanningRoutes(): Map<number, UnassignedStop[]> {
    return this.stopsForSelectedPlanningRoutes;
  }

  /**
   * Return the list of Stops for the specified Route.
   */
  getStopsForRoute(routeInstId: number): UnassignedStop[] {
    const stops = this.stopsForSelectedPlanningRoutes.get(routeInstId);
    return _defaultTo(stops, []);
  }

  /**
   * Find and return the DeliveryShipmentSearchRecord that matches the shipmentId passed in
   */
  getDeliveryShipmentSearchRecord(shipmentId: PlanningRouteShipmentIdentifier): DeliveryShipmentSearchRecord {
    const shipmentConsigneeId = consigneeToId(shipmentId);

    // find the route for the shipment
    const stops: UnassignedStop[] = this.getStopsForRoute(shipmentId.routeInstId);

    // find the shipment in the stop
    const selectedStop = _find(stops, (stop) => consigneeToId(stop) === shipmentConsigneeId);

    // find the shipment at the stop
    const shipment: DeliveryShipmentSearchRecord = _find(
      _get(selectedStop, 'deliveryShipments', []),
      (delShip: DeliveryShipmentSearchRecord) => delShip.shipmentInstId === shipmentId.shipmentInstId
    );

    return shipment;
  }

  /**
   * Search all Stops for a Route and return the first instance of the requested Shipment
   */
  getDeliveryShipmentSearchRecordInRoute(routeInstId: number, shipmentInstId: number): DeliveryShipmentSearchRecord {
    const stops: UnassignedStop[] = this.getStopsForRoute(routeInstId);
    const record = _reduce(
      stops,
      (shipment, stop) => {
        return (
          shipment ||
          _find(
            _get(stop, 'deliveryShipments', []),
            (delShip: DeliveryShipmentSearchRecord) => delShip.shipmentInstId === shipmentInstId
          )
        );
      },
      undefined
    );

    return record;
  }

  /**
   * Get from server all planning route shipments that match the search criteria
   * TODO - This makes no sense...why would we have unassigned stops in a route?
   */
  searchPlanningRouteShipments(
    criteria: PlanningRouteDeliveriesSearchCriteria
  ): Observable<ListPnDUnassignedStopsResp> {
    const header: XrtSearchQueryHeader = {
      pageNumber: 1,
      pageSize: 10000,
      sortExpressions: [],
    };

    const listPnDUnassignedStopsRqst: ListPnDUnassignedStopsRqst = {
      header: header,
      filter: {
        ...new DeliveryShipmentSearchFilter(),
        hostDestSicCd: this.pndXrtService.toXrtFilterEquals(criteria.hostDestSicCd),
        routeInstId: this.pndXrtService.toXrtFilterEquals(criteria.routeInstId),
      },
      unmappedInd: false,
    };

    return this.cityOperationsService.listPnDUnassignedStops(listPnDUnassignedStopsRqst).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
