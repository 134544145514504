import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { isEmpty as _isEmpty, size as _size, sortBy as _sortBy, forEach as _forEach } from 'lodash';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, share, switchMap, takeUntil } from 'rxjs/operators';
import {
  GeoLocationStoreActions,
  GeoLocationStoreSelectors,
  PndStoreState,
  UnassignedDeliveriesStoreActions,
  UnassignedPickupsStoreActions,
} from '../../../../store';
import { UnassignedDeliveriesService } from '../../services/unassigned-deliveries.service';
import { UnassignedPickupsService } from '../../services/unassigned-pickups.service';
import { UnmappedStopDetail } from './components/unmapped-stop-detail/unmapped-stop-detail.model';
import { UnmappedStopsEditMode } from './components/unmapped-stop-detail/unmapped-stops-edit-mode.enum';

@Component({
  selector: 'pnd-unmapped-stops',
  templateUrl: './unmapped-stops.component.html',
  styleUrls: ['./unmapped-stops.component.scss'],
  host: { class: 'pnd-UnmappedStops' },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnmappedStopsComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();

  stopsToEdit$: Observable<UnmappedStopDetail[]>;
  title$: Observable<string>;

  mode$ = this.pndStore$.select(GeoLocationStoreSelectors.editMode).pipe(share(), takeUntil(this.unsubscriber.done));

  private get unmappedDeliveries$(): Observable<UnmappedStopDetail[]> {
    return this.unassignedDeliveriesService.unmappedDeliveries$.pipe(
      map((results) => {
        const unmappedStops: UnmappedStopDetail[] = [];
        _forEach(results, (result: UnassignedStop) => {
          unmappedStops.push({
            shipmentDetails: result.deliveryShipments,
            acctInstId: result.consignee.acctInstId,
            stopName: result.consignee.name1,
            stopTypeCd: TripNodeActivityCd.DELIVER_SHIPMENT,
            address: `${result.consignee.addressLine1} ${result.consignee.cityName} ${result.consignee.stateCd} ${result.consignee.postalCd}`,
            location: null,
            isFutureCustomer: _isEmpty(result.consignee.acctMadCd),
          });
        });
        return unmappedStops;
      })
    );
  }

  private get unmappedPickups$(): Observable<UnmappedStopDetail[]> {
    return this.unassignedPickupsService.unmappedPickups$.pipe(
      map((results) => {
        const unmappedStops: UnmappedStopDetail[] = [];
        (results || []).forEach((result) => {
          unmappedStops.push({
            acctInstId: result.shipper.acctInstId,
            stopName: result.shipper.name1,
            stopTypeCd: TripNodeActivityCd.PICKUP_SHIPMENTS,
            address: `${result.shipper.addressLine1} ${result.shipper.cityName} ${result.shipper.stateCd} ${result.shipper.postalCd}`,
            location: null,
            isFutureCustomer: _isEmpty(result.shipper.acctMadCd),
          });
        });
        return unmappedStops;
      })
    );
  }

  private get unassignedDelivery$(): Observable<UnmappedStopDetail[]> {
    return this.pndStore$.select(GeoLocationStoreSelectors.stopToEdit).pipe(map((stop) => [stop]));
  }

  private get unassignedPickup$(): Observable<UnmappedStopDetail[]> {
    return this.pndStore$.select(GeoLocationStoreSelectors.stopToEdit).pipe(map((stop) => [stop]));
  }

  private get assignedStop$(): Observable<UnmappedStopDetail[]> {
    return this.pndStore$.select(GeoLocationStoreSelectors.stopToEdit).pipe(map((stop) => [stop]));
  }

  constructor(
    private unassignedDeliveriesService: UnassignedDeliveriesService,
    private unassignedPickupsService: UnassignedPickupsService,
    private pndStore$: Store<PndStoreState.State>
  ) {}

  ngOnInit() {
    // update state of sidenav when the unmapped stops edit mode changes
    this.stopsToEdit$ = this.mode$.pipe(
      distinctUntilChanged(),
      switchMap((mode) => this.switchStopsForMode(mode))
    );
  }

  ngOnDestroy() {
    this.endEdit();
    this.unsubscriber.complete();
  }

  endEdit() {
    this.pndStore$.dispatch(new GeoLocationStoreActions.EndEdit());
  }

  beginEdit(stopDetail: UnmappedStopDetail) {
    this.pndStore$.dispatch(new GeoLocationStoreActions.SetStopToEdit(stopDetail));
  }

  stopDetailReassigned(stopDetail: UnmappedStopDetail) {
    // the stop location was updated, so refresh list of unmapped stops
    if (stopDetail.stopTypeCd === TripNodeActivityCd.DELIVER_SHIPMENT) {
      this.pndStore$.dispatch(new UnassignedDeliveriesStoreActions.Refresh());
    } else {
      this.pndStore$.dispatch(new UnassignedPickupsStoreActions.Refresh());
    }
  }

  // switch the list of unmapped stops and the title to display
  private switchStopsForMode(mode: UnmappedStopsEditMode): Observable<UnmappedStopDetail[]> {
    // clear previous stop when switching modes
    this.pndStore$.dispatch(new GeoLocationStoreActions.SetStopToEdit(undefined));

    let stops$: Observable<UnmappedStopDetail[]>;
    switch (mode) {
      case UnmappedStopsEditMode.UnmappedDeliveries:
        stops$ = this.unmappedDeliveries$;
        this.title$ = stops$.pipe(
          map((stops) => {
            return `Unmapped Deliveries (${_size(stops)})`;
          })
        );
        break;

      case UnmappedStopsEditMode.UnmappedPickups:
        stops$ = this.unmappedPickups$;
        this.title$ = stops$.pipe(
          map((stops) => {
            return `Unmapped Pickups (${_size(stops)})`;
          })
        );
        break;

      case UnmappedStopsEditMode.UnassignedDelivery:
        stops$ = this.unassignedDelivery$;
        this.title$ = of('Unassigned Delivery');
        break;

      case UnmappedStopsEditMode.UnassignedPickup:
        stops$ = this.unassignedPickup$;
        this.title$ = of('Unassigned Pickup');
        break;

      case UnmappedStopsEditMode.AssignedStop:
        stops$ = this.assignedStop$;
        this.title$ = of('Aassigned Stop');
        break;

      default:
        // can't get any stops, but we do need an Observable
        stops$ = of([]);
        this.title$ = of('');
        break;
    }

    return stops$.pipe(
      map((stops) => {
        // alphabetize by stop name
        return _sortBy(stops, 'stopName');
      })
    );
  }
}
