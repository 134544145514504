import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatDialogModule,
  MatDividerModule,
  MatInputModule,
  MatTableModule,
  MatIconModule,
} from '@angular/material';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoLtlDialogTitleModule, XpoLtlEnvironmentAndRoleGuardModule, XpoLtlPipeModule } from '@xpo/ngx-ltl';
import { AgGridModule } from 'ag-grid-angular';
import { pndFrameworkComponentsDeclarations } from '../../app/inbound-planning/shared';
import { ErrorDialogComponent } from '../../app/inbound-planning/shared/components/error-dialog/error-dialog.component';
import { ConfirmCancelComponent } from './confirm-cancel/confirm-cancel.component';
import { GridRowTransposedComponent } from './grid-row-transposed/grid-row-transposed.component';

const dialogs = [ConfirmCancelComponent, GridRowTransposedComponent, ErrorDialogComponent];

@NgModule({
  declarations: [...dialogs],
  exports: [...dialogs],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
    DragDropModule,
    FormsModule,
    AgGridModule.withComponents(pndFrameworkComponentsDeclarations),
    XpoDialogModule,
    XpoLtlPipeModule,
    XpoLtlDialogTitleModule,
    XpoLtlEnvironmentAndRoleGuardModule,
  ],
  entryComponents: [...dialogs],
})
export class PndDialogModule {}
