import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { XpoBoardData, XpoBoardState, XpoBoardDataSource } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardState } from '@xpo-ltl/ngx-ltl-board-grid';
import { Route, UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { flatten as _flatten, forEach as _forEach, get as _get, pick as _pick, size as _size } from 'lodash';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PndRouteUtils } from '../../../../shared/route-utils';
import { PndStoreState, RoutesStoreSelectors } from '../../../store';
import { BoardStateSource } from '../../../store/board-state-source';
import { GrandTotalService } from '../../shared';
import { routeStopToId } from '../../shared/interfaces/event-item.interface';
import { PlanningRoutesCacheService } from '../../shared/services/planning-routes-cache.service';
import { PlanningRouteShipmentsGridItem } from './models';

@Injectable()
export class PlanningRouteShipmentsDataSource extends XpoBoardDataSource implements OnDestroy {
  private unsubscriber: Unsubscriber = new Unsubscriber();

  get currentState(): XpoAgGridBoardState {
    return this.stateCache;
  }

  constructor(
    private pndStore$: Store<PndStoreState.State>,
    private grandTotalService: GrandTotalService,
    private planningRoutesCacheService: PlanningRoutesCacheService
  ) {
    super();

    this.subscribeToStoreChanges();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  // get the latest data for the Board from the Store
  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    return this.pndStore$.select(RoutesStoreSelectors.selectedPlanningRoutes).pipe(
      switchMap((selectedRoutes: number[]) => {
        const results: PlanningRouteShipmentsGridItem[][] = [];
        _forEach(selectedRoutes, (routeInstId: number) => {
          const stopsForRoute = this.planningRoutesCacheService.getStopsForRoute(routeInstId);
          if (_size(stopsForRoute) > 0) {
            const route = this.planningRoutesCacheService.getPlanningRoute(routeInstId);
            results.push(
              this.transformStopsToGridItems(
                {
                  ...new Route(),
                  routeInstId: routeInstId,
                  routePrefix: _get(route, 'routePrefix'),
                  routeSuffix: _get(route, 'routeSuffix'),
                },
                stopsForRoute
              )
            );
          }
        });

        const routeStops = _flatten(results);
        this.grandTotalService.updatePlanningRouteShipmentsGrandTotals(routeStops);
        return of(new XpoBoardData(state, { rows: routeStops }, routeStops.length, 10000));
      })
    );
  }

  private subscribeToStoreChanges() {
    // Store change listening happens in the Component
  }

  /**
   * map the list of Stops for a Route to a list of PlanningRoutesShipmentGridItems
   */
  private transformStopsToGridItems(route: Route, stops: UnassignedStop[]): PlanningRouteShipmentsGridItem[] {
    // TODO: Finish fixing Mapping
    return (
      stops
        // .filter((s) => s.tripNode.nodeTypeCd !== 'ServiceCenter')
        .map((s) => {
          const item = <PlanningRouteShipmentsGridItem>{
            routeId: PndRouteUtils.getRouteId(route),
            routeInstId: route.routeInstId,
            consignee: s.consignee,
            totalShipmentsCount: s.totalShipmentsCount,
            totalWeightLbs: s.totalWeightLbs,
            motorizedPiecesCount: s.motorizedPiecesCount,
            loosePcsCnt: s.loosePcsCnt,
            totalCubePercentage: s.totalCubePercentage,
            footprintPercentage: s.footprintPercentage,
            currentSicCd: s.currentSicCd,
            serviceDateMessage: s.serviceDateMessage,
            deliveryQualifiers: s.deliveryQualifiers,
            specialServiceSummary: s.specialServiceSummary,
            deliveryShipments: s.deliveryShipments,
            acctInstId: s.consignee.acctInstId,
            stopWindow: s.stopWindow,
            uniqId: routeStopToId({
              routeInstId: route.routeInstId,
              consignee: _pick(s.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
            }),
            lastSelectedFromRedux: false,
          };
          return item;
        })
    );
  }
}
