import { LazyMapsAPILoaderConfigLiteral } from '@agm/core';
import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../enums';

@Injectable({ providedIn: 'root' })
export class GoogleMapsConfig implements LazyMapsAPILoaderConfigLiteral {
  apiKey = '';
  channel = '';
  libraries = ['drawing', 'geometry'];

  constructor(configManagerService: ConfigManagerService) {
    configManagerService.configured$.subscribe((appConfig) => {
      if (appConfig) {
        this.apiKey = appConfig[ConfigManagerProperties.googleApiLicenseKey];
        this.channel = appConfig[ConfigManagerProperties.googleApiChannelName];
      }
    });
  }
}
