import { Injectable } from '@angular/core';
import { Activity, Stop } from '@xpo-ltl/sdk-cityoperations';
import { ShipmentServiceTypeCd, ShipmentSpecialServiceCd, ShipmentSpecialServiceSummary } from '@xpo-ltl/sdk-common';
import { RowNode } from 'ag-grid-community';
import { concat as _concat, forEach as _forEach, get as _get, has as _has, size as _size, uniq as _uniq } from 'lodash';
import { SpecialServicesCountInterface } from '../models/special-services-count.interface';

@Injectable({
  providedIn: 'root',
})
export class SpecialServicesService {
  constructor() {}

  /**
   * Return list of active special services defined in the passed SpecialServicesCountInterface (usually a Trip or Route)
   */
  getSpecialServicesForCounts(serviceCounts: SpecialServicesCountInterface): ShipmentSpecialServiceCd[] {
    // define the properties to test and corresponding service codes
    const counters = [
      { key: 'hazmatCount', serviceCd: ShipmentSpecialServiceCd.HAZMAT },
      { key: 'liftgateCount', serviceCd: ShipmentSpecialServiceCd.LIFT_GATE },
      { key: 'timeDateCriticalCount', serviceCd: ShipmentSpecialServiceCd.TIME_DATE_CRITICAL },
      { key: 'cashDueCount', serviceCd: ShipmentSpecialServiceCd.CASH_ON_DELIVERY },
      { key: 'rrsCount', serviceCd: ShipmentSpecialServiceCd.RAPID_REMOTE_SERVICE },
      { key: 'guaranteedCount', serviceCd: ShipmentSpecialServiceCd.GUARANTEED },
      { key: 'guaranteedBy12Count', serviceCd: ShipmentSpecialServiceCd.GUARANTEED_BY_NOON },
    ];

    const specialServices: ShipmentSpecialServiceCd[] = [];
    _forEach(counters, (counter) => {
      if (_get(serviceCounts, counter.key, 0) > 0) {
        specialServices.push(counter.serviceCd);
      }
    });
    return specialServices;
  }

  /**
   * Return list of active special services defined in the passed ShipmentSpecialServicesSummary
   */
  getSpecialServicesForSummary(summary: ShipmentSpecialServiceSummary[]): ShipmentSpecialServiceCd[] {
    const specialServices: ShipmentSpecialServiceCd[] = [];

    _forEach(summary, (service) => {
      if (service.specialService && service.applicableInd) {
        specialServices.push(service.specialService);
      }
    });

    return _uniq(specialServices);
  }

  /**
   * Return the special services defined for a Stop (including Activities at Stop)
   */
  getSpecialServicesForStop(stop: Stop): ShipmentSpecialServiceCd[] {
    if (stop) {
      let specialServices = this.getSpecialServicesForSummary(stop.specialServicesSummary);

      // aggrigate servics for each a activity at the stop
      _forEach(stop.activities, (activity) => {
        const activitySS = this.getSpecialServicesForActivity(activity);
        specialServices = _concat(specialServices, activitySS);
      });

      return _uniq(specialServices);
    } else {
      return [];
    }
  }

  /**
   * Return all special services defined for the Activity
   */
  getSpecialServicesForActivity(activity: Activity): ShipmentSpecialServiceCd[] {
    if (activity && _has(activity, 'routeShipment')) {
      // only care about activities that have a shipment
      const specialServices = this.getSpecialServicesForSummary(activity.shipmentSpecialServices);

      if (_get(activity.tripNodeActivity, 'serviceTypeCd') === ShipmentServiceTypeCd.GUARANTEED_BY_12_NOON) {
        specialServices.push(ShipmentSpecialServiceCd.GUARANTEED_BY_NOON);
      }
      return _uniq(specialServices);
    } else {
      return [];
    }
  }

  // Score special services so that they sort according to the number of them
  getSpecialServicesComparator(nodeA: RowNode, nodeB: RowNode): number {
    const countsA = _get(nodeA, 'data.route', _get(nodeA, 'data'));
    const servicesA = this.getSpecialServicesForCounts(countsA);
    const nodeAScore = _size(servicesA);

    const countsB = _get(nodeB, 'data.route', _get(nodeB, 'data'));
    const servicesB = this.getSpecialServicesForCounts(countsB);
    const nodeBScore = _size(servicesB);

    if (nodeAScore === nodeBScore && nodeAScore > 0) {
      // same number of services, so sort by service name
      return servicesA[0].localeCompare(servicesB[0]);
    } else {
      return nodeAScore - nodeBScore;
    }
  }
}
