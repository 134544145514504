import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { CityOperationsApiService } from '@xpo-ltl/sdk-cityoperations';
import { forEach as _forEach, get as _get, isEmpty as _isEmpty, without as _without } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, catchError } from 'rxjs/operators';
import { PndStoreState } from '..';
import {
  ActionTypes,
  SetDriversCurrentLocationAction,
  FetchDriversCurrentLocationAction,
} from './driver-store.actions';

@Injectable()
export class DriverStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<PndStoreState.State>,
    private cityOpsService: CityOperationsApiService
  ) {}

  @Effect()
  fetchDriversCurrentLocationForSelectedSic$: Observable<Action> = this.actions$.pipe(
    ofType<FetchDriversCurrentLocationAction>(ActionTypes.fetchDriversCurrentLocation),
    switchMap((action) => {
      return this.cityOpsService
        .listPnDDriverLocations({
          sicCd: action.payload.sicCd,
        })
        .pipe(
          take(1),
          catchError(() => of(undefined))
        );
    }),
    catchError(() => of(undefined)),
    map((response) => {
      const driverLocations = _get(response, 'driverLocations', []);
      return new SetDriversCurrentLocationAction({ driversCurrentLocation: driverLocations });
    })
  );
}
