import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { get as _get } from 'lodash';

import { ShipmentSpecialServiceSummary } from '@xpo-ltl/sdk-common';

@Component({
  selector: 'app-special-services-cell-renderer',
  templateUrl: './special-services-cell-renderer.component.html',
  styleUrls: ['./special-services-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialServicesCellRendererComponent implements ICellRendererAngularComp {
  specialServices: ShipmentSpecialServiceSummary[];

  constructor() {}

  agInit(params: any): void {
    const value = _get(params, 'value', undefined);

    if (value) {
      this.specialServices = value;
    }
  }

  refresh(): boolean {
    return false;
  }
}
