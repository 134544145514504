export class ConfirmCancelData {
  heading: string;
  body: string;
  cancelButtonTitle: string;
  confirmButtonTitle: string;

  constructor(heading: string, body: string, cancelButtonTitle: string, confirmButtonTitle: string) {
    this.heading = heading;
    this.body = body;
    this.cancelButtonTitle = cancelButtonTitle;
    this.confirmButtonTitle = confirmButtonTitle;
  }
}
