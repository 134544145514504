import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { DeliveryShipmentSearchRecord } from '@xpo-ltl/sdk-cityoperations';
import {
  DeliveryQualifierCdPipe,
  ProFormatterPipe,
  XpoLtlServiceCentersService,
  XpoLtlTimeService,
} from '@xpo/ngx-ltl';
import { RowNode } from 'ag-grid-community';
import {
  filter as _filter,
  find as _find,
  first as _first,
  forOwn as _forOwn,
  get as _get,
  groupBy as _groupBy,
  has as _has,
  includes as _includes,
  map as _map,
  set as _set,
  size as _size,
} from 'lodash';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { PndDialogService } from '../../../../../core';
import { PndStoreState, UnassignedDeliveriesStoreActions, UnassignedDeliveriesStoreSelectors } from '../../../../store';
import { SpecialServicesService, StopWindowService, UnassignedDeliveriesService } from '../../../shared';
import {
  consigneeShipmentToId,
  consigneeToId,
  UnassignedDeliveryIdentifier,
} from '../../../shared/interfaces/event-item.interface';
import { BillClassCdPipe } from '../../../shared/pipes/bill-class-cd.pipe';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import { UnassignedDeliveriesBase } from '../unassigned-deliveries-base';
import { UnassignedDeliveriesBoard } from '../unassigned-deliveries-header/unassigned-deliveries-header.component';
import { UnassignedDeliveriesShipmentsBoardTemplate } from './unassigned-deliveries-shipments-board-view-template.model';
import { UnassignedDeliveriesShipmentsDataSource } from './unassigned-deliveries-shipments-data-source.service';

@Component({
  selector: 'pnd-unassigned-deliveries-shipments-board',
  templateUrl: './unassigned-deliveries-shipments.component.html',
  styleUrls: ['./unassigned-deliveries-shipments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [UnassignedDeliveriesShipmentsDataSource],
})
export class UnassignedDeliveriesShipmentsComponent extends UnassignedDeliveriesBase implements OnInit, OnDestroy {
  @Input() selectedBoard$: Observable<UnassignedDeliveriesBoard>;
  @Input() set groupSelected$(value: Observable<boolean>) {
    this.setGroupSelected(value);
  }
  @Output() showBoard = new EventEmitter<UnassignedDeliveriesBoard>();
  @Output() groupSelectedChange = new EventEmitter<boolean>();

  viewTemplates: UnassignedDeliveriesShipmentsBoardTemplate[];

  constructor(
    protected dataSource: UnassignedDeliveriesShipmentsDataSource,
    protected pndStore$: Store<PndStoreState.State>,
    protected dialog: MatDialog,
    protected pndDialogService: PndDialogService,
    protected userPreferencesService: UserPreferencesService,
    protected unassignedDeliveriesService: UnassignedDeliveriesService,
    protected timeService: XpoLtlTimeService,

    private serviceCentersService: XpoLtlServiceCentersService,
    private stopWindowService: StopWindowService,
    private specialServicesService: SpecialServicesService,
    private locationApiService: LocationApiService,

    private billClassCdPipe: BillClassCdPipe,
    private proFormatterPipe: ProFormatterPipe,
    protected deliveryQualifierCdPipe: DeliveryQualifierCdPipe,
    private decimalPipe: DecimalPipe
  ) {
    super(dataSource, pndStore$, dialog, pndDialogService, userPreferencesService, unassignedDeliveriesService);

    const unassignedDeliveriesTemplate = new UnassignedDeliveriesShipmentsBoardTemplate(
      this.pndStore$,
      this.locationApiService,
      this.serviceCentersService,
      this.deliveryQualifierCdPipe,
      this.proFormatterPipe,
      this.timeService,
      this.stopWindowService,
      this.billClassCdPipe,
      this.specialServicesService,
      this.decimalPipe,

      this.onAddressClick.bind(this),
      this.onShowShipmentDetails.bind(this)
    );

    this.viewTemplates = [unassignedDeliveriesTemplate];
    this.gridOptions = unassignedDeliveriesTemplate.getGridOptions();
  }

  /**
   * User clicked on an item in the board, so need to update the Store to reflect the new state
   */
  protected onSelectonChangedFromBoard(selectedInBoard: UnassignedDeliveryIdentifier[]) {
    this.pndStore$
      .select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected)
      .pipe(take(1))
      .subscribe((storeSelection) => {
        const currentSelection = _map(storeSelection, (item) => item.id);

        // group all nodes by stop
        const nodesGroupByStop = {};
        this.gridApi.forEachNode((node: RowNode) => {
          const shipmentData = node.data as DeliveryShipmentSearchRecord;
          const stopId = consigneeToId(shipmentData);
          if (!_has(nodesGroupByStop, stopId)) {
            _set(nodesGroupByStop, stopId, []);
          }
          _get(nodesGroupByStop, stopId).push(node);
        });

        // group the selected shipments by stops
        const selectedItemsByStops = _groupBy(selectedInBoard, (item) => consigneeToId(item));

        // hold all selection data.
        const boardSelection: UnassignedDeliveryIdentifier[] = [];

        _forOwn(selectedItemsByStops, (shipments, stopId) => {
          if (_size(shipments) === _size(_get(nodesGroupByStop, stopId))) {
            // all shipments at this stop have been selected. We only want to store the
            // stop Id, not each shipment, in the store
            const stop = { consignee: { ...shipments[0].consignee } };
            boardSelection.push(stop);
          } else {
            // push each selected shipment
            boardSelection.splice(-1, 0, ...shipments);
          }
        });

        // compare current slection with board selection. If different, then update the store
        if (!this.areListsEqual(boardSelection, currentSelection)) {
          // selections differ, so update store with latest from Board
          this.pndStore$.dispatch(
            new UnassignedDeliveriesStoreActions.SetSelectedDeliveries({
              selectedDeliveries: _map(boardSelection, (item) => this.toEventItem(item)),
            })
          );
        }
      });
  }

  /**
   * Reflect changes in the Store in to the Grid
   */
  protected onSelectionChangedFromStore(selectedInStore: UnassignedDeliveryIdentifier[]) {
    if (!this.gridApi) {
      // grid is not ready, so skip this
      return;
    }

    // group selections by consigneeId
    const selectedItemsByStops = _groupBy(selectedInStore, (item) => consigneeToId(item));

    // get list of all stops that have all shipments selected
    const selectedEntireStopIds = _map(
      _filter(selectedInStore, (item) => !item.shipmentInstId),
      (item2) => consigneeToId(item2)
    );

    this.gridApi.forEachNode((node: RowNode) => {
      const shipmentData = node.data as DeliveryShipmentSearchRecord;

      // if the stop is in the selected list, then it is selected
      const shipmentConsigneeId = consigneeToId(shipmentData);
      let isSelected = _includes(selectedEntireStopIds, shipmentConsigneeId);

      if (!isSelected) {
        const shipmentsAtStop = _get(selectedItemsByStops, shipmentConsigneeId, []);
        const shipmentId = consigneeShipmentToId(shipmentData);
        isSelected = !!_find(shipmentsAtStop, (item) => consigneeShipmentToId(item) === shipmentId);
      }

      // set selection state for the node
      node.setSelected(isSelected, false, true);
    });
  }

  /**
   * Handle the focused item changing
   */
  protected onFocusChangedFromStore(focusedItem: UnassignedDeliveryIdentifier) {
    this.focusedItem = focusedItem;
    if (this.gridApi) {
      this.gridApi.redrawRows({ rowNodes: this.focusedRows });

      this.focusedRows = this.findNodesForStop(consigneeToId(focusedItem));
      this.gridApi.redrawRows({ rowNodes: this.focusedRows });
      this.gridApi.ensureNodeVisible(_first(this.focusedRows));
    }
  }
}
