import { UnassignedPickup } from '@xpo-ltl/sdk-cityoperations';
import { EventItem, UnassignedPickupIdentifier } from '../../inbound-planning/shared/interfaces/event-item.interface';
import { GlobalFilterMapCoordinate } from '../../inbound-planning/shared/models/global-filter-criteria.model';
import { UnassignedPickupsSearchCriteria } from './unassigned-pickups-search-criteria.interface';

export const StateKey = 'unassignedPickupsState';

export interface State {
  readonly criteria: UnassignedPickupsSearchCriteria;
  readonly selectedPickups: EventItem<UnassignedPickupIdentifier>[];
  readonly focusedPickup: EventItem<UnassignedPickupIdentifier>;
  readonly boundingSearchArea: GlobalFilterMapCoordinate[];
}

export const initialState: State = {
  criteria: null,
  selectedPickups: [],
  focusedPickup: {
    id: undefined,
    source: undefined,
  },
  boundingSearchArea: new Array<GlobalFilterMapCoordinate>(),
};
