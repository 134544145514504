import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import {
  CityOperationsApiService,
  DriverLocation,
  Route,
  RouteEquipment,
  TripDetail,
} from '@xpo-ltl/sdk-cityoperations';
import { TripStatusCd } from '@xpo-ltl/sdk-common';
import { Unsubscriber, XpoLtlTimeService } from '@xpo/ngx-ltl';
import {
  chain as _chain,
  defaultTo as _defaultTo,
  find as _find,
  findIndex as _findIndex,
  forEach as _forEach,
  get as _get,
  includes as _includes,
  invoke as _invoke,
  isEmpty as _isEmpty,
  nth as _nth,
  remove as _remove,
  size as _size,
  uniq as _uniq,
  without as _without,
} from 'lodash';
import { BehaviorSubject, forkJoin, interval, Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { ConfigManagerProperties } from '../../../../../../core';
import { PndRouteUtils } from '../../../../../../shared/route-utils';
import {
  DriverStoreActions,
  DriverStoreSelectors,
  GlobalFilterStoreSelectors,
  PndStoreState,
  RoutesStoreSelectors,
  TripsStoreSelectors,
} from '../../../../../store';
import { DriverMapMarkerInfo } from '../../../../shared';
import { DriverMapMarker } from '../../../../shared/models/markers/driver-map-marker';
import { MapMarkersService } from '../../../../shared/services/map-markers.service';
import { RouteColorService } from '../../../../shared/services/route-color.service';

interface MarkerRouteInfo {
  routeName: string;
  routeInstId: number;
  color: string;
}

const DEFAULT_DRIVER_UPDATE_INTERVAL = 10000; // if no config value, update driver every 10s

@Component({
  selector: 'app-driver-location-layer',
  templateUrl: './driver-location-layer.component.html',
  styleUrls: ['./driver-location-layer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverLocationLayerComponent implements OnInit, OnDestroy {
  @Input()
  set showAllDrivers(value: boolean) {
    this.showAllDriversSubject.next(value);
  }
  get showAllDrivers(): boolean {
    return this.showAllDriversSubject.value;
  }
  private showAllDriversSubject = new BehaviorSubject<boolean>(false);
  readonly showAllDrivers$ = this.showAllDriversSubject.asObservable();

  private unsubscriber: Unsubscriber = new Unsubscriber();

  private markersSubject = new BehaviorSubject<DriverMapMarker[]>([]);
  readonly markers$ = this.markersSubject.asObservable();

  private updateDriversSubscription: Subscription;
  private updateRouteDriversSubscription: Subscription;
  private updateInterval = DEFAULT_DRIVER_UPDATE_INTERVAL;

  private selectedRouteDrivers: string[] = [];
  private driversFromLocations: string[] = [];

  constructor(
    private pndStore$: Store<PndStoreState.State>,
    private markersService: MapMarkersService,
    private timeService: XpoLtlTimeService,
    private configService: ConfigManagerService,
    private cityOpsService: CityOperationsApiService,
    private routeColorService: RouteColorService
  ) {
    // get update interval
    this.updateInterval = _defaultTo(
      this.configService.getSetting<number>(ConfigManagerProperties.driversLocationRefreshInterval),
      this.updateInterval
    );
  }

  ngOnInit() {
    // update driver locations on SIC changes
    this.pndStore$
      .pipe(
        select(GlobalFilterStoreSelectors.globalFilterSic),
        filter((sicCd) => !_isEmpty(sicCd)),
        distinctUntilChanged(),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((sicCd) => {
        if (this.showAllDrivers) {
          this.beginUpdateDriverLocations();
        }
      });

    // update the markers when Store changes
    this.pndStore$
      .pipe(select(DriverStoreSelectors.driversCurrentLocation), takeUntil(this.unsubscriber.done))
      .subscribe((driverLocations: DriverLocation[]) => {
        this.updateDriverLocations(driverLocations);
      });

    // update markers when selected routes changes
    this.pndStore$
      .pipe(select(RoutesStoreSelectors.selectedRoutes), takeUntil(this.unsubscriber.done))
      .subscribe((selectedRoutes: Route[]) => {
        this.updateDriversForRoutes(selectedRoutes);
      });

    // toggle updating driver locations based on ShowAllDrivers toggle
    this.showAllDrivers$.subscribe((showAllDrivers: boolean) => {
      if (showAllDrivers) {
        this.beginUpdateDriverLocations();
      } else {
        this.stopUpdateDriverLocations();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private inSelectedRoute(marker: DriverMapMarker): boolean {
    // return true if this driver is part of any selected route
    const inARoute = !!_find(this.selectedRouteDrivers, (driverId: string) => driverId === marker.markerInfo.driverId);
    return inARoute;
  }

  inSelectedRoute$(marker: DriverMapMarker): Observable<boolean> {
    return of(this.inSelectedRoute(marker));
  }

  private beginUpdateDriverLocations() {
    this.stopUpdateDriverLocations();

    this.pndStore$
      .pipe(
        select(GlobalFilterStoreSelectors.globalFilterSic),
        filter((sicCd) => !_isEmpty(sicCd)),
        take(1)
      )
      .subscribe((sicCd: string) => {
        // Fetch all of the driver locations for the given sicCd
        this.pndStore$.dispatch(new DriverStoreActions.FetchDriversCurrentLocationAction({ sicCd }));

        // begin refreshing driver locations periodically
        this.updateDriversSubscription = interval(this.updateInterval)
          .pipe(takeUntil(this.unsubscriber.done))
          .subscribe(() => {
            this.pndStore$.dispatch(new DriverStoreActions.FetchDriversCurrentLocationAction({ sicCd }));
          });
      });
  }

  private stopUpdateDriverLocations() {
    _invoke(this.updateDriversSubscription, 'unsubscribe');
    this.updateDriversSubscription = undefined;
  }

  private beginUpdateRouteDriverLocations() {
    this.stopUpdateRouteDriverLocations();

    // Fetch all of the driver locations for the given sicCd
    this.updateDriversWithIds(this.selectedRouteDrivers);

    // begin refreshing selected driver locations periodically
    if (_size(this.selectedRouteDrivers) > 0) {
      this.updateRouteDriversSubscription = interval(this.updateInterval)
        .pipe(takeUntil(this.unsubscriber.done))
        .subscribe(() => {
          this.updateDriversWithIds(this.selectedRouteDrivers);
        });
    } else {
      // clear out drivers that are no longer selected
      this.updateDriversWithIds(this.selectedRouteDrivers);
    }
  }

  private stopUpdateRouteDriverLocations() {
    _invoke(this.updateRouteDriversSubscription, 'unsubscribe');
    this.updateRouteDriversSubscription = undefined;
  }

  private updateDriversWithIds(driverIds: string[]) {
    // get location for a driver
    const fetchDriverLocation = (employeeId: string) => {
      return this.cityOpsService
        .getPnDDriverLocation({
          employeeId,
        })
        .pipe(map((response) => response.driverLocation));
    };

    // fetch driver locations and join into a single list
    forkJoin([...driverIds.map((employee) => fetchDriverLocation(employee))])
      .pipe(
        catchError((error) => {
          return of(undefined);
        }),
        map((results) => results)
      )
      .subscribe((driverLocations: DriverLocation[]) => {
        // update the store with latest driver data
        this.pndStore$.dispatch(
          new DriverStoreActions.UpdateDriversCurrentLocationAction({ updatedDriverLocations: driverLocations })
        );

        // update the markers for these drivers
        // this.updateDriverLocations(driverLocations, false);
      });
  }

  /**
   * Update the DriverLocation for all drivers associated with the passed routes
   */
  private updateDriversForRoutes(routes: Route[]) {
    // get all drivers associated with routes. To do that, we need to
    // get all of the Trips and see which ones contain the route, and get
    // the driver assigned to that trip

    // get the list of trips
    this.pndStore$.pipe(select(TripsStoreSelectors.trips), take(1)).subscribe((trips: TripDetail[]) => {
      const driverIds: string[] = [];

      // get all driverIds for routes in trips
      _forEach(routes, (route: Route) => {
        const tripDetail = _find(trips, (detail: TripDetail) => _get(detail, 'trip.tripInstId') === route.tripInstId);
        const tripStatusCd = _get(tripDetail, 'trip.statusCd');
        if (tripStatusCd === TripStatusCd.DISPATCHED || tripStatusCd === TripStatusCd.RETURNING) {
          const driverId = _get(tripDetail, 'tripDriver.dsrEmployeeId');
          if (driverId) {
            driverIds.push(driverId);
          }
        }
      });

      const routeDrivers = _uniq(driverIds);

      // remove markers for any driver that is no longer in a selected route AND
      // has not been added to list of drivers from Driver Location update
      const driversToRemove = _without(this.selectedRouteDrivers, ...routeDrivers, ...this.driversFromLocations);
      this.removeMarkersForDrivers(driversToRemove);

      this.selectedRouteDrivers = routeDrivers;

      this.beginUpdateRouteDriverLocations();
    });
  }

  /**
   * Remove markers for the specified drivers
   * @param driverIds list of driverIds to remove
   */
  private removeMarkersForDrivers(driverIds: string[]) {
    const existingMarkers = this.markersSubject.value;
    _remove(existingMarkers, (marker: DriverMapMarker) => {
      return _includes(driverIds, marker.markerInfo.driverId);
    });
    this.markersSubject.next(existingMarkers);
  }

  /**
   * Update markers to reflect the new set of DriverLocations
   *
   * @param driverLocations DriverLocations to use for updating markers
   * @param removeOldDrivers when true, removes all markers that are not included in the passed driverLocations array
   */
  private updateDriverLocations(driverLocations: DriverLocation[], removeOldDrivers: boolean = true) {
    const existingMarkers: DriverMapMarker[] = this.markersSubject.value;

    // remove all markers that no longer have an updated position
    if (removeOldDrivers) {
      _remove(existingMarkers, (marker: DriverMapMarker) => {
        if (this.inSelectedRoute(marker)) {
          // don't remove markers that are in a selected route
          return false;
        } else {
          return (
            _findIndex(
              driverLocations,
              (location: DriverLocation) => location.tripDriver.dsrEmployeeId === marker.markerInfo.driverId
            ) === -1
          );
        }
      });
    }

    this.driversFromLocations = [];

    this.pndStore$
      .select(TripsStoreSelectors.trips)
      .pipe(take(1))
      .subscribe((trips: TripDetail[]) => {
        this.updateDriverMarkers(driverLocations, trips, existingMarkers);
      });
  }

  /**
   * Update the Driver mapMarkers and driversFromLocations with the passed data
   */
  private updateDriverMarkers(
    driverLocations: DriverLocation[],
    trips: TripDetail[],
    existingMarkers: DriverMapMarker[]
  ) {
    // update or add driver locations
    _forEach(driverLocations, (driver: DriverLocation) => {
      const driverId = driver.tripDriver.dsrEmployeeId;
      const existingMarkerIndex = _findIndex(existingMarkers, (m) => m.markerInfo.driverId === driverId);

      this.driversFromLocations.push(driverId); // remember that this driver came from ALL driver location, not a trip

      // find the route the driver is on.  Only way to do this is to look through the routeEquipment until
      // we find the first Route.
      let route = _chain(driver.routeEquipment)
        .find((equip: RouteEquipment) => !!_get(equip, 'route.routeInstId'))
        .get('route')
        .value();

      if (!route) {
        // find the route for the driver in the trips
        route = _chain(trips)
          .find((trip) => _get(trip, 'tripDriver.dsrEmployeeId') === driverId)
          .get('route')
          .first()
          .get('route')
          .value();
      }

      // get route info for the driver
      const routeInfo: MarkerRouteInfo = {
        routeName: PndRouteUtils.getRouteId(route),
        routeInstId: _get(route, 'routeInstId'),
        color: route ? this.routeColorService.getColorForRoute(route.routeInstId) : undefined,
      };

      if (existingMarkerIndex === -1) {
        // create new marker
        const marker = this.createMarkerForDriverLocation(driver, routeInfo);
        existingMarkers.push(marker);
      } else {
        // udpate existing marker
        const marker = _nth(existingMarkers, existingMarkerIndex);
        this.updateMarkerData(marker, driver, routeInfo);
      }
    });

    this.driversFromLocations = _uniq(this.driversFromLocations);

    this.markersSubject.next(existingMarkers);
  }

  private fixMissingRouteData(markerInfo: DriverMapMarkerInfo, routeInfo: MarkerRouteInfo) {
    if (_size(markerInfo.routes) === 0) {
      // couldn't find a route in the driver data, so push the info we need.
      markerInfo.routes.push({
        id: routeInfo.routeName,
        routeInstId: routeInfo.routeInstId,
        feetAvailable: 0,
      });
    }
  }

  private createMarkerForDriverLocation(driver: DriverLocation, routeInfo: MarkerRouteInfo): DriverMapMarker {
    const driverMapMarkerInfo = new DriverMapMarkerInfo(driver, this.timeService);

    const dsr: string = _get(driver, 'tripDriver.dsrName');
    let initials = '';
    if (dsr && dsr.indexOf(', ') > 1) {
      initials = dsr[dsr.indexOf(', ') + 2] + dsr[0];
    }

    const driverMarker = {
      ...new DriverMapMarker(),
      latitude: driver.currentLocation.coordinates.latitude,
      longitude: driver.currentLocation.coordinates.longitude,
      icon: this.markersService.getDriverIconWithInitials(initials, routeInfo.color),
      initials: initials,
      markerInfo: driverMapMarkerInfo,
    };

    this.fixMissingRouteData(driverMapMarkerInfo, routeInfo);

    return driverMarker;
  }

  private updateMarkerData(
    marker: DriverMapMarker,
    driverLocation: DriverLocation,
    routeInfo: MarkerRouteInfo
  ): DriverMapMarker {
    marker.latitude = driverLocation.currentLocation.coordinates.latitude;
    marker.longitude = driverLocation.currentLocation.coordinates.longitude;
    marker.markerInfo = new DriverMapMarkerInfo(driverLocation, this.timeService);
    marker.icon = this.markersService.getDriverIconWithInitials(marker.initials, routeInfo.color);

    this.fixMissingRouteData(marker.markerInfo, routeInfo);

    return marker;
  }
}
