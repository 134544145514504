import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { AppMetadataApiService } from '@xpo-ltl/sdk-appmetadata';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IMinAppVersionResponse, VersionGuardBase } from '../version-guard-base.class';

@Injectable({
  providedIn: 'root',
})
export class VersionMismatchGuard extends VersionGuardBase implements CanActivate {
  constructor(appMetadataApiService: AppMetadataApiService, configManager: ConfigManagerService, router: Router) {
    super(appMetadataApiService, configManager, router);
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.isMinimumVersionMet().pipe(
      map(({ meetsMinimumVersion }: IMinAppVersionResponse) => {
        if (meetsMinimumVersion) {
          this.router.navigate([''], {
            replaceUrl: true,
          });
        }
        return !meetsMinimumVersion;
      })
    );
  }
}
