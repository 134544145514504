import { MatDialog } from '@angular/material';
import { XpoCheckboxFilter } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoAgGridFormatters } from '@xpo-ltl/ngx-ltl-grid';
import { Metric } from '@xpo-ltl/sdk-cityoperations';
import { MetricValueKeyCd, RouteStatusCd } from '@xpo-ltl/sdk-common';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { get as _get, isEmpty as _isEmpty } from 'lodash';
import { take } from 'rxjs/operators';
import { PndRouteUtils } from '../../../../../shared/route-utils';
import { EquipmentPipe, RouteService, SpecialServicesService } from '../../../shared';
import { ReleaseRouteComponent } from '../../../shared/components/release-route';
import { ReleaseRouteDialogData } from '../../../shared/components/release-route/release-route-dialog-data';
import { ReleaseRouteDialogResults } from '../../../shared/components/release-route/release-route-dialog-results';
import { ReleaseRouteFormFields } from '../../../shared/components/release-route/release-route-form-fields';
import { TripStatusFilter } from '../../../shared/filters';
import { RemoveLeadingZerosPipe } from '../../../shared/pipes/remove-leading-zeros.pipe';
import { RouteColorService } from '../../../shared/services/route-color.service';
import { TripPlanningGridHeaders } from '../enums/trip-planning-grid-headers.enum';
import { TripPlanningGridFields } from './../enums/trip-planning-grid-fields.enum';

export class TripPlanningRouteBoardTemplate extends XpoAgGridBoardViewTemplate {
  static templateId = 'TripPlanningRouteBoardTemplate';

  constructor(
    showRouteCallback: (route: any) => void,
    private refreshCallback: () => void,
    timeService: XpoLtlTimeService,
    private equipmentPipe: EquipmentPipe,
    private removeLeadingZerosPipe: RemoveLeadingZerosPipe,
    specialServicesService: SpecialServicesService,
    private dialog: MatDialog,
    private routeService: RouteService,
    private routeColorService: RouteColorService
  ) {
    super({
      id: TripPlanningRouteBoardTemplate.templateId,
      name: 'Trips',
      keyField: 'keyField',
      availableColumns: [
        {
          headerName: TripPlanningGridHeaders.ROW_SELECTED,
          field: TripPlanningGridFields.ROW_SELECTED,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          width: 60,
          lockPosition: true,
          sortable: true,
          pinnedRowCellRenderer: 'totalTextCellRenderer',
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const nodeASel = nodeA.isSelected() ? 1 : 0;
            const nodeBSel = nodeB.isSelected() ? 1 : 0;
            return nodeASel - nodeBSel;
          },
          cellStyle: (params) => {
            const routeInstId = _get(params, 'node.data.routeInstId');
            const isSelected = params.node.isSelected();
            const color =
              isSelected && routeInstId ? this.routeColorService.getColorForRoute(routeInstId) : 'transparent';
            const width = '4px';

            return {
              'border-left': isSelected ? `${width} solid ${color}` : `${width} solid transparent`,
            };
          },
        },
        {
          headerName: TripPlanningGridHeaders.TIMELINE,
          field: TripPlanningGridFields.TIMELINE,
          hide: true,
          headerComponent: 'routeTimelineHeaderRenderer',
          cellRenderer: 'routeTimelineCellRenderer',
          width: 750,
          pinned: 'left',
          sortable: false,
        },
        {
          headerName: TripPlanningGridHeaders.RELEASE,
          width: 80,
          cellRenderer: 'releaseRouteToggleCellRenderer',
          cellRendererParams: {
            onClick: (params) => {
              if (this.isSelectedRouteForRowReleasable(params)) {
                const isReleased = _get(params, 'data.route.xdockReleaseInd', false);
                const startTime = _get(params, 'data.route.deliveryRouteDepartTime', '00:00');
                if (startTime === '00:00' && !isReleased) {
                  this.showReleaseReleaseRouteDialog(params.data, ReleaseRouteFormFields.RouteStartTime);
                } else {
                  const routeName = PndRouteUtils.getRouteId(params.data.route);
                  const routeInstId = _get(params, 'data.route.routeInstId');
                  const doorNbr = _get(params, 'data.route.plannedDoor');
                  const trailerNbr = this.equipmentPipe.transform(
                    _get(params, 'data.route.equipmentIdPrefix', ''),
                    _get(params, 'data.route.equipmentIdSuffixNbr', 0)
                  );
                  const dockLocation = _get(params, 'data.route.equipmentDockLocation.dockName');
                  const nearestDoorNbr = _get(params, 'data.route.equipmentDockLocation.dockClosestDoorNbr');
                  const originalStatusCd = _get(params, 'data.route.statusCd');
                  // If route is released, the intent is to unrelease it and vice-versa
                  const toRelease = !isReleased;

                  this.routeService
                    .releaseRoute(
                      routeName,
                      routeInstId,
                      doorNbr,
                      startTime,
                      dockLocation,
                      nearestDoorNbr,
                      trailerNbr,
                      toRelease
                    )
                    .pipe(take(1))
                    .subscribe(
                      () => {
                        this.refreshCallback();
                      },
                      () => {
                        params.data.route.statusCd = originalStatusCd;
                        params.api.redrawRows();
                      }
                    );
                }
              }
            },
          },
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const nodeAVal: string = _get(nodeA, 'data.route.statusCd', '');
            const nodeBVal: string = _get(nodeB, 'data.route.statusCd', '');
            return nodeAVal.localeCompare(nodeBVal);
          },
        },
        {
          headerName: TripPlanningGridHeaders.SEQ,
          width: 55,
          cellRenderer: 'circleCellRenderer',
          cellRendererParams: { field: 'data.route.sequenceValidInd', color: 'green' },
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const nodeAInd = _get(nodeA, 'data.route.sequenceValidInd') ? 1 : 0;
            const nodeBInd = _get(nodeB, 'data.route.sequenceValidInd') ? 1 : 0;
            return nodeAInd - nodeBInd;
          },
        },
        {
          headerName: TripPlanningGridHeaders.DRIVER,
          field: TripPlanningGridFields.DRIVER,
          width: 200,
        },
        {
          headerName: TripPlanningGridHeaders.ROUTE_PREFIX,
          field: TripPlanningGridFields.ROUTE_PREFIX,
          cellRenderer: 'routeActionLinkCellRenderer',
          cellRendererParams: { onClick: showRouteCallback },
          sortable: true,
          sort: 'asc',
          width: 105,
          valueGetter: (params) => {
            return PndRouteUtils.getRouteId(params.data.route);
          },
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const routeA = PndRouteUtils.getRouteId(nodeA.data.route);
            const routeB = PndRouteUtils.getRouteId(nodeB.data.route);
            return routeA.localeCompare(routeB);
          },
        },
        {
          headerName: TripPlanningGridHeaders.SUFFIX,
          field: TripPlanningGridFields.SUFFIX,
          sortable: true,
          sort: 'asc',
          width: 70,
          valueGetter: () => '',
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const suffixA = _get(nodeA, 'data.route.routeSuffix', '');
            const suffixB = _get(nodeB, 'data.route.routeSuffix', '');
            return suffixA.localeCompare(suffixB);
          },
          cellStyle: () => ({
            'padding-left': '0px',
          }),
        },
        {
          headerName: TripPlanningGridHeaders.DELIVERY_CWT,
          width: 125,
          type: 'numericColumn',
          cellStyle: () => {
            return { 'padding-right': '10px' };
          },
          valueFormatter: (param) => {
            const metric = _get(param.data, 'metrics', []).find(
              (m) => m.valueKeyCd === MetricValueKeyCd.DELIVERY_COST_PER_CWT
            );
            return _get(metric, 'displayValue', '');
          },
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const getMetricValue = (metrics: Metric[]) => {
              const metric = metrics.find((m) => m.valueKeyCd === MetricValueKeyCd.DELIVERY_COST_PER_CWT);
              return _get(metric, 'value', 0);
            };
            const nodeAValue = getMetricValue(_get(nodeA, 'data.metrics', []));
            const nodeBValue = getMetricValue(_get(nodeB, 'data.metrics', []));

            return nodeAValue - nodeBValue;
          },
        },
        {
          headerName: TripPlanningGridHeaders.ROUTE_STATUS,
          field: TripPlanningGridFields.ROUTE_STATUS,
          width: 112,
          valueGetter: (params) => {
            return _get(params, 'data.route.statusCd', '');
          },
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const nodeAStatus = _get(nodeA, 'data.route.statusCd', '');
            const nodeBStatus = _get(nodeB, 'data.route.statusCd', '');
            return nodeAStatus.localeCompare(nodeBStatus);
          },
        },
        {
          headerName: TripPlanningGridHeaders.SPECIAL_SERVICES,
          width: 170,
          valueGetter: (params) => _get(params, 'data.specialServices', []),
          cellRenderer: 'specialServicesCellRenderer',
          comparator: (valueA, valueB, nodeA, nodeB) =>
            specialServicesService.getSpecialServicesComparator(nodeA, nodeB),
        },
        {
          headerName: TripPlanningGridHeaders.BILLS,
          field: TripPlanningGridFields.BILLS,
          width: 56,
          type: 'numericColumn',
        },
        {
          headerName: TripPlanningGridHeaders.WEIGHT,
          field: TripPlanningGridFields.WEIGHT,
          width: 110,
          type: 'numericColumn',
          valueFormatter: XpoAgGridFormatters.formatNumber,
        },
        { headerName: TripPlanningGridHeaders.MM, field: TripPlanningGridFields.MM, width: 56, type: 'numericColumn' },
        {
          headerName: TripPlanningGridHeaders.TRIP_STOPS,
          field: TripPlanningGridFields.TRIP_STOPS,
          width: 90,
          type: 'numericColumn',
        },
        {
          headerName: TripPlanningGridHeaders.EST_START_TIME,
          field: TripPlanningGridFields.EST_START_TIME,
          width: 124,
          valueGetter: (params) => {
            const startTime = _get(params, 'data.route.deliveryRouteDepartTime', '00:00');
            return startTime === '00:00' ? '' : startTime;
          },
          cellStyle: (params) => {
            return this.getReleaseRouteCellStyle(params);
          },
          cellRenderer: 'actionLinkCellRenderer',
          cellRendererParams: {
            onClick: (event$) => {
              this.showReleaseReleaseRouteDialog(event$, ReleaseRouteFormFields.RouteStartTime);
            },
          },
        },
        {
          headerName: TripPlanningGridHeaders.EST_CLEAR_TIME,
          width: 124,
          valueGetter: (params) => {
            const emptyDateTime = _get(params, 'data.estimatedEmptyDateTime');
            if (emptyDateTime) {
              return timeService.to24Time(emptyDateTime, _get(params, 'data.route.terminalSicCd'));
            } else {
              return '';
            }
          },
        },
        {
          headerName: TripPlanningGridHeaders.DOOR,
          type: 'numericColumn',
          field: TripPlanningGridFields.DOOR,
          width: 74,
          cellStyle: (params) => ({
            ...this.getReleaseRouteCellStyle(params),
          }),
          cellRenderer: 'actionLinkCellRenderer',
          cellRendererParams: {
            onClick: (event$) => {
              this.showReleaseReleaseRouteDialog(event$, ReleaseRouteFormFields.DoorNumber);
            },
          },
          valueFormatter: (param) => this.removeLeadingZerosPipe.transform(param.value),
          comparator: (valueA, valueB, nodeA, nodeB) => {
            return _get(nodeA, 'data.route.plannedDoor', '').localeCompare(_get(nodeB, 'data.route.plannedDoor', ''));
          },
        },
        {
          headerName: TripPlanningGridHeaders.TRAILER,
          field: TripPlanningGridFields.TRAILER,
          width: 120,
          cellStyle: (params) => {
            return this.getReleaseRouteCellStyle(params);
          },
          cellRenderer: 'actionLinkCellRenderer',
          cellRendererParams: {
            onClick: (event$) => {
              this.showReleaseReleaseRouteDialog(event$, ReleaseRouteFormFields.TrailerNumber);
            },
          },
          valueFormatter: (param) => {
            return equipmentPipe.transform(
              _get(param, 'data.route.equipmentIdPrefix', ''),
              _get(param, 'data.route.equipmentIdSuffixNbr', 0)
            );
          },
          comparator: (valueA, valueB, nodeA, nodeB) => {
            return equipmentPipe
              .transform(
                _get(nodeA, 'data.route.equipmentIdPrefix', ''),
                _get(nodeA, 'data.route.equipmentIdSuffixNbr', 0)
              )
              .localeCompare(
                equipmentPipe.transform(
                  _get(nodeB, 'data.route.equipmentIdPrefix', ''),
                  _get(nodeB, 'data.route.equipmentIdSuffixNbr', 0)
                )
              );
          },
        },
        {
          headerName: TripPlanningGridHeaders.DOCK_LOCATION,
          field: TripPlanningGridFields.DOCK_LOCATION,
          width: 120,
          cellStyle: (params) => {
            return this.getReleaseRouteCellStyle(params);
          },
          cellRenderer: 'actionLinkCellRenderer',
          cellRendererParams: {
            onClick: (event$) => {
              this.showReleaseReleaseRouteDialog(event$, ReleaseRouteFormFields.DockLocation);
            },
          },
          valueFormatter: (param) => _get(param, 'data.route.equipmentDockLocation.dockName', ''),
          comparator: (valueA, valueB, nodeA, nodeB) => {
            return _get(nodeA, 'data.route.equipmentDockLocation.dockName', '').localeCompare(
              _get(nodeB, 'data.route.equipmentDockLocation.dockName', '')
            );
          },
        },
        {
          headerName: TripPlanningGridHeaders.NEAREST_DOOR,
          field: TripPlanningGridFields.NEAREST_DOOR,
          type: 'numericColumn',
          width: 120,
          cellStyle: (params) => {
            return this.getReleaseRouteCellStyle(params);
          },
          cellRenderer: 'actionLinkCellRenderer',
          cellRendererParams: {
            onClick: (event$) => {
              this.showReleaseReleaseRouteDialog(event$, ReleaseRouteFormFields.NearestDoor);
            },
          },
          valueFormatter: (param) =>
            this.removeLeadingZerosPipe.transform(
              _get(param, 'data.route.equipmentDockLocation.dockClosestDoorNbr', '')
            ),
          comparator: (valueA, valueB, nodeA, nodeB) => {
            return _get(nodeA, 'data.route.equipmentDockLocation.dockClosestDoorNbr', '').localeCompare(
              _get(nodeB, 'data.route.equipmentDockLocation.dockClosestDoorNbr', '')
            );
          },
        },
        {
          headerName: TripPlanningGridHeaders.EARLIEST_SHIPMENT_ETA,
          field: TripPlanningGridFields.EARLIEST_SHIPMENT_ETA,
          width: 160,
        },
        {
          headerName: TripPlanningGridHeaders.LATEST_SHIPMENT_ETA,
          field: TripPlanningGridFields.LATEST_SHIPMENT_ETA,
          width: 160,
        },
      ],
      availableFilters: [
        // Currently Search will be disabled until BE can support it.
        // new XpoQuickSearchFilter('q', { disableAutofocus: true }),
        new TripStatusFilter('tripStatusCd'),
      ],
      allowAdditional: true,
    });
  }

  private getReleaseRouteCellStyle(params): {} {
    if (this.isSelectedRouteForRowReleasable(params)) {
      return { 'background-color': '#fff8bc' };
    }
    return undefined;
  }

  private showReleaseReleaseRouteDialog(data, focusedField: ReleaseRouteFormFields): void {
    if (this.isSelectedRouteForRowReleasable(data)) {
      const startTime = _get(data, 'route.deliveryRouteDepartTime');
      const dialogRef = this.dialog.open(ReleaseRouteComponent, {
        data: <ReleaseRouteDialogData>{
          focusedField: focusedField,
          routeName: PndRouteUtils.getRouteId(data.route),
          routeInstId: _get(data, 'route.routeInstId'),
          routeStatusCd: _get(data, 'route.statusCd'),
          routeCategoryCd: _get(data, 'route.categoryCd'),
          xdockReleaseInd: _get(data, 'route.xdockReleaseInd', false),
          startTime: startTime && startTime !== '00:00' ? startTime : undefined,
          doorNbr: _get(data, 'route.plannedDoor'),
          trailerNbr: this.equipmentPipe.transform(
            _get(data, 'route.equipmentIdPrefix', ''),
            _get(data, 'route.equipmentIdSuffixNbr', 0)
          ),
          dockLocation: _get(data, 'route.equipmentDockLocation.dockName'),
          nearestDoorNbr: _get(data, 'route.equipmentDockLocation.dockClosestDoorNbr'),
        },
        disableClose: true,
        hasBackdrop: true,
      });
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((results: ReleaseRouteDialogResults) => {
          if (results) {
            this.refreshCallback();
          }
        });
    }
  }

  private isSelectedRouteForRowReleasable(params): boolean {
    const selector = !_isEmpty(_get(params, 'route')) ? 'route' : 'data.route';
    const statusCd = _get(params, `${selector}.statusCd`);
    return (
      statusCd === RouteStatusCd.RELEASED || statusCd === RouteStatusCd.UNRELEASED || statusCd === RouteStatusCd.LOADING
    );
  }
}
