/**
 * List of colors used for rendering routes.
 */
export const ROUTE_COLORS = [
  '#880E4F',
  '#0D47A1',
  '#8D6E63',
  '#43A047',
  '#9575CD',
  '#78909C',
  '#F4511E',
  '#1E88E5',
  '#795548',
  '#AFB42B',
  '#9C27B0',
  '#607D8B',
  '#01579B',
  '#00ACC1',
  '#3F51B5',
  '#7CB342',
  '#4A148C',
  '#EF6C00',
  '#0288D1',
  '#A1887F',
  '#009688',
  '#FB8C00',
  '#546E7A',
  '#EF5350',
  '#1976D2',
  '#558B2F',
  '#B71C1C',
  '#7986CB',
  '#0097A7',
  '#33691E',
  '#E65100',
  '#673AB7',
  '#2E7D32',
  '#BF360C',
  '#512DA8',
  '#E91E63',
  '#303F9F',
  '#388E3C',
  '#039BE5',
  '#D32F2F',
  '#006064',
  '#827717',
  '#E64A19',
  '#00796B',
  '#EC407A',
  '#00695C',
  '#BA68C8',
];
