import { XpoQuickSearchFilter, XpoDateRangeFilter, XpoCheckboxFilter } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoAgGridFormatters } from '@xpo-ltl/ngx-ltl-grid';
import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { GridOptions } from 'ag-grid-community';
import { get as _get, has as _has, truncate as _truncate } from 'lodash';
import * as moment from 'moment-timezone';
import { SpecialServicesService } from '../../../shared';
import { UnassignedPickupsGridFields } from '../enums/unassigned-pickups-grid-fields.enum';
import { UnassignedPickupsGridHeaders } from '../enums/unassigned-pickups-grid-headers.enum';
import { UnassignedPickupsGridItem } from './unassigned-pickups-grid-item.model';

export class UnassignedPickupsBoardViewTemplate extends XpoAgGridBoardViewTemplate {
  static templateId = 'UnassignedPickupsBoardTemplate';
  addressClickCallback: any;

  constructor(
    private timeService: XpoLtlTimeService,
    addressClickCallback: (pickup: any) => void,
    specialServicesService: SpecialServicesService
  ) {
    super({
      id: UnassignedPickupsBoardViewTemplate.templateId,
      name: 'Unassigned Pickups',
      keyField: 'pickupInstId',
      availableColumns: [
        {
          field: UnassignedPickupsGridFields.ROW_SELECTED,
          headerName: UnassignedPickupsGridHeaders.ROW_SELECTED,
          headerValueGetter: () => '',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          width: 50,
          sortable: true,
          pinnedRowCellRenderer: 'totalTextCellRenderer',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel = nodeA.isSelected() ? 1 : 0;
            const nodeBSel = nodeB.isSelected() ? 1 : 0;
            return nodeASel - nodeBSel;
          },
        },
        {
          headerName: UnassignedPickupsGridHeaders.SOURCE,
          field: UnassignedPickupsGridFields.SOURCE,
          sortable: true,
          width: 100,
          cellClass: 'pnd-UnassignedPickups__SourceCell',
        },
        {
          headerName: UnassignedPickupsGridHeaders.TYPE,
          field: UnassignedPickupsGridFields.TYPE,
          sortable: true,
          width: 75,
        },
        {
          headerName: UnassignedPickupsGridHeaders.SHIPPER,
          field: UnassignedPickupsGridFields.SHIPPER,
          sortable: true,
          width: 250,
          valueGetter: (params) => {
            const item: UnassignedPickupsGridItem = params.data;
            return `${_get(item, 'shipper.name1', '')} ${_get(item, 'shipper.name2', '')}`;
          },
        },
        {
          headerName: UnassignedPickupsGridHeaders.ADDRESS,
          field: UnassignedPickupsGridFields.ADDRESS,
          width: 250,
          cellClass: 'pnd-UnassignedPickups__AddressCell',
          cellRenderer: 'actionLinkCellRenderer',
          cellRendererParams: { onClick: addressClickCallback },
          valueGetter: (params) => {
            const item: UnassignedPickupsGridItem = params.data;
            return _get(item, 'shipper.addressLine1', '');
          },
        },
        {
          headerName: UnassignedPickupsGridHeaders.CITY,
          field: UnassignedPickupsGridFields.CITY,
          sortable: true,
          width: 175,
          valueGetter: (params) => {
            const item: UnassignedPickupsGridItem = params.data;
            return _get(item, 'shipper.cityName', '');
          },
        },
        {
          headerName: UnassignedPickupsGridHeaders.ZIP_CODE,
          field: UnassignedPickupsGridFields.ZIP_CODE,
          sortable: true,
          width: 100,
          valueGetter: (params) => {
            const item: UnassignedPickupsGridItem = params.data;
            return _get(item, 'shipper.postalCd', '');
          },
        },
        {
          headerName: UnassignedPickupsGridHeaders.SPECIAL_SERVICES,
          field: UnassignedPickupsGridFields.SPECIAL_SERVICES,
          width: 170,
          valueGetter: (params) => {
            // the UnassignedPickup.specialServiceSummary is actually an array of ShipmentSpecialServiceCd
            const specialServices = _get(params, 'data.specialServiceSummary') as ShipmentSpecialServiceCd[];
            return specialServices;
          },
          cellRenderer: 'specialServicesCellRenderer',
        },
        {
          headerName: UnassignedPickupsGridHeaders.PICKUP_DATE,
          field: UnassignedPickupsGridFields.PICKUP_DATE,
          width: 125,
          sortable: true,
          valueFormatter: (params) => {
            const item: UnassignedPickupsGridItem = params.data;
            if (_has(item, 'pickupDate')) {
              const time = moment(item.pickupDate);
              return time.format('MM-DD');
            }
            return '';
          },
        },

        {
          headerName: UnassignedPickupsGridHeaders.READY_TIME,
          field: UnassignedPickupsGridFields.READY_TIME,
          sortable: true,
          width: 100,
          valueFormatter: (params) => {
            const item: UnassignedPickupsGridItem = params.data;
            const date = _get(item, 'readyTime');
            if (date) {
              const readyTime = _truncate(date, { length: 5, omission: '' });
              return readyTime;
            } else {
              return '';
            }
          },
        },
        {
          headerName: UnassignedPickupsGridHeaders.CLOSE_TIME,
          field: UnassignedPickupsGridFields.CLOSE_TIME,
          sortable: true,
          width: 100,
          valueFormatter: (params) => {
            const item: UnassignedPickupsGridItem = params.data;
            const date = _get(item, 'closeTime');
            if (date) {
              const closeTime = _truncate(date, { length: 5, omission: '' });
              return closeTime;
            } else {
              return '';
            }
          },
        },
        {
          headerName: UnassignedPickupsGridHeaders.LP,
          field: UnassignedPickupsGridFields.LP,
          sortable: true,
          width: 50,
          type: 'numericColumn',
          valueFormatter: XpoAgGridFormatters.zeroAsDash,
        },
        {
          headerName: UnassignedPickupsGridHeaders.MM,
          field: UnassignedPickupsGridFields.MM,
          sortable: true,
          width: 50,
          type: 'numericColumn',
          valueFormatter: XpoAgGridFormatters.zeroAsDash,
        },
        {
          headerName: UnassignedPickupsGridHeaders.WEIGHT,
          field: UnassignedPickupsGridFields.WEIGHT,
          sortable: true,
          width: 110,
          type: 'numericColumn',
          valueFormatter: XpoAgGridFormatters.formatNumber,
        },
        {
          headerName: UnassignedPickupsGridHeaders.CUBE,
          field: UnassignedPickupsGridFields.CUBE,
          sortable: true,
          width: 100,
          type: 'numericColumn',
          valueFormatter: XpoAgGridFormatters.zeroAsDash,
        },
        {
          headerName: UnassignedPickupsGridHeaders.ORIGIN_SIC,
          field: UnassignedPickupsGridFields.ORIGIN_SIC,
          sortable: true,
          width: 80,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: UnassignedPickupsGridHeaders.CALL_NBR,
          field: UnassignedPickupsGridFields.CALL_NBR,
          sortable: true,
          width: 100,
        },
        // {
        //   headerName: UnassignedPickupsGridHeaders.Existing Stops',
        //   field: 'preAssignedTrip',
        //   sortable: true,
        //   width: 175,
        // },
        {
          headerName: UnassignedPickupsGridHeaders.STATUS,
          field: UnassignedPickupsGridFields.STATUS,
          sortable: true,
          width: 175,
        },
        {
          headerName: UnassignedPickupsGridHeaders.REMARKS,
          field: UnassignedPickupsGridFields.REMARKS,
          sortable: true,
          width: 250,
          cellStyle: {
            'margin-top': '-3px',
            display: 'block',
            overflow: 'hidden',
            'text-overflow': 'ellipsis',
            'min-width': '0',
          },
          tooltipField: UnassignedPickupsGridFields.REMARKS,
        },
      ],
      availableFilters: [
        new XpoQuickSearchFilter('q', { disableAutofocus: true }),
        new XpoDateRangeFilter('pickupDate', 'Pickup Date'),
      ],
      allowAdditional: true,
    });
    this.addressClickCallback = addressClickCallback;
  }

  getGridOptions(): GridOptions {
    return {
      headerHeight: 40,
      rowHeight: 40,
      defaultColDef: { resizable: true },
    };
  }
}
