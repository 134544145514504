import { OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material';
import { Store } from '@ngrx/store';
import { XpoBoardDataSource, XpoBoardViewDataStoreBase } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardState, XpoAgGridBoardViewConfig, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { AgGridEvent, Column, ColumnApi, GridOptions, RowNode } from 'ag-grid-community';
import {
  forEach as _forEach,
  get as _get,
  includes as _includes,
  isEqual as _isEqual,
  map as _map,
  size as _size,
  some as _some,
} from 'lodash';
import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { BoardStatesEnum } from '../../../../../shared/enums/board-states.enum';
import { PndStoreState } from '../../../../store';
import { StoreSourcesEnum } from '../../enums/store-sources.enum';
import {
  AssignedStopIdentifier,
  EventItem,
  PlanningRouteShipmentIdentifier,
  RouteIdentifier,
  UnassignedDeliveryIdentifier,
  UnassignedPickupIdentifier,
} from '../../interfaces/event-item.interface';
import { UserPreferencesService } from '../../services/user-preferences.service';

export abstract class InboundPlanningGridBaseComponent implements OnInit, OnDestroy {
  protected unsubscriber = new Unsubscriber();

  protected groupSelectedSubject = new BehaviorSubject<boolean>(false);
  groupSelected$ = this.groupSelectedSubject.asObservable();

  protected boardReadySubject = new BehaviorSubject<boolean>(false);
  boardReady$ = this.boardReadySubject.asObservable();

  stateChange$ = new ReplaySubject<XpoAgGridBoardState>(1);
  viewTemplates: XpoAgGridBoardViewTemplate[];
  viewDataStore: XpoBoardViewDataStoreBase;
  gridOptions: GridOptions;

  protected gridApiEvent: AgGridEvent;
  private gridSortModelList = [];

  constructor(
    protected pndStore$: Store<PndStoreState.State>,
    public dataSource: XpoBoardDataSource,
    protected userPreferencesService: UserPreferencesService
  ) {}

  protected abstract getRowNodeId(node: RowNode): string;
  protected abstract getSelectedRowFromStoreId(selectedRow): string;
  protected abstract getSelectedStoreStateSelector();
  protected abstract getSelectedRowColumnFieldName(): string;
  protected abstract getBoardViewTemplates(): XpoAgGridBoardViewTemplate[];
  protected abstract getGridOptions(): GridOptions;
  protected abstract mapViewDataStore(preferences): XpoBoardViewDataStoreBase;
  protected abstract getComponentName(): string;

  ngOnInit() {
    this.viewTemplates = this.getBoardViewTemplates();
    this.gridOptions = this.getGridOptions();

    this.userPreferencesService
      .getPreferencesFor<XpoAgGridBoardViewConfig[]>(this.getComponentName())
      .subscribe((preferences) => {
        this.viewDataStore = this.mapViewDataStore(preferences);
        this.boardReadySubject.next(true);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
    this.boardReadySubject.next(false);
  }

  onGridReady(gridEvent: AgGridEvent) {
    this.gridApiEvent = gridEvent;
    this.subscribeToBoardStateEvents();
    this.SubscribeToGroupSelectedToRefreshSort();
  }

  SubscribeToGroupSelectedToRefreshSort() {
    this.groupSelected$.pipe(takeUntil(this.unsubscriber.done)).subscribe(() => {
      this.setPrimarySortOnCondition('rowSelected', 'desc', this.groupSelectedSubject.getValue());
    });
  }

  toggleGroupSelected(event: MatCheckboxChange) {
    this.groupSelectedSubject.next(event.checked);

    if (!event.checked) {
      let sortModelList = this.gridApiEvent.api.getSortModel();
      const rowSelectedSortModel = {
        colId: this.getSelectedRowColumnFieldName(),
        sort: 'desc',
      };

      if (_size(sortModelList) > 0 && _isEqual(sortModelList[0], rowSelectedSortModel)) {
        sortModelList = sortModelList.filter((sortModel) => sortModel.colId !== rowSelectedSortModel.colId);
        this.gridApiEvent.api.setSortModel(sortModelList);
      }
    }
  }

  private subscribeToBoardStateEvents() {
    this.dataSource
      .connect(this)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((state: XpoAgGridBoardState) => {
        this.manageBoardStates(state);
      });
  }

  protected checkForPolygonSelections(
    items: EventItem<
      | AssignedStopIdentifier
      | UnassignedDeliveryIdentifier
      | UnassignedPickupIdentifier
      | RouteIdentifier
      | PlanningRouteShipmentIdentifier
      | PlanningRouteShipmentIdentifier[]
    >[],
    sourceEnum: StoreSourcesEnum
  ) {
    if (items.some((item) => item.source === StoreSourcesEnum.POLYGON_SELECTION)) {
      if (!this.groupSelectedSubject.getValue()) {
        this.groupSelectedSubject.next(true);
      }
      items.forEach((item) => {
        item.source = sourceEnum;
        return item;
      });
    }
  }

  protected manageBoardStates(state: XpoAgGridBoardState) {
    switch (state.source) {
      case BoardStatesEnum.SORT_CHANGE:
      case BoardStatesEnum.FILTER_CHANGE:
        this.setPrimarySortOnCondition('rowSelected', 'desc', this.groupSelectedSubject.getValue());
        break;
      case BoardStatesEnum.BOARD_DATA_FETCHER:
        this.setRowsSelectedFromStore();
        break;
      case BoardStatesEnum.SAVE_VIEW:
      case BoardStatesEnum.SAVE_VIEW_AS: {
        this.dataSource.refresh();
        break;
      }
    }
  }

  protected setPrimarySortOnCondition(rowToSort: string, sortOrder: string, condition: boolean) {
    if (!this.gridApiEvent) {
      return;
    }
    let sortModelList = this.gridApiEvent.api.getSortModel();

    if (condition) {
      const rowSelectedSortModel = {
        colId: rowToSort,
        sort: sortOrder,
      };
      const isClearSelectedRowSort =
        !_some(sortModelList, (sort) => sort.colId === rowSelectedSortModel.colId) &&
        _some(this.gridSortModelList, (sort) => sort.colId === rowSelectedSortModel.colId);

      sortModelList = [
        rowSelectedSortModel,
        ...sortModelList.filter((sortModel) => sortModel.colId !== rowSelectedSortModel.colId),
      ];
      if (!_isEqual(this.gridSortModelList, sortModelList)) {
        this.gridApiEvent.api.setSortModel(sortModelList);
      }
    }
    this.gridSortModelList = sortModelList;
  }

  protected setRowsSelectedFromStore() {
    this.pndStore$
      .select(this.getSelectedStoreStateSelector())
      .pipe(take(1))
      .subscribe((selectedResults) => {
        selectedResults = selectedResults.filter(
          (selectedResult) => selectedResult.source !== StoreSourcesEnum.POLYGON_SELECTION
        );
        const selectedResultsRowNodeIds = selectedResults.map((selectedResult) =>
          this.getSelectedRowFromStoreId(selectedResult)
        );
        this.updateNodeSelection(selectedResultsRowNodeIds);
      });
  }

  protected updateNodeSelection(selectedNodeIds: string[]) {
    if (!this.gridApiEvent) {
      return;
    }
    this.gridApiEvent.api.forEachNode((node) => {
      const rowNodeId = this.getRowNodeId(node);
      const isSelected = _includes(selectedNodeIds, rowNodeId);
      if (isSelected && !node.isSelected()) {
        node.setSelected(true);
      } else if (!isSelected && node.isSelected()) {
        node.setSelected(false);
      }
    });
    this.refreshSortForSelectedRows();
  }

  protected refreshSortForSelectedRows() {
    const currentSortModel = this.gridApiEvent.api.getSortModel();
    if (
      _some(
        currentSortModel.map((x) => x.colId),
        (colId) => colId === this.getSelectedRowColumnFieldName()
      )
    ) {
      this.refreshSort();
    }
  }

  protected refreshSort() {
    if (!this.gridApiEvent) {
      return;
    }
    this.gridApiEvent.api.setSortModel(this.gridApiEvent.api.getSortModel());
  }
}
