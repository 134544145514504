import { StopCard } from '../../inbound-planning/components/route-balancing/classes/stop-card.model';

export const StateKey = 'RouteBalancingState';

export interface State {
  readonly openRouteBalancingPanel: boolean;
  readonly routes: number[];
  readonly pinFirst: StopCard;
  readonly pinLast: StopCard;
  readonly lanesDirty: boolean;
  readonly canOpenRouteBalancing: boolean;
}

export const initialState: State = {
  openRouteBalancingPanel: false,
  routes: [],
  pinFirst: undefined,
  pinLast: undefined,
  lanesDirty: false,
  canOpenRouteBalancing: true,
};
