import { get as _get } from 'lodash';
import { tassign } from 'tassign';
import { Actions, ActionTypes } from './routes-store.actions';
import { initialState, State } from './routes-store.state';

export function routesReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    /////////////
    // Misc
    case ActionTypes.setShowCompletedStops: {
      return tassign(state, { showCompletedStops: action.payload.showCompletedStops });
    }

    case ActionTypes.updateUnassignedDeliveriesGrid: {
      return tassign(state, {
        updateUnassignedDeliveriesGrid: action.payload.updateUnassignedDeliveriesGrid,
      });
    }

    case ActionTypes.setRouteMarkers: {
      return tassign(state, { routeMarkers: action.payload.routeMarkers });
    }

    /////////////
    // Trip Routes
    case ActionTypes.setSelectedRoutes: {
      return tassign(state, {
        selectedRoutes: action.payload.selectedRoutes,
      });
    }

    case ActionTypes.setClickedRouteStop: {
      return tassign(state, { clickedRouteStop: action.payload.clickedRouteStop });
    }

    case ActionTypes.setFocusedRoute: {
      return tassign(state, {
        focusedRoute: action.payload.focusedRoute,
      });
    }

    case ActionTypes.setFocusedStopForSelectedRoute: {
      return tassign(state, {
        focusedStopForSelectedRoute: action.payload.focusedStopForSelectedRoute,
      });
    }

    case ActionTypes.setStopsForSelectedRoutes: {
      return tassign(state, {
        stopsForSelectedRoutes: action.payload.stopsForSelectedRoutes,
      });
    }

    case ActionTypes.setSelectedStopsForSelectedRoutes: {
      return tassign(state, {
        stopsSelectedForSelectedRoutes: action.payload.selectedStopsForSelectedRoutes,
      });
    }

    /////////////
    // TODO - move to Route Balancing
    case ActionTypes.setResequencedRouteData: {
      return tassign(state, { resequencedRouteData: action.payload.resequenceData });
    }

    /////////////
    // Planning Routes
    case ActionTypes.setSearchCriteria: {
      return tassign(state, {
        searchCriteria: action.payload.criteria,
      });
    }

    case ActionTypes.setChangedRoutes: {
      return tassign(state, {
        changedRoutes: action.payload.changedRoutes,
      });
    }

    case ActionTypes.setPlanningRoutesLastUpdate: {
      return tassign(state, {
        planningRoutesLastUpdate: action.payload.planningRoutesLastUpdate,
      });
    }

    case ActionTypes.setFocusedPlanningRoute: {
      return tassign(state, {
        focusedPlanningRoute: action.payload.focusedPlanningRoute,
      });
    }

    case ActionTypes.setFocusedPlanningRouteShipment: {
      return tassign(state, {
        focusedPlanningRouteShipment: action.payload.focusedPlanningRouteShipment,
      });
    }

    case ActionTypes.setSelectedPlanningRoutes: {
      // note - we want to sort the routeInstIds to make comparison faster in other functions
      return tassign(state, {
        selectedPlanningRoutes: _get(action.payload, 'selectedPlanningRoutes', []).sort(),
      });
    }

    case ActionTypes.setStopsForSelectedPlanningRoutesLastUpdate: {
      return tassign(state, {
        stopsForSelectedPlanningRoutesLastUpdate: action.payload.stopsForSelectedPlanningRoutesLastUpdate,
      });
    }

    case ActionTypes.setSelectedPlanningRoutesShipments: {
      return tassign(state, {
        selectedPlanningRoutesShipments: action.payload.selectedPlanningRoutesShipments,
      });
    }

    //////
    default: {
      return state;
    }
  }
}
