import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material';
import { Observable } from 'rxjs';
import { PluralMaps } from '../../../shared/classes/plural-maps';
import { UnassignedDeliveriesHeaderService } from './unassigned-deliveries-header.service';

export enum UnassignedDeliveriesBoard {
  Stops = 'stops',
  Shipments = 'shipments',
}

@Component({
  selector: 'pnd-unassigned-deliveries-header',
  templateUrl: './unassigned-deliveries-header.component.html',
  styleUrls: ['./unassigned-deliveries-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'pnd-UnassignedDeliveriesHeader' },
})
export class UnassignedDeliveriesHeaderComponent implements OnInit {
  @Input() selectedBoard$: Observable<UnassignedDeliveriesBoard>;
  @Input() groupSelected$: Observable<boolean>;

  @Output() showBoard = new EventEmitter<UnassignedDeliveriesBoard>();
  @Output() groupSelectedChange = new EventEmitter<boolean>();

  readonly summary$ = this.unassignedDeliveriesHeaderService.summary$;
  readonly isGeoFilterActive$ = this.unassignedDeliveriesHeaderService.isGeoFilterActive$;
  readonly areDeliveriesSelected$ = this.unassignedDeliveriesHeaderService.areDeliveriesSelected$;
  readonly showOptimize$ = this.unassignedDeliveriesHeaderService.showOptimize$;

  readonly PluralMaps = PluralMaps;
  readonly UnassignedDeliveriesBoard = UnassignedDeliveriesBoard;

  constructor(private unassignedDeliveriesHeaderService: UnassignedDeliveriesHeaderService) {}

  ngOnInit() {}

  showBoardTrigger(boardToShow: UnassignedDeliveriesBoard) {
    this.showBoard.emit(boardToShow);
  }

  onGroupSelectedChange(event: MatCheckboxChange) {
    this.groupSelectedChange.emit(event.checked);
  }

  clearGeoFilter() {
    this.unassignedDeliveriesHeaderService.clearGeoFilter();
  }

  assignRoute() {
    this.unassignedDeliveriesHeaderService.assignRoute();
  }

  optimize(): void {
    // TODO development pending
  }

  createRoute() {
    this.unassignedDeliveriesHeaderService.createRoute();
  }

  clearSelection() {
    this.unassignedDeliveriesHeaderService.clearSelection();
  }
}
