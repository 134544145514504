import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-sic-marker-compass',
  templateUrl: './sic-marker-compass.component.html',
  styleUrls: ['./sic-marker-compass.component.scss'],
})
export class SicMarkerCompassComponent implements OnChanges {
  @Input() rotationAngle: number = 0;
  transform: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['rotationAngle']) {
      this.transform = `rotate(${this.rotationAngle} 20 20)`;
    }
  }
}
