import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { get as _get } from 'lodash';
import { ActionLinkCellRendererComponent } from '../action-link-cell-renderer/action-link-cell-renderer';

@Component({
  selector: 'pnd-route-action-link-cell-renderer',
  templateUrl: './route-action-link-cell-renderer.html',
})
export class RouteActionLinkCellRendererComponent extends ActionLinkCellRendererComponent
  implements ICellRendererAngularComp {
  displayLink = true;

  private readonly deliveryRouteCategoryCd = 'Delivery';

  agInit(params: any): void {
    this.displayLink = _get(params, 'data.route.categoryCd', '') === this.deliveryRouteCategoryCd;
    super.agInit(params);
  }

  mouseOverFunction(clickEvent: MouseEvent): void {
    super.mouseOverFunction(clickEvent);
  }

  onClickFunction(clickEvent: any): void {
    super.onClickFunction(clickEvent);
  }

  refresh(params: any): boolean {
    return super.refresh(params);
  }
}
