import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatRadioChange } from '@angular/material';
import { WeatherLayer } from '../../models';

@Component({
  selector: 'app-map-weather',
  templateUrl: './map-weather.component.html',
  styleUrls: ['./map-weather.component.scss'],
})
export class MapWeatherComponent implements OnInit {
  @Output() weatherLayerChange = new EventEmitter<MatRadioChange>();
  @Input() selectedLayer: WeatherLayer;
  @Input() weatherLayers: Array<WeatherLayer>;
  @Input() map;

  constructor() {}

  ngOnInit(): void {
    console.log(this.map);
  }

  onLayerChange(event: MatRadioChange) {
    this.weatherLayerChange.emit(event);
  }
}
