import { Pipe, PipeTransform } from '@angular/core';
import { StopWindowCd } from '@xpo-ltl/sdk-common';

@Pipe({ name: 'stopWindowCd' })
export class StopWindowCdPipe implements PipeTransform {
  transform(value: StopWindowCd, abbr?: boolean): string {
    switch (value || '') {
      case StopWindowCd.APPOINTMENT:
        return abbr ? 'Appt' : 'Appointment';
      case StopWindowCd.CONFLICT:
        return 'Conflict';
      case StopWindowCd.CUSTOMER_OPERATIONAL_HOURS:
        return abbr ? 'DLW' : 'Operations Hours';
      case StopWindowCd.CUSTOMER_PROFILE_TIME:
        return abbr ? 'DLW' : 'Delivery Hours';
      case StopWindowCd.MIXED_SOURCE:
        return 'Mixed Source';
      case StopWindowCd.TIME_DATE_CRITICAL:
        return 'TDC';
      case StopWindowCd.GUARANTEED_BY_NOON:
        return 'Guaranteed by Noon';
    }
    return value;
  }
}
