export enum LayoutComponentName {
  PLANNING_MAP = 'planning-map',
  UNASSIGNED_STOPS = 'unassigned-deliveries',
  UNASSIGNED_PICKUPS = 'unassigned-pickups',
  ROUTE_PLANNING = 'route-planning',
  TRIP_PLANNING = 'trip-planning',
  ROUTE_STOPS = 'route-stops',
  ROUTE_BALANCING = 'route-balancing',
  PLANNING_ROUTE_SHIPMENTS = 'planning-route-shipments',
}
