import { Injectable, OnDestroy } from '@angular/core';
import {
  CityOperationsApiService,
  MetricFilter,
  RegisterFilterServiceCenterMetricsResp,
  RegisterFilterServiceCenterMetricsRqst,
  UnregisterFilterServiceCenterMetricsRqst,
} from '@xpo-ltl/sdk-cityoperations';
import { XrtAttributeFilter, LogLevel } from '@xpo-ltl/sdk-common';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { XrtChangedDocuments } from '@xpo/ngx-xrt';
import { XrtFireMessagingService, XrtFireMessagingTokenService } from '@xpo/ngx-xrt-firebase';
import { isEqual as _isEqual, result as _result, isEmpty as _isEmpty } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { UserRoleService } from '../../../../../core/services/user-role/user-role.service';
import { FilterParameters } from './metric-bar.interface';

@Injectable({
  providedIn: 'root',
})
export class MetricBarMessagingService implements OnDestroy {
  private currentFilterHash = '';
  private unsubscriber = new Unsubscriber();
  private elementsUpdatedSubject = new BehaviorSubject<Number>(0);
  private currentFilterParameters: FilterParameters = null;
  get elementsUpdated$(): Observable<Number> {
    return this.elementsUpdatedSubject.asObservable();
  }

  constructor(
    private cityOperationsService: CityOperationsApiService,
    private messagingTokenService: XrtFireMessagingTokenService,
    private messagingService: XrtFireMessagingService,
    private userRoleService: UserRoleService,
    private loggingService: LoggingApiService
  ) {
    this.messagingService
      .changedDocument$()
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((changedDocuments: XrtChangedDocuments) => {
        this.broadcastNumberOfElementsUpdated({
          filterHash: changedDocuments.filterHash,
          documents: JSON.parse(changedDocuments.documents.toString()),
        });
      });

    new BroadcastChannel('pnd-notification-broadcast-channel').onmessage = (event) => {
      this.broadcastNumberOfElementsUpdated({
        filterHash: event.data.data.filterHash,
        documents: JSON.parse(event.data.data.changedDocumentsJson),
      });
    };
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private broadcastNumberOfElementsUpdated(changedDocuments: XrtChangedDocuments) {
    this.loggingService.log(LogLevel.INFO, 'broadcastNumberOfElementsUpdated');
    if (changedDocuments.filterHash === this.currentFilterHash) {
      this.loggingService.log(LogLevel.INFO, 'SIC level metrics changes received', JSON.stringify(changedDocuments));
      this.elementsUpdatedSubject.next(changedDocuments.documents.length);
    }
  }

  private registerFilter(filterParameters: FilterParameters): void {
    this.messagingTokenService.getToken$().subscribe(
      (token) => {
        const request = new RegisterFilterServiceCenterMetricsRqst();
        request.userId = this.userRoleService.getUserId();
        request.connectionToken = token;
        request.filter = new MetricFilter();
        request.filter.sicCd = { ...new XrtAttributeFilter(), equals: filterParameters.sicCd };
        request.filter.planDate = { ...new XrtAttributeFilter(), equals: filterParameters.planDate };
        request.filter.roleCd = { ...new XrtAttributeFilter(), equals: 'Planner' };
        this.cityOperationsService.registerFilterServiceCenterMetrics(request).subscribe(
          (response: RegisterFilterServiceCenterMetricsResp) => {
            this.currentFilterHash = response.filterHash;
          },
          (error) => {
            this.loggingService.error(`Error ${error.code}, filter not registered for SIC level Metrics`);
          }
        );
      },
      (error) => {
        this.loggingService.error(`Error ${error.code}, filter not registered for SIC level Metrics`);
      }
    );
  }

  private unregisterFilter(filterHash: string): void {
    if (filterHash) {
      this.messagingTokenService.getToken$().subscribe((token) => {
        const request = new UnregisterFilterServiceCenterMetricsRqst();
        request.connectionToken = token;
        request.filterHash = filterHash;

        this.cityOperationsService.unregisterFilterServiceCenterMetrics(request).subscribe();
      });
    }
  }

  updateCurrentFilter(newFilterParameters: FilterParameters) {
    if (!_isEqual(newFilterParameters, this.currentFilterParameters) && !_isEmpty(newFilterParameters.sicCd)) {
      this.unregisterFilter(this.currentFilterHash);
      this.registerFilter(newFilterParameters);
      this.currentFilterParameters = newFilterParameters;
    }
  }
}
