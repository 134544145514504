import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import {
  CityOperationsApiService,
  ListPnDInboundSelectionProfilesQuery,
  SelectionProfile,
} from '@xpo-ltl/sdk-cityoperations';
import { get as _get, map as _map } from 'lodash';
import { Observable } from 'rxjs';
import { withLatestFrom, switchMap, map } from 'rxjs/operators';
import { PndStoreState } from '..';
import { globalFilterSic } from '../global-filters-store/global-filters-store.selectors';
import { PlanningProfileInterface } from './planning-profile.interface';
import {
  ActionTypes,
  LoadPlanningProfilesAction,
  SetPlanningProfilesStoreAction,
} from './planning-profiles-store.actions';

@Injectable()
export class PlanningProfilesStoreEffects {
  constructor(
    private actions$: Actions,
    private cityOperationsService: CityOperationsApiService,
    private store$: Store<PndStoreState.State>
  ) {}

  @Effect()
  loadPlanningProfiles$: Observable<Action> = this.actions$.pipe(
    ofType<LoadPlanningProfilesAction>(ActionTypes.loadPlanningProfiles),
    withLatestFrom(this.store$.select(globalFilterSic)),
    switchMap(([action, sic]) => {
      const queryParams = { ...new ListPnDInboundSelectionProfilesQuery(), sicCd: sic };
      return this.cityOperationsService.listPnDInboundSelectionProfiles(queryParams);
    }),
    switchMap((response) => {
      const planningProfiles = _map(_get(response, 'selectionProfiles'), (selectionProfile: SelectionProfile) => {
        return <PlanningProfileInterface>{
          profileId: selectionProfile.selectionProfileId,
          profileName: selectionProfile.profileName,
        };
      });
      return [new SetPlanningProfilesStoreAction({ planningProfiles: planningProfiles })];
    })
  );
}
