import { Pipe, PipeTransform } from '@angular/core';
import { TripNodeActivityCd } from '@xpo-ltl/sdk-common';

@Pipe({ name: 'activityType' })
export class ActivityTypePipe implements PipeTransform {
  transform(value: string | TripNodeActivityCd, args?: any): string {
    switch (value) {
      case TripNodeActivityCd.DELIVER_SHIPMENT:
      case 'DL':
        return 'Delivery';
      case TripNodeActivityCd.PICKUP_SHIPMENTS:
      case 'PU':
        return 'Pickup';
      case TripNodeActivityCd.HOOK_LOADED:
      case 'HL':
        return 'Hook Load';
      case TripNodeActivityCd.HOOK_EMPTY:
      case 'HE':
        return 'Hook Empty';
      case TripNodeActivityCd.SPOT_LOADED:
      case 'SL':
        return 'Spot Load';
      case TripNodeActivityCd.SPOT_EMPTY:
      case 'SE':
        return 'Spot Empty';
      case TripNodeActivityCd.DROP_EQUIPMENT:
      case 'DP':
        return 'Drop';
      case TripNodeActivityCd.HOOK_EQUIPMENT:
      case 'HK':
        return 'Hook';
      case TripNodeActivityCd.ARRIVE:
      case 'AR':
        return 'Arrive';
      case TripNodeActivityCd.DEPART_DISPATCH:
      case 'DE':
        return 'Depart';
      case 'MX':
        return 'Mixed';
    }

    return value;
  }
}
