import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { DeliveryShipmentSearchRecord, UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { chain as _chain, find as _find, get as _get, toUpper as _toUpper } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, concatMap, concatMapTo, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { StoreSourcesEnum } from '../../inbound-planning/shared/enums/store-sources.enum';
import {
  consigneeToId,
  EventItem,
  UnassignedDeliveryIdentifier,
} from '../../inbound-planning/shared/interfaces/event-item.interface';
import { UnassignedDeliveriesService } from '../../inbound-planning/shared/services/unassigned-deliveries.service';
import * as GlobalFilterStoreSelectors from '../global-filters-store/global-filters-store.selectors';
import { PlanningProfilesStoreSelectors } from '../planning-profiles-store';
import * as PndStoreState from '../pnd-store.state';
import {
  ActionTypes,
  Refresh,
  SetLastUpdate,
  SetSearchCriteria,
  SetSelectedDeliveries,
} from './unassigned-deliveries-store.actions';
import * as UnassignedDeliveriesStoreSelectors from './unassigned-deliveries-store.selectors';
import { searchCriteria } from './unassigned-deliveries-store.selectors';

@Injectable()
export class UnassignedDeliveriesStoreEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<PndStoreState.State>,
    private unassignedDeliveriesService: UnassignedDeliveriesService
  ) {}

  @Effect()
  setSearchCriteria$: Observable<Action> = this.actions$.pipe(
    ofType<SetSearchCriteria>(ActionTypes.setSearchCriteria),
    concatMapTo([new Refresh()])
  );

  // refresh deliveries based on current searchCriteria
  @Effect()
  refresh$: Observable<Action> = this.actions$.pipe(
    ofType<Refresh>(ActionTypes.refresh),
    concatMap(() => this.store$.select(searchCriteria).pipe(take(1))),
    withLatestFrom(this.store$.select(PlanningProfilesStoreSelectors.planningProfiles)),
    withLatestFrom(this.store$.select(GlobalFilterStoreSelectors.globalFilterPlanDate)),
    switchMap(([[criteria, planningProfiles], planDate]) => {
      const planningProfile = _find(planningProfiles, (profile) => profile.profileId === criteria.profileId);
      criteria.profileName = planningProfile ? _toUpper(planningProfile.profileName) : undefined;
      return this.unassignedDeliveriesService.searchUnassignedDeliveries(criteria, planDate);
    }),
    withLatestFrom(this.store$.select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected)),
    switchMap(([response, currentSelection]) => {
      const deliveries = _get(response, 'unassignedStops', []) as UnassignedStop[];

      // keep selected items that are in the new list
      const newSelectedDeliveries = _chain(currentSelection)
        .map((item) => item.id)
        .filter((selectedItem) => {
          // first, find the stop in the new set of deliveries
          const selectedStop = _find(
            deliveries,
            (stop) => consigneeToId(stop) === consigneeToId(selectedItem)
          ) as UnassignedStop;

          if (selectedStop) {
            // we found the stop. is the current selection a shipment?  if so, see if it is in the stop
            if (!!selectedItem.shipmentInstId) {
              // return true if shipment in the new stop, else return false
              const found = !!_find(
                _get(selectedStop, 'deliveryShipments', []),
                (shipment: DeliveryShipmentSearchRecord) => shipment.shipmentInstId === selectedItem.shipmentInstId
              );
              return found;
            } else {
              // entire stop selected
              return true;
            }
          }
          // selected stop not in the new list of stops
          return false;
        })
        .map((item) => {
          return {
            id: item,
            source: StoreSourcesEnum.REDUX_STORE,
          } as EventItem<UnassignedDeliveryIdentifier>;
        })
        .value();

      this.unassignedDeliveriesService.updateUnassignedDeliveries(deliveries);
      /// Clear the selectedShipments every time we setShipments
      return [
        new SetLastUpdate({ lastUpdate: new Date() }),
        new SetSelectedDeliveries({ selectedDeliveries: newSelectedDeliveries }),
      ];
    }),
    catchError(() => {
      this.unassignedDeliveriesService.updateUnassignedDeliveries([]);
      return [new SetLastUpdate({ lastUpdate: new Date() }), new SetSelectedDeliveries({ selectedDeliveries: [] })];
    })
  );
}
