import { Action } from '@ngrx/store';
import { TripDetail } from '@xpo-ltl/sdk-cityoperations';
import { TripPlanningGridItem } from '../../inbound-planning/components/trip-planning/models/trip-planning-grid-item.model';
import { TripsSearchCriteria } from './trips-search-criteria.interface';

export enum ActionTypes {
  setSearchCriteria = 'trips.setSearchCriteria',
  setTrips = 'trips.setTrips',
  setSelectedTrips = 'trips.setSelectedTrips',
  refreshTrips = 'trips.refreshTrips',
  setTransactionTimestampUTC = 'trips.SetTransactionTimestampUTC',
}

export class SetTransactionTimestampUTC implements Action {
  readonly type = ActionTypes.setTransactionTimestampUTC;
  constructor(public payload: { transactionTimestampUTC: Date }) {}
}

export class SetTripsSearchCriteria implements Action {
  readonly type = ActionTypes.setSearchCriteria;
  constructor(public payload: { criteria: TripsSearchCriteria }) {}
}

export class SetTrips implements Action {
  readonly type = ActionTypes.setTrips;
  constructor(public payload: { trips: TripDetail[] }) {}
}

export class SetSelectedTrips implements Action {
  readonly type = ActionTypes.setSelectedTrips;
  constructor(public payload: { selectedTrips: TripPlanningGridItem[] }) {}
}

export class RefreshTrips implements Action {
  readonly type = ActionTypes.refreshTrips;
  constructor() {}
}

export type Actions = SetTripsSearchCriteria | SetTrips | SetSelectedTrips | RefreshTrips | SetTransactionTimestampUTC;
