import { XpoSelectFilter, XpoSelectFilterParams } from '@xpo-ltl/ngx-ltl-board';
import { BillClassCd } from '@xpo-ltl/sdk-common';

export class BillClassFilter extends XpoSelectFilter {
  constructor(field: string) {
    super(<XpoSelectFilterParams>{
      field: field,
      label: 'Bill Class',
      options: [
        { title: 'NORM', code: BillClassCd.NORMAL_MVMT },
        { title: 'MOVR', code: BillClassCd.ASTRAY_FRT_SEGMENT },
        { title: 'COBZ', code: BillClassCd.CO_BUS_SHPMT },
        { title: 'EXPD', code: BillClassCd.EXPEDITE },
        { title: 'GCBZ', code: BillClassCd.GENERAL_CLAIMS_BUS_SHPMT },
        { title: 'PSEG', code: BillClassCd.PARTIAL_SEGMENT },
        { title: 'PTLT', code: BillClassCd.SPLIT_SEGMENT },
      ],
    });
  }
}
