import { DecimalPipe } from '@angular/common';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormatValidationService, ConditioningService } from '@xpo-ltl/common-services';
import { XpoBoardData, XpoBoardDataFetchState, XpoBoardState, XpoBoardDataSource } from '@xpo-ltl/ngx-ltl-board';
import { UnassignedPickup } from '@xpo-ltl/sdk-cityoperations';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take, takeUntil, map, tap } from 'rxjs/operators';
import {
  GlobalFilterStoreSelectors,
  PndStoreState,
  UnassignedPickupsStoreActions,
  UnassignedPickupsStoreSelectors,
} from '../../../../store';
import { State } from '../../../../store/global-filters-store/global-filters-store.state';
import { UnassignedPickupsSearchCriteria } from '../../../../store/unassigned-pickups-store/unassigned-pickups-search-criteria.interface';
import { GlobalFilterMapCoordinate, UnassignedPickupsService } from '../../../shared';
import { UnassignedPickupsGridItem } from '../models/unassigned-pickups-grid-item.model';

const MIN_TIME_BETWEEN_SEARCHES = 3000; // min time between searches when criteria is the same

@Injectable()
export class UnassignedPickupsDataSource extends XpoBoardDataSource implements OnDestroy {
  private unsubscriber: Unsubscriber = new Unsubscriber();
  private loadingDataSubject = new BehaviorSubject<UnassignedPickupsGridItem[]>([]);
  private loadingData$ = this.loadingDataSubject.asObservable();

  private lastSearchCriteriaUpdate: number = 0;

  constructor(
    private pndStore$: Store<PndStoreState.State>,
    private unassignedPickupsService: UnassignedPickupsService,
    private zone: NgZone,
    private validationService: FormatValidationService,
    private conditioningService: ConditioningService,
    private decimalPipe: DecimalPipe
  ) {
    super();

    this.subscribeToStoreChanges();
    this.subscribeToStateChanges();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  subscribeToStoreChanges() {
    // Update board when pickups changed
    this.unassignedPickupsService.unassignedPickups$
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscriber.done))
      .subscribe((pickups: UnassignedPickup[]) => {
        // tell the board to fetch data any time the UnassignedPickups is changed
        const unassignedPickups: UnassignedPickupsGridItem[] = [];
        pickups.forEach((pickup: UnassignedPickup) => {
          const unassignedPickupsGridItem: UnassignedPickupsGridItem = {
            ...pickup,
          };
          unassignedPickups.push(unassignedPickupsGridItem);
        });
        this.loadingDataSubject.next(unassignedPickups);
        this.refresh();
      });

    // Display loading while is fetching data from the backend
    this.pndStore$
      .select(UnassignedPickupsStoreSelectors.searchCriteria)
      .pipe(
        filter((search) => !!search),
        distinctUntilChanged(),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((_) => {
        // Avoiding changedAfterCheck errors
        this.zone.onStable.pipe(take(1)).subscribe(() => {
          this.setState({ dataFetchState: XpoBoardDataFetchState.Loading, source: 'PND-PROGRAMATICALLY-LOADING' });
        });
      });
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    const computeTotals = (rows: UnassignedPickupsGridItem[]) => {
      let totalLP = 0;
      let totalMM = 0;
      let totalWeight = 0;
      let totalCube = 0;
      (rows || []).forEach((row) => {
        totalLP += row.loosePcsCnt || 0;
        totalMM += row.totalMotorizedPiecesCount || 0;
        totalWeight += row.totalWeightLbs || 0;
        totalCube += row.totalCubePercentage || 0;
      });
      return [
        {
          loosePcsCnt: totalLP,
          totalMotorizedPiecesCount: totalMM,
          totalWeightLbs: totalWeight,
          totalCubePercentage: this.decimalPipe.transform(totalCube, '1.1'),
        },
      ];
    };

    return new Observable((observer) => {
      this.loadingData$.pipe(takeUntil(this.unsubscriber.done)).subscribe(
        (unassignedPickups) => {
          observer.next(
            new XpoBoardData(
              state,
              { rows: unassignedPickups, totals: computeTotals(unassignedPickups) },
              unassignedPickups.length,
              10000
            )
          );
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  private subscribeToStateChanges(): void {
    // Set Search criteria whenever any global or local criteria changes
    const globalFilters$ = this.pndStore$.select(GlobalFilterStoreSelectors.selectGlobalFilterState).pipe(
      distinctUntilChanged(),
      debounceTime(500),
      takeUntil(this.unsubscriber.done),
      filter((s) => !!s.sic && !!s.planDate && !!s.sicLatLng)
    );

    const boardState$ = this.state$.pipe(
      filter((s) => s.changes.includes('criteria')),
      distinctUntilChanged(),
      debounceTime(500),
      takeUntil(this.unsubscriber.done)
    );

    const boundingArea$ = this.pndStore$
      .select(UnassignedPickupsStoreSelectors.unassignedPickupsBoundingSearchArea)
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.unsubscriber.done));

    combineLatest([globalFilters$, boardState$, boundingArea$]).subscribe(this.updateFilters.bind(this));
  }

  updateFilters(value: Array<State | XpoBoardState | Array<GlobalFilterMapCoordinate>>) {
    const globalFilters = value[0] as State;
    const boardState = value[1] as XpoBoardState;
    const boundingArea = value[2] as GlobalFilterMapCoordinate[];

    let q = _get(boardState.criteria, 'q');
    if (!_isEmpty(q) && this.validationService.isValidProNumber(q)) {
      q = this.conditioningService.conditionProNumber(q, 11);
    }

    // create search filter criteria from updated values
    const criteria: UnassignedPickupsSearchCriteria = {
      Q: q,
      pickupTerminalSicCd: globalFilters.sic,
      pickupDate: boardState.criteria.pickupDate,
      shipperGeoCoordinates: !_isEmpty(boundingArea) ? boundingArea : undefined,
    };

    this.pndStore$
      .select(UnassignedPickupsStoreSelectors.searchCriteria)
      .pipe(take(1))
      .subscribe((previousCriteria) => {
        if (
          !_isEqual(previousCriteria, criteria) ||
          Date.now() - this.lastSearchCriteriaUpdate > MIN_TIME_BETWEEN_SEARCHES
        ) {
          this.lastSearchCriteriaUpdate = Date.now();
          this.pndStore$.dispatch(new UnassignedPickupsStoreActions.SetSearchCriteriaAction({ criteria }));
        }
      });
  }
}
