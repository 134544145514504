import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConditioningService, FormatValidationService } from '@xpo-ltl/common-services';
import { XpoBoardData, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { forEach as _forEach, get as _get, map as _map, size as _size } from 'lodash';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/operators';
import { PndStoreState } from '../../../../store';
import { BoardStateSource } from '../../../../store/board-state-source';
import { consigneeToId } from '../../../shared/interfaces/event-item.interface';
import { UnassignedDeliveriesService } from '../../../shared/services/unassigned-deliveries.service';
import { UnassignedDeliveriesDataSourceBaseService } from '../unassigned-deliveries-data-source-base.service';

export class UnassignedStopGridItem extends UnassignedStop {
  consigneeId: string; // required for looking up detilGridInfo as `detail_${consigneeId}`
}

@Injectable()
export class UnassignedDeliveriesStopsDataSource
  extends UnassignedDeliveriesDataSourceBaseService<UnassignedStopGridItem>
  implements OnDestroy {
  isRowGroupActive: boolean = true;

  constructor(
    protected pndStore$: Store<PndStoreState.State>,
    protected timeService: XpoLtlTimeService,
    protected formValidationService: FormatValidationService,
    protected conditioningService: ConditioningService,
    private unassignedDeliveriesService: UnassignedDeliveriesService
  ) {
    super(pndStore$, timeService, formValidationService, conditioningService);
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    const computeTotals = (stops: UnassignedStop[]) => {
      let totalShipmentsCount = 0;
      let motorizedPiecesCount = 0;
      let totalWeightLbs = 0;
      _forEach(stops, (stop) => {
        totalShipmentsCount += _size(stop.deliveryShipments);
        motorizedPiecesCount += _get(stop, 'motorizedPiecesCount', 0);
        totalWeightLbs += _get(stop, 'totalWeightLbs', 0);
      });
      return [
        {
          totalShipmentsCount,
          totalWeightLbs,
          motorizedPiecesCount,
        },
      ];
    };

    if (state.source === BoardStateSource.ReduxStore) {
      if (state.changes.includes('viewId')) {
        // When switching views, we need to wait until the Store updates the list.
        // however, fetchData is called before refresh, so we end up getting the previous view's
        // list, which causes the view to display incorrect data initially until the refresh occurs.
        // To combat that, return an empty data set when changing views. fetchData will be called
        // again once the Store is updated
        return of(new XpoBoardData(state, [], 0, 10000));
      } else {
        // fetch the latest Stops
        return this.unassignedDeliveriesService.unassignedDeliveries$.pipe(
          take(1),
          map((stops: UnassignedStop[]): UnassignedStopGridItem[] => {
            return _map(stops, (stop) => {
              const gridData = stop as UnassignedStopGridItem;
              gridData.consigneeId = consigneeToId(stop);
              return gridData;
            });
          }),
          map((stops: UnassignedStopGridItem[]) => {
            return new XpoBoardData(state, { rows: stops, totals: computeTotals(stops) }, _size(stops), 10000);
          })
        );
      }
    } else {
      // const routes = _get(state, 'data.consumerData.rows', []);
      // return of(new XpoBoardData(state, { rows: routes, totals: computeTotals(routes) }, _size(routes), 10000));
      return of(new XpoBoardData(state, [], 0, 10000));
    }
  }
}
