import { GeocoderLocationType } from '@agm/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  BalancePnDRoutesPath,
  BalancePnDRoutesResp,
  BalancePnDRoutesRqst,
  CityOperationsApiService,
  EvaluatePnDRouteBalancingRqst,
  GetPnDRoutePath,
  GetPnDRouteQuery,
  GetPnDTripPath,
  Route,
  RouteBalancingActivity,
  RouteBalancingRoute,
  RouteBalancingStop,
  RouteBalancingTrip,
  Stop,
  StopSequenceModelConflict,
  TripDetail,
} from '@xpo-ltl/sdk-cityoperations';
import {
  LatLong,
  NodeTypeCd,
  RouteBalancingTypeCd,
  RouteCategoryCd,
  RouteStatusCd,
  TripStatusCd,
} from '@xpo-ltl/sdk-common';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { Unsubscriber, XpoLtlTimeService } from '@xpo/ngx-ltl';
import {
  cloneDeep as _cloneDeep,
  filter as _filter,
  find as _find,
  findIndex as _findIndex,
  forEach as _forEach,
  get as _get,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  keys as _keys,
  remove as _remove,
  result as _result,
  set as _set,
  size as _size,
} from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, of, zip, forkJoin } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  skip,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { NotificationMessageService, NotificationMessageStatus } from '../../../../core';
import {
  GlobalFilterStoreSelectors,
  PndStoreState,
  RoutesStoreActions,
  RoutesStoreSelectors,
  TripsStoreActions,
  TripsStoreSelectors,
} from '../../../store';
import { NumberToValueMap } from '../../../store/number-to-value-map';
import { RouteBalancingActions, RouteBalancingSelectors } from '../../../store/route-balancing-store';
import { TripsGridItemConverterService } from '../../shared';
import { ComponentChangeUtils } from '../../shared/classes/component-change-utils';
import {
  SaveChangesModalComponent,
  SaveChangesModalEnum,
} from '../../shared/components/save-changes-modal/save-changes-modal.component';
import { StoreSourcesEnum } from '../../shared/enums/store-sources.enum';
import { GenericErrorLazyTypedModel } from '../../shared/models/generic-error-lazy-typed.model';
import { AddressUpdated, GeocodeResult, GeoLocationService } from '../../shared/services/geo-location.service';
import { ResequenceService } from '../../shared/services/resequence.service';
import { RouteColorService } from '../../shared/services/route-color.service';
import { TripPlanningGridItem } from '../trip-planning/models/trip-planning-grid-item.model';
import { AbstractBoardLane } from './classes/board/abstract-board-lane.model';
import { BoardLaneCreator } from './classes/board/board-lane-creator.model';
import { ExistingRouteBoardLane } from './classes/board/existing-route-board-lane.model';
import { NewRouteBoardLane } from './classes/board/new-route-board-lane.model';
import { RouteBoardLane, TypesOfRoute } from './classes/board/route-board-lane.model';
import { RoutebalancingContainerDirective } from './classes/directives/route-balancing-container.directive';
import { StopCardMouseOver } from './classes/interfaces/stop-card-mouseover.interface';
import { RouteBalancingBuilderService } from './classes/services/route-balancing-builder.service';
import { RouteBalancingMessagingService } from './classes/services/route-balancing-messaging.service';
import { StopCard } from './classes/stop-card.model';
import {
  AutosequenceDialogComponent,
  AutosequenceDialogEnum,
} from './components/autosequence-dialog/autosequence-dialog.component';
import { RouteBalancingMetrics } from './route-balancing-metrics/route-balancing-metrics.interface';

export interface ResequencingRouteData {
  routeName: string;
  routePrefix: string;
  routeSuffix: string;
  routeStatusCd: RouteStatusCd;
  newResequencingStops: StopCard[];
  pinnedStops: {
    first: StopCard;
    last: StopCard;
  };
  source: StoreSourcesEnum;
}

enum TypesOfLanes {
  ROUTE_LANE = 'ROUTE_LANE',
  NEW_ROUTE_LANE = 'NEW_ROUTE_LANE',
  EXISTING_ROUTE_LANE = 'EXISTING_ROUTE_LANE',
  LANE_CREATOR = 'LANE_CREATOR',
}

@Component({
  selector: 'pnd-route-balancing',
  templateUrl: './route-balancing.component.html',
  styleUrls: ['./route-balancing.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteBalancingComponent implements AfterViewChecked, OnDestroy, OnInit {
  @ViewChild(RoutebalancingContainerDirective, { static: true }) instance: RoutebalancingContainerDirective;

  private isStartTimeSet: boolean = false;
  componentInstance: number = 1;
  height: number = 15;
  dragginStop: StopCard;
  listenersRemoved: boolean = false;
  loadedRoutesNames: {
    prefix: string;
    suffix: string;
    satelliteSic: string;
  }[] = [];

  isSaving: boolean;

  private unsubscriber = new Unsubscriber();
  private currentSic: string;
  private planDate: Date;
  private currentTerminalGeoCoordinates: LatLong;
  private usingSuggestion: boolean = false;
  private showSpinnerSubject = new BehaviorSubject<boolean>(true);
  readonly showSpinner$ = this.showSpinnerSubject.asObservable();

  balancingMetrics: RouteBalancingMetrics = { metrics: [], trips: [] };
  nonEvaluatedTrips: RouteBalancingTrip[] = [];
  suggestedBalancingMetrics: RouteBalancingMetrics = { metrics: [], trips: [] };

  originalLanes$: BehaviorSubject<AbstractBoardLane[]> = new BehaviorSubject<AbstractBoardLane[]>([]);
  lanes$: BehaviorSubject<AbstractBoardLane[]> = new BehaviorSubject<AbstractBoardLane[]>([new BoardLaneCreator()]);
  private autoSequencePerformed: boolean;

  constructor(
    private pndStore$: Store<PndStoreState.State>,
    private notificationMessageService: NotificationMessageService,
    private timeService: XpoLtlTimeService,
    private cityOperationsService: CityOperationsApiService,
    private resequenceService: ResequenceService,
    private routeBalancingMessagingService: RouteBalancingMessagingService,
    private changeRef: ChangeDetectorRef,
    private routeColorService: RouteColorService,
    private loggingService: LoggingApiService,
    private dialog: MatDialog,
    private geoLocationService: GeoLocationService,
    private routeBalancingBuilderService: RouteBalancingBuilderService,
    private router: Router,
    private tripsGridItemConverterService: TripsGridItemConverterService
  ) {
    this.loggingService.info('Inside constructor of route balancing component');

    // This creates the Lane's structure with it's StopCards
    this.pndStore$
      .select(RoutesStoreSelectors.stopsForSelectedRoutes)
      .pipe(
        withLatestFrom(this.pndStore$.select(RoutesStoreSelectors.selectedRoutes)),
        withLatestFrom(this.pndStore$.select(GlobalFilterStoreSelectors.selectGlobalFilterState)),
        withLatestFrom(this.pndStore$.select(TripsStoreSelectors.selectedTrips)),
        filter(([[[routeStops, selectedRoutes]]]) => {
          return (
            selectedRoutes.length === _size(routeStops) &&
            _isEqual(
              selectedRoutes.map((r) => r.routeInstId).sort(),
              _keys(routeStops)
                .map((r) => +r)
                .sort()
            )
          );
        }),
        take(1)
      )
      .subscribe(([[[routeStops, selectedRoutes], global], trips]) => {
        this.currentSic = global.sic;
        this.planDate = global.planDate;
        this.currentTerminalGeoCoordinates = global.sicLatLng;

        this.createLanes(routeStops, selectedRoutes, trips);
      });

    this.showSpinnerSubject.next(false);

    // Listen for geo-coordinate changes
    this.geoLocationService.addressUpdated$.pipe(skip(1)).subscribe((addressUpdated: AddressUpdated) => {
      const updatedLanes: AbstractBoardLane[] = this.applyGeoCoordinateChanges(this.lanes$.value, addressUpdated);

      if (updatedLanes.length > 0) {
        this.lanes$.next(updatedLanes);

        this.updateTrips$()
          .pipe(take(1))
          .subscribe();
      }
    });
  }

  ngOnInit(): void {
    this.subscribeToColorChange();
    this.subscribeToBrowserNavigation();
  }

  ngAfterViewChecked(): void {
    // TODO: Change this over css if possible
    if (this.instance.instanceNumber === this.componentInstance) {
      const containerElement = this.instance.element.nativeElement.parentElement.parentElement.parentElement;
      const newHeight =
        Number.parseInt(containerElement.style.height.slice(0, containerElement.style.height.length - 2), 10) || 500;
      if (this.height !== newHeight) {
        this.height = newHeight - 242;

        const wrapper = this.instance.element.nativeElement.getElementsByClassName('ng2-carouselamos-wrapper')[0];
        if (!this.listenersRemoved && wrapper) {
          this.listenersRemoved = true;

          wrapper.removeAllListeners();
        }

        ComponentChangeUtils.detectChanges(this.changeRef);
      }
    }

    const lanes = this.lanes$.value;
    if (!this.isStartTimeSet && _size(lanes) > 1) {
      const lane = lanes.find((ln) => ln instanceof RouteBoardLane && !(<RouteBoardLane>ln).isStartTimeValid);
      if (lane) {
        (<RouteBoardLane>lane).focusStartTime = true;
        this.isStartTimeSet = true;
      }
    }
  }

  ngOnDestroy(): void {
    this.routeBalancingMessagingService.resetMetrics();

    this.pndStore$.dispatch(
      new RouteBalancingActions.SetLanesDirty({
        lanesDirty: false,
      })
    );

    this.pndStore$.dispatch(new RouteBalancingActions.SetManualSequencingRoutes({ routes: [] }));
    this.pndStore$.dispatch(new RouteBalancingActions.SetPinFirst({ pinFirst: undefined }));
    this.pndStore$.dispatch(new RouteBalancingActions.SetPinLast({ pinLast: undefined }));

    this.pndStore$
      .select(RoutesStoreSelectors.selectedStopsForSelectedRoutes)
      .pipe(withLatestFrom(this.pndStore$.select(RoutesStoreSelectors.resequencedRouteData)), take(1))
      .subscribe(([selectedStopsForSelectedRoutes, resequencedRouteData]) => {
        const routeInstIds = Object.keys(resequencedRouteData || {}).map((key) => +key);

        const newSelectedStopsForSelectedRoutes = _filter(selectedStopsForSelectedRoutes, (stop) => {
          return !routeInstIds.includes(_get(stop, 'id.routeInstId'));
        });

        this.pndStore$.dispatch(
          new RoutesStoreActions.SetSelectedStopsForSelectedRoutesAction({
            selectedStopsForSelectedRoutes: newSelectedStopsForSelectedRoutes,
          })
        );

        this.pndStore$.dispatch(new RoutesStoreActions.SetResequencedRouteData({ resequenceData: {} }));
      });

    this.unsubscriber.complete();
  }

  private subscribeToColorChange(): void {
    this.routeColorService.colorChanged$
      .pipe(
        filter((change) => !!change),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((change) => {
        this.lanes$.value.forEach((lane) => {
          if (lane instanceof RouteBoardLane && lane.routeInstId === change.routeInstId) {
            lane.color = change.color;
          }
        });
      });
  }

  private subscribeToBrowserNavigation() {
    this.router.events
      .pipe(
        takeUntil(this.unsubscriber.done),
        filter((event: RouterEvent) => event instanceof NavigationStart)
      )
      .subscribe((event: NavigationStart) => {
        if (event.restoredState) {
          this.close();
          this.updateMofifiedTrips({ tripDetails: [] });
        }
      });
  }

  /**
   * Create all the lanes for the given selected Routes
   */
  private createLanes(routeStops: NumberToValueMap<Stop[]>, selectedRoutes: Route[], trips: TripPlanningGridItem[]) {
    const lanes: RouteBoardLane[] = this.routeBalancingBuilderService.buildLanes(
      routeStops,
      selectedRoutes,
      trips,
      this.currentSic,
      this.planDate
    );

    this.loadedRoutesNames = lanes.map((lane) => {
      return {
        prefix: lane.routePrefix,
        suffix: lane.routeSuffix,
        satelliteSic: lane.routeSatelliteSic,
      };
    });

    this.isStartTimeSet = false;

    const resequenceData = {};
    this.lanes$.next([...lanes, new BoardLaneCreator()]);
    _forEach(lanes, (lane) => {
      resequenceData[lane.routeInstId] = {
        newResequencingStops: lane.assignedStops,
        routeName: lane.routeName,
        routePrefix: lane.routePrefix,
        routeSuffix: lane.routeSuffix,
        routeStatusCd: lane.routeStatusCd,
        pinnedStops: {},
        source: StoreSourcesEnum.ROUTE_BALANCING_BOARD,
      };
    });

    this.pndStore$.dispatch(new RoutesStoreActions.SetResequencedRouteData({ resequenceData: resequenceData }));

    this.originalLanes$.next(_cloneDeep(this.lanes$.value));

    // TEMPORARY: PCT-5797 - disallow save for more than one route for same trip
    if (this.areLanesForSameTrip()) {
      this.displayLanesForSameTripError();
    }
  }

  /**
   * Applies geo-coordinate changes to related lanes
   */
  private applyGeoCoordinateChanges(lanes: AbstractBoardLane[], addressUpdated: AddressUpdated): AbstractBoardLane[] {
    const routesChanged: number[] = [];

    _forEach(lanes, (lane) => {
      if (lane instanceof RouteBoardLane) {
        for (const stop of lane.assignedStops) {
          if (addressUpdated.customerId === stop.customer.acctInstId) {
            const newCoordinates: LatLong = {
              latitude: addressUpdated.geoCoordinate.latitude,
              longitude: addressUpdated.geoCoordinate.longitude,
            };

            stop.canResequenceStop = true;
            stop.coordinatesUnknown = false;
            stop.consigneeGeoCoordinates = newCoordinates;
            stop.customer.geoCoordinates = newCoordinates;
            stop.customer.latitudeNbr = newCoordinates.latitude;
            stop.customer.longitudeNbr = newCoordinates.longitude;

            routesChanged.push(lane.routeInstId);
          }
        }
      }
    });

    if (_size(routesChanged) > 0) {
      for (const routeInstId of routesChanged) {
        for (const lane of lanes) {
          if (lane instanceof RouteBoardLane && lane.routeInstId === routeInstId) {
            this.onDispatchStops(lane.routeInstId, lane.assignedStops);
          }
        }
      }

      return lanes;
    }

    return [];
  }

  /**
   * Update trips structure and metrics (after: drag/drop, start time changes, autosequence, manualsequence).
   */
  private updateTrips$(): Observable<void> {
    return new Observable((observer) => {
      if (this.areLanesValid()) {
        this.showSpinnerSubject.next(true);
        const routeBalancingTrips: RouteBalancingTrip[] = [];
        this.nonEvaluatedTrips = [];

        this.resolveStopsWithMissingCoordinates$()
          .pipe(take(1))
          .subscribe(
            () => {
              this.timeService
                .timezoneForSicCd$(this.currentSic)
                .pipe(take(1))
                .subscribe(
                  (timezone) => {
                    let update = false;

                    _forEach(this.lanes$.value, (lane) => {
                      if (lane instanceof RouteBoardLane) {
                        _forEach(lane.assignedStops, (stopCard) => {
                          this.routeBalancingBuilderService.checkForInvalidGeoCoordinates(stopCard);
                        });

                        const routeBalancingTrip: RouteBalancingTrip = new RouteBalancingTrip();
                        const planDate = this.planDate.toISOString().substring(0, 10);
                        const date = moment.tz(`${planDate} ${lane.startTime}`, 'YYYY-MM-DD HH:mm', timezone);

                        routeBalancingTrip.estimatedStartDateTime = date.toDate().getTime();
                        routeBalancingTrip.estimatedEmptyDateTime = lane.estimatedEmptyDateTime;
                        routeBalancingTrip.tripDate = lane.tripStartDate || moment(this.planDate).format('MM/DD/YYYY');
                        routeBalancingTrip.tripStatusCd = lane.tripStatusCd || TripStatusCd.NEW_TRIP;

                        const routeBalancingRoute: RouteBalancingRoute = new RouteBalancingRoute();

                        routeBalancingRoute.routeBalancingTypeCd = lane.autoSequencePerformed
                          ? RouteBalancingTypeCd.AUTOSEQUENCE
                          : lane.dragAndDropPerformed || lane.isStopsTouched || lane.isStartTimeTouched
                          ? RouteBalancingTypeCd.MANUAL_SEQUENCE
                          : RouteBalancingTypeCd.NO_ACTION;

                        routeBalancingRoute.routePrefix = lane.routePrefix;
                        routeBalancingRoute.routeSuffix = lane.routeSuffix;
                        routeBalancingRoute.routeCategoryCd = lane.routeCategoryCd;

                        if (lane.pinnedStops && lane.pinnedStops.first) {
                          routeBalancingRoute.firstStopNodeSequenceNumber = _get(
                            lane.pinnedStops.first,
                            'tripNodeSequenceNbr'
                          );
                        }
                        if (lane.pinnedStops && lane.pinnedStops.last) {
                          routeBalancingRoute.lastStopNodeSequenceNumber = _get(
                            lane.pinnedStops.last,
                            'tripNodeSequenceNbr'
                          );
                        }

                        routeBalancingRoute.stops = lane.assignedStops.map((stopCard) => {
                          return this.routeBalancingBuilderService.buildRouteBalancingStop(
                            stopCard.tripNodeInstId,
                            stopCard.tripNodeSequenceNbr,
                            (stopCard.activities || []).map((activity) => {
                              return <RouteBalancingActivity>{
                                activityCd: activity.tripNodeActivity.activityCd,
                                totalWeight: activity.tripNodeActivity.totalWeightCount,
                                totalMotorMoves: activity.tripNodeActivity.totalMmCount,
                                specialServices: (activity.shipmentSpecialServices || []).map(
                                  (ss) => ss.specialService
                                ),
                                proNbr: activity.tripNodeActivity.proNbr,
                                shipmentInstId: activity.tripNodeActivity.shipmentInstId,
                              };
                            }),
                            {
                              tripInstId: stopCard.origTripInstId,
                              routeInstId: stopCard.origRouteInstId,
                              tripNodeSequenceNbr: stopCard.tripNodeSequenceNbr,
                            },
                            stopCard.nodeTypeCd,
                            stopCard.tripNodeTypeCd,
                            stopCard.consigneeGeoCoordinates,
                            stopCard.consigneeName,
                            stopCard.stopWindows,
                            stopCard.estimatedArriveDateTime,
                            stopCard.estimatedDepartDateTime,
                            stopCard.estimatedArriveDateTimeLocal,
                            stopCard.estimatedDepartDateTimeLocal,
                            stopCard.planDistanceFromPreviousNode,
                            stopCard.potentialMissedStopWindow,
                            stopCard.ignoreStopWindowInd
                          );
                        });

                        routeBalancingTrip.routes = [];

                        // Add origin and destination
                        if (lane.origin && lane.destination) {
                          routeBalancingRoute.stops.unshift(
                            this.routeBalancingBuilderService.buildRouteBalancingStop(
                              lane.origin.tripNode.nodeInstId,
                              lane.origin.tripNode.tripNodeSequenceNbr,
                              [],
                              {
                                tripInstId: lane.origin.tripNode.tripInstId,
                                routeInstId: lane.routeInstId,
                                tripNodeSequenceNbr: lane.origin.tripNode.tripNodeSequenceNbr,
                              },
                              lane.origin.tripNode.nodeTypeCd,
                              lane.origin.tripNode.tripNodeTypeCd,
                              {
                                latitude: _get(lane, 'origin.latitudeNbr', 0),
                                longitude: _get(lane, 'origin.longitudeNbr', 0),
                              },
                              _get(lane, 'origin.customer.name1', ''),
                              [],
                              lane.origin.tripNode.estimatedArriveDateTime,
                              lane.origin.tripNode.estimatedDepartDateTime,
                              lane.origin.tripNode.estimatedArriveDateTimeLocal,
                              lane.origin.tripNode.estimatedDepartDateTimeLocal,
                              lane.origin.tripNode.planDistanceFromPrevNode
                            )
                          );

                          routeBalancingRoute.stops.push(
                            this.routeBalancingBuilderService.buildRouteBalancingStop(
                              lane.destination.tripNode.nodeInstId,
                              lane.destination.tripNode.tripNodeSequenceNbr,
                              [],
                              {
                                tripInstId: lane.destination.tripNode.tripInstId,
                                routeInstId: lane.routeInstId,
                                tripNodeSequenceNbr: lane.destination.tripNode.tripNodeSequenceNbr,
                              },
                              lane.destination.tripNode.nodeTypeCd,
                              lane.destination.tripNode.tripNodeTypeCd,
                              {
                                latitude: _get(lane, 'origin.latitudeNbr', 0),
                                longitude: _get(lane, 'origin.longitudeNbr', 0),
                              },
                              _get(lane, 'destination.customer.name1', ''),
                              [],
                              lane.destination.tripNode.estimatedArriveDateTime,
                              lane.destination.tripNode.estimatedDepartDateTime,
                              lane.destination.tripNode.estimatedArriveDateTimeLocal,
                              lane.destination.tripNode.estimatedDepartDateTimeLocal,
                              lane.destination.tripNode.planDistanceFromPrevNode
                            )
                          );
                        }

                        if (lane.typeOfRoute === TypesOfRoute.EXISTING_ROUTE) {
                          // Existing routes
                          routeBalancingTrip.tripInstId = lane.tripInstId;
                          routeBalancingRoute.routeInstId = lane.routeInstId;

                          routeBalancingTrip.routes.push(routeBalancingRoute);
                          routeBalancingTrips.push(routeBalancingTrip);
                        } else if (lane.typeOfRoute === TypesOfRoute.NEW_ROUTE) {
                          // New routes
                          routeBalancingTrip.tripInstId = lane.tripInstId;
                          routeBalancingRoute.routeBalancingTypeCd = lane.autoSequencePerformed
                            ? RouteBalancingTypeCd.AUTOSEQUENCE
                            : RouteBalancingTypeCd.MANUAL_SEQUENCE;

                          routeBalancingTrip.routes.push(routeBalancingRoute);
                          routeBalancingTrips.push(routeBalancingTrip);
                        }

                        if (!_find(lane.assignedStops, (stopCard) => stopCard.coordinatesUnknown)) {
                          update = true;
                        } else {
                          this.nonEvaluatedTrips.push(routeBalancingTrips.pop());
                          this.loggingService.warn(`Route ${lane.routeName} has no geoCoordinates and no address`);
                        }
                      }
                    });

                    if (update) {
                      this.updateMetrics$(routeBalancingTrips)
                        .pipe(take(1))
                        .subscribe(() => {
                          this.showSpinnerSubject.next(false);
                          this.solveNonEvaluatedTrips();
                          observer.next();
                          observer.complete();
                        });
                    } else {
                      this.solveNonEvaluatedTrips();
                      this.showSpinnerSubject.next(false);
                      observer.next();
                      observer.complete();
                    }
                  },
                  (error) => {
                    this.showSpinnerSubject.next(false);

                    this.notificationMessageService
                      .openNotificationMessage(NotificationMessageStatus.Error, error)
                      .subscribe(() => {});

                    observer.error(error);
                  }
                );
            },
            (error) => {
              this.showSpinnerSubject.next(false);

              this.notificationMessageService
                .openNotificationMessage(NotificationMessageStatus.Error, error)
                .subscribe(() => {});

              observer.error(error);
            }
          );
      } else {
        this.showSpinnerSubject.next(false);
        observer.next();
        observer.complete();
      }
    });
  }

  private solveNonEvaluatedTrips() {
    _forEach(this.nonEvaluatedTrips, (nonEvaluatedTrip) => {
      const routeBalancingTrip: RouteBalancingTrip = _find(_get(this.balancingMetrics, 'trips', []), (trip) => {
        return trip.tripInstId === nonEvaluatedTrip.tripInstId;
      });

      if (!routeBalancingTrip) {
        this.balancingMetrics.trips.push(nonEvaluatedTrip);
      }
    });
  }

  private resolveStopsWithMissingCoordinates$(): Observable<void> {
    return new Observable((observer) => {
      const observers: Observable<GeocodeResult[]>[] = [];

      for (const lane of this.lanes$.value) {
        if (lane instanceof RouteBoardLane) {
          for (const stop of lane.assignedStops) {
            if (!stop.consigneeGeoCoordinates) {
              const requests: google.maps.GeocoderRequest[] = [
                {
                  address: `${stop.consigneeAddress} ${stop.consigneeCity} ${stop.consigneeStateCd} ${stop.consigneePostalCd}`,
                },
                {
                  address: `${stop.consigneeName} ${stop.consigneeCity} ${stop.consigneeStateCd} ${stop.consigneePostalCd}`,
                },
              ];

              observers.push(
                this.geoLocationService.geocodeAddresses(requests).pipe(
                  take(1),
                  catchError((error) => of([])),
                  tap((results: GeocodeResult[]) => {
                    if (!_isEmpty(results)) {
                      const result =
                        this.geoLocationService.firstOfLocationType(results, GeocoderLocationType.ROOFTOP) ||
                        this.geoLocationService.firstOfLocationType(results, GeocoderLocationType.RANGE_INTERPOLATED);
                      if (result) {
                        stop.canResequenceStop = true;
                        stop.coordinatesUnknown = false;
                        stop.consigneeGeoCoordinates = {
                          latitude: result.location.lat(),
                          longitude: result.location.lng(),
                        };
                      } else {
                        stop.coordinatesInvalid = true;
                      }
                    } else {
                      stop.coordinatesInvalid = true;
                    }
                  })
                )
              );
            }
          }
        }
      }

      if (observers.length === 0) {
        observer.next();
        observer.complete();
      } else {
        zip(...observers).subscribe(
          () => {
            observer.next();
            observer.complete();
          },
          (error) => {
            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, error)
              .subscribe(() => {});

            observer.error(error);
          }
        );
      }
    });
  }

  private updateMetrics$(routeBalancingTrips: RouteBalancingTrip[]): Observable<void> {
    return new Observable((observer) => {
      const request: EvaluatePnDRouteBalancingRqst = {
        terminalSicCode: this.currentSic,
        terminalGeoCoordinates: this.currentTerminalGeoCoordinates,
        routeBalancingTrips: routeBalancingTrips,
      };

      const continueMetrics = (balancingMetrics: RouteBalancingMetrics) => {
        const conflicts = this.findConflicts(balancingMetrics);
        if (conflicts.length > 0) {
          this.suggestedBalancingMetrics = balancingMetrics;
          this.solveConflicts(conflicts);
          observer.next();
          observer.complete();
        } else {
          const autoSequencedRoutes = [];
          routeBalancingTrips.forEach((trip) => {
            _get(trip, 'routes', []).forEach((route: RouteBalancingRoute) => {
              if (route.routeBalancingTypeCd === RouteBalancingTypeCd.AUTOSEQUENCE) {
                autoSequencedRoutes.push(`${route.routePrefix}-${route.routeSuffix}`);
              }
            });
          });
          if (!_isEmpty(autoSequencedRoutes)) {
            this.notificationMessageService
              .openNotificationMessage(
                NotificationMessageStatus.Success,
                `${_size(autoSequencedRoutes) > 1 ? 'Routes' : 'Route'} ${autoSequencedRoutes.join(
                  ','
                )} auto sequenced.`
              )
              .subscribe(() => {});
          }

          this.balancingMetrics = balancingMetrics;
          this.updateLanes$()
            .pipe(take(1))
            .subscribe(() => {
              this.pndStore$.dispatch(new RouteBalancingActions.SetPinFirst({ pinFirst: undefined }));
              this.pndStore$.dispatch(new RouteBalancingActions.SetPinLast({ pinLast: undefined }));

              const lanes = this.lanes$.value;

              lanes.forEach((lane) => {
                if (lane instanceof RouteBoardLane) {
                  this.onDispatchStops(lane.routeInstId, lane.assignedStops);
                }
              });

              observer.next();
              observer.complete();
            });
        }
      };

      this.routeBalancingMessagingService
        .getMetrics(request)
        .pipe(
          finalize(() => {
            ComponentChangeUtils.detectChanges(this.changeRef);
          })
        )
        .subscribe(
          (balancingMetrics: RouteBalancingMetrics) => {
            continueMetrics(balancingMetrics);
          },
          (error) => {
            // Continue for errors: 400 or 404 (PCT-5295)
            if (_get(error, 'code') === '400' || _get(error, 'code') === '404') {
              continueMetrics({
                metrics: [],
                trips: routeBalancingTrips,
              });
            } else {
              console.error(error);
              this.showSpinnerSubject.next(false);

              this.notificationMessageService
                .openNotificationMessage(NotificationMessageStatus.Error, _get(error, 'error.message'))
                .subscribe(() => {});

              this.pndStore$.dispatch(
                new RouteBalancingActions.SetLanesDirty({
                  lanesDirty: this.areLanesDirty(),
                })
              );

              observer.error(error);
            }
          }
        );
    });
  }

  private updateLanes$(): Observable<void> {
    return new Observable((observer) => {
      this.timeService
        .timezoneForSicCd$(this.currentSic)
        .pipe(take(1))
        .subscribe(
          (timezone) => {
            const lanes = this.lanes$.value;
            _get(this.balancingMetrics, 'trips', []).forEach((trip: RouteBalancingTrip) => {
              _get(trip, 'routes', []).forEach((route: RouteBalancingRoute) => {
                const lane = <RouteBoardLane>lanes.find((l) => {
                  return (
                    l instanceof RouteBoardLane &&
                    l.tripInstId === trip.tripInstId &&
                    ((l.typeOfRoute === TypesOfRoute.EXISTING_ROUTE && l.routeInstId === route.routeInstId) ||
                      l.typeOfRoute === TypesOfRoute.NEW_ROUTE)
                  );
                });

                if (!!lane) {
                  lane.routeInstId = route.routeInstId ? route.routeInstId : lane.routeInstId;
                  // Update startime when autosequence or when start time is < now (considering timezone)
                  lane.startTime$.next(
                    moment(trip.estimatedStartDateTime)
                      .tz(timezone)
                      .format('HH:mm')
                  );
                  lane.estimatedEmptyDateTime = trip.estimatedEmptyDateTime;
                  lane.dragAndDropPerformed = false;
                  lane.isStartTimeTouched = false;
                  lane.isStopsTouched = false;
                  lane.autoSequencePerformed = false;

                  const stops = route.stops.filter((stop) => stop.nodeTypeCd !== NodeTypeCd.SERVICE_CENTER);
                  let assignedStops = lane.assignedStops;
                  stops.forEach((stop, i) => {
                    const assignedStop = assignedStops.find(
                      (stp) =>
                        stp.tripNodeInstId === stop.tripNodeInstId &&
                        stp.tripNodeSequenceNbr === stop.tripNodeSequenceNumber &&
                        stp.origTripInstId === stop.fromRoute.tripInstId &&
                        stp.origRouteInstId === stop.fromRoute.routeInstId
                    );

                    assignedStop.seqNo = i + 1;
                    assignedStop.origSeqNo = i + 1;
                    assignedStop.estimatedArriveDateTime = stop.estimatedArriveDateTime;
                    assignedStop.estimatedDepartDateTime = stop.estimatedDepartDateTime;
                    assignedStop.estimatedArriveDateTimeLocal = stop.estimatedArriveDateTimeLocal;
                    assignedStop.estimatedDepartDateTimeLocal = stop.estimatedDepartDateTimeLocal;
                    assignedStop.planDistanceFromPreviousNode = stop.planDistanceFromPreviousNode;
                    assignedStop.potentialMissedStopWindow = stop.potentialMissedStopWindowsInd;
                    assignedStop.idleTimeInMinutes = stop.idleWaitTime;
                  });
                  // Origin drive times
                  const origin = route.stops[0];
                  _set(lane, 'origin.tripNode.nodeInstId', origin.tripNodeInstId);
                  _set(lane, 'origin.tripNode.tripNodeSequenceNbr', origin.tripNodeSequenceNumber);
                  _set(lane, 'origin.tripNode.tripInstId', trip.tripInstId);
                  _set(lane, 'origin.tripNode.nodeTypeCd', origin.nodeTypeCd);
                  _set(lane, 'origin.tripNode.tripNodeTypeCd', origin.tripNodeTypeCd);

                  _set(lane, 'origin.tripNode.estimatedArriveDateTime', origin.estimatedArriveDateTime);
                  _set(lane, 'origin.tripNode.estimatedDepartDateTime', origin.estimatedDepartDateTime);
                  _set(lane, 'origin.tripNode.estimatedArriveDateTimeLocal', origin.estimatedArriveDateTimeLocal);
                  _set(lane, 'origin.tripNode.estimatedDepartDateTimeLocal', origin.estimatedDepartDateTimeLocal);
                  _set(lane, 'origin.tripNode.planDistanceFromPrevNode', origin.planDistanceFromPreviousNode);

                  // Destination drive times
                  const destination = route.stops[route.stops.length - 1];
                  _set(lane, 'destination.tripNode.nodeInstId', destination.tripNodeInstId);
                  _set(lane, 'destination.tripNode.tripNodeSequenceNbr', destination.tripNodeSequenceNumber);
                  _set(lane, 'destination.tripNode.tripInstId', trip.tripInstId);
                  _set(lane, 'destination.tripNode.nodeTypeCd', destination.nodeTypeCd);
                  _set(lane, 'destination.tripNode.tripNodeTypeCd', destination.tripNodeTypeCd);

                  _set(lane, 'destination.tripNode.estimatedArriveDateTime', destination.estimatedArriveDateTime);
                  _set(lane, 'destination.tripNode.estimatedDepartDateTime', destination.estimatedDepartDateTime);
                  _set(
                    lane,
                    'destination.tripNode.estimatedArriveDateTimeLocal',
                    destination.estimatedArriveDateTimeLocal
                  );
                  _set(
                    lane,
                    'destination.tripNode.estimatedDepartDateTimeLocal',
                    destination.estimatedDepartDateTimeLocal
                  );
                  _set(lane, 'destination.tripNode.planDistanceFromPrevNode', destination.planDistanceFromPreviousNode);

                  assignedStops = lane.sortStops(assignedStops);
                  lane.assignedStopsSubject$.next([...assignedStops]);
                }
              });
            });

            this.lanes$.next(lanes);
            this.showSpinnerSubject.next(false);

            this.pndStore$.dispatch(
              new RouteBalancingActions.SetLanesDirty({
                lanesDirty: this.areLanesDirty(),
              })
            );

            observer.next();
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  private findConflicts(balancingMetrics: RouteBalancingMetrics): StopSequenceModelConflict[] {
    const conflicts: StopSequenceModelConflict[] = [];
    const trips = <RouteBalancingTrip[]>_get(balancingMetrics, 'trips', []);
    trips.forEach((trip) => {
      const routes = _get(trip, 'routes', []);
      routes.forEach((route: RouteBalancingRoute) => {
        conflicts.push(...(<StopSequenceModelConflict[]>_get(route, 'conflicts', [])));
      });
    });
    return conflicts;
  }

  private solveConflicts(conflicts: StopSequenceModelConflict[]): void {
    const message = conflicts.map((conflict) => conflict.description).join('<br>');
    const dialogRef = this.dialog.open(AutosequenceDialogComponent, {
      data: message,
      disableClose: false,
      hasBackdrop: true,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((actionPerformed) => {
        this.showSpinnerSubject.next(false);

        if (actionPerformed === AutosequenceDialogEnum.USE_SUGGESTION) {
          this.onUseSuggestion();
        } else {
          _forEach(this.lanes$.value, (lane) => {
            if (lane instanceof RouteBoardLane) {
              lane.autoSequencePerformed = false;
            }
          });
        }
      });
  }

  private closeLane(laneId: string) {
    const lanes = this.lanes$.value;
    const laneIndex = _findIndex(lanes, (l) => l.laneId === laneId);
    lanes.splice(laneIndex, 1);
    this.lanes$.next(lanes);
  }

  /**
   * Checks to see if the drag or drop event is a pinned stop. If it is, unpins it.
   */
  private unpinAssignedStops(
    event: CdkDragDrop<StopCard[]>,
    previousLane: RouteBoardLane,
    currentLane?: RouteBoardLane
  ) {
    if (currentLane) {
      // For previous lane
      if (_isEqual(event.previousIndex, 0) && _get(previousLane, 'pinnedStops.first')) {
        previousLane.pinnedStops.first = undefined;
      }
      if (_isEqual(event.previousIndex, _size(previousLane.assignedStops)) && _get(previousLane, 'pinnedStops.last')) {
        previousLane.pinnedStops.last = undefined;
      }

      // For current lane
      if (_isEqual(event.currentIndex, 0) && _get(currentLane, 'pinnedStops.first')) {
        currentLane.pinnedStops.first = undefined;
      }
      if (_isEqual(event.currentIndex, _size(currentLane.assignedStops) - 1) && _get(currentLane, 'pinnedStops.last')) {
        currentLane.pinnedStops.last = undefined;
      }
    } else {
      if (
        (_isEqual(event.previousIndex, 0) || _isEqual(event.currentIndex, 0)) &&
        _get(previousLane, 'pinnedStops.first')
      ) {
        // First stop is moved or replaced
        previousLane.pinnedStops.first = undefined;
      }
      if (
        (_isEqual(event.previousIndex, _size(previousLane.assignedStops) - 1) ||
          _isEqual(event.currentIndex, _size(previousLane.assignedStops) - 1)) &&
        previousLane.pinnedStops.last
      ) {
        // Last stop is moved or replaced
        previousLane.pinnedStops.last = undefined;
      }
    }
  }

  typeOfLane(lane: AbstractBoardLane): string {
    if (lane instanceof RouteBoardLane) {
      return TypesOfLanes.ROUTE_LANE;
    } else if (lane instanceof NewRouteBoardLane) {
      return TypesOfLanes.NEW_ROUTE_LANE;
    } else if (lane instanceof ExistingRouteBoardLane) {
      return TypesOfLanes.EXISTING_ROUTE_LANE;
    } else if (lane instanceof BoardLaneCreator) {
      return TypesOfLanes.LANE_CREATOR;
    }
  }

  // #region : Events

  /**
   * Called when the user drag/drop a stop between routes or in the same route.
   * @param event CdkDragDrop event
   */
  onDrop(event: CdkDragDrop<StopCard[]>) {
    const previousContainerLaneId = event.previousContainer.element.nativeElement.getAttribute('laneId');
    const currentContainerRouteLaneId = event.container.element.nativeElement.getAttribute('laneId');
    if (previousContainerLaneId === currentContainerRouteLaneId && event.previousIndex === event.currentIndex) {
      return;
    }

    const lanes = this.lanes$.value;
    const previousLane = <RouteBoardLane>_find(lanes, (l) => l.laneId === previousContainerLaneId);
    const currentLane = <RouteBoardLane>_find(lanes, (l) => l.laneId === currentContainerRouteLaneId);

    if (event.previousContainer === event.container) {
      let stopsFromCurrentContainer: StopCard[] = event.container.data;

      moveItemInArray(stopsFromCurrentContainer, event.previousIndex, event.currentIndex);
      previousLane.autoSequencePerformed = false;
      previousLane.dragAndDropPerformed = true;

      stopsFromCurrentContainer = stopsFromCurrentContainer.map((stop, index) => {
        stop.seqNo = index + 1;
        stop.origSeqNo = stop.seqNo;
        return stop;
      });
      stopsFromCurrentContainer = previousLane.sortStops(stopsFromCurrentContainer);
      if (previousLane.pinnedStops) {
        this.unpinAssignedStops(event, previousLane);
      }
      this.onDispatchStops(currentLane.routeInstId, stopsFromCurrentContainer);
      currentLane.isStopsTouched = true;
    } else {
      if (currentLane.canResequenceRoute && currentLane.isStartTimeValid) {
        let stopsFromPreviousContainer: StopCard[] = event.previousContainer.data;
        let stopsFromCurrentContainer: StopCard[] = event.container.data;

        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

        stopsFromPreviousContainer = stopsFromPreviousContainer.map((stop, index) => {
          stop.seqNo = index + 1;
          stop.origSeqNo = stop.seqNo;
          stop.routeInstId = previousLane.routeInstId;
          return stop;
        });

        previousLane.assignedStopsSubject$.next([...stopsFromPreviousContainer]);
        previousLane.autoSequencePerformed = false;
        previousLane.dragAndDropPerformed = true;
        previousLane.isStopsTouched = true;

        stopsFromCurrentContainer = stopsFromCurrentContainer.map((stop, index) => {
          stop.seqNo = index + 1;
          stop.origSeqNo = stop.seqNo;
          stop.routeInstId = currentLane.routeInstId;
          return stop;
        });

        currentLane.assignedStopsSubject$.next([...stopsFromCurrentContainer]);
        currentLane.autoSequencePerformed = false;
        currentLane.dragAndDropPerformed = true;
        currentLane.isStopsTouched = true;

        if (previousLane.pinnedStops || currentLane.pinnedStops) {
          this.unpinAssignedStops(event, previousLane, currentLane);
        }

        this.pndStore$
          .select(RoutesStoreSelectors.resequencedRouteData)
          .pipe(take(1))
          .subscribe((resequencedRouteData: { [routeInstId: number]: ResequencingRouteData }) => {
            const resequenceData = _cloneDeep(resequencedRouteData);

            resequenceData[previousLane.routeInstId] = {
              newResequencingStops: previousLane.assignedStops,
              routeName: previousLane.routeName,
              routePrefix: previousLane.routePrefix,
              routeSuffix: previousLane.routeSuffix,
              routeStatusCd: previousLane.routeStatusCd,
              pinnedStops: {
                first: _get(previousLane, 'pinnedStops.first'),
                last: _get(previousLane, 'pinnedStops.last'),
              },
              source: StoreSourcesEnum.ROUTE_BALANCING_BOARD,
            };

            resequenceData[currentLane.routeInstId] = {
              newResequencingStops: currentLane.assignedStops,
              routeName: currentLane.routeName,
              routePrefix: currentLane.routePrefix,
              routeSuffix: currentLane.routeSuffix,
              routeStatusCd: currentLane.routeStatusCd,
              pinnedStops: {
                first: _get(currentLane, 'pinnedStops.first'),
                last: _get(currentLane, 'pinnedStops.last'),
              },
              source: StoreSourcesEnum.ROUTE_BALANCING_BOARD,
            };

            this.pndStore$.dispatch(new RoutesStoreActions.SetResequencedRouteData({ resequenceData: resequenceData }));
          });
      }
    }

    if (currentLane.isStopsTouched) {
      this.lanes$.next(lanes);
      this.updateTrips$()
        .pipe(take(1))
        .subscribe();
    }
  }

  /**
   * Called when the user removes a lane.
   * @param laneId lane id
   */
  onRemove(laneId: string) {
    const lanes = this.lanes$.value;
    const laneIndex = _findIndex(this.lanes$.value, (l) => (<RouteBoardLane>l).laneId === laneId);

    this.pndStore$
      .select(RoutesStoreSelectors.resequencedRouteData)
      .pipe(take(1))
      .subscribe((resequencedRouteData: { [routeInstId: number]: ResequencingRouteData }) => {
        const lane = <RouteBoardLane>lanes[laneIndex];
        delete resequencedRouteData[lane.routeInstId];
        this.loadedRoutesNames.splice(
          this.loadedRoutesNames.findIndex(
            (route) =>
              route.prefix === lane.routePrefix &&
              route.suffix === lane.routeSuffix &&
              route.satelliteSic === lane.routeSatelliteSic
          ),
          1
        );

        this.pndStore$.dispatch(
          new RoutesStoreActions.SetResequencedRouteData({ resequenceData: { ...resequencedRouteData } })
        );
      });

    this.pndStore$
      .select(RouteBalancingSelectors.manualSequencingRoutes)
      .pipe(take(1))
      .subscribe((routeInstIds) => {
        const routeInstId = (<RouteBoardLane>lanes[laneIndex]).routeInstId;
        routeInstIds.splice(routeInstIds.indexOf(routeInstId), 1);

        if (routeInstIds.length === 0) {
          this.updateTrips$()
            .pipe(take(1))
            .subscribe(() => {
              this.pndStore$.dispatch(
                new RouteBalancingActions.SetLanesDirty({
                  lanesDirty: this.areLanesDirty(),
                })
              );
            });
        }

        lanes.splice(laneIndex, 1);
        this.lanes$.next(lanes);
      });
  }

  /**
   * Dispatch stops to the store after balancing. This ensure to reflect balancer changes into the map.
   * @param routeInstId route instance id
   * @param stops Array of StopCards
   */
  onDispatchStops(routeInstId: number, stops: StopCard[]) {
    const lane = <RouteBoardLane>_find(this.lanes$.value, (l) => (<RouteBoardLane>l).routeInstId === routeInstId);
    const pinnedStops = lane.pinnedStops;

    this.pndStore$
      .select(RoutesStoreSelectors.resequencedRouteData)
      .pipe(take(1))
      .subscribe((resequencedRouteData: { [routeInstId: number]: ResequencingRouteData }) => {
        const resequenceData = _cloneDeep(resequencedRouteData);
        resequenceData[routeInstId] = {
          newResequencingStops: stops,
          routeName: lane.routeName,
          routePrefix: lane.routePrefix,
          routeSuffix: lane.routeSuffix,
          routeStatusCd: lane.routeStatusCd,
          pinnedStops: {
            first: pinnedStops ? pinnedStops.first : undefined,
            last: pinnedStops ? pinnedStops.last : undefined,
          },
          source: StoreSourcesEnum.ROUTE_BALANCING_BOARD,
        };

        this.pndStore$.dispatch(new RoutesStoreActions.SetResequencedRouteData({ resequenceData: resequenceData }));
      });
  }

  /**
   * Unassign a stop from the assigned list
   * @param routeInstId
   * @param stop
   */
  onUnassign(routeInstId: number, stop: StopCard): void {
    const lane: RouteBoardLane = <RouteBoardLane>(
      _find(this.lanes$.value, (l) => (<RouteBoardLane>l).routeInstId === routeInstId)
    );

    let unassignedStops: StopCard[] = lane.unassignedStops;
    unassignedStops.push(stop);
    unassignedStops = lane.sortStops(unassignedStops);
    unassignedStops = unassignedStops.map((stp, i) => {
      stp.seqNo = i + 1;
      return stp;
    });

    lane.unassignedStopsSubject$.next(unassignedStops);

    const pinnedStops = lane.pinnedStops;
    if (
      pinnedStops.first &&
      pinnedStops.first.routeInstId === stop.routeInstId &&
      pinnedStops.first.origSeqNo === stop.origSeqNo
    ) {
      pinnedStops.first = undefined;
    }

    if (
      pinnedStops.last &&
      pinnedStops.last.routeInstId === stop.routeInstId &&
      pinnedStops.last.origSeqNo === stop.origSeqNo
    ) {
      pinnedStops.last = undefined;
    }

    lane.pinnedStopsSubject$.next(pinnedStops);

    let assignedStops: StopCard[] = lane.assignedStops;
    const index: number = assignedStops.findIndex(
      (stp) => stp.routeInstId === stop.routeInstId && stp.origSeqNo === stop.origSeqNo
    );
    assignedStops.splice(index, 1);
    assignedStops = lane.sortStops(assignedStops);
    assignedStops = assignedStops.map((stp, i) => {
      stp.seqNo = i + 1;
      return stp;
    });

    lane.assignedStopsSubject$.next(assignedStops);

    this.onDispatchStops(routeInstId, assignedStops);
    lane.isStopsTouched = true;

    this.updateTrips$()
      .pipe(take(1))
      .subscribe();
  }

  /**
   * Assign a stop from the assigned list
   * @param routeInstId
   * @param stop
   */
  onAssign(routeInstId: number, stop: StopCard): void {
    const lane = <RouteBoardLane>_find(this.lanes$.value, (l) => (<RouteBoardLane>l).routeInstId === routeInstId);

    let unassignedStops: StopCard[] = lane.unassignedStops;
    const index = unassignedStops.findIndex(
      (stp) => stp.routeInstId === stop.routeInstId && stp.origSeqNo === stop.origSeqNo
    );
    unassignedStops.splice(index, 1);
    unassignedStops = lane.sortStops(unassignedStops);
    unassignedStops = unassignedStops.map((stp, i) => {
      stp.seqNo = i + 1;
      return stp;
    });

    lane.unassignedStopsSubject$.next(unassignedStops);

    let assignedStops: StopCard[] = lane.assignedStops;
    assignedStops = lane.sortStops(assignedStops);
    assignedStops.push(stop);
    assignedStops = assignedStops.map((stp, i) => {
      stp.seqNo = i + 1;
      return stp;
    });

    lane.assignedStopsSubject$.next(assignedStops);

    this.onDispatchStops(routeInstId, assignedStops);
    lane.isStopsTouched = true;

    this.updateTrips$()
      .pipe(take(1))
      .subscribe();
  }

  /**
   * Inserts a lane for creating new routes giving Prefix and Suffix.
   */
  onNewRoute(): void {
    const lanes = this.lanes$.value;
    lanes.splice(lanes.length - 1, 0, new NewRouteBoardLane());
    this.lanes$.next(lanes);
  }

  /**
   * Inserts a new lane with the given Prefix and Suffix and the basic information.
   * @param laneId lane id
   * @param event Object with the Prefix and Suffix
   */
  applyNewRoute(laneId: string, event: { prefix: string; suffix: string; satelliteSic: string }): void {
    this.showSpinnerSubject.next(true);

    this.pndStore$
      .select(GlobalFilterStoreSelectors.selectGlobalFilterState)
      .pipe(take(1))
      .subscribe(
        (state) => {
          const lanes = this.lanes$.value;
          const laneIndex = _findIndex(lanes, (l) => l.laneId === laneId);

          const tempRouteInstId = new Date().getTime();
          const tempTripInstId = new Date().getTime() * -1;

          const lane = new RouteBoardLane(tempRouteInstId, tempTripInstId);
          lane.routePrefix = _get(event, 'prefix', '').toUpperCase();
          lane.routeSuffix = _get(event, 'suffix', '').toUpperCase();
          lane.routeSatelliteSic = _get(event, 'satelliteSic', '').toUpperCase();
          lane.routeCategoryCd = RouteCategoryCd.DELIVERY;
          lane.estimatedEmptyDateTime = new Date();
          lane.routeStatusCd = RouteStatusCd.NEW;
          lane.canResequenceRoute = true;
          lane.color = this.routeColorService.setRouteColor(lane.routeInstId);
          lane.typeOfRoute = TypesOfRoute.NEW_ROUTE;
          lane.focusStartTime = true;

          lanes.splice(laneIndex, 1);
          lanes.splice(lanes.length - 1, 0, lane);
          this.onDispatchStops(lane.routeInstId, lane.assignedStops);
          this.lanes$.next(lanes);
          this.updateTrips$()
            .pipe(take(1))
            .subscribe(() => {
              this.loadedRoutesNames.push({
                prefix: lane.routePrefix,
                suffix: lane.routeSuffix,
                satelliteSic: lane.routeSatelliteSic,
              });
              this.showSpinnerSubject.next(false);
            });
        },
        (error) => {
          console.error(error);
          this.showSpinnerSubject.next(false);
        }
      );
  }

  cancelNewRoute(laneId: string): void {
    this.closeLane(laneId);
  }

  /**
   * Inserts a lane for creating new routes from existing names.
   */
  onExistingRoute(): void {
    const lanes = this.lanes$.value;
    lanes.splice(lanes.length - 1, 0, new ExistingRouteBoardLane());
    this.lanes$.next(lanes);
  }

  /**
   * Inserts a new lane with the given route name and the basic information.
   * @param laneId lane id
   * @param route existing
   */
  applyExistingRoute(laneId: string, route: Route): void {
    const lanes = this.lanes$.value;
    const laneIndex = _findIndex(lanes, (l) => l.laneId === laneId);

    this.showSpinnerSubject.next(true);

    this.cityOperationsService
      .listPnDStops({ routeInstId: `${route.routeInstId}` })
      .pipe(
        take(1),
        catchError(() => of({ stops: [] })),
        map((response) => response.stops)
      )
      .subscribe(
        (stops) => {
          const routePath = { ...new GetPnDRoutePath(), routeInstId: `${route.routeInstId}` };
          const routeQuery = { ...new GetPnDRouteQuery(), sicCd: this.currentSic };

          this.cityOperationsService
            .getPnDRoute(routePath, routeQuery)
            .pipe(
              take(1),
              switchMap((response) => {
                // TODO: The suggested names API should return the tripInstId (right now is returning zero for every trip)
                const tripInstId = _get(response, 'route.tripInstId');
                route.tripInstId = tripInstId;

                const tripPath = {
                  ...new GetPnDTripPath(),
                  sicCd: this.currentSic,
                  tripInstId: tripInstId,
                };
                return this.cityOperationsService.getPnDTrip(tripPath).pipe(take(1));
              }),
              finalize(() => {
                this.showSpinnerSubject.next(false);
              })
            )
            .subscribe(
              (response) => {
                const currentTrip = _get(response, 'tripDetail.trip');
                const currentRoute: { route: Route } = _find(
                  _get(response, 'tripDetail.route', []),
                  (rt: { route: Route }) => rt.route.routeInstId === route.routeInstId
                );
                _set(currentRoute, 'route.tripInstId', route.tripInstId);
                const lane = this.routeBalancingBuilderService.buildLane(
                  _get(currentRoute, 'route', route),
                  stops,
                  _get(response, 'tripDetail.tripDriver.dsrName'),
                  _get(currentTrip, 'statusCd'),
                  this.currentSic,
                  this.planDate
                );
                // Force api evaluate call
                lane.isStopsTouched = true;

                lanes.splice(laneIndex, 1);
                lanes.splice(lanes.length - 1, 0, lane);
                this.onDispatchStops(lane.routeInstId, lane.assignedStops);
                this.lanes$.next(lanes);
                this.updateTrips$()
                  .pipe(take(1))
                  .subscribe(() => {
                    this.loadedRoutesNames.push({
                      prefix: lane.routePrefix,
                      suffix: lane.routeSuffix,
                      satelliteSic: lane.routeSatelliteSic,
                    });
                  });

                // TEMPORARY: PCT-5797 - disallow save for more than one route for same trip
                if (this.areLanesForSameTrip()) {
                  this.displayLanesForSameTripError();
                }
              },
              () => {
                this.showSpinnerSubject.next(false);
              }
            );
        },
        () => {
          this.showSpinnerSubject.next(false);
        }
      );
  }

  cancelExistingRoute(laneId: string): void {
    this.closeLane(laneId);
  }

  onStartTimeChanged(): void {
    if (!this.usingSuggestion) {
      this.updateTrips$()
        .pipe(take(1))
        .subscribe();
    }
  }

  onAutoSequence(): void {
    this.autoSequencePerformed = true;
    this.updateTrips$()
      .pipe(take(1))
      .subscribe();
  }

  onManualSequence(): void {
    this.updateTrips$()
      .pipe(take(1))
      .subscribe();
  }

  onShowSpinner(value: boolean): void {
    this.showSpinnerSubject.next(value);
  }

  onUseSuggestion(): void {
    this.balancingMetrics = this.suggestedBalancingMetrics;

    // Remove conflicts
    _get(this.balancingMetrics, 'trips', []).forEach((trip: RouteBalancingTrip) => {
      const routes = _get(trip, 'routes', []);
      routes.forEach((route) => {
        route.conflicts = [];
      });
    });

    this.usingSuggestion = true;
    this.updateLanes$()
      .pipe(take(1))
      .subscribe(() => {
        this.pndStore$.dispatch(new RouteBalancingActions.SetPinFirst({ pinFirst: undefined }));
        this.pndStore$.dispatch(new RouteBalancingActions.SetPinLast({ pinLast: undefined }));

        const lanes = this.lanes$.value;

        lanes.forEach((lane) => {
          if (lane instanceof RouteBoardLane) {
            lane.pinnedStopsSubject$.next(null);
            this.onDispatchStops(lane.routeInstId, lane.assignedStops);
          }
        });
        this.usingSuggestion = false;
      });
  }

  onMouseOver(event: StopCardMouseOver): void {
    if (!this.dragginStop) {
      const correspondingRouteCardContent: HTMLElement = event.currentCardContent;
      const assignedStopsTab = correspondingRouteCardContent.querySelector('[tabType="Assigned"]');
      if (assignedStopsTab) {
        if (event.stop) {
          let correspondingStopCard: HTMLElement;

          if (event.stop.seqNo) {
            correspondingStopCard = correspondingRouteCardContent.querySelector(`[seqno="${event.stop.seqNo}"]`);
          } else if (event.stop.origSeqNo) {
            correspondingStopCard = correspondingRouteCardContent.querySelector(
              `[origseqno="${event.stop.origSeqNo}"]`
            );
          }

          if (correspondingStopCard) {
            if (!event.fromStore) {
              this.pndStore$.dispatch(
                new RoutesStoreActions.SetFocusedStopForSelectedRouteAction({
                  focusedStopForSelectedRoute: {
                    id: event.mouseOver
                      ? {
                          routeInstId: event.stop.routeInstId,
                          seqNo: event.stop.seqNo,
                          origSeqNo: event.stop.origSeqNo,
                        }
                      : undefined,
                    source: StoreSourcesEnum.ROUTE_BALANCING_BOARD,
                  },
                })
              );
            }

            if (event.mouseOver) {
              correspondingStopCard['style'].borderLeft = `5px solid ${event.color || 'transparent'}`;
            } else {
              correspondingStopCard['style'].borderLeft = `5px solid transparent`;
            }

            if (event.scrollIntoView) {
              correspondingStopCard.scrollIntoView();
            }
          }
        } else {
          const correspondingStopCards = assignedStopsTab.querySelectorAll(`[origseqno]`);
          correspondingStopCards.forEach((correspondingStopCard) => {
            correspondingStopCard['style'].borderLeft = `5px solid transparent`;
          });
        }
      }
    }
  }

  onDragStarted(stop: StopCard): void {
    this.dragginStop = stop;

    this.pndStore$.dispatch(
      new RoutesStoreActions.SetFocusedStopForSelectedRouteAction({
        focusedStopForSelectedRoute: {
          id: {
            routeInstId: this.dragginStop.routeInstId,
            seqNo: this.dragginStop.seqNo,
            origSeqNo: this.dragginStop.origSeqNo,
          },
          source: StoreSourcesEnum.ROUTE_BALANCING_BOARD,
        },
      })
    );
  }

  onDragReleased(stop: StopCard): void {
    this.dragginStop = undefined;

    this.pndStore$.dispatch(
      new RoutesStoreActions.SetFocusedStopForSelectedRouteAction({
        focusedStopForSelectedRoute: {
          id: undefined,
          source: StoreSourcesEnum.ROUTE_BALANCING_BOARD,
        },
      })
    );

    const correspondingStopCards = this.instance.element.nativeElement.querySelectorAll(`[origseqno]`);
    correspondingStopCards.forEach((correspondingStopCard) => {
      correspondingStopCard['style'].borderLeft = `5px solid transparent`;
    });
  }

  // #endregion

  // #region : Action buttons

  /**
   * Returns the number of remaining stops to sequence
   * @param lanes
   */
  private getRemainingStopsToSequence(lanes: AbstractBoardLane[]): number {
    let remainingStopsToSequence: number = 0;

    for (const lane of lanes.filter((laneToFilter) => laneToFilter instanceof RouteBoardLane)) {
      remainingStopsToSequence += (<RouteBoardLane>lane).assignedStops.filter((stopCard) => !!!stopCard.seqNo).length;
    }

    return remainingStopsToSequence;
  }

  private save$(close: boolean = false): Observable<void> {
    return new Observable((observer) => {
      const performSave = () => {
        this.showSpinnerSubject.next(true);

        const request: BalancePnDRoutesRqst = new BalancePnDRoutesRqst();
        request.existingRoutes = [];
        request.newRoutes = [];
        request.unassignedStops = [];

        const pathParams: BalancePnDRoutesPath = new BalancePnDRoutesPath();
        pathParams.sicCd = this.currentSic;

        _forEach(this.lanes$.value, (lane) => {
          if (lane instanceof RouteBoardLane) {
            if (lane.unassignedStops.length > 0) {
              const unassignedBalancingTrips: RouteBalancingTrip = new RouteBalancingTrip();

              unassignedBalancingTrips.tripInstId = lane.tripInstId;
              unassignedBalancingTrips.routes = [
                {
                  ...new RouteBalancingRoute(),
                  routeBalancingTypeCd: RouteBalancingTypeCd.NO_ACTION,
                  stops: lane.unassignedStops.map((stopCard) => {
                    return {
                      ...new RouteBalancingStop(),
                      tripNodeInstId: stopCard.tripNodeInstId,
                      tripNodeSequenceNumber: stopCard.tripNodeSequenceNbr,
                      nodeTypeCd: stopCard.nodeTypeCd,
                      tripNodeTypeCd: stopCard.tripNodeTypeCd,
                      geoCoordinate: stopCard.consigneeGeoCoordinates,
                      fromRoute: {
                        tripInstId: stopCard.origTripInstId,
                        routeInstId: stopCard.origRouteInstId,
                        tripNodeSequenceNbr: stopCard.tripNodeSequenceNbr,
                      },
                    };
                  }),
                },
              ];

              request.unassignedStops.push(unassignedBalancingTrips);
            }

            if (lane.typeOfRoute === TypesOfRoute.EXISTING_ROUTE) {
              const routeBalancingTrip: RouteBalancingTrip = _find(
                _get(this.balancingMetrics, 'trips', []),
                (trip: RouteBalancingTrip) => {
                  return (
                    trip.tripInstId === lane.tripInstId &&
                    _result(trip, 'routes[0].routeInstId', 0) === lane.routeInstId
                  );
                }
              );

              if (routeBalancingTrip) {
                request.existingRoutes.push(routeBalancingTrip);
              }
            } else if (lane.typeOfRoute === TypesOfRoute.NEW_ROUTE) {
              const routeBalancingTrip: RouteBalancingTrip = _find(_get(this.balancingMetrics, 'trips', []), (trip) => {
                return (
                  _get(trip.routes[0], 'routePrefix') === lane.routePrefix &&
                  _get(trip.routes[0], 'routeSuffix') === lane.routeSuffix
                );
              });

              if (routeBalancingTrip) {
                request.newRoutes.push(routeBalancingTrip);
              }
            }
          }
        });

        this.cityOperationsService.balancePnDRoutes(request, pathParams).subscribe(
          (response: BalancePnDRoutesResp) => {
            this.pndStore$.dispatch(new RouteBalancingActions.SetManualSequencingRoutes({ routes: [] }));
            this.pndStore$.dispatch(new RouteBalancingActions.SetPinFirst({ pinFirst: undefined }));
            this.pndStore$.dispatch(new RouteBalancingActions.SetPinLast({ pinLast: undefined }));
            this.pndStore$.dispatch(new RoutesStoreActions.SetResequencedRouteData({ resequenceData: {} }));
            this.loadedRoutesNames = [];

            if (!close) {
              const trips: TripPlanningGridItem[] = this.tripsGridItemConverterService.getTripsGridItems(
                _get(response, 'tripDetails', [])
              );
              const selectedRoutes: Route[] = trips.map((trip) => trip.route);

              const fetchStopsForRoute = (routeInstId: number) => {
                return this.cityOperationsService.listPnDStops({ routeInstId: `${routeInstId}` }).pipe(
                  take(1),
                  map((resp) => {
                    return { routeInstId, stops: resp.stops };
                  })
                );
              };

              forkJoin(selectedRoutes.map((route) => fetchStopsForRoute(route.routeInstId)))
                .pipe(
                  catchError((error) => {
                    this.loggingService.error(`Error fetching stops: ${error}`);
                    return of({});
                  }),
                  map((results: { routeInstId: number; stops: Stop[] }[]) => {
                    const stopsForRoutes: NumberToValueMap<Stop[]> = {};
                    _forEach(results, (value) => {
                      _set(stopsForRoutes, value.routeInstId, value.stops);
                    });
                    return stopsForRoutes;
                  })
                )
                .subscribe((routeStops: NumberToValueMap<Stop[]>) => {
                  this.routeBalancingMessagingService.resetMetrics();

                  this.createLanes(routeStops, selectedRoutes, trips);

                  this.notificationMessageService
                    .openNotificationMessage(NotificationMessageStatus.Success, 'Changes saved')
                    .subscribe(() => {});

                  observer.next();
                  observer.complete();
                });
            } else {
              this.updateMofifiedTrips(response);
              observer.next();
              observer.complete();
            }
          },
          (error) => {
            const message = _get(error, 'error.message', 'An error has happened. Contact support.');
            error.message = message;

            this.notificationMessageService
              .openNotificationMessage(NotificationMessageStatus.Error, error)
              .subscribe(() => {});

            observer.error(message);
            this.showSpinnerSubject.next(false);
          }
        );
      };

      const remainingStopsToSequence: number = this.getRemainingStopsToSequence(this.lanes$.value);

      if (remainingStopsToSequence === 0) {
        performSave();
      } else {
        this.isSaving = false;
        const error = new GenericErrorLazyTypedModel({
          error: {
            message: `You need to manually sequence all the stops (${remainingStopsToSequence} remaining).`,
          },
        });

        this.notificationMessageService
          .openNotificationMessage(NotificationMessageStatus.Error, error)
          .subscribe(() => {});
      }
    });
  }

  private updateMofifiedTrips(modifieds: BalancePnDRoutesResp) {
    this.pndStore$
      .select(TripsStoreSelectors.trips)
      .pipe(
        take(1),
        withLatestFrom(this.pndStore$.select(TripsStoreSelectors.selectedTrips)),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(([trips, selecteds]) => {
        const modifiedTrips = trips.map((tripDetail: TripDetail) => {
          const replace = _find(
            modifieds.tripDetails,
            (changedTrip: TripDetail) => tripDetail.trip.tripInstId === changedTrip.trip.tripInstId
          );
          return replace ? replace : tripDetail;
        });

        this.dispatchModifiedTrips(modifiedTrips, selecteds);
      });
  }

  private dispatchModifiedTrips(modifiedTrips: TripDetail[], selecteds: TripPlanningGridItem[]) {
    this.pndStore$.dispatch(new TripsStoreActions.SetTrips({ trips: modifiedTrips }));
    this.pndStore$.dispatch(new TripsStoreActions.SetSelectedTrips({ selectedTrips: selecteds }));
  }

  saveChanges(): void {
    // TEMPORARY: PCT-5797 - disallow save for more than one route for same trip
    if (this.areLanesForSameTrip()) {
      this.displayLanesForSameTripError();
      return;
    }

    this.isSaving = true;

    this.save$()
      .pipe(
        finalize(() => {
          this.isSaving = false;
          this.autoSequencePerformed = false;
          this.showSpinnerSubject.next(false);
        })
      )
      .subscribe(() => {});
  }

  saveAndClose(): void {
    // TEMPORARY: PCT-5797 - disallow save for more than one route for same trip
    if (this.areLanesForSameTrip()) {
      this.displayLanesForSameTripError();
      return;
    }

    this.loggingService.info('Saving and closing route balancer');

    this.isSaving = true;

    this.save$(true)
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe(() => {
        this.close();
      });
  }

  closeWithoutSaving(): void {
    this.loggingService.info('Closing without save route balancer');

    if (this.areLanesDirty()) {
      const dialogRef = this.dialog.open(SaveChangesModalComponent, {
        disableClose: false,
        hasBackdrop: true,
      });
      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe((actionPerformed) => {
          if (actionPerformed === SaveChangesModalEnum.DO_NOT_SAVE) {
            this.close();
            // Update Trips grid
            this.updateMofifiedTrips({ tripDetails: [] });
          }
        });
    } else {
      this.close();
    }
  }

  private close() {
    this.pndStore$.dispatch(
      new RouteBalancingActions.SetOpenRouteBalancingPanel({
        openRouteBalancingPanel: false,
      })
    );
  }

  resetToDefault(): void {
    this.pndStore$.dispatch(new RouteBalancingActions.SetManualSequencingRoutes({ routes: [] }));
    this.pndStore$.dispatch(new RouteBalancingActions.SetPinFirst({ pinFirst: undefined }));
    this.pndStore$.dispatch(new RouteBalancingActions.SetPinLast({ pinLast: undefined }));
    this.pndStore$.dispatch(new RoutesStoreActions.SetResequencedRouteData({ resequenceData: {} }));

    this.loadedRoutesNames = [];
    const lanes = _cloneDeep(this.originalLanes$.value);
    this.lanes$.next(lanes);

    lanes.forEach((lane) => {
      if (lane instanceof RouteBoardLane) {
        this.loadedRoutesNames.push({
          prefix: lane.routePrefix,
          suffix: lane.routeSuffix,
          satelliteSic: lane.routeSatelliteSic,
        });
        this.onDispatchStops(lane.routeInstId, lane.assignedStops);
      }
    });

    this.routeBalancingMessagingService.resetMetrics();

    this.updateTrips$()
      .pipe(take(1))
      .subscribe(() => {
        this.changeRef.markForCheck();
      });
  }

  // #endregion

  // #region : Validations
  areLanesValid(): boolean {
    let isValid = true;

    for (let i = 0; i < this.lanes$.value.length; i++) {
      const lane = this.lanes$.value[i];
      if (lane instanceof RouteBoardLane && !(<RouteBoardLane>lane).isStartTimeValid) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  // TEMPORARY: PCT-5797 - disallow save for more than one route for same trip
  areLanesForSameTrip(): boolean {
    let isValid = true;
    const distinctTrips: number[] = [];

    for (let i = 0; i < this.lanes$.value.length; i++) {
      const lane = this.lanes$.value[i];
      if (lane instanceof RouteBoardLane) {
        const routeLane = <RouteBoardLane>lane;
        if (distinctTrips.includes(routeLane.tripInstId)) {
          isValid = false;
          break;
        }
        distinctTrips.push(routeLane.tripInstId);
      }
    }

    return !isValid;
  }

  displayLanesForSameTripError(): void {
    this.notificationMessageService
      .openNotificationMessage(
        NotificationMessageStatus.Error,
        'Route Balancing is not supported for multiple routes of the same trip. Select one route at a time to sequence. A fix is in progress!'
      )
      .subscribe(() => {});

    this.loggingService.error('Cannot save multiple routes on same trip');
  }

  areLanesDirty(): boolean {
    for (let i = 0; i < this.lanes$.value.length; i++) {
      const current = this.lanes$.value[i];
      const original = this.originalLanes$.value.find((lane) => lane.laneId === current.laneId);

      if (!original || this.autoSequencePerformed) {
        return true;
      } else if (original instanceof RouteBoardLane) {
        if (original.startTime !== (<RouteBoardLane>current).startTime) {
          return true;
        } else if (
          _get(<RouteBoardLane>original, 'assignedStops', []).length !==
          _get(<RouteBoardLane>current, 'assignedStops', []).length
        ) {
          return true;
        } else {
          for (let j = 0; j < _get(<RouteBoardLane>original, 'assignedStops', []).length; j++) {
            const originalStop = (<RouteBoardLane>original).assignedStops[j];
            const currentStop = (<RouteBoardLane>current).assignedStops[j];

            if (originalStop.tripNodeInstId !== currentStop.tripNodeInstId) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  areMetricsEvaluated(): boolean {
    return _get(this.balancingMetrics, 'trips.length', 0) !== 0;
  }

  // #endregion
}
