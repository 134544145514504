import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatSlideToggleModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import { GoldenLayoutModule } from '@embedded-enterprises/ng6-golden-layout';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoResponsiveLoadingSpinnerModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
import {
  XpoLtlDialogTitleModule,
  XpoLtlEmployeeDetailsCacheService,
  XpoLtlEnvironmentAndRoleGuardModule,
} from '@xpo/ngx-ltl';
import { AgGridModule } from 'ag-grid-angular';
import dispatcherLayout from '../../assets/layouts/dispatcher.json';
import inboundPlannerLayout from '../../assets/layouts/inbound-planner.json';
import { LayoutConfig } from '../../shared/layout-preference/layout-config.interface';
import { LayoutPreferenceService } from '../../shared/layout-preference/services/layout-preference.service';
import { LayoutComponentName } from '../layout-component-name.enum';

import { PlanningMapSplitterComponent } from './components/planning-map-splitter/planning-map-splitter.component';
// import { PlanningMapComponent } from './components/planning-map/planning-map.component';
import { PlanningMapModule } from './components/planning-map/planning-map.module';
import { PlanningRouteShipmentsComponent } from './components/planning-route-shipments/planning-route-shipments.component';
import { PlanningRouteShipmentsModule } from './components/planning-route-shipments/planning-route-shipments.module';
import { RouteBalancingComponent } from './components/route-balancing/index';
import { RouteBalancingModule } from './components/route-balancing/route-balancing.module';
import { RoutePlanningComponent } from './components/route-planning/route-planning.component';
import { RoutePlanningModule } from './components/route-planning/route-planning.module';
import { RouteStopsComponent } from './components/route-stops';
import { RouteStopsModule } from './components/route-stops/route-stops.module';
import { TripPlanningComponent } from './components/trip-planning/trip-planning.component';
import { InboundPlanningTripPlanningModule } from './components/trip-planning/trip-planning.module';
import { UnassignedDeliveriesComponent } from './components/unassigned-deliveries/unassigned-deliveries.component';
import { UnassignedDeliveriesModule } from './components/unassigned-deliveries/unassigned-deliveries.module';
import { UnassignedPickupsComponent } from './components/unassigned-pickups/unassigned-pickups.component';
import { UnassignedPickupsModule } from './components/unassigned-pickups/unassigned-pickups.module';
import { InboundPlanningComponent } from './inbound-planning.component';
import { TimeFormatValidator } from './shared/directives/index';
import { InboundPlanningSharedModule, pndFrameworkComponentsDeclarations } from './shared/index';

// define the components usable by GoldenLayout.  componentName is internal to that.
const layoutComponents = [
  {
    component: PlanningMapSplitterComponent,
    componentName: LayoutComponentName.PLANNING_MAP,
  },
  {
    component: UnassignedDeliveriesComponent,
    componentName: LayoutComponentName.UNASSIGNED_STOPS,
  },
  {
    component: UnassignedPickupsComponent,
    componentName: LayoutComponentName.UNASSIGNED_PICKUPS,
  },
  {
    component: RoutePlanningComponent,
    componentName: LayoutComponentName.ROUTE_PLANNING,
  },
  {
    component: TripPlanningComponent,
    componentName: LayoutComponentName.TRIP_PLANNING,
  },
  {
    component: RouteStopsComponent,
    componentName: LayoutComponentName.ROUTE_STOPS,
  },
  {
    component: RouteBalancingComponent,
    componentName: LayoutComponentName.ROUTE_BALANCING,
  },
  {
    component: PlanningRouteShipmentsComponent,
    componentName: LayoutComponentName.PLANNING_ROUTE_SHIPMENTS,
  },
];

@NgModule({
  declarations: [InboundPlanningComponent, TimeFormatValidator],
  exports: [InboundPlanningComponent, TimeFormatValidator],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatButtonModule,
    AgGridModule.withComponents(pndFrameworkComponentsDeclarations),
    XpoAgGridBoardModule,
    XpoResponsiveLoadingSpinnerModule,
    XpoAgGridModule,
    XpoLtlDialogTitleModule,
    XpoLtlEnvironmentAndRoleGuardModule,
    GoldenLayoutModule,
    PlanningMapModule,
    RoutePlanningModule,
    RouteStopsModule,
    PlanningRouteShipmentsModule,
    RouteBalancingModule,
    UnassignedDeliveriesModule,
    UnassignedPickupsModule,
    InboundPlanningTripPlanningModule,
    InboundPlanningSharedModule,
  ],
  providers: [XpoLtlEmployeeDetailsCacheService],
})
export class InboundPlanningModule {
  constructor(private layoutPreferenceService: LayoutPreferenceService) {
    // Initialize LayoutPreferences/GoldenLayout with components and default layouts
    const defaultLayouts = [(inboundPlannerLayout as any) as LayoutConfig, (dispatcherLayout as any) as LayoutConfig];
    this.layoutPreferenceService.initialize(layoutComponents, defaultLayouts);
  }
}
