import { OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { XpoBoardApi, XpoBoardDataSource, XpoBoardReadyEvent, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardState, XpoAgGridBoardViewConfig } from '@xpo-ltl/ngx-ltl-board-grid';
import { DeliveryShipmentSearchRecord, UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { GridApi, GridOptions, GridReadyEvent, MenuItemDef, RowNode, SelectionChangedEvent } from 'ag-grid-community';
import {
  bind as _bind,
  concat as _concat,
  filter as _filter,
  find as _find,
  get as _get,
  has as _has,
  isEmpty as _isEmpty,
  map as _map,
  pick as _pick,
  size as _size,
  xorWith as _xorWith,
} from 'lodash';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { GridRowTransposedComponent, PndDialogService } from '../../../../core';
import { BoardStatesEnum } from '../../../../shared/enums/board-states.enum';
import { PndStoreState, UnassignedDeliveriesStoreActions, UnassignedDeliveriesStoreSelectors } from '../../../store';
import { BoardStateSource } from '../../../store/board-state-source';
import { StopMapComponent, UnassignedDeliveriesService } from '../../shared';
import { RowHoverManager } from '../../shared/classes/row-hover-manager';
import { StoreSourcesEnum } from '../../shared/enums/store-sources.enum';
import {
  consigneeShipmentToId,
  consigneeToId,
  EventItem,
  UnassignedDeliveryIdentifier,
} from '../../shared/interfaces/event-item.interface';
import { UserPreferencesService } from '../../shared/services/user-preferences.service';
import { UnassignedDeliveriesGridFields } from './enums/unassigned-deliveries-grid-fields.enum';
import { UnassignedDeliveriesComponentName } from './unassigned-deliveries-component-name';
import { UnassignedDeliveriesBoard } from './unassigned-deliveries-header/unassigned-deliveries-header.component';
import { UnassignedDeliveriesViewDataStore } from './unassigned-deliveries-view-data-store.service';

enum ColSortOrder {
  asc = 'asc',
  desc = 'desc',
  none = 'none',
}

interface SortModel {
  colId: string;
  sort: ColSortOrder;
}

// TODO - Refactor to use the inbound-planning-grid-base
export abstract class UnassignedDeliveriesBase implements OnInit, OnDestroy {
  readonly UnassignedDeliveriesBoard = UnassignedDeliveriesBoard;

  protected unsubscriber = new Unsubscriber();
  protected currentlyOpenedStopMapComponentDialog: MatDialogRef<StopMapComponent>;

  private boardReadySubject = new BehaviorSubject<boolean>(false);
  readonly boardReady$ = this.boardReadySubject.asObservable();

  // common Grid data
  protected boardApi: XpoBoardApi;
  protected gridApi: GridApi;

  protected viewDataStore: UnassignedDeliveriesViewDataStore;
  protected gridOptions: GridOptions;

  groupSelectedValue$: Observable<boolean>;

  private rowHoverManager: RowHoverManager<UnassignedStop>;
  readonly stateChange$ = new ReplaySubject<XpoAgGridBoardState>(1);
  protected boardState$: Observable<XpoBoardState>;

  protected focusedItem: UnassignedDeliveryIdentifier; // currently focused stop/shipment
  protected focusedRows: RowNode[] = []; // rows effected by the focused item (needed to redraw)

  private setFocusedActionSubject = new Subject<UnassignedDeliveriesStoreActions.SetFocusedDelivery>();
  protected dispatchFocusedAction(action) {
    this.setFocusedActionSubject.next(action);
  }

  protected setGroupSelected(value: Observable<boolean>) {
    this.groupSelectedValue$ = value;
  }

  constructor(
    protected dataSource: XpoBoardDataSource,
    protected pndStore$: Store<PndStoreState.State>,
    protected dialog: MatDialog,
    protected pndDialogService: PndDialogService,
    protected userPreferencesService: UserPreferencesService,
    protected unassignedDeliveriesService: UnassignedDeliveriesService
  ) {
    this.userPreferencesService
      .getPreferencesFor<XpoAgGridBoardViewConfig[]>(UnassignedDeliveriesComponentName)
      .subscribe((preferences) => {
        this.viewDataStore = new UnassignedDeliveriesViewDataStore(
          this.userPreferencesService,
          preferences,
          this.pndStore$
        );
        this.boardReadySubject.next(true);
      });
  }

  ngOnInit() {
    // add common options
    this.gridOptions.getContextMenuItems = (params) => this.getContextMenuItems(params);

    // add hover class for all nodes that match focused stop
    this.gridOptions.rowClassRules = {
      'ag-row-hover': (params) => {
        if (_has(this.focusedItem, 'shipmentInstId')) {
          return consigneeShipmentToId(this.focusedItem) === consigneeShipmentToId(params.data);
        } else {
          return consigneeToId(this.focusedItem) === consigneeToId(params.data);
        }
      },
    };

    this.gridOptions.onSelectionChanged = (event: SelectionChangedEvent) => {
      const selected: RowNode[] = event.api.getSelectedNodes();
      const selectedInBoard = _map(selected, (item) => item.data as UnassignedStop);
      this.onSelectonChangedFromBoard(selectedInBoard);
    };

    this.groupSelectedValue$.pipe(takeUntil(this.unsubscriber.done)).subscribe((groupSelected) => {
      this.setColumnSort(
        UnassignedDeliveriesGridFields.ROW_SELECTED,
        groupSelected ? ColSortOrder.desc : ColSortOrder.none,
        true
      );
    });

    // create a debounced Subject to reduce spamming focus actions
    this.setFocusedActionSubject
      .asObservable()
      .pipe(debounceTime(250))
      .subscribe((action) => {
        this.pndStore$.dispatch(action);
      });

    // listen for grid hover changes
    this.rowHoverManager = new RowHoverManager(
      this.gridOptions,
      _bind(this.setFocusedRow, this),
      _bind(this.clearFocusedRow, this)
    );

    this.subscribeToBoardChanges();
    this.subscribeToStoreChanges();
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
    this.boardReadySubject.next(false);
    this.rowHoverManager.destroy();
  }

  onBoardReady($event: XpoBoardReadyEvent) {
    this.boardApi = $event.boardApi;
  }

  onGridReady(gridEvent: GridReadyEvent) {
    this.gridApi = gridEvent.api;
  }

  /**
   * Set the sort for a column. If primary is true, makes this the first item in the sort model
   */
  protected setColumnSort(colToSort: string, sortOrder: ColSortOrder, primary: boolean = true) {
    if (!this.gridApi) {
      return;
    }

    const currentSortModel: SortModel[] = this.gridApi.getSortModel() as SortModel[];

    if (sortOrder === ColSortOrder.none) {
      // remove this column from the sort model
      const newSortModel = _filter(currentSortModel, (model: SortModel) => model.colId !== colToSort);
      this.gridApi.setSortModel(newSortModel);
    } else {
      if (primary) {
        // make sure this sort is the first one in the sort model
        const newSortModel = _concat(
          {
            colId: colToSort,
            sort: sortOrder,
          },
          _filter(currentSortModel, (model: SortModel) => model.colId !== colToSort)
        );
        this.gridApi.setSortModel(newSortModel);
      } else {
        // update col sort to new sortOrder if it already exists, or add it as new to the sort model
        const item = _find(currentSortModel, (model: SortModel) => model.colId === colToSort);
        if (item) {
          item.sort = sortOrder;
        } else {
          currentSortModel.push({
            colId: colToSort,
            sort: sortOrder,
          });
        }
        this.gridApi.setSortModel(currentSortModel);
      }
    }
  }

  protected subscribeToStoreChanges() {
    // trigger a board refresh when the unassignedDeliveries change
    // NOTE: This should NOT trigger getting data from the API, since that is probably what triggered this in the first place
    this.unassignedDeliveriesService.unassignedDeliveries$.pipe(takeUntil(this.unsubscriber.done)).subscribe(() => {
      this.stateChange$.next({
        source: BoardStateSource.ReduxStore,
        pageNumber: 1,
        changes: ['source', 'pageNumber'],
      });
    });

    this.pndStore$
      .select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected)
      .pipe(distinctUntilChanged(), withLatestFrom(this.groupSelectedValue$), takeUntil(this.unsubscriber.done))
      .subscribe(([selectedItems, groupSelected]) => {
        this.onSelectionChangedFromStore(_map(selectedItems, (item) => item.id));

        if (groupSelected && this.gridApi) {
          // selection changed, so resort the grid
          const currentSortModel: SortModel[] = this.gridApi.getSortModel() as SortModel[];
          this.gridApi.setSortModel(currentSortModel);
          this.gridApi.ensureIndexVisible(0, 'top');
        }
      });

    this.pndStore$
      .select(UnassignedDeliveriesStoreSelectors.unassignedDeliveryFocused)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((item) => {
        // only change focus if it came from a somewere else
        if (item.source !== StoreSourcesEnum.UNASSIGNED_DELIVERY_GRID) {
          this.onFocusChangedFromStore(_get(item, 'id'));
        }
      });
  }

  protected subscribeToBoardChanges() {
    // We want to ignore ALL state changes that were triggered by updates from the Store
    this.boardState$ = this.dataSource
      .connect(this)
      .pipe(filter((state) => state.source !== BoardStateSource.ReduxStore));

    this.boardState$
      .pipe(
        takeUntil(this.unsubscriber.done),
        filter(
          (state: XpoAgGridBoardState) =>
            state.source === BoardStatesEnum.SAVE_VIEW || state.source === BoardStatesEnum.SAVE_VIEW_AS
        )
      )
      .subscribe(() => {
        this.dataSource.refresh();
      });
  }

  protected abstract onSelectonChangedFromBoard(selectedInBoard: UnassignedDeliveryIdentifier[]);
  protected abstract onSelectionChangedFromStore(selectedInStore: UnassignedDeliveryIdentifier[]);
  protected abstract onFocusChangedFromStore(focusedItem: UnassignedDeliveryIdentifier);

  protected getContextMenuItems(params): (string | MenuItemDef)[] {
    return [
      'copy',
      'copyWithHeaders',
      'paste',
      'separator',
      'export',
      'separator',
      {
        name: 'Transpose',
        action: () => {
          this.dialog.open(GridRowTransposedComponent, {
            data: { ...params, dialogTitle: `Unassigned Delivery - ${params.node.data.consignee.name1}` },
            disableClose: true,
            hasBackdrop: false,
          });
        },
      },
    ];
  }

  /**
   * Set the focused shipment
   */
  protected setFocusedRow(unassignedShipment: DeliveryShipmentSearchRecord) {
    const focusedDelivery: EventItem<UnassignedDeliveryIdentifier> = {
      id: undefined,
      source: StoreSourcesEnum.UNASSIGNED_DELIVERY_GRID,
    };

    let consigneeId: {
      acctInstId: number;
      name1: string;
      latitudeNbr: number;
      longitudeNbr: number;
    };
    if (_has(unassignedShipment, 'consignee')) {
      consigneeId = _pick(unassignedShipment.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']);
    }
    const shipmentInstId: number = _get(unassignedShipment, 'shipmentInstId');

    if (consigneeId || shipmentInstId) {
      focusedDelivery.id = {
        consignee: consigneeId,
        shipmentInstId,
      };
    }

    this.dispatchFocusedAction(new UnassignedDeliveriesStoreActions.SetFocusedDelivery({ focusedDelivery }));
  }

  /**
   * Clear the current focused row
   */
  protected clearFocusedRow() {
    this.dispatchFocusedAction(
      new UnassignedDeliveriesStoreActions.SetFocusedDelivery({
        focusedDelivery: {
          id: undefined,
          source: StoreSourcesEnum.UNASSIGNED_DELIVERY_GRID,
        },
      })
    );
  }

  /**
   * Show the map location for the specified shipment
   */
  onAddressClick(data: DeliveryShipmentSearchRecord): void {
    // TODO - move this to a service!
    // this.hasClickActive = true;
    const name1 = _get(data, 'consignee.name1');
    const latitude = _get(data, 'consignee.geoCoordinates.latitude');
    const longitude = _get(data, 'consignee.geoCoordinates.longitude');

    if (_isEmpty(name1)) {
      return;
    }

    if (this.currentlyOpenedStopMapComponentDialog) {
      this.currentlyOpenedStopMapComponentDialog.close();
    }

    if (!latitude || !longitude) {
      // no location to display
      this.pndDialogService.showConfirmCancelDialog(
        'Missing Location Data',
        `No Geo Coordinates for ${name1}'s location`,
        ''
      );

      this.pndStore$.dispatch(new UnassignedDeliveriesStoreActions.SetClickedDelivery({ clickedDelivery: data }));
    } else {
      this.pndStore$.dispatch(
        new UnassignedDeliveriesStoreActions.SetFocusedDelivery({
          focusedDelivery: {
            id: undefined,
            source: StoreSourcesEnum.UNASSIGNED_DELIVERY_GRID,
          },
        })
      );
      this.pndStore$.dispatch(new UnassignedDeliveriesStoreActions.SetClickedDelivery({ clickedDelivery: data }));
      this.currentlyOpenedStopMapComponentDialog = this.dialog.open(StopMapComponent, {
        data: {
          consigneeName: name1,
          geoCoordinates: { latitude, longitude },
        },
        disableClose: true,
      });
      this.currentlyOpenedStopMapComponentDialog.afterOpened().subscribe(() => {});
    }
  }

  /**
   * Show the shipment details dialog
   */
  onShowShipmentDetails(data: { proNbr: string; shipmentInstId: number }): void {
    if (!_isEmpty(data.proNbr)) {
      this.pndDialogService
        .showShipmentDetailsDialog({ proNbr: data.proNbr, shipmentInstId: data.shipmentInstId })
        .subscribe();
    }
  }

  /**
   * Find all nodes in the grid that have the specified consigneeId
   */
  protected findNodesForStop(consigneeId: string): RowNode[] {
    const gridNodes: RowNode[] = [];
    this.gridApi.forEachNode((node) => {
      const shipment = node.data as UnassignedDeliveryIdentifier;
      if (consigneeToId(shipment) === consigneeId) {
        gridNodes.push(node);
      }
    });
    return gridNodes;
  }

  /**
   * Return the EventItem representing the passed Stop/Shipment
   */
  protected toEventItem(sourceId: UnassignedDeliveryIdentifier): EventItem<UnassignedDeliveryIdentifier> {
    if (!sourceId) {
      return undefined;
    } else {
      return {
        id: {
          consignee: _pick(sourceId.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
          shipmentInstId: sourceId.shipmentInstId,
        },
        source: StoreSourcesEnum.UNASSIGNED_DELIVERY_GRID,
      } as EventItem<UnassignedDeliveryIdentifier>;
    }
  }

  /**
   * compares the two lists and returns true if they contain the same items (in any order), else returns false
   */
  protected areListsEqual(list1: UnassignedDeliveryIdentifier[], list2: UnassignedDeliveryIdentifier[]): boolean {
    if (_size(list1) !== _size(list2)) {
      return false;
    } else {
      const diffs = _xorWith(list1, list2, (a, b) => consigneeShipmentToId(a) === consigneeShipmentToId(b));
      return _size(diffs) === 0; // lists were the same
    }
  }
}
