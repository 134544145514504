import { UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { MapMarkersService } from '../../services/map-markers.service';
import { SpecialServicesService } from '../../services/special-services.service';
import { StopWindowService } from '../../services/stop-window.service';
import { MapMarkerInfo } from '../map-marker-info.model';
import { ClusterableMarker } from './clusterable-marker';
import { MapMarkerWithInfoWindow } from './map-marker-with-info-window';

export class UnassignedDeliveryMapMarker extends MapMarkerWithInfoWindow<MapMarkerInfo> implements ClusterableMarker {
  readonly rowData: UnassignedStop;
  infoWindowTopOffset: number = 0;
  infoWindowLeftOffset: number = 0;
  infoWindowSpaceBetweenMarker: number = 0;

  get mappable(): boolean {
    return !!this.latitude && !!this.longitude;
  }

  constructor(
    unassignedStop: UnassignedStop,
    mapMarkerService: MapMarkersService,
    private stopWindowService: StopWindowService,
    private specialServicesService: SpecialServicesService
  ) {
    super();

    this.rowData = unassignedStop;
    this.icon = mapMarkerService.getMarkerIconUnassigned();

    if (unassignedStop.consignee && unassignedStop.consignee.geoCoordinates) {
      this.latitude = +unassignedStop.consignee.geoCoordinates.latitude;
      this.longitude = +unassignedStop.consignee.geoCoordinates.longitude;
    }

    this.markerInfo = new MapMarkerInfo(this.stopWindowService, unassignedStop, undefined, undefined);
    this.markerInfo.specialServices = this.specialServicesService.getSpecialServicesForSummary(
      unassignedStop.specialServiceSummary
    );
  }
}
