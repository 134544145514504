import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SdkLocationModule } from '@xpo-ltl-2.0/sdk-location';
import { CorrelationIdHttpInterceptor } from '@xpo-ltl/data-api';
import { LoginModule } from '@xpo-ltl/login';
import { XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import {
  XpoAccountPopoverModule,
  XpoAppSwitcherPopoverModule,
  XpoCommonModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoIconModule,
  XpoMessagingPopoverModule,
  XpoPageNotFoundRoutingModule,
  XpoShellModule,
  XpoSnackBarModule,
} from '@xpo-ltl/ngx-ltl-core';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { SdkHumanResourceModule } from '@xpo-ltl/sdk-humanresource';
import { SdkInfrastructureModule } from '@xpo-ltl/sdk-infrastructure';
import { SdkLoggingModule } from '@xpo-ltl/sdk-logging';
import { SdkPricingModule } from '@xpo-ltl/sdk-pricing';
import { SdkShipmentOdsModule } from '@xpo-ltl/sdk-shipmentods';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import {
  XpoLtlDateSelectorModule,
  XpoLtlDmsUtilsModule,
  XpoLtlReleaseNotesModule,
  XpoLtlRoleSwitcherModule,
  XpoLtlShipmentDetailsModule,
  XpoLtlSicSwitcherModule,
} from '@xpo/ngx-ltl';
import * as $ from 'jquery';
import { CoreModule, NotAuthorizedComponent } from '../core';
import { PndDialogModule } from '../core/dialogs/pnd-dialog.module';
import { DocumentViewerModule } from '../shared/document-viewer';
import { LayoutPreferenceModule } from '../shared/layout-preference/layout-preference.module';
import { XpoLtlLayoutPreferencesStorageUserPreferencesService } from '../shared/layout-preference/services/layout-preferences-storage-user-preferences.service';
import { XPO_LTL_LAYOUT_PREFERENCES_STORAGE } from '../shared/layout-preference/services/layout-preferences-storage.interface';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InboundPlanningModule } from './inbound-planning/inbound-planning.module';
import { XpoFooterModule } from './xpo-footer/xpo-footer.module';

window['$'] = $;

@NgModule({
  declarations: [AppComponent, NotAuthorizedComponent],
  imports: [
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    BrowserAnimationsModule,
    LoginModule,
    CoreModule,
    InboundPlanningModule,
    PndDialogModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    XpoCommonModule,
    XpoDialogModule,
    XpoShellModule,
    XpoLtlDateSelectorModule,
    XpoSnackBarModule,
    XpoFeedbackModule,
    XpoAccountPopoverModule,
    XpoAppSwitcherPopoverModule,
    XpoLtlReleaseNotesModule,
    XpoLtlSicSwitcherModule,
    XpoLtlRoleSwitcherModule,
    XpoMessagingPopoverModule,
    SdkAppMetadataModule,
    SdkLocationModule,
    SdkInfrastructureModule,
    SdkLoggingModule,
    SdkUserPreferenceModule,
    SdkShipmentOdsModule,
    SdkHumanResourceModule,
    AppRoutingModule,
    LayoutPreferenceModule,
    XpoPageNotFoundRoutingModule,
    SdkPricingModule,
    XpoLtlShipmentDetailsModule,
    XpoLtlDmsUtilsModule,
    DocumentViewerModule,
    XpoFooterModule,
    XpoLtlAuthModule,
    XpoIconModule,
  ],
  entryComponents: [NotAuthorizedComponent],
  providers: [
    // { provide: DocumentManagementApiService, useClass: MockDocumentManagementApiService }, // TODO - this mocks the Document access since we don't have any in DMS right now
    {
      provide: XPO_LTL_LAYOUT_PREFERENCES_STORAGE,
      // useClass: XpoLtlLayoutPreferencesStorageLocalService,
      useClass: XpoLtlLayoutPreferencesStorageUserPreferencesService,
    },
    { provide: HTTP_INTERCEPTORS, useClass: CorrelationIdHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
