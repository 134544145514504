export enum UnassignedDeliveriesGridHeaders {
  ROW_SELECTED = 'checkbox',
  BILLS = 'Bills',
  CONSIGNEE = 'Consignee',
  ADDRESS = 'Address',
  CITY = 'City',
  ZIP_CODE = 'Zip Code',
  SPECIAL_SERVICES = 'Special Services',
  WEIGHT = 'Weight (lbs.)',
  MM = 'MM',
  LP = 'LP',
  CUBE = 'Cube',
  DESTINATION = 'Destination SIC',
  DELIVERY_WINDOW_TYPE = 'Delivery Window Type',
  DELIVERY_WINDOW_DATE = 'Delivery Window Time',
  DELIVERY_WINDOW_TIME = 'Delivery Window Date',
  EARLIEST_ETA = 'Earliest ETA',
  LATEST_ETA = 'Latest ETA',
  PRO = 'PRO',
  BILL_CLASS = 'Bill Class',
  SERVICE_DATE = 'Svc Date',
  DESTINATION_ETA = 'Destination ETA',
  DESTINATION_SIC = 'Destination SIC',
  CURRENT_SIC = 'Current SIC',
  TRAILER = 'Trailer',
  TRAILER_SIC = 'Trailer SIC',
  EXCEPTIONS = 'Exceptions',
  SCHEDULED_ETA = 'Schedule ETA',
  SCHEDULED_DESTINATION = 'Schedule Destination',
}
