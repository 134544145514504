import { Injectable } from '@angular/core';
import {
  filter as _filter,
  find as _find,
  forEach as _forEach,
  get as _get,
  map as _map,
  reduce as _reduce,
  size as _size,
  uniq as _uniq,
} from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { MapSplitPanelItem } from '../../components/planning-map/components/map-split-panels/map-split-panel-item';

@Injectable({ providedIn: 'root' })
export class MapSplitPanelService {
  private componentsListSubject = new BehaviorSubject<MapSplitPanelItem[]>([]);
  readonly componentsList$ = this.componentsListSubject.asObservable();

  addPanelItem(panelItem: MapSplitPanelItem): void {
    let newArr: MapSplitPanelItem[] = [];
    this.componentsList$.pipe(take(1)).subscribe((oldArr) => {
      if (!_find(oldArr, panelItem)) {
        newArr = oldArr.concat([panelItem]);
        this.componentsListSubject.next(newArr);
      }
    });
  }

  removePanelItem(panelItem: MapSplitPanelItem): void {
    let newArr: MapSplitPanelItem[] = [];
    this.componentsList$.pipe(take(1)).subscribe((oldArr) => {
      if (_find(oldArr, panelItem)) {
        newArr = oldArr.filter((item: MapSplitPanelItem) => {
          return item.data.id !== panelItem.data.id;
        });
        this.componentsListSubject.next(newArr);
      }
    });
  }
}
