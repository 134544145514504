import { XpoBoardViewDataStoreBase } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewConfig } from '@xpo-ltl/ngx-ltl-board-grid';
import { forEach as _forEach, size as _size } from 'lodash';
import { Observable } from 'rxjs';
import { UserPreferencesService } from '../services/user-preferences.service';

export abstract class InboundPlanningDataStoreBase extends XpoBoardViewDataStoreBase {
  constructor(
    protected userPreferencesService: UserPreferencesService,
    protected preferences: XpoAgGridBoardViewConfig[],
    private componentName: string
  ) {
    super();
  }

  abstract getAll(): Observable<XpoAgGridBoardViewConfig[]>;

  updateDataStore(viewConfigs: XpoAgGridBoardViewConfig[]): Observable<XpoAgGridBoardViewConfig[]> {
    return this.userPreferencesService.updatePreferencesFor<XpoAgGridBoardViewConfig[]>(
      this.componentName,
      viewConfigs
    );
  }
}
