import { DriverLocation } from '@xpo-ltl/sdk-cityoperations';

export const StateKey = 'driversState';

export interface State {
  readonly driversCurrentLocation: DriverLocation[];
}

export const initialState: State = {
  driversCurrentLocation: new Array<DriverLocation>(),
};
