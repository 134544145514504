import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StateKey, State } from './route-balancing.state';

export const selectRouteBalancingState = createFeatureSelector<State>(StateKey);

export const openRouteBalancingPanel = createSelector(
  selectRouteBalancingState,
  (state: State) => state.openRouteBalancingPanel
);

export const manualSequencingRoutes = createSelector(selectRouteBalancingState, (state: State) => state.routes);

export const pinnedFirst = createSelector(selectRouteBalancingState, (state: State) => state.pinFirst);

export const pinnedLast = createSelector(selectRouteBalancingState, (state: State) => state.pinLast);

export const lanesDirty = createSelector(selectRouteBalancingState, (state: State) => state.lanesDirty);

export const canOpenRouteBalancing = createSelector(
  selectRouteBalancingState,
  (state: State) => state.canOpenRouteBalancing
);
