import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { get as _get, map as _map, isEmpty as _isEmpty, find as _find, first as _first } from 'lodash';
import { PndStoreState } from '../../../../../../store';
import { DriverMapMarkerInfo, DriverRouteInfo } from '../../../../../shared/models/driver-map-marker-info.model';
import { RouteColorService } from '../../../../../shared/services/route-color.service';

@Component({
  selector: 'driver-marker-info',
  templateUrl: './driver-marker-info.component.html',
  styleUrls: ['./driver-marker-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DriverMarkerInfoComponent implements OnInit {
  @Input()
  driverMarkerInfo: DriverMapMarkerInfo;

  routeColor: string;
  leftBorderWidth: string;

  driverMessage: string = undefined;
  routes: Array<DriverRouteInfo> = [];

  constructor(private pndStore$: Store<PndStoreState.State>, private routeColorService: RouteColorService) {}

  ngOnInit() {
    //    this.routes = _get(this.driverMarkerInfo, 'routes', [{ id: 'N/A', feetAvailable: 0 }]);
    this.routes = _get(this.driverMarkerInfo, 'routes', []);

    const currentRoute = _first(this.driverMarkerInfo.routes);

    this.routeColor = this.routeColorService.getColorForRoute(_get(currentRoute, 'routeInstId'));
    this.leftBorderWidth = this.routeColor ? '4px' : '1px';
  }
}
