import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatButtonModule,
  MatDividerModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatSlideToggleModule,
} from '@angular/material';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { XpoGMapBoardModule } from '@xpo-ltl/ngx-ltl-board-gmaps';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoButtonModule, XpoCardModule, XpoChipsModule, XpoIconModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoAllowCharactersModule, XpoLtlPipeModule } from '@xpo/ngx-ltl';
import { DragScrollModule } from 'cdk-drag-scroll';
import { Ng2CarouselamosModule } from 'ng2-carouselamos';
import { InboundPlanningSharedModule } from '../../shared';
import { RoutebalancingContainerDirective } from './classes/directives/route-balancing-container.directive';
import { AutosequenceDialogComponent } from './components/autosequence-dialog/autosequence-dialog.component';
import { ExistingRouteLaneComponent } from './components/board/existing-route-lane/existing-route-lane.component';
import { LaneCreatorComponent } from './components/board/lane-creator/lane-creator.component';
import { NewRouteLaneComponent } from './components/board/new-route-lane/new-route-lane.component';
import { RouteLaneComponent } from './components/board/route-lane/route-lane.component';
import { StopCardComponent } from './components/stop-card/stop-card.component';
import { CategoryColumnComponent } from './route-balancing-metrics/category-column/category-column.component';
import { RouteBalancingMetricsComponent } from './route-balancing-metrics/route-balancing-metrics.component';
import { RouteBalancingComponent } from './route-balancing.component';

@NgModule({
  declarations: [
    RouteBalancingComponent,
    StopCardComponent,
    RoutebalancingContainerDirective,
    RouteBalancingMetricsComponent,
    CategoryColumnComponent,
    LaneCreatorComponent,
    ExistingRouteLaneComponent,
    NewRouteLaneComponent,
    RouteLaneComponent,
    AutosequenceDialogComponent,
  ],
  exports: [RouteBalancingComponent, StopCardComponent],
  entryComponents: [RouteBalancingComponent, AutosequenceDialogComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    DragScrollModule,
    XpoAllowCharactersModule,
    XpoLtlPipeModule,
    DragDropModule,
    XpoButtonModule,
    XpoAgGridBoardModule,
    XpoCardModule,
    XpoChipsModule,
    XpoIconModule,
    XpoGMapBoardModule,
    Ng2CarouselamosModule,
    InboundPlanningSharedModule,
  ],
})
export class RouteBalancingModule {}
