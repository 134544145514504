import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { DriverStoreModule } from './driver-store/driver-store.module';
import { GeoAreaStoreModule } from './geo-area-store/geo-area-store.module';
import { GeoLocationStoreModule } from './geo-location-store/geo-location-store.module';
import { GlobalFiltersStoreModule } from './global-filters-store';
import { PlanningProfilesStoreModule } from './planning-profiles-store';
import { RouteBalancingStoreModule } from './route-balancing-store';
import { RoutesStoreModule } from './routes-store/routes-store.module';
import { TripsStoreModule } from './trips-store/trips-store.module';
import { UnassignedDeliveriesStoreModule } from './unassigned-deliveries-store';
import { UnassignedPickupsStoreModule } from './unassigned-pickups-store';

const storeName = window.origin.includes('localhost') ? 'PndApp-Local' : 'PndApp';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({ name: storeName, maxAge: 50, logOnly: environment.production }),
    DriverStoreModule,
    GeoAreaStoreModule,
    GeoLocationStoreModule,
    GlobalFiltersStoreModule,
    PlanningProfilesStoreModule,
    RouteBalancingStoreModule,
    RoutesStoreModule,
    TripsStoreModule,
    UnassignedDeliveriesStoreModule,
    UnassignedPickupsStoreModule,
  ],
})
export class PndStoreModule {}
