import { Injectable } from '@angular/core';
import { Route, TripDetail } from '@xpo-ltl/sdk-cityoperations';
import { forEach as _forEach, get as _get } from 'lodash';
import { TripPlanningGridItem } from '../../../components/trip-planning/models/trip-planning-grid-item.model';

@Injectable({
  providedIn: 'root',
})
export class TripsGridItemConverterService {
  constructor() {}

  getTripsGridItems(tripDetails: TripDetail[]): TripPlanningGridItem[] {
    const gridItems: TripPlanningGridItem[] = [];
    _forEach(tripDetails, (tripDetail) => {
      if (tripDetail.route.length === 0) {
        const keyField: string = _get(tripDetail, 'trip.tripInstId', '').toString();
        const tripDsrName: string = _get(tripDetail, 'trip.tripDsrName', '');
        const route = new Route();
        route.routeInstId = 0;
        gridItems.push(this.tripOnlyGridItem(tripDetail, route, keyField, tripDsrName));
      } else {
        let routeNumber = 0;
        _forEach(tripDetail.route, (routeDetail) => {
          routeNumber += 1;
          const numberOfRoutes = tripDetail.route.length;
          let tripDsrName = _get(tripDetail, 'trip.tripDsrName', '');
          if (numberOfRoutes > 1) {
            if (tripDsrName === '') {
              tripDsrName = `${tripDetail.trip.tripInstId}`;
            }
            tripDsrName += `(${numberOfRoutes})`;
          }
          const keyField = _get(tripDetail, 'trip.tripInstId', '') + '-' + routeNumber; // creating a unique keyField

          const tripPlanningGridItem: TripPlanningGridItem = this.tripOnlyGridItem(
            tripDetail,
            routeDetail.route,
            keyField,
            tripDsrName
          );
          gridItems.push(tripPlanningGridItem);
        });
      }
    });
    return gridItems;
  }

  tripOnlyGridItem(tripDetail: TripDetail, route: Route, keyField: string, tripDsrName: string): TripPlanningGridItem {
    route.tripInstId = tripDetail.trip.tripInstId;
    const tripPlanningGridItem: TripPlanningGridItem = {
      keyField: keyField,
      tripDsrName: tripDsrName,
      tripStopCount: _get(tripDetail, 'trip.stopCount'),
      tripInstId: _get(tripDetail, 'trip.tripInstId'),
      routeInstId: _get(route, 'routeInstId'),
      route: route,
      metrics: _get(tripDetail, 'metrics'),
      estimatedEmptyDateTime: _get(tripDetail, 'trip.estimatedEmptyDateTime'),
      statusCd: _get(tripDetail, 'trip.statusCd'),
      specialServices: _get(tripDetail, 'specialServices'),
    };
    return tripPlanningGridItem;
  }
}
