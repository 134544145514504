import { UnassignedPickup } from '@xpo-ltl/sdk-cityoperations';
import { MapMarkersService } from '../../services/map-markers.service';
import { PICKUP_COLOR } from '../../services/stop-colors';
import { StopWindowService } from '../../services/stop-window.service';
import { MapMarkerInfo } from '../map-marker-info.model';
import { ClusterableMarker } from './clusterable-marker';
import { MapMarkerWithInfoWindow } from './map-marker-with-info-window';

export class UnassignedPickupMapMarker extends MapMarkerWithInfoWindow<MapMarkerInfo> implements ClusterableMarker {
  readonly pickup: UnassignedPickup;
  infoWindowTopOffset: number = 0;
  infoWindowLeftOffset: number = 0;
  infoWindowSpaceBetweenMarker: number = 0;

  get mappable(): boolean {
    return !!this.latitude && !!this.longitude;
  }

  constructor(
    pickup: UnassignedPickup,
    mapMarkerService: MapMarkersService,
    private stopWindowService: StopWindowService
  ) {
    super();

    this.pickup = pickup;
    this.icon = mapMarkerService.getMarkerIconUnassigned(pickup.typeCd, 10, false, PICKUP_COLOR);

    if (pickup.shipper) {
      this.latitude = +pickup.shipper.latitudeNbr;
      this.longitude = +pickup.shipper.longitudeNbr;
    }

    // TODO - MapMarkerInfo uses the stopType to recreate the icon for the marker.  This is BAD since it ignores
    // any color (and other data) set on the initial marker creation.
    this.markerInfo = new MapMarkerInfo(
      this.stopWindowService,
      { ...pickup, stopType: pickup.typeCd, color: PICKUP_COLOR },
      undefined,
      undefined
    );

    // for pickups, specialServiceSummary is already ShipmentSpecialServiceCd[]
    this.markerInfo.specialServices = pickup.specialServiceSummary;
  }
}
