import { Injectable } from '@angular/core';
import { XrtAttributeFilter } from '@xpo-ltl/sdk-common';
import { isEmpty as _isEmpty, isUndefined as _isUndefined } from 'lodash';
import * as moment from 'moment-timezone';

@Injectable({ providedIn: 'root' })
export class PndXrtService {
  toXrtFilterValues(values: any): XrtAttributeFilter {
    return !_isEmpty(values) ? { ...new XrtAttributeFilter(), values } : undefined;
  }

  toXrtFilterEquals(equals: any): XrtAttributeFilter {
    return !_isEmpty(equals) ? { ...new XrtAttributeFilter(), equals } : undefined;
  }

  toXrtFilterPoints(points: any): XrtAttributeFilter {
    return !_isEmpty(points) ? { ...new XrtAttributeFilter(), points } : undefined;
  }

  toXrtFilterEqualsDate(date: Date): XrtAttributeFilter {
    return !_isUndefined(date) && moment(date).isValid()
      ? { ...new XrtAttributeFilter(), equals: moment(date).format('YYYY-MM-DD') }
      : undefined;
  }

  toXrtFilterEqualsDateRange(min: Date, max: Date): XrtAttributeFilter {
    const filter = { ...new XrtAttributeFilter() };

    const minDate = !_isUndefined(min) && moment(min).isValid() ? moment(min).format('YYYY-MM-DD') : undefined;
    const maxDate = !_isUndefined(max) && moment(max).isValid() ? moment(max).format('YYYY-MM-DD') : undefined;

    if (minDate === maxDate) {
      filter.equals = minDate;
    } else {
      if (minDate) {
        filter.min = minDate;
      }
      if (maxDate) {
        filter.max = maxDate;
      }
    }

    return !_isUndefined(filter.min) || !_isUndefined(filter.max) || !_isUndefined(filter.equals) ? filter : undefined;
  }

  toXrtFilterEqualsDateTimeRange(min: Date, max: Date): XrtAttributeFilter {
    const filter = { ...new XrtAttributeFilter() };

    const minDate = !_isUndefined(min) && moment(min).isValid() ? moment(min).toISOString() : undefined;
    const maxDate = !_isUndefined(max) && moment(max).isValid() ? moment(max).toISOString() : undefined;

    if (minDate === maxDate) {
      filter.equals = minDate;
    } else {
      if (minDate) {
        filter.min = minDate;
      }
      if (maxDate) {
        filter.max = maxDate;
      }
    }

    return !_isUndefined(filter.min) || !_isUndefined(filter.max) || !_isUndefined(filter.equals) ? filter : undefined;
  }

  toXrtFilterMinMaxRange(min: string, max: string): XrtAttributeFilter {
    const filter = { ...new XrtAttributeFilter() };

    filter.min = !_isEmpty(min) ? min : undefined;
    filter.max = !_isEmpty(max) ? max : undefined;

    return _isEmpty(filter.min) && _isEmpty(filter.max) ? undefined : filter;
  }
}
