import { Component } from '@angular/core';

@Component({
  selector: 'pnd-recenter',
  templateUrl: './recenter.component.html',
  styleUrls: ['./recenter.component.scss'],
})
export class RecenterComponent {
  constructor() {}
}
