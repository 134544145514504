import { XpoAgGridBoardViewConfig } from '@xpo-ltl/ngx-ltl-board-grid';
import { forEach as _forEach, size as _size } from 'lodash';
import { Observable, of } from 'rxjs';
import { InboundPlanningDataStoreBase } from '../../../shared/classes/inbound-planning-data-store-base.class';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import { TripPlanningComponentName } from '../models/trip-planning-component-name';

export class TripPlanningViewDataStore extends InboundPlanningDataStoreBase {
  private readonly viewId = '1';

  private readonly defaultSortOrder = [
    {
      column: 'routeName',
      direction: 'asc',
    },
  ];

  constructor(userPreferencesService: UserPreferencesService, preferences: XpoAgGridBoardViewConfig[]) {
    super(userPreferencesService, preferences, TripPlanningComponentName);
  }

  getAll(): Observable<XpoAgGridBoardViewConfig[]> {
    if (_size(this.preferences) === 0) {
      return of([
        {
          closeable: false,
          criteria: { tripStatusCd: ['new'] },
          id: this.viewId,
          name: 'Show All',
          visible: true,
          systemDefined: true,
          sortOrder: this.defaultSortOrder,
        },
      ]);
    } else {
      const viewArray: XpoAgGridBoardViewConfig[] = [];
      _forEach(this.preferences, (element: XpoAgGridBoardViewConfig) => {
        viewArray.push({
          closeable: element.closeable,
          criteria: element.criteria,
          id: element.id,
          name: element.name === 'New' ? 'Show All' : element.name,
          visible: element.visible,
          visibleColumns: element.visibleColumns,
          systemDefined: element.systemDefined || false,
          children: element.children || [],
          lastSaved: element.lastSaved,
          sortOrder: _size(element.sortOrder) > 0 ? element.sortOrder : this.defaultSortOrder,
          templateId: element.templateId || '',
        });
      });
      return of(viewArray);
    }
  }
}
