import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, StateKey } from './routes-store.state';

export const routesState = createFeatureSelector<State>(StateKey);

// Misc
export const showCompletedStops = createSelector(routesState, (state: State) => state.showCompletedStops);
export const updateUnassignedDeliveriesGrid = createSelector(
  routesState,
  (state: State) => state.updateUnassignedDeliveriesGrid
);
export const routeMarkers = createSelector(routesState, (state: State) => state.routeMarkers);

// Trip Routes
export const selectedRoutes = createSelector(routesState, (state: State) => state.selectedRoutes);
export const routeStopClicked = createSelector(routesState, (state: State) => state.clickedRouteStop);

export const stopsForSelectedRoutes = createSelector(routesState, (state: State) => state.stopsForSelectedRoutes);
export const selectedStopsForSelectedRoutes = createSelector(
  routesState,
  (state: State) => state.stopsSelectedForSelectedRoutes
);

export const focusedRoute = createSelector(routesState, (state: State) => state.focusedRoute);
export const focusedStopForSelectedRoute = createSelector(
  routesState,
  (state: State) => state.focusedStopForSelectedRoute
);

// TODO - move to Route Balancing
export const resequencedRouteData = createSelector(routesState, (state: State) => state.resequencedRouteData);

// Planning Routes
export const searchCriteria = createSelector(routesState, (state: State) => state.searchCriteria);
export const changedRoutes = createSelector(routesState, (state: State) => state.changedRoutes);
export const planningRoutesLastUpdate = createSelector(routesState, (state: State) => state.planningRoutesLastUpdate);

export const focusedPlanningRoute = createSelector(routesState, (state: State) => state.focusedPlanningRoute);
export const planningRouteShipmentFocused = createSelector(
  routesState,
  (state: State) => state.focusedPlanningRouteShipment
);

export const selectedPlanningRoutes = createSelector(routesState, (state: State) => state.selectedPlanningRoutes);

export const stopsForSelectedPlanningRoutesLastUpdate = createSelector(
  routesState,
  (state: State) => state.stopsForSelectedPlanningRoutesLastUpdate
);

export const selectedPlanningRoutesShipments = createSelector(
  routesState,
  (state: State) => state.selectedPlanningRoutesShipments
);
