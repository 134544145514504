import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { GoldenLayoutService } from '@embedded-enterprises/ng6-golden-layout';
import { Store, select } from '@ngrx/store';
import { CityOperationsApiService, ListPnDServiceCenterMetricsResp, Metric } from '@xpo-ltl/sdk-cityoperations';
import { Unsubscriber } from '@xpo/ngx-ltl';
import * as GoldenLayout from 'golden-layout';
import {
  filter as _filter,
  findIndex as _findIndex,
  forEach as _forEach,
  get as _get,
  isUndefined as _isUndefined,
  map as _map,
  uniq as _uniq,
} from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, combineLatest, Subscription, timer } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { GoldenLayoutExtService } from '../../shared/layout-preference/services/golden-layout-ext.service';
import { LayoutPreferenceService } from '../../shared/layout-preference/services/layout-preference.service';
import { GlobalFilterStoreSelectors, PndStoreState } from '../store';
import { RouteBalancingSelectors } from '../store/route-balancing-store';
import { MetricBarMessagingService } from './shared/components/metric-bar/metric-bar-messaging.service';
import { ReleaseNotesUpdateService } from './shared/services/release-notes-update.service';

@Component({
  selector: 'app-inbound-planning',
  templateUrl: './inbound-planning.component.html',
  styleUrls: ['./inbound-planning.component.scss'],
  providers: [MetricBarMessagingService],
})
export class InboundPlanningComponent implements OnDestroy, OnInit {
  isRouteBalancingActive: boolean = false;
  // KeyboarcEvent.code values for the CTRL-SHIFT-# keys
  private readonly keyMap = [
    'Digit1',
    'Digit2',
    'Digit3',
    'Digit4',
    'Digit5',
    'Digit6',
    'Digit7',
    'Digit8',
    'Digit9',
    'Digit0',
  ];

  private shortcutSlots = new Array<GoldenLayout.ContentItem>(10); // create 10 slots

  private unsubscriber = new Unsubscriber();

  private metricsSubject = new BehaviorSubject<Metric[]>(undefined);
  metrics$ = this.metricsSubject.asObservable();

  private sicCd: string;
  private planDate: Date;
  private subscriberAutorefreshMetrics: Subscription;
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // NOTE: Hard-coded to listen for CTRL+SHFT+#.
    if (event.ctrlKey && event.shiftKey && !event.repeat && event.key !== 'Control' && event.key !== 'Shift') {
      const slot = _findIndex(this.keyMap, (k) => k === event.code);
      if (slot > -1) {
        this.toggleMaximizeComponent(slot);
      }
    }
  }

  constructor(
    private goldenLayout: GoldenLayoutService,
    private layoutPreferences: LayoutPreferenceService,
    private pndStore$: Store<PndStoreState.State>,
    private cityOperationsApi: CityOperationsApiService,
    private releaseNotesUpdateService: ReleaseNotesUpdateService,
    public metricBarMessagingService: MetricBarMessagingService
  ) {
    const glService = this.goldenLayout as GoldenLayoutExtService;

    this.layoutPreferences.loadLayoutsFromStorage();

    glService.componentCreated$.subscribe((component: GoldenLayout.ContentItem) => {
      // Assign newly created Items to a shortcut key slot, if available
      const slot = _findIndex(this.shortcutSlots, (compSlot) => _isUndefined(compSlot));
      if (slot >= 0) {
        // register this component for the keyboard shortcut at index
        this.shortcutSlots[slot] = component;

        // Set tooltip to show keyboard shortcut
        // TODO - this doesn't work when you move to a new stack!
        component.on('titleChanged', (title: string) => {
          const tooltip = `${title} (CTRL+SHFT+${slot + 1})`;
          timer(1).subscribe(() => {
            // set the tooltip for the tab to contain the keyboard shortcut
            const tabElem = _get(component, 'container.tab.element');
            if (tabElem) {
              tabElem.attr('title', tooltip);
            }
          });
        });

        const tabTitle = component.config.title;

        component.setTitle(`${tabTitle}`);

        component.on('tab', (tab: GoldenLayout.Tab) => {
          const tabElem = _get(tab, 'contentItem.container.tab.element');
          if (tabElem) {
            tabElem.attr('title', tabTitle);
          }
          component.setTitle(`${tabTitle}`);
        });
      }
    });

    glService.componentDestroyed$.subscribe((component: GoldenLayout.ContentItem) => {
      // remove a destroyed Item from its shortcut slot
      const slot = _findIndex(this.shortcutSlots, (compSlot) => compSlot === component);
      if (slot >= 0) {
        this.shortcutSlots[slot] = undefined;
      }
    });

    this.pndStore$
      .pipe(select(RouteBalancingSelectors.openRouteBalancingPanel), takeUntil(this.unsubscriber.done))
      .subscribe((isRouteBalancingActive: boolean) => {
        this.isRouteBalancingActive = isRouteBalancingActive;
      });

    this.subscribeToGlobalFilterChange();
  }

  ngOnInit() {
    this.subscribeToAutorefreshMetrics();
    this.releaseNotesUpdateService.showReleaseNotes();
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
    this.subscriberAutorefreshMetrics.unsubscribe();
  }

  private subscribeToAutorefreshMetrics(): void {
    this.subscriberAutorefreshMetrics = this.metricBarMessagingService.elementsUpdated$.subscribe((n: number) => {
      if (n > 0) {
        this.updateMetrics();
      }
    });
  }

  private deMaximizeAll() {
    // shrink any maximized stacks
    const stacks = _uniq(
      _map(
        _filter(this.shortcutSlots, (pane) => !!pane),
        (pane) => pane.parent
      )
    );
    _forEach(stacks, (stack) => {
      if (stack.isMaximised) {
        stack.toggleMaximise();
      }
    });
  }

  private toggleMaximizeComponent(slot: number) {
    const selectedPane = this.shortcutSlots[slot];
    if (selectedPane) {
      if (selectedPane.parent) {
        if (selectedPane.parent.isMaximised && selectedPane === selectedPane.parent.getActiveContentItem()) {
          // active pane is being shrunken
          selectedPane.parent.toggleMaximise();
        } else {
          this.deMaximizeAll();
          selectedPane.parent.toggleMaximise();
          selectedPane.parent.setActiveContentItem(selectedPane);
        }
      } else {
        // this pane isn't part of a stack, so directly toggle size
        selectedPane.toggleMaximise();
      }
    }
  }

  private updateMetrics() {
    this.cityOperationsApi
      .listPnDServiceCenterMetrics(
        { sicCd: this.sicCd },
        { planDate: moment(this.planDate).format('YYYY-MM-DD'), roleCd: undefined }
      )
      .pipe(map((resp: ListPnDServiceCenterMetricsResp) => resp.metrics))
      .subscribe(
        (metrics) => {
          this.metricsSubject.next(metrics);
        },
        (err) => {
          this.metricsSubject.next([]);
        }
      );
  }

  private subscribeToGlobalFilterChange(): void {
    const sic$ = this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic);
    const planDate$ = this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterPlanDate);
    combineLatest([sic$, planDate$])
      .pipe(
        filter(([sicCd, planDate]: [string, Date]) => !!sicCd),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(([sicCd, planDate]: [string, Date]) => {
        this.sicCd = sicCd;
        this.planDate = planDate;
        this.updateMetrics();
        this.metricBarMessagingService.updateCurrentFilter({
          sicCd: sicCd,
          planDate: moment(planDate).format('YYYY-MM-DD'),
        });
      });
  }
}
