import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { LoginService } from '@xpo-ltl/login';
import { User } from '@xpo-ltl/sdk-common';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { XpoLtlRoleSwitcherComponent, XpoLtlRoleSwitcherData } from '@xpo/ngx-ltl';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { NotAuthorizedComponent } from '../../dialogs/not-authorized/not-authorized.component';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { UserRole } from '../../enums/user-role/user-role.enum';
import UserRoleHelper from '../../enums/user-role/user-role.helper';
import { UserRoleService } from '../../services/user-role/user-role.service';

@Injectable({
  providedIn: 'root',
})
export class RoleSwitchGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private config: ConfigManagerService,
    private userRoleService: UserRoleService,
    private dialog: MatDialog,
    private loggingService: LoggingApiService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    /** TODO: see user-role.service.ts on allowed roles... if a user has more than one allowed role,
     *        let them select the role to log in as.
     **/
    return new Promise((resolve, reject) => {
      const failGuardFunc = () => {
        this.userRoleService.setUser(undefined);
        this.loggingService.clearContext('Role');
        this.loggingService.error(`User Not Authorized`);
        this.showNotAuthorizedDialog();
        reject();
      };

      this.loginService.getLoggedInUser(this.config.getSetting(ConfigManagerProperties.loggedInUserRoot)).subscribe(
        (user: User) => {
          if (this.userRoleService.hasMultipleRoles(user)) {
            this.showRoleSwitcherDialog()
              .pipe(take(1))
              .subscribe((role: UserRole) => {
                this.userRoleService.setUser(user);
                this.userRoleService.setRole(role);
                this.loggingService.setContext('Role', role);
                resolve(true);
                return;
              });
          } else if (this.userRoleService.isAuthorizedUser(user)) {
            this.userRoleService.setUser(user);
            // this.loggingService.setContext('Role', this.userRoleService.getRole());
            resolve(true);
            return;
          } else {
            failGuardFunc();
          }
        },
        () => {
          failGuardFunc();
        }
      );
    });
  }

  private showRoleSwitcherDialog(): Observable<UserRole> {
    const subject = new Subject<any>();

    const roles = { roles: [UserRole.User, UserRole.SuperUser] } as XpoLtlRoleSwitcherData;
    const dialogRef = this.dialog.open(XpoLtlRoleSwitcherComponent, { data: roles, disableClose: true });
    dialogRef.afterClosed().subscribe((role: string) => {
      subject.next(UserRoleHelper.toEnum(role));
      subject.complete();
    });

    return subject.asObservable();
  }

  private showNotAuthorizedDialog() {
    this.dialog.open(NotAuthorizedComponent, { disableClose: true });
  }
}
