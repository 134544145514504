import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { DeliveryShipmentSearchRecord } from '@xpo-ltl/sdk-cityoperations';
import {
  DeliveryQualifierCdPipe,
  ProFormatterPipe,
  XpoLtlServiceCentersService,
  XpoLtlTimeService,
} from '@xpo/ngx-ltl';
import { AgGridEvent, GridOptions, RowNode, SelectionChangedEvent } from 'ag-grid-community';
import {
  bind as _bind,
  filter as _filter,
  first as _first,
  forEach as _forEach,
  get as _get,
  groupBy as _groupBy,
  has as _has,
  map as _map,
  size as _size,
  some as _some,
} from 'lodash';
import { Observable } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { PndDialogService } from '../../../../../core';
import { PndStoreState, UnassignedDeliveriesStoreActions, UnassignedDeliveriesStoreSelectors } from '../../../../store';
import { SpecialServicesService, StopWindowService, UnassignedDeliveriesService } from '../../../shared';
import { RowHoverManager } from '../../../shared/classes/row-hover-manager';
import {
  consigneeToId,
  EventItem,
  UnassignedDeliveryIdentifier,
} from '../../../shared/interfaces/event-item.interface';
import { BillClassCdPipe } from '../../../shared/pipes/bill-class-cd.pipe';
import { UserPreferencesService } from '../../../shared/services/user-preferences.service';
import { UnassignedDeliveriesBase } from '../unassigned-deliveries-base';
import { UnassignedDeliveriesBoard } from '../unassigned-deliveries-header/unassigned-deliveries-header.component';
import {
  StopDetailInfo,
  UnassignedDeliveriesStopsBoardViewTemplate,
} from './unassigned-deliveries-stops-board-view-template.model';
import {
  UnassignedDeliveriesStopsDataSource,
  UnassignedStopGridItem,
} from './unassigned-deliveries-stops-data-source.service';

@Component({
  selector: 'pnd-unassigned-deliveries-stops-board',
  templateUrl: './unassigned-deliveries-stops.component.html',
  styleUrls: ['./unassigned-deliveries-stops.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [UnassignedDeliveriesStopsDataSource],
})
export class UnassignedDeliveriesStopsComponent extends UnassignedDeliveriesBase implements OnInit, OnDestroy {
  @Input() selectedBoard$: Observable<UnassignedDeliveriesBoard>;
  @Input() set groupSelected$(value: Observable<boolean>) {
    this.setGroupSelected(value);
  }
  @Output() showBoard = new EventEmitter<UnassignedDeliveriesBoard>();
  @Output() groupSelectedChange = new EventEmitter<boolean>();

  viewTemplates: UnassignedDeliveriesStopsBoardViewTemplate[];

  private detailGridOptions: GridOptions;
  private detailGridRowHoverManager: RowHoverManager<DeliveryShipmentSearchRecord>;

  private consigneeToGridEventMap: Map<string, AgGridEvent[]> = new Map();

  constructor(
    protected dataSource: UnassignedDeliveriesStopsDataSource,
    protected pndStore$: Store<PndStoreState.State>,
    protected dialog: MatDialog,
    protected pndDialogService: PndDialogService,
    protected userPreferencesService: UserPreferencesService,
    protected unassignedDeliveriesService: UnassignedDeliveriesService,
    protected timeService: XpoLtlTimeService,

    private serviceCentersService: XpoLtlServiceCentersService,
    private stopWindowService: StopWindowService,
    private specialServicesService: SpecialServicesService,
    private locationApiService: LocationApiService,

    private billClassCdPipe: BillClassCdPipe,
    private proFormatterPipe: ProFormatterPipe,
    protected deliveryQualifierCdPipe: DeliveryQualifierCdPipe
  ) {
    super(dataSource, pndStore$, dialog, pndDialogService, userPreferencesService, unassignedDeliveriesService);

    const unassignedStopsTemplate = new UnassignedDeliveriesStopsBoardViewTemplate(
      this.pndStore$,
      this.locationApiService,
      this.serviceCentersService,
      this.deliveryQualifierCdPipe,
      this.proFormatterPipe,
      this.timeService,
      this.stopWindowService,
      this.billClassCdPipe,
      this.specialServicesService,

      this.onAddressClick.bind(this),
      this.onShowShipmentDetails.bind(this),
      this.onDetailGridReady.bind(this),
      this.onDetailSelectonChangedFromBoard.bind(this)
    );

    this.viewTemplates = [unassignedStopsTemplate];
    this.gridOptions = unassignedStopsTemplate.getGridOptions();

    this.detailGridOptions = this.gridOptions.detailCellRendererParams.detailGridOptions;
  }

  ngOnInit() {
    super.ngOnInit();

    // listen for grid hover changes
    this.detailGridRowHoverManager = new RowHoverManager(
      this.detailGridOptions,
      _bind(this.setFocusedRow, this),
      _bind(this.clearFocusedRow, this)
    );
  }

  ngOnDestroy() {
    this.detailGridRowHoverManager.destroy();

    super.ngOnDestroy();
  }

  // --------------------
  // #region Stop rows

  /**
   * Handle selection changes from the Board
   */
  protected onSelectonChangedFromBoard(selectedInBoard: UnassignedDeliveryIdentifier[]) {
    // NOTE: the selectedInBoard here is ONLY the master-level selection.
    this.pndStore$
      .select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected)
      .pipe(take(1))
      .subscribe((storeSelection) => {
        const currentSelection = _map(storeSelection, (item) => item.id);

        // remove all selections that are no longer selected (this includes shipments as well)
        const newSelection = _filter(currentSelection, (selItem) => {
          const selItemId = consigneeToId(selItem);
          return _some(selectedInBoard, (boardItem) => consigneeToId(boardItem) === selItemId);
        });

        // add any stops that are not in the current selection to the new selection (full stops only)
        _forEach(selectedInBoard, (boardItem) => {
          const boardItemId = consigneeToId(boardItem);
          if (!_some(newSelection, (selItem) => boardItemId === consigneeToId(selItem))) {
            newSelection.push(boardItem);
          }
        });

        // compare current slection with board selection. If different, then update the store
        if (!this.areListsEqual(newSelection, currentSelection)) {
          // selections differ, so update store with latest from Board
          this.pndStore$.dispatch(
            new UnassignedDeliveriesStoreActions.SetSelectedDeliveries({
              selectedDeliveries: _map(newSelection, (item) => this.toEventItem(item)),
            })
          );
        }
      });
  }

  /**
   * Update the selected state of each row node based on the selectedItems
   */
  protected onSelectionChangedFromStore(selectedInStore: UnassignedDeliveryIdentifier[]) {
    if (!this.gridApi) {
      // grid is not ready, so skip this
      return;
    }

    // Group selectedShipments by their Stops
    const selectedItemsByStops = _groupBy(selectedInStore, (item) => consigneeToId(item));

    this.gridApi.forEachNode((node: RowNode) => {
      const stopData = node.data as UnassignedStopGridItem;

      // if the stop is in the selected list, then it is selected
      const stopId = consigneeToId(stopData);

      // set selection state for the node
      const isSelected = _has(selectedItemsByStops, stopId);
      node.setSelected(isSelected, false, true);

      if (node.expanded) {
        const detailGridId = `detail_${stopId}`;
        const selectedItemsAtStop = _get(selectedItemsByStops, stopId, []);
        this.onDetailSelectionChangedFromStore(detailGridId, selectedItemsAtStop);
        this.gridApi.redrawRows({ rowNodes: [node] });
      }
    });
  }

  protected onFocusChangedFromStore(focusedItem: UnassignedDeliveryIdentifier) {
    this.focusedItem = focusedItem;
    if (this.gridApi) {
      this.gridApi.redrawRows({ rowNodes: this.focusedRows });

      this.focusedRows = this.findNodesForStop(consigneeToId(focusedItem));
      this.gridApi.redrawRows({ rowNodes: this.focusedRows });
      this.gridApi.ensureNodeVisible(_first(this.focusedRows));
    }
  }

  // #endregion

  // --------------------
  // #region Shipment rows

  /**
   * Handle when a Shipment detail grid is displayed for a Stop
   */
  onDetailGridReady(event: AgGridEvent): void {
    // update the initial checked state to match selected shipments
    this.pndStore$
      .select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected)
      .pipe(take(1))
      .subscribe((selectedItems: EventItem<UnassignedDeliveryIdentifier>[]) => {
        const stopDetailInfo = _get(event.api, 'context.stopDetailInfo') as StopDetailInfo;

        // if (!this.consigneeToGridEventMap.has(stopDetailInfo.detailGridId)) {
        // NOTE: we need the event passed in every time onDetailGridReady gets called so
        // we can safely check/uncheck detail items.
        // At this point we are not cleaning up the map - this will need to be addressed.
        // This currently solves the issue where details were not getting selected correctly.
        if (!this.consigneeToGridEventMap.has(stopDetailInfo.detailGridId)) {
          this.consigneeToGridEventMap.set(stopDetailInfo.detailGridId, []);
        }
        const events = this.consigneeToGridEventMap.get(stopDetailInfo.detailGridId);
        events.push(event);
        this.consigneeToGridEventMap.set(stopDetailInfo.detailGridId, events);
        // }

        this.onDetailSelectionChangedFromStore(
          stopDetailInfo.detailGridId,
          _map(selectedItems, (item) => item.id)
        );
      });
  }

  /**
   * Handle user selecting/deselecting a shipment in the detail grid for a stop
   */
  protected onDetailSelectonChangedFromBoard(event: SelectionChangedEvent) {
    // get the current selection from the Store
    this.pndStore$
      .select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected)
      .pipe(take(1))
      .subscribe((storeSelection: EventItem<UnassignedDeliveryIdentifier>[]) => {
        const currentSelection = _map(storeSelection, (item) => item.id);

        const selectedShipments = event.api.getSelectedRows() as UnassignedDeliveryIdentifier[];

        // first, remove all items for this stop from the current selection
        const stopDetailInfo = _get(event.api, 'context.stopDetailInfo') as StopDetailInfo;
        const newSelection = _filter(
          currentSelection,
          (item) => consigneeToId(item) !== stopDetailInfo.consigneeId
        ) as UnassignedDeliveryIdentifier[];

        // add selections for this stop to the new Selections
        if (_size(selectedShipments) === _size(stopDetailInfo.shipmentInstIds)) {
          // entire stop has been selected.
          const stopId = { consignee: { ...selectedShipments[0].consignee } } as UnassignedDeliveryIdentifier;
          newSelection.push(stopId);
        } else {
          // add each selected shipment to the newSelection list
          _forEach(selectedShipments, (selShip) => {
            newSelection.push(selShip);
          });
        }

        if (!this.areListsEqual(newSelection, currentSelection)) {
          // selections differ, so update store with new selection
          this.pndStore$.dispatch(
            new UnassignedDeliveriesStoreActions.SetSelectedDeliveries({
              selectedDeliveries: _map(newSelection, (item) => this.toEventItem(item)),
            })
          );
        }
      });
  }

  /**
   * Updae the selectiion state in the detail grid for the passed selection
   */
  protected onDetailSelectionChangedFromStore(
    // detailGridInfo: DetailGridInfo,
    stopId: string,
    selectedItems: UnassignedDeliveryIdentifier[]
  ) {
    // NOTE: see comments in onDetailGridReady...

    // if (!detailGridInfo) {
    //   return;
    // }

    // if any of the selectedItems does NOT have a shipment, then the entire Stop is selected
    const isEntireStopSelected = _some(selectedItems, (item) => !item.shipmentInstId);

    const events = this.consigneeToGridEventMap.get(stopId);
    events.forEach((event) => {
      // go through all of the rows in the detail grid and update selection state
      event.api.forEachNode((shipRowNode: RowNode) => {
        // is this shipment selected?
        const shipment = shipRowNode.data as UnassignedDeliveryIdentifier;
        const selected =
          isEntireStopSelected || _some(selectedItems, (selShip) => selShip.shipmentInstId === shipment.shipmentInstId);

        // shipRowNode.selectThisNode(selected);
        shipRowNode.setSelected(selected, false, true);
      });
    });
  }

  // #endregion
}
