import { AgmSnazzyInfoWindow } from '@agm/snazzy-info-window';
import { Component, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'app-offset-snazzy-info-window',
  template: '<div #outerWrapper><div #viewContainer></div></div><ng-content></ng-content>',
})
export class OffsetSnazzyInfoWindow extends AgmSnazzyInfoWindow implements AfterViewInit {
  @Input() offset: {
    top: string;
    left: string;
  };

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this._snazzyInfoWindowInitialized.then(() => {
      this._nativeSnazzyInfoWindow._opts.offset = this.offset;
    });
  }
}
