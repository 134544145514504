import { XpoSelectFilter, XpoSelectFilterParams } from '@xpo-ltl/ngx-ltl-board';
import { DeliveryQualifierCd } from '@xpo-ltl/sdk-common';

export class DeliveryQualifierFilter extends XpoSelectFilter {
  constructor(field: string) {
    super(<XpoSelectFilterParams>{
      field: field,
      label: 'Exceptions',
      options: [
        { title: 'Appt', code: DeliveryQualifierCd.APPT },
        { title: 'Trapped', code: DeliveryQualifierCd.TRAPPED },
        { title: 'Transfer', code: DeliveryQualifierCd.TRANSFER },
        { title: 'Cartage', code: DeliveryQualifierCd.CARTAGE },
        { title: 'Attempted', code: DeliveryQualifierCd.ATTEMPTED },
        { title: 'All Short', code: DeliveryQualifierCd.ALL_SHORT },
        { title: 'Part Short', code: DeliveryQualifierCd.PARTIAL_SHORT },
        { title: 'Returned', code: DeliveryQualifierCd.RETURNED },
        { title: 'Refused', code: DeliveryQualifierCd.REFUSED },
        { title: 'Damaged', code: DeliveryQualifierCd.DAMAGED },
        { title: 'Customs', code: DeliveryQualifierCd.HOLD_FOR_CUSTOMS },
        { title: 'Over', code: DeliveryQualifierCd.OVER_SHPMT },
        { title: 'Undeliverable', code: DeliveryQualifierCd.UNDELIVERED },
        { title: 'No Exception', code: DeliveryQualifierCd.NOT_APPLICABLE },
      ],
    });
  }
}
