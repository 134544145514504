import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { XpoGMapBoardModule } from '@xpo-ltl/ngx-ltl-board-gmaps';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoButtonModule, XpoCardModule } from '@xpo-ltl/ngx-ltl-core';
import { AgGridModule } from 'ag-grid-angular';
import { pndFrameworkComponentsDeclarations } from '../../shared';
import { InboundPlanningSharedModule } from '../../shared/shared.module';
import { TripPlanningComponent } from '../trip-planning/trip-planning.component';

@NgModule({
  declarations: [TripPlanningComponent],
  exports: [TripPlanningComponent],
  entryComponents: [TripPlanningComponent],
  imports: [
    InboundPlanningSharedModule,
    AgGridModule.withComponents(pndFrameworkComponentsDeclarations),
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    XpoButtonModule,
    XpoAgGridBoardModule,
    XpoCardModule,
    XpoGMapBoardModule,
  ],
})
export class InboundPlanningTripPlanningModule {}
