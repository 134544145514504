export enum RoutePlanningGridHeaders {
  ROW_SELECTED = 'rowSelected',
  TIMELINE = 'Timeline',
  ROUTE_NAME = 'Route Name',
  SIC = 'SIC',
  SPECIAL_SERVICES = 'Special Services',
  EARLIEST_SHIPMENT_ETA = 'Earliest Shipment ETA',
  LATEST_SHIPMENT_ETA = 'Latest Shipment ETA',
  BILLS = 'Bills',
  MM = 'MM',
  WEIGHT = 'Weight (lbs.)',
  DOOR = 'Door#',
  TRAILER = 'Trailer#',
  DOCK_LOCATION = 'Dock Location',
  NEAREST_DOOR = 'Nearest Door',
}
