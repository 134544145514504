import { Action } from '@ngrx/store';
import { UnassignedPickup } from '@xpo-ltl/sdk-cityoperations';
import { EventItem, UnassignedPickupIdentifier } from '../../inbound-planning/shared/interfaces/event-item.interface';
import { GlobalFilterMapCoordinate } from '../../inbound-planning/shared/models/global-filter-criteria.model';
import { UnassignedPickupsSearchCriteria } from './unassigned-pickups-search-criteria.interface';

export enum ActionTypes {
  setSearchCriteria = 'unassignedPickups.setSearchCriteria',
  setUnassignedPickups = 'unassignedPickups.setUnassignedPickups',
  setSelectedUnassignedPickups = 'unassignedPickups.setSelectedUnassignedPickups',
  setFocusedUnassignedPickup = 'unassignedPickups.setFocusedUnassignedPickup',
  setBoundingSearchArea = 'unassignedPickups.setBoundingSearchArea',
  refresh = 'unassignedPickups.refresh',
}

export class SetSearchCriteriaAction implements Action {
  readonly type = ActionTypes.setSearchCriteria;
  constructor(public payload: { criteria: UnassignedPickupsSearchCriteria }) {}
}

export class SetSelectedUnassignedPickupsAction implements Action {
  readonly type = ActionTypes.setSelectedUnassignedPickups;
  constructor(public payload: { selectedPickups: EventItem<UnassignedPickupIdentifier>[] }) {}
}

export class SetFocusedUnassignedPickupAction implements Action {
  readonly type = ActionTypes.setFocusedUnassignedPickup;
  constructor(public payload: { focusedPickup: EventItem<UnassignedPickupIdentifier> }) {}
}

export class SetBoundingSearchAreaAction implements Action {
  readonly type = ActionTypes.setBoundingSearchArea;
  constructor(public payload: { boundingSearchArea: GlobalFilterMapCoordinate[] }) {}
}

export class Refresh implements Action {
  readonly type = ActionTypes.refresh;
  constructor() {}
}

export type Actions =
  | SetSearchCriteriaAction
  | SetSelectedUnassignedPickupsAction
  | SetFocusedUnassignedPickupAction
  | SetBoundingSearchAreaAction
  | Refresh;
