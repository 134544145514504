import { Injectable } from '@angular/core';
import { forEach as _forEach, size as _size, isEmpty as _isEmpty } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { UnassignedStop, Route } from '@xpo-ltl/sdk-cityoperations';
import { RouteStopsGridItem } from '../../components/route-stops/models/route-stops-grid-item.model';

interface GrandTotals {
  totalBillCount?: number;
  totalWeightLbs: number;
  motorizedPiecesCount: number;
}

export interface UnassignedDeliveriesGrandTotals extends GrandTotals {
  totalShipmentsCount: number;
  loosePcsCnt: number;
  totalCubePercentage: number;
}

export interface RoutePlanningGrandTotals extends GrandTotals {}

export interface TripsGrandTotals extends GrandTotals {
  tripStopCount: number;
}

export interface RouteStopsGrandTotals {
  totalBills: number;
  totalMotorMoves: number;
  totalWeight: number;
  totalCube: number;
}

export interface PlanningRouteShipmentsGrandTotals extends GrandTotals {
  totalShipmentsCount: number;
  totalCubePercentage: number;
}

@Injectable({ providedIn: 'root' })
export class GrandTotalService {
  private unassignedDeliveriesTotals = new BehaviorSubject<UnassignedDeliveriesGrandTotals[]>([]);
  readonly unassignedDeliveriesTotals$ = this.unassignedDeliveriesTotals.asObservable();

  private routePlanningDeliveriesTotals = new BehaviorSubject<RoutePlanningGrandTotals[]>([]);
  readonly routePlanningDeliveriesTotals$ = this.routePlanningDeliveriesTotals.asObservable();

  private tripsTotals = new BehaviorSubject<TripsGrandTotals[]>([]);
  readonly tripsTotals$ = this.tripsTotals.asObservable();

  private routeStopsTotals = new BehaviorSubject<RouteStopsGrandTotals[]>([]);
  readonly routeStopsTotals$ = this.routeStopsTotals.asObservable();

  private planningRouteShipmentsTotals = new BehaviorSubject<PlanningRouteShipmentsGrandTotals[]>([]);
  readonly planningRouteShipmentsTotals$ = this.planningRouteShipmentsTotals.asObservable();

  constructor() {}

  updateUnassignedDeliveriesGrandTotals(unassignedDeliveries: UnassignedStop[]) {
    const totals: UnassignedDeliveriesGrandTotals = {
      totalShipmentsCount: 0,
      totalWeightLbs: 0,
      motorizedPiecesCount: 0,
      loosePcsCnt: 0,
      totalCubePercentage: 0,
    };

    _forEach(unassignedDeliveries, (delivery) => {
      totals.totalShipmentsCount += delivery.totalShipmentsCount;
      totals.totalWeightLbs += delivery.totalWeightLbs;
      totals.motorizedPiecesCount += delivery.motorizedPiecesCount;
      totals.loosePcsCnt += delivery.loosePcsCnt;
      totals.totalCubePercentage += delivery.totalCubePercentage;
    });

    totals.totalCubePercentage = +totals.totalCubePercentage.toFixed(2);

    this.unassignedDeliveriesTotals.next([totals]);
  }

  updatePlanningRoutesGrandTotals(routes: Route[]) {
    const totals: RoutePlanningGrandTotals = {
      totalBillCount: 0,
      totalWeightLbs: 0,
      motorizedPiecesCount: 0,
    };

    _forEach(routes, (route) => {
      totals.totalBillCount += route.totalBillCount;
      totals.totalWeightLbs += route.totalWeightCount;
      totals.motorizedPiecesCount += route.totalMmCount;
    });

    this.routePlanningDeliveriesTotals.next([totals]);
  }

  updateTripsGrandTotals(trips) {
    const totals: TripsGrandTotals = {
      totalBillCount: 0,
      totalWeightLbs: 0,
      motorizedPiecesCount: 0,
      tripStopCount: 0,
    };

    _forEach(trips, (trip) => {
      totals.totalBillCount += trip.totalBillCount;
      totals.totalWeightLbs += trip.totalWeightCount;
      totals.motorizedPiecesCount += trip.totalMmCount;
      totals.tripStopCount += trip.tripStopCount;
    });

    this.tripsTotals.next([totals]);
  }

  updateRouteStopsGrandTotals(stops: RouteStopsGridItem[]) {
    const totals: RouteStopsGrandTotals = {
      totalBills: 0,
      totalWeight: 0,
      totalMotorMoves: 0,
      totalCube: 0,
    };

    _forEach(stops, (stop) => {
      totals.totalBills += stop.totalBills;
      totals.totalWeight += stop.totalWeight;
      totals.totalMotorMoves += stop.totalMotorMoves;
      totals.totalCube += stop.totalCube;
    });

    totals.totalCube = +totals.totalCube.toFixed(2);

    this.routeStopsTotals.next([totals]);
  }

  updatePlanningRouteShipmentsGrandTotals(shipments) {
    const totals: PlanningRouteShipmentsGrandTotals = {
      totalShipmentsCount: 0,
      totalWeightLbs: 0,
      motorizedPiecesCount: 0,
      totalCubePercentage: 0,
    };

    _forEach(shipments, (shipment) => {
      totals.totalShipmentsCount += shipment.totalShipmentsCount;
      totals.totalWeightLbs += shipment.totalWeightLbs;
      totals.motorizedPiecesCount += shipment.motorizedPiecesCount;
      totals.totalCubePercentage += shipment.totalCubePercentage;
    });

    totals.totalCubePercentage = +totals.totalCubePercentage.toFixed(2);

    this.planningRouteShipmentsTotals.next([totals]);
  }

  isUnassignedDeliveriesGrandTotalsEmpty(totals: UnassignedDeliveriesGrandTotals[]): boolean {
    return (
      _isEmpty(totals) ||
      (totals.length === 1 &&
        totals[0].loosePcsCnt === 0 &&
        totals[0].motorizedPiecesCount === 0 &&
        totals[0].totalCubePercentage === 0 &&
        totals[0].totalShipmentsCount === 0 &&
        totals[0].totalWeightLbs === 0)
    );
  }

  isRoutePlanningGrandTotalsEmpty(totals: RoutePlanningGrandTotals[]): boolean {
    return (
      _isEmpty(totals) ||
      (totals.length === 1 &&
        totals[0].totalBillCount === 0 &&
        totals[0].motorizedPiecesCount === 0 &&
        totals[0].totalWeightLbs === 0)
    );
  }

  isPlanningRouteShipmentGrandTotalsEmpty(totals: PlanningRouteShipmentsGrandTotals[]): boolean {
    return (
      _isEmpty(totals) ||
      (totals.length === 1 &&
        totals[0].totalShipmentsCount === 0 &&
        totals[0].totalCubePercentage === 0 &&
        totals[0].motorizedPiecesCount === 0 &&
        totals[0].totalWeightLbs === 0)
    );
  }

  isRouteStopsGrandTotalsEmpty(totals: RouteStopsGrandTotals[]): boolean {
    return (
      _isEmpty(totals) ||
      (totals.length === 1 &&
        totals[0].totalBills === 0 &&
        totals[0].totalCube === 0 &&
        totals[0].totalMotorMoves === 0 &&
        totals[0].totalWeight === 0)
    );
  }

  isTripsGrandTotalsEmpty(totals: TripsGrandTotals[]): boolean {
    return (
      _isEmpty(totals) ||
      (totals.length === 1 &&
        totals[0].motorizedPiecesCount === 0 &&
        totals[0].totalBillCount === 0 &&
        totals[0].totalWeightLbs === 0 &&
        totals[0].tripStopCount === 0)
    );
  }
}
