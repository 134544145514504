import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatButtonModule,
  MatMenuModule,
  MatCheckboxModule,
} from '@angular/material';
import { XpoGMapBoardModule } from '@xpo-ltl/ngx-ltl-board-gmaps';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoButtonModule, XpoCardModule } from '@xpo-ltl/ngx-ltl-core';
import { AgGridModule } from 'ag-grid-angular';
import { pndFrameworkComponentsDeclarations } from '../../shared/components/frameworkComponents';
import { InboundPlanningSharedModule } from '../../shared/shared.module';
import { RoutePlanningComponent } from './route-planning.component';

@NgModule({
  declarations: [RoutePlanningComponent],
  exports: [RoutePlanningComponent],
  entryComponents: [RoutePlanningComponent],
  imports: [
    AgGridModule.withComponents(pndFrameworkComponentsDeclarations),
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    XpoButtonModule,
    XpoAgGridBoardModule,
    XpoCardModule,
    XpoGMapBoardModule,
    InboundPlanningSharedModule,
  ],
})
export class RoutePlanningModule {}
