import { Component, ViewEncapsulation, Input } from '@angular/core';
import { Metric } from '@xpo-ltl/sdk-cityoperations';
import { isEmpty as _isEmpty, get as _get } from 'lodash';

@Component({
  selector: 'pnd-metric-bar',
  templateUrl: 'metric-bar.component.html',
  styleUrls: ['metric-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MetricBarComponent {
  @Input()
  metrics: Metric[];

  selectedItem: Metric;
  selectedIndex: number;
}
