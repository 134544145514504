import { Injectable, OnDestroy } from '@angular/core';
import { Activity } from '@xpo-ltl/sdk-cityoperations';
import { TripNodeStatusCd, TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { filter as _filter, map as _map, size as _size, uniq as _uniq } from 'lodash';
import { UnassignedDeliveryMapMarker } from '../../../inbound-planning/shared/models/markers/unassigned-delivery-map-marker.model';
import { UnassignedPickupMapMarker } from '../../../inbound-planning/shared/models/markers/unassigned-pickup-map-marker.model';
import { UnassignedDeliveryShipmentsMapMarker } from '../models';
import { MapMarkerIcon } from '../models/markers/map-marker-icon.model';
import { TripStatusEnum } from '../models/trip-status.enum';
import { ActivityCdPipe } from '../pipes/activity-cd.pipe';
import { DELIVERY_COLOR } from './stop-colors';

export enum DefaultIconColors {
  defaultBorderColor = 'black',
  defaultHighlightedColor = 'rgb(204,204,0)',
}

export interface BuildIconOptions {
  isSelected: boolean;
  width: number;
  height: number;
  parsedColor: string;
  sequenceNumber?: number;
  stopType: string;
  tripStatus: TripStatusEnum | TripNodeStatusCd;
}

@Injectable({ providedIn: 'root' })
export class MapMarkersService implements OnDestroy {
  readonly ZOOM_LEVEL: number = 7; // levels lower then this are rendered as dots
  private unsubscriber = new Unsubscriber();

  constructor(private activityCdPipe: ActivityCdPipe) {}

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  /**
   * Return the 2 character abbreviation for the Activities provided
   * (eg, PU, DL, MX, etc.)
   */
  activityCdForActivities(activities: Activity[]): string {
    const activityCodes = this.activityCdsForActivities(activities);

    // if there is more then 1 activity, then it is a Mixed (MX) stop
    const activityCd = _size(activityCodes) > 1 ? 'MX' : this.activityCdPipe.transform(activityCodes[0]);
    return activityCd;
  }

  /**
   * Return the 2 character abbreviation for the Activities provided
   * (eg, PU, DL, MX, etc.)
   */
  activityCdsForActivities(activities: Activity[]): TripNodeActivityCd[] {
    const activityCodes = _uniq(
      _map(
        _filter(activities, (activity: Activity) => activity.routeShipment),
        (activity: Activity) => activity.tripNodeActivity.activityCd
      )
    );
    return activityCodes;
  }

  /**
   * Resize unassigned stop markers
   * @param mapMarker
   * @param zoomLevel
   * @param anchor
   */
  updateUnassignedStopMarker(
    mapMarker: UnassignedDeliveryMapMarker | UnassignedPickupMapMarker | UnassignedDeliveryShipmentsMapMarker,
    zoomLevel: number,
    anchor?: google.maps.Point
  ) {
    mapMarker.icon = this.getMarkerIconUnassigned(
      mapMarker.markerInfo.stopType,
      zoomLevel,
      mapMarker.isSelected || mapMarker.isFocused,
      mapMarker.markerInfo.color,
      anchor
    );
  }

  /**
   * Get marker icon for service center
   */
  getServiceCenterIconPath(): MapMarkerIcon {
    const anchor: google.maps.Point = new google.maps.Point(20, 20);
    const url: string = `data:image/svg+xml;utf-8,
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <defs>
        <filter id="shadowSic" x="-16" y="-16" width="32" height="32">
          <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
          <feOffset dx="0" dy="0" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <circle cx="20" cy="20" r="11.5" fill="white" stroke="black" stroke-width="1" filter="url(%23shadowSic)" />
      <polygon
        transform='rotate(0 20 20)'
        points="10,26.5 15,30.5 16,31 24,31 25,30.5 30,26.5 20,40"
        fill="black"
        stroke="black"
        stroke-width="0.1"
      />

      <g transform="translate(10,10.5)">
        <path
          fill="%23CC0000"
          d="M4.8,9.8l2.4,3.6H4.8l-0.7-1.1c-0.2-0.4-0.3-0.6-0.5-1c-0.1,0.3-0.3,0.7-0.5,1l-0.6,1.1H0l2.5-3.6L0,6.6h2.4
              c0,0,1.1,1.5,1.3,1.9c0.1-0.3,1.1-1.9,1.1-1.9h2.5L4.8,9.8z"
        />
        <path
          fill="%23CC0000"
          d="M10.1,6.6c1.1,0,1.6,0.1,2.1,0.4c0.6,0.4,1,1.1,1,2c0,0.7-0.2,1.2-0.7,1.7c-0.4,0.4-1,0.6-1.9,0.6H9.4v2.1H7.3
              V6.6H10.1z M9.4,9.7h0.8c0.6,0,0.9-0.2,0.9-0.7c0-0.5-0.3-0.7-0.9-0.7H9.4V9.7z"
        />
        <path
          fill="%23CC0000"
          d="M20,10c0,2.1-1.4,3.5-3.4,3.5c-2.1,0-3.5-1.4-3.5-3.5c0-2.1,1.4-3.6,3.5-3.6C18.6,6.4,20,7.9,20,10 M15.3,10
              c0,1.2,0.4,1.9,1.3,1.9c0.8,0,1.3-0.7,1.3-2c0-1.1-0.4-1.8-1.3-1.8C15.7,8.1,15.3,8.8,15.3,10"
        />
      </g>
    </svg>`;

    return new MapMarkerIcon(url, anchor);
  }

  getGeoAreaIcon(geoAreaName: string): MapMarkerIcon {
    const fillColor = this.getStringToHslColor(geoAreaName, 100, 30);
    return new MapMarkerIcon(
      `data:image/svg+xml;utf-8,
      <svg class="geo-area-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="50" viewBox="0 0 100 50">
        <g>
          <text font-family="Roboto-Bold, Roboto, sans-serif" font-size="14" font-weight="bold" fill="${fillColor}">
          <tspan x="50" y="35" text-anchor="middle">${geoAreaName}</tspan>
          </text>
        </g>
      </svg>`,
      null
    );
  }

  /**
   * Get driver marker icon with initials and route color
   * @param initials Driver initials
   * @param routeInstId route id
   */
  getDriverIconWithInitials(initials: string, color?: string): MapMarkerIcon {
    const parsedColor = this.getParsedColor(color || DefaultIconColors.defaultBorderColor);
    const width: number = 28;
    const height: number = 38;
    const anchor: google.maps.Point = new google.maps.Point(width / 2, height / 2);
    const url = `data:image/svg+xml;utf-8,
      <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
        <defs>
            <filter id="shadowDriver" x="-14" y="-19" width="${width}" height="${height}">
                <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
                <feOffset dx="0" dy="0" />
                <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
            </filter>
        </defs>
        <rect x="3" y="3" rx="1" ry="1" width="22" height="22" fill="white" stroke="black" stroke-width="1" filter="url(%23shadowDriver)" />
        <text x="14" y="18.5" text-anchor="middle" fill="${parsedColor}" font-family="Roboto-Bold, Roboto, sans-serif"
          font-size="12" font-weight="bold">
          ${initials}
        </text>
        <polygon  points="4,25.5 24,25.5 14,35.5" fill="black" stroke="black" stroke-width="1" filter="url(%23shadowDriver)" />
      </svg>
    `;

    return new MapMarkerIcon(url, anchor);
  }

  /**
   * Get marker icon for unassigned stops
   * @param stopType DL, MX, MS, etc.
   * @param zoomLevel from 0 to 20. 0 means 'World' and 20 'Zoomed in'
   * @param isSelected if the marker is selected or hovered
   * @param iconColor icon color (hexadecimal)
   */
  getMarkerIconUnassignedSvg(
    stopType: string = 'DL',
    zoomLevel: number = 10,
    isSelected: boolean = false,
    iconColor: string = DELIVERY_COLOR
  ): { svg: string; width: number; height: number } {
    let width: number = 0;
    let height: number = 0;
    const color: string = this.getParsedColor(iconColor); // `%23${iconColor}`;
    let svg: string = '';

    if (zoomLevel >= this.ZOOM_LEVEL) {
      width = 38;
      height = 38;
      if (isSelected) {
        svg += `
          <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
          <defs>
              <filter id="shadowUn4" x="-19" y="-19" width="${width}" height="${height}">
              <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
              <feOffset dx="0" dy="0" />
              <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
              </feMerge>
              </filter>
          </defs>
          <circle cx="19" cy="19" r="13" fill="white" stroke="${color}" stroke-width="5.5" filter="url(%23shadowUn4)" />
          <circle cx="19" cy="19" r="9" fill="${color}" />
          <text x="50%" y="23" text-anchor="middle" fill="white" font-family="Roboto-Bold, Roboto, sans-serif"
              font-size="11" font-weight="bold">
              ${stopType}
          </text>
          </svg>`;
      } else {
        svg += `
          <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
          <defs>
              <filter id="shadowUn3" x="-19" y="-19" width="${width}" height="${height}">
              <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
              <feOffset dx="0" dy="0" />
              <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
              </feMerge>
              </filter>
          </defs>
          <circle cx="19" cy="19" r="9.5" fill="${color}" stroke="white" stroke-width="1" filter="url(%23shadowUn3)" />
          <text x="50%" y="23" text-anchor="middle" fill="white" font-family="Roboto-Bold, Roboto, sans-serif"
              font-size="11" font-weight="bold">
              ${stopType}
          </text>
          </svg>`;
      }
    } else {
      width = 18;
      height = 18;

      if (isSelected) {
        svg += `
          <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
          <defs>
              <filter id="shadowUn2" x="-9" y="-9" width="${width}" height="${height}">
              <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
              <feOffset dx="0" dy="0" />
              <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
              </feMerge>
              </filter>
          </defs>
          <circle cx="9" cy="9" r="5.5" fill="${color}" stroke="white" stroke-width="1" filter="url(%23shadowUn2)" />
          </svg>`;
      } else {
        svg += `
          <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
          <defs>
              <filter id="shadowUn1" x="-9" y="-9" width="${width}" height="${height}">
              <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
              <feOffset dx="0" dy="0" />
              <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
              </feMerge>
              </filter>
          </defs>
          <circle cx="9" cy="9" r="4" fill="white" stroke="${color}" stroke-width="2" filter="url(%23shadowUn1)" />
        </svg>`;
      }
    }

    return { svg, width, height };
  }

  getMarkerIconUnassigned(
    stopType: string = 'DL',
    zoomLevel: number = 10,
    isSelected: boolean = false,
    iconColor: string = DELIVERY_COLOR,
    anchor?: google.maps.Point
  ): MapMarkerIcon {
    const icon = this.getMarkerIconUnassignedSvg(stopType, zoomLevel, isSelected, iconColor);
    const url: string = 'data:image/svg+xml;utf-8,' + icon.svg;

    if (!anchor) {
      anchor = new google.maps.Point(icon.width / 2, icon.height / 2);
    }

    return new MapMarkerIcon(url, anchor);
  }

  /**
   * Get marker icon for assigned stops
   * @param stopType DL, MX, MS, etc.
   * @param zoomLevel from 0 to 20. 0 means 'World' and 20 'Zoomed in'
   * @param isSelected if the marker is selected or hovered
   * @param routeInstId route id
   * @param sequenceNumber Optional. Sequence number
   */
  getMarkerIconAssigned(
    stopType: string,
    zoomLevel: number,
    isSelected: boolean,
    color: string,
    tripStatus: TripStatusEnum | TripNodeStatusCd,
    sequenceNumber?: number,
    anchor?: google.maps.Point
  ): MapMarkerIcon {
    let url: string = 'data:image/svg+xml;utf-8,';

    let width: number = 0;
    let height: number = 0;

    const parsedColor = this.getParsedColor(color);

    if (zoomLevel >= this.ZOOM_LEVEL) {
      // Render the full icon
      width = 38;
      height = 38;

      url += this.buildIcon({ isSelected, width, height, parsedColor, sequenceNumber, stopType, tripStatus });
    } else {
      width = 18;
      height = 18;

      if (isSelected) {
        url += `
          <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
          <style type="text/css">
            .st0{transform:scale(0.4) translate(10px, 10px);}
          </style>
          <defs>
              <filter id="shadowAs2" x="-9" y="-9" width="${width}" height="${height}">
              <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
              <feOffset dx="0" dy="0" />
              <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
              </feMerge>
              </filter>
          </defs>
          <circle cx="9" cy="9" r="9.5" fill="${parsedColor}" stroke="white" stroke-width="1" filter="url(%23shadowAs2)" />
          ${
            tripStatus === 'Completed'
              ? '<path class="st0" fill="white" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>'
              : ''
          }
          </svg>`;
      } else {
        url += `
          <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
          <style type="text/css">
            .st0{transform:scale(0.4) translate(10px, 10px);}
          </style>
          <defs>
              <filter id="shadowAs1" x="-9" y="-9" width="${width}" height="${height}">
              <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
              <feOffset dx="0" dy="0" />
              <feMerge>
                  <feMergeNode />
                  <feMergeNode in="SourceGraphic" />
              </feMerge>
              </filter>
          </defs>
          <circle cx="9" cy="9" r="4.5" fill="${parsedColor}" stroke="white" stroke-width="1" filter="url(%23shadowAs1)" />
          ${
            tripStatus === 'Completed'
              ? '<path class="st0" fill="white" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>'
              : ''
          }
          </svg>`;
      }
    }

    if (!anchor) {
      anchor = new google.maps.Point(width / 2, height / 2);
    }

    return new MapMarkerIcon(url, anchor);
  }

  /**
   * Get marker icon for clusters
   * @param stopType DL, PU, etc.
   * @param clusteredMarkers Number of clustered markers
   */
  getClusterMarkerIcon(markerType: string, clusteredMarkers: number): MapMarkerIcon {
    let url: string = 'data:image/svg+xml;utf-8,';
    const width: number = 40;
    const height: number = 40;

    url += `
      <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
        <defs>
            <filter id="shadowCluster" x="-15" y="-15" width="${width}" height="${height}">
            <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
            <feOffset dx="0" dy="0" />
            <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
            </feMerge>
            </filter>
        </defs>
        <circle cx="${width / 2}" cy="${height /
      2}" r="16" fill="white" stroke="black" stroke-width="1" filter="url(%23shadowCluster)" />
        <text x="${width /
          2}" y="24" text-anchor="middle" fill="black" font-family="Roboto-Bold, Roboto, sans-serif" font-size="11" font-weight="bold">
            ${clusteredMarkers} ${markerType}
        </text>
      </svg>
    `;

    return new MapMarkerIcon(url, new google.maps.Point(width / 2, height / 2));
  }

  private convertToDecimal(hexColor: string): number {
    return parseInt(hexColor, 16);
  }

  /**
   * Returns an RGB type color
   * @param color hexa type color (#AABBCC)
   */
  private getParsedColor(color: string): string {
    return !color
      ? DefaultIconColors.defaultBorderColor
      : color === DefaultIconColors.defaultBorderColor
      ? color
      : `rgb(${this.convertToDecimal(color.slice(1, 3))}, ${this.convertToDecimal(
          color.slice(3, 5)
        )}, ${this.convertToDecimal(color.slice(5, 7))})`;
  }

  private buildIcon(options: BuildIconOptions): string {
    const icon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${options.width}" height="${options.height}">
      <style type="text/css">
        .st0{transform:scale(0.8) translate(8px, 9px);}
      </style>
      <defs>
        <filter id="${options.isSelected ? 'shadowSq3' : 'shadowSq1'}" x="-19" y="-19" width="${
      options.width
    }" height="${options.height}">
          <feGaussianBlur in="SourceAlpha" stdDeviation="1.5" />
          <feOffset dx="0" dy="0" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <circle cx="19" cy="19" r="${options.isSelected ? '13' : '9.5'}" fill="${
      options.isSelected ? 'white' : options.parsedColor
    }" stroke="${options.isSelected ? options.parsedColor : 'white'}" stroke-width="${
      options.isSelected ? '5.5' : '1'
    }" filter="url(%23shadowSq${options.isSelected ? '3' : '1'})" />
      ${options.isSelected ? '<circle cx="19" cy="19" r="9" fill="' + options.parsedColor + '" />' : ''}
      ${this.getIconTripStatusTemplate(options.tripStatus, options.stopType)}
      ${this.getIconSequenceNumber(options.parsedColor, options.sequenceNumber, options.isSelected)}
    </svg>`;

    return icon;
  }

  getIconTripStatusTemplate(status: TripStatusEnum | TripNodeStatusCd, stopType: string): string {
    if (status === TripNodeStatusCd.COMPLETED || status === TripStatusEnum.Completed) {
      return '<path class="st0" fill="white" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>';
    }

    return `<text x="50%" y="23" text-anchor="middle" fill="white" font-family="Roboto-Bold, Roboto, sans-serif" font-size="11" font-weight="bold">
              ${stopType}
            </text>`;
  }

  getIconSequenceNumber(parsedColor: string, sequenceNumber: number, isSelected: boolean) {
    return `<circle cx="23" cy="${isSelected ? 8 : 9}" r="${isSelected ? 8 : 7}" fill="${parsedColor}" />
            <text x="23" y="12" text-anchor="middle" fill="white" font-family="Roboto-Bold, Roboto, sans-serif" font-size="9" font-weight="bold">
              <tspan>${sequenceNumber ? sequenceNumber : ''}</tspan>
            </text>`;
  }

  getStringToHslColor(str: string, s: number, l: number) {
    // str = input string, s = saturation or color, l = lightness of color
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      // tslint:disable-next-line:no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }
}
