import { Component, Input } from '@angular/core';
import { MapSplitPanelComponent } from './map-split-panel.component';

@Component({
  template: `
    <div style="padding:8px">
      <h1>{{ data.title }}</h1>
      <h4>inside shipments panel component!!</h4>
    </div>
  `,
})
export class ShipmentsPanelComponent implements MapSplitPanelComponent {
  data: any;
}
