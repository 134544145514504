import { DriverLocation } from '@xpo-ltl/sdk-cityoperations';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { get as _get } from 'lodash';
import { PndRouteUtils } from '../../../../shared/route-utils';

export interface DriverRouteInfo {
  id: string;
  feetAvailable: number;
  routeInstId: number;
}

export class DriverMapMarkerInfo {
  readonly driverId: string;
  readonly driverName: string;
  readonly phone: string;
  readonly lastUpdated: string;
  readonly remainingStops: string;
  readonly lastStopType: string;
  readonly timeOfCompletion: string;
  readonly speed: string;
  readonly routes: DriverRouteInfo[];
  readonly tripInstId: number;
  readonly routeInstId: number;

  constructor(driverLocation: DriverLocation, timeService: XpoLtlTimeService) {
    this.driverId =
      _get(driverLocation, 'tripDriver.dsrEmployeeId', '') || _get(driverLocation, 'currentLocation.employeeId', '');
    this.driverName = _get(driverLocation, 'tripDriver.dsrName', '');
    this.phone = _get(driverLocation, 'employeeMobileDevice.devicePhoneNbr', '');
    this.lastStopType = _get(driverLocation, 'trip.lastStopTypeCd', 'N/A');
    this.remainingStops = (
      _get(driverLocation, 'trip.stopCount', '') - _get(driverLocation, 'trip.stopCompletedCount', '')
    ).toString();
    this.speed = _get(driverLocation, 'currentLocation.speed', '');
    this.tripInstId = _get(driverLocation, 'trip.tripInstId', '');

    const lastStopArrivalDateTime = _get(driverLocation, 'trip.lastStopArrivalDateTime', '');
    if (lastStopArrivalDateTime) {
      this.timeOfCompletion = timeService.formatDate(
        lastStopArrivalDateTime,
        'LT',
        _get(driverLocation, 'currentLocation.domicileSic', '')
      );
    }

    const eventDateTime = _get(driverLocation, 'currentLocation.eventDateTime', '');
    if (eventDateTime) {
      this.lastUpdated = timeService.formatDate(
        eventDateTime,
        'lll',
        _get(driverLocation, 'currentLocation.domicileSic', '')
      );
    }

    const routesToShow: DriverRouteInfo[] = [];

    if (driverLocation.routeEquipment && driverLocation.tripDriver) {
      for (const routeEquipment of driverLocation.routeEquipment) {
        try {
          routesToShow.push({
            id: PndRouteUtils.getRouteId(routeEquipment.route),
            feetAvailable: routeEquipment.tripEquipment.lengthUOMFeetNbr,
            routeInstId: routeEquipment.route.routeInstId,
          });
        } catch (error) {}
      }
    }

    this.routes = routesToShow;
  }
}
