import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export enum AutosequenceDialogEnum {
  CANCEL = 'CANCEL',
  USE_SUGGESTION = 'USE_SUGGESTION',
}

@Component({
  selector: 'app-autosequence-dialog',
  templateUrl: './autosequence-dialog.component.html',
  styleUrls: ['./autosequence-dialog.component.scss'],
})
export class AutosequenceDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AutosequenceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}

  ngOnInit() {}

  onCancel() {
    this.dialogRef.close(AutosequenceDialogEnum.CANCEL);
  }

  onUseSuggestion() {
    this.dialogRef.close(AutosequenceDialogEnum.USE_SUGGESTION);
  }
}
