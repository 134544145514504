import { Route, Stop } from '@xpo-ltl/sdk-cityoperations';
import { XrtChangedDocument } from '@xpo/ngx-xrt';
import { ResequencingRouteData } from '../../inbound-planning/components/route-balancing';
import { RouteStopsGridItem } from '../../inbound-planning/components/route-stops/models/route-stops-grid-item.model';
import { StoreSourcesEnum } from '../../inbound-planning/shared/enums/store-sources.enum';
import {
  AssignedStopIdentifier,
  EventItem,
  PlanningRouteShipmentIdentifier,
  RouteIdentifier,
} from '../../inbound-planning/shared/interfaces/event-item.interface';
import { AssignedStopMapMarker } from '../../inbound-planning/shared/models/markers/assigned-stop-map-marker.model';
import { NumberToValueMap } from '../number-to-value-map';
import { RoutesSearchCriteria } from './routes-search-criteria.interface';

export const StateKey = 'routesState';

export interface RouteError {
  routeName: string;
  error: string;
}

export interface State {
  // Misc
  readonly showCompletedStops: boolean;
  readonly updateUnassignedDeliveriesGrid: { source: StoreSourcesEnum; date: Date };
  readonly routeMarkers: AssignedStopMapMarker[];

  // Trip Routes
  readonly selectedRoutes: Route[];
  readonly clickedRouteStop: RouteStopsGridItem;

  readonly focusedRoute: EventItem<RouteIdentifier>;
  readonly focusedStopForSelectedRoute: EventItem<AssignedStopIdentifier>;

  readonly stopsForSelectedRoutes: NumberToValueMap<Stop[]>;
  readonly stopsSelectedForSelectedRoutes: EventItem<AssignedStopIdentifier>[];

  // TODO - move to Route Balancing
  readonly resequencedRouteData: { [routeInstId: number]: ResequencingRouteData };

  // Planning Routes
  readonly searchCriteria: RoutesSearchCriteria;
  readonly changedRoutes: XrtChangedDocument[];
  readonly planningRoutesLastUpdate: Date;

  readonly focusedPlanningRoute: EventItem<RouteIdentifier>;
  readonly focusedPlanningRouteShipment: EventItem<PlanningRouteShipmentIdentifier>;

  readonly selectedPlanningRoutes: number[]; // list of selected routeInstId
  readonly stopsForSelectedPlanningRoutesLastUpdate: Date;

  readonly selectedPlanningRoutesShipments: PlanningRouteShipmentIdentifier[];
}

export const initialState: State = {
  // Misc
  showCompletedStops: true,
  updateUnassignedDeliveriesGrid: { source: undefined, date: undefined },
  routeMarkers: [],

  // Trip Routes
  selectedRoutes: [],
  clickedRouteStop: undefined,

  stopsForSelectedRoutes: {},
  stopsSelectedForSelectedRoutes: [],

  focusedRoute: { id: undefined, source: undefined },
  focusedStopForSelectedRoute: { id: undefined, source: undefined },

  // TODO - move to Route Balancing
  resequencedRouteData: {},

  // Planning Routes
  searchCriteria: {},
  changedRoutes: [],
  planningRoutesLastUpdate: new Date(0),

  focusedPlanningRoute: { id: undefined, source: undefined },
  focusedPlanningRouteShipment: { id: undefined, source: undefined },

  selectedPlanningRoutes: [],
  stopsForSelectedPlanningRoutesLastUpdate: new Date(0),

  selectedPlanningRoutesShipments: [],
};
