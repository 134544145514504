import { Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { XpoDateRangeFilter, XpoQuickSearchFilter } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardView, XpoAgGridBoardViewConfig, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoAgGridFormatters } from '@xpo-ltl/ngx-ltl-grid';
import { DeliveryShipmentSearchRecord, UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { ShipmentSpecialServiceSummary } from '@xpo-ltl/sdk-common';
import { ProFormatterPipe, XpoLtlServiceCentersService, XpoLtlTimeService } from '@xpo/ngx-ltl';
import { GridOptions, RowEvent, RowSelectedEvent, SelectionChangedEvent, ValueGetterParams } from 'ag-grid-community';
import { AgEvent } from 'ag-grid-community/dist/lib/events';
import { find as _find, first as _first, get as _get, map as _map, result as _result, set as _set } from 'lodash';
import * as moment from 'moment-timezone';
import { PndStoreState } from '../../../../store';
import {
  DeliveryQualifierCdPipe,
  pndFrameworkComponents,
  SpecialServicesService,
  StopWindowService,
} from '../../../shared';
import { TimeFilter } from '../../../shared/components/time-filter/time-filter';
import {
  BillClassFilter,
  DeliveryQualifierFilter,
  DestinationSicFilter,
  SicFilter,
  SpecialServiceFilter,
} from '../../../shared/filters';
import { PlanningProfilesFilter } from '../../../shared/filters/planning-profiles-filter';
import { consigneeToId } from '../../../shared/interfaces/event-item.interface';
import { OnAnyInputErrorStateMatcher } from '../../../shared/matchers/on-any-input-error-state.matcher';
import { BillClassCdPipe } from '../../../shared/pipes/bill-class-cd.pipe';
import { TimeUtil } from '../../../shared/services/time-format.util';
import { UnassignedDeliveriesGridFields } from '../enums/unassigned-deliveries-grid-fields.enum';
import { UnassignedDeliveriesGridHeaders } from '../enums/unassigned-deliveries-grid-headers.enum';

const DEFAULT_DETAIL_ROW_HEIGHT = 39;

// information required by detail grids about the Stop they belong to
export interface StopDetailInfo {
  consigneeId: string;
  detailGridId: string;
  shipmentInstIds: number[];
}

export class UnassignedDeliveriesStopsBoardViewTemplate extends XpoAgGridBoardViewTemplate {
  static templateId = 'UnassignedDeliveriesStopsBoardViewTemplate';
  currentPlanDate: Date = new Date();

  constructor(
    private pndStore$: Store<PndStoreState.State>, // required for PlanningProfilesFilter
    private locationService: LocationApiService, // needed for DestinationSicFilter,
    private serviceCentersService: XpoLtlServiceCentersService, // needed for SicFilter
    private deliveryQualifierCdPipe: DeliveryQualifierCdPipe,
    private proFormatterPipe: ProFormatterPipe,
    private timeService: XpoLtlTimeService,
    private stopWindowService: StopWindowService,
    private billClassCdPipe: BillClassCdPipe,
    private specialServicesService: SpecialServicesService,

    public onAddressClicked: (shipment: DeliveryShipmentSearchRecord) => void,
    public onShipmentClicked: (shipment: DeliveryShipmentSearchRecord) => void,
    public onDetailGridReady: (event: AgEvent) => void,
    public onDetailSelectionChanged: (event: SelectionChangedEvent) => void
  ) {
    super({
      id: UnassignedDeliveriesStopsBoardViewTemplate.templateId,
      name: 'Unassigned Deliveries',
      keyField: 'consigneeId',
      availableColumns: [
        {
          headerName: UnassignedDeliveriesGridHeaders.ROW_SELECTED,
          colId: UnassignedDeliveriesGridFields.ROW_SELECTED,
          headerValueGetter: () => '',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          width: 50,
          sortable: true,
          pinnedRowCellRenderer: 'totalTextCellRenderer',
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const nodeASel = nodeA.isSelected() ? 1 : 0;
            const nodeBSel = nodeB.isSelected() ? 1 : 0;
            return nodeASel - nodeBSel;
          },
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.BILLS,
          field: UnassignedDeliveriesGridFields.BILLS,
          width: 56,
          cellClass: 'pnd-UnassignedDeliveries__BillsCell',
          cellRenderer: 'agGroupCellRenderer',
          type: 'numericColumn',
          valueFormatter: XpoAgGridFormatters.formatNumber,
          valueGetter: (params: ValueGetterParams) => {
            const stop = params.data as UnassignedStop;
            return stop.totalShipmentsCount;
          },
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.CONSIGNEE,
          field: UnassignedDeliveriesGridFields.CONSIGNEE,
          width: 275,
          valueGetter: (params: ValueGetterParams) => {
            const stop = params.data as UnassignedStop;
            return `${_get(stop, 'consignee.name1', '')} ${_get(stop, 'consignee.name2', '')}`;
          },
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.ADDRESS,
          field: UnassignedDeliveriesGridFields.ADDRESS,
          width: 250,
          cellRenderer: 'actionLinkCellRenderer',
          cellRendererParams: { onClick: onAddressClicked },
          valueGetter: (params: ValueGetterParams) => {
            const stop = params.data as UnassignedStop;
            return _get(stop, 'consignee.addressLine1', '');
          },
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.CITY,
          field: UnassignedDeliveriesGridFields.CITY,
          width: 175,
          valueGetter: (params: ValueGetterParams) => {
            const stop = params.data as UnassignedStop;
            return _get(stop, 'consignee.cityName', '');
          },
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.ZIP_CODE,
          field: UnassignedDeliveriesGridFields.ZIP_CODE,
          width: 90,
          valueGetter: (params: ValueGetterParams) => {
            const stop = params.data as UnassignedStop;
            return _get(stop, 'consignee.postalCd', '');
          },
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.SPECIAL_SERVICES,
          field: UnassignedDeliveriesGridFields.SPECIAL_SERVICES,
          width: 170,
          valueGetter: (params: ValueGetterParams) => {
            const stop = params.data as UnassignedStop;
            const specialServicesSummary = stop.specialServiceSummary;
            const specialServices = this.specialServicesService.getSpecialServicesForSummary(specialServicesSummary);
            return specialServices;
          },
          cellRenderer: 'specialServicesCellRenderer',
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.WEIGHT,
          field: UnassignedDeliveriesGridFields.WEIGHT,
          width: 110,
          type: 'numericColumn',
          valueFormatter: XpoAgGridFormatters.formatNumber,
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.MM,
          field: UnassignedDeliveriesGridFields.MM,
          valueFormatter: XpoAgGridFormatters.formatNumber,
          width: 80,
          type: 'numericColumn',
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.LP,
          field: UnassignedDeliveriesGridFields.LP,
          valueFormatter: XpoAgGridFormatters.formatNumber,
          width: 50,
          type: 'numericColumn',
        },
        // Not yet implememnted
        // { headerName: 'Footprint', field: 'footprintPercentage', width: 100, type: 'numericColumn' },
        {
          headerName: UnassignedDeliveriesGridHeaders.CUBE,
          field: UnassignedDeliveriesGridFields.CUBE,
          valueFormatter: XpoAgGridFormatters.formatNumber,
          width: 80,
          type: 'numericColumn',
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.DESTINATION_SIC,
          field: UnassignedDeliveriesGridFields.DESTINATION_SIC,
          width: 125,
          valueGetter: (params: ValueGetterParams) => {
            const stop = params.data as UnassignedStop;
            const shipment: DeliveryShipmentSearchRecord = _first(stop.deliveryShipments);
            return _get(shipment, 'destinationSicCd');
          },
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.DELIVERY_WINDOW_TYPE,
          field: UnassignedDeliveriesGridFields.DELIVERY_WINDOW_TYPE,
          width: 160,
          valueGetter: (params: ValueGetterParams) => {
            const stop = params.data as UnassignedStop;
            return this.stopWindowService.getStopWindowType(stop.stopWindow);
          },
          comparator: (curr, prev) => {
            const sort = `${curr}`.localeCompare(prev);
            return sort;
          },
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.DELIVERY_WINDOW_TIME,
          field: UnassignedDeliveriesGridFields.DELIVERY_WINDOW_TIME,
          width: 160,
          cellRenderer: 'deliverWindowTimeCellRenderer',
          valueGetter: (params: ValueGetterParams) => {
            const stop = params.data as UnassignedStop;
            return this.stopWindowService.getStopWindowTime(stop.stopWindow, false);
          },
          comparator: (curr, prev) => {
            const current = this.startHourToInt(curr);
            const previous = this.startHourToInt(prev);
            return current - previous;
          },
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.DELIVERY_WINDOW_DATE,
          field: UnassignedDeliveriesGridFields.DELIVERY_WINDOW_DATE,
          width: 160,
          valueGetter: (params: ValueGetterParams) => {
            const stop = params.data as UnassignedStop;
            return this.stopWindowService.getStopWindowDate(stop.stopWindow);
          },
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.EARLIEST_ETA,
          field: UnassignedDeliveriesGridFields.EARLIEST_ETA,
          width: 120,
          valueGetter: (params: ValueGetterParams) => {
            const stop = params.data as UnassignedStop;
            const earliestEta = stop.earliestDestSicEta;
            if (earliestEta) {
              return this.timeService.formatDate(
                earliestEta,
                'MM-DD HH:mm',
                _get(_first(stop.deliveryShipments), 'destinationSicCd')
              );
            } else {
              return undefined;
            }
          },
        },
        {
          headerName: UnassignedDeliveriesGridHeaders.LATEST_ETA,
          field: UnassignedDeliveriesGridFields.LATEST_ETA,
          width: 120,
          valueGetter: (params: ValueGetterParams) => {
            const stop = params.data as UnassignedStop;
            const latestEta = stop.latestDestSicEta;
            if (latestEta) {
              return this.timeService.formatDate(
                latestEta,
                'MM-DD HH:mm',
                _get(_first(stop.deliveryShipments), 'destinationSicCd')
              );
            } else {
              return undefined;
            }
          },
        },
      ],
      availableFilters: [
        new XpoQuickSearchFilter('q', { disableAutofocus: true }),
        new PlanningProfilesFilter('profileId', 'profileName', pndStore$),
        new TimeFilter({
          field: UnassignedDeliveriesGridFields.SCHEDULED_ETA,
          label: 'ETA (HH:MM)',
          validators: [Validators.pattern(new RegExp(TimeUtil.TimeFormatValidatorRegExpWithAnchors))],
          title: `ETA (HH:MM)`,
          inputDebounceTime: 800,
          matcher: new OnAnyInputErrorStateMatcher(),
        }),
        new SpecialServiceFilter('specialServiceSummary'),
        new SicFilter('currentSicCd', 'Current SIC', serviceCentersService),
        new DestinationSicFilter('destinationSicCd', 'Destination SIC', pndStore$, locationService),
        new XpoDateRangeFilter('estimatedDeliveryDate', 'Service Date'),
        new DeliveryQualifierFilter('deliveryQualifiers'),
        new BillClassFilter('billClass'),
      ],
      allowAdditional: true,
    });
  }

  createView(config: XpoAgGridBoardViewConfig): XpoAgGridBoardView {
    // This is called everytime the view is changed.

    if (config.id === 'currentSic') {
      const sicCd = _result(config, 'criteria.currentSicCd[0]');

      const sicFilter: SicFilter = _find(
        this.availableFilters,
        (xpoFilter) => xpoFilter instanceof SicFilter
      ) as SicFilter;

      if (sicFilter) {
        sicFilter.setSelectedOptions([{ title: sicCd, code: sicCd }]);
      }
    }

    this.assignUniqueId(config);
    return new XpoAgGridBoardView(this, {
      ...config,
      templateId: this.id,
    });
  }

  private startHourToInt(hour: string): number {
    const match = hour ? Number(hour.match(/[^-]*/i)[0].replace(':', '.')) : 24;
    return isNaN(match) ? 24 : match;
  }

  private setCurrentPlanDate(planDate: Date): void {
    this.currentPlanDate = planDate;
  }

  // #region Stop Grid
  getGridOptions(): GridOptions {
    return {
      frameworkComponents: pndFrameworkComponents,
      headerHeight: 40,
      defaultColDef: { resizable: true },
      embedFullWidthRows: true,
      getRowHeight: (params) => this.getRowHeight(params),
      pinnedBottomRowData: [],

      masterDetail: true,
      detailCellRendererParams: this.getDetailCellRendererParams(),
    };
  }

  private getRowHeight(params) {
    // Default row height
    let rowHeight = 39;
    if (params.node && params.node.detail) {
      let subRowHeight = 39;
      const subHeaderHeight = 39 + 2;
      const subScrollBarHeight = 17;
      const subStopWindows = _get(params.node, 'data.stopWindow');

      if (subStopWindows && subStopWindows.length > 1) {
        subRowHeight = subRowHeight + 4 * subStopWindows.length;
      }

      const detailRows = _get(params.node, 'data.deliveryShipments');
      if (detailRows) {
        const detailRowsHeight = detailRows.length * subRowHeight;
        rowHeight = detailRowsHeight + subHeaderHeight + subScrollBarHeight;
      }
    } else {
      const stopWindows = _get(params.data, 'stopWindow');
      if (stopWindows && stopWindows.length > 1) {
        rowHeight = rowHeight + 4 * stopWindows.length;
      }
    }
    return rowHeight;
  }

  // #endregion

  // #region Detail Grid

  private getDetailCellRendererParams() {
    return {
      detailGridOptions: {
        rowClass: 'pnd-UnassignedDeliveries__DetailsRow',
        rowSelection: 'multiple',
        rowMultiSelectWithClick: true,
        frameworkComponents: pndFrameworkComponents,
        headerHeight: DEFAULT_DETAIL_ROW_HEIGHT,
        defaultColDef: { resizable: true },
        getRowNodeId: (data: DeliveryShipmentSearchRecord) => {
          return data.shipmentInstId;
        },
        columnDefs: [
          {
            headerName: UnassignedDeliveriesGridHeaders.ROW_SELECTED,
            headerValueGetter: () => '',
            checkboxSelection: true,
            width: 40,
          },
          {
            headerName: UnassignedDeliveriesGridHeaders.PRO,
            field: 'proNbr',
            width: 95,
            cellRenderer: 'actionLinkCellRenderer',
            cellRendererParams: { onClick: this.onShipmentClicked },
            valueFormatter: (params) => this.proFormatterPipe.transform(params.data.proNbr, 10),
          },
          {
            headerName: UnassignedDeliveriesGridHeaders.BILL_CLASS,
            width: 75,
            valueFormatter: (params) => {
              return this.billClassCdPipe.transform(_get(params, 'data.billClassCd'));
            },
            tooltipValueGetter: (params) => {
              return this.billClassCdPipe.transformToTooltip(_get(params, 'data.billClassCd'));
            },
          },
          {
            headerName: UnassignedDeliveriesGridHeaders.LP,
            field: 'loosePcsCnt',
            width: 60,
            type: 'numericColumn',
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: UnassignedDeliveriesGridHeaders.SPECIAL_SERVICES,
            field: 'specialServiceSummary',
            width: 170,
            valueGetter: (params) => {
              // the UnassignedPickup.specialServiceSummary is actually an array of ShipmentSpecialServiceCd
              const specialServicesSummary = _get(
                params,
                'data.specialServiceSummary'
              ) as ShipmentSpecialServiceSummary[];
              const specialServices = this.specialServicesService.getSpecialServicesForSummary(specialServicesSummary);
              return specialServices;
            },
            cellRenderer: 'specialServicesCellRenderer',
          },
          {
            headerName: UnassignedDeliveriesGridHeaders.WEIGHT,
            field: 'totalWeightLbs',
            width: 110,
            type: 'numericColumn',
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: UnassignedDeliveriesGridHeaders.MM,
            field: 'motorizedPiecesCount',
            width: 70,
            type: 'numericColumn',
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: UnassignedDeliveriesGridHeaders.CUBE,
            field: 'totalCubePercentage',
            width: 70,
            type: 'numericColumn',
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: UnassignedDeliveriesGridHeaders.SERVICE_DATE,
            field: 'estimatedDeliveryDate',
            width: 100,
            valueFormatter: (params) => {
              const shipment: DeliveryShipmentSearchRecord = params.data as DeliveryShipmentSearchRecord;
              const estimatedDeliveryDate = _get(shipment, 'estimatedDeliveryDate');
              if (estimatedDeliveryDate) {
                return moment(estimatedDeliveryDate).format('MM-DD');
              } else {
                return '';
              }
            },
          },
          {
            headerName: UnassignedDeliveriesGridHeaders.DESTINATION_ETA,
            field: 'destSicEta',
            width: 120,
            valueFormatter: (params) => {
              const shipment: DeliveryShipmentSearchRecord = params.data as DeliveryShipmentSearchRecord;
              return this.timeService.formatDate(shipment.destSicEta, 'MM-DD HH:mm', shipment.destinationSicCd);
            },
          },
          { headerName: UnassignedDeliveriesGridHeaders.DESTINATION_SIC, field: 'destinationSicCd', width: 125 },
          { headerName: UnassignedDeliveriesGridHeaders.CURRENT_SIC, field: 'shipmentLocationSicCd', width: 125 },
          { headerName: UnassignedDeliveriesGridHeaders.TRAILER, field: 'currentTrailer', width: 125 },
          { headerName: UnassignedDeliveriesGridHeaders.TRAILER_SIC, field: 'trailerCurrSicCd', width: 125 },
          {
            headerName: UnassignedDeliveriesGridHeaders.EXCEPTIONS,
            field: 'deliveryExceptions',
            width: 120,
            valueFormatter: (params) => this.deliveryQualifierCdPipe.transform(params.data.deliveryQualifierCd),
          },
          {
            headerName: UnassignedDeliveriesGridHeaders.SCHEDULED_ETA,
            field: 'scheduleETA',
            width: 120,
            valueFormatter: (params) => {
              const shipment: DeliveryShipmentSearchRecord = params.data as DeliveryShipmentSearchRecord;
              return this.timeService.formatDate(
                shipment.scheduleETA,
                'MM-DD HH:mm',
                shipment.scheduleDestinationSicCd
              );
            },
          },
          // Not yet Implemented
          {
            headerName: UnassignedDeliveriesGridHeaders.SCHEDULED_DESTINATION,
            field: 'scheduleDestinationSicCd',
            width: 130,
            cellStyle: { textAlign: 'center' },
          },
          {
            headerName: UnassignedDeliveriesGridHeaders.DELIVERY_WINDOW_TYPE,
            field: 'deliveryWindowType',
            width: 160,
            valueGetter: (params) => {
              const stopWindow = _get(params.data, 'stopWindow');
              return this.stopWindowService.getStopWindowType(stopWindow);
            },
          },
          {
            headerName: UnassignedDeliveriesGridHeaders.DELIVERY_WINDOW_TIME,
            field: 'deliveryWindowTime',
            width: 150,
            cellRenderer: 'deliverWindowTimeCellRenderer',
          },
          {
            headerName: UnassignedDeliveriesGridHeaders.DELIVERY_WINDOW_DATE,
            field: 'deliveryWindowDate',
            width: 170,
            valueGetter: (params) => {
              const stopWindow = _get(params.data, 'stopWindow');
              return this.stopWindowService.getStopWindowDate(stopWindow);
            },
          },
        ],

        getRowHeight: (event: RowEvent) => this.getDetailRowHeight(event),
        onSelectionChanged: (event: RowSelectedEvent) => this.onDetailSelectionChanged(event),
        onFirstDataRendered: (event: AgEvent) => {
          this.onDetailGridReady(event);
        },
      },
      getDetailRowData: (params) => {
        // we need to store the id of the detail grid so we can fetch the DetailGridInfo
        // in the other callbacks
        const stop = params.data as UnassignedStop;
        const consigneeId = consigneeToId(stop);
        const detailGridId = `detail_${consigneeId}`;

        const stopDetails: StopDetailInfo = {
          detailGridId,
          consigneeId,
          shipmentInstIds: _map(
            stop.deliveryShipments,
            (shipment: DeliveryShipmentSearchRecord) => shipment.shipmentInstId
          ),
        };

        _set(params.node.detailGridInfo.api.context, 'stopDetailInfo', stopDetails);
        params.successCallback(params.data.deliveryShipments);
      },
    };
  }

  private getDetailRowHeight(event: RowEvent) {
    let totalRowHeight = DEFAULT_DETAIL_ROW_HEIGHT;
    const stopWindows = _get(event.data, 'stopWindow');
    if (stopWindows && stopWindows.length > 1) {
      totalRowHeight = totalRowHeight + 4 * stopWindows.length;
    }
    return totalRowHeight;
  }

  // //#endregion
}
