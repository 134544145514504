import { GoogleMapsAPIWrapper } from '@agm/core';
import * as mapTypes from '@agm/core/services/google-maps-types';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Stop, UnassignedPickup, UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { Unsubscriber } from '@xpo/ngx-ltl';
import {
  cloneDeep as _cloneDeep,
  find as _find,
  forEach as _forEach,
  forOwn as _forOwn,
  get as _get,
  pick as _pick,
  union as _union,
  flatten as _flatten,
} from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  PndStoreState,
  RoutesStoreActions,
  RoutesStoreSelectors,
  UnassignedDeliveriesStoreActions,
  UnassignedDeliveriesStoreSelectors,
  UnassignedPickupsStoreActions,
  UnassignedPickupsStoreSelectors,
} from '../../../../../../store';
import { NumberToValueMap } from '../../../../../../store/number-to-value-map';
import { StoreSourcesEnum } from '../../../../enums/store-sources.enum';
import {
  areStopsEqual,
  AssignedStopIdentifier,
  AssignedStopPositionIdentifier,
  EventItem,
  PlanningRouteShipmentIdentifier,
  UnassignedDeliveryIdentifier,
  UnassignedPickupIdentifier,
  consigneeToId,
} from '../../../../interfaces/event-item.interface';
import { MapToolbarService, UnassignedPickupsService, UnassignedDeliveriesService } from '../../../../services';
import { PlanningRoutesCacheService } from '../../../../services/planning-routes-cache.service';

@Component({
  selector: 'pnd-polygon-selection',
  templateUrl: './polygon-selection.component.html',
  styleUrls: ['./polygon-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolygonSelectionComponent implements OnDestroy, AfterViewInit {
  @Input()
  showUnassignedDeliveries: Observable<boolean>;
  @Input()
  showUnassignedPickups: boolean;
  @Output()
  toggleDrawMode = new EventEmitter<boolean>();
  unassignedDeliveriesMarkers: EventItem<UnassignedDeliveryIdentifier>[] = [];
  unassignedPickupsMarkers: EventItem<UnassignedPickupIdentifier>[] = [];
  planningRouteShipmentsMarkers: EventItem<PlanningRouteShipmentIdentifier>[] = [];
  assignedStopsMarkers: EventItem<AssignedStopPositionIdentifier>[] = [];
  inDrawMode = false;

  private disabledSubject = new BehaviorSubject<boolean>(false);
  readonly disabled$ = this.disabledSubject.asObservable();

  private googleMap: google.maps.Map;
  private polygonDrawnEventListener: google.maps.MapsEventListener;
  private unassignedDeliveriesMarkersPositions = new Map<google.maps.LatLng, EventItem<UnassignedDeliveryIdentifier>>();
  private unassignedPickupsMarkersPositions = new Map<google.maps.LatLng, EventItem<UnassignedPickupIdentifier>>();
  private planningRouteShipmentsMarkersPositions = new Map<
    google.maps.LatLng,
    EventItem<PlanningRouteShipmentIdentifier>
  >();
  private assignedStopMarkersPositions = new Map<google.maps.LatLng, EventItem<AssignedStopPositionIdentifier>>();
  private newShape: google.maps.Polyline;
  private matchedUnassignedDelveries: EventItem<UnassignedDeliveryIdentifier>[] = [];
  private matchedUnassignedPickups: EventItem<UnassignedPickupIdentifier>[] = [];
  private matchedPlanningRouteShipments: EventItem<PlanningRouteShipmentIdentifier>[] = [];
  private matchedAssignedRouteStops: EventItem<AssignedStopPositionIdentifier>[] = [];
  private mouseMoveDrawer: mapTypes.MapsEventListener;
  private mouseUpDrawer: mapTypes.MapsEventListener;
  private mouseDownDrawer: mapTypes.MapsEventListener;
  private unsubscriber = new Unsubscriber();
  private drawing: boolean;

  constructor(
    private googleMapsApi: GoogleMapsAPIWrapper,
    private mapToolbarService: MapToolbarService,
    private pndStore$: Store<PndStoreState.State>,
    private unassignedPickupsService: UnassignedPickupsService,
    private planningRoutesCacheService: PlanningRoutesCacheService,
    private unassignedDeliveriesService: UnassignedDeliveriesService
  ) {}

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.inDrawMode) {
      this.togglePolygonDraw();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
    if (this.polygonDrawnEventListener) {
      google.maps.event.removeListener(this.polygonDrawnEventListener);
    }
    if (this.googleMap) {
      google.maps.event.clearListeners(this.googleMap.getDiv(), 'mousedown');
    }
  }

  ngAfterViewInit(): void {
    this.matchedUnassignedDelveries = [];
    this.matchedUnassignedPickups = [];
    this.matchedPlanningRouteShipments = [];
    this.matchedAssignedRouteStops = [];
    this.googleMapsApi.getNativeMap().then((map) => {
      this.googleMap = (map as any) as google.maps.Map;
    });

    this.mapToolbarService.setDrawModeState$.pipe(takeUntil(this.unsubscriber.done)).subscribe((enabled) => {
      if (this.inDrawMode && !enabled) {
        this.togglePolygonDraw();
      }

      this.disabledSubject.next(!enabled);
    });

    this.mapToolbarService.toggleDrawModeOff$.pipe(takeUntil(this.unsubscriber.done)).subscribe(() => {
      if (this.inDrawMode) {
        this.togglePolygonDraw();
      }
    });

    this.subscribeToAllMarkers();
    this.subscribeToSelectedMarkers();
  }

  private subscribeToSelectedMarkers(): void {
    this.pndStore$
      .select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((items: EventItem<UnassignedDeliveryIdentifier>[]) => {
        this.matchedUnassignedDelveries = items;
      });

    this.pndStore$
      .pipe(select(UnassignedPickupsStoreSelectors.unassignedPickupsSelected), takeUntil(this.unsubscriber.done))
      .subscribe((items: EventItem<UnassignedPickupIdentifier>[]) => {
        this.matchedUnassignedPickups = items;
      });

    this.pndStore$
      .pipe(select(RoutesStoreSelectors.selectedStopsForSelectedRoutes), takeUntil(this.unsubscriber.done))
      .subscribe((selectedStops: EventItem<AssignedStopIdentifier>[]) => {
        const matcheds = [];
        this.assignedStopsMarkers.forEach((stop: EventItem<AssignedStopPositionIdentifier>) => {
          if (_find(selectedStops, (selected) => areStopsEqual(selected.id, stop.id))) {
            const addedFromGrid = _cloneDeep(stop);
            addedFromGrid.source = StoreSourcesEnum.ROUTE_STOPS_GRID;
            matcheds.push(addedFromGrid);
          }
        });
        this.matchedAssignedRouteStops = matcheds;
      });
  }

  private getShipmentsData(unassignedStops: UnassignedStop[]): UnassignedStop[] {
    const result = [];
    unassignedStops.map((stop) => {
      stop.deliveryShipments.map((shipment) => {
        result.push({
          consigneeName: `${_get(stop, 'consignee.name1', '')} ${_get(stop, 'consignee.name2', '')}`,
          acctInstId: _get(stop, 'consignee.acctInstId'),
          ...shipment,
          consignee: stop.consignee,
          stopData: {
            ...stop,
            deliveryShipments: [],
          },
        });
      });
    });
    return result;
  }

  private subscribeToAllMarkers(): void {
    this.unassignedDeliveriesService.unassignedDeliveries$
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((unassignedDeliveries: UnassignedStop[]) => {
        const shipmentsData = this.getShipmentsData(unassignedDeliveries);

        this.unassignedDeliveriesMarkers = shipmentsData
          .filter(
            (unassignedStop: UnassignedStop) =>
              unassignedStop.consignee.geoCoordinates.latitude !== 0 &&
              unassignedStop.consignee.geoCoordinates.longitude !== 0
          )
          .map((unassignedStop: UnassignedDeliveryIdentifier) => ({
            id: {
              consignee: _pick(unassignedStop.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
              shipmentInstId: unassignedStop.shipmentInstId ? unassignedStop.shipmentInstId : null,
            },
            source: StoreSourcesEnum.POLYGON_SELECTION,
          }));

        this.unassignedPickupsService.unassignedPickups$
          .pipe(takeUntil(this.unsubscriber.done))
          .subscribe((unassignedPickups: UnassignedPickup[]) => {
            this.unassignedPickupsMarkers = unassignedPickups
              .filter(
                (unassignedPickup: UnassignedPickup) =>
                  unassignedPickup.shipper.latitudeNbr !== 0 && unassignedPickup.shipper.longitudeNbr !== 0
              )
              .map((unassignedPickup: UnassignedPickup) => ({
                id: {
                  pickupInstId: unassignedPickup.pickupInstId,
                  shipper: {
                    latitudeNbr: unassignedPickup.shipper.latitudeNbr,
                    longitudeNbr: unassignedPickup.shipper.longitudeNbr,
                  },
                },
                source: StoreSourcesEnum.POLYGON_SELECTION,
              }));

            this.pndStore$
              .select(RoutesStoreSelectors.stopsForSelectedPlanningRoutesLastUpdate)
              .pipe(
                takeUntil(this.unsubscriber.done),
                switchMap(() => this.pndStore$.select(RoutesStoreSelectors.selectedPlanningRoutes))
              )
              .subscribe((selectedRoutes: number[]) => {
                let markerCandidates: UnassignedStop[] = [];
                _forEach(selectedRoutes, (routeInstId) => {
                  const unassignedStops = this.planningRoutesCacheService.getStopsForRoute(routeInstId);
                  if (unassignedStops && unassignedStops.length > 0) {
                    markerCandidates = [...markerCandidates, ...unassignedStops];
                  }
                });
                this.planningRouteShipmentsMarkers = markerCandidates
                  .filter(
                    (unassignedStop: UnassignedStop) =>
                      _get(unassignedStop, 'consignee.geoCoordinates.latitude', 0) !== 0 &&
                      _get(unassignedStop, 'consignee.geoCoordinates.longitude', 0) !== 0
                  )
                  .map((unassignedStop) => ({
                    id: {
                      routeInstId: _get(unassignedStop, 'deliveryShipments[0].routeInstId'),
                      consignee: _pick(unassignedStop.consignee, [
                        'acctInstId',
                        'name1',
                        'latitudeNbr',
                        'longitudeNbr',
                      ]),
                    },
                    source: StoreSourcesEnum.POLYGON_SELECTION,
                  }));

                this.pndStore$
                  .pipe(
                    select(RoutesStoreSelectors.stopsForSelectedRoutes),
                    debounceTime(500),
                    takeUntil(this.unsubscriber.done)
                  )
                  .subscribe((stopsForSelectedRoutes: NumberToValueMap<Stop[]>) => {
                    let assignedMarkerCandidates: Stop[] = [];
                    _forOwn(stopsForSelectedRoutes, (stops: Stop[]) => {
                      if (stops && stops.length > 0) {
                        assignedMarkerCandidates = [...assignedMarkerCandidates, ...stops];
                      }
                    });
                    this.assignedStopsMarkers = assignedMarkerCandidates
                      .filter(
                        (assignedStop: Stop) =>
                          _get(assignedStop, 'customer.latitudeNbr', 0) !== 0 &&
                          _get(assignedStop, 'customer.longitudeNbr', 0) !== 0
                      )
                      .map((assignedStop: Stop) => ({
                        id: {
                          routeInstId: _get(assignedStop, 'activities[0].routeShipment.routeInstId', null),
                          seqNo: _get(assignedStop, 'tripNode.stopSequenceNbr', null),
                          origSeqNo: _get(assignedStop, 'tripNode.stopSequenceNbr', null),
                          latitudeNbr: assignedStop.customer.latitudeNbr,
                          longitudeNbr: assignedStop.customer.longitudeNbr,
                        },
                        source: StoreSourcesEnum.POLYGON_SELECTION,
                      }));

                    this.initMarkersPositions();
                  });
              });
          });
      });
  }

  togglePolygonDraw(): void {
    this.inDrawMode = !this.inDrawMode;

    this.toggleDrawMode.emit(this.inDrawMode);

    if (this.inDrawMode) {
      this.mouseDownDrawer = google.maps.event.addListener(this.googleMap, 'mousedown', () => {
        if (this.inDrawMode && !this.drawing) {
          this.disableMap();
          this.initPolygonSelection();
        }
      });
    } else {
      google.maps.event.removeListener(this.mouseDownDrawer);
    }
  }

  private initPolygonSelection(): void {
    const polylineOptions: google.maps.PolylineOptions = {
      map: this.googleMap,
      strokeColor: '#304FFE',
      strokeWeight: 3,
      clickable: false,
      zIndex: 1,
      editable: false,
    };

    this.newShape = new google.maps.Polyline(polylineOptions);
    this.mouseMoveDrawer = google.maps.event.addListener(this.googleMap, 'mousemove', ($event) => {
      this.newShape.getPath().push($event.latLng);
    });
    this.mouseUpDrawer = google.maps.event.addListener(this.googleMap, 'mouseup', () => {
      google.maps.event.removeListener(this.mouseMoveDrawer);

      const path = this.newShape.getPath();

      this.newShape.setMap(null);
      const arrayofLatLng = path.getArray();
      const arrayForPolygontoUse = this.reducerDP(arrayofLatLng, 300);

      const polygonOptions: google.maps.PolygonOptions = {
        ...polylineOptions,
        map: this.googleMap,
        paths: arrayForPolygontoUse,
      };
      const newShapePolygon = new google.maps.Polygon(polygonOptions);
      this.onPolygonDrawn(newShapePolygon);
      setTimeout(() => {
        newShapePolygon.setMap(null);
      }, 100);
    });
  }

  private disableMap() {
    this.drawing = true;
    this.googleMap.setOptions({
      draggable: false,
    });
  }

  private enableMap() {
    this.drawing = false;
    this.googleMap.setOptions({
      draggable: true,
    });
  }

  private onPolygonDrawn(polygon: google.maps.Polygon): void {
    google.maps.event.removeListener(this.mouseUpDrawer);

    this.showUnassignedDeliveries
      .pipe(
        take(1),
        filter((value) => value)
      )
      .subscribe((value) => {
        const matchedMarkers: EventItem<UnassignedDeliveryIdentifier>[] = [];
        this.unassignedDeliveriesMarkersPositions.forEach(
          (marker: EventItem<UnassignedDeliveryIdentifier>, position: google.maps.LatLng) => {
            if (google.maps.geometry.poly.containsLocation(position, polygon)) {
              marker.source = StoreSourcesEnum.POLYGON_SELECTION;
              matchedMarkers.push(marker);
            }
          }
        );

        this.matchedUnassignedDelveries = _union(this.matchedUnassignedDelveries, matchedMarkers);

        this.pndStore$.dispatch(
          new UnassignedDeliveriesStoreActions.SetSelectedDeliveries({
            selectedDeliveries: this.matchedUnassignedDelveries,
          })
        );
      });

    if (this.showUnassignedPickups) {
      const unassignedPickupsMatchedMarkers: EventItem<UnassignedPickupIdentifier>[] = [];
      this.unassignedPickupsMarkersPositions.forEach(
        (marker: EventItem<UnassignedPickupIdentifier>, position: google.maps.LatLng) => {
          if (google.maps.geometry.poly.containsLocation(position, polygon)) {
            marker.source = StoreSourcesEnum.POLYGON_SELECTION;
            unassignedPickupsMatchedMarkers.push(marker);
          }
        }
      );

      this.matchedUnassignedPickups = _union(this.matchedUnassignedPickups, unassignedPickupsMatchedMarkers);

      this.pndStore$.dispatch(
        new UnassignedPickupsStoreActions.SetSelectedUnassignedPickupsAction({
          selectedPickups: this.matchedUnassignedPickups,
        })
      );
    }

    const planningRouteShipmentsMatchedMarkers: EventItem<PlanningRouteShipmentIdentifier>[] = [];
    this.planningRouteShipmentsMarkersPositions.forEach(
      (marker: EventItem<PlanningRouteShipmentIdentifier>, position: google.maps.LatLng) => {
        if (google.maps.geometry.poly.containsLocation(position, polygon)) {
          marker.source = StoreSourcesEnum.POLYGON_SELECTION;
          planningRouteShipmentsMatchedMarkers.push(marker);
        }
      }
    );

    this.matchedPlanningRouteShipments = _union(
      this.matchedPlanningRouteShipments,
      planningRouteShipmentsMatchedMarkers
    );
    const selectedPlanningRoutesShipmentsIds = this.matchedPlanningRouteShipments.map((s) => s.id);
    const stopsForSelectedPlanningRoutes = this.planningRoutesCacheService.getStopsForSelectedPlanningRoutes();

    const selectedPlanningRoutesShipmentsList = _flatten(
      selectedPlanningRoutesShipmentsIds.map((identifier) =>
        stopsForSelectedPlanningRoutes
          .get(identifier.routeInstId)
          .filter((stop) => stop.consignee.acctInstId === identifier.consignee.acctInstId)
          .map((filerStop) => _flatten(filerStop.deliveryShipments))
      )
    );

    this.pndStore$.dispatch(
      new RoutesStoreActions.SetSelectedPlanningRoutesShipmentsAction({
        selectedPlanningRoutesShipments: _flatten(selectedPlanningRoutesShipmentsList),
      })
    );

    const assignedRouteStopsMatchedMarkers: EventItem<AssignedStopPositionIdentifier>[] = [];
    this.assignedStopMarkersPositions.forEach(
      (marker: EventItem<AssignedStopPositionIdentifier>, position: google.maps.LatLng) => {
        if (google.maps.geometry.poly.containsLocation(position, polygon)) {
          marker.source = StoreSourcesEnum.POLYGON_SELECTION;
          assignedRouteStopsMatchedMarkers.push(marker);
        }
      }
    );

    this.matchedAssignedRouteStops = _union(this.matchedAssignedRouteStops, assignedRouteStopsMatchedMarkers);

    this.pndStore$.dispatch(
      new RoutesStoreActions.SetSelectedStopsForSelectedRoutesAction({
        selectedStopsForSelectedRoutes: this.matchedAssignedRouteStops,
      })
    );

    this.enableMap();
  }

  private initMarkersPositions(): void {
    this.unassignedDeliveriesMarkersPositions = new Map<google.maps.LatLng, EventItem<UnassignedDeliveryIdentifier>>();
    for (const marker of this.unassignedDeliveriesMarkers) {
      this.unassignedDeliveriesMarkersPositions.set(
        new google.maps.LatLng(marker.id.consignee.latitudeNbr, marker.id.consignee.longitudeNbr),
        marker
      );
    }

    this.unassignedPickupsMarkersPositions = new Map<google.maps.LatLng, EventItem<UnassignedPickupIdentifier>>();
    this.unassignedPickupsMarkers.forEach((marker: EventItem<UnassignedPickupIdentifier>) => {
      this.unassignedPickupsMarkersPositions.set(
        new google.maps.LatLng(marker.id.shipper.latitudeNbr, marker.id.shipper.longitudeNbr),
        marker
      );
    });

    this.planningRouteShipmentsMarkersPositions = new Map<
      google.maps.LatLng,
      EventItem<PlanningRouteShipmentIdentifier>
    >();
    this.planningRouteShipmentsMarkers.forEach((marker: EventItem<PlanningRouteShipmentIdentifier>) => {
      this.planningRouteShipmentsMarkersPositions.set(
        new google.maps.LatLng(marker.id.consignee.latitudeNbr, marker.id.consignee.longitudeNbr),
        marker
      );
    });

    this.assignedStopMarkersPositions = new Map<google.maps.LatLng, EventItem<AssignedStopPositionIdentifier>>();
    this.assignedStopsMarkers.forEach((marker: EventItem<AssignedStopPositionIdentifier>) => {
      this.assignedStopMarkersPositions.set(
        new google.maps.LatLng(marker.id.latitudeNbr, marker.id.longitudeNbr),
        marker
      );
    });
  }

  removeSelectedMarker(marker: EventItem<UnassignedDeliveryIdentifier>) {
    this.matchedUnassignedDelveries = this.matchedUnassignedDelveries.filter(
      (matchedMarker: EventItem<UnassignedDeliveryIdentifier>) =>
        consigneeToId(matchedMarker.id) !== consigneeToId(marker.id)
    );
  }

  /* Stack-based Douglas Peucker line simplification routine
  https://en.wikipedia.org/wiki/Ramer–Douglas–Peucker_algorithm
   returned is a reduced GLatLng array
  */

  private reducerDP(source: google.maps.LatLng[], kink: number) {
    /* source Input coordinates in GLatLngs 	*/
    /* kink	in metres, kinks above this depth kept  */
    /* kink depth is the height of the triangle abc where a-b and b-c are two consecutive line segments */

    let nSource: number,
      nStack: number,
      nDest: number,
      start: number,
      end: number,
      i: number,
      sig: number,
      devSqr: number,
      maxDevSqr: number,
      bandSqr: number,
      kain12: number,
      y12: number,
      d12: number,
      x13: number,
      y13: number,
      d13: number,
      x23: number,
      y23: number,
      d23: number;
    const F = (Math.PI / 180.0) * 0.5;
    const index = new Array();
    const sigStart = new Array();
    const sigEnd = new Array();

    if (source.length < 3) {
      return source;
    }

    nSource = source.length;
    bandSqr = (kink * 360.0) / (2.0 * Math.PI * 6378137.0); /* Now in degrees */
    bandSqr *= bandSqr;
    nDest = 0;
    sigStart[0] = 0;
    sigEnd[0] = nSource - 1;
    nStack = 1;

    /* while the stack is not empty  ... */
    while (nStack > 0) {
      start = sigStart[nStack - 1];
      end = sigEnd[nStack - 1];
      nStack--;

      if (end - start > 1) {
        /* any intermediate points ? */
        /* ... yes, so find most deviant intermediate point to
          either side of line joining start & end points */

        kain12 = source[end].lng() - source[start].lng();
        y12 = source[end].lat() - source[start].lat();

        if (Math.abs(kain12) > 180.0) {
          kain12 = 360.0 - Math.abs(kain12);
        }

        kain12 *= Math.cos(F * (source[end].lat() + source[start].lat()));
        /* use avg lat to reduce lng */
        d12 = kain12 * kain12 + y12 * y12;

        for (i = start + 1, sig = start, maxDevSqr = -1.0; i < end; i++) {
          x13 = source[i].lng() - source[start].lng();
          y13 = source[i].lat() - source[start].lat();
          if (Math.abs(x13) > 180.0) {
            x13 = 360.0 - Math.abs(x13);
          }
          x13 *= Math.cos(F * (source[i].lat() + source[start].lat()));
          d13 = x13 * x13 + y13 * y13;

          x23 = source[i].lng() - source[end].lng();
          y23 = source[i].lat() - source[end].lat();
          if (Math.abs(x23) > 180.0) {
            x23 = 360.0 - Math.abs(x23);
          }

          x23 *= Math.cos(F * (source[i].lat() + source[end].lat()));
          d23 = x23 * x23 + y23 * y23;

          if (d13 >= d12 + d23) {
            devSqr = d23;
          } else if (d23 >= d12 + d13) {
            devSqr = d13;
          } else {
            devSqr = ((x13 * y12 - y13 * kain12) * (x13 * y12 - y13 * kain12)) / d12; // solve triangle
          }

          if (devSqr > maxDevSqr) {
            sig = i;
            maxDevSqr = devSqr;
          }
        }

        if (maxDevSqr < bandSqr) {
          /* is there a sig. intermediate point ? */
          /* ... no, so transfer current start point */
          index[nDest] = start;
          nDest++;
        } else {
          /* ... yes, so push two sub-sections on stack for further processing */
          nStack++;
          sigStart[nStack - 1] = sig;
          sigEnd[nStack - 1] = end;
          nStack++;
          sigStart[nStack - 1] = start;
          sigEnd[nStack - 1] = sig;
        }
      } else {
        /* ... no intermediate points, so transfer current start point */
        index[nDest] = start;
        nDest++;
      }
    }

    /* transfer last point */
    index[nDest] = nSource - 1;
    nDest++;

    /* make return array */
    const r = new Array();
    for (let ix = 0; ix < nDest; ix++) {
      r.push(source[index[ix]]);
    }
    return r;
  }
}
