import { Directive } from '@angular/core';
import { AbstractControl, Validator } from '@angular/forms';
import { TimeUtil } from '../services/time-format.util';

@Directive({
  selector: '[timeFormatValidator]',
})
export class TimeFormatValidator implements Validator {
  constructor() {}

  validate(control: AbstractControl): { [key: string]: any } | null {
    return TimeUtil.timeFormatValidatorFunction()(control);
  }
}
