import { XpoSelectFilter, XpoSelectFilterParams } from '@xpo-ltl/ngx-ltl-board';
import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';

export class RouteSpecialServiceFilter extends XpoSelectFilter {
  constructor(field: string) {
    super(<XpoSelectFilterParams>{
      field: field,
      label: 'Special Services',
      options: [
        { title: 'Guaranteed', code: ShipmentSpecialServiceCd.GUARANTEED },
        { title: 'G12', code: ShipmentSpecialServiceCd.GUARANTEED_BY_NOON },
        { title: 'Hazmat', code: ShipmentSpecialServiceCd.HAZMAT },
        { title: 'LiftGate', code: ShipmentSpecialServiceCd.LIFT_GATE },
        { title: 'RRS', code: ShipmentSpecialServiceCd.RAPID_REMOTE_SERVICE },
      ],
    });
  }
}
