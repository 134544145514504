import { AgmMarker } from '@agm/core';
import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { InteractiveMapMarker } from '../../shared/models/markers/map-marker';

/**
 * Provide a right-click event for an AgmMarker with the   data
 */
@Directive({
  selector: '[pnd-map-marker]',
})
export class PndMapMarkerDirective implements OnInit, OnDestroy {
  static lastRightClickedMarker: InteractiveMapMarker;

  @Input('pnd-map-marker') marker: InteractiveMapMarker;
  @Output() markerRightClickWithEvent = new EventEmitter<MouseEvent>();

  private rightClickSubscription: Subscription;

  constructor(private agmMarker: AgmMarker) {}

  ngOnInit() {
    if (!this.rightClickSubscription) {
      this.rightClickSubscription = fromEvent(document, 'contextmenu')
        .pipe(debounceTime(200))
        .subscribe((event: MouseEvent) => {
          if (PndMapMarkerDirective.lastRightClickedMarker === this.marker) {
            this.markerRightClickWithEvent.emit(event);
            PndMapMarkerDirective.lastRightClickedMarker = undefined;
          }
          event.preventDefault();
          event.stopPropagation();
        });
    }

    // listen for right-clicks on this marker and store it for the
    // global contextmenu handler above
    this.agmMarker.markerRightClick.subscribe(() => {
      PndMapMarkerDirective.lastRightClickedMarker = this.marker;
    });
  }

  ngOnDestroy() {
    if (this.rightClickSubscription) {
      this.rightClickSubscription.unsubscribe();
    }
  }
}
