import { Injectable, Injector } from '@angular/core';
import {
  CityOperationsApiService,
  DeliveryShipmentSearchFilter,
  DeliveryShipmentSearchFilterAnd,
  DeliveryShipmentSearchFilterOr,
  GetPnDInboundSelectionProfilePath,
  GetPnDInboundSelectionProfileResp,
  ListPnDUnassignedStopsResp,
  ListPnDUnassignedStopsRqst,
  ProfileSic,
  UnassignedStop,
} from '@xpo-ltl/sdk-cityoperations';
import { XrtAttributeFilter, XrtSearchQueryHeader } from '@xpo-ltl/sdk-common';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import {
  filter as _filter,
  forEach as _forEach,
  get as _get,
  isEmpty as _isEmpty,
  map as _map,
  uniq as _uniq,
} from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { PndXrtService } from '../../../../core/services/pnd-xrt.service';
import { UnassignedDeliveriesSearchCriteria } from '../../../store/unassigned-deliveries-store/unassigned-deliveries-search-criteria.interface';
import { GrandTotalService } from './grand-total.service';
import { TimeOffset } from './time-offset.util';

// NOTE: DO NOT include the Store here as it will cause a circular dependency error
@Injectable({
  providedIn: 'root',
})
export class UnassignedDeliveriesService {
  constructor(
    private pndXrtService: PndXrtService,
    private cityOperationsService: CityOperationsApiService,
    private injector: Injector,
    private grandTotalService: GrandTotalService
  ) {}

  // cache of all unassigned deliveries from the last search
  private unassignedDeliveriesSubject = new BehaviorSubject<UnassignedStop[]>([]);
  readonly unassignedDeliveries$ = this.unassignedDeliveriesSubject.asObservable();

  // cache of all unmapped deliveries from the last search
  private unmappedDeliveriesSubject = new BehaviorSubject<UnassignedStop[]>([]);
  readonly unmappedDeliveries$ = this.unmappedDeliveriesSubject.asObservable();
  get unmappedDeliveries() {
    return this.unmappedDeliveriesSubject.value;
  }

  updateUnassignedDeliveries(value: UnassignedStop[]) {
    this.unassignedDeliveriesSubject.next(value);
    this.grandTotalService.updateUnassignedDeliveriesGrandTotals(value);
  }

  updateUnmappedDeliveries(unassignedStops: UnassignedStop[]) {
    this.unmappedDeliveriesSubject.next(unassignedStops);
  }

  /**
   * Get from server all unassigned and unmapped deliveries
   */
  searchUnassignedDeliveries(
    criteria: UnassignedDeliveriesSearchCriteria,
    planDate: Date
  ): Observable<ListPnDUnassignedStopsResp> {
    return forkJoin([
      this.fetchUnassignedDeliveries(criteria, planDate, false),
      this.fetchUnmappedDeliveries(criteria, planDate),
    ]).pipe(
      // we only want to return the mapped deliveries here]
      map((results) => results[0])
    );
  }

  private fetchUnassignedDeliveries(
    criteria: UnassignedDeliveriesSearchCriteria,
    planDate: Date,
    unmapped: boolean
  ): Observable<ListPnDUnassignedStopsResp> {
    const header: XrtSearchQueryHeader = {
      pageNumber: 1,
      pageSize: 10000,
      sortExpressions: [],
    };

    const request: ListPnDUnassignedStopsRqst = {
      header: header,
      filter: {
        ...new DeliveryShipmentSearchFilter(),
        q: _get(criteria, `Q`),
        hostDestSicCd: this.pndXrtService.toXrtFilterEquals(criteria.hostSicCd),
        destinationSicCd: this.pndXrtService.toXrtFilterValues(criteria.destinationSicCd),
        destSicEta: criteria.destinationSicEta
          ? {
              ...new XrtAttributeFilter(),
              min: '2000-01-01T00:00:00.000',
              max: criteria.destinationSicEta,
              convert: true,
            }
          : undefined,
        estimatedDeliveryDate: this.pndXrtService.toXrtFilterEqualsDateRange(
          _get(criteria, 'estimatedDeliveryDate.min'),
          _get(criteria, 'estimatedDeliveryDate.max')
        ),
        specialServiceSummary_specialService: this.pndXrtService.toXrtFilterValues(criteria.specialServices),
        consignee_geoCoordinates: !unmapped
          ? this.pndXrtService.toXrtFilterPoints(criteria.consigneeGeoCoordinates)
          : undefined,
        deliveryQualifierCd: this.pndXrtService.toXrtFilterValues(criteria.deliveryQualifiers),
        billClassCd: this.pndXrtService.toXrtFilterValues(criteria.billClass),
        shipmentLocationSicCd: this.pndXrtService.toXrtFilterValues(criteria.currentSicCd),
      },
      unmappedInd: unmapped,
    };

    return this.buildPlanningProfileCriteria(criteria, planDate, request).pipe(
      switchMap((params) => this.cityOperationsService.listPnDUnassignedStops(params)),
      catchError(() => of(undefined)),
      take(1)
    );
  }

  private fetchUnmappedDeliveries(
    criteria: UnassignedDeliveriesSearchCriteria,
    planDate: Date
  ): Observable<UnassignedStop[]> {
    return this.fetchUnassignedDeliveries(criteria, planDate, true).pipe(
      map((response: ListPnDUnassignedStopsResp) => {
        // store all unmapped deliveries
        this.unmappedDeliveriesSubject.next(_get(response, 'unassignedStops', []));
        return this.unmappedDeliveriesSubject.value;
      }),
      catchError(() => {
        this.unmappedDeliveriesSubject.next([]);
        return of([]);
      })
    );
  }

  private buildPlanningProfileCriteria(
    criteria: UnassignedDeliveriesSearchCriteria,
    planDate: Date,
    request: ListPnDUnassignedStopsRqst
  ): Observable<ListPnDUnassignedStopsRqst> {
    return new Observable((observer) => {
      if (!criteria.profileId) {
        observer.next(request);
        observer.complete();
        return;
      }

      const pathParams = { ...new GetPnDInboundSelectionProfilePath(), selectionProfileId: `${criteria.profileId}` };
      this.cityOperationsService
        .getPnDInboundSelectionProfile(pathParams)
        .pipe(take(1))
        .subscribe(
          (response: GetPnDInboundSelectionProfileResp) => {
            if (response) {
              // NOTE: we can't inject this through the constructor as we get an config error.
              const timeService = this.injector.get(XpoLtlTimeService);

              const currentPlanDate = moment(planDate).format('YYYY-MM-DD');
              const yesterdayPlanDate = moment(planDate)
                .add('-1', 'days')
                .format('YYYY-MM-DD');

              const allSicProfiles: ProfileSic[] = _get(response, 'selectionProfile.profileSic', []);

              // Any time value within this window we will assume plan date for yesterday
              const isTimeWithinCutoff = (timeHHMMSS: string): boolean => {
                const result = moment(timeHHMMSS, 'HH:mm:ss').isBetween(
                  moment('19:00:00', 'HH:mm:ss'),
                  moment('23:59:59', 'HH:mm:ss')
                );
                return result;
              };

              // We need to track if there is a profile where the SIC equals the owning SIC
              // If one does not exist we will need to use the owning SIC's enroute start and end times
              // when constructing filters.
              const owningSicCd: string = _get(response, 'selectionProfile.owningSicCd');
              const hasProfileForOwningSic = allSicProfiles.findIndex((profile) => profile.sicCd === owningSicCd) >= 0;

              // For these we want to return records where shipmentLocationSicCd = sicCd on profile.
              const currentTypeCd = 'C';
              // For these we want to return records where scheduleDestinationSicCd = sicCd AND
              // scheduleETA <= arrivalCutoffTime.
              const arrivalTypeCd = 'A';

              // ALL existing criteria for filtering will be and'd with a set of OR/AND criteria
              // to support profile filter construction.
              request.filter.and = request.filter.and || [];
              const orCriterias: DeliveryShipmentSearchFilterOr[] = [];
              request.filter.and.push({
                ...new DeliveryShipmentSearchFilterAnd(),
                or: orCriterias,
              });

              // First, handle profiles for Arrival
              // This is common regardless of if there is an owning profile SIC record.
              _forEach(
                _filter(allSicProfiles, (profile) => profile.sicTypeCd === arrivalTypeCd),
                (profile) => {
                  const arrivalCutoffTime = _get(profile, 'arrivalCutoffTime');
                  const arrivalCutoffDate = isTimeWithinCutoff(arrivalCutoffTime) ? yesterdayPlanDate : currentPlanDate;
                  const offset = TimeOffset.getOffsetFromTimezone(timeService.timezoneForSicCd(profile.sicCd));
                  const endTime = `${arrivalCutoffDate}T${arrivalCutoffTime}.999${offset}`;

                  orCriterias.push({
                    ...new DeliveryShipmentSearchFilterOr(),
                    and: [
                      {
                        ...new DeliveryShipmentSearchFilterAnd(),
                        scheduleDestinationSicCd: this.pndXrtService.toXrtFilterEquals(profile.sicCd),
                      },
                      {
                        ...new DeliveryShipmentSearchFilterAnd(),
                        scheduleETA: this.pndXrtService.toXrtFilterMinMaxRange(undefined, endTime),
                      },
                    ],
                  });
                }
              );

              if (hasProfileForOwningSic) {
                // In this case there is a profile record with SIC = owning profile SIC
                // so we use arrival records to build out scheduleETA.
                const shipmentLocationSicCodes = _map(
                  _filter(allSicProfiles, (profile) => profile.sicTypeCd === currentTypeCd),
                  (profile) => profile.sicCd
                );
                shipmentLocationSicCodes.push(owningSicCd);

                orCriterias.push({
                  ...new DeliveryShipmentSearchFilterOr(),
                  shipmentLocationSicCd: this.pndXrtService.toXrtFilterValues([..._uniq(shipmentLocationSicCodes)]),
                });
              } else {
                // In this case there is no profile record for the SIC so we have to use
                // the header enrouteStart/End times for the owning SIC.
                const enrouteStartTime = _get(response, 'selectionProfile.enrouteStartTime');
                const enrouteEndTime = _get(response, 'selectionProfile.enrouteEndTime');
                const enrouteStartDate =
                  isTimeWithinCutoff(enrouteEndTime) || isTimeWithinCutoff(enrouteStartTime)
                    ? yesterdayPlanDate
                    : currentPlanDate;
                const enrouteEndDate = isTimeWithinCutoff(enrouteEndTime) ? yesterdayPlanDate : currentPlanDate;
                const offset = TimeOffset.getOffsetFromTimezone(timeService.timezoneForSicCd(owningSicCd));
                const startTime = `${enrouteStartDate}T${enrouteStartTime}.000${offset}`;
                const endTime = `${enrouteEndDate}T${enrouteEndTime}.999${offset}`;

                const shipmentLocationSicCodes = _map(
                  _filter(allSicProfiles, (profile) => profile.sicTypeCd === currentTypeCd),
                  (profile) => profile.sicCd
                );
                if (_get(response, 'selectionProfile.inboundDestinationSicInd')) {
                  shipmentLocationSicCodes.push(owningSicCd);
                }

                orCriterias.push(
                  ...[
                    {
                      ...new DeliveryShipmentSearchFilterOr(),
                      scheduleDestinationSicCd: this.pndXrtService.toXrtFilterEquals(owningSicCd),
                      scheduleETA: this.pndXrtService.toXrtFilterMinMaxRange(startTime, endTime),
                    },
                    {
                      ...new DeliveryShipmentSearchFilterOr(),
                      shipmentLocationSicCd: this.pndXrtService.toXrtFilterValues([..._uniq(shipmentLocationSicCodes)]),
                    },
                  ]
                );
              }
            }

            observer.next(request);
            observer.complete();
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }
}
