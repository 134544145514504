export enum TripPlanningGridHeaders {
  ROW_SELECTED = '',
  TIMELINE = 'Timeline',
  RELEASE = 'Release',
  SEQ = 'Seq',
  DRIVER = 'Driver',
  ROUTE_PREFIX = 'Route Prefix',
  SUFFIX = 'Suffix',
  DELIVERY_CWT = 'Delivery $ / CWT',
  ROUTE_STATUS = 'Route Status',
  SPECIAL_SERVICES = 'Special Services',
  BILLS = 'Bills',
  WEIGHT = 'Weight (lbs.)',
  MM = 'MM',
  TRIP_STOPS = 'Trip Stops',
  EST_START_TIME = 'Est. Start Time',
  EST_CLEAR_TIME = 'Est. Clear Time',
  DOOR = 'Door#',
  TRAILER = 'Trailer#',
  DOCK_LOCATION = 'Dock Location',
  NEAREST_DOOR = 'Nearest Door',
  EARLIEST_SHIPMENT_ETA = 'Earliest Shipment ETA',
  LATEST_SHIPMENT_ETA = 'Latest Shipment ETA',
}
