import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConditioningService, FormatValidationService } from '@xpo-ltl/common-services';
import { XpoBoardData, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { DeliveryShipmentSearchRecord, UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { forEach as _forEach, get as _get, size as _size } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PndStoreState } from '../../../../store';
import { BoardStateSource } from '../../../../store/board-state-source';
import { UnassignedDeliveriesService } from '../../../shared';
import { UnassignedDeliveriesDataSourceBaseService } from '../unassigned-deliveries-data-source-base.service';

@Injectable()
export class UnassignedDeliveriesShipmentsDataSource extends UnassignedDeliveriesDataSourceBaseService<
  DeliveryShipmentSearchRecord
> {
  constructor(
    protected pndStore$: Store<PndStoreState.State>,
    protected timeService: XpoLtlTimeService,
    protected formValidationService: FormatValidationService,
    protected conditioningService: ConditioningService,
    private unassignedDeliveriesService: UnassignedDeliveriesService
  ) {
    super(pndStore$, timeService, formValidationService, conditioningService);
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    const computeTotals = (shipments: DeliveryShipmentSearchRecord[]) => {
      const totalShipmentsCount = _size(shipments);
      let motorizedPiecesCount = 0;
      let totalWeightLbs = 0;
      _forEach(shipments, (shipment) => {
        motorizedPiecesCount += _get(shipment, 'motorizedPiecesCount', 0);
        totalWeightLbs += _get(shipment, 'totalWeightLbs', 0);
      });
      return [
        {
          totalShipmentsCount,
          totalWeightLbs,
          motorizedPiecesCount,
        },
      ];
    };

    if (state.source === BoardStateSource.ReduxStore) {
      if (state.changes.includes('viewId')) {
        // When switching views, we need to wait until the Store updates the list.
        // however, fetchData is called before refresh, so we end up getting the previous view's
        // list, which causes the view to display incorrect data initially until the refresh occurs.
        // To combat that, return an empty data set when changing views. fetchData will be called
        // again once the Store is updated
        return of(new XpoBoardData(state, [], 0, 10000));
      } else {
        // fetch the latest Shipments from the Store to provide to the Board
        return this.unassignedDeliveriesService.unassignedDeliveries$.pipe(
          take(1),
          map((stops: UnassignedStop[]) => {
            return this.getShipmentsData(stops);
          }),
          map((shipments: DeliveryShipmentSearchRecord[]) => {
            return new XpoBoardData(
              state,
              { rows: shipments, totals: computeTotals(shipments) },
              _size(shipments),
              10000
            );
          })
        );
      }
    } else {
      // just return the existing data
      const existingData = _get(state, 'data.consumerData.rows', []);
      return of(
        new XpoBoardData(state, { rows: existingData, totals: computeTotals(existingData) }, _size(existingData), 10000)
      );
    }
  }

  private getShipmentsData(unassignedStops: UnassignedStop[]): DeliveryShipmentSearchRecord[] {
    const shipments: DeliveryShipmentSearchRecord[] = [];

    _forEach(unassignedStops, (stop: UnassignedStop) => {
      shipments.splice(-1, 0, ...stop.deliveryShipments);
    });

    return shipments;
  }
}
