import { DecimalPipe } from '@angular/common';
import { Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import {
  XpoDateRangeFilter,
  XpoMultiSelectAutocompleteFilter,
  XpoQuickSearchFilter,
  XpoFilter,
} from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardView, XpoAgGridBoardViewConfig, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoAgGridFormatters } from '@xpo-ltl/ngx-ltl-grid';
import { DeliveryShipmentSearchRecord } from '@xpo-ltl/sdk-cityoperations';
import { ShipmentSpecialServiceSummary } from '@xpo-ltl/sdk-common';
import { ProFormatterPipe, XpoLtlServiceCentersService, XpoLtlTimeService } from '@xpo/ngx-ltl';
import { GridOptions } from 'ag-grid-community';
import { get as _get, find as _find, result as _result } from 'lodash';
import * as moment from 'moment-timezone';
import { PndStoreState } from '../../../../store';
import {
  DeliveryQualifierCdPipe,
  pndFrameworkComponents,
  SpecialServicesService,
  StopWindowService,
} from '../../../shared';
import { TimeFilter } from '../../../shared/components/time-filter/time-filter';
import {
  BillClassFilter,
  DeliveryQualifierFilter,
  DestinationSicFilter,
  SicFilter,
  SpecialServiceFilter,
} from '../../../shared/filters';
import { PlanningProfilesFilter } from '../../../shared/filters/planning-profiles-filter';
import { OnAnyInputErrorStateMatcher } from '../../../shared/matchers/on-any-input-error-state.matcher';
import { BillClassCdPipe } from '../../../shared/pipes/bill-class-cd.pipe';
import { TimeUtil } from '../../../shared/services/time-format.util';
import { UnassignedDeliveriesGridFields } from '../enums/unassigned-deliveries-grid-fields.enum';
import { UnassignedDeliveriesGridHeaders } from '../enums/unassigned-deliveries-grid-headers.enum';
import { UnassignedDeliveriesViewId } from '../unassigned-deliveries-view-data-store.service';

export class UnassignedDeliveriesShipmentsBoardTemplate extends XpoAgGridBoardViewTemplate {
  static templateId = 'UnassignedDeliveriesShipmentsBoardTemplate';
  currentPlanDate: Date = new Date();

  constructor(
    private pndStore$: Store<PndStoreState.State>, // needed for PlanningProfilesFilter
    private locationService: LocationApiService, // needed for DestinationSicFilter
    private serviceCentersService: XpoLtlServiceCentersService, // needed for SicFilter
    private deliveryQualifierCdPipe: DeliveryQualifierCdPipe,
    private proFormatterPipe: ProFormatterPipe,
    private timeService: XpoLtlTimeService,
    private stopWindowService: StopWindowService,
    private billClassCdPipe: BillClassCdPipe,
    private specialServicesService: SpecialServicesService,
    private deciamlPipe: DecimalPipe,

    public addressClickCallback: (shipment: DeliveryShipmentSearchRecord) => void,
    public shipmentClickCallback: (shipment: DeliveryShipmentSearchRecord) => void
  ) {
    super({
      id: UnassignedDeliveriesShipmentsBoardTemplate.templateId,
      name: 'Unassigned Deliveries',
      keyField: 'shipmentInstId',
      availableColumns: [
        {
          headerName: UnassignedDeliveriesGridHeaders.ROW_SELECTED,
          colId: UnassignedDeliveriesGridFields.ROW_SELECTED,
          headerValueGetter: () => '',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          width: 50,
          sortable: true,
          pinnedRowCellRenderer: 'totalTextCellRenderer',
          comparator: (valueA, valueB, nodeA, nodeB) => {
            const nodeASel = nodeA.isSelected() ? 1 : 0;
            const nodeBSel = nodeB.isSelected() ? 1 : 0;
            return nodeASel - nodeBSel;
          },
        },
        {
          headerName: 'PRO',
          field: 'proNbr',
          width: 120,
          cellRenderer: 'actionLinkCellRenderer',
          cellRendererParams: { onClick: shipmentClickCallback },
          valueFormatter: (params) =>
            this.proFormatterPipe.transform(_get(params, 'data.proNbr'), 10) ||
            this.deciamlPipe.transform(_get(params, 'data.totalShipmentsCount')),
        },
        {
          headerName: 'Bill Class',
          width: 120,
          valueFormatter: (params) => {
            return this.billClassCdPipe.transform(_get(params, 'data.billClassCd'));
          },
          tooltip: (params) => {
            return this.billClassCdPipe.transformToTooltip(_get(params, 'data.billClassCd'));
          },
        },
        {
          headerName: 'Consignee',
          field: 'consigneeName',
          width: 275,
          valueGetter: (params) => {
            const shipment = params.data as DeliveryShipmentSearchRecord;
            return `${_get(shipment, 'consignee.name1', '')} ${_get(shipment, 'consignee.name2', '')}`;
          },
        },
        {
          headerName: 'Address',
          field: 'consigneeAddress',
          width: 250,
          cellRenderer: 'actionLinkCellRenderer',
          cellRendererParams: { onClick: addressClickCallback },
          valueGetter: (params) => {
            const shipment = params.data as DeliveryShipmentSearchRecord;
            return _get(shipment, 'consignee.addressLine1', '');
          },
        },
        {
          headerName: 'LP',
          field: 'loosePcsCnt',
          valueFormatter: XpoAgGridFormatters.formatNumber,
          width: 60,
          type: 'numericColumn',
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'Special Services',
          field: 'specialServiceSummary',
          width: 170,
          valueGetter: (params) => {
            const specialServicesSummary = _get(
              params,
              'data.specialServiceSummary'
            ) as ShipmentSpecialServiceSummary[];
            const specialServices = this.specialServicesService.getSpecialServicesForSummary(specialServicesSummary);
            return specialServices;
          },
          cellRenderer: 'specialServicesCellRenderer',
        },
        {
          headerName: 'Weight (lbs.)',
          field: 'totalWeightLbs',
          valueFormatter: XpoAgGridFormatters.formatNumber,
          width: 110,
          type: 'numericColumn',
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'MM',
          field: 'motorizedPiecesCount',
          valueFormatter: XpoAgGridFormatters.formatNumber,
          width: 70,
          type: 'numericColumn',
          cellStyle: { textAlign: 'center' },
        },

        {
          headerName: 'Cube',
          field: 'totalCubePercentage',
          valueFormatter: XpoAgGridFormatters.formatNumber,
          width: 70,
          type: 'numericColumn',
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'Svc Date',
          field: 'estimatedDeliveryDate',
          width: 100,
          valueFormatter: (params) => {
            const shipment: DeliveryShipmentSearchRecord = params.data as DeliveryShipmentSearchRecord;
            const estimatedDeliveryDate = _get(shipment, 'estimatedDeliveryDate');
            if (estimatedDeliveryDate) {
              return moment(estimatedDeliveryDate).format('MM-DD');
            } else {
              return '';
            }
          },
        },
        {
          headerName: 'Destination ETA',
          field: 'destSicEta',
          width: 150,
          valueFormatter: (params) => {
            const shipment: DeliveryShipmentSearchRecord = params.data as DeliveryShipmentSearchRecord;
            return this.timeService.formatDate(
              _get(shipment, 'destSicEta'),
              'MM-DD HH:mm',
              _get(shipment, 'destinationSicCd')
            );
          },
        },

        { headerName: 'Destination SIC', field: 'destinationSicCd', width: 125 },
        { headerName: 'Current SIC', field: 'shipmentLocationSicCd', width: 125 },
        { headerName: 'Trailer', field: 'currentTrailer', width: 125 },
        { headerName: 'Trailer SIC', field: 'trailerCurrSicCd', width: 125 },
        {
          headerName: 'Exceptions',
          field: 'deliveryExceptions',
          width: 120,
          sortable: true,
          valueFormatter: (params) => this.deliveryQualifierCdPipe.transform(params.data.deliveryQualifierCd),
        },
        {
          headerName: 'Schedule ETA',
          field: 'scheduleETA',
          width: 120,
          valueFormatter: (params) => {
            const shipment: DeliveryShipmentSearchRecord = params.data as DeliveryShipmentSearchRecord;
            return this.timeService.formatDate(
              _get(shipment, 'scheduleETA'),
              'MM-DD HH:mm',
              _get(shipment, 'scheduleDestinationSicCd')
            );
          },
        },
        // Not yet Implemented
        {
          headerName: 'Schedule Destination',
          field: 'scheduleDestinationSicCd',
          width: 130,
          cellStyle: { textAlign: 'center' },
        },
        {
          headerName: 'Delivery Window Type',
          field: 'deliveryWindowType',
          width: 160,
          valueGetter: (params) => {
            const stopWindow = _get(params.data, 'stopWindow');
            return this.stopWindowService.getStopWindowType(stopWindow);
          },
        },
        {
          headerName: 'Delivery Window Time',
          field: 'deliveryWindowTime',
          width: 160,
          cellRenderer: 'deliverWindowTimeCellRenderer',
        },
        {
          headerName: 'Delivery Window Date',
          field: 'deliveryWindowDate',
          width: 160,
          valueGetter: (params) => {
            const stopWindow = _get(params.data, 'stopWindow');
            return this.stopWindowService.getStopWindowDate(stopWindow);
          },
        },
      ],
      availableFilters: [
        new XpoQuickSearchFilter('q', { disableAutofocus: true }),
        new PlanningProfilesFilter('profileId', 'profileName', pndStore$),
        new TimeFilter({
          field: 'scheduleETA',
          label: 'ETA (HH:MM)',
          validators: [Validators.pattern(new RegExp(TimeUtil.TimeFormatValidatorRegExpWithAnchors))],
          title: `ETA (HH:MM)`,
          inputDebounceTime: 800,
          matcher: new OnAnyInputErrorStateMatcher(),
        }),
        new SpecialServiceFilter('specialServiceSummary'),
        new SicFilter('currentSicCd', 'Current SIC', serviceCentersService),
        new DestinationSicFilter('destinationSicCd', 'Destination SIC', pndStore$, locationService),
        new XpoDateRangeFilter('estimatedDeliveryDate', 'Service Date'),
        new DeliveryQualifierFilter('deliveryQualifiers'),
        new BillClassFilter('billClass'),
      ],
      allowAdditional: true,
    });
  }

  createView(config: XpoAgGridBoardViewConfig): XpoAgGridBoardView {
    // This is called everytime the view is changed.

    if (config.id === UnassignedDeliveriesViewId.CurrentSic) {
      // when showing the CurrentSic view, update the Current SIC filter with the current sic code
      const sicCd = _result(config, 'criteria.currentSicCd[0]');
      const sicFilterObj: XpoMultiSelectAutocompleteFilter = _find(this.availableFilters, (filter: XpoFilter) => {
        return filter.field === 'currentSicCd';
      }) as XpoMultiSelectAutocompleteFilter;
      if (sicFilterObj) {
        sicFilterObj.setSelectedOptions([{ title: sicCd, code: sicCd }]);
      }
    }

    this.assignUniqueId(config);
    return new XpoAgGridBoardView(this, {
      ...config,
      templateId: this.id,
    });
  }

  startHourToInt(hour: string): number {
    const match = hour ? Number(hour.match(/[^-]*/i)[0].replace(':', '.')) : 24;
    return isNaN(match) ? 24 : match;
  }
  setCurrentPlanDate(planDate: Date): void {
    this.currentPlanDate = planDate;
  }

  getGridOptions(): GridOptions {
    return {
      headerHeight: 40,
      frameworkComponents: pndFrameworkComponents,
      defaultColDef: { resizable: true },
      getRowHeight: function() {
        // Default row height
        const rowHeight = 39;
        return rowHeight;
      },
      pinnedBottomRowData: [],
    };
  }
}
