import { Component, HostListener, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'resequence-route-stops',
  templateUrl: './resequence-route-stops.component.html',
  styleUrls: ['./resequence-route-stops.component.scss'],
})
export class ResequenceRouteStopsComponent {
  @Input()
  inReselectionMode: boolean = false;
  @Output()
  reSequenceStart = new EventEmitter<boolean>();
  @Output()
  reSequenceEnd = new EventEmitter<boolean>();

  constructor() {}

  toggleSelectionMode(): void {
    this.inReselectionMode = !this.inReselectionMode;
    if (this.inReselectionMode) {
      this.reSequenceStart.emit(this.inReselectionMode);
    } else {
      this.reSequenceEnd.emit(this.inReselectionMode);
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.toggleSelectionMode();
    }
  }
}
