export enum UnassignedDeliveriesGridFields {
  ROW_SELECTED = 'rowSelected',
  BILLS = '*',
  CONSIGNEE = 'consigneeName',
  ADDRESS = 'consigneeAddress',
  CITY = 'consigneeCity',
  ZIP_CODE = 'consignee.postalCd',
  SPECIAL_SERVICES = 'specialServiceSummary',
  WEIGHT = 'totalWeightLbs',
  MM = 'motorizedPiecesCount',
  LP = 'loosePcsCnt',
  CUBE = 'totalCubePercentage',
  DELIVERY_WINDOW_TYPE = 'deliveryWindowType',
  DELIVERY_WINDOW_TIME = 'deliveryWindowTime',
  DELIVERY_WINDOW_DATE = 'deliveryWindowDate',
  EARLIEST_ETA = 'earliestDestSicEta',
  LATEST_ETA = 'latestDestSicEta',
  PRO = 'proNbr',
  SERVICE_DATE = 'estimatedDeliveryDate',
  DESTINATION_ETA = 'destSicEta',
  DESTINATION_SIC = 'destinationSicCd',
  CURRENT_SIC = 'shipmentLocationSicCd',
  TRAILER = 'currentTrailer',
  TRAILER_SIC = 'trailerCurrSicCd',
  EXCEPTIONS = 'deliveryExceptions',
  SCHEDULED_ETA = 'scheduleETA',
  SCHEDULED_DESTINATION = 'scheduleDestinationSicCd',
  DELIVERY_QUALIFIERS = 'deliveryQualifiers',
  BILL_CLASS = 'billClass',
  PROFILE_ID = 'profileId',
}
