import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate, XpoAgGridBoardViewConfig, XpoAgGridBoardView } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoAgGridFormatters } from '@xpo-ltl/ngx-ltl-grid';
import { DeliveryShipmentSearchRecord } from '@xpo-ltl/sdk-cityoperations';
import { ShipmentSpecialServiceSummary } from '@xpo-ltl/sdk-common';
import { first as _first, get as _get } from 'lodash';
import { PlanningRouteShipmentsGridItem } from '.';
import { StopWindowService, ActivityCdPipe, SpecialServicesService } from '../../shared';
import { RouteColorService } from '../../shared/services/route-color.service';
import { PlanningRouteShipmentsGridFields } from './enums/planning-route-shipments-grid-fields.enum';
import { PlanningRouteShipmentsGridHeaders } from './enums/planning-route-shipments-grid-headers.enum';

export class PlanningRouteShipmentsBoardTemplate extends XpoAgGridBoardViewTemplate {
  static templateId = 'PlanningRouteShipmentsBoardTemplate';
  constructor(
    addressClickCallback: (stop: PlanningRouteShipmentsGridItem) => void,
    private stopWindowService: StopWindowService,
    private activityCd: ActivityCdPipe,
    private specialServicesService: SpecialServicesService,
    private routeColorService: RouteColorService
  ) {
    super({
      id: PlanningRouteShipmentsBoardTemplate.templateId,
      name: 'Planning Route Shipments',
      allowAdditional: true,
      keyField: 'uniqId',
      availableColumns: [
        {
          headerName: PlanningRouteShipmentsGridHeaders.ROUTE_ID,
          field: PlanningRouteShipmentsGridFields.ROUTE_ID,
          width: 120,
          rowGroup: true,
          hide: true,
        },
        {
          headerName: PlanningRouteShipmentsGridHeaders.ROW_SELECTED,
          field: PlanningRouteShipmentsGridFields.ROW_SELECTED,
          headerValueGetter: () => '',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          width: 50,
          sortable: true,
          pinnedRowCellRenderer: 'totalTextCellRenderer',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel = nodeA.isSelected() ? 1 : 0;
            const nodeBSel = nodeB.isSelected() ? 1 : 0;
            return nodeASel - nodeBSel;
          },
          cellStyle: (params) => {
            const routeInstId = _get(params, 'node.data.routeInstId');
            const isSelected = params.node.isSelected();
            const color =
              isSelected && routeInstId ? this.routeColorService.getColorForRoute(routeInstId) : 'transparent';
            const width = '4px';

            return {
              'border-left': isSelected ? `${width} solid ${color}` : `${width} solid transparent`,
            };
          },
        },
        {
          headerName: PlanningRouteShipmentsGridHeaders.BILLS,
          field: PlanningRouteShipmentsGridFields.BILLS,
          width: 56,
          sortable: true,
          cellClass: 'pnd-UnassignedDeliveries__BillsCell',
          cellRenderer: 'agGroupCellRenderer',
          type: 'numericColumn',
          valueGetter: (params) => {
            return _get(params.data, 'totalShipmentsCount', 0);
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel = _get(nodeA, 'data.totalShipmentsCount', 0);
            const nodeBSel = _get(nodeB, 'data.totalShipmentsCount', 0);
            return nodeASel - nodeBSel;
          },
        },
        {
          headerName: PlanningRouteShipmentsGridHeaders.CONSIGNEE,
          field: PlanningRouteShipmentsGridFields.CONSIGNEE,
          width: 275,
          valueGetter: (params) => {
            return `${_get(params.data, 'consignee.name1', '')} ${_get(params.data, 'consignee.name2', '')}`;
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel: string = `${_get(nodeA, 'data.deliveryShipments[0].consignee.name1', '')} ${_get(
              nodeA,
              'data.deliveryShipments[0].consignee.name2',
              ''
            )}`;
            const nodeBSel: string = `${_get(nodeB, 'data.deliveryShipments[0].consignee.name1', '')} ${_get(
              nodeB,
              'data.deliveryShipments[0].consignee.name1',
              ''
            )}`;
            return nodeASel.localeCompare(nodeBSel);
          },
        },
        {
          headerName: PlanningRouteShipmentsGridHeaders.ADDRESS,
          field: PlanningRouteShipmentsGridFields.ADDRESS,
          width: 250,
          cellRenderer: 'actionLinkCellRenderer',
          cellRendererParams: { onClick: addressClickCallback },
          valueGetter: (params) => {
            return _get(params.data, 'consignee.addressLine1', '');
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel: string = _get(nodeA, 'data.deliveryShipments[0].consignee.addressLine1', '');
            const nodeBSel: string = _get(nodeB, 'data.deliveryShipments[0].consignee.addressLine1', '');
            return nodeASel.localeCompare(nodeBSel);
          },
        },
        {
          headerName: PlanningRouteShipmentsGridHeaders.CITY,
          field: PlanningRouteShipmentsGridFields.CITY,
          width: 175,
          valueGetter: (params) => {
            return _get(params.data, 'consignee.cityName', '');
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel: string = _get(nodeA, 'data.deliveryShipments[0].consignee.cityName', '');
            const nodeBSel: string = _get(nodeB, 'data.deliveryShipments[0].consignee.cityName', '');
            return nodeASel.localeCompare(nodeBSel);
          },
        },
        {
          headerName: 'Zip Code',
          field: 'consignee.postalCd',
          width: 90,
        },
        // {
        //   headerName: PlanningRouteShipmentsGridHeaders.LP',
        //   field: PlanningRouteShipmentsGridFields.loosePcsCnt',
        //   width: 50,
        //   type: 'numericColumn',
        //   comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        //     const nodeASel = _get(nodeA, 'allLeafChildren[0].data.loosePcsCnt', 0);
        //     const nodeBSel = _get(nodeB, 'allLeafChildren[0].data.loosePcsCnt', 0);
        //     return nodeASel - nodeBSel;
        //   },
        // },
        {
          headerName: PlanningRouteShipmentsGridHeaders.WEIGHT,
          field: PlanningRouteShipmentsGridFields.WEIGHT,
          width: 110,
          type: 'numericColumn',
          valueFormatter: XpoAgGridFormatters.formatNumber,
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel = _get(nodeA, 'data.deliveryShipments', []).reduce(
              (total, curr) => total + _get(curr, 'totalWeightLbs', 0),
              0
            );
            const nodeBSel = _get(nodeB, 'data.deliveryShipments', []).reduce(
              (total, curr) => total + _get(curr, 'totalWeightLbs', 0),
              0
            );
            return nodeASel - nodeBSel;
          },
        },
        {
          headerName: PlanningRouteShipmentsGridHeaders.MM,
          field: PlanningRouteShipmentsGridFields.MM,
          width: 80,
          type: 'numericColumn',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel = _get(nodeA, 'data.deliveryShipments', []).reduce(
              (total, curr) => total + _get(curr, 'motorizedPiecesCount', 0),
              0
            );
            const nodeBSel = _get(nodeB, 'data.deliveryShipments', []).reduce(
              (total, curr) => total + _get(curr, 'motorizedPiecesCount', 0),
              0
            );
            return nodeASel - nodeBSel;
          },
        },
        {
          headerName: PlanningRouteShipmentsGridHeaders.CUBE,
          field: PlanningRouteShipmentsGridFields.CUBE,
          width: 80,
          type: 'numericColumn',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return (valueA ? valueA : 0) - (valueB ? valueB : 0);
          },
        },
        {
          headerName: PlanningRouteShipmentsGridHeaders.DESTINATION_SIC,
          width: 125,
          valueGetter: (params) => {
            const data: PlanningRouteShipmentsGridItem = params.data;
            if (data) {
              const shipment: DeliveryShipmentSearchRecord = _first(data.deliveryShipments);
              return _get(shipment, 'destinationSicCd');
            }
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel: string = _get(nodeA, 'data.deliveryShipments[0].destinationSicCd', '');
            const nodeBSel: string = _get(nodeB, 'data.deliveryShipments[0].destinationSicCd', '');
            return nodeASel.localeCompare(nodeBSel);
          },
        },
        {
          headerName: PlanningRouteShipmentsGridHeaders.CURRENT_SIC,
          field: PlanningRouteShipmentsGridFields.CURRENT_SIC,
          width: 110,
          cellStyle: { textAlign: 'center' },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel: string = _get(nodeA, 'data.currentSicCd', '');
            const nodeBSel: string = _get(nodeB, 'data.currentSicCd', '');
            return nodeASel.localeCompare(nodeBSel);
          },
        },
        {
          headerName: PlanningRouteShipmentsGridHeaders.SPECIAL_SERVICES,
          field: PlanningRouteShipmentsGridFields.SPECIAL_SERVICES,
          width: 170,
          valueGetter: (params) => {
            const specialServicesSummary = _get(
              params,
              'data.specialServiceSummary'
            ) as ShipmentSpecialServiceSummary[];
            const specialServices = this.specialServicesService.getSpecialServicesForSummary(specialServicesSummary);
            return specialServices;
          },
          cellRenderer: 'specialServicesCellRenderer',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel: string = _get(nodeA, 'data.specialServiceSummary[0].specialService', '');
            const nodeBSel: string = _get(nodeB, 'data.specialServiceSummary[0].specialService', '');
            return nodeASel.localeCompare(nodeBSel);
          },
        },
        {
          headerName: PlanningRouteShipmentsGridHeaders.DELIVERY_WINDOW_TYPE,
          field: PlanningRouteShipmentsGridFields.DELIVERY_WINDOW_TYPE,
          width: 160,
          valueGetter: (params) => {
            const stopWindow = _get(params.data, 'stopWindow');
            return this.stopWindowService.getStopWindowType(stopWindow);
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel: [] = _get(nodeA, 'data.stopWindow', []);
            const nodeBSel: [] = _get(nodeB, 'data.stopWindow', []);
            const stopWindowA = this.stopWindowService.getStopWindowType(nodeASel);
            const stopWindowB = this.stopWindowService.getStopWindowType(nodeBSel);
            return (stopWindowA ? stopWindowA : '').localeCompare(stopWindowB ? stopWindowB : '');
          },
        },
        {
          headerName: PlanningRouteShipmentsGridHeaders.DELIVERY_WINDOW_TIME,
          field: PlanningRouteShipmentsGridFields.DELIVERY_WINDOW_TIME,
          width: 160,
          cellRenderer: 'deliverWindowTimeCellRenderer',
          valueGetter: (params) => {
            const stopWindow = _get(params.data, 'stopWindow');
            return this.stopWindowService.getStopWindowTime(stopWindow, false);
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel: [] = _get(nodeA, 'data.stopWindow', []);
            const nodeBSel: [] = _get(nodeB, 'data.stopWindow', []);
            const stopWindowA = this.stopWindowService.getStopWindowTime(nodeASel, false);
            const stopWindowB = this.stopWindowService.getStopWindowTime(nodeBSel, false);
            return (stopWindowA ? stopWindowA : '').localeCompare(stopWindowB ? stopWindowB : '');
          },
        },
        {
          headerName: PlanningRouteShipmentsGridHeaders.DELIVERY_WINDOW_DATE,
          field: PlanningRouteShipmentsGridFields.DELIVERY_WINDOW_DATE,
          width: 160,
          valueGetter: (params) => {
            const stopWindow = _get(params.data, 'stopWindow');
            return this.stopWindowService.getStopWindowDate(stopWindow);
          },
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            const nodeASel: [] = _get(nodeA, 'data.stopWindow', []);
            const nodeBSel: [] = _get(nodeB, 'data.stopWindow', []);
            const stopWindowA = this.stopWindowService.getStopWindowDate(nodeASel);
            const stopWindowB = this.stopWindowService.getStopWindowDate(nodeBSel);
            return (stopWindowA ? stopWindowA : '').localeCompare(stopWindowB ? stopWindowB : '');
          },
        },
      ],
    });
  }
  startHourToInt(hour: string): number {
    const match = hour ? Number(hour.match(/[^-]*/i)[0].replace(':', '.')) : 24;
    return isNaN(match) ? 24 : match;
  }

  createView(config: XpoAgGridBoardViewConfig): XpoAgGridBoardView {
    // This is called everytime the view is changed.
    this.assignUniqueId(config);
    return new XpoAgGridBoardView(this, {
      ...config,
      templateId: this.id,
    });
  }

  createStopsView(): XpoBoardView {
    return this.createView({
      closeable: false,
      criteria: {},
      id: 'planning-route-shipments-default',
      name: '',
    });
  }
}
