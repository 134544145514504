import { Injectable } from '@angular/core';
import { Route, Stop } from '@xpo-ltl/sdk-cityoperations';
import { RouteStatusCd } from '@xpo-ltl/sdk-common';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { get as _get, isUndefined as _isUndefined } from 'lodash';
import { MapUtils } from '../classes/map-utils';

@Injectable({
  providedIn: 'root',
})
export class ResequenceService {
  constructor(private loggingService: LoggingApiService) {}

  canResequenceRoute(route: Route): boolean {
    return (
      _get(route, 'statusCd') !== RouteStatusCd.DISPATCHED &&
      _get(route, 'statusCd') !== RouteStatusCd.RETURNING &&
      _get(route, 'statusCd') !== RouteStatusCd.COMPLETE &&
      _get(route, 'statusCd') !== RouteStatusCd.CANCELLED
    );
  }
}
