import { OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConditioningService, FormatValidationService } from '@xpo-ltl/common-services';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { Unsubscriber, XpoLtlTimeService } from '@xpo/ngx-ltl';
import {
  uniq as _uniq,
  flatten as _flatten,
  keys as _keys,
  get as _get,
  isEmpty as _isEmpty,
  eq as _eq,
  isEqual as _isEqual,
  size as _size,
} from 'lodash';
import moment from 'moment';
import { combineLatest } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import {
  GlobalFilterStoreSelectors,
  PndStoreState,
  UnassignedDeliveriesStoreActions,
  UnassignedDeliveriesStoreSelectors,
} from '../../../store';
import { UnassignedDeliveriesSearchCriteria } from '../../../store/unassigned-deliveries-store/unassigned-deliveries-search-criteria.interface';
import { GlobalFilterMapCoordinate } from '../../shared';
import { TimeUtil } from '../../shared/services/time-format.util';
import { TimeOffset } from '../../shared/services/time-offset.util';

const MIN_TIME_BETWEEN_SEARCHES = 3000; // min time between searches when criteria is the same

/**
 * Abstract base class for data source providers in the unassigned deliveries grid.
 */
export abstract class UnassignedDeliveriesDataSourceBaseService<DATA_TYPE>
  extends XpoBoardDataSource<XpoBoardData<DATA_TYPE>>
  implements OnDestroy {
  protected unsubscriber = new Unsubscriber();

  isRowGroupActive: boolean = true;
  private lastSearchCriteriaUpdate: number = 0;

  constructor(
    protected pndStore$: Store<PndStoreState.State>,
    protected timeService: XpoLtlTimeService,
    protected formValidationService: FormatValidationService,
    protected conditioningService: ConditioningService
  ) {
    super();

    this.subscribeToStateChanges();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  subscribeToStateChanges() {
    // refresh datawhen board requests it
    this.state$
      .pipe(
        filter((state) => state.source === 'DATA SOURCE REFRESH'),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe(() => {
        this.pndStore$.dispatch(new UnassignedDeliveriesStoreActions.Refresh());
      });

    // rebuild search criteria when filters change
    combineLatest([
      this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterSic),
      this.pndStore$.select(GlobalFilterStoreSelectors.globalFilterPlanDate),
      this.state$.pipe(
        filter(
          (state) =>
            state.source === 'BOARD-ACTIVATING-VIEW' ||
            state.source === 'FILTER-CHANGE' ||
            state.source === 'ACTIVE-VIEW-CHANGE' ||
            state.source === 'ADD-NEW-VIEW'
        )
      ),
      this.pndStore$.select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesBoundingSearchArea),
    ])
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe(([sicCd, newPlanDate, boardState, boundingArea]) => {
        this.updateSearchCriteria(sicCd, newPlanDate, boardState, boundingArea);
      });
  }

  /**
   * Creates new search from passed data and updates the store if needed
   */
  updateSearchCriteria(
    hostSicCd: string,
    planDate: Date,
    boardState: XpoBoardState,
    boundingArea: GlobalFilterMapCoordinate[]
  ) {
    // create search filter criteria from updated values

    const offset = TimeOffset.getOffsetFromTimezone(this.timeService.timezoneForSicCd(hostSicCd));
    let scheduleETA;
    if (_get(boardState.criteria, 'scheduleETA')) {
      if (new RegExp(TimeUtil.TimeFormatValidatorRegExpWithAnchors).test(boardState.criteria.scheduleETA)) {
        scheduleETA = `${moment(planDate).format('YYYY-MM-DD')}T${boardState.criteria.scheduleETA}:59.999${offset}`;
      }
    }

    let q = _get(boardState.criteria, 'q');
    if (!_isEmpty(q) && this.formValidationService.isValidProNumber(q)) {
      q = this.conditioningService.conditionProNumber(q, 11);
    }

    const criteria: UnassignedDeliveriesSearchCriteria = {
      Q: q,
      currentSicCd: _get(boardState.criteria, 'currentSicCd'),
      hostSicCd: hostSicCd,
      destinationSicCd: _get(boardState.criteria, 'destinationSicCd', []),
      destinationSicEta: scheduleETA,
      scheduleDestinationSicCd: _get(boardState.criteria, 'scheduleDestinationSicCd'),
      estimatedDeliveryDate: _get(boardState.criteria, 'estimatedDeliveryDate'),
      specialServices: _get(boardState.criteria, 'specialServiceSummary'),
      consigneeGeoCoordinates: !_isEmpty(boundingArea) ? boundingArea : undefined,
      deliveryQualifiers: _get(boardState.criteria, 'deliveryQualifiers'),
      billClass: _get(boardState.criteria, 'billClass'),
      profileId: _get(boardState.criteria, 'profileId'),
    };

    const areObjectsEquivalent = (a: Object, b: Object): boolean => {
      const keys = _uniq(_flatten([_keys(a), _keys(b)]));
      let equal = true;
      for (let ii = 0; ii < _size(keys) && equal; ii++) {
        equal = equal && _isEqual(_get(a, keys[ii]), _get(b, keys[ii]));
      }
      return equal;
    };

    this.pndStore$
      .select(UnassignedDeliveriesStoreSelectors.searchCriteria)
      .pipe(take(1))
      .subscribe((previousCriteria) => {
        if (
          !areObjectsEquivalent(previousCriteria, criteria) ||
          Date.now() - this.lastSearchCriteriaUpdate > MIN_TIME_BETWEEN_SEARCHES
        ) {
          this.lastSearchCriteriaUpdate = Date.now();
          this.pndStore$.dispatch(new UnassignedDeliveriesStoreActions.SetSearchCriteria({ criteria }));
        }
      });
  }
}
