import { PipeTransform, Pipe } from '@angular/core';
import { TripNodeActivityCd } from '@xpo-ltl/sdk-common';

@Pipe({ name: 'activityCd' })
export class ActivityCdPipe implements PipeTransform {
  transform(value: TripNodeActivityCd, args?: any): string {
    switch (value) {
      case TripNodeActivityCd.ARRIVE:
        return 'AR';
      case TripNodeActivityCd.DELIVER_SHIPMENT:
        return 'DL';
      case TripNodeActivityCd.DEPART_DISPATCH:
        return 'DE';
      case TripNodeActivityCd.DROP_EQUIPMENT:
        return 'DP';
      case TripNodeActivityCd.HOOK_EMPTY:
        return 'HE';
      case TripNodeActivityCd.HOOK_EQUIPMENT:
        return 'HK';
      case TripNodeActivityCd.HOOK_LOADED:
        return 'HL';
      case TripNodeActivityCd.PICKUP_SHIPMENTS:
        return 'PU';
      case TripNodeActivityCd.SPOT_EMPTY:
        return 'SE';
      case TripNodeActivityCd.SPOT_LOADED:
        return 'SL';
    }

    return value;
  }
}
