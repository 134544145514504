import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { GeoArea } from '@xpo-ltl-2.0/sdk-location';
import { GetPnDTripResp, RouteDetail, Trip } from '@xpo-ltl/sdk-cityoperations';
import { XpoLtlTimeService } from '@xpo/ngx-ltl';
import { filter as _filter, get as _get, map as _map, result as _result } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { PndRouteUtils } from '../../../../../../../shared/route-utils';
import { PndStoreState } from '../../../../../../store';
import { GeoAreaStoreSelectors } from '../../../../../../store/geo-area-store';
import { EquipmentPipe } from '../../../../pipes';
import { SpecialServicesService } from '../../../../services/special-services.service';

export interface DispatchSummaryLine {
  dontAutoDispatch: string;
  dispatchGroup: string;
  estimatedStart: string;
  actualStart: string;
  estimatedClear: string;
  estimatedComplete: string;
  actualComplete: string;
}

export interface TripStatsSummaryLine {
  lastStopArea: string;
  lastStopType: string;
  lastStopArrive: string;
  lastStopDepart: string;
  returnedShipments: number;
  remainingPickupWeight: string;
  remainingDeliveries: number;
  completedDeliveries: number;
  totalRemaining: number;
  totalCompleted: number;
  remainingPickups: number;
}

export interface TripEquipmentSummaryLine {
  route: string;
  trailer: string;
  dolly: string;
  year: string;
  status: string;
  routeWeight: string;
  door: string;
  routeInstId: number;
  specialServices: any;
}

@Component({
  selector: 'pnd-trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.scss'],
})
export class TripDetailsComponent implements OnInit {
  private dispatchSummaryDataSourceSubject = new BehaviorSubject<MatTableDataSource<DispatchSummaryLine>>(undefined);
  dispatchSummaryDataSource$ = this.dispatchSummaryDataSourceSubject.asObservable();

  private tripStatsDataSourceSubject = new BehaviorSubject<MatTableDataSource<TripStatsSummaryLine>>(undefined);
  tripStatsDataSource$ = this.tripStatsDataSourceSubject.asObservable();

  private tripEquipmentDataSourceSubject = new BehaviorSubject<MatTableDataSource<TripEquipmentSummaryLine>>(undefined);
  tripEquipmentDataSource$ = this.tripEquipmentDataSourceSubject.asObservable();

  dispatchSummaryColumns = [
    {
      id: 'dontAutoDispatch',
      label: 'Do Not Auto-Dispatch',
      renderer: (row: DispatchSummaryLine) => row.dontAutoDispatch,
    },
    { id: 'dispatchGroup', label: 'Dispatch Group', renderer: (row: DispatchSummaryLine) => row.dispatchGroup },
    { id: 'estimatedStart', label: 'Estimated Start', renderer: (row: DispatchSummaryLine) => row.estimatedStart },
    { id: 'actualStart', label: 'Actual Start', renderer: (row: DispatchSummaryLine) => row.actualStart },
    { id: 'estimatedClear', label: 'Estimated Clear', renderer: (row: DispatchSummaryLine) => row.estimatedClear },
    {
      id: 'estimatedComplete',
      label: 'Estimated Completion',
      renderer: (row: DispatchSummaryLine) => row.estimatedComplete,
    },
    { id: 'actualComplete', label: 'Actual Completion', renderer: (row: DispatchSummaryLine) => row.actualComplete },
  ];
  dispatchSummaryColumnsToDisplay = _map(this.dispatchSummaryColumns, (c) => c.id);

  tripStatsColumns = [
    { id: 'lastStopArea', label: 'Last Stop Area', renderer: (row: TripStatsSummaryLine) => row.lastStopArea },
    { id: 'lastStopType', label: 'Last Stop Type', renderer: (row: TripStatsSummaryLine) => row.lastStopType },
    { id: 'lastStopArrive', label: 'Last Stop Arrival', renderer: (row: TripStatsSummaryLine) => row.lastStopArrive },
    { id: 'lastStopDepart', label: 'Last Stop Departure', renderer: (row: TripStatsSummaryLine) => row.lastStopDepart },
    {
      id: 'returnedShipments',
      label: 'Returned Shipments',
      renderer: (row: TripStatsSummaryLine) => row.returnedShipments,
    },
    { id: 'pickupWeight', label: 'Rem PU Weight', renderer: (row: TripStatsSummaryLine) => row.remainingPickupWeight },
    { id: 'remainingDeliveries', label: 'Rem DL', renderer: (row: TripStatsSummaryLine) => row.remainingDeliveries },
    {
      id: 'completedDeliveries',
      label: 'Completed DL',
      renderer: (row: TripStatsSummaryLine) => row.completedDeliveries,
    },
    { id: 'totalRemaining', label: 'Total Remaining', renderer: (row: TripStatsSummaryLine) => row.totalRemaining },
    { id: 'totalCompleted', label: 'Total Completed', renderer: (row: TripStatsSummaryLine) => row.totalCompleted },
    { id: 'remainingPickups', label: 'Rem PU', renderer: (row: TripStatsSummaryLine) => row.remainingPickups },
  ];
  tripStatsColumnsToDisplay = _map(this.tripStatsColumns, (c) => c.id);

  tripEquipmentColumns = [
    { id: 'route', label: 'Route', renderer: (row: TripEquipmentSummaryLine) => row.route },
    { id: 'trailer', label: 'Trailer', renderer: (row: TripEquipmentSummaryLine) => row.trailer },
    { id: 'dolly', label: 'Dolly', renderer: (row: TripEquipmentSummaryLine) => row.dolly },
    { id: 'year', label: 'Year', renderer: (row: TripEquipmentSummaryLine) => row.year },
    { id: 'status', label: 'Status', renderer: (row: TripEquipmentSummaryLine) => row.status },
    { id: 'door', label: 'Door', renderer: (row: TripEquipmentSummaryLine) => row.door },
    { id: 'routeWeight', label: 'Route Weight', renderer: (row: TripEquipmentSummaryLine) => row.routeWeight },
    { id: 'routeInstId', label: '', renderer: (row: TripEquipmentSummaryLine) => row.routeInstId, isHidden: true },
    { id: 'specialServices', label: 'Special Services' },
  ];
  tripEquipmentColumnsToDisplay = _map(_filter(this.tripEquipmentColumns, (c) => !c.isHidden), (c) => c.id);

  tractor: string = '';

  @Input()
  tripDetails: GetPnDTripResp;

  @Output()
  routeClicked = new EventEmitter<number>();

  constructor(
    private equipmentPipe: EquipmentPipe,
    private timeService: XpoLtlTimeService,
    private decimalPipe: DecimalPipe,
    private pndStore$: Store<PndStoreState.State>,
    private specialServicesService: SpecialServicesService
  ) {}

  ngOnInit() {
    this.tractor = this.equipmentPipe.transform(
      _get(this.tripDetails, 'tripDetail.tractorTripEquipment.equipmentIdPrefix', ''),
      _get(this.tripDetails, 'tripDetail.tractorTripEquipment.equipmentIdSuffixNbr', '')
    );

    this.populateDispatchSummary();
    this.populateTripStats();
    this.populateTripEquipment();
  }

  cellClicked(row: TripEquipmentSummaryLine, columnId: any) {
    if (columnId === 'route') {
      this.routeClicked.emit(row.routeInstId);
    }
  }

  private populateDispatchSummary(): void {
    const toFlag = (n: number) => {
      return n === 0 ? 'No' : 'Yes';
    };

    const trip = _get(this.tripDetails, 'tripDetail.trip', new Trip());
    const tripStops = _get(this.tripDetails, 'tripDetail.tripStops', []);

    const originTripStop = tripStops.filter((tripStop) => tripStop.tripNode.tripNodeTypeCd === 'Origin')[0];
    const destinationTripStop = tripStops.filter((tripStop) => tripStop.tripNode.tripNodeTypeCd === 'Destination')[0];

    const dispatchSummary: DispatchSummaryLine = {
      dontAutoDispatch: toFlag(_get(this.tripDetails, 'tripDetail.trip.doNotAutoDisplayFlag', 0)),
      dispatchGroup: _get(this.tripDetails, 'tripDetail.dispatchGroup.groupName', ''),
      estimatedStart: this.timeService.to24Time(
        _get(originTripStop, 'tripNode.estimatedDepartDateTime'),
        trip.terminalSicCd
      ),
      actualStart: this.timeService.to24Time(
        _get(originTripStop, 'activityTypes[0].actualActivityDateTime'),
        trip.terminalSicCd
      ),
      estimatedClear: this.timeService.to24Time(trip.estimatedEmptyDateTime, trip.terminalSicCd),
      estimatedComplete: this.timeService.to24Time(
        _get(destinationTripStop, 'tripNode.estimatedArriveDateTime'),
        trip.terminalSicCd
      ),
      actualComplete: this.timeService.to24Time(
        _get(destinationTripStop, 'activityTypes[0].actualActivityDateTime'),
        trip.terminalSicCd
      ),
    };

    this.dispatchSummaryDataSourceSubject.next(new MatTableDataSource([dispatchSummary]));
  }

  private populateTripStats(): void {
    this.pndStore$
      .select(GeoAreaStoreSelectors.geoAreaGeoAreas)
      .pipe(take(1))
      .subscribe((geoAreas) => {
        const trip = _get(this.tripDetails, 'tripDetail.trip', new Trip());
        const geoArea = _get(geoAreas, 'geoArea', []).find((a) => a.areaInstId === `${trip.lastStopAreaId}`) as GeoArea;
        const tripStatsSummary: TripStatsSummaryLine = {
          lastStopArea: _get(geoArea, 'geoAreaName', ''),
          lastStopType: trip.lastStopTypeCd,
          lastStopArrive: this.timeService.to24Time(trip.lastStopArrivalDateTime, trip.terminalSicCd),
          lastStopDepart: this.timeService.to24Time(trip.lastStopDateTime, trip.terminalSicCd),
          returnedShipments: trip.shipmentRtndCount,
          remainingPickupWeight: this.decimalPipe.transform(trip.incompletePickupWeightCount || 0),
          remainingDeliveries: trip.deliveryStopCount - trip.deliveryCompletedCount,
          completedDeliveries: trip.deliveryCompletedCount,
          totalRemaining:
            trip.deliveryStopCount - trip.deliveryCompletedCount + (trip.pickupStopCount - trip.pickupCompletedCount),
          totalCompleted: trip.deliveryCompletedCount + trip.pickupCompletedCount,
          remainingPickups: trip.pickupStopCount - trip.pickupCompletedCount,
        };
        this.tripStatsDataSourceSubject.next(new MatTableDataSource([tripStatsSummary]));
      });
  }

  private populateTripEquipment(): void {
    const tripEquipmentSummary = [];
    const routes = _get(this.tripDetails, 'tripDetail.route', []);
    routes.forEach((route: RouteDetail) => {
      const equipmentSummary: TripEquipmentSummaryLine = {
        route: PndRouteUtils.getRouteId(route.route),
        trailer: this.equipmentPipe.transform(
          _get(route, 'equipment.equipmentIdPrefix', ''),
          _get(route, 'equipment.equipmentIdSuffixNbr', '')
        ),
        dolly: '',
        year: `${_get(route, 'equipment.mfrYr', '')}`,
        status: _get(route, 'trailer.trailerLoad.currentStatus', ''),
        door: _get(route, 'route.equipmentDockLocation.dockName', ''),
        routeWeight: this.decimalPipe.transform(_get(route, 'route.totalWeightCount', 0)),
        routeInstId: _get(route, 'route.routeInstId', 0),
        specialServices: this.specialServicesService.getSpecialServicesForCounts(
          _get(this.tripDetails, 'tripDetail.trip')
        ),
      };
      tripEquipmentSummary.push(equipmentSummary);
    });
    if (_get(this.tripDetails, 'tripDetail.dolly')) {
      for (let i = 0; i < this.tripDetails.tripDetail.dolly.length; i++) {
        if (tripEquipmentSummary.length < i + 1) {
          tripEquipmentSummary.push({});
        }
        tripEquipmentSummary[i].dolly = this.equipmentPipe.transform(
          _result(this.tripDetails, `tripDetail.dolly[${i}].equipmentIdPrefix`, ''),
          _result(this.tripDetails, `tripDetail.dolly[${i}].equipmentIdSuffixNbr`, 0)
        );
      }
    }
    this.tripEquipmentDataSourceSubject.next(new MatTableDataSource(tripEquipmentSummary));
  }
}
