import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { DeliveryShipmentSearchRecord, UnassignedStop } from '@xpo-ltl/sdk-cityoperations';
import { TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import { XpoLtlShipmentDescriptor } from '@xpo/ngx-ltl';
import {
  filter as _filter,
  find as _find,
  first as _first,
  forEach as _forEach,
  get as _get,
  has as _has,
  invoke as _invoke,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  map as _map,
  pick as _pick,
  set as _set,
  size as _size,
} from 'lodash';
import { Subscription, timer } from 'rxjs';
import { debounceTime, filter, pairwise, startWith, take, takeUntil } from 'rxjs/operators';
import { PndDialogService } from '../../../../../../core/dialogs/pnd-dialog.service';
import {
  GeoLocationStoreActions,
  PndStoreState,
  UnassignedDeliveriesStoreActions,
  UnassignedDeliveriesStoreSelectors,
} from '../../../../../store';
import { RouteBalancingSelectors } from '../../../../../store/route-balancing-store';
import { UnassignedDeliveriesService } from '../../../../shared';
import { AssignToRouteDialogData } from '../../../../shared/components/assign-to-route/assign-to-route-dialog-data';
import { AssignToRouteComponent } from '../../../../shared/components/assign-to-route/assign-to-route.component';
import { ExistingOrNewRoute } from '../../../../shared/components/assign-to-route/existing-or-new-route.enum';
import { StopMapComponent } from '../../../../shared/components/stop-map/stop-map.component';
import { UnmappedStopDetail } from '../../../../shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stop-detail.model';
import { UnmappedStopsEditMode } from '../../../../shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stops-edit-mode.enum';
import { StoreSourcesEnum } from '../../../../shared/enums/store-sources.enum';
import {
  EventItem,
  UnassignedDeliveryIdentifier,
  consigneeToId,
} from '../../../../shared/interfaces/event-item.interface';
import { MapMarkerIcon, MapMarkerInfo, UnassignedDeliveryMapMarker } from '../../../../shared/models';
import { InteractiveMapMarker } from '../../../../shared/models/markers/map-marker';
import { UnassignedDeliveryMapMarkerCluster } from '../../../../shared/models/markers/unassigned-delivery-map-marker-cluster.model';
import { MapMarkersService } from '../../../../shared/services/map-markers.service';
import { MapToolbarService } from '../../../../shared/services/map-toolbar.service';
import { MappingService } from '../../../../shared/services/mapping.service';
import { SpecialServicesService } from '../../../../shared/services/special-services.service';
import { StopWindowService } from '../../../../shared/services/stop-window.service';
import {
  ContextMenuItem,
  MarkerContextMenuComponent,
} from '../../components/marker-context-menu/marker-context-menu.component';
import { AbstractClusteredMarkerLayer, OverlappedMarkers, ProcessedMarkers } from '../abstract-clustered-marker-layer';

enum ContextMenuItemId {
  AssignStop,
  ShowDetails,
  Zoom,
  EditGeoLocation,
}

@Component({
  selector: 'app-unassigned-deliveries-layer',
  templateUrl: './unassigned-deliveries-layer.component.html',
  styleUrls: ['./unassigned-deliveries-layer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnassignedDeliveriesLayerComponent
  extends AbstractClusteredMarkerLayer<UnassignedDeliveryMapMarker, UnassignedDeliveryMapMarkerCluster>
  implements OnInit {
  @ViewChild(MarkerContextMenuComponent, { static: false }) contextMenu: MarkerContextMenuComponent;

  private delaySubscription: Subscription; // used to delay display of info window on focus

  private isRouteBalancerOpen: boolean = false;

  contextMenuItems: ContextMenuItem<ContextMenuItemId>[] = [
    { id: ContextMenuItemId.AssignStop, label: 'Assign To Route', shouldHide: () => this.isRouteBalancerOpen },
    { id: ContextMenuItemId.ShowDetails, label: 'Shipment Details' },
    { id: ContextMenuItemId.Zoom, label: 'Satellite View' },
    { id: ContextMenuItemId.EditGeoLocation, label: 'Edit Geo Location' },
  ];

  constructor(
    private pndStore$: Store<PndStoreState.State>,
    private markersService: MapMarkersService,
    private stopWindowService: StopWindowService,
    private changeRef: ChangeDetectorRef,
    private mappingService: MappingService,
    private pndDialogService: PndDialogService,
    private dialog: MatDialog,
    private specialServicesService: SpecialServicesService,
    private mapToolbarService: MapToolbarService,
    private unassignedDeliveriesService: UnassignedDeliveriesService
  ) {
    super(new UnassignedDeliveryMapMarkerCluster(), 'DL');
    this.clusteringEnabled = true;
  }

  ngOnInit() {
    // Handle updates
    this.unassignedDeliveriesService.unassignedDeliveries$
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((unassignedDeliveries: UnassignedStop[]) => {
        const deliveryMarkers = unassignedDeliveries
          .filter(
            (unassignedDelivery: UnassignedStop) =>
              unassignedDelivery.consignee.geoCoordinates.latitude !== 0 &&
              unassignedDelivery.consignee.geoCoordinates.longitude !== 0
          )
          .map(
            (unassignedDelivery: UnassignedStop) =>
              new UnassignedDeliveryMapMarker(
                unassignedDelivery,
                this.markersService,
                this.stopWindowService,
                this.specialServicesService
              )
          );

        const markers: ProcessedMarkers<
          UnassignedDeliveryMapMarker,
          UnassignedDeliveryMapMarkerCluster
        > = this.processMarkers(deliveryMarkers, 38);

        this.markersSubject.next(markers.singles);
        this.markerClustersSubject.next(markers.overlapped);
      });

    // Handle selection
    this.pndStore$
      .pipe(
        select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected),
        filter(() => !!this.googleMap),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((items: EventItem<UnassignedDeliveryIdentifier>[]) => {
        // ensure selected markers are flagged as selected
        const zoom = this.googleMap ? this.googleMap.getZoom() : undefined;

        _forEach(this.markers, (marker: UnassignedDeliveryMapMarker) => {
          const isSelected = !!_find(items, (unassignedDelivery) =>
            _isEqual(consigneeToId(unassignedDelivery.id), consigneeToId(marker.rowData))
          );

          marker.isSelected = isSelected;

          this.markersService.updateUnassignedStopMarker(marker, zoom);
        });

        _forEach(
          this.markerClusters,
          (markerClusters: OverlappedMarkers<UnassignedDeliveryMapMarkerCluster, UnassignedDeliveryMapMarker>) => {
            _forEach(markerClusters.markers, (marker: UnassignedDeliveryMapMarker) => {
              const isSelected = !!_find(items, (unassignedDelivery) =>
                _isEqual(consigneeToId(unassignedDelivery.id), consigneeToId(marker.rowData))
              );

              marker.isSelected = isSelected;

              this.markersService.updateUnassignedStopMarker(marker, zoom, marker.icon.anchor);
            });
          }
        );

        // if there are selected deliveries, fit them into bounds and center map to them
        if (_size(items) > 0 && items[items.length - 1].source !== StoreSourcesEnum.PLANNING_MAP) {
          this.mappingService.updateMarkersBounds(
            items.map((i) => i.id.consignee),
            this.googleMap
          );
        }

        this.changeRef.detectChanges();
      });

    // Handle focusing an unassigned delivery
    this.pndStore$
      .pipe(
        select(UnassignedDeliveriesStoreSelectors.unassignedDeliveryFocused),
        debounceTime(500),
        filter(() => this.showMarkers),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((item: EventItem<UnassignedDeliveryIdentifier>) => {
        // ensure the focused deliveries are selected and others are not
        const focusedDeliveryId = consigneeToId(item.id);
        let focusedMarker: UnassignedDeliveryMapMarker;
        let clusterOfFocusedMarker: UnassignedDeliveryMapMarkerCluster;
        const markers = this.markers;
        const markerClusters = this.markerClustersSubject.value;
        const zoom = this.googleMap ? this.googleMap.getZoom() : undefined;

        _forEach(markers, (marker: UnassignedDeliveryMapMarker) => {
          const isFocused = _isEqual(focusedDeliveryId, consigneeToId(marker.rowData));
          marker.isInfoWindowOpened = isFocused && marker.isInfoWindowOpened;
          marker.isFocused = isFocused;

          this.markersService.updateUnassignedStopMarker(marker, zoom);

          focusedMarker = isFocused ? marker : focusedMarker;
        });

        _forEach(
          markerClusters,
          (overlappedMarkers: OverlappedMarkers<UnassignedDeliveryMapMarkerCluster, UnassignedDeliveryMapMarker>) => {
            _forEach(overlappedMarkers.markers, (marker: UnassignedDeliveryMapMarker) => {
              const isFocused = _isEqual(focusedDeliveryId, consigneeToId(marker.rowData));
              marker.isInfoWindowOpened = isFocused && marker.isInfoWindowOpened;
              marker.isFocused = isFocused;

              this.markersService.updateUnassignedStopMarker(marker, zoom, marker.icon.anchor);

              if (isFocused) {
                focusedMarker = marker;
                clusterOfFocusedMarker = overlappedMarkers.clusterer;
              }
            });
          }
        );

        _invoke(this.delaySubscription, 'unsubscribe');
        this.delaySubscription = timer(400).subscribe(() => {
          // open the info window for focused marker
          _set(clusterOfFocusedMarker, 'isSelected', true);
          _set(focusedMarker, 'isInfoWindowOpened', true);

          if (item && item.source && item.source !== StoreSourcesEnum.PLANNING_MAP && _has(item, 'id.consignee')) {
            this.mappingService.updateMarkersBounds([item.id.consignee], this.googleMap);
          }

          this.markersSubject.next(this.markers);
          this.markerClustersSubject.next(this.markerClusters);
        });

        this.markersSubject.next(markers);
        this.markerClustersSubject.next(markerClusters);
      });

    this.zoomLevel$.pipe(startWith(-1), pairwise(), takeUntil(this.unsubscriber.done)).subscribe(([lastZoom, zoom]) => {
      // update the markers when zoom level changes
      if (
        (lastZoom <= this.markersService.ZOOM_LEVEL && zoom >= this.markersService.ZOOM_LEVEL) ||
        (lastZoom >= this.markersService.ZOOM_LEVEL && zoom <= this.markersService.ZOOM_LEVEL)
      ) {
        this.markersSubject.value.forEach((marker) => {
          this.markersService.updateUnassignedStopMarker(marker, zoom);
        });

        this.markerClustersSubject.value.forEach((overlappedMarkers) => {
          // TODO: 18/38 should be defined in the marker class
          this.calculateVirtualPositionForClusteredMarkers(overlappedMarkers.markers, zoom < 7 ? 18 : 38);

          overlappedMarkers.markers.forEach((marker) => {
            this.markersService.updateUnassignedStopMarker(marker, zoom, marker.icon.anchor);
          });
        });
      }
    });

    this.pndStore$
      .select(RouteBalancingSelectors.openRouteBalancingPanel)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((isOpen) => {
        this.isRouteBalancerOpen = isOpen;
      });
  }

  onMouseOver(marker: UnassignedDeliveryMapMarker) {
    this.pndStore$.dispatch(
      new UnassignedDeliveriesStoreActions.SetFocusedDelivery({
        focusedDelivery: {
          id: {
            consignee: _pick(marker.rowData.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
          },
          source: StoreSourcesEnum.PLANNING_MAP,
        },
      })
    );
  }

  onMouseOut(marker: UnassignedDeliveryMapMarker) {
    this.pndStore$.dispatch(
      new UnassignedDeliveriesStoreActions.SetFocusedDelivery({
        focusedDelivery: {
          id: undefined,
          source: StoreSourcesEnum.PLANNING_MAP,
        },
      })
    );
  }

  onClick(marker: UnassignedDeliveryMapMarker) {
    // tslint:disable-next-line
    // add/remove the delivery from the list of selected deliveries
    this.pndStore$
      .pipe(select(UnassignedDeliveriesStoreSelectors.unassignedDeliveriesSelected), take(1))
      .subscribe((items: EventItem<UnassignedDeliveryIdentifier>[]) => {
        // Preserve previous selection
        let selectedDeliveries: EventItem<UnassignedDeliveryIdentifier>[] = [...items];
        if (!marker.isSelected) {
          const selectedDelivery: EventItem<UnassignedDeliveryIdentifier> = {
            id: {
              consignee: _pick(marker.rowData.consignee, ['acctInstId', 'name1', 'latitudeNbr', 'longitudeNbr']),
            },
            source: StoreSourcesEnum.PLANNING_MAP,
          };
          if (!_find(selectedDeliveries, (item) => consigneeToId(item.id) === consigneeToId(selectedDelivery.id))) {
            selectedDeliveries.push(selectedDelivery);
          } else {
            selectedDelivery.source = StoreSourcesEnum.PLANNING_MAP;
          }
          marker.isSelected = true;
        } else {
          selectedDeliveries = _filter(
            items,
            (unassignedDelivery) => !_isEqual(consigneeToId(unassignedDelivery.id), consigneeToId(marker.rowData))
          );
          marker.isSelected = false;
        }

        this.pndStore$.dispatch(
          new UnassignedDeliveriesStoreActions.SetSelectedDeliveries({
            selectedDeliveries: selectedDeliveries,
          })
        );
      });
  }

  onRightClick(marker: InteractiveMapMarker, event: MouseEvent) {
    this.contextMenu.openMenu(event.clientX, event.clientY).subscribe((item: ContextMenuItem<ContextMenuItemId>) => {
      if (item) {
        switch (item.id) {
          case ContextMenuItemId.ShowDetails:
            this.showDetails(marker as UnassignedDeliveryMapMarker);
            break;

          case ContextMenuItemId.Zoom:
            this.showSatelliteView(marker as UnassignedDeliveryMapMarker);
            break;

          case ContextMenuItemId.AssignStop:
            this.openAssignToRouteDialog(marker as UnassignedDeliveryMapMarker);
            break;

          case ContextMenuItemId.EditGeoLocation:
            this.editGeoLocation(marker as UnassignedDeliveryMapMarker);
            break;
        }
      }
    });
  }

  private editGeoLocation(marker: UnassignedDeliveryMapMarker) {
    const markerInfo: MapMarkerInfo = marker.markerInfo;

    const stopDetail: UnmappedStopDetail = {
      acctInstId: markerInfo.acctInstId,
      stopName: markerInfo.consigneeName,
      stopTypeCd: TripNodeActivityCd.DELIVER_SHIPMENT,
      address: `${markerInfo.consigneeAddress}, ${markerInfo.consigneeCity}, ${markerInfo.consigneeState} ${markerInfo.consigneeZipCode}`,
      location: new google.maps.LatLng(marker.latitude, marker.longitude),
      isFutureCustomer: _isEmpty(markerInfo.acctMadCd),
    };
    this.pndStore$.dispatch(new GeoLocationStoreActions.SetEditMode(UnmappedStopsEditMode.UnassignedDelivery));
    this.pndStore$.dispatch(new GeoLocationStoreActions.SetStopToEdit(stopDetail));
  }

  private showDetails(marker: UnassignedDeliveryMapMarker) {
    const shipmentsAtStop = _map(_get(marker, 'rowData.deliveryShipments'), (record: DeliveryShipmentSearchRecord) => {
      return {
        proNbr: record.proNbr,
        shipmentInstId: record.shipmentInstId,
      } as XpoLtlShipmentDescriptor;
    });
    this.pndDialogService.showShipmentDetailsDialog(shipmentsAtStop).subscribe();
  }

  private showSatelliteView(marker: UnassignedDeliveryMapMarker) {
    const consigneeName = _get(marker, 'markerInfo.consigneeName');

    this.dialog.open(StopMapComponent, {
      data: {
        consigneeName,
        geoCoordinates: { latitude: marker.latitude, longitude: marker.longitude },
      },
      disableClose: true,
      hasBackdrop: false,
    });
  }

  onClusterMarkerClick(marker: UnassignedDeliveryMapMarkerCluster) {
    const markerClusters = this.markerClustersSubject.value;

    for (const overlappedMarkers of markerClusters) {
      if (marker.clusterMarkerId === overlappedMarkers.clusterer.clusterMarkerId) {
        overlappedMarkers.clusterer.isSelected = !overlappedMarkers.clusterer.isSelected;
        break;
      }
    }

    this.markerClustersSubject.next(markerClusters);
  }

  onClusterMarkerRightClick(marker: UnassignedDeliveryMapMarkerCluster, event: MouseEvent) {
    const markerClusters = this.markerClustersSubject.value;

    this.contextMenu.openMenu(event.clientX, event.clientY).subscribe((item: ContextMenuItem<ContextMenuItemId>) => {
      if (item) {
        switch (item.id) {
          case ContextMenuItemId.Zoom:
            for (const overlappedMarkers of markerClusters) {
              if (marker.clusterMarkerId === overlappedMarkers.clusterer.clusterMarkerId) {
                this.showSatelliteView(_first(overlappedMarkers.markers));
              }
            }
            break;
        }
      }
    });
  }

  getClusterMarkerIcon(markerType: string, clusteredMarkers: number): MapMarkerIcon {
    return this.markersService.getClusterMarkerIcon(markerType, clusteredMarkers);
  }

  private openAssignToRouteDialog(marker: UnassignedDeliveryMapMarker): void {
    // TODO: note we need the full delivery shipment search record structure which includes consignee.
    // BUT we only want the ones selected and currently we get all
    const shipmentsAtStop = _get(marker, 'rowData.deliveryShipments', []) as DeliveryShipmentSearchRecord[];
    const acctInstId = _get(marker, 'rowData.consignee.acctInstId', -1);
    const selectedShipments = new Map<number, DeliveryShipmentSearchRecord[]>();
    selectedShipments.set(acctInstId, shipmentsAtStop);

    const dialogRef = this.dialog.open(AssignToRouteComponent, {
      data: <AssignToRouteDialogData>{
        initialMode: ExistingOrNewRoute.Existing,
        selectedShipments,
      },
      disableClose: true,
      hasBackdrop: true,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((actionPerformed) => {
        if (actionPerformed) {
          this.mapToolbarService.toggleDrawModeOff();
        }
      });
  }

  trackMarkerBy(index, item: UnassignedDeliveryMapMarker) {
    if (!item) {
      return null;
    }
    return `${item.rowData.consignee.acctInstId}-${item.latitude}-${item.longitude}`;
  }

  trackClusterBy(index, item: OverlappedMarkers<UnassignedDeliveryMapMarkerCluster, UnassignedDeliveryMapMarker>) {
    if (!item) {
      return null;
    }
    return item.clusterer.clusterMarkerId;
  }
}
