import { Stop } from '@xpo-ltl/sdk-cityoperations';
import { RouteCategoryCd, TripStatusCd, RouteStatusCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject } from 'rxjs';
import { StopCard } from '../stop-card.model';
import { AbstractBoardLane } from './abstract-board-lane.model';
import { PinnedStops } from './pinned-stops.model';

export enum TypesOfRoute {
  EXISTING_ROUTE = 'EXISTING-ROUTE',
  NEW_ROUTE = 'NEW-ROUTE',
}

export class RouteBoardLane extends AbstractBoardLane {
  tripInstId: number;
  routeInstId: number;
  typeOfRoute: TypesOfRoute;

  routePrefix?: string;
  routeSuffix?: string;
  routeSatelliteSic?: string = '';
  routeStatusCd?: RouteStatusCd;
  routeCategoryCd?: RouteCategoryCd;

  tripStatusCd: TripStatusCd;
  estimatedEmptyDateTime: Date;

  color: string;

  isStartTimeValid: boolean;
  isStartTimeTouched: boolean;
  isStopsTouched: boolean;
  isSameStops: boolean;
  focusStartTime: boolean;

  tripStartDate: string;

  startTime$: BehaviorSubject<string> = new BehaviorSubject(undefined);
  get startTime() {
    return this.startTime$.value;
  }

  canAutoSequence: boolean = true;
  canResequenceRoute: boolean = true;

  autoSequencePerformed: boolean;
  dragAndDropPerformed: boolean;

  selectedRoutesDrivers: string;

  origin: Stop;
  destination: Stop;

  assignedTotalWeight: number;
  assignedTotalMotorMoves: number;
  unassignedTotalWeight: number;
  unassignedTotalMotorMoves: number;

  originalStops: StopCard[] = [];

  assignedStopsSubject$: BehaviorSubject<StopCard[]> = new BehaviorSubject([]);
  get assignedStops(): StopCard[] {
    return this.assignedStopsSubject$.value;
  }

  unassignedStopsSubject$: BehaviorSubject<StopCard[]> = new BehaviorSubject([]);
  get unassignedStops(): StopCard[] {
    return this.unassignedStopsSubject$.value;
  }

  pinnedStopsSubject$: BehaviorSubject<PinnedStops> = new BehaviorSubject(null);
  get pinnedStops(): PinnedStops {
    return this.pinnedStopsSubject$.value;
  }

  get routeName(): string {
    return this.routePrefix && this.routeSuffix ? `${this.routePrefix}-${this.routeSuffix}` : '';
  }

  constructor(routeInstId: number, tripInstId: number) {
    super();
    this.routeInstId = routeInstId;
    this.tripInstId = tripInstId;
    this.typeOfRoute = TypesOfRoute.EXISTING_ROUTE;

    this.assignedStopsSubject$.subscribe((stops) => {
      this.updateStopsEquality(stops);

      this.assignedTotalWeight = stops.reduce((prev, curr) => {
        return prev + curr.totalWeight;
      }, 0);

      this.assignedTotalMotorMoves = stops.reduce((prev, curr) => {
        return prev + curr.totalMotorMoves;
      }, 0);

      const canResequenceAssignedStops = stops.length !== 0 && !stops.find((stop) => !stop.canResequenceStop);
      this.canAutoSequence = this.canResequenceRoute && canResequenceAssignedStops;
    });

    this.unassignedStopsSubject$.subscribe((stops) => {
      this.unassignedTotalWeight = stops.reduce((prev, curr) => {
        return prev + curr.totalWeight;
      }, 0);

      this.unassignedTotalMotorMoves = stops.reduce((prev, curr) => {
        return prev + curr.totalMotorMoves;
      }, 0);
    });
  }

  private sortBySequenceNumber(stops: StopCard[]) {
    const orderedStops = [...stops];

    orderedStops.sort((a, b) => {
      if (!a.seqNo && !b.seqNo) {
        return a.origSeqNo - b.origSeqNo;
      }
      if (!a.seqNo) {
        return 1;
      }
      if (!b.seqNo) {
        return -1;
      }
      return a.seqNo - b.seqNo;
    });

    return orderedStops;
  }

  sortStops(stops: StopCard[], pinnedStops?: { first: StopCard; last: StopCard }): StopCard[] {
    let orderedStops = [...stops];

    if (!pinnedStops) {
      pinnedStops = {
        first: undefined,
        last: undefined,
      };
    }

    if (pinnedStops.first) {
      orderedStops.splice(
        orderedStops.findIndex((stop) => pinnedStops.first.origSeqNo === stop.origSeqNo),
        1
      );
    }

    if (pinnedStops.last) {
      orderedStops.splice(
        orderedStops.findIndex((stop) => pinnedStops.last.origSeqNo === stop.origSeqNo),
        1
      );
    }

    orderedStops = this.sortBySequenceNumber(orderedStops);
    if (pinnedStops.first) {
      orderedStops.unshift(pinnedStops.first);
    }

    if (pinnedStops.last) {
      orderedStops.push(pinnedStops.last);
    }

    return orderedStops;
  }

  /**
   * Checks if the original stops match with the current stops
   */
  updateStopsEquality(currentAssignedStops: StopCard[]) {
    this.isSameStops = true;
    const allStops = [...currentAssignedStops, ...this.unassignedStops];
    if (allStops.length !== this.originalStops.length) {
      this.isSameStops = false;
    } else {
      for (let i = 0; i < allStops.length; i++) {
        const stop = allStops[i];
        if (
          !this.originalStops.find((stp) => stp.routeInstId === stop.routeInstId && stp.origSeqNo === stop.origSeqNo)
        ) {
          this.isSameStops = false;
          break;
        }
      }
    }
  }
}
