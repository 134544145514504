import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';
import { DecimalPipe } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TooltipPosition } from '@angular/material/tooltip';
import {
  CityOperationsApiService,
  ListPnDStopsPath,
  Route,
  RouteDetail,
  Stop,
  Trip,
  TripDriver,
  GetPnDTripResp,
  Metric,
} from '@xpo-ltl/sdk-cityoperations';
import { TripNodeStatusCd, MetricValueKeyCd, TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import { GetOdsShipmentQuery, GetOdsShipmentResp, ShipmentOdsApiService } from '@xpo-ltl/sdk-shipmentods';
import { XpoLtlShipmentDetailsConfig, XpoLtlShipmentDetailsTabs, XpoLtlTimeService } from '@xpo/ngx-ltl';
import { filter as _filter, forEach as _forEach, get as _get, map as _map, orderBy as _orderBy } from 'lodash';
import * as moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
import { DocumentViewerComponent } from '../../../../../shared/document-viewer';
import { PndRouteUtils } from '../../../../../shared/route-utils';
import { EquipmentPipe } from '../../pipes';
import { ShipmentSummaryLine } from './components/shipment-details/shipment-details.component';

export interface RouteDetailsData {
  selectedRoute?: number;
  selectedStop?: number;
  tripDetails: GetPnDTripResp;
}

interface RouteSummaryLine {
  status: string;
  feetAvailable: number;
  feetAsOfTime: string;
  stops: number;
  bills: number;
  lp: number;
  mm: number;
  weight: string;
  cube: number;
}

interface CurrentView {
  isTrip?: boolean;
  isRoute?: boolean;
  isStop?: boolean;
  isShipment?: boolean;
}

@Component({
  selector: 'pnd-route-summary',
  templateUrl: './route-summary.component.html',
  styleUrls: ['./route-summary.component.scss'],
  host: { class: 'pnd-RouteSummary' },
  encapsulation: ViewEncapsulation.None,
})
export class RouteSummaryComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RouteDetailsData,
    private cityOperationsService: CityOperationsApiService,
    private shipmentOdsApiService: ShipmentOdsApiService,
    private timeService: XpoLtlTimeService,
    private decimalPipe: DecimalPipe,
    private equipmentPipe: EquipmentPipe
  ) {}

  trip: Trip;
  driver: TripDriver;

  columns = [
    { id: 'status', label: 'Status', renderer: (row: RouteSummaryLine) => row.status },
    { id: 'feetAvailable', label: 'Feet Available', renderer: (row: RouteSummaryLine) => row.feetAvailable },
    { id: 'feetAsOfTime', label: 'Feet as of Time', renderer: (row: RouteSummaryLine) => row.feetAsOfTime },
    { id: 'bills', label: 'Bills', renderer: (row: RouteSummaryLine) => row.bills },
    { id: 'mm', label: 'MM', renderer: (row: RouteSummaryLine) => row.mm },
    { id: 'weight', label: 'Weight (lbs.)', renderer: (row: RouteSummaryLine) => row.weight },
    { id: 'cube', label: 'Cube', renderer: (row: RouteSummaryLine) => row.cube },
  ];
  columnsToDisplay = _map(this.columns, (c) => c.id);

  position: TooltipPosition = 'below';
  color: ThemePalette = 'primary';
  caretPosition: HorizontalConnectionPos | VerticalConnectionPos = 'start';

  private incompleteStopsSubject = new BehaviorSubject<Stop[]>(undefined);
  incompleteStops$ = this.incompleteStopsSubject.asObservable();
  private completeStopsSubject = new BehaviorSubject<Stop[]>(undefined);
  completeStops$ = this.completeStopsSubject.asObservable();

  private dataSourceSubject = new BehaviorSubject<MatTableDataSource<RouteSummaryLine>>(undefined);
  dataSource$ = this.dataSourceSubject.asObservable();

  private driverDetailsSubject = new BehaviorSubject<string>(undefined);
  driverDetails$ = this.driverDetailsSubject.asObservable();

  private currentRouteSubject = new BehaviorSubject<RouteDetail>(undefined);
  currentRoute$ = this.currentRouteSubject.asObservable();
  private currentRouteNameSubject = new BehaviorSubject<string>('');
  currentRouteName$ = this.currentRouteNameSubject.asObservable();
  private currentTrailerNumberSubject = new BehaviorSubject<string>('');
  currentTrailerNumber$ = this.currentTrailerNumberSubject.asObservable();

  private currentStopSubject = new BehaviorSubject<Stop>(undefined);
  currentStop$ = this.currentStopSubject.asObservable();

  private currentProSubject = new BehaviorSubject<string>(undefined);
  currentPro$ = this.currentProSubject.asObservable();

  private currentShipmentSubject = new BehaviorSubject<GetOdsShipmentResp>(undefined);
  currentShipment$ = this.currentShipmentSubject.asObservable();

  private currentViewSubject = new BehaviorSubject<CurrentView>(undefined);
  currentView$ = this.currentViewSubject.asObservable();

  private currentViewNameSubject = new BehaviorSubject<string>(undefined);
  currentViewName$ = this.currentViewNameSubject.asObservable();

  private metricsSubject = new BehaviorSubject<Metric[]>(undefined);
  metrics$ = this.metricsSubject.asObservable();

  shipmentDetailsConfig: XpoLtlShipmentDetailsConfig = {
    tabs: [{ id: XpoLtlShipmentDetailsTabs.Details }, { id: XpoLtlShipmentDetailsTabs.History }],
    hideCharges: true,
    tabInfoComponent: DocumentViewerComponent,
  };

  private selectedRoute: number;

  ngOnInit() {
    this.setCurrentView({ isRoute: true });

    this.selectedRoute = _get(this.data, 'selectedRoute');
    const selectedStop = _get(this.data, 'selectedStop');
    this.trip = _get(this.data, 'tripDetails.tripDetail.trip');
    this.driver = _get(this.data, 'tripDetails.tripDetail.tripDriver');

    const routeDetail = _filter(
      _get(this.data, 'tripDetails.tripDetail.route', []),
      (r) => _get(r, 'route.routeInstId') === this.selectedRoute
    )[0];
    this.updateRouteDetails(routeDetail, selectedStop);

    this.metricsSubject.next(_get(this.data, 'tripDetails.tripDetail.metrics', []));
  }

  onViewStop(stop: Stop): void {
    this.setCurrentView({ isStop: true });
    this.currentStopSubject.next(stop);
  }

  onViewRoute(routeInstId: number): void {
    const routeDetail = _filter(
      _get(this.data, 'tripDetails.tripDetail.route', []),
      (r) => _get(r, 'route.routeInstId') === routeInstId
    )[0];
    if (routeDetail) {
      this.setCurrentView({ isRoute: true });
      this.updateRouteDetails(routeDetail);
    }
  }

  onViewShipment(activity: ShipmentSummaryLine): void {
    this.setCurrentView({ isShipment: true });
    this.currentProSubject.next(activity.proNbr);

    const queryParams = new GetOdsShipmentQuery();
    if (activity.shipmentInstId) {
      queryParams.shipmentInstId = activity.shipmentInstId;
    } else {
      queryParams.pickupDate = moment(this.trip.tripDate).toDate();
      queryParams.proNbr = activity.proNbr;
    }
    this.shipmentOdsApiService.getOdsShipment(queryParams).subscribe((shipment) => {
      if (shipment) {
        this.currentShipmentSubject.next(shipment);
      }
    });
  }

  tripClicked(): void {
    if (!_get(this.currentViewSubject, 'value.isTrip', false)) {
      this.setCurrentView({ isTrip: true });
      this.currentRouteNameSubject.next(undefined);
      this.currentRouteSubject.next(undefined);
      this.currentStopSubject.next(undefined);
      this.currentShipmentSubject.next(undefined);
      this.currentProSubject.next(undefined);
    }
  }

  routeClicked(): void {
    if (!_get(this.currentViewSubject, 'value.isRoute', false)) {
      this.setCurrentView({ isRoute: true });
      this.currentStopSubject.next(undefined);
      this.currentShipmentSubject.next(undefined);
      this.currentProSubject.next(undefined);
    }
  }

  stopClicked(): void {
    if (!_get(this.currentViewSubject, 'value.isStop', false)) {
      this.setCurrentView({ isStop: true });
      this.currentShipmentSubject.next(undefined);
      this.currentProSubject.next(undefined);
    }
  }

  private updateRouteDetails(routeDetail: RouteDetail, selectedStop?: number): void {
    this.currentRouteSubject.next(routeDetail);

    const routeName = PndRouteUtils.getRouteId(_get(routeDetail, 'route', { ...new Route() }));
    this.currentRouteNameSubject.next(routeName);

    const trailerNumber = this.equipmentPipe.transform(
      _get(routeDetail, 'equipment.equipmentIdPrefix', ''),
      _get(routeDetail, 'equipment.equipmentIdSuffixNbr', 0)
    );
    this.currentTrailerNumberSubject.next(trailerNumber);

    const routeSummary: RouteSummaryLine = {
      status: _get(routeDetail, 'route.statusCd', ''),
      feetAvailable: _get(routeDetail, 'trailer.tripEquipment.lengthUOMFeetNbr', 0),
      feetAsOfTime: this.timeService.to24Time(
        _get(routeDetail, 'trailer.tripEquipment.lengthUOMUpdateDateTime'),
        _get(routeDetail, 'route.terminalSicCd')
      ),
      stops: _get(routeDetail, 'route.pickupStopCount', 0) + _get(routeDetail, 'route.deliveryStopCount', 0),
      bills: _get(routeDetail, 'route.totalBillCount', 0),
      lp: _get(routeDetail, 'route.totalPiecesCount', 0),
      mm: _get(routeDetail, 'route.totalMmCount', 0),
      weight: this.decimalPipe.transform(_get(routeDetail, 'route.totalWeightCount', 0)),
      cube: _get(routeDetail, 'route.totalCubePercentage', 0),
    };
    this.dataSourceSubject.next(new MatTableDataSource([routeSummary]));

    const request = new ListPnDStopsPath();
    request.routeInstId = `${_get(routeDetail, 'route.routeInstId') || this.selectedRoute}`;

    this.cityOperationsService.listPnDStops(request).subscribe((response) => {
      const allStops = _get(response, 'stops', []) as Stop[];
      const incompleteStops = allStops.filter(
        (stop) => stop.tripNode.nodeTypeCd !== 'ServiceCenter' && stop.tripNode.statusCd !== TripNodeStatusCd.COMPLETED
      );
      const completeStops = allStops.filter(
        (stop) => stop.tripNode.nodeTypeCd !== 'ServiceCenter' && stop.tripNode.statusCd === TripNodeStatusCd.COMPLETED
      );

      this.incompleteStopsSubject.next(
        _orderBy(incompleteStops, (s) => _get(s, 'activities[0].routeShipment.routeSequenceNbr'))
      );
      this.completeStopsSubject.next(
        _orderBy(completeStops, (s) => {
          const departureActivity = s.activities.find(
            (a) => _get(a, 'tripNodeActivity.activityCd') === TripNodeActivityCd.DEPART_DISPATCH
          );
          return _get(departureActivity, 'tripNodeActivity.actualActivityDateTime');
        })
      );

      if (selectedStop) {
        const stop = allStops.find((s) => _get(s, 'tripNode.stopSequenceNbr') === selectedStop);

        if (stop) {
          this.onViewStop(stop);
        }
      }
    });
  }

  private setCurrentView(view: CurrentView): void {
    this.currentViewSubject.next(view);
    if (view.isTrip) {
      this.currentViewNameSubject.next('Trip Details');
    } else if (view.isRoute) {
      this.currentViewNameSubject.next('Route Details');
    } else if (view.isStop) {
      this.currentViewNameSubject.next('Stop Details');
    } else if (view.isShipment) {
      this.currentViewNameSubject.next('Shipment Details');
    }
  }
}
