import { Activity, Route, Stop, TripNodeActivity } from '@xpo-ltl/sdk-cityoperations';
import { RouteStatusCd, TripNodeStatusCd } from '@xpo-ltl/sdk-common';
import {
  countBy as _countBy,
  filter as _filter,
  first as _first,
  get as _get,
  has as _has,
  map as _map,
  size as _size,
  uniq as _uniq,
} from 'lodash';
import { ActivityTypePipe } from '../../pipes';
import { MapMarkersService } from '../../services/map-markers.service';
import { SpecialServicesService } from '../../services/special-services.service';
import { StopWindowService } from '../../services/stop-window.service';
import { MapMarkerInfo } from '../map-marker-info.model';
import { TripStatusEnum } from '../trip-status.enum';
import { ClusterableMarker } from './clusterable-marker';
import { MapMarkerWithInfoWindow } from './map-marker-with-info-window';

export class AssignedStopMapMarker extends MapMarkerWithInfoWindow<MapMarkerInfo> implements ClusterableMarker {
  stop: Stop;
  routeInstId: number;
  routeName: string;
  tripInstId: number;
  tripNodeSequenceNbr: number;
  seqNo: number;
  origSeqNo: number;
  tripNodeStatus: TripNodeStatusCd;
  routeStatus: RouteStatusCd;
  color: string;
  infoWindowTopOffset: number = 0;
  infoWindowLeftOffset: number = 0;
  infoWindowSpaceBetweenMarker: number = 0;

  constructor(
    route: Route,
    stop: Stop,
    color: string,
    private mapMarkerService: MapMarkersService,
    private stopWindowService: StopWindowService,
    private specialServicesService: SpecialServicesService,
    isVisible: boolean,
    private activityTypePipe: ActivityTypePipe
  ) {
    super();

    this.stop = stop;
    this.color = color;

    this.latitude = _get(stop, 'customer.latitudeNbr');
    this.longitude = _get(stop, 'customer.longitudeNbr');

    this.routeInstId = route.routeInstId;
    this.tripInstId = stop.tripNode.tripInstId;
    this.tripNodeSequenceNbr = stop.tripNode.tripNodeSequenceNbr;
    this.seqNo = stop.tripNode.stopSequenceNbr;
    this.origSeqNo = _get(stop, 'tripNode.originalStopSequenceNbr') || _get(stop, 'tripNode.stopSequenceNbr');
    this.tripNodeStatus = this.stop.tripNode.statusCd;
    this.routeStatus = route.statusCd;
    this.isSelected = false;
    this.isVisible = isVisible;

    this.routeName = route.routeName ? route.routeName : `${route.routePrefix}-${route.routeSuffix}`;

    const activityTypes = _countBy(stop.activities || [], (a) => _get(a, 'tripNodeActivity.activityCd', ''));

    let totalWeight = 0;
    let totalMM = 0;
    let totalPieces = 0;
    _filter(stop.activities, (activity: Activity) => _has(activity, 'routeShipment')).forEach((activity) => {
      totalWeight += _get(activity, 'tripNodeActivity.totalWeightCount', 0);
      totalMM += _get(activity, 'tripNodeActivity.totalMmCount', 0);
      totalPieces += _get(activity, 'tripNodeActivity.totalBillCount', 0);
    });

    this.markerInfo = new MapMarkerInfo(
      this.stopWindowService,
      {
        totalWeightLbs: totalWeight,
        motorizedPiecesCount: totalMM,
        totalShipmentsCount: totalPieces,
        stopType: this.activityTypePipe.transform(activityTypes.length > 1 ? 'MX' : Object.keys(activityTypes)[0]),
        color,
      },
      this.routeStatus,
      TripStatusEnum.Cancelled,
      this.routeInstId.toString()
    );
    this.markerInfo.specialServices = this.specialServicesService.getSpecialServicesForStop(stop);

    // generate initial icon
    this.updateIcon(10, this.mapMarkerService);
  }

  updateIcon(zoomLevel: number, mapMarkerService: MapMarkersService, anchor?: google.maps.Point) {
    const stopType: string = mapMarkerService.activityCdForActivities(this.stop.activities);

    this.icon = mapMarkerService.getMarkerIconAssigned(
      stopType,
      zoomLevel,
      this.isSelected || this.isFocused,
      this.color,
      this.tripNodeStatus,
      this.seqNo,
      anchor
    );
  }
}
