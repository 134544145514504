import { XpoSelectFilterParams, XpoSelectFilter } from '@xpo-ltl/ngx-ltl-board';

export class TripStatusFilter extends XpoSelectFilter {
  constructor(field: string) {
    super(<XpoSelectFilterParams>{
      field: field,
      label: 'Status',
      options: [
        { title: 'New', code: 'new' },
        { title: 'Dispatched', code: 'dispatched' },
        { title: 'Returning', code: 'returning' },
        { title: 'Completed', code: 'completed' },
      ],
    });
  }
}
