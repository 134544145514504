import { Component, ViewEncapsulation, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TripNodeActivityCd } from '@xpo-ltl/sdk-common';
import { Unsubscriber } from '@xpo/ngx-ltl';
import { get as _get, toUpper as _toUpper } from 'lodash';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { NotificationMessageService } from '../../../../../../core/services';
import {
  GeoLocationStoreActions,
  GeoLocationStoreSelectors,
  PndStoreState,
  UnassignedDeliveriesStoreActions,
  UnassignedPickupsStoreActions,
} from '../../../../../store';
import { UnmappedStopStatusCode } from '../../../../shared/components/unmapped-stops/classes/unmapped-stop-status-code.enum';
import { UnmappedStopDetail } from '../../../../shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stop-detail.model';
import { UnmappedStopsEditMode } from '../../../../shared/components/unmapped-stops/components/unmapped-stop-detail/unmapped-stops-edit-mode.enum';
import { DraggableMapMarker, MarkerDragEvent, UnmappedStopMarker } from '../../../../shared/models/markers/map-marker';
import { ActivityCdPipe } from '../../../../shared/pipes/activity-cd.pipe';
import {
  GeocodeResult,
  GeoLocationService,
  GeocodeCustomLocationTypes,
} from '../../../../shared/services/geo-location.service';
import { MapMarkersService } from '../../../../shared/services/map-markers.service';
import { DELIVERY_COLOR, PICKUP_COLOR } from '../../../../shared/services/stop-colors';

const DEFAULT_ZOOM = 17;

export enum GeoLocationActionType {
  Accept = 0,
  MoveMarker = 1,
  Cancel = 2,
  Close = 3,
}

export interface UnmappedInfoWindowAction {
  text: string;
  type: GeoLocationActionType;
}

@Component({
  selector: 'app-unmapped-stops-layer',
  templateUrl: './unmapped-stops-layer.component.html',
  styleUrls: ['./unmapped-stops-layer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UnmappedStopsLayerComponent implements OnInit, OnDestroy {
  private unsubscriber = new Unsubscriber();

  @Input() private googleMap: any;

  private originalAddress: GeocodeResult;
  private originalStopDetail: UnmappedStopDetail; // stop we are currently editing

  private currentAddressSubject = new BehaviorSubject<GeocodeResult>(undefined);
  readonly currentAddress$ = this.currentAddressSubject.asObservable();
  get currentAddress(): GeocodeResult {
    return this.currentAddressSubject.value;
  }

  unmappedMarker: DraggableMapMarker; // marker used to edit location on map
  infoWindowMessage: string;
  infoWindowActions: UnmappedInfoWindowAction[];

  // map settings before the stop editor was activated
  private preEditMap: {
    zoom: number;
    center: google.maps.LatLng;
  };

  constructor(
    private mapMarkerService: MapMarkersService,
    private activityCdPipe: ActivityCdPipe,
    private pndStore$: Store<PndStoreState.State>,
    private geoLocationService: GeoLocationService,
    private notificationMessageService: NotificationMessageService
  ) {}

  ngOnInit() {
    this.pndStore$
      .select(GeoLocationStoreSelectors.stopToEdit)
      .pipe(takeUntil(this.unsubscriber.done))
      .subscribe((stopDetail) => {
        this.initEditForStop(stopDetail);
      });
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  /**
   * set the stop we are editing and initialize marker and editor
   */
  private initEditForStop(stopDetail: UnmappedStopDetail) {
    this.unmappedMarker = undefined;
    this.originalAddress = undefined;
    this.currentAddressSubject.next(undefined);
    this.originalStopDetail = stopDetail;

    if (stopDetail) {
      if (!this.preEditMap) {
        // When we first open the editor, save the zoom and center of the map
        // so we can restore when we exit
        this.preEditMap = {
          zoom: this.googleMap.getZoom(),
          center: this.googleMap.getCenter(),
        };
      }

      this.buildMarker(stopDetail);

      // make sure we are in hybrid mode
      this.googleMap.setMapTypeId(google.maps.MapTypeId.HYBRID);
    } else {
      // restore original zoom and center if we have them
      if (this.preEditMap) {
        this.googleMap.setZoom(this.preEditMap.zoom);
        this.googleMap.setCenter(this.preEditMap.center);
      }
      this.preEditMap = undefined;

      // return to road map mode
      this.googleMap.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    }
  }

  // geocode the stop address and create the map marker at the location
  private buildMarker(stopDetails: UnmappedStopDetail) {
    const createMarker = (result: GeocodeResult, stopTypeCd: TripNodeActivityCd) => {
      this.originalAddress = result;

      this.unmappedMarker = new UnmappedStopMarker(
        result.location.lat(),
        result.location.lng(),
        this.mapMarkerService.getMarkerIconUnassigned(
          this.activityCdPipe.transform(stopTypeCd),
          10,
          true,
          stopTypeCd === TripNodeActivityCd.PICKUP_SHIPMENTS ? PICKUP_COLOR : DELIVERY_COLOR
        ),
        false,
        true
      );
      this.resetMarkerPosition(result);
    };

    const address = _get(stopDetails, 'address', '');
    const originalLocation: google.maps.LatLng = _get(stopDetails, 'location');

    // soft US bounds (lat > 0 && lng < 0)
    if (originalLocation && originalLocation.lat() > 0 && originalLocation.lng() < 0) {
      // already have a lat/lng for this stop, so start with that
      const geocodedAddress: GeocodeResult = {
        location: originalLocation,
        address: stopDetails.stopName,
        locationType: GeocodeCustomLocationTypes.NON_GEOCODED,
      };
      createMarker(geocodedAddress, stopDetails.stopTypeCd);
    } else {
      // geocode the address (ie, find the closest 'known' address to the one passed in)
      this.geocodeAddress({ address })
        .pipe(
          catchError((error) => {
            // failed to get the initial address. can't edit without it
            return EMPTY;
          })
        )
        .subscribe((geocodedAddress: GeocodeResult) => {
          // create the map marker at geocoded location
          createMarker(geocodedAddress, stopDetails.stopTypeCd);
        });
    }
  }

  private resetMarkerPosition(geocodedAddress: GeocodeResult) {
    this.currentAddressSubject.next(geocodedAddress);

    if (geocodedAddress.locationType === GeocodeCustomLocationTypes.NON_GEOCODED) {
      // address was not geocoded
      this.infoWindowMessage = `<div>Current Location</div><div>${_toUpper(geocodedAddress.address)}</div>`;
      this.infoWindowActions = [{ text: 'MOVE MARKER', type: GeoLocationActionType.MoveMarker }];
    } else {
      // addrewss WAS geocoded, so show the new address and accuracy
      this.infoWindowMessage = `<div>Nearest Address<br> (Accuracy: ${geocodedAddress.locationType})</div><div>${(
        geocodedAddress.address || ''
      ).toUpperCase()}</div>`;

      this.infoWindowActions = [
        { text: 'ACCEPT', type: GeoLocationActionType.Accept },
        { text: 'MOVE MARKER', type: GeoLocationActionType.MoveMarker },
      ];
    }

    // center map on the marker
    if (this.googleMap) {
      this.googleMap.setZoom(DEFAULT_ZOOM);
      this.googleMap.setCenter(geocodedAddress.location);
    }
  }

  // geocode the passed request handling errors
  private geocodeAddress(request: google.maps.GeocoderRequest): Observable<GeocodeResult> {
    this.pndStore$.dispatch(new GeoLocationStoreActions.SetStatus({ code: UnmappedStopStatusCode.SEARCHING }));

    return this.geoLocationService.geocodeAddress(request).pipe(
      take(1),
      tap((result) => {
        this.pndStore$.dispatch(
          new GeoLocationStoreActions.SetStatus({ code: UnmappedStopStatusCode.SEARCHING_SUCCESS })
        );
      }),
      catchError((error) => {
        this.pndStore$.dispatch(
          new GeoLocationStoreActions.SetStatus({
            code: UnmappedStopStatusCode.SEARCHING_ERROR,
            message: this.notificationMessageService.parseErrorMessage(error),
          })
        );
        return throwError(error);
      })
    );
  }

  // handle user selecting an action from the detail window
  selectedAction(action: GeoLocationActionType) {
    switch (action) {
      case GeoLocationActionType.Accept:
        this.submitAccepted();
        break;

      case GeoLocationActionType.MoveMarker:
        this.moveMarkerAction();
        break;

      case GeoLocationActionType.Cancel:
        this.resetMarkerPosition(this.originalAddress);
        break;

      case GeoLocationActionType.Close:
        this.closeEdit();
        break;
    }
  }

  private moveMarkerAction(): void {
    this.unmappedMarker.draggable = true;
    this.infoWindowMessage = '<div>Drag the marker to the <br> desired location</div>';
    this.infoWindowActions = [{ text: 'CANCEL', type: GeoLocationActionType.Cancel }];
  }

  // Handle user placing the marker at a new location.  attempt to geocode that location to
  // find the new address
  //
  // NOTE: The marker STAYS where the user placed it, even if it is not a valid geocoded location
  // This allows the user to place the marker in a location not yet updated in Google (say a new office park)
  markerMoved(event: MarkerDragEvent): void {
    const location: google.maps.LatLngLiteral = { lat: event.coords.lat, lng: event.coords.lng };

    this.geocodeAddress({ location }).subscribe((geocodedAddress: GeocodeResult) => {
      this.infoWindowMessage = `<div>Accept this new location?</div><div>${geocodedAddress.address}</div>`;
      this.infoWindowActions = [
        { text: 'ACCEPT', type: GeoLocationActionType.Accept },
        { text: 'CANCEL', type: GeoLocationActionType.Cancel },
      ];
      this.unmappedMarker.infowindowsOpen = true;

      // ignore the geocoded lat/lng and use the one entered by the user
      geocodedAddress.location = new google.maps.LatLng(location);

      this.currentAddressSubject.next(geocodedAddress);
      this.googleMap.panTo(geocodedAddress.location);
    });
  }

  private submitAccepted(): void {
    // submit the new location for the marker
    const newStopDetail = {
      ...this.originalStopDetail,
      address: this.currentAddress.address,
      location: this.currentAddress.location,
    };

    this.pndStore$.dispatch(new GeoLocationStoreActions.SetStatus({ code: UnmappedStopStatusCode.SAVING }));

    this.geoLocationService
      .updateCustomerGeoLocation(newStopDetail.acctInstId, !newStopDetail.isFutureCustomer, {
        latitude: newStopDetail.location.lat(),
        longitude: newStopDetail.location.lng(),
      })
      .pipe(
        take(1),
        catchError((error) => {
          this.pndStore$.dispatch(
            new GeoLocationStoreActions.SetStatus({
              code: UnmappedStopStatusCode.SAVING_ERROR,
              message: this.notificationMessageService.parseErrorMessage(error),
            })
          );
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.pndStore$.dispatch(new GeoLocationStoreActions.SetStatus({ code: UnmappedStopStatusCode.SAVING_SUCCESS }));

        // refresh the unassigned stops to get latest location data
        if (newStopDetail.stopTypeCd === TripNodeActivityCd.PICKUP_SHIPMENTS) {
          this.pndStore$.dispatch(new UnassignedPickupsStoreActions.Refresh());
        } else {
          this.pndStore$.dispatch(new UnassignedDeliveriesStoreActions.Refresh());
        }

        this.closeEdit();
      });
  }

  clearRelocation() {
    this.unmappedMarker = undefined;
  }

  private closeEdit() {
    this.pndStore$
      .select(GeoLocationStoreSelectors.editMode)
      .pipe(take(1))
      .subscribe((mode) => {
        if (mode === UnmappedStopsEditMode.UnmappedDeliveries || mode === UnmappedStopsEditMode.UnmappedPickups) {
          // stay in edit mode and allow user to pick a new stop to edit
          this.pndStore$.dispatch(new GeoLocationStoreActions.SetStopToEdit(undefined));
        } else {
          // exit edit mode
          this.pndStore$.dispatch(new GeoLocationStoreActions.EndEdit());
        }
      });
  }
}
