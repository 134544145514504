import { get as _get, has as _has, sortBy as _sortBy, toNumber as _toNumber } from 'lodash';
import { StoreSourcesEnum } from '../enums/store-sources.enum';

export interface EventItem<
  IDENTIFIER_TYPE extends
    | AssignedStopIdentifier
    | UnassignedDeliveryIdentifier
    | UnassignedPickupIdentifier
    | RouteIdentifier
    | PlanningRouteShipmentIdentifier
    | PlanningRouteShipmentIdentifier[]
> {
  id: IDENTIFIER_TYPE;
  source: StoreSourcesEnum;
}

export interface AssignedStopIdentifier {
  routeInstId: number;
  seqNo: number;
  origSeqNo: number;
}

// Return true if the passed AssignedStopIdentifiers are equal
export function areStopsEqual(stopA: AssignedStopIdentifier, stopB: AssignedStopIdentifier): boolean {
  return stopA.routeInstId === stopB.routeInstId && stopA.seqNo === stopB.seqNo;
}

export interface AssignedStopPositionIdentifier extends AssignedStopIdentifier {
  latitudeNbr: number;
  longitudeNbr: number;
}

export interface ConsigneeIdentifier {
  consignee: {
    acctInstId: number;
    name1: string;
    latitudeNbr: number;
    longitudeNbr: number;
  };
}

// return true if the UnassignedDeliveryIdentifier are equal (consignee and shipmentInstId)
export function unassignedDeliveryIdentifierEqual(
  a: UnassignedDeliveryIdentifier,
  b: UnassignedDeliveryIdentifier
): boolean {
  if (consigneeToId(a) === consigneeToId(b)) {
    if (!!a.shipmentInstId || !!b.shipmentInstId) {
      return _toNumber(a.shipmentInstId) === _toNumber(b.shipmentInstId);
    } else {
      return true;
    }
  }
  return false;
}

// return a string ID for the given ConsigneeIdentifier
export function consigneeToId(id: ConsigneeIdentifier): string {
  const acctInstId = _get(id, 'consignee.acctInstId');
  return acctInstId ? `${acctInstId}` : undefined;
}

export function consigneeShipmentToId(id: UnassignedDeliveryIdentifier): string {
  const consigneeId = consigneeToId(id);
  const shipmentInstId = _get(id, 'shipmentInstId');
  if (consigneeId && shipmentInstId) {
    return `${consigneeId}-${shipmentInstId}`;
  } else {
    return undefined;
  }
}

export interface UnassignedDeliveryIdentifier extends ConsigneeIdentifier {
  shipmentInstId?: number;
}

export interface RouteStopIdentifier extends ConsigneeIdentifier {
  routeInstId?: number;
}

export interface PlanningRouteShipmentIdentifier extends RouteStopIdentifier {
  shipmentInstId?: number;
  proNbr?: string;
}

// return a string ID for the given RouteStopIdentifier
export function routeStopToId(id: RouteStopIdentifier): string {
  return `${id.routeInstId}-${id.consignee.acctInstId}`;
}

export interface UnassignedPickupIdentifier {
  pickupInstId: number;
  shipper: {
    latitudeNbr: number;
    longitudeNbr: number;
  };
}

export interface RouteIdentifier {
  routeInstId: number;
}
