import { RouteStatusCd, ShipmentSpecialServiceCd, TripNodeStatusCd } from '@xpo-ltl/sdk-common';
import { get as _get, isEmpty as _isEmpty } from 'lodash';
import { StopWindowService } from '../services/stop-window.service';
import { TripStatusEnum } from './trip-status.enum';

export class MapMarkerInfo {
  readonly routeStatus: RouteStatusCd;
  readonly tripStatus: TripStatusEnum | TripNodeStatusCd;
  readonly consigneeName: string;
  readonly consigneeName2: string;
  readonly consigneeAddress: string;
  readonly consigneeCity: string;
  readonly consigneeState: string;
  readonly consigneeZipCode: string;
  readonly estimatedArrival: Date;
  readonly totalWeight: number;
  readonly totalShipments: number;
  readonly totalMotorMoves: number;
  readonly acctInstId: number;
  readonly acctMadCd: string;
  readonly routeId: string;
  readonly stopType: string;
  readonly stopWindowType: string;
  readonly stopWindowTime: string;

  readonly color: string; // HACK to let us set original color of marker

  specialServices: ShipmentSpecialServiceCd[];

  routeName?: string;

  constructor(
    private stopWindowService: StopWindowService,
    ud: any, // TODO - this should be type!
    routeStatus: RouteStatusCd,
    tripStatus: TripStatusEnum,
    routeId?: string,
    routeName?: string
  ) {
    this.routeStatus = routeStatus;
    this.tripStatus = tripStatus;
    this.routeId = routeId;
    this.routeName = routeName;

    const account = _get(ud, 'consignee', _get(ud, 'shipper'));
    if (account) {
      this.acctInstId = account.acctInstId;
      this.acctMadCd = account.acctMadCd;
      this.consigneeName = account.name1;
      this.consigneeName2 = account.name2;
      this.consigneeAddress = account.addressLine1;
      this.consigneeCity = account.cityName;
      this.consigneeState = account.stateCd;
      this.consigneeZipCode = account.postalCd;
    }

    this.estimatedArrival = ud.scheduleETA;
    this.totalWeight = ud.totalWeightLbs;
    this.totalMotorMoves = ud.motorizedPiecesCount;
    this.totalShipments = ud.totalShipmentsCount;
    this.stopType = ud.stopType;
    this.color = ud.color;

    this.stopWindowType = this.stopWindowService.getStopWindowType(ud.stopWindow);
    this.stopWindowTime = this.stopWindowService.getStopWindowTime(ud.stopWindow, false);
  }
}
