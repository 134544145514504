import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { XpoLtlShipmentDescriptor, XpoLtlShipmentDetailsService, XpoLtlShipmentDetailsTabs } from '@xpo/ngx-ltl';
import { size as _size } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DocumentViewerComponent } from '../../shared/document-viewer';
import { ConfirmCancelData } from './confirm-cancel/confirm-cancel-data';
import { ConfirmCancelComponent } from './confirm-cancel/confirm-cancel.component';

@Injectable({
  providedIn: 'root',
})
export class PndDialogService {
  constructor(private dialog: MatDialog, private xpoLtlShipmentDetailsService: XpoLtlShipmentDetailsService) {}

  /**
   * Basic confirmation dialog
   */
  showConfirmCancelDialog(
    title: string,
    message: string,
    cancelButtonText: string = 'Cancel',
    confirmButtonText: string = 'Ok',
    config: any = {}
  ): Observable<boolean> {
    const subject = new Subject<boolean>();
    const data = new ConfirmCancelData(
      title,
      message,
      _size(cancelButtonText) > 0 ? cancelButtonText : undefined,
      _size(confirmButtonText) > 0 ? confirmButtonText : undefined
    );
    const dialogRef = this.dialog.open(ConfirmCancelComponent, {
      data,
      disableClose: true,
      panelClass: 'confirm-cancel-dialog',
      ...config,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((results: boolean) => {
        subject.next(results);
        subject.complete();
      });

    return subject.asObservable();
  }

  /**
   * Display dialog contianing the ShipmentDetails
   * @param shipmentInstId the shipment to display
   */
  showShipmentDetailsDialog(
    shipmentDescriptors: XpoLtlShipmentDescriptor | XpoLtlShipmentDescriptor[]
  ): Observable<void> {
    return this.xpoLtlShipmentDetailsService.showShipmentDetailsDialog(
      shipmentDescriptors,
      {
        tabs: [{ id: XpoLtlShipmentDetailsTabs.Details }, { id: XpoLtlShipmentDetailsTabs.History }],
        hideCharges: true,
        moveable: true,
        tabInfoComponent: DocumentViewerComponent,
        showShipmentSelector: _size(shipmentDescriptors) > 1,
      },
      {
        width: '1290px',
        maxHeight: '1200px',
      }
    );
  }
}
