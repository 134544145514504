import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import { get as _get } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'pnd-special-services',
  templateUrl: './special-services.component.html',
  styleUrls: ['./special-services.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialServicesComponent {
  readonly ShipmentSpecialServiceCd = ShipmentSpecialServiceCd;

  readonly SPECIAL_SERVICE_ICONS = {
    [ShipmentSpecialServiceCd.APPOINTMENT]: 'SS_Appointment',
    [ShipmentSpecialServiceCd.BULK_LIQUID]: 'SS_BulkLiquid',
    [ShipmentSpecialServiceCd.BYPASS]: 'SS_Bypass',
    [ShipmentSpecialServiceCd.CASH_ON_DELIVERY]: 'SS_CashOnDelivery',
    [ShipmentSpecialServiceCd.CONSTRUCTION_AND_UTILITY_SITES_DELIVERY]: 'SS_CSD',
    [ShipmentSpecialServiceCd.CUSTOMS_IN_BOND]: 'SS_CustomsInBond',
    [ShipmentSpecialServiceCd.DRIVER_COLLECT]: 'SS_CashOnDelivery',
    [ShipmentSpecialServiceCd.EXCESSIVE_LENGTH]: 'SS_ExcessiveLength',
    [ShipmentSpecialServiceCd.EXPEDITE]: 'SS_Expedite',
    [ShipmentSpecialServiceCd.FOOD]: 'SS_Food',
    [ShipmentSpecialServiceCd.FORK_LIFT]: 'SS_Forklift',
    [ShipmentSpecialServiceCd.FREEZABLE]: 'SS_Freezable',
    [ShipmentSpecialServiceCd.GUARANTEED]: 'SS_G',
    [ShipmentSpecialServiceCd.GUARANTEED_BY_NOON]: 'SS_G12',
    [ShipmentSpecialServiceCd.HAZMAT]: 'SS_Hazmat',
    [ShipmentSpecialServiceCd.HEADLOAD]: 'SS_Headload',
    [ShipmentSpecialServiceCd.HIGHWAY_SUB_SERVICE]: 'SS_HSS',
    [ShipmentSpecialServiceCd.LIFT_GATE]: 'SS_Liftgate',
    [ShipmentSpecialServiceCd.LINEHAUL]: 'SS_Linehaul',
    [ShipmentSpecialServiceCd.MISLOAD]: 'SS_Misload',
    [ShipmentSpecialServiceCd.NOTIFY_ON_ARRIVAL]: 'SS_NOA',
    [ShipmentSpecialServiceCd.PALLET_JACK]: 'SS_PalletJack',
    [ShipmentSpecialServiceCd.PARTIAL_BYPASS]: 'SS_PartialByPass',
    [ShipmentSpecialServiceCd.PREMIUM_SUPPORT_SERVICE]: 'SS_Premium',
    [ShipmentSpecialServiceCd.PUP_ONLY]: 'SS_PUP',
    [ShipmentSpecialServiceCd.RAPID_REMOTE_SERVICE]: 'SS_RSS',
    [ShipmentSpecialServiceCd.RESIDENTIAL_DELIVERY]: 'SS_ResidentialDelivery',
    [ShipmentSpecialServiceCd.SUPPLEMENTAL]: 'SS_Supplemental',
    [ShipmentSpecialServiceCd.TIME_DATE_CRITICAL]: 'SS_TDC',
    [ShipmentSpecialServiceCd.TRAP_SERVICE]: 'SS_Trap',
    [ShipmentSpecialServiceCd.UNMAPPED]: 'SS_Unmapped',
  };

  private specialServicesSubject = new BehaviorSubject<ShipmentSpecialServiceCd[]>([]);
  readonly specialServices$ = this.specialServicesSubject.asObservable();

  constructor() {}

  @Input()
  set value(specialServices: ShipmentSpecialServiceCd[]) {
    this.specialServicesSubject.next(specialServices);
  }

  /**
   * Returns true if the service code is set
   */
  iconForCd(serviceCd: ShipmentSpecialServiceCd): string {
    const icon = _get(this.SPECIAL_SERVICE_ICONS, serviceCd, 'ERROR');
    return icon;
  }
}
