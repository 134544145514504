export enum RouteStopsGridHeaders {
  ROUTE_ID = 'Route ID.',
  ROW_SELECTED = 'checkbox',
  SEQ = 'Seq.',
  STOP_TYPE = 'Type',
  CONSIGNEE = 'Consignee',
  CONSIGNEE_ADDRESS = 'Address',
  CONSIGNEE_CITY = 'City',
  ZIP_CODE = 'Zip Code',
  SPECIAL_SERVICES = 'Special Services',
  LTR = 'L/T/R',
  BILLS = 'Bills',
  MM = 'MM',
  WEIGHT = 'Weight (lbs.)',
  CUBE = 'Cube',
  DELIVERY_WINDOW_TYPE = 'Delivery Window Type',
  DELIVERY_WINDOW_TIME = 'Delivery Window Time',
  DELIVERY_WINDOW = 'Delivery Window Date',
  ETA = 'ETA',
  ETD = 'ETD',
}
